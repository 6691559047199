import { FC } from 'react';
import L from 'i18n-react';
import { IUploadFileInput } from './types';
// ==================================:

const UploadFileInput: FC<IUploadFileInput> = (props) => {
	const { handleUploadFile, errorSelfie } = props;

	return (
		<>
			<div className="upload-photo-wrap">
				<label htmlFor="file-input" className="upload-photo-box ">
					<input
						id="file-input"
						type="file"
						className="hidden"
						onChange={(e) => handleUploadFile(e)}
						accept="image/png,image/jpeg"
					/>
					<span className="upload-photo">
						<span className="upload-photo__info">
							<span className="flex flex-c flex-m">
								<svg
									width="24"
									height="25"
									viewBox="0 0 24 25"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M8.32918 3.16459C8.45622 2.9105 8.71592 2.75 9 2.75H15C15.2841 2.75 15.5438 2.9105 15.6708 3.16459L17.1708 6.16459C17.2871 6.39708 17.2746 6.67319 17.138 6.8943C17.0013 7.11541 16.7599 7.25 16.5 7.25H7.5C7.24007 7.25 6.99867 7.11541 6.86201 6.8943C6.72536 6.67319 6.71293 6.39708 6.82918 6.16459L8.32918 3.16459ZM9.46353 4.25L8.71353 5.75H15.2865L14.5365 4.25H9.46353Z"
										fill="#1C1C29"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M3.5 7.25C3.08579 7.25 2.75 7.58579 2.75 8V20C2.75 20.4142 3.08579 20.75 3.5 20.75H20.5C20.9142 20.75 21.25 20.4142 21.25 20V8C21.25 7.58579 20.9142 7.25 20.5 7.25H3.5ZM1.25 8C1.25 6.75736 2.25736 5.75 3.5 5.75H20.5C21.7426 5.75 22.75 6.75736 22.75 8V20C22.75 21.2426 21.7426 22.25 20.5 22.25H3.5C2.25736 22.25 1.25 21.2426 1.25 20V8Z"
										fill="#1C1C29"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M7.25 14C7.25 11.3766 9.37664 9.25 12 9.25C14.6234 9.25 16.75 11.3766 16.75 14C16.75 16.6234 14.6234 18.75 12 18.75C9.37664 18.75 7.25 16.6234 7.25 14ZM12 10.75C10.2051 10.75 8.75 12.2051 8.75 14C8.75 15.7949 10.2051 17.25 12 17.25C13.7949 17.25 15.25 15.7949 15.25 14C15.25 12.2051 13.7949 10.75 12 10.75Z"
										fill="#1C1C29"
									/>
								</svg>
								<span className="upload-photo__info-title">
									{String(L.translate('Forms.Inputs.field_upload'))}
								</span>
							</span>
						</span>
					</span>
				</label>
			</div>
			{errorSelfie && (
				<div className="error-text error-text--centered">
					<p className="error-text__item">{String(L.translate('Errors.please_upload_selfie'))}</p>
				</div>
			)}
			{/* <div className={errorSelfie ? 'authorization__field error' : 'authorization__field'}>
				<div className="form-upload reset-form">
					<input
						id="file-input"
						type="file"
						className="input-item form-upload__item"
						onChange={(e) => handleUploadFile(e)}
						accept="image/png,image/jpeg"
					/>
					<label htmlFor="file-input" className="input-file-btn">
						<span className="input-file-descr input-file-descr--empty">
							{String(L.translate('Forms.Forgot2fa.forgot_2fa_text_button'))}
						</span>
					</label>
				</div>
			</div>
			{errorSelfie && (
				<div className="error-text error-text--centered">
					<p className="error-text__item">{String(L.translate('Errors.please_upload_selfie'))}</p>
				</div>
			)} */}
		</>
	);
};

export default UploadFileInput;
