/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import L from 'i18n-react';
/* eslint-disable no-debugger */
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { callErrorFunc } from 'services/http/customResponseError/customResponseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { ICheckTwoFaResponse } from './types';
import {
	authInitState,
	createNewPasswordRequest,
	createNewPasswordSuccess,
	emailConfirmRequest,
	emailConfirmStatusCallback,
	emailConfirmStatusRequest,
	emailConfirmSuccess,
	emailConfirmFailure,
	emailResetConfirmTokenRequest,
	emailResetConfirmTokenSuccess,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	generateSmsRequest,
	forgotTwoFaRequest,
	ipConfirmRequest,
	ipConfirmSuccess,
	loginRequest,
	loginSuccess,
	logoutRequest,
	registrationRequest,
	registrationSuccess,
	phoneConfirmRequest,
	generateEmailTotpRequest,
	check2faEnabledRequest,
	check2faEnabledSuccess,
} from './reducer';
import {
	ILoginResponse,
	ILoginPayload,
	IRegistrationPayload,
	ILogoutPayload,
	IForgotPasswordPayload,
	ICreateNewPasswordPayload,
	IEmailConfirmPayload,
	IEmailResetConfirmTokenPayload,
	IipConfirmPayload,
	IGenerateSmsRequestPayload,
	IResetTwoFaPayload,
	IRegistrResponse,
} from './types';

// =============================================================:
function* ipConfirmRequestWorker(action: PayloadAction<IipConfirmPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.ipConfirm, payload.data);
		yield put(ipConfirmSuccess());

		// notificationContainer(
		// 	String(L.translate('IPConfirmation.ip_confirmation_success_confirm')),
		// 	'info',
		// );

		// payload.history.push('/login');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* loginRequestWorker(action: PayloadAction<ILoginPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ILoginResponse = yield call(api.auth.login, payload.data);
		yield put(loginSuccess(response));
		yield put(check2faEnabledRequest({ email: payload.data.email }));
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'ip_not_verified') {
				// payload.history.push('/ip-confirmation');
			} else if (error?.response?.data?.errors?.totp) {
				payload.history.replace({ pathname: '/2fa', state: { ...payload.data } });
				const code: string = error?.response?.data?.errors?.totp[0];
				notificationContainer(String(L.translate(`Errors.${code}`)), 'info');
			} else {
				if (error?.response?.data?.errors) {
					const isBlocked = error?.response?.data?.errors.find(
						(err: string) => err === 'user_blocked',
					);
					if (isBlocked) {
						payload.history.replace({ pathname: '/login', state: { ...payload.data } });
					}
					responseErrors(error);
					yield put(authInitState());
					return;
				}
				yield put(authInitState());
			}
			// responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* registrationRequestWorker(action: PayloadAction<IRegistrationPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IRegistrResponse = yield call(api.auth.registration, payload.data);
		yield put(registrationSuccess(response));

		notificationContainer(String(L.translate('Forms.Register.check_your_email')), 'info');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors?.email?.[0] === 'the_email_has_already_been_taken') {
				payload.history.replace('/email-is-taken');
				yield put(authInitState());
				return;
			}
			if (error?.response?.data?.errors) {
				yield put(authInitState());
				callErrorFunc(error?.response?.data?.errors);
				return;
			}
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* forgotPasswordRequestWorker(action: PayloadAction<IForgotPasswordPayload>) {
	const { payload } = action;

	const {
		data: { email },
	} = payload;

	try {
		yield put(showLoading());
		yield call(api.auth.forgotPassword, payload.data);
		yield put(forgotPasswordSuccess());

		notificationContainer(`Reset password link was send to ${email}`, 'info');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors) {
				yield put(authInitState());
				callErrorFunc(error?.response?.data?.errors);
				return;
			}
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* emailConfirmRequestWorker(action: PayloadAction<IEmailConfirmPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.emailConfirm, payload);
		yield put(emailConfirmStatusCallback());
		yield put(emailConfirmSuccess());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (
				error?.response?.status === 400 &&
				error?.response?.data?.errors[0] === 'email_already_confirmed'
			) {
				yield put(emailConfirmStatusRequest());
			} else {
				responseErrors(error);
				yield put(authInitState());
			}
		}
		yield put(emailConfirmFailure());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* emailResetConfirmTokenRequestWorker(
	action: PayloadAction<IEmailResetConfirmTokenPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.emailResetConfirmToken, payload);
		yield put(emailConfirmStatusCallback());
		yield put(emailResetConfirmTokenSuccess());

		// payload.history.replace('/email-confirm-check');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (
				error?.response?.status === 400 &&
				error?.response?.data?.errors[0] === 'email_already_confirmed'
			) {
				yield put(emailConfirmStatusRequest());
			} else {
				responseErrors(error);
				yield put(authInitState());
			}
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* createNewPasswordWorker(action: PayloadAction<ICreateNewPasswordPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.newPassword, payload.data);
		yield put(createNewPasswordSuccess());
		notificationContainer('Password has been successfully changed!', 'info');
		// payload.history.push('/login');

		// notificationContainer(String(L.translate(`Errors.password_successfully_changed`)), 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* logoutRequestWorker(action: PayloadAction<ILogoutPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.logout);
		yield put(authInitState());
		payload.history.push({
			search: '',
		});
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* generateSmsRequestWorker(action: PayloadAction<IGenerateSmsRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());

		yield call(api.auth.generateSms, payload);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer('Sms code already send on your phone!', 'error');
			// responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* generateEmailTotpRequestWorker() {
	try {
		yield put(showLoading());
		yield call(api.auth.generateEmailTotp);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer('Email code already send on your email!', 'error');
			// responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* confirmPhoneRequestWorker(action: PayloadAction<IGenerateSmsRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());

		yield call(api.auth.confirmPhone, payload);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer('Something wrong, try again!', 'error');
			// responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* resetTwoFaRequestWorker(action: PayloadAction<IResetTwoFaPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.resetTwoFa, payload);
		notificationContainer(String(L.translate(`Success.2fa_successfully`)), 'success');
		// payload.history.push({ pathname: '/forgot-2fa', state: '2fa_success' });
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors) {
				yield put(authInitState());
				// callErrorFunc(error?.response?.data?.errors);
				return;
			}
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* checkTwoFaRequestWorker(action: PayloadAction<IResetTwoFaPayload>) {
	const { payload } = action;

	try {
		const response: ICheckTwoFaResponse = yield call(api.auth.checkTwoFa, payload);
		yield put(check2faEnabledSuccess(response.data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* authSaga() {
	yield takeEvery(loginRequest.type, loginRequestWorker);
	yield takeEvery(ipConfirmRequest.type, ipConfirmRequestWorker);
	yield takeEvery(registrationRequest.type, registrationRequestWorker);
	yield takeEvery(forgotPasswordRequest.type, forgotPasswordRequestWorker);
	yield takeEvery(emailConfirmRequest.type, emailConfirmRequestWorker);
	yield takeEvery(emailResetConfirmTokenRequest.type, emailResetConfirmTokenRequestWorker);
	yield takeEvery(createNewPasswordRequest.type, createNewPasswordWorker);
	yield takeEvery(logoutRequest.type, logoutRequestWorker);
	yield takeEvery(generateSmsRequest.type, generateSmsRequestWorker);
	yield takeEvery(generateEmailTotpRequest.type, generateEmailTotpRequestWorker);
	yield takeEvery(forgotTwoFaRequest.type, resetTwoFaRequestWorker);
	yield takeEvery(phoneConfirmRequest.type, confirmPhoneRequestWorker);
	yield takeEvery(check2faEnabledRequest.type, checkTwoFaRequestWorker);
}
