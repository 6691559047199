export const numberValidation = (text: string): boolean => {
	if (text?.length >= 2 && text?.[0] === '0' && text?.[1] !== '.') return false;

	return /^\d+[.,]?\d*$/.test(text);
};

export const positiveNumber = (value: string | number | null | undefined) => {
	if (Number(value) < 0) {
		return 0;
	}
	return value;
};

export const numberValidationNext = (text: string): boolean => {
	if (Number(text) === 0 || text === '') return true;
	if (text?.length >= 2 && text?.[0] === '0' && text?.[1] !== '.') return true;

	return false;
};

export const numberExists = (value: number | null | undefined) => {
	return !!value || value === 0;
};
