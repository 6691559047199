import { useState, useEffect, useMemo } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetPairs } from 'redux/reducers/assetPairs/selectors';
import { IAssetsItem } from 'redux/reducers/assets/types';
import { getAssetsList } from 'redux/reducers/assets/selectors';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import {
	getCurrencyRateRequest,
	getLimitsRequest,
	putConvertRequest,
} from 'redux/reducers/exchange/reducer';
import { getLimitsState, getRateState } from 'redux/reducers/exchange/selectors';
import reduceToValidNumber from 'services/utils/reduceToValidNumber';
import { convertToFixed } from 'services/utils/convertToFixed';
import InputField from './InputField';
import ExchangeSlider from './Slider';
import SwitchButton from './SwitchButton';

// eslint-disable-next-line react-hooks/exhaustive-deps

const Exchange = () => {
	const dispatch = useDispatch();

	const [sliderValue, setSliderValue] = useState(0);
	const [fromValue, setFromValue] = useState('');
	const [toValue, setToValue] = useState('');
	const [assetsStore, setAssetsStore] = useState({ from_asset_code: 'btc', to_asset_code: 'usdt' });
	const [secondList, setSecondList] = useState<IAssetsItem[] | null>(null);

	const assetPairs = useSelector(getAssetPairs);
	const assetsList = useSelector(getAssetsList);
	const walletsList = useSelector(getWalletsList);
	const limits = useSelector(getLimitsState);
	const rate = useSelector(getRateState);

	useEffect(() => {
		if (assetsStore.from_asset_code === assetsStore.to_asset_code) {
			return;
		}
		dispatch(getLimitsRequest({ ...assetsStore }));
		dispatch(getCurrencyRateRequest({ ...assetsStore }));
	}, [dispatch, assetsStore]);

	const availableBalance = () => {
		return (
			walletsList?.find((item) => item.asset.code === assetsStore.from_asset_code)?.balance || 0
		);
	};

	const toAssets = (from: string | null) => {
		const newResult = [];
		const arr =
			assetPairs.assetPairs
				?.filter((item) => item.code.split('_').indexOf(from || 'btc') !== -1)
				.map((item) => item.code.split('_'))
				.flat(Infinity) || [];
		for (let i = 0; i < arr.length; i += 1) {
			if (newResult.indexOf(arr[i]) === -1 && arr[i] !== from) {
				newResult.push(arr[i]);
			}
		}
		return newResult;
	};

	useEffect(() => {
		const second = [];
		const assets = toAssets(assetsStore.from_asset_code);
		for (let i = 0; i < assets.length; i += 1) {
			const find = assetsList?.find((item) => item.code === assets[i]);
			if (find) {
				second.push(find);
			}
		}
		setSecondList(second);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assetsStore.from_asset_code, assetsList, assetPairs]);

	const handleFromCurrencyChange = (code: string) => {
		setAssetsStore({ ...assetsStore, from_asset_code: code });
	};

	const handleToCurrencyChange = (code: string) => {
		setAssetsStore({ ...assetsStore, to_asset_code: code });
	};

	const handleFromValueChange = (value: string | number) => {
		const balance = availableBalance();
		const slider = (Number(reduceToValidNumber(String(value))) / Number(balance)) * 100;
		if (!value) {
			setFromValue('');
			setToValue('');
			setSliderValue(0);
			return;
		}
		setFromValue(String(value));
		setToValue(String(Number(reduceToValidNumber(String(value))) * rate.rate));
		setSliderValue(slider);
	};

	const handleSliderChange = (value: number) => {
		const balance = availableBalance();
		const from = Number(balance) * (value / 100);
		setSliderValue(value);
		setFromValue(String(from));
	};

	const handleSwitchClick = () => {
		const from = assetsStore.from_asset_code;
		const to = assetsStore.to_asset_code;
		setAssetsStore({
			from_asset_code: to,
			to_asset_code: from,
		});
		const secondBalance = walletsList?.find((item) => item.asset.code === to)?.balance || 0;
		if (!fromValue && !toValue) {
			return;
		}
		setFromValue(String((Number(secondBalance) * sliderValue) / 100));
	};

	const firstDefaultValue = () => {
		return assetsList?.find((item) => item.code === assetsStore.from_asset_code);
	};

	const secondDefaultValue = () => {
		return secondList?.find((item) => item.code === assetsStore.to_asset_code);
	};

	useEffect(() => {
		if (assetsStore.from_asset_code === assetsStore.to_asset_code) {
			const assets = toAssets(assetsStore.from_asset_code)[0];
			setAssetsStore({ ...assetsStore, to_asset_code: String(assets) });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assetsStore, secondList]);

	useEffect(() => {
		if (!fromValue) {
			setToValue('');
			return;
		}
		setToValue(String(Number(reduceToValidNumber(String(fromValue))) * rate.rate));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rate, assetsStore, sliderValue]);

	const handleConvertClick = () => {
		dispatch(
			putConvertRequest({
				from_asset_code: assetsStore.from_asset_code,
				to_asset_code: assetsStore.to_asset_code,
				quantity: reduceToValidNumber(fromValue),
			}),
		);
		setFromValue('');
	};

	const handleSecondValueChange = (value: string | number) => {
		if (value) {
			const balance = availableBalance();
			const slider =
				(Number(reduceToValidNumber(String(value))) / rate.rate / Number(balance)) * 100;
			setToValue(String(value));
			setFromValue(String(Number(reduceToValidNumber(String(value))) / rate.rate));
			setSliderValue(slider);
			return;
		}
		setToValue('');
		setFromValue('');
		setSliderValue(0);
	};

	return (
		<section className="convert-section section-gradient">
			<div className="inside">
				<div className="convert">
					<div className="page-title page-title--size32 page-title--center fw-500">
						<p>{String(L.translate('Convert.convert_title'))}</p>
					</div>
					<div className="page-text page-text--size16 page-text--center fw-400">
						<p>{String(L.translate('Convert.convert_info_1'))}</p>
						<p>{String(L.translate('Convert.convert_info_2'))}</p>
					</div>
					<div className="convert-form">
						<InputField
							min={convertToFixed(limits?.min_quantity, 6) || 0}
							max={convertToFixed(limits?.max_quantity, 6) || 0}
							maxButton
							value={
								String(reduceToValidNumber(fromValue)).split('.')[1]?.length > 8
									? convertToFixed(Number(fromValue), 8)
									: fromValue
							}
							setValue={handleFromValueChange}
							itemsList={assetsList}
							defaultValue={firstDefaultValue() || ''}
							setCurrency={handleFromCurrencyChange}
						/>
						<SwitchButton onSwitchClick={handleSwitchClick} />
						<InputField
							min={limits && rate && convertToFixed(limits.min_quantity * rate.rate, 6)}
							max={limits && rate && convertToFixed(limits.max_quantity * rate.rate, 6)}
							title={String(L.translate('Convert.convert_info_to'))}
							value={
								String(reduceToValidNumber(toValue)).split('.')[1]?.length > 8
									? convertToFixed(Number(toValue), 8)
									: toValue
							}
							setValue={handleSecondValueChange}
							itemsList={secondList}
							defaultValue={secondDefaultValue() || ''}
							setCurrency={handleToCurrencyChange}
						/>
						<ExchangeSlider
							rate={rate?.rate || 0}
							sliderValue={sliderValue}
							setSliderValue={handleSliderChange}
							currencies={{ from: assetsStore.from_asset_code, to: assetsStore.to_asset_code }}
						/>
						<button
							className="button button--size2 button--full-width"
							type="button"
							onClick={handleConvertClick}
						>
							{String(L.translate('TransactionHistory.navButtons.convert'))}
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Exchange;
