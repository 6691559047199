import { FC } from 'react';
import { useSelector } from 'react-redux';
import L from 'i18n-react';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { useHistory } from 'react-router';
import { IPropsOrderButtons } from './types';

const OrderFormButtons: FC<IPropsOrderButtons> = ({ buyClick, sellClick }) => {
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const history = useHistory();

	const handleBuyClick = () => {
		if (authIsAuthenticated) {
			buyClick!();
		} else {
			history.push('/login');
		}
	};

	const handleSellClick = () => {
		if (authIsAuthenticated) {
			sellClick!();
		} else {
			history.push('/registration');
		}
	};

	return (
		<div className="orderform-content-item-bottom__buttons">
			<button
				className="button button--full-width button--type12"
				type="button"
				onClick={handleBuyClick}
			>
				{authIsAuthenticated
					? String(L.translate('Trade.Perpetual.OrderForm.buy_button'))
					: String(L.translate('Trade.Perpetual.OrderForm.login_button'))}
			</button>
			<button
				className="button button--full-width button--type13"
				type="button"
				onClick={handleSellClick}
			>
				{authIsAuthenticated
					? String(L.translate('Trade.Perpetual.OrderForm.sell_button'))
					: String(L.translate('Trade.Perpetual.OrderForm.sign_up_button'))}
			</button>
		</div>
	);
};
export default OrderFormButtons;
