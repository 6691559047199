import { FC, useEffect, ReactNode } from 'react';
import L from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getClosedOrderHistory,
	getPerpetualBaseAssets,
	getClosedOrderHistoryLoader,
	getBaseAsset,
} from 'redux/reducers/perpetual/selectors';
import { getClosedOrderHistoryRequest } from 'redux/reducers/perpetual/reducer';
import Loader from 'ui/Loader';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';
import LoginTradeTablePlug from '../TradeTablePlug';
import ClosedHistoryTabItem from './ClosedHistoryTabItem';
import { CLOSED_HISTORY } from '../mock';
import { IClosedHistoryTab } from './types';

const ClosedHistoryTab: FC<IClosedHistoryTab> = ({ showAll }) => {
	const dispatch = useDispatch();
	const stopOrders = useSelector(getClosedOrderHistory);
	const loader = useSelector(getClosedOrderHistoryLoader);
	// const stopOrders = CLOSED_HISTORY;
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const baseAsset = useSelector(getBaseAsset);
	const { perPage, currentPage } = usePagination();

	useEffect(() => {
		if (authIsAuthenticated) {
			if (baseAsset?.base_asset) {
				dispatch(
					getClosedOrderHistoryRequest({
						is_show_all_asset: showAll ? 1 : 0,
						base_asset: baseAsset.base_asset,
						per_page: perPage,
						current_page: currentPage,
					}),
				);
			}
		}
	}, [baseAsset?.base_asset, currentPage, dispatch, perPage, showAll, authIsAuthenticated]);

	const renderContent: () => ReactNode = () => {
		if (!authIsAuthenticated) {
			return <LoginTradeTablePlug />;
		}
		if (loader) {
			return (
				<div className="popup-loader">
					<Loader />
				</div>
			);
		}
		if (stopOrders && stopOrders?.data.length > 0) {
			// eslint-disable-next-line react/no-array-index-key
			return stopOrders?.data?.map((item, idx) => <ClosedHistoryTabItem key={idx} item={item} />);
		}
		return <TableEmptyPlug />;
	};

	return (
		<div className="table table--trade table--trade-perpetual-close-history">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Tables.time'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Tables.contract'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Tables.side'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Tables.profit_loss'))}</p>
					</div>
					<div className="td td--right">
						<p>{String(L.translate('Trade.Perpetual.Tables.source'))}</p>
					</div>
				</div>
			</div>
			<div className="table-body">{renderContent()}</div>
			{stopOrders?.last_page && Number(stopOrders?.total) > Number(stopOrders?.per_page) && (
				<Pagination lastPage={stopOrders.last_page} getCurrentHref={(s) => String(s)} />
			)}
		</div>
	);
};

export default ClosedHistoryTab;
