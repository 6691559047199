import { FC } from 'react';
import L from 'i18n-react';

const NoBorrowLabel: FC<{ code: string }> = ({ code }) => {
	return (
		<div className="flex-column">
			<div className="error-info">
				<span>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M3.66534 3.66582C5.28581 2.04537 7.52625 1.04199 9.99984 1.04199C12.4734 1.04199 14.7139 2.04537 16.3343 3.66582L15.8924 4.10777L16.3343 3.66583C17.9548 5.2863 18.9582 7.52674 18.9582 10.0003C18.9582 12.4739 17.9548 14.7144 16.3343 16.3348C14.7139 17.9553 12.4734 18.9587 9.99984 18.9587C7.52625 18.9587 5.28581 17.9553 3.66534 16.3348L4.10728 15.8929L3.66534 16.3348C2.04489 14.7144 1.0415 12.4739 1.0415 10.0003C1.0415 7.52674 2.04489 5.2863 3.66533 3.66583L3.66534 3.66582ZM9.99984 2.29199C7.87109 2.29199 5.94486 3.15409 4.54922 4.54971C3.1536 5.94536 2.2915 7.87158 2.2915 10.0003C2.2915 12.1291 3.1536 14.0553 4.54922 15.4509C5.94486 16.8466 7.87109 17.7087 9.99984 17.7087C12.1286 17.7087 14.0548 16.8466 15.4504 15.4509C16.8461 14.0553 17.7082 12.1291 17.7082 10.0003C17.7082 7.87158 16.8461 5.94535 15.4504 4.54971C14.0548 3.15409 12.1286 2.29199 9.99984 2.29199Z"
							fill="#EB5757"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M10.0002 15.4163C10.5755 15.4163 11.0418 14.95 11.0418 14.3747C11.0418 13.7994 10.5755 13.333 10.0002 13.333C9.42487 13.333 8.9585 13.7994 8.9585 14.3747C8.9585 14.95 9.42487 15.4163 10.0002 15.4163Z"
							fill="#EB5757"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M10 4.375C10.3452 4.375 10.625 4.65482 10.625 5V11.6667C10.625 12.0118 10.3452 12.2917 10 12.2917C9.65482 12.2917 9.375 12.0118 9.375 11.6667V5C9.375 4.65482 9.65482 4.375 10 4.375Z"
							fill="#EB5757"
						/>
					</svg>
				</span>
				<p>
					{L.translate('Wallets.MarginAccount.messages.not_borrowed_yet', {
						asset: code.toUpperCase(),
					})}
				</p>
			</div>
		</div>
	);
};

export default NoBorrowLabel;
