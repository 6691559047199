import React from 'react';
import Trading from 'layouts/Trading';
import FAQ from 'components/StaticPages/faq';

const FaqPage = () => {
	return (
		<Trading title="FAQ">
			<FAQ />
		</Trading>
	);
};

export default FaqPage;
