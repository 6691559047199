import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import L from 'i18n-react';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getBaseAsset,
	getUnrealisedPnlLoader,
	getUnrealizedPnl,
	getUserStat,
} from 'redux/reducers/perpetual/selectors';
import Loader from 'ui/Loader';
import TransferModal from 'components/Wallet/modals/TransferEnhanced';
import { roundingNumber } from 'services/utils/roundingNumber';
import { getAvailableBalanceRequest } from 'redux/reducers/perpetual/reducer';

const Asset = () => {
	const dispatch = useDispatch();
	const auth = useSelector(getAuthIsAuthenticated);
	const asset = useSelector(getBaseAsset);
	const unrealisedPnl = useSelector(getUnrealizedPnl);
	const unrealisedPnlLoader = useSelector(getUnrealisedPnlLoader);
	const userStat = useSelector(getUserStat);

	const [transferOpened, setTransferOpened] = useState(false);
	const openTransfer = () => {
		setTransferOpened(true);
	};

	const closeTransfer = () => {
		setTransferOpened(false);
		dispatch(getAvailableBalanceRequest());
	};

	return (
		<>
			<div className="asset">
				<div className="asset__title">
					<p>{String(L.translate('Trade.Perpetual.asset_title'))}</p>
					<span onClick={openTransfer} className="cursorPointer">
						{String(L.translate('Trade.Perpetual.funds_transfer_title'))}
					</span>
				</div>
				<div className="asset-list">
					{unrealisedPnlLoader ? (
						<div className="orderbook-loader-wrapper">
							<Loader />
						</div>
					) : (
						<>
							<div className="asset-list__item">
								<p className="grey">{String(L.translate('Trade.Perpetual.total_assets'))}</p>
								<p>{userStat?.total_assets.toFixed(2) || 0}</p>
							</div>
							<div className="asset-list__item">
								<p className="grey">{String(L.translate('Trade.Perpetual.unrealised_pnl'))}</p>
								<p>{roundingNumber(unrealisedPnl?.unrealized_pnl_usdt || 0) || 0}</p>
							</div>
							<div className="asset-list__item">
								<p className="grey">
									{String(L.translate('Trade.Perpetual.cumulative_realised_pnl'))}
								</p>
								<p>{userStat?.cumulative_realised_pnl.toFixed(2) || 0}</p>
							</div>
						</>
					)}
				</div>
			</div>
			{transferOpened && <TransferModal onClose={closeTransfer} />}
		</>
	);
};

export default Asset;
