import React, { FC, useRef, useState } from 'react';
import L from 'i18n-react';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import Input from 'ui/Formik/Input';
import PhoneField from 'ui/Formik/Input/PhoneField';
import { useDispatch } from 'react-redux';
import { setPhoneRequest } from 'redux/reducers/settings/reducer';
import { generateEmailTotpRequest, generateSmsRequest } from 'redux/reducers/auth/reducer';

interface IModalProps {
	closeModal: () => void;
}

const SetPhonePopup: FC<IModalProps> = ({ closeModal }: any) => {
	const dispatch = useDispatch();

	const initialValues = {
		phone_number: '',
		email_code: '',
		sms_code: '',
	};

	const validationSchema = yup.object().shape({
		phone_number: yup
			.string()
			.required(String(L.translate('Account.ChangePhonePopup.messages.please_enter_phone')))
			.matches(
				/^\d{9,15}$/,
				String(L.translate('Account.ChangePhonePopup.messages.please_enter_phone_correct')),
			),
		email_code: yup
			.string()
			.required(String(L.translate('Account.ChangePhonePopup.messages.please_enter_code')))
			.matches(
				/^\d{6}$/,
				String(L.translate('Account.ChangePhonePopup.messages.please_enter_6_numbers_code')),
			),
		sms_code: yup
			.string()
			.required(String(L.translate('Account.ChangePhonePopup.messages.please_enter_code')))
			.matches(
				/^\d{6}$/,
				String(L.translate('Account.ChangePhonePopup.messages.please_enter_6_numbers_code')),
			),
	});

	const [phonePrefix, setPhonePrefix] = useState('1');
	const [codeButton, setCodeButton] = useState(false);
	const phoneNumber = useRef('');

	const onChange = (value: string) => {
		setPhonePrefix(value);
	};
	const onPhoneChange = (value: string) => {
		const phoneRegex = /^\d{9,15}$/;
		const validatePhone = phoneRegex?.exec(value);
		setCodeButton(!!validatePhone);
		phoneNumber.current = value;
	};

	const handleGetPhoneCode = () => {
		dispatch(generateSmsRequest({ phone: `+${phonePrefix + phoneNumber.current}`, update: true }));
	};
	const handleGetEmailCode = () => {
		dispatch(generateEmailTotpRequest());
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--width600">
					<button type="button" className="popup__close" onClick={closeModal}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
								fill="#1C1C29"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
								fill="#1C1C29"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title">
							{String(L.translate('Account.SetPhonePopup.title'))}
						</p>
					</div>
					<div className="popup-body">
						<Formik
							validationSchema={validationSchema}
							initialValues={initialValues}
							onSubmit={(obj, { resetForm, setSubmitting }) => {
								dispatch(
									setPhoneRequest({
										...obj,
										phone_number: `+${phonePrefix + obj.phone_number}`,
									}),
								);
								setSubmitting(false);
								resetForm();
							}}
							enableReinitialize
							validateOnBlur
						>
							{({ isSubmitting, isValid, dirty }) => (
								<Form className="form">
									<div className="select-block__name select-block__name--type3">
										<p>Phone number</p>
									</div>
									<div className="popup-body-item--pr125">
										<PhoneField onChange={onChange} value={phonePrefix}>
											<Field
												type="tel"
												placeholder={String(
													L.translate('Account.SetPhonePopup.placeholders.enter_phone'),
												)}
												name="phone_number"
												required
												customOnChange={onPhoneChange}
												component={Input}
											/>
										</PhoneField>
									</div>
									<div className="popup-body-item">
										<div className="input__name input__name--type3">
											<p>Email code</p>
										</div>
										<Field
											type="text"
											placeholder={String(
												L.translate('Account.SetPhonePopup.placeholders.email_code'),
											)}
											name="email_code"
											codeLength={6}
											required
											getCodeHandler={handleGetEmailCode}
											component={Input}
										/>
									</div>
									<div className="popup-body-item">
										<div className="input__name input__name--type3">
											<p>SMS code</p>
										</div>
										<Field
											type="text"
											placeholder={String(
												L.translate('Account.SetPhonePopup.placeholders.sms_code'),
											)}
											name="sms_code"
											codeLength={6}
											required
											getCodeHandler={handleGetPhoneCode}
											component={Input}
										/>
									</div>
									<div className="popup-footer">
										<div className="popup-submit popup-submit--sb">
											<button
												type="submit"
												disabled={!(isValid && dirty) || isSubmitting}
												className="button button--size2 button--full-width"
											>
												{String(L.translate('Global.confirm'))}
											</button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SetPhonePopup;
