import {
	IBorrowCrossWalletsList,
	IBorrowIsolatedWalletsList,
	IBorrowCrossWalletsResponsePayload,
	IBorrowIsolatedWalletsResponsePayload,
	IRepayCrossWalletsResponsePayload,
	IRepayIsolatedWalletsResponsePayload,
	IInterestCrossWalletsResponsePayload,
	IInterestIsolatedWalletsResponsePayload,
	IRepayCrossWalletsList,
	IRepayIsolatedWalletsList,
	IInterestCrossWalletsList,
	IInterestIsolatedWalletsList,
	ILiquidationWalletsCrossResponsePayload,
	ILiquidationWalletsIsolatedResponsePayload,
	ICallsCrossWalletsResponsePayload,
	ICallsIsolatedWalletsResponsePayload,
	ICallsIsolatedWalletsList,
	ILiquidationIsolatedWalletsList,
} from 'redux/reducers/marginWallets/types';

export function isBorrowIsolated(
	item: IBorrowCrossWalletsResponsePayload | IBorrowIsolatedWalletsResponsePayload | null,
): item is IBorrowIsolatedWalletsResponsePayload {
	return (
		item?.data?.length === 0 || (item?.data as IBorrowIsolatedWalletsList)[0]?.pair !== undefined
	);
}

export function isRepayIsolated(
	item: IRepayCrossWalletsResponsePayload | IRepayIsolatedWalletsResponsePayload | null,
): item is IRepayIsolatedWalletsResponsePayload {
	return (
		item?.data?.length === 0 || (item?.data as IRepayIsolatedWalletsList)[0]?.pair !== undefined
	);
}

export function isInterstIsolated(
	item: IInterestCrossWalletsResponsePayload | IInterestIsolatedWalletsResponsePayload | null,
): item is IInterestIsolatedWalletsResponsePayload {
	return (
		item?.data?.length === 0 || (item?.data as IInterestIsolatedWalletsList)[0]?.pair !== undefined
	);
}

export function isCallsIsolated(
	item: ICallsCrossWalletsResponsePayload | ICallsIsolatedWalletsResponsePayload | null,
): item is ICallsIsolatedWalletsResponsePayload {
	return (
		item?.data?.length === 0 || (item?.data as ICallsIsolatedWalletsList)[0]?.pair !== undefined
	);
}

export function isLiquidationIsolated(
	item: ILiquidationWalletsCrossResponsePayload | ILiquidationWalletsIsolatedResponsePayload | null,
): item is ILiquidationWalletsIsolatedResponsePayload {
	return (
		item?.data?.length === 0 ||
		(item?.data as ILiquidationIsolatedWalletsList)[0]?.pair !== undefined
	);
}
