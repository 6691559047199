import { FC, SyntheticEvent } from 'react';
import L from 'i18n-react';
import { useFormikContext, FormikContextType } from 'formik';
import { useSelector } from 'react-redux';
import { getBaseAsset } from 'redux/reducers/perpetual/selectors';
import { roundingNumber } from 'services/utils/roundingNumber';
import PriceTypeSelect, { PriceType } from '../PriceTypeSelect';
import { IPositionPTSLForm, FormValues } from './types';

const PositionPTSLForm: FC<IPositionPTSLForm> = ({ item, markPrice, onClose }) => {
	const { values, setValues, resetForm, errors }: FormikContextType<FormValues> =
		useFormikContext();
	const baseAsset = useSelector(getBaseAsset);

	const onTpPriceTypeChange = (priceType: string) => {
		setValues({
			...values,
			tpPriceType: priceType as PriceType,
		});
	};

	const onSlPriceTypeChange = (priceType: string) => {
		setValues({
			...values,
			slPriceType: priceType as PriceType,
		});
	};

	const handleTpPriceChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: targetValue } = target;

		setValues({
			...values,
			tpPrice: targetValue,
		});
	};

	const handleSlPriceChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: targetValue } = target;

		setValues({
			...values,
			slPrice: targetValue,
		});
	};

	const handleClose = () => {
		resetForm();
		onClose && onClose();
	};

	const tpPnl =
		values.tpPrice &&
		item.amount_base_asset &&
		item.entry_price &&
		(item.transactions_type === 'buy'
			? item.amount_base_asset * (Number(baseAsset?.last_price || 0) - Number(values.tpPrice))
			: item.amount_base_asset * (Number(values.tpPrice) - Number(baseAsset?.last_price || 0)));

	const slPnl =
		values.slPrice &&
		item.amount_base_asset &&
		item.entry_price &&
		(item.transactions_type === 'buy'
			? item.amount_base_asset * (Number(baseAsset?.last_price || 0) - Number(values.slPrice))
			: item.amount_base_asset * (Number(values.slPrice) - Number(baseAsset?.last_price || 0)));

	return (
		<>
			<div className="popup-body">
				<div className="position-ptsl-pop">
					<div className="position-ptsl-header">
						<div className="position-ptsl-header__item">
							<p className="grey">
								{item.transactions_type === 'buy'
									? String(L.translate('Trade.Perpetual.long'))
									: String(L.translate('Trade.Perpetual.short'))}{' '}
								{item.base_asset?.toUpperCase()}/USDT
							</p>
							<span>{String(L.translate('Trade.Perpetual.Modals.isolated'))}</span>
						</div>
						<div className="position-ptsl-header__item">
							<p className="grey">{String(L.translate('Trade.Perpetual.Positions.entry_price'))}</p>
							<span>{roundingNumber(item.entry_price)}</span>
						</div>
						<div className="position-ptsl-header__item text-align-right">
							<p className="grey">{String(L.translate('Trade.Perpetual.Positions.mark_price'))}</p>
							<span>{roundingNumber(markPrice)}</span>
						</div>
					</div>

					<div className="position-ptsl-item">
						<div className="position-ptsl-item__grid">
							<PriceTypeSelect value={values.tpPriceType} onChange={onTpPriceTypeChange} />
							<label className="orderform-input orderform-input--type2">
								<span className="orderform-input__placeholder">
									{String(L.translate('Trade.Perpetual.Positions.take_profit'))}
								</span>
								<input
									className="orderform-input__data"
									type="text"
									placeholder="0.0"
									value={values.tpPrice}
									onChange={handleTpPriceChange}
								/>
								<span className="orderform-input__placeholder fw-500">USDT</span>
							</label>
							<span className="input-notify">{errors?.tpPriceType}</span>
							<span className="input-notify">{errors?.tpPrice}</span>
						</div>
						<div className="position-ptsl-item__text">
							<p>
								{String(L.translate('Trade.Perpetual.Positions.when_mark_price_hit'))}{' '}
								{+values.tpPrice === 0 || +values.tpPrice ? values.tpPrice : '--'},{' '}
								{String(L.translate('Trade.Perpetual.Positions.position_will_be_closed'))}{' '}
								{tpPnl === 0 || tpPnl ? tpPnl.toFixed(2) : '--'}
							</p>
						</div>
					</div>
					<div className="position-ptsl-item">
						<div className="position-ptsl-item__grid">
							<PriceTypeSelect value={values.slPriceType} onChange={onSlPriceTypeChange} />
							<label className="orderform-input orderform-input--type2">
								<span className="orderform-input__placeholder">
									{String(L.translate('Trade.Perpetual.Positions.stop_loss'))}
								</span>
								<input
									className="orderform-input__data"
									type="text"
									placeholder="0.0"
									value={values.slPrice}
									onChange={handleSlPriceChange}
								/>
								<span className="orderform-input__placeholder fw-500">USDT</span>
							</label>
							<span className="input-notify">{errors?.slPriceType}</span>
							<span className="input-notify">{errors?.slPrice}</span>
						</div>
						<div className="position-ptsl-item__text">
							<p>
								{String(L.translate('Trade.Perpetual.Positions.when_mark_price_hit'))}{' '}
								{+values.slPrice === 0 || +values.slPrice ? values.slPrice : '--'},{' '}
								{String(L.translate('Trade.Perpetual.Positions.position_will_be_closed'))}{' '}
								{slPnl === 0 || slPnl ? slPnl.toFixed(2) : '--'}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="popup-footer popup-footer--item2">
				<button
					className="button button--type3 button--size2 button--full-width "
					type="button"
					onClick={handleClose}
				>
					{String(L.translate('Global.cancel'))}
				</button>
				<button className="button button--size2 button--full-width" type="submit">
					{String(L.translate('Global.confirm'))}
				</button>
			</div>
		</>
	);
};

export type { FormValues };

export default PositionPTSLForm;
