import { FC } from 'react';
import {
	getTimeParserDateFromTimestamp,
	getTimeParserTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import { TTableItem } from './type';

export const TableItem: FC<TTableItem> = ({ item }) => {
	const getClassByStatus = (typeValue: string): string => {
		if (typeValue?.includes('sent')) return 'green fw-700';

		if (typeValue?.includes('buy')) return 'table-info__type table-info__type--green';

		return 'fw-700';
	};
	return (
		<div className="tr">
			<div className="td">
				<p>{item?.email}</p>
			</div>
			<div className="td">
				<p>
					{getTimeParserDateFromTimestamp(item?.created_at)}{' '}
					{getTimeParserTimeFromTimestamp(item?.created_at)}
				</p>
			</div>
			<div className="td td--right">
				<span className={getClassByStatus(item?.status)}>{item?.status}</span>
			</div>
		</div>
	);
};
