import { useSelector } from 'react-redux';
import L from 'i18n-react';
import { getRecentTrades } from 'redux/reducers/options/selectors';
import { BlackScholesService } from 'services/utils/BlackScholes';
import { daysToExpiration } from 'services/utils/daysToExpiration';
import RecentTradesItem from './RecentTradesItem';

/* eslint-disable */
const impVol = require('implied-volatility');

let bs = new BlackScholesService();

const RecentTrades = ({
	id,
	stockPrice,
	strikePrice,
	expDate,
	contract,
}: {
	id: number | undefined;
	stockPrice: number;
	strikePrice: number;
	expDate: any;
	contract: string | undefined;
}) => {
	const recentTrades = useSelector(getRecentTrades);

	const [year, month, day] = expDate?.replace(/[%]/g, '')?.slice(0, 10)?.split('-') || [0, 0, 0];

	const cumulativeMarketVolume = () => {
		if (recentTrades === null) {
			return;
		}
		if (recentTrades[id || 0] === undefined) {
			return 0;
		}
		if (recentTrades) {
			return recentTrades[id || 0].reduce(
				(acc: number, row: any) => acc + row.fill_price * row.amount_asset_filled,
				0,
			);
		}
		return 0;
	};

	const renderItems = () => {
		let price = 0;
		const volume = cumulativeMarketVolume();
		if (recentTrades) {
			if (JSON.stringify(recentTrades[id || 0]) === undefined) {
				return null;
			}
			const reverseTrades = JSON.parse(JSON.stringify(recentTrades[id || 0]));
			let priceChange: 'hold' | 'down' | 'up' = 'hold';
			return (
				reverseTrades
					.reverse()
					?.map((item: any, index: number) => {
						const itemVolume = ((item.fill_price * item.amount_asset_filled) / volume) * 100;
						let volatility;
						if (String(contract)[contract!.length - 1] === 'C') {
							volatility = bs.ivCall(
								stockPrice,
								strikePrice,
								0.001,
								daysToExpiration({ yyyy: year, mm: month, dd: day }),
								Number(item.fill_price),
								0);
						} else { 
							volatility = bs.ivPut(
								stockPrice,
								strikePrice,
								0.001,
								daysToExpiration({ yyyy: year, mm: month, dd: day }),
								Number(item.fill_price),
								0);
						}
						if (volatility === "Unable to calculate IV because entered values are incorrect") {
							volatility = 0;
						}
						if (item.fill_price > price) {
							priceChange = 'up';
						}
						if (item.fill_price < price) {
							priceChange = 'down';
						}
						if (index === 0 || item.fill_price === price) {
							priceChange = 'hold';
						}
						price = item.fill_price;
						return (
							<RecentTradesItem
								volume={itemVolume}
								key={JSON.stringify(item)}
								data={item}
								priceChange={priceChange}
								iv={volatility}
							/>
						);
					})
					.reverse() || null
			);
		}
		return null;
	};

	return (
		<>
			<div className="options-details__title">
				<p>{String(L.translate('Trade.Options.OrderForm.RecentTrades.title'))}</p>
			</div>
			<div className="options-details-recent-trades">
				<div className="orderbook orderbook--type2">
					<div className="orderbook-body">
						<div className="orderbook-body-item">
							<div className="orderbook-body-item__line orderbook-body-item__line--header">
								<span className="grey justify-start">{String(L.translate('Trade.Options.OrderForm.RecentTrades.price'))}</span>
								<span className="grey justify-end">{String(L.translate('Trade.Options.OrderForm.RecentTrades.price'))}($)</span>
								<span className="grey justify-end">{String(L.translate('Trade.Options.OrderForm.RecentTrades.iv'))}</span>
								<span className="grey justify-end">{String(L.translate('Trade.Options.OrderForm.RecentTrades.amount'))}</span>
								<span className="grey justify-end">{String(L.translate('Trade.Options.OrderForm.RecentTrades.time'))}</span>
							</div>
							<div className="orderbook-body-item__scroll options-recenttrades--height">
								{renderItems()}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default RecentTrades;
