import React from 'react';
import Trading from 'layouts/Trading';
import FAQ from 'components/StaticPages/faq';
import SpotMarginEtf from 'components/StaticPages/SpotMarginEtf';

const SpotMarginEtfPage = () => {
	return (
		<Trading title="Spot/Margin/ETF">
			<SpotMarginEtf />
		</Trading>
	);
};

export default SpotMarginEtfPage;
