import { FC, useState, useEffect } from 'react';
import { useLocation } from 'react-router';

export const Confirmated: FC = () => {
	const [userInfo, setUserInfo] = useState<any>(null);
	const location = useLocation();
	useEffect(() => {
		if (location.state) {
			setUserInfo(location.state);
		}
	}, [location]);

	const handleClick = () => {};

	return (
		<div className="container">
			<div className="authorization-box">
				<div className="authorization-form">
					<div className="authorization-form__header">
						<p className="form-title form-title--center">Confirm email</p>
						<div className="form-text form-text--center form-text--fixed-width">
							<p>We’ve sent you the confirmation link to {userInfo?.email}</p>
						</div>
					</div>
					<div className="authorization-form__body">
						<div className="confirm-request">
							<p className="confirm-request__text">
								Didn’t receive email? Please check Spam or{' '}
								<button onClick={handleClick} type="button" className="confirm-request__link">
									request for a new one
								</button>
							</p>
						</div>
					</div>
					<div className="form-footer">
						<button type="button" className="button button--big-height button--full-width">
							Done
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
