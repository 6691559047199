import PersentSlider from 'components/Trade/TradesBox/PersentSlider';
import L from 'i18n-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createOrderRequest } from 'redux/reducers/perpetual/reducer';
import { getBaseAsset, getPerpetualIsolatedLeverage } from 'redux/reducers/perpetual/selectors';
import reduceToNumber from 'services/utils/reduceToValidNumber';
import OrderFormButtons from '../OrderFormButtons';

type ITrigger = 'last_price' | 'index_price' | 'mark_price';

const StopLimitOrderForm = ({ balance, priceFromOrderBook }: any) => {
	const asset = useSelector(getBaseAsset);
	const [percent, setPercent] = useState(0);
	const availableBalance = balance?.available_balance_usdt.toFixed(6) || 0;
	const [stopPrice, setStopPrice] = useState('');
	const [size, setSize] = useState('');
	const [price, setPrice] = useState(String(Number(asset?.last_price).toFixed(2) || '0'));
	const [triggerPrice, setTriggerPrice] = useState<ITrigger>('last_price');
	const dispatch = useDispatch();
	const leverage = useSelector(getPerpetualIsolatedLeverage);

	const handlePercentChange = (value: number | number[]) => {
		if (!Array.isArray(value)) {
			setPercent(value);
			setSize(String((((availableBalance * leverage) / Number(price)) * (value / 100)).toFixed(6)));
		}
	};

	const setInitState = () => {
		setPrice(String(Number(asset?.last_price || 0).toFixed(2)));
		setSize('');
		setTriggerPrice('last_price');
		setPercent(0);
		setStopPrice('');
	};

	const newOrderData = (type: 'buy' | 'sell') => {
		return {
			asset_future_settings_id: asset?.id,
			type_order: 'stop_limit',
			trigger_by_type_price: triggerPrice,
			transactions_type: type,
			amount_asset_request: Number(reduceToNumber(size)),
			leverage_future_size: Number(leverage),
			price_entry_for_order: Number(reduceToNumber(price)).toFixed(2),
			price_entry_for_stop_order: Number(reduceToNumber(stopPrice)).toFixed(2),
		};
	};

	const percentButtonCountValue = (percentValue: number): number => {
		return percentValue / 100;
	};

	const countOrder = (value: number) => {
		// Dont touch. Function needed for slider
	};

	const handleStopPriceChange = (e: any) => {
		const { value } = e.target;
		if (!value) {
			setStopPrice('');
		}
		if (/^\d+[.,]?\d*$/.test(value)) {
			setStopPrice(value);
		}
	};

	const handleSizeChange = (e: any) => {
		const { value } = e.target;
		const result = reduceToNumber(value);
		if (!value) {
			setSize('');
			setPercent(0);
		}
		if (/^\d+[.,]?\d*$/.test(value)) {
			setSize(value);
			setPercent((Number(result) * Number(price) * 100) / (availableBalance * leverage));
		}
	};

	const handlePriceChange = (e: any) => {
		const { value } = e.target;
		const result = reduceToNumber(value);
		if (!value) {
			setPrice('');
		}
		if (/^\d+[.,]?\d*$/.test(value)) {
			if (Number(value) === 0) {
				setPrice(value);
				return;
			}
			setPrice(value);
			setSize(
				String(((((availableBalance * leverage) / Number(result)) * percent) / 100).toFixed(6)),
			);
		}
	};

	const handleBuyClick = () => {
		dispatch(createOrderRequest(newOrderData('buy')));
		setInitState();
	};

	const handleSellClick = () => {
		dispatch(createOrderRequest(newOrderData('sell')));
		setInitState();
	};

	const allowLongOrShort = (type: string) => {
		if (Number(price) !== 0) {
			if (type === 'long') {
				const value = ((availableBalance * leverage) / Number(reduceToNumber(price))).toFixed(6);
				return `${value} ${asset?.base_asset.toUpperCase() || ''}`;
			}
			const value = ((availableBalance * leverage) / Number(reduceToNumber(price))).toFixed(6);
			return `${value} ${asset?.base_asset.toUpperCase() || ''}`;
		}
		return 0;
	};

	const handleTriggerPriceChange = (e: any) => {
		const { name } = e.target;
		if (name === 'last_price' || name === 'index_price' || name === 'mark_price') {
			setTriggerPrice(name);
		}
	};

	useEffect(() => {
		// If we clicked on some row in orderbook then price field will get value of that row
		if (priceFromOrderBook) {
			setPrice(String(Number(priceFromOrderBook).toFixed(2)));
		}
	}, [priceFromOrderBook]);

	useEffect(() => {
		if (size === '0.000000') {
			setSize('');
		}
	}, [size]);

	useEffect(() => {
		// If we changed leverage and size value is bigger than balance*leverage then set size to max value
		if (Number(size) > (availableBalance * leverage) / Number(asset!.last_price)) {
			setSize(String((availableBalance * leverage) / asset!.last_price));
		}
	}, [leverage, size, availableBalance, asset]);

	return (
		<div className="orderform-content-item">
			<div className="orderform-content-item-top">
				<div className="orderform-input-title">
					<p>{String(L.translate('Trade.avbl'))}</p>
					<p>
						{availableBalance} <span className="grey">USDT</span>
					</p>
				</div>
				<div className="table-nav flex flex--gap4" onClick={handleTriggerPriceChange}>
					<button
						className={`button button--width-auto button--size3 ${
							triggerPrice === 'last_price' ? '' : 'button--type11'
						}`}
						type="button"
						name="last_price"
					>
						{String(L.translate('Trade.Perpetual.OrderForm.last_price_field_placeholder'))}
					</button>
					<button
						className={`button button--width-auto button--size3 ${
							triggerPrice === 'mark_price' ? '' : 'button--type11'
						}`}
						type="button"
						name="mark_price"
					>
						{String(L.translate('Trade.Perpetual.OrderForm.mark_price_field_placeholder'))}
					</button>
				</div>
				<label className="orderform-input">
					<span className="orderform-input__placeholder">
						{String(L.translate('Trade.Perpetual.OrderForm.stop_field_placeholder'))}
					</span>
					<input
						className="orderform-input__data"
						type="text"
						placeholder="0.0"
						value={stopPrice}
						onChange={handleStopPriceChange}
					/>
					<span className="orderform-input__placeholder fw-700">USDT</span>
				</label>
				<label className="orderform-input">
					<span className="orderform-input__placeholder">
						{String(L.translate('Trade.Perpetual.OrderForm.price_field_placeholder'))}
					</span>
					<input
						className="orderform-input__data"
						type="text"
						placeholder="0.0"
						value={price}
						onChange={handlePriceChange}
					/>
					<span className="orderform-input__placeholder fw-700">USDT</span>
				</label>
				<label className="orderform-input">
					<span className="orderform-input__placeholder">
						{String(L.translate('Trade.Perpetual.OrderForm.size_field_placeholder'))}
					</span>
					<input
						className="orderform-input__data"
						type="text"
						placeholder="0.0"
						value={size}
						onChange={handleSizeChange}
					/>
					<span className="orderform-input__placeholder fw-700">
						{asset?.base_asset.toUpperCase() || '-'}
					</span>
				</label>
				<div className="slider-wrapper">
					<PersentSlider
						value={percent}
						onChange={handlePercentChange}
						countOrder={countOrder}
						percentButtonCountValue={percentButtonCountValue}
					/>
				</div>
				<div className="orderform-item">
					<div className="orderform-item__title">
						<p>{String(L.translate('Trade.Perpetual.OrderForm.available_title'))}</p>
					</div>
				</div>
			</div>

			<div className="orderform-content-item-bottom">
				<div className="orderform-content-item-bottom__title">
					<p>{String(L.translate('Trade.Perpetual.OrderForm.type_title'))}</p>
				</div>
				<OrderFormButtons buyClick={handleBuyClick} sellClick={handleSellClick} />
				<div className="orderform-content-item-info">
					<p>
						{String(L.translate('Trade.Perpetual.OrderForm.allow_long'))}{' '}
						<span>{allowLongOrShort('long')}</span>
					</p>
					<p>
						{String(L.translate('Trade.Perpetual.OrderForm.allow_short'))}{' '}
						<span>{allowLongOrShort('short')}</span>
					</p>
				</div>
			</div>
		</div>
	);
};
export default StopLimitOrderForm;
