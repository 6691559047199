import { FC } from 'react';
import { IOrderBookItemProps } from './types';

const MAXWIDTH = 85;

const OrderBookItem: FC<IOrderBookItemProps> = ({ colour, item, maxTotal }) => {
	const amount = item?.amount_asset_request_left || 0;
	const price = item?.price_entry_for_order || 0;
	const total = item?.total || 0;

	const width = MAXWIDTH * (Number(item?.total || 1) / Number(maxTotal || 1));

	return (
		<div className="orderbook-body-item__line" data-item={price}>
			<span data-item={price} className={colour}>
				{price}
			</span>
			<span data-item={price}>{amount}</span>
			<span data-item={price} className="text-align-right">
				{total}
			</span>
			<span
				data-item={price}
				className={`orderbook-body-item__line-progres ${
					colour === 'green' ? 'orderbook-body-item__line-progres--green' : ''
				}`}
				style={{ width, maxWidth: MAXWIDTH }}
			/>
		</div>
	);
};

export default OrderBookItem;
