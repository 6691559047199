/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */
import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext, FormikContextType, Form, Field } from 'formik';
import L from 'i18n-react';
import { getUsersSettings } from 'redux/reducers/settings/selectors';
import { generateSmsRequest } from 'redux/reducers/auth/reducer';
import useTimer from 'hooks/useTimer';
import { Values, IConfirmWithdrawForm } from './types';

const ConfirmWithdrawForm: FC<IConfirmWithdrawForm> = ({
	actionType,
	receiveAmount,
	assetCode,
	networkFee,
	address,
	network,
	networkName,
}) => {
	const dispatch = useDispatch();
	const userSettings = useSelector(getUsersSettings);
	const { values, setValues, isValid, errors, isSubmitting }: FormikContextType<Values> =
		useFormikContext();
	const [timerValue, startTimer] = useTimer();

	const handleGetCode = () => {
		dispatch(generateSmsRequest({}));
		startTimer();
	};

	const handleCodeChange = (e: any) => {
		const { value } = e.target;
		if (value?.length <= 6) {
			setValues({ code: value });
		} else {
			const { code } = values;
			setValues({ code });
		}
	};

	useEffect(() => {
		setValues({
			code: '',
		});
	}, [actionType, setValues]);

	const confirmDisabled = useMemo(() => {
		if (actionType && ['2fa-sms', '2fa-google'].includes(actionType)) {
			return !values.code || !isValid;
		}
		return false;
	}, [actionType, isValid, values.code]);

	return (
		<Form>
			<div className="popup-body">
				<div className="popup-body-item">
					<div className="withdrawal-info-pop">
						<p>
							{String(L.translate('WithdrawCrypto.amount'))}{' '}
							<span>
								{String(L.translate('WithdrawCrypto.amount'))}{' '}
								{String(
									L.translate('recieve_receive_amount', {
										receiveAmount,
										assetCode,
										networkFee,
									}),
								)}
								)
							</span>
						</p>
						<p>
							{String(L.translate('WithdrawCrypto.address'))} <span>{address}</span>
						</p>
						<p>
							{String(L.translate('WithdrawCrypto.network'))}{' '}
							<span>
								{network} ({networkName})
							</span>
						</p>
						<p>
							{String(L.translate('WithdrawCrypto.source'))}{' '}
							<span>{String(L.translate('WithdrawCrypto.spot_wallet'))}</span>
						</p>
					</div>

					{actionType === 'confirm' && (
						<div className="withdrawal-info-list-pop">
							<ul>
								<li>{String(L.translate('WithdrawCrypto.Messages.ensure_address_is_correct'))}</li>
								<li>{String(L.translate('WithdrawCrypto.Messages.transactions_cannot_be_cancelled'))}</li>
							</ul>
						</div>
					)}

					{actionType && ['2fa-sms', '2fa-google'].includes(actionType) && (
						<div className={actionType === '2fa-sms' ? 'security-verification' : ''}>
							<div className="input">
								<div className="input-wrapper">
									<Field
										name="code"
										required
										onChange={handleCodeChange}
										placeholder="Authenticator Code"
										component="input"
										className="input-item"
									/>
									{errors?.code && <span className="input-notify">* {errors?.code}</span>}
								</div>
								<div className="input__notification input__notification--type2">
									<p>{String(L.translate('WithdrawCrypto.Messages.enter_6_digit_code_sent_to', { phone: userSettings?.data.phone }))}</p>
								</div>
							</div>
							{actionType === '2fa-sms' && (
								<button
									type="button"
									className="button button--size2 button--full-width"
									onClick={handleGetCode}
									disabled={!!timerValue}
								>
									{timerValue ? `${timerValue} sec` : 'Get code'}
								</button>
							)}
						</div>
					)}
				</div>
			</div>

			<div className="popup-footer">
				<button
					type="submit"
					className="button button--size2 button--full-width"
					disabled={confirmDisabled}
				>
					{String(L.translate('WithdrawCrypto.confirm_btn'))}
				</button>
			</div>
		</Form>
	);
};

export default ConfirmWithdrawForm;
