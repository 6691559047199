import { useHistory } from 'react-router';
import { Auth } from 'layouts/Auth';
import L from 'i18n-react';

const EmailIsTaken = () => {
	const history = useHistory();
	const handleConfirmClick = () => {
		history.push('/registration');
	};

	return (
		<Auth title="CheckEmail">
			<div className="authorization-section">
				<div className="authorization-section__container">
					<div className="authorization">
						<div className="authorization__icon">
							<svg
								width="56"
								height="56"
								viewBox="0 0 56 56"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M11.5959 5.76256C12.2793 5.07915 13.3874 5.07915 14.0708 5.76256L28 19.6918L41.9292 5.76256C42.6126 5.07915 43.7207 5.07915 44.4041 5.76256L50.2374 11.5959C50.9209 12.2793 50.9209 13.3874 50.2374 14.0708L36.3082 28L50.2374 41.9292C50.9209 42.6126 50.9209 43.7207 50.2374 44.4041L44.4041 50.2374C43.7207 50.9209 42.6126 50.9209 41.9292 50.2374L28 36.3082L14.0708 50.2374C13.3874 50.9209 12.2793 50.9209 11.5959 50.2374L5.76256 44.4041C5.07915 43.7207 5.07915 42.6126 5.76256 41.9292L19.6918 28L5.76256 14.0708C5.07915 13.3874 5.07915 12.2793 5.76256 11.5959L11.5959 5.76256ZM9.47487 12.8333L23.4041 26.7626C24.0875 27.446 24.0875 28.554 23.4041 29.2374L9.47487 43.1667L12.8333 46.5251L26.7626 32.5959C27.446 31.9125 28.554 31.9125 29.2374 32.5959L43.1667 46.5251L46.5251 43.1667L32.5959 29.2374C31.9125 28.554 31.9125 27.446 32.5959 26.7626L46.5251 12.8333L43.1667 9.47487L29.2374 23.4041C28.554 24.0875 27.446 24.0875 26.7626 23.4041L12.8333 9.47487L9.47487 12.8333Z"
									fill="#EB5757"
								/>
							</svg>
						</div>
						<p className="authorization__title authorization__title--center">
							{String(L.translate('EmailIsTaken.title_0'))}
						</p>
						<div className="authorization__details authorization__details--center">
							<p> {String(L.translate('EmailIsTaken.title_1'))}</p>
						</div>
						<div className="form-submit">
							<button
								type="button"
								className="button button--full-width button--size2"
								onClick={handleConfirmClick}
							>
								{String(L.translate('EmailIsTaken.button'))}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Auth>
	);
};

export default EmailIsTaken;
