import { FC, useState } from 'react';
import L from 'i18n-react';
import DatePicker from 'react-datepicker';
import DatepickerSearch from 'ui/DatepickerSearch';
import { IFilteredBarProps } from './types';
import 'react-datepicker/dist/react-datepicker.css';

const FilteredBar: FC<IFilteredBarProps> = ({
	dateRange: defaultDataRange,
	handleSearch,
	currentClass,
}) => {
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(
		defaultDataRange || [null, null],
	);
	const [startDateV, endDateV] = dateRange;
	const onDatePickerChange = (update: [Date | null, Date | null]) => {
		if (Array.isArray(update)) {
			setDateRange(update);
		}
	};

	const handleSearchClick = () => {
		if (endDateV === null) {
			dateRange[1] = startDateV;
		}
		handleSearch(dateRange);
	};

	return (
		<div className={currentClass}>
			<div className="table-date-filter__item">
				<div className="input input--no-mb">
					<div className="input-wrapper">
						<DatePicker
							customInput={<DatepickerSearch />}
							selectsRange
							startDate={startDateV}
							endDate={endDateV}
							placeholderText="Select start and end date..."
							onChange={onDatePickerChange}
							isClearable
							dateFormat="yyyy.MM.dd"
							selectsStart
							maxDate={new Date()}
						/>
						<div className="input-icon input-icon--left">
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2.50033 3.95801C2.38527 3.95801 2.29199 4.05128 2.29199 4.16634V17.4997C2.29199 17.6147 2.38527 17.708 2.50033 17.708H17.5003C17.6154 17.708 17.7087 17.6147 17.7087 17.4997V4.16634C17.7087 4.05128 17.6154 3.95801 17.5003 3.95801H2.50033ZM1.04199 4.16634C1.04199 3.36093 1.69491 2.70801 2.50033 2.70801H17.5003C18.3057 2.70801 18.9587 3.36093 18.9587 4.16634V17.4997C18.9587 18.3051 18.3057 18.958 17.5003 18.958H2.50033C1.69491 18.958 1.04199 18.3051 1.04199 17.4997V4.16634Z"
									fill="#9D9DBC"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M1.04199 8.33301C1.04199 7.98783 1.32181 7.70801 1.66699 7.70801H18.3337C18.6788 7.70801 18.9587 7.98783 18.9587 8.33301C18.9587 8.67819 18.6788 8.95801 18.3337 8.95801H1.66699C1.32181 8.95801 1.04199 8.67819 1.04199 8.33301Z"
									fill="#9D9DBC"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M1.04199 13.333C1.04199 12.9878 1.32181 12.708 1.66699 12.708H18.3337C18.6788 12.708 18.9587 12.9878 18.9587 13.333C18.9587 13.6782 18.6788 13.958 18.3337 13.958H1.66699C1.32181 13.958 1.04199 13.6782 1.04199 13.333Z"
									fill="#9D9DBC"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7.08301 1.04199C7.42819 1.04199 7.70801 1.32181 7.70801 1.66699V5.00033C7.70801 5.3455 7.42819 5.62533 7.08301 5.62533C6.73783 5.62533 6.45801 5.3455 6.45801 5.00033V1.66699C6.45801 1.32181 6.73783 1.04199 7.08301 1.04199Z"
									fill="#9D9DBC"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12.917 1.04199C13.2622 1.04199 13.542 1.32181 13.542 1.66699V5.00033C13.542 5.3455 13.2622 5.62533 12.917 5.62533C12.5718 5.62533 12.292 5.3455 12.292 5.00033V1.66699C12.292 1.32181 12.5718 1.04199 12.917 1.04199Z"
									fill="#9D9DBC"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7.08301 7.70801C7.42819 7.70801 7.70801 7.98783 7.70801 8.33301V18.333C7.70801 18.6782 7.42819 18.958 7.08301 18.958C6.73783 18.958 6.45801 18.6782 6.45801 18.333V8.33301C6.45801 7.98783 6.73783 7.70801 7.08301 7.70801Z"
									fill="#9D9DBC"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12.917 7.70801C13.2622 7.70801 13.542 7.98783 13.542 8.33301V18.333C13.542 18.6782 13.2622 18.958 12.917 18.958C12.5718 18.958 12.292 18.6782 12.292 18.333V8.33301C12.292 7.98783 12.5718 7.70801 12.917 7.70801Z"
									fill="#9D9DBC"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M18.333 4.79199C18.6782 4.79199 18.958 5.07181 18.958 5.41699V16.2503C18.958 16.5955 18.6782 16.8753 18.333 16.8753C17.9878 16.8753 17.708 16.5955 17.708 16.2503V5.41699C17.708 5.07181 17.9878 4.79199 18.333 4.79199Z"
									fill="#9D9DBC"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M1.66699 4.79199C2.01217 4.79199 2.29199 5.07181 2.29199 5.41699V16.2503C2.29199 16.5955 2.01217 16.8753 1.66699 16.8753C1.32181 16.8753 1.04199 16.5955 1.04199 16.2503V5.41699C1.04199 5.07181 1.32181 4.79199 1.66699 4.79199Z"
									fill="#9D9DBC"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M5.20801 18.333C5.20801 17.9878 5.48783 17.708 5.83301 17.708H14.1663C14.5115 17.708 14.7913 17.9878 14.7913 18.333C14.7913 18.6782 14.5115 18.958 14.1663 18.958H5.83301C5.48783 18.958 5.20801 18.6782 5.20801 18.333Z"
									fill="#9D9DBC"
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<div className="table-date-filter__item">
				<button
					className="button button--regular button--size2"
					type="button"
					onClick={handleSearchClick}
				>
					{String(L.translate('Trade.Spot.search'))}
				</button>
			</div>
		</div>
	);
};

export default FilteredBar;
