export enum ETwoFaOption {
	APP = 'app',
	PHONE = 'phone',
}

export type TModalTwoFaOption = {
	onClose: () => void;
	onConfirm: (type: ETwoFaOption) => void;
};

export type TFormTwoFa = {
	onClose: () => void;
};
