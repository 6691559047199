/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/naming-convention */
import L from 'i18n-react';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetPairsList } from 'redux/reducers/assetPairs/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { createOrderRequest } from 'redux/reducers/orders/reducer';
import { getTempOrderPrice } from 'redux/reducers/orders/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';

import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { numberValidation } from 'services/utils/numberValidation';
import { convertToFixed } from 'services/utils/convertToFixed';
import LoginOrRegister from 'ui/LoginOrRegister';
import PersentSlider from 'components/Trade/TradesBox/PersentSlider';
import reduceToValidNumber from 'services/utils/reduceToValidNumber';
import { ILimitOrderProps } from './types';

const LimitOrder: FC<ILimitOrderProps> = ({
	mode,
	assetToTradeCode,
	assetBalanceCode,
	balance,
	marketType,
}) => {
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const currentPair = useSelector(getCurrentPair);
	// const assetPairsFeesAndLimit = useSelector(getAssetPairsFeesAndLimitsList);
	const tempOrderPrice = useSelector(getTempOrderPrice);
	const assetPairsList = useSelector(getAssetPairsList);

	const dispatch = useDispatch();

	const { decimals, quantity_decimals } = assetPairsList?.find((item) => item.id === currentPair.id)
		?.setting || { decimals: 10, quantity_decimals: 10 };

	const cutNumberToDecimals = (num: number | string, dec: number) => {
		let res = num;
		if (typeof res === 'number') {
			res = String(num);
		}
		const afterComa =
			String(reduceToValidNumber(res)).split('.')?.[1]?.slice(0, Math.abs(dec)) || '0';
		const fullNumber = [String(reduceToValidNumber(res)).split('.')[0], afterComa].join('.');
		return Number(fullNumber);
	};

	// const currentAssetPairLimits = useMemo(() => {
	// 	return assetPairsFeesAndLimit?.length
	// 		? assetPairsFeesAndLimit.find((pair) => pair.code === currentPair.pair)
	// 		: null;
	// }, [assetPairsFeesAndLimit, currentPair]);
	// const FunAmountLimitDeleteE = () => {
	// 	if (currentAssetPairLimits?.amount_min.toString().includes('e') === true) {
	// 		const numberFix = Number(currentAssetPairLimits?.amount_min.toString().split('-')[1]);
	// 		currentAssetPairLimits?.amount_min.toFixed(Number(numberFix));
	// 		return currentAssetPairLimits?.amount_min.toFixed(Number(numberFix));
	// 	}
	// 	return currentAssetPairLimits?.amount_min || 0;
	// };
	// const currentAssetPairLimitsAmountMin = FunAmountLimitDeleteE();
	// const currentAssetPairLimitsAmountMax = currentAssetPairLimits?.amount_max || 0;
	const initState = {
		order_type_id: 4,
		assets_pairs_id: Number(currentPair.id),
		quantity: '',
		price: '',
		direction: mode.toLowerCase(),
	};

	const [limitOrderState, setLimitOrderState] = useState({ ...initState });
	const [percent, setPercent] = useState(0);
	const [total, setTotal] = useState('');

	const isDisabled =
		!Number(limitOrderState.price) || !Number(limitOrderState.quantity) || !Number(total);

	const pairCrossCourse = () => {
		return assetPairsList?.find((pair) => {
			return pair.code === currentPair.pair;
		})?.last_price;
	};

	// const isMaxAmountError =
	// 	authIsAuthenticated &&
	// 	Number(limitOrderState.quantity) &&
	// 	Number(limitOrderState.quantity) > currentAssetPairLimitsAmountMax;

	const isMaxAmountSellError =
		authIsAuthenticated &&
		Number(limitOrderState.quantity) &&
		mode === 'Sell' &&
		Number(limitOrderState.quantity) > balance;

	// const isMaxAmountBuyError =
	// 	authIsAuthenticated &&
	// 	Number(limitOrderState.quantity) &&
	// 	mode === 'Buy' &&
	// 	Number(limitOrderState.quantity) > balance;
	// const isMinAmountError =
	// 	authIsAuthenticated &&
	// 	Number(limitOrderState.quantity) &&
	// 	Number(limitOrderState.quantity) < currentAssetPairLimitsAmountMin;

	const isBuyTotalError =
		authIsAuthenticated && mode === 'Buy' && Number(total) && Number(total) > balance;

	useEffect(() => {
		setLimitOrderState({
			...initState,
			assets_pairs_id: Number(currentPair.id),
			// pair_code: currentPair,
		});
		setTotal('');
	}, [currentPair]);

	useEffect(() => {
		setLimitOrderState({
			...initState,
			price: String(tempOrderPrice || ''),
		});
	}, [tempOrderPrice]);

	useEffect(() => {
		if (!limitOrderState.price || !limitOrderState.quantity) return;
		setTotal(
			convertToFixed(
				Number(reduceToValidNumber(limitOrderState.price)) *
					Number(reduceToValidNumber(limitOrderState.quantity)),
			),
		);
	}, [limitOrderState]);

	useEffect(() => {
		const { quantity, price } = limitOrderState;

		if (
			authIsAuthenticated &&
			currentPair &&
			Number(quantity) &&
			Number(price) &&
			mode &&
			!isDisabled &&
			!isBuyTotalError &&
			!isMaxAmountSellError
		) {
			// const calculateRequestData = {
			// pair_code: initState.pair_code,
			// quantity: Number(quantity),
			// price: Number(price),
			// type: mode.toLowerCase(),
			// market_type: marketType,
			// };
			// dispatch(calculateLimitOrderRequest(calculateRequestData));
		}
	}, [currentPair, limitOrderState, mode]);

	const handleChangeTotal = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		value === '' && setTotal(value);

		if (/^\d+[.,]?\d*$/.test(value) && Number(limitOrderState.price) > 0) {
			setTotal(value);

			if (value[value.length - 1] !== '.' && value[value.length - 1] !== ',') {
				setLimitOrderState({
					...limitOrderState,
					quantity: convertToFixed(Number(value) / Number(limitOrderState.price) || 0),
				});
			}
		}
	};

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;

		if (!value) {
			setLimitOrderState({
				...limitOrderState,
				[name]: '',
			});
		}
		if (numberValidation(value)) {
			setLimitOrderState({
				...limitOrderState,
				[name]: value,
			});
			if (name === 'price' && limitOrderState.quantity) {
				setTotal(convertToFixed(Number(limitOrderState.quantity) * Number(value)));
			}

			if (name === 'quantity' && limitOrderState.price) {
				let newPercent;
				if (mode === 'Buy') {
					newPercent = (Number(limitOrderState.price) * (Number(value) * 100)) / balance;
				} else {
					newPercent = (Number(value) * 100) / balance;
				}
				console.log('Percent', newPercent);
				setPercent(newPercent);
				setTotal(convertToFixed(Number(limitOrderState.price) * Number(value)));
			}
		}
	};

	const handleTradeClick = () => {
		const { quantity, price } = limitOrderState;

		if (quantity && price) {
			// if (marketType === 'cross' || marketType === 'isolated') {
			// 	const createOrderData = {
			// 		...limitOrderState,
			// 		quantity: Number(quantity),
			// 		price: Number(price),
			// 		[`${marketType === 'cross' ? 'is_margin' : 'is_isolated'}`]: true,
			// 	};
			// 	// dispatch(createOrderRequest(createOrderData));
			// 	setLimitOrderState({
			// 		...initState,
			// 	});
			// 	setTotal('');
			// 	return;
			// }
			let marketTypeOrder = 4;
			if (marketType === 'isolated') {
				marketTypeOrder = 6;
			}
			const createOrderData = {
				...limitOrderState,
				quantity: Number(cutNumberToDecimals(Number(quantity), quantity_decimals)),
				price: Number(cutNumberToDecimals(Number(price), decimals)),
				order_type_id: marketTypeOrder,
			};

			dispatch(createOrderRequest(createOrderData));
		}

		setLimitOrderState({
			...initState,
		});
		setTotal('');
	};

	// TODO: Delete unused code. Branch 1EX_DEV-171

	// const countOrder = (value: number) => {
	// 	debugger; // eslint-disable-line no-debugger

	// 	if (limitOrderState.price && value >= 0) {
	// 		if (value > currentAssetPairLimitsAmountMax || value < currentAssetPairLimitsAmountMin) {
	// 			setLimitOrderState({
	// 				...limitOrderState,
	// 				quantity: '', // TODO needed current exchange course
	// 			});
	// 		} else {
	// 			setLimitOrderState({
	// 				...limitOrderState,
	// 				quantity: String(fixedCropNumber(Number(value), 6)),
	// 			});
	// 		}

	// 		setTotal(String(fixedCropNumber(Number(limitOrderState.price) * value, 6)));
	// 	} else if (mode === 'Sell') {
	// setLimitOrderState({
	// 	...limitOrderState,
	// 	quantity: String(fixedCropNumber(Number(value), 6)),
	// });
	// 	}
	// };

	const countOrder = (value: number) => {
		if (mode === 'Buy') {
			if (limitOrderState.price === '') {
				const lastPrice = pairCrossCourse();
				if (lastPrice) {
					setLimitOrderState({
						...limitOrderState,
						price: String(lastPrice),
						quantity: String(fixedCropNumber(Number(value) / lastPrice, 6)),
					});
					return;
				}
			}
			setLimitOrderState({
				...limitOrderState,
				quantity: String(fixedCropNumber(Number(value), 6)),
			});
			return;
		}
		if (mode === 'Sell') {
			if (limitOrderState.price === '') {
				setLimitOrderState({
					...limitOrderState,
					price: String(pairCrossCourse()),
					quantity: String(fixedCropNumber(Number(value), 6)),
				});
				return;
			}
			setLimitOrderState({
				...limitOrderState,
				quantity: String(fixedCropNumber(Number(value), 6)),
			});
		}
	};

	const percentButtonCountValue = (percentValue: number): number => {
		if (!balance || !Number(percentValue)) {
			return 0;
		}

		if (mode === 'Buy' && Number(limitOrderState?.price)) {
			return ((balance / Number(limitOrderState?.price)) * percentValue) / 100;
		}
		console.log(balance);
		return (balance * percentValue) / 100;
	};

	// const getFieldClass = (fieldName: string): string => {
	// 	switch (fieldName) {
	// 		case 'amount':
	// 			return isMaxAmountError || isMinAmountError || isMaxAmountSellError
	// 				? 'trade-form__input input-form__item--error'
	// 				: 'trade-form__input';
	//
	// 		case 'total':
	// 			return isBuyTotalError && mode === 'Buy'
	// 				? 'trade-form__input input-form__item--error'
	// 				: 'trade-form__input';
	//
	// 		default:
	// 			return 'trade-form__input';
	// 	}
	// };

	const handlePercentChange = (value: number | number[]) => {
		if (!Array.isArray(value)) {
			setPercent(value);
		}
	};

	return (
		<>
			<label className="orderform-input">
				<span className="orderform-input__placeholder">
					{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.price_field_name')}
				</span>
				<input
					type="text"
					className="orderform-input__data"
					placeholder="0.00"
					autoComplete="off"
					value={limitOrderState.price}
					name="price"
					onChange={handleChangeInput}
				/>
				<span className="orderform-input__placeholder fw-700">
					{assetBalanceCode?.toUpperCase()}
				</span>
			</label>
			<label className="orderform-input">
				<span className="orderform-input__placeholder">
					{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.amount_field_name')}
				</span>
				<input
					className="orderform-input__data"
					type="text"
					placeholder="0.00"
					autoComplete="off"
					value={limitOrderState.quantity}
					name="quantity"
					onChange={handleChangeInput}
				/>
				<span className="orderform-input__placeholder fw-700">
					{assetToTradeCode?.toUpperCase()}
				</span>
			</label>
			{/* {isMaxAmountError ? ( */}
			{/*	<TextError> */}
			{/*		{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.max_amount_error_text')}{' '} */}
			{/*		{currentAssetPairLimitsAmountMax} {assetToTradeCode?.toUpperCase()} */}
			{/*	</TextError> */}
			{/* ) : null} */}

			{/* {isMinAmountError ? (
				<TextError>
					{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.min_amount_error_text')}{' '}
					{currentAssetPairLimitsAmountMin} {assetToTradeCode?.toUpperCase()}
				</TextError>
			) : null}
			{isMaxAmountSellError ? (
				<TextError>
					{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.amount_available_balance_error_text')}
				</TextError>
			) : null}
			<PercentRadioButtons
				countOrder={countOrder}
				percentButtonCountValue={percentButtonCountValue}
				mode={mode}
				amount={limitOrderState.quantity}
				precents={[0, 0.25, 0.5, 0.75, 1]}
			/> */}
			<div className="orderform-slider">
				<PersentSlider
					value={percent}
					onChange={handlePercentChange}
					countOrder={countOrder}
					percentButtonCountValue={percentButtonCountValue}
				/>
			</div>
			<label className="orderform-input">
				<span className="orderform-input__placeholder">
					{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.total_field_name')}
				</span>
				<input
					className="orderform-input__data"
					type="text"
					placeholder="0.00"
					autoComplete="off"
					name="total"
					onChange={handleChangeTotal}
					value={total}
				/>
				<span className="orderform-input__placeholder fw-700">
					{assetBalanceCode?.toUpperCase()}
				</span>
			</label>
			{/* {authIsAuthenticated && isBuyTotalError && mode === 'Buy' ? (
				<TextError>{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.total_error_text')}</TextError>
			) : null} */}
			{authIsAuthenticated ? (
				<button
					className={`button button--full-width  ${
						mode === 'Buy' ? 'button--type12' : 'button--type13'
					} trade-form__btn`}
					type="button"
					onClick={handleTradeClick}
					disabled={isDisabled}
				>
					{mode === 'Buy'
						? L.translate('Trade.Spot.SpotTradeBox.OrderButtons.buy_button')
						: L.translate('Trade.Spot.SpotTradeBox.OrderButtons.sell_button')}{' '}
					{assetToTradeCode?.toUpperCase()}
				</button>
			) : (
				<div className="create-order-btn-login-register-wrapper">
					<LoginOrRegister />
				</div>
			)}
		</>
	);
};

export default LimitOrder;
