import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import L from 'i18n-react';
import Loader from 'ui/Loader';
import { twoFaCheckEnabledRequest, twoFaInitState } from 'redux/reducers/twoFa/reducer';
import { getTwoFaEnableStatus, getTwoFaLoading } from 'redux/reducers/twoFa/selectors';
import { ETwoFaEnabledStatus } from 'redux/reducers/twoFa/types';
import { generateSmsRequest } from 'redux/reducers/auth/reducer';
import GetCodeButton from 'ui/GetCodeButton/GetCodeButton';
import { useDispatch, useSelector } from 'react-redux';
import { IForgotPassSubmitValue } from '../types';

interface ITwoFaFormPopup {
	email: string;
	twoFaFormPopup: boolean;
	closeTwoFaFormPopup: () => void;
	emailSubmit: (values: IForgotPassSubmitValue) => void;
}

const TwoFaFormPopup: FC<ITwoFaFormPopup> = ({
	email,
	emailSubmit,
	twoFaFormPopup,
	closeTwoFaFormPopup,
}) => {
	const dispatch = useDispatch();
	const twoFaLoading = useSelector(getTwoFaLoading);
	const twoFaEnableStatus = useSelector(getTwoFaEnableStatus);
	const [code, setCode] = useState('');
	const [codeValidation, setCodeValidation] = useState(false);
	const changeCodeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
		const regex = /^\d{6}$/;
		setCode(event.target.value);
		setCodeValidation(!!regex?.exec(event.target.value));
	};

	useEffect(() => {
		if (twoFaFormPopup) {
			dispatch(twoFaCheckEnabledRequest(email));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [twoFaFormPopup]);
	useEffect(() => {
		if (twoFaEnableStatus === ETwoFaEnabledStatus.TWO_FA_DISABLED) {
			emailSubmit({
				email,
			});
			closeTwoFaFormPopup();
			dispatch(twoFaInitState());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [twoFaEnableStatus]);

	const handleGetPhoneCode = () => {
		dispatch(generateSmsRequest({ email }));
	};

	const confirmHandler = () => {
		emailSubmit({
			email,
			totp: code,
		});
		closeTwoFaFormPopup();
	};

	return (
		<div className={`${twoFaFormPopup ? 'popup-window' : ''}`}>
			<Popup open={twoFaFormPopup} onClose={closeTwoFaFormPopup} closeOnDocumentClick closeOnEscape>
				<div className="popup-window__inside">
					{!twoFaLoading ? (
						<div className="popup popup--width670">
							<button type="button" className="popup__close" onClick={closeTwoFaFormPopup}>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
										fill="#1C1C29"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
										fill="#1C1C29"
									/>
								</svg>
							</button>
							<div className="popup-header">
								<p className="popup-header__title">
									{String(L.translate('Forms.Auth2faPopup.title'))}
								</p>
							</div>
							<div className="popup-body">
								<div className="popup-text">
									{twoFaEnableStatus === ETwoFaEnabledStatus.SMS_TWO_FA_ENABLED && (
										<p>
											{String(
												L.translate('Forms.Auth2faPopup.fill_the_field_below_to_send_your_phone'),
											)}
										</p>
									)}
									{twoFaEnableStatus === ETwoFaEnabledStatus.GOOGLE_TWO_FA_ENABLED && (
										<p>
											{String(
												L.translate('Forms.Auth2faPopup.fill_the_field_below_from_your_auth_app'),
											)}
										</p>
									)}
								</div>
								<div className="popup-body-item">
									<div className="input">
										<div className="input-wrapper">
											<input
												type="text"
												className="input-item input-item--pr125"
												placeholder="Please enter the 6-digit code"
												onChange={changeCodeInputValue}
												value={code}
											/>
											{twoFaEnableStatus === ETwoFaEnabledStatus.SMS_TWO_FA_ENABLED && (
												<GetCodeButton onClick={handleGetPhoneCode} />
											)}
										</div>
									</div>
								</div>
								<div className="popup-footer">
									<div className="popup-submit popup-submit--sb">
										<button
											type="submit"
											disabled={!codeValidation}
											onClick={confirmHandler}
											className="button button--size2 button--full-width"
										>
											Confirm
										</button>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div
							className="popup popup--width670"
							style={{ display: 'flex', justifyContent: 'center' }}
						>
							<Loader />
						</div>
					)}
				</div>
			</Popup>
		</div>
	);
};

export default TwoFaFormPopup;
