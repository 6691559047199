import { FC } from 'react';
import L from 'i18n-react';
import UserTradesTable from '../UserTradesTable';
import { IUserTradesProps } from './types';

const UserTrades: FC<IUserTradesProps> = ({ data }) => {
	return data?.data?.length ? (
		<UserTradesTable data={data.data} />
	) : (
		<div>{String(L.translate('Trade.Spot.no_data'))}</div>
	);
};

export default UserTrades;
