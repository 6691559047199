import SocketWithTopicConnectWrapper from 'components/SocketWithTopicConnectWrapper';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import {
	getOrderbookTradesRequest,
	getStatisticRequest,
	updateCreateOrder,
} from 'redux/reducers/options/reducer';
import {
	getCreateOrder,
	getLastPrice,
	getOptionStatistics,
	getStatisticLoader,
} from 'redux/reducers/options/selectors';
import AssetStatistics from './AssetStatistics';
import CloseModalButton from './CloseModalButton';
import ContractDetails from './ContractDetails';
import ModalHeader from './ModalHeader';
import OrderBook from './OrderBookTable';
import OrderForm from './OrderForm';
import RecentTrades from './RecentTradesTable';

const BuySellModal = () => {
	const dispatch = useDispatch();
	const order = useSelector(getCreateOrder);
	// const statistic = useSelector(getStatisticData);
	const statistic = useSelector(getOptionStatistics);
	const lastPrice = useSelector(getLastPrice);

	const loader = useSelector(getStatisticLoader);

	const closeModal = () => {
		document.documentElement.style.overflowY = 'auto';
		dispatch(updateCreateOrder(null));
	};

	useEffect(() => {
		if (order) {
			document.documentElement.style.overflowY = 'hidden';
			dispatch(getOrderbookTradesRequest({ option_id: order?.id }));
			dispatch(getStatisticRequest({ option_id: order?.id }));
		}
	}, [dispatch, order]);

	return (
		<SocketWithTopicConnectWrapper
			topicName={String(`opened_user_orders_option:option:${order ? order.id : ''}`)}
		>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--width1280">
						<CloseModalButton closeModal={() => closeModal()} />
						<ModalHeader data={order?.contract_name} />
						<div className="popup-body">
							<div className="options-details-body">
								<div className="options-details-body-item">
									<OrderForm
										id={order?.id}
										contract={order?.contract_name}
										markPrice={order?.mark_price || 0}
										stockPrice={lastPrice || 0}
										strikePrice={order?.strike_price || 0}
										expDate={order?.date_expires}
									/>
									<ContractDetails data={order} />
								</div>
								<div className="options-details-body-item">
									<div className="options-details__title">
										<p>{String(L.translate('Trade.Options.Table.order_book'))}</p>
									</div>
									<SocketWithTopicConnectWrapper topicName="header_option_statistic:option">
										<AssetStatistics data={statistic} />
									</SocketWithTopicConnectWrapper>
									<SocketWithTopicConnectWrapper topicName="order_book:option:btc">
										<OrderBook />
									</SocketWithTopicConnectWrapper>
									<SocketWithTopicConnectWrapper topicName="option_recent_trades:option">
										<RecentTrades
											id={order?.id}
											contract={order?.contract_name}
											stockPrice={lastPrice || 0}
											strikePrice={order?.strike_price || 0}
											expDate={order?.date_expires}
										/>
									</SocketWithTopicConnectWrapper>
								</div>
							</div>
						</div>
						<div className="popup-footer" />
					</div>
				</div>
			</div>
		</SocketWithTopicConnectWrapper>
	);
};

export default BuySellModal;
