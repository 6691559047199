import { FC } from 'react';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { ITradeHistoryTabItem } from './types';

const TradeHistoryTabItem: FC<ITradeHistoryTabItem> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td">
				<p>{item.contract?.toUpperCase()}/USDT</p>
			</div>
			<div className="td">
				<p>{item.order_id}</p>
			</div>
			<div className="td">
				<p>{item.size}</p>
			</div>
			<div className="td">
				<p>{item.file_price}</p>
			</div>
			<div className="td">
				<p>{item.role}</p>
			</div>
			<div className="td td--right">
				<p>{item.created_at && customDateFormat(new Date(item.created_at), true)}</p>
			</div>
		</div>
	);
};
export default TradeHistoryTabItem;
