import { FC, useCallback, useEffect, useState, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import L from 'i18n-react';
import { Formik, Form } from 'formik';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import { api } from 'services';
import yup from 'services/utils/capsLockValidate';
import { notificationContainer } from 'services/utils/notificationContainer';
import BorrowContentCross from './BorrowContentCross';
import BorrowContentIsolated from './BorrowContentIsolated';
import { IBorrowModalFormIsolated, BorrowInfo } from './BorrowContentIsolated/types';
import { IBorrowModal } from './types';

const BorrowModal: FC<IBorrowModal> = ({ onClose, item, assetId, assetPairId, mode, refetch }) => {
	const dispatch = useDispatch();
	const [isFetching, setIsFetching] = useState(false);
	const [state, setState] = useReducer(
		(_state: BorrowInfo, newState: BorrowInfo) => ({
			..._state,
			...newState,
		}),
		{},
	);
	const isAuth = useSelector(getAuthIsAuthenticated);
	const history = useHistory();
	const [balance, setBalance] = useState<number>(0);
	const amountMin = item?.pair?.setting?.amount_min;

	const handleClose = () => {
		onClose && onClose();
	};

	const fetchBorrowInfo: (
		params: Pick<IBorrowModalFormIsolated, 'assetId' | 'assetPairId'>,
	) => void = useCallback(({ assetId: _assetId, assetPairId: _assetPairId }) => {
		setIsFetching(true);
		api.marginWallets
			.getBorrowIsolated({
				asset_id: _assetId,
				asset_pair_id: _assetPairId,
			})
			.then((response) => {
				setIsFetching(false);
				const { borrowed, hourlyInterestRate, maxBorrowAmount } = response;
				setState({
					borrowed,
					interestRate: +hourlyInterestRate,
					maxBorrowAmount: +maxBorrowAmount,
				});
				// TODO: Тут будем записывать даные с сервера в setState
			})
			.catch((err) => {
				setIsFetching(false);
				notificationContainer(
					String(L.translate('Wallets.MarginAccount.messages.failed_to_fetch_borrow_info')),
					'error',
				);
			});
	}, []);

	useEffect(() => {
		if (assetPairId || assetPairId === 0) {
			fetchBorrowInfo({ assetId, assetPairId });
		}
	}, [assetId, assetPairId, fetchBorrowInfo]);

	useEffect(() => {
		dispatch(getAssetsRequest());
	}, [dispatch]);

	const initialValues = {
		asset_pair_id: assetPairId,
		asset_id: assetId,
		amount: '',
		percent: 0,
	};

	const validationSchema = yup.object().shape({
		amount: yup
			.number()
			.min(
				Number(amountMin),
				String(
					L.translate('Wallets.MarginAccount.messages.amount_not_less_than', {
						minAmount: String(Number(amountMin)),
					}),
				),
			)
			.max(
				Number(state.maxBorrowAmount),
				String(
					L.translate('Wallets.MarginAccount.messages.amount_not_greater_than', {
						maxAmount: String(Number(state.maxBorrowAmount)),
					}),
				),
			)
			.typeError(String(L.translate('Wallets.MarginAccount.messages.amount_must_be_a_number')))
			.required(String(L.translate('Wallets.MarginAccount.messages.amount_is_required'))),
	});

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					validateOnBlur
					onSubmit={async (values, { resetForm }) => {
						if (!isAuth) {
							history.push('/login');
							return;
						}
						// eslint-disable-next-line @typescript-eslint/naming-convention
						const { asset_pair_id, asset_id } = values;
						try {
							if (mode === 'isolated') {
								if (!asset_pair_id) {
									throw new Error(
										String(L.translate('Wallets.MarginAccount.messages.asset_pair_id_is_required')),
									);
								}
								if (!balance) {
									throw new Error(
										String(
											L.translate(
												'Wallets.MarginAccount.messages.failed_to_confirm_the_borrowing_you_need_to_replenish_your_margine_balance',
											),
										),
									);
								}
								await api.marginWallets.postBorrowIsolated({
									asset_pair_id,
									asset_id,
									amount: Number(values.amount),
								});
							} else {
								await api.marginWallets.takeBorrowCross({
									asset_id,
									amount: Number(values.amount),
								});
							}
							notificationContainer(
								String(L.translate('Wallets.MarginAccount.messages.borrow_was_confirmed')),
								'success',
							);
							resetForm();
							onClose && onClose();
							refetch && refetch();
						} catch (error) {
							notificationContainer(
								String(L.translate('Wallets.MarginAccount.messages.failed_to_confirm_borrow')),
								'error',
							);
						}
					}}
				>
					<Form>
						{mode === 'isolated' ? (
							assetPairId && (
								<BorrowContentIsolated
									amountMin={amountMin}
									onClose={handleClose}
									assetPairId={assetPairId}
									assetId={assetId}
									mode={mode}
									setBalance={setBalance}
									borrowInfo={state}
									isFetching={isFetching}
									refetch={fetchBorrowInfo}
								/>
							)
						) : (
							<BorrowContentCross onClose={handleClose} assetId={assetId} mode={mode} />
						)}
					</Form>
				</Formik>
			</div>
		</div>
	);
};

export default BorrowModal;
