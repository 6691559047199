import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import L from 'i18n-react';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import EditOrderModal from 'components/Trade/OptionTrades/Modals/EditOrderModal';
import CancelOrderModal from 'components/Trade/OptionTrades/Modals/CancelOrderModal';
import { useGetOptionsMap } from 'components/Trade/OptionTrades/TradeTables/hooks';
import { getShowCancelOrderConfirm } from 'redux/reducers/options/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { deleteActiveOrder } from 'redux/reducers/options/reducer';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { numberExists } from 'services/utils/numberValidation';
import { IActiveOrderTabItem } from './types';

const ActiveOrderTabItem: FC<IActiveOrderTabItem> = ({ item }) => {
	const dispatch = useDispatch();
	const isAuth = useSelector(getAuthIsAuthenticated);
	const history = useHistory();
	const [editModalOpened, setEditModallOpened] = useState(false);
	const [cancelModalOpened, setCancelModalOpened] = useState(false);
	const [isCancelSubmitting, setIsCancelSubmittimg] = useState(false);

	const showCancelOrder = useSelector(getShowCancelOrderConfirm);
	const optionsMap = useGetOptionsMap();

	const option = item?.option_id && optionsMap?.[item?.option_id];

	let direction: string | null = null;
	let directionColor: string | null = null;
	if (item.transactions_type === 'buy') {
		direction = 'Buy';
		directionColor = 'green';
	}
	if (item.transactions_type === 'sell') {
		direction = 'Sell';
		directionColor = 'red';
	}

	const openEditModal = () => {
		setEditModallOpened(true);
	};

	const closeEditeModal = () => {
		setEditModallOpened(false);
	};

	const closeCancelModal = () => {
		setCancelModalOpened(false);
	};

	const handleCancelOrderSubmit = async () => {
		if (!isAuth) {
			history.push('/login');
			return;
		}
		try {
			setIsCancelSubmittimg(true);
			const deletedItem = await api.optionsTrade.cancelOrder({
				order_option_id: item.id,
			});
			dispatch(deleteActiveOrder(deletedItem));
			setIsCancelSubmittimg(false);
			notificationContainer(
				String(L.translate('Trade.Options.messages.order_was_canceled')),
				'success',
			);
			setCancelModalOpened(false);
		} catch (error) {
			setIsCancelSubmittimg(false);
			notificationContainer(
				String(L.translate('Trade.Options.messages.failed_to_close_order')),
				'error',
			);
			console.error(error);
		}
	};

	const handleCancelClick = async () => {
		if (showCancelOrder) {
			setCancelModalOpened(true);
			return;
		}
		await handleCancelOrderSubmit();
	};

	const createdAt = (Number(item.created_at) && customDateFormat(Number(item.created_at))) || '';

	return (
		<SocketWithPrivateTopicConnectWrapper
			topicName={String(`closed_user_orders_option:option:${item.option_id}`)}
		>
			<div className="tr-wrap">
				<div className="tr">
					<div className="td td--left">
						<p className="td-hidden-name">Contract</p>
						<p className="upper-case">{item.contract_name?.toUpperCase()}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Direction</p>
						<p className={directionColor || ''}>{direction}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Order Price</p>
						<p>
							{numberExists(item.price_entry_for_order)
								? item.price_entry_for_order?.toFixed(2).replace(/\.00$/, '')
								: '-'}
						</p>
						<span>
							{numberExists(item?.iv_coefficient) &&
								`${Number(item?.iv_coefficient)?.toFixed(2).replace(/\.00$/, '')}%`}
						</span>
					</div>
					<div className="td">
						<p className="td-hidden-name">Qty (BTC)</p>
						<p>{numberExists(item.amount_asset_request) ? item.amount_asset_request : '-'}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Filled Qty (BTC)</p>
						<p>
							{numberExists(item.amount_exequted_in_asset) ? item.amount_exequted_in_asset : '-'}
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Unfilled Qty (BTC)</p>
						<p>
							{numberExists(item.amount_asset_request_left) ? item.amount_asset_request_left : '-'}
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Initial Margin</p>
						<p>{numberExists(item.im_coefficient) ? item.im_coefficient?.toFixed(0) : '-'}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Order ID</p>
						<p>{item.id}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Order Type</p>
						<p className="capitalize">{item.type_order}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Order Time</p>
						<p>{createdAt?.split(' ')?.[0] || '-'}</p>
						<span>{createdAt?.split(' ')?.[1] || '-'}</span>
					</div>
					<div className="td">
						<p className="td-hidden-name">Time in Force</p>
						<p>GTC</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Action</p>
						<div className="flex flex-e flex--gap8">
							<button
								type="button"
								className="button button--type-text button--type-text-fz12 fw-400"
								onClick={openEditModal}
							>
								Edit
							</button>
							<button
								type="button"
								className="button button--type-text button--type-text-fz12 fw-400"
								// eslint-disable-next-line @typescript-eslint/no-misused-promises
								onClick={handleCancelClick}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
				{editModalOpened && item && (
					<EditOrderModal strikePrice={option.strike_price} item={item} onClose={closeEditeModal} />
				)}
				{cancelModalOpened && showCancelOrder && (
					<CancelOrderModal
						loader={isCancelSubmitting}
						onClose={closeCancelModal}
						onSubmit={handleCancelOrderSubmit}
					/>
				)}
			</div>
		</SocketWithPrivateTopicConnectWrapper>
	);
};

export default ActiveOrderTabItem;
