import { FC, useEffect, useState, useMemo, SyntheticEvent } from 'react';
import L from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import useSelect from 'hooks/useSelect';
import { getBaseAssetStatisticsRequest, setBaseAsset } from 'redux/reducers/perpetual/reducer';
import { setCurrentPair } from 'redux/reducers/tradingSettings/reducer';
import { getPerpetualBaseAssets, getBaseAsset } from 'redux/reducers/perpetual/selectors';
import { IBaseAssetItem } from 'redux/reducers/perpetual/types';
import { IBaseAssetResponse } from 'redux/reducers/perpetual/types';
import PerpetualSelectItem from './PerpetualSelectItem';

const PerpetualSelect: FC = () => {
	const dispatch = useDispatch();
	const [search, setSearch] = useState('');
	const [priceSort, setPriceSort] = useState<'asc' | 'desc' | null>(null);
	const [changeSort, setChangeSort] = useState<'asc' | 'desc' | null>(null);

	const handleSearchInput = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: targetValue } = target;
		setSearch(targetValue);
	};

	const handlePriceSort = () => {
		let newPriceSort: 'asc' | 'desc' | null = null;
		switch (priceSort) {
			case null:
				newPriceSort = 'asc';
				break;
			case 'asc':
				newPriceSort = 'desc';
				break;
			default:
				newPriceSort = null;
				break;
		}
		setPriceSort(newPriceSort);
	};

	const handleChangeSort = () => {
		let newChangeSort: 'asc' | 'desc' | null = null;
		switch (changeSort) {
			case null:
				newChangeSort = 'asc';
				break;
			case 'asc':
				newChangeSort = 'desc';
				break;
			default:
				newChangeSort = null;
				break;
		}
		setChangeSort(newChangeSort);
	};

	const baseAssets = useSelector(getPerpetualBaseAssets);
	const baseAsset = useSelector(getBaseAsset);

	const { open, setOpen, toggleOpen, currentRef, dropRef } = useSelect();

	useEffect(() => {
		dispatch(getBaseAssetStatisticsRequest());
	}, [dispatch]);

	const handleSelectItem = (item: IBaseAssetItem) => {
		dispatch(setBaseAsset(item));
		setOpen(false);
	};

	useEffect(() => {
		dispatch(
			setCurrentPair({ pair: `${String(baseAsset?.base_asset)}_usdt`, id: Number(baseAsset?.id) }),
		);
	}, [baseAsset, dispatch]);

	const filteredBaseAssets = useMemo(() => {
		let result: IBaseAssetResponse | null = baseAssets ? [...baseAssets] : null;
		if (search) {
			const searchFormatted = search
				.toLowerCase()
				.split('')
				.map((symb: string) => {
					if (symb === ' ' || symb === '/') {
						return null;
					}
					return symb;
				})
				.filter(Boolean)
				.join('');
			result =
				result?.filter((item) =>
					`${item?.base_asset}usdt`.toLowerCase().includes(searchFormatted),
				) || null;
		}
		if (result && priceSort) {
			result.sort((a, b) => {
				if (a.last_price > b.last_price) {
					return priceSort === 'asc' ? 1 : -1;
				}
				if (a.last_price < b.last_price) {
					return priceSort === 'asc' ? -1 : 1;
				}
				return 0;
			});
		}
		return result;
	}, [baseAssets, priceSort, search]);

	return (
		<div className="select-block perpetual-select">
			<div className={`select select--trade-header ${open ? ' active' : ''}`}>
				<button className="select__current" type="button" onClick={toggleOpen} ref={currentRef}>
					<span className="trade-select-currensy">
						<span className="trade-select-currensy__title">{`${
							baseAsset?.base_asset?.toUpperCase() || ''
						}/USDT ${String(L.translate('Global.perpetual'))}`}</span>
					</span>
					<span className="select__current-arrow">
						<svg
							width="10"
							height="6"
							viewBox="0 0 10 6"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M10 0.333496H1H0L5 5.3335L10 0.333496Z" fill="#1C1C29" />
						</svg>
					</span>
				</button>
				<div className="select__drop select--shadow" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="trade-drop">
							<div className="trade-drop__title">
								<p>{String(L.translate('Trade.Perpetual.Header.margined'))} USDT</p>
							</div>
							<div className="input input--no-mb">
								<div className="input-wrapper">
									<input
										className="input-item input-item--h40"
										type="text"
										placeholder="Search"
										value={search}
										onChange={handleSearchInput}
									/>
									<div className="input-icon input-icon--right">
										<svg
											width="15"
											height="15"
											viewBox="0 0 15 15"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M0.78125 6.5625C0.78125 3.36962 3.36962 0.78125 6.5625 0.78125C9.75538 0.78125 12.3438 3.36962 12.3438 6.5625C12.3438 9.75538 9.75538 12.3438 6.5625 12.3438C3.36962 12.3438 0.78125 9.75538 0.78125 6.5625ZM6.5625 1.71875C3.88738 1.71875 1.71875 3.88738 1.71875 6.5625C1.71875 9.23762 3.88738 11.4062 6.5625 11.4062C9.23762 11.4062 11.4062 9.23762 11.4062 6.5625C11.4062 3.88738 9.23762 1.71875 6.5625 1.71875Z"
												fill="#9D9DBC"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M4.46348 4.15075C5.00022 3.61404 5.74305 3.28125 6.5627 3.28125C7.38236 3.28125 8.12519 3.61404 8.66191 4.15076C8.84497 4.33382 8.84497 4.63062 8.66191 4.81367C8.47885 4.99673 8.18206 4.99673 7.999 4.81367C7.63091 4.44559 7.12374 4.21875 6.5627 4.21875C6.00167 4.21875 5.49449 4.44558 5.12637 4.81369C4.9433 4.99674 4.64651 4.99673 4.46345 4.81366C4.2804 4.6306 4.28041 4.3338 4.46348 4.15075Z"
												fill="#9D9DBC"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M10.0504 10.0504C10.2334 9.86732 10.5302 9.86732 10.7133 10.0504L13.3649 12.702C13.548 12.8851 13.548 13.1819 13.3649 13.3649C13.1819 13.548 12.8851 13.548 12.702 13.3649L10.0504 10.7133C9.86732 10.5302 9.86732 10.2334 10.0504 10.0504Z"
												fill="#9D9DBC"
											/>
										</svg>
									</div>
								</div>
							</div>
							<div className="trade-drop-list">
								<div className="trade-drop-list__line trade-drop-list__line--header">
									<span className="grey">
										{String(L.translate('Trade.Perpetual.Header.contract'))}
									</span>
									<button
										className="td-sorting-arrow-button grey"
										type="button"
										onClick={handlePriceSort}
									>
										{String(L.translate('Trade.Perpetual.Header.price'))}
										<span className="td-sorting-arrow">
											<span
												className={`td-sorting-arrow__item${priceSort === 'asc' ? ' active' : ''}`}
											>
												<svg
													width="6"
													height="3"
													viewBox="0 0 6 3"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path d="M0 3L5.4 3L6 3L3 -2.62268e-07L0 3Z" fill="#9D9DBC" />
												</svg>
											</span>
											<span
												className={`td-sorting-arrow__item${priceSort === 'desc' ? ' active' : ''}`}
											>
												<svg
													width="6"
													height="3"
													viewBox="0 0 6 3"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path d="M6 0H0.6H0L3 3L6 0Z" fill="#9D9DBC" />
												</svg>
											</span>
										</span>
									</button>
									<button
										className="td-sorting-arrow-button grey"
										type="button"
										onClick={handleChangeSort}
									>
										{String(L.translate('Trade.Perpetual.Header.change'))}
										<span className="td-sorting-arrow">
											<span
												className={`td-sorting-arrow__item${changeSort === 'asc' ? ' active' : ''}`}
											>
												<svg
													width="6"
													height="3"
													viewBox="0 0 6 3"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path d="M0 3L5.4 3L6 3L3 -2.62268e-07L0 3Z" fill="#9D9DBC" />
												</svg>
											</span>
											<span
												className={`td-sorting-arrow__item${
													changeSort === 'desc' ? ' active' : ''
												}`}
											>
												<svg
													width="6"
													height="3"
													viewBox="0 0 6 3"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path d="M6 0H0.6H0L3 3L6 0Z" fill="#9D9DBC" />
												</svg>
											</span>
										</span>
									</button>
									<span className="grey text-align-right">
										{String(L.translate('Trade.Perpetual.Header.amount'))}USDⓈ
									</span>
								</div>
								<div className="trade-drop-list__scroll">
									{filteredBaseAssets?.map((item) => (
										<PerpetualSelectItem key={item.id} item={item} onClick={handleSelectItem} />
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default PerpetualSelect;
