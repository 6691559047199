import { FC } from 'react';
import { IWalletSummary, IWalletSummaryItem } from './types';

const WalletSummary: FC<IWalletSummary> = ({ items }) => {
	return (
		<div className="wallet-content-balance flex">
			{items.map((item, idx) => (
				// eslint-disable-next-line react/no-array-index-key
				<div key={`${item.title}-${idx}`} className="wallet-content-balance-item">
					<div className="wallet-content-balance-item__title">
						<p>{item.title}</p>
					</div>
					<div className="wallet-content-balance-item__value">
						<p>
							{item.btcValue} <span>BTC</span>
						</p>
					</div>
					<div className="wallet-content-balance-item__user-currensy">
						<p>≈ ${item.usdValue}</p>
					</div>
				</div>
			))}
		</div>
	);
};

export type { IWalletSummary, IWalletSummaryItem };
export default WalletSummary;
