import { FC, useEffect, useState } from 'react';
import L from 'i18n-react';
import PersentSlider from 'components/Trade/TradesBox/PersentSlider';
import { IAdjustLeveragePopup } from './types';

const AdjustLeveragePopup: FC<IAdjustLeveragePopup> = ({
	leverage,
	setLeverage,
	onSubmit,
	onClose,
	setOldLeverage,
}) => {
	useEffect(() => {
		setOldLeverage(leverage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePercentChange = (value: number | number[]) => {
		if (!Array.isArray(value)) {
			setLeverage(value);
		}
	};

	// TODO: Пока что обязательна, нужно усовершенствовать логику PersentSlider
	const countOrder = (value: number) => {};

	const percentButtonCountValue = (leverageValue: number): number => {
		return leverageValue;
	};

	const incrementLeverage = () => {
		if (leverage <= 49) {
			setLeverage((prev) => +prev + 1);
		}
	};

	const decrementLeverage = () => {
		if (leverage >= 2) {
			setLeverage((prev) => prev - 1);
		}
	};

	const closePopup = () => {
		onClose && onClose();
	};

	const handleSubmit = () => {
		onSubmit();
		closePopup();
	};

	const handleLeverageChange = (e: any) => {
		const { value } = e.target;
		if (!value) {
			setLeverage(1);
		}
		if (/^\d\d*$/.test(value)) {
			if (value > 50) {
				setLeverage(50);
				return;
			}
			if (value < 1) {
				setLeverage(1);
				return;
			}
			setLeverage(value);
		}
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup">
					<button className="popup__close" type="button" onClick={closePopup}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
								fill="#1C1C29"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
								fill="#1C1C29"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title">
							{String(L.translate('Trade.Perpetual.AdjustLeverageModal.title'))}
						</p>
					</div>
					<div className="popup-body">
						<div className="adjust-leverage-pop">
							{/* TODO: Не удалять, возможно понадобится */}
							{/* <div className="adjust-leverage-title">
								<p>{String(L.translate('Trade.Perpetual.AdjustLeverageModal.leverage_mode'))}</p>
								<button className="button button--type2" type="button">
									{String(L.translate('Trade.Perpetual.isolated'))}
								</button>
							</div> */}
							<div className="popup-body-item">
								<PersentSlider
									min={1}
									max={50}
									values={[1, 25, 50]}
									unitLabel="X"
									markStyles={{ fontSize: 12 }}
									value={leverage}
									onChange={handlePercentChange}
									countOrder={countOrder}
									percentButtonCountValue={percentButtonCountValue}
								/>
							</div>
							<div className="popup-body-item">
								<div className="input-number">
									<button
										className="input-number__button"
										type="button"
										onClick={decrementLeverage}
									>
										<svg
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M3.75 10C3.75 9.65482 4.02982 9.375 4.375 9.375H16.0417C16.3868 9.375 16.6667 9.65482 16.6667 10C16.6667 10.3452 16.3868 10.625 16.0417 10.625H4.375C4.02982 10.625 3.75 10.3452 3.75 10Z"
												fill="#FF57D4"
											/>
										</svg>
									</button>
									<input
										className="input-number-item"
										type="number"
										value={leverage}
										onChange={handleLeverageChange}
									/>
									<button
										className="input-number__button input-number__button--plus"
										type="button"
										onClick={incrementLeverage}
									>
										<svg
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M10.0259 3.54163C10.3711 3.54208 10.6505 3.82227 10.6501 4.16745L10.6348 15.8341C10.6343 16.1793 10.3541 16.4587 10.0089 16.4583C9.66377 16.4578 9.38431 16.1777 9.38477 15.8325L9.40006 4.16581C9.40051 3.82063 9.6807 3.54117 10.0259 3.54163Z"
												fill="#FF57D4"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M3.5415 10C3.5415 9.65482 3.82133 9.375 4.1665 9.375H15.8332C16.1783 9.375 16.4582 9.65482 16.4582 10C16.4582 10.3452 16.1783 10.625 15.8332 10.625H4.1665C3.82133 10.625 3.5415 10.3452 3.5415 10Z"
												fill="#FF57D4"
											/>
										</svg>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="popup-footer">
						<button
							className="button button--size2 button--full-width"
							type="button"
							onClick={handleSubmit}
						>
							{String(L.translate('Trade.Perpetual.confirm'))}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
export default AdjustLeveragePopup;
