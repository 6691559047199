import { FC } from 'react';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import Footer from 'layouts/Footer';
import { Header } from 'layouts-elements/Header';
// import { MobNav } from 'layouts-elements/MobNav';
import { getTheme } from 'redux/reducers/settings/selectors';
import { TPageProps } from './types';

// ==========================================:
export const Page: FC<TPageProps> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';

	const theme = useSelector(getTheme);

	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet>
			<div className={`wrapper ${theme}`}>
				{/* <MobNav /> */}
				<Header />
				<main className="main">{children}</main>
				<Footer />
			</div>
		</>
	);
};
