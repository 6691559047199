export interface IStatusSelect {
	label: string;
	value?: TransactionStatuses | null;
	onChange?: (type: TransactionStatuses | null) => void;
}

export enum TransactionStatuses {
	Pending = 1,
	Processing = 2,
	Completed = 3,
	Canceled = 4,
}
