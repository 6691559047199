import { customDateFormat } from 'services/utils/dateAndTimeHelpers';

const RecentTradesItem = ({
	priceChange,
	data,
	volume,
	iv,
}: {
	priceChange: 'up' | 'down' | 'hold';
	data: any;
	volume: number;
	iv: string | number;
}) => {
	const renderDate = () => {
		const date = customDateFormat(Number(data.created_at)).split(' ');
		return (
			<>
				<strong className="grey">{date[1]}</strong> {date[0]}
			</>
		);
	};

	const getStyles =
		priceChange === 'down'
			? { width: `${volume.toFixed(2)}%` }
			: { width: `${volume.toFixed(2)}%`, background: '#219653', opacity: '0.1' };

	const renderImpliedVolatility = () => {
		if (String(iv).includes('e')) {
			const ivArr = String(iv).split('e');
			const result = [];
			result.push(Number(ivArr[0]).toFixed(3));
			result.push(ivArr[1]);
			return result.join('e');
		}
		return iv;
	};

	return (
		<div className="orderbook-body-item__line">
			<span
				className={`justify-start ${
					priceChange === 'up' || priceChange === 'hold' ? 'green' : 'red'
				}`}
			>
				{priceChange === 'down' && (
					<>
						<svg
							width="15"
							height="16"
							viewBox="0 0 15 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M7.5 14.0938C7.24112 14.0938 7.03125 13.8839 7.03125 13.625L7.03125 2.375C7.03125 2.11612 7.24112 1.90625 7.5 1.90625C7.75888 1.90625 7.96875 2.11612 7.96875 2.375L7.96875 13.625C7.96875 13.8839 7.75888 14.0938 7.5 14.0938Z"
								fill="#EB5757"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M7.83146 13.9565C7.6484 14.1395 7.3516 14.1395 7.16854 13.9565L3.41854 10.2065C3.23549 10.0234 3.23549 9.7266 3.41854 9.54354C3.6016 9.36049 3.8984 9.36049 4.08146 9.54354L7.5 12.9621L10.9185 9.54354C11.1016 9.36049 11.3984 9.36049 11.5815 9.54354C11.7645 9.7266 11.7645 10.0234 11.5815 10.2065L7.83146 13.9565Z"
								fill="#EB5757"
							/>
						</svg>
					</>
				)}
				{priceChange === 'up' && (
					<>
						<svg
							width="15"
							height="16"
							viewBox="0 0 15 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M7.5 1.90625C7.75888 1.90625 7.96875 2.11612 7.96875 2.375V13.625C7.96875 13.8839 7.75888 14.0938 7.5 14.0938C7.24112 14.0938 7.03125 13.8839 7.03125 13.625V2.375C7.03125 2.11612 7.24112 1.90625 7.5 1.90625Z"
								fill="#219653"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M7.16854 2.04354C7.3516 1.86049 7.6484 1.86049 7.83146 2.04354L11.5815 5.79354C11.7645 5.9766 11.7645 6.2734 11.5815 6.45646C11.3984 6.63951 11.1016 6.63951 10.9185 6.45646L7.5 3.03791L4.08146 6.45646C3.8984 6.63951 3.6016 6.63951 3.41854 6.45646C3.23549 6.2734 3.23549 5.9766 3.41854 5.79354L7.16854 2.04354Z"
								fill="#219653"
							/>
						</svg>
					</>
				)}
				{priceChange === 'hold' && (
					<>
						<svg
							width="15"
							height="22"
							viewBox="0 0 15 22"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M2.37492 5.93941C1.85924 6.19725 1.71875 6.46673 1.71875 6.625C1.71875 6.78327 1.85924 7.05275 2.37492 7.31059C2.86257 7.55441 3.56905 7.71875 4.375 7.71875C5.18095 7.71875 5.88743 7.55441 6.37508 7.31059C6.89076 7.05275 7.03125 6.78327 7.03125 6.625C7.03125 6.46673 6.89076 6.19725 6.37508 5.93941C5.88743 5.69559 5.18095 5.53125 4.375 5.53125C3.56905 5.53125 2.86257 5.69559 2.37492 5.93941ZM1.95566 5.10088C2.59904 4.77919 3.45506 4.59375 4.375 4.59375C5.29494 4.59375 6.15096 4.77919 6.79434 5.10088C7.40968 5.40855 7.96875 5.92033 7.96875 6.625C7.96875 7.32967 7.40968 7.84144 6.79434 8.14912C6.15096 8.47081 5.29494 8.65625 4.375 8.65625C3.45506 8.65625 2.59904 8.47081 1.95566 8.14912C1.34032 7.84144 0.78125 7.32967 0.78125 6.625C0.78125 5.92033 1.34032 5.40855 1.95566 5.10088Z"
								fill="#219653"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M1.25 6.15625C1.50888 6.15625 1.71875 6.36612 1.71875 6.625H1.25H0.78125C0.78125 6.36612 0.991117 6.15625 1.25 6.15625ZM7.5 6.625L7.96875 6.62502V8.8125C7.96875 9.51717 7.40968 10.0289 6.79433 10.3366C6.15095 10.6583 5.29493 10.8438 4.375 10.8438C3.45506 10.8438 2.59904 10.6583 1.95566 10.3366C1.34032 10.0289 0.78125 9.51717 0.78125 8.8125V6.625H1.25H1.71875V8.8125C1.71875 8.97077 1.85924 9.24025 2.37492 9.49809C2.86257 9.74191 3.56905 9.90625 4.375 9.90625C5.18094 9.90625 5.88742 9.74191 6.37507 9.49809C6.89076 9.24024 7.03125 8.97077 7.03125 8.8125V6.62502L7.5 6.625ZM7.5 6.625L7.96875 6.62502C7.96875 6.36614 7.75888 6.15625 7.5 6.15625C7.24112 6.15625 7.03125 6.36614 7.03125 6.62502L7.5 6.625Z"
								fill="#219653"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M1.25 8.34375C1.50888 8.34375 1.71875 8.55362 1.71875 8.8125H1.25H0.78125C0.78125 8.55362 0.991117 8.34375 1.25 8.34375ZM7.5 8.8125L7.96875 8.81252V11C7.96875 11.7047 7.40968 12.2164 6.79433 12.5241C6.15095 12.8458 5.29493 13.0312 4.375 13.0312C3.45506 13.0312 2.59904 12.8458 1.95566 12.5241C1.34032 12.2164 0.78125 11.7047 0.78125 11V8.8125H1.25H1.71875V11C1.71875 11.1583 1.85924 11.4277 2.37492 11.6856C2.86257 11.9294 3.56905 12.0938 4.375 12.0938C5.18094 12.0938 5.88742 11.9294 6.37507 11.6856C6.89076 11.4277 7.03125 11.1583 7.03125 11V8.81252L7.5 8.8125ZM7.5 8.8125L7.96875 8.81252C7.96875 8.55364 7.75888 8.34375 7.5 8.34375C7.24112 8.34375 7.03125 8.55364 7.03125 8.81252L7.5 8.8125Z"
								fill="#219653"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M1.25 10.5312C1.50888 10.5312 1.71875 10.7411 1.71875 11H1.25H0.78125C0.78125 10.7411 0.991117 10.5312 1.25 10.5312ZM7.5 11L7.96875 11V13.1875C7.96875 13.8922 7.40968 14.4039 6.79433 14.7116C6.15095 15.0333 5.29493 15.2188 4.375 15.2188C3.45506 15.2188 2.59904 15.0333 1.95566 14.7116C1.34032 14.4039 0.78125 13.8922 0.78125 13.1875V11H1.25H1.71875V13.1875C1.71875 13.3458 1.85924 13.6152 2.37492 13.8731C2.86257 14.1169 3.56905 14.2812 4.375 14.2812C5.18094 14.2812 5.88742 14.1169 6.37507 13.8731C6.89076 13.6152 7.03125 13.3458 7.03125 13.1875V11L7.5 11ZM7.5 11L7.96875 11C7.96875 10.7411 7.75888 10.5312 7.5 10.5312C7.24112 10.5312 7.03125 10.7411 7.03125 11L7.5 11Z"
								fill="#219653"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M1.25 12.7188C1.50888 12.7188 1.71875 12.9286 1.71875 13.1875H1.25H0.78125C0.78125 12.9286 0.991117 12.7188 1.25 12.7188ZM7.5 13.1875L7.96875 13.1875V15.375C7.96875 16.0797 7.40968 16.5914 6.79433 16.8991C6.15095 17.2208 5.29493 17.4062 4.375 17.4062C3.45506 17.4062 2.59904 17.2208 1.95566 16.8991C1.34032 16.5914 0.78125 16.0797 0.78125 15.375V13.1875H1.25H1.71875V15.375C1.71875 15.5333 1.85924 15.8027 2.37492 16.0606C2.86257 16.3044 3.56905 16.4688 4.375 16.4688C5.18094 16.4688 5.88742 16.3044 6.37507 16.0606C6.89076 15.8027 7.03125 15.5333 7.03125 15.375V13.1875L7.5 13.1875ZM7.5 13.1875L7.96875 13.1875C7.96875 12.9286 7.75888 12.7188 7.5 12.7188C7.24112 12.7188 7.03125 12.9286 7.03125 13.1875L7.5 13.1875Z"
								fill="#219653"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M8.62492 10.3144C8.10924 10.5723 7.96875 10.8417 7.96875 11C7.96875 11.1583 8.10924 11.4278 8.62492 11.6856C9.11257 11.9294 9.81905 12.0938 10.625 12.0938C11.431 12.0938 12.1374 11.9294 12.6251 11.6856C13.1408 11.4278 13.2812 11.1583 13.2812 11C13.2812 10.8417 13.1408 10.5723 12.6251 10.3144C12.1374 10.0706 11.431 9.90625 10.625 9.90625C9.81905 9.90625 9.11257 10.0706 8.62492 10.3144ZM8.20566 9.47588C8.84904 9.15419 9.70506 8.96875 10.625 8.96875C11.5449 8.96875 12.401 9.15419 13.0443 9.47588C13.6597 9.78355 14.2188 10.2953 14.2188 11C14.2188 11.7047 13.6597 12.2164 13.0443 12.5241C12.401 12.8458 11.5449 13.0312 10.625 13.0312C9.70506 13.0312 8.84904 12.8458 8.20566 12.5241C7.59032 12.2164 7.03125 11.7047 7.03125 11C7.03125 10.2953 7.59032 9.78355 8.20566 9.47588Z"
								fill="#219653"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M7.5 10.5312C7.75888 10.5312 7.96875 10.7411 7.96875 11H7.5H7.03125C7.03125 10.7411 7.24112 10.5312 7.5 10.5312ZM13.75 11L14.2188 11V13.1875C14.2188 13.8922 13.6597 14.4039 13.0443 14.7116C12.401 15.0333 11.5449 15.2188 10.625 15.2188C9.70507 15.2188 8.84905 15.0333 8.20567 14.7116C7.59032 14.4039 7.03125 13.8922 7.03125 13.1875V11H7.5H7.96875V13.1875C7.96875 13.3458 8.10924 13.6152 8.62493 13.8731C9.11258 14.1169 9.81906 14.2812 10.625 14.2812C11.4309 14.2812 12.1374 14.1169 12.6251 13.8731C13.1408 13.6152 13.2812 13.3458 13.2812 13.1875V11L13.75 11ZM13.75 11L14.2188 11C14.2188 10.7411 14.0089 10.5312 13.75 10.5312C13.4911 10.5312 13.2812 10.7411 13.2812 11L13.75 11Z"
								fill="#219653"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M7.5 12.7188C7.75888 12.7188 7.96875 12.9286 7.96875 13.1875H7.5H7.03125C7.03125 12.9286 7.24112 12.7188 7.5 12.7188ZM13.75 13.1875L14.2188 13.1875V15.375C14.2188 16.0797 13.6597 16.5914 13.0443 16.8991C12.401 17.2208 11.5449 17.4062 10.625 17.4062C9.70507 17.4062 8.84905 17.2208 8.20567 16.8991C7.59032 16.5914 7.03125 16.0797 7.03125 15.375V13.1875H7.5H7.96875V15.375C7.96875 15.5333 8.10924 15.8027 8.62493 16.0606C9.11258 16.3044 9.81906 16.4688 10.625 16.4688C11.4309 16.4688 12.1374 16.3044 12.6251 16.0606C13.1408 15.8027 13.2812 15.5333 13.2812 15.375V13.1875L13.75 13.1875ZM13.75 13.1875L14.2188 13.1875C14.2188 12.9286 14.0089 12.7188 13.75 12.7188C13.4911 12.7188 13.2812 12.9286 13.2812 13.1875L13.75 13.1875Z"
								fill="#219653"
							/>
						</svg>
					</>
				)}
				{data.fill_price}
			</span>
			<span className="justify-end">{data.fill_price}</span>
			<span className="green justify-end">{Number(renderImpliedVolatility()) * 100}%</span>
			<span className="justify-end">
				<span className="orderbook-body-item__line-progres" style={{ ...getStyles }} />
				<div className="zIndex">{data.amount_asset_filled}</div>
			</span>
			<span className="justify-end">{renderDate()}</span>
		</div>
	);
};
export default RecentTradesItem;
