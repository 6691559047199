import { FC } from 'react';
import L from 'i18n-react';
import { useHistory, useLocation } from 'react-router';
import { IMarketTypeToggle, MarketTypes } from './types';

export const MARKET_TYPE = 'marketType';

const MarketTypeToggle: FC<IMarketTypeToggle> = () => {
	const location = useLocation();
	const history = useHistory();
	const marketType = new URLSearchParams(location.search).get(MARKET_TYPE);

	const { pathname } = location;

	const toggleMargetType: (marketType: string) => void = (_marketType) => {
		history.replace({
			pathname,
			search: `?${MARKET_TYPE}=${_marketType}`,
		});
	};

	return (
		<div className="wallet-content-button-tabs">
			<button
				type="button"
				className={`button button--type9 ${
					!marketType || marketType === MarketTypes.Cross ? 'active' : ''
				}`}
				onClick={() => toggleMargetType(MarketTypes.Cross)}
			>
				{String(L.translate('Wallets.MarginAccount.marketType.cross'))}
			</button>
			<button
				type="button"
				className={`button button--type9 ${marketType === MarketTypes.Isolated ? 'active' : ''}`}
				onClick={() => toggleMargetType(MarketTypes.Isolated)}
			>
				{String(L.translate('Wallets.MarginAccount.marketType.isolated'))}
			</button>
		</div>
	);
};

export { MarketTypes };
export default MarketTypeToggle;
