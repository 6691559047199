import { FC, useEffect, useMemo, useState } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { useLocation } from 'react-router-dom';
import {
	getOpenOrdersRequest,
	removeAllOpenOrdersRequest,
	setTradeType,
} from 'redux/reducers/spotTrade/reducer';
import {
	getSpotUserOpenOrders,
	getSpotUserOpenOrdersIsLoad,
	getTradeType,
} from 'redux/reducers/spotTrade/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { getAllOrderTypesRequest } from 'redux/reducers/orders/reducer';
import { getAllOrderTypes } from 'redux/reducers/orders/selectors';
import ConfirmDeletePopup from 'ui/ConfirmDeletePopup';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';
import OpenOrdersItem from '../OpenOrdersItem';
import { IOpenOrdersProps } from './types';
import EmptyHistoryPlug from '../EmptyHistoryPlug';

const OpenOrders: FC<IOpenOrdersProps> = ({ otherPairsIsHide }) => {
	const openOrders = useSelector(getSpotUserOpenOrders);
	const openOrdersIsLoad = useSelector(getSpotUserOpenOrdersIsLoad);
	const { perPage, currentPage } = usePagination();

	const currentPair = useSelector(getCurrentPair);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const dispatch = useDispatch();
	const location = useLocation();
	const marginTypeSearch = new URLSearchParams(location.search).get('type') || null;
	const allOrderTypes = useSelector(getAllOrderTypes);
	const tradeType = useSelector(getTradeType);

	const [openModal, setOpenModal] = useState(false);
	const closeModal = () => setOpenModal(false);

	useEffect(() => {
		dispatch(getAllOrderTypesRequest());
	}, [dispatch]);

	useEffect(() => {
		if (marginTypeSearch && tradeType !== marginTypeSearch) {
			dispatch(setTradeType(marginTypeSearch));
		}
		if (location.pathname.includes('spot' || 'Spot') && tradeType !== 'spot') {
			dispatch(setTradeType('spot'));
		}
	}, [location, marginTypeSearch, tradeType, dispatch]);

	useEffect(() => {
		if (authIsAuthenticated) {
			if (marginTypeSearch && ['cross', 'isolated'].includes(marginTypeSearch)) {
				dispatch(
					getOpenOrdersRequest({
						params: {
							per_page: perPage,
							current_page: currentPage,
							order_type: marginTypeSearch as 'cross' | 'isolated',
							status: 'opened',
							asset_pair_code: otherPairsIsHide ? currentPair.pair : undefined,
						},
					}),
				);
				return;
			}
			dispatch(
				getOpenOrdersRequest({
					params: {
						order_type_ids: '1,4,7',
						status: 'opened',
						order_type: 'spot',
						per_page: perPage,
						current_page: currentPage,
						asset_pair_code: otherPairsIsHide ? currentPair.pair : undefined,
					},
				}),
			);
		}
	}, [
		dispatch,
		authIsAuthenticated,
		marginTypeSearch,
		perPage,
		currentPage,
		otherPairsIsHide,
		currentPair.pair,
	]);

	const { pathname } = location;

	const trading = pathname.slice(1, pathname.slice(1).indexOf('/') + 1);

	const orderTypes = useMemo(() => {
		if (allOrderTypes) {
			if (trading === 'spot') {
				return allOrderTypes.spot;
			}
			if (trading === 'margin') {
				const { search } = location;
				if (/^\?\w+=\w+(&\w+=\w+)*$/.test(search)) {
					const searchItem = 'type=';
					const cuttedSearch = search.slice(search.indexOf(searchItem) + searchItem.length);
					const type = cuttedSearch.slice(0, cuttedSearch.indexOf('&'));
					if (type === 'cross') {
						return allOrderTypes?.margin?.cross;
					}
					if (type === 'isolated') {
						return allOrderTypes?.margin?.isolated;
					}
				}
			}
		}
		return null;
	}, [allOrderTypes, location, trading]);

	const handleRemoveUserAllOpenOrders = () => {
		if (openOrders) {
			// const payload = {
			// 	[`${marginTypeSearch === 'cross' ? 'is_margin' : 'is_isolated'}`]: true,
			// };
			// if (marginTypeSearch === 'cross' || marginTypeSearch === 'isolated') {
			// 	dispatch(removeAllOpenOrdersRequest(payload));
			// } else {
			// 	dispatch(removeAllOpenOrdersRequest(null));

			// }
			let ordersToRemove: 'cross' | 'spot' | 'isolated' | 'margin' | undefined;
			if (location.pathname.split('/')[1] === 'spot') {
				ordersToRemove = 'spot';
			}
			if (location.pathname.split('/')[1] === 'marginTrading') {
				ordersToRemove = 'margin';
			}
			if (marginTypeSearch === 'cross') {
				ordersToRemove = 'cross';
			}
			if (marginTypeSearch === 'isolated') {
				ordersToRemove = 'isolated';
			}
			dispatch(
				removeAllOpenOrdersRequest({
					order_type: ordersToRemove,
					// asset_pair_id: String(currentPair.id),
				}),
			);
		}

		closeModal();
	};

	return (
		<div className="table table--trade table--trade-open-orders">
			<div className="table-header">
				<div className="tr tr--eleventhColumn">
					<div className="td">
						<p>
							{String(L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.date_column_name'))}
						</p>
					</div>
					<div className="td">
						<p>
							{String(L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.pair_column_name'))}
						</p>
					</div>
					<div className="td">
						<p>
							{String(L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.type_column_name'))}
						</p>
					</div>
					<div className="td">
						<p>
							{String(L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.side_column_name'))}
						</p>
					</div>
					<div className="td">
						<p>
							{String(
								L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.average_column_name'),
							)}
						</p>
					</div>
					<div className="td">
						<p>
							{String(
								L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.price_column_name'),
							)}
						</p>
					</div>
					<div className="td">
						<p>
							{String(
								L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.amount_column_name'),
							)}
						</p>
					</div>
					{/* <div className="td">
						<p>
							{String(
								L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.filled_column_name'),
							)}
						</p>
					</div> */}
					{/* <div className="td">
						<p>
							{String(
								L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.total_column_name'),
							)}
						</p>
					</div> */}
					<div className="td">
						<p>
							{String(
								L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.filled_column_name'),
							)}
						</p>
					</div>
					<div className="td">
						<p>
							{String(
								L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.total_column_name'),
							)}
						</p>
					</div>
					<div className="td">
						<p>
							{String(
								L.translate(
									'Trade.Spot.SpotHistoryTables.OpenOrdersTable.trigger_conditions_column_name',
								),
							)}
						</p>
					</div>
					<div className="td td--right">
						<button
							type="button"
							className="link status-table-text"
							onClick={() => setOpenModal((prevOpenModal) => !prevOpenModal)}
							disabled={!openOrders}
						>
							{String(
								L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.cancel_all_button'),
							)}
						</button>
						<ConfirmDeletePopup
							title={String(
								L.translate(
									'Trade.Spot.SpotHistoryTables.OpenOrdersTable.confirm_delete_all_orders_title',
								),
							)}
							bodyMessage={String(
								L.translate(
									'Trade.Spot.SpotHistoryTables.OpenOrdersTable.confirm_delete_all_body_message',
								),
							)}
							openModal={openModal}
							closeModal={closeModal}
							handleDelete={handleRemoveUserAllOpenOrders}
						/>
					</div>
				</div>
			</div>
			<div className="table-body">
				{openOrdersIsLoad && (
					<div className="open-orders-history-loader-wrapper">
						<Loader />
					</div>
				)}
				{!openOrdersIsLoad && openOrders?.data
					? openOrders?.data?.map((order: any) => <OpenOrdersItem key={order.id} data={order} />)
					: null}

				{!openOrdersIsLoad && openOrders?.total === 0 && (
					<EmptyHistoryPlug
						text={String(
							L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.no_open_orders_text'),
						)}
					/>
				)}
				{openOrders?.last_page && Number(openOrders?.total) > Number(openOrders?.per_page) && (
					<Pagination lastPage={openOrders.last_page} getCurrentHref={(s) => String(s)} />
				)}
			</div>
		</div>
	);
};

export default OpenOrders;
