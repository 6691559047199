import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	IGenerateAddressWalletData,
	IGetSpotWalletsParams,
	IWalletItem,
	IWalletsConvertHistory,
	IWalletsCryptoHistoryData,
	IWalletsDepositHistoryData,
	IWalletsNetwork,
	IWalletsStore,
	IWalletsTransferHistory,
	IWalletsFiatHistory,
} from './types';

// ==========================================:
const getWalletsState = (state: TStoreState): IWalletsStore => state.wallets;
export const getWallets = createSelector([getWalletsState], (wallets: IWalletsStore) => wallets);

// ====================================================:

export const getWalletsList = (state: TStoreState) => getWalletsState(state).walletsList;

export const getWalletsIsLoad = (state: TStoreState) => getWalletsState(state).walletsLoader;

export const getSpotWalletsByParams = createSelector(
	[
		getWalletsList,
		(_, { assetType }: IGetSpotWalletsParams) => assetType,
		(_, { search }: IGetSpotWalletsParams) => search,
		(_, { excludeSmallBalances }: IGetSpotWalletsParams) => excludeSmallBalances,
		(_, { sortField }: IGetSpotWalletsParams) => sortField,
		(_, { sortAsc }: IGetSpotWalletsParams) => sortAsc,
	],
	(spotWallets, assetType, search, excludeSmallBalances, sortField, sortAsc) => {
		if (!spotWallets) return null;

		// Filters

		const lowSearch = search?.toLowerCase();

		const wallets = spotWallets.filter(({ asset, btc_value }) => {
			if (
				(assetType === 'fiat' && asset.is_crypto) ||
				(assetType === 'crypto' && !asset.is_crypto)
			) {
				return false;
			}
			if (lowSearch && !asset.code.toLowerCase().includes(lowSearch)) {
				return false;
			}
			if (excludeSmallBalances && Number(btc_value) <= 0.0003) {
				return false;
			}
			return true;
		});

		// Sort

		const compareStrings = (strA: string, strB: string, isAsc: boolean) => {
			if (strA < strB) {
				return isAsc ? -1 : 1;
			}
			if (strA > strB) {
				return isAsc ? 1 : -1;
			}
			return 0;
		};

		if (sortField) {
			wallets.sort((walletA, walletB) => {
				if (sortField === 'asset_code') {
					return compareStrings(walletA.asset.code, walletB.asset.code, !!sortAsc);
				}
				const numA = Number(walletA[sortField]);
				const numB = Number(walletB[sortField]);

				return sortAsc ? numA - numB : numB - numA;
			});
		}

		return wallets;
	},
);

// ====================================================:
export const getWalletsAddress = createSelector(
	[getWallets],
	(wallets: IWalletsStore): IGenerateAddressWalletData | null => wallets.generateAddress,
);

// ====================================================:
export const getGenerateAddressLoader = createSelector(
	[getWallets],
	(wallets: IWalletsStore): boolean => wallets.generateAddressLoader,
);

// ====================================================:
export const getWalletsHistoryLoader = createSelector(
	[getWallets],
	(wallets: IWalletsStore): boolean => wallets.walletsHistoryLoader,
);

// ====================================================:
export const getWalletsCryptoHistoryList = createSelector(
	[getWallets],
	(wallets: IWalletsStore): IWalletsCryptoHistoryData => wallets.walletsCryptoHistory,
);
// ====================================================:
export const getWalletsDepositHistoryList = createSelector(
	[getWallets],
	(wallets: IWalletsStore): IWalletsDepositHistoryData => wallets.walletsDepositHistory,
);
// ====================================================:
export const getWalletsConvertHistoryList = createSelector(
	[getWallets],
	(wallets: IWalletsStore): IWalletsConvertHistory | null => wallets.walletsConvertHistory,
);
// ======================================================
export const getWalletsTransferHistory = createSelector(
	[getWallets],
	(wallets: IWalletsStore): IWalletsTransferHistory | null => wallets.walletsTransferHistory,
);
// ======================================================
export const getWalletsFiatHistory = createSelector(
	[getWallets],
	(wallets: IWalletsStore): IWalletsFiatHistory | null => wallets.walletsFiatHistory,
);
// ====================================================:
export const getWalletNetworks = createSelector(
	[getWallets],
	(wallets: IWalletsStore): IWalletsNetwork[] | null => wallets.currentWalletNetworks,
);
// ====================================================:
export const getCurrentWallet = createSelector(
	[getWallets],
	(wallets: IWalletsStore): IWalletItem | null => wallets.currentWallet,
);

export const getWithdrawCryptoLoader = (state: TStoreState) => {
	return getWalletsState(state).withdrawCryptoLoader;
};

export const getCheckCryptoAddress = (state: TStoreState) => {
	return getWalletsState(state).checkCryptoAddress;
};
