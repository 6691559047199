import { FC, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import L from 'i18n-react';
import { Formik, Form } from 'formik';
import Portal from 'ui/Portal';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { api } from 'services';
import yup from 'services/utils/capsLockValidate';
import { notificationContainer } from 'services/utils/notificationContainer';
import axios from 'axios';
import { responseErrors } from 'services/http/responseErrors';
import EditOrderModalContent from './EditOrderModalContent';
import { IEditOrderModal } from './types';

const EditOrderModal: FC<IEditOrderModal> = ({ item, strikePrice, onClose }) => {
	const isAuth = useSelector(getAuthIsAuthenticated);
	const history = useHistory();

	const initialValues = {
		isIv: false,
		price:
			item.price_entry_for_order || item.price_entry_for_order === 0
				? String(item.price_entry_for_order)
				: '',
		iv: '',
		quantity:
			item.amount_asset_request || item.amount_asset_request === 0
				? String(item.amount_asset_request)
				: '',
	};

	const validationSchema = yup.object().shape({
		price: yup
			.number()
			.typeError(String(L.translate('Trade.Options.messages.price_must_be_a_number')))
			.required(String(L.translate('Trade.Options.messages.price_is_required'))),
		iv: yup
			.number()
			.typeError(String(L.translate('Trade.Options.messages.iv_must_be_a_number')))
			.min(0, `IV can not be less than 0%`)
			.max(100, `IV can not be greater than 100%}`),
		quantity: yup
			.number()
			.typeError(String(L.translate('Trade.Options.messages.quantity_must_be_a_number')))
			.required(String(L.translate('Trade.Options.messages.quantity_is_required'))),
	});

	return (
		<Portal>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				validateOnBlur
				onSubmit={async (values, { resetForm, setSubmitting }) => {
					if (!isAuth) {
						history.push('/login');
						return;
					}
					setSubmitting(true);
					try {
						setSubmitting(true);
						await api.optionsTrade.editOrder({
							order_option_id: item.id,
							amount_asset_request: Number(values.quantity),
							price_entry_for_order: Number(values.price),
						});
						notificationContainer(
							`The order price and order quantity for ${String(
								item?.contract_name,
							)} contracts have been modified to ${values.price} and ${
								values.quantity
							}, respectively`,
							'success',
							{
								title: 'Order(s) modified successfully!',
							},
						);
						setSubmitting(false);
						onClose && onClose();
					} catch (error) {
						if (axios.isAxiosError(error)) {
							responseErrors(error);
						}
						// notificationContainer(
						// 	String(L.translate('Trade.Options.messages.failed_to_close_order')),
						// 	'error',
						// );
						console.error(error);
						setSubmitting(false);
					}
				}}
			>
				<Form>
					<EditOrderModalContent item={item} strikePrice={strikePrice} onClose={onClose} />
				</Form>
			</Formik>
		</Portal>
	);
};

export default EditOrderModal;
