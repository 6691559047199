import Chart from 'components/Chart';
import { Link } from 'react-router-dom';
import L from 'i18n-react';
import SpotHistoryTables from '../SpotHistoryTables';
import SpotRecentTrades from '../SpotRecentTrades';
import SpotMarketHeader from '../SpotMarketHeader';
import SpotOrderBook from '../SpotOrderBook';
import SpotPairs from '../SpotPairs';
import TradesBox from '../TradesBox';

// import TradesBox from '../TradesBox';

export const SpotTradeWithOrderBook = () => {
	return (
		<section className="trade-section">
			<SpotMarketHeader />

			<div className="trade-mob-nav">
				<button type="button" className="trade-mob-nav__item active">
					{String(L.translate('Trade.Spot.MobNav.chart'))}
				</button>
				<button type="button" className="trade-mob-nav__item">
					{String(L.translate('Trade.Spot.MobNav.orderbook'))}
				</button>
				<button type="button" className="trade-mob-nav__item">
					{String(L.translate('Trade.Spot.MobNav.trades'))}
				</button>
			</div>
			<div className="trade">
				<SpotOrderBook />
				<div className="chart active">
					<Chart />
				</div>
				<TradesBox />

				<SpotPairs />
				<SpotRecentTrades />
			</div>
			<SpotHistoryTables />
		</section>
	);
};
