import { FC } from 'react';
import Slider from 'rc-slider';

type ISliderProps = {
	sliderValue: number;
	setSliderValue: any;
	currencies: { from: string; to: string };
	rate: string | number;
};

const ExchangeSlider: FC<ISliderProps> = ({ sliderValue, setSliderValue, currencies, rate }) => {
	const handleClick = (e: any) => {
		const { name } = e.target;
		if (e.target.tagName === 'BUTTON') {
			setSliderValue(Number(name));
		}
	};

	return (
		<>
			<div className="convert-calc">
				<div className="convert-calc__block">
					<span className="convert-calc__text">
						1 {currencies.from.toUpperCase()} ≈ {rate} {currencies.to.toUpperCase()}
					</span>
				</div>
				<div className="range-slider">
					<Slider
						className="range-slider"
						onChange={(value) => setSliderValue(value)}
						value={sliderValue}
						min={0}
						max={100}
						defaultValue={0}
						step={1}
					/>
				</div>
			</div>
			<div className="convert-button" onClick={handleClick}>
				<button name="25" className="button button--type7" type="button">
					25%
				</button>
				<button name="50" className="button button--type7" type="button">
					50%
				</button>
				<button name="75" className="button button--type7" type="button">
					75%
				</button>
				<button name="100" className="button button--type7" type="button">
					100%
				</button>
			</div>
		</>
	);
};

export default ExchangeSlider;
