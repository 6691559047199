/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unneeded-ternary */

import React, { useEffect, useState, ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import {
	getAssetPairsIsLoad,
	getAssetPairsList,
	getAssetPairsListFavorite,
	getVolume24,
} from 'redux/reducers/assetPairs/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { IAssetPairsArray, IAssetPairsItem } from 'redux/reducers/assetPairs/types';
import {
	getAssetPairsRequest,
	getAssetPairsRequestFavorite,
	putAssetPairsRequestFavoriteId,
	getVolume24Request,
} from 'redux/reducers/assetPairs/reducer';
import { setCurrentPair } from 'redux/reducers/tradingSettings/reducer';
import { getFirstPartPairCode, getLastPartPairCode } from 'services/utils/tradingPairHelpers';
import { convertToFixed } from 'services/utils/convertToFixed';
import { changePage } from 'services/utils/changePage';
import { Page } from 'layouts/page';
import Loader from 'ui/Loader';
import { Link } from 'react-router-dom';
import SocketWithTopicConnectWrapper from '../../components/SocketWithTopicConnectWrapper';

const Arrow = ({ selected }: { selected: boolean }) => {
	return (
		<span className="arrow-icon">
			<svg
				style={{ transform: `${selected === true ? 'rotate(180deg)' : ''}` }}
				width="8"
				height="5"
				viewBox="0 0 8 5"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M1 1L4 4L7 1" stroke="#011E26" strokeLinecap="square" />
			</svg>
		</span>
	);
};

export const PairsSpotPage = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const assetPairs = useSelector(getAssetPairsList);
	const favoritePairs = useSelector(getAssetPairsListFavorite);
	const assetPairsLoader = useSelector(getAssetPairsIsLoad);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const volume24 = useSelector(getVolume24);

	const [filterAssetsList, setFilterAssetsList] = useState<IAssetPairsArray>(assetPairs);
	const [favoriteTab, setFavoriteTab] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [sortBy, setSortBy] = useState<string>('');

	useEffect(() => {
		dispatch(getAssetPairsRequest());
		dispatch(getAssetPairsRequestFavorite());
		dispatch(getVolume24Request());
	}, [dispatch]);

	const handleTrade = (pairCode: string, id: number) => {
		if (!pairCode) return;
		dispatch(setCurrentPair({ pair: pairCode, id: Number(id) }));
		changePage(`/spot/${pairCode.toUpperCase()}`, history);
	};

	const pairSearch = (pair: IAssetPairsItem, search: string) => {
		return pair.code
			.toLowerCase()
			.replace('_', '/')
			.includes(search.toLowerCase().split(' ').join('/').trim());
	};

	const getIsFavorite = (code: string) => {
		if (!favoritePairs) return false;
		return Object.values(favoritePairs).some((favCode) => favCode === code);
	};

	const changeSearchValueHandler = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
		const newFilterAssetPairsList = favoriteTab
			? assetPairs?.filter((el) => getIsFavorite(el.code) && pairSearch(el, e.target.value))
			: assetPairs?.filter((el) => pairSearch(el, e.target.value));
		setFilterAssetsList(newFilterAssetPairsList?.length ? newFilterAssetPairsList : null);
	};

	const filterOnlyFavorites = () => {
		if (!favoriteTab) {
			const newFavoritePairs = searchValue
				? assetPairs?.filter((el) => getIsFavorite(el.code) && pairSearch(el, searchValue))
				: assetPairs?.filter((el) => getIsFavorite(el.code));

			setFilterAssetsList(newFavoritePairs || null);
			setFavoriteTab(true);
		}
	};

	const returnAllAssetPairs = () => {
		if (favoriteTab) {
			const newAssetPairs = searchValue
				? assetPairs?.filter((el) => pairSearch(el, searchValue))
				: assetPairs;
			setFilterAssetsList(newAssetPairs || null);
			setFavoriteTab(false);
		}
	};

	const handleChangePair = (code: string) => {
		if (authIsAuthenticated) {
			dispatch(putAssetPairsRequestFavoriteId({ code, is_choosen: getIsFavorite(code) ? 0 : 1 }));
		} else {
			history.push('/login');
		}
	};

	const handleSortClick = (field: string) => {
		if (sortBy === field) {
			setSortBy('');
			return;
		}
		setSortBy(field);
	};

	useEffect(() => {
		if (searchValue || favoriteTab) {
			const newFavoritePairs = favoriteTab
				? assetPairs?.filter((el) => getIsFavorite(el.code) && pairSearch(el, searchValue))
				: assetPairs?.filter((el) => pairSearch(el, searchValue));
			setFilterAssetsList(newFavoritePairs || null);
		} else {
			if (sortBy) {
				let list = filterAssetsList;
				switch (sortBy) {
					case 'CURRENCY':
						list = list?.sort((a, b) => (a.code > b.code ? 1 : -1)) || [];
						setFilterAssetsList(list);
						break;
					case 'LAST_PRICE':
						list =
							list?.sort((a, b) => {
								if (a.last_price > b.last_price) {
									return 1;
								}
								if (a.last_price < b.last_price) {
									return -1;
								}
								return 0;
							}) || [];
						setFilterAssetsList(list);
						break;
					case 'CHANGE':
						list =
							list?.sort((a, b) => {
								if (a.change24 > b.change24) {
									return 1;
								}
								if (a.change24 < b.change24) {
									return -1;
								}
								return 0;
							}) || [];
						setFilterAssetsList(list);
						break;
					case 'HIGH':
						list =
							list?.sort((a, b) => {
								if (a.high24hr > b.high24hr) {
									return 1;
								}
								if (a.high24hr < b.high24hr) {
									return -1;
								}
								return 0;
							}) || [];
						setFilterAssetsList(list);
						break;
					case 'LOW':
						list =
							list?.sort((a, b) => {
								if (a.low24hr > b.low24hr) {
									return 1;
								}
								if (a.low24hr < b.low24hr) {
									return -1;
								}
								return 0;
							}) || [];
						setFilterAssetsList(list);
						break;
					case 'VOLUME':
						list =
							list?.sort((a, b) => {
								if (a.volume24 > b.volume24) {
									return 1;
								}
								if (a.volume24 < b.volume24) {
									return -1;
								}
								return 0;
							}) || [];
						setFilterAssetsList(list);
						break;
					default:
				}
				return;
			}
			setFilterAssetsList((prev) =>
				Array.isArray(assetPairs)
					? assetPairs.map((assetPair, idx) => {
							const prevCurrentValue = prev?.[idx];
							return {
								...assetPair,
								last_price_usd:
									// eslint-disable-next-line eqeqeq
									assetPair?.last_price_usd != undefined
										? assetPair?.last_price_usd
										: prevCurrentValue?.last_price_usd || 0,
							};
					  })
					: prev,
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assetPairs, favoritePairs, sortBy]);

	return (
		<Page title={String(L.translate('PairsSpot.title'))}>
			<SocketWithTopicConnectWrapper topicName="assets_pairs:spot">
				<section className="transaction-history-section section-gradient">
					<div className="inside">
						{!assetPairsLoader ? (
							<div className="transaction-history">
								<div className="transaction-history-top">
									<div className="deposit-content-header">
										<Link to="/" className="deposit-content-header__back">
											<svg
												width="21"
												height="21"
												viewBox="0 0 21 21"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M4.875 10.4961C4.875 10.1509 5.15482 9.87109 5.5 9.87109H15.5C15.8452 9.87109 16.125 10.1509 16.125 10.4961C16.125 10.8413 15.8452 11.1211 15.5 11.1211H5.5C5.15482 11.1211 4.875 10.8413 4.875 10.4961Z"
													fill="#1C1C29"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M10.9419 5.05806C11.186 5.30214 11.186 5.69786 10.9419 5.94194L6.38388 10.5L10.9419 15.0581C11.186 15.3021 11.186 15.6979 10.9419 15.9419C10.6979 16.186 10.3021 16.186 10.0581 15.9419L5.05806 10.9419C4.81398 10.6979 4.81398 10.3021 5.05806 10.0581L10.0581 5.05806C10.3021 4.81398 10.6979 4.81398 10.9419 5.05806Z"
													fill="#1C1C29"
												/>
											</svg>
										</Link>
										<p>{String(L.translate('PairsSpot.title'))}</p>
									</div>
									<div className="table-nav-wrap">
										<div className="tabs-page">
											<button
												type="button"
												className={`tabs-page__item ${!favoriteTab ? 'active' : ''}`}
												onClick={returnAllAssetPairs}
											>
												{String(L.translate('PairsSpot.spot'))}
											</button>
											{authIsAuthenticated && (
												<button
													type="button"
													className={`tabs-page__item ${favoriteTab ? 'active' : ''}`}
													onClick={filterOnlyFavorites}
												>
													{String(L.translate('PairsSpot.favorites'))}
												</button>
											)}
										</div>
										<div className="input input--no-mb input--width-280">
											<div className="input-wrapper">
												<input
													type="text"
													value={searchValue}
													placeholder={String(L.translate('Global.search'))}
													onChange={changeSearchValueHandler}
													className="input-item input-item--h40"
												/>
												<div className="input-icon input-icon--right">
													<svg
														width="15"
														height="15"
														viewBox="0 0 15 15"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M0.78125 6.5625C0.78125 3.36962 3.36962 0.78125 6.5625 0.78125C9.75538 0.78125 12.3438 3.36962 12.3438 6.5625C12.3438 9.75538 9.75538 12.3438 6.5625 12.3438C3.36962 12.3438 0.78125 9.75538 0.78125 6.5625ZM6.5625 1.71875C3.88738 1.71875 1.71875 3.88738 1.71875 6.5625C1.71875 9.23762 3.88738 11.4062 6.5625 11.4062C9.23762 11.4062 11.4062 9.23762 11.4062 6.5625C11.4062 3.88738 9.23762 1.71875 6.5625 1.71875Z"
															fill="#9D9DBC"
														/>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M4.46348 4.15075C5.00022 3.61404 5.74305 3.28125 6.5627 3.28125C7.38236 3.28125 8.12519 3.61404 8.66191 4.15076C8.84497 4.33382 8.84497 4.63062 8.66191 4.81367C8.47885 4.99673 8.18206 4.99673 7.999 4.81367C7.63091 4.44559 7.12374 4.21875 6.5627 4.21875C6.00167 4.21875 5.49449 4.44558 5.12637 4.81369C4.9433 4.99674 4.64651 4.99673 4.46345 4.81366C4.2804 4.6306 4.28041 4.3338 4.46348 4.15075Z"
															fill="#9D9DBC"
														/>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M10.0504 10.0504C10.2334 9.86732 10.5302 9.86732 10.7133 10.0504L13.3649 12.702C13.548 12.8851 13.548 13.1819 13.3649 13.3649C13.1819 13.548 12.8851 13.548 12.702 13.3649L10.0504 10.7133C9.86732 10.5302 9.86732 10.2334 10.0504 10.0504Z"
															fill="#9D9DBC"
														/>
													</svg>
												</div>
											</div>
										</div>
									</div>
								</div>
								{filterAssetsList?.length ? (
									<>
										<div className="total-volume">
											<p>
												<span className="grey">
													{String(L.translate('PairsSpot.total_24h_volume'))}:
												</span>{' '}
												${volume24}
											</p>
										</div>
										<div className="table table--transaction-history table--favorit">
											<div className="table-header">
												<div className="tr">
													<div
														className="td"
														style={{ display: 'flex' }}
														onClick={() => handleSortClick('CURRENCY')}
													>
														<p className={`${sortBy === 'CURRENCY' ? 'selected-sort' : ''}`}>
															{String(L.translate('PairsSpot.columns.currency'))}
														</p>
														<Arrow selected={sortBy === 'CURRENCY' ? true : false} />
													</div>
													<div
														className="td"
														style={{ display: 'flex' }}
														onClick={() => handleSortClick('LAST_PRICE')}
													>
														<p className={`${sortBy === 'LAST_PRICE' ? 'selected-sort' : ''}`}>
															{String(L.translate('PairsSpot.columns.last_price'))}
														</p>
														<Arrow selected={sortBy === 'LAST_PRICE' ? true : false} />
													</div>
													<div
														className="td"
														style={{ display: 'flex' }}
														onClick={() => handleSortClick('CHANGE')}
													>
														<p className={`${sortBy === 'CHANGE' ? 'selected-sort' : ''}`}>
															{String(L.translate('PairsSpot.columns.24h_change'))}
														</p>
														<Arrow selected={sortBy === 'CHANGE' ? true : false} />
													</div>
													<div
														className="td"
														style={{ display: 'flex' }}
														onClick={() => handleSortClick('HIGH')}
													>
														<p className={`${sortBy === 'HIGH' ? 'selected-sort' : ''}`}>
															{String(L.translate('PairsSpot.columns.24h_high'))}
														</p>
														<Arrow selected={sortBy === 'HIGH' ? true : false} />
													</div>
													<div
														className="td"
														style={{ display: 'flex' }}
														onClick={() => handleSortClick('LOW')}
													>
														<p className={`${sortBy === 'LOW' ? 'selected-sort' : ''}`}>
															{String(L.translate('PairsSpot.columns.24h_low'))}
														</p>
														<Arrow selected={sortBy === 'LOW' ? true : false} />
													</div>
													<div
														className="td"
														style={{ display: 'flex' }}
														onClick={() => handleSortClick('VOLUME')}
													>
														<p className={`${sortBy === 'VOLUME' ? 'selected-sort' : ''}`}>
															{String(L.translate('PairsSpot.columns.24h_volume'))}
														</p>
														<Arrow selected={sortBy === 'VOLUME' ? true : false} />
													</div>
													<div className="td td--right" />
												</div>
											</div>
											<div className="table-body">
												{filterAssetsList?.map((asset) => {
													return (
														<div className="tr" key={asset.id}>
															<div className="td">
																<p className="td-hidden-name">CURRENCY</p>
																<div className="flex flex--gap8">
																	<button
																		type="button"
																		className="favorit-button"
																		onClick={() => handleChangePair(asset.code)}
																	>
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			version="1.1"
																			id="Capa_1"
																			x="0px"
																			y="0px"
																			viewBox="0 0 488.784 488.784"
																			xmlSpace="preserve"
																		>
																			<path
																				fill={getIsFavorite(asset.code) ? '#FF57D4' : '#9d9dbc'}
																				d="M478.63,219.042l-98.2,80.2c-5.9,4.8-8.5,12.6-6.7,20l29.7,121.3c4.4,19.7-10.6,36-27.4,36c-5,0-10.3-1.5-15.2-4.7   l-107.4-66c-6.4-3.9-14.4-3.9-20.8,0.1l-106.1,66c-4.9,3.3-10.2,4.7-15.3,4.7c-17.2,0-32.7-16.3-27.3-36l29.7-121.4   c1.8-7.4-0.8-15.1-6.6-19.9l-96.9-80.1c-19.9-17-8.5-49.7,17-51.1l125.5-9.1c7.5-0.5,14-5.3,16.8-12.2l48-116.6   c5-12.1,16-18.1,27-18.1s22,6,27,18.1l47.9,116.5c2.8,6.9,9.4,11.6,16.8,12.2l125.5,9.1   C487.23,169.442,498.53,202.042,478.63,219.042z"
																			/>
																		</svg>
																	</button>
																	<p>
																		{getFirstPartPairCode(asset.code)}{' '}
																		<span className="grey">
																			/ {getLastPartPairCode(asset.code)}
																		</span>
																	</p>
																</div>
															</div>
															<div className="td">
																<p className="td-hidden-name">LAST PRICE</p>
																<p>
																	{asset.last_price}{' '}
																	<span className="grey">
																		${asset?.last_price_usd || '0.00 (static)'}
																	</span>
																</p>
															</div>
															<div className="td">
																<p className="td-hidden-name">24H CHANGE</p>
																<p className={asset.change24 < 0 ? 'red' : 'green'}>
																	{asset.change24.toFixed(2)}%
																</p>
															</div>
															<div className="td">
																<p className="td-hidden-name">LAST PRICE</p>
																<p>{convertToFixed(asset.high24hr)}</p>
															</div>
															<div className="td">
																<p className="td-hidden-name">24H LOW</p>
																<p>{convertToFixed(asset.low24hr)}</p>
															</div>
															<div className="td">
																<p className="td-hidden-name">24H VOLUME</p>
																<p>{convertToFixed(asset.volume24)}</p>
															</div>
															<div className="td td--right">
																<button
																	type="button"
																	className="button"
																	onClick={() => handleTrade(asset.code, asset.id)}
																>
																	{String(L.translate('PairsSpot.trade_btn'))}
																</button>
															</div>
														</div>
													);
												})}
											</div>
										</div>
									</>
								) : (
									<p>{String(L.translate('PairsSpot.pairs_not_found'))}</p>
								)}
							</div>
						) : (
							<Loader />
						)}
					</div>
				</section>
			</SocketWithTopicConnectWrapper>
		</Page>
	);
};
