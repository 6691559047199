import React from 'react';
import Trading from 'layouts/Trading';
import Contract from 'components/StaticPages/contract';

const ContractPage = () => {
	return (
		<Trading title="Contract">
			<Contract />
		</Trading>
	);
};

export default ContractPage;
