import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { IProps } from './index';
import Loader from '../../../../ui/Loader';

const Graphic = ({ data }: IProps) => {
	const graphTypeBase = [
		'last_day_by_hours',
		'last_hour_by_five_minutes',
		'thirty_days_by_five_days',
		'three_days_by_three_hour',
	];
	const hourbyMinute = (arr: []) => {
		return arr?.map((e: number) => {
			return moment(e).format('HH:mm');
		});
	};
	const threeDay = (arr: []) => {
		return arr?.map((e: number) => {
			return moment(e).format('ddd DD, HH:mm A');
		});
	};
	const ninetyDay = (arr: []) => {
		return arr?.map((e: number) => {
			return moment(e).format('DD-MM-gggg');
		});
	};

	return (
		<>
			{data === undefined ? (
				<div className="analytics-loader-wrapper">
					<Loader />
				</div>
			) : (
				<div className="analitics-block__item">
					<div className="container">
						<div className="row">
							{graphTypeBase?.map((graph) => {
								return (
									<div key={uuidv4()} className="col-lg-6">
										<div className="analitic-graph">
											<div className="analitic-graph__header">
												<h2>
													{graph.charAt(0).toUpperCase() + graph.slice(1).replace(/_/gi, ' ')}
												</h2>
											</div>
											<div className="analitic-graph__item">
												<Bar
													data={{
														labels: data[graph].timestamp.map((item: string) =>
															new Date(Number(item) * 1000).toLocaleString(),
														),
														datasets: [
															{
																label: 'buy',
																data: data[graph]?.buy_volume,
																backgroundColor: '#00bf5e',
																borderColor: '#2c2e3a',
															},
															{
																label: 'sell',
																data: data[graph]?.sell_volume,
																backgroundColor: '#eb5757',
																borderColor: '#2c2e3a',
															},
														],
													}}
												/>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			)}
		</>
	);
};
export default Graphic;
