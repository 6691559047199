import React, { useEffect } from 'react';
import L from 'i18n-react';

const FAQ = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="static-section section-gradient">
			<div className="inside">
				<p className="section-title">{String(L.translate('StaticPages.FaqPage.faq_title'))}</p>
				<div className="static-content">
					<h3>{String(L.translate('StaticPages.FaqPage.header_1'))}</h3>
					<p>{String(L.translate('StaticPages.FaqPage.paragraph_1'))}</p>

					<h3>{String(L.translate('StaticPages.FaqPage.header_2'))}</h3>
					<p>{String(L.translate('StaticPages.FaqPage.paragraph_2'))}</p>

					<h3>{String(L.translate('StaticPages.FaqPage.header_3'))}</h3>
					<p>{String(L.translate('StaticPages.FaqPage.paragraph_3'))}</p>

					<h3>{String(L.translate('StaticPages.FaqPage.header_4'))}</h3>
					<p>{String(L.translate('StaticPages.FaqPage.paragraph_4'))}</p>
					<p>{String(L.translate('StaticPages.FaqPage.paragraph_5'))}</p>
					<ul>
						<li>{String(L.translate('StaticPages.FaqPage.li_1'))}</li>
						<li>{String(L.translate('StaticPages.FaqPage.li_2'))}</li>
						<li>{String(L.translate('StaticPages.FaqPage.li_3'))}</li>
					</ul>

					<h3>{String(L.translate('StaticPages.FaqPage.header_5'))}</h3>
					<p>{String(L.translate('StaticPages.FaqPage.paragraph_6'))}</p>
					<p>{String(L.translate('StaticPages.FaqPage.paragraph_7'))}</p>
					<p>{String(L.translate('StaticPages.FaqPage.paragraph_8'))}</p>
					<p>{String(L.translate('StaticPages.FaqPage.paragraph_9'))}</p>

					<h3>{String(L.translate('StaticPages.FaqPage.header_6'))}</h3>
					<p>{String(L.translate('StaticPages.FaqPage.paragraph_10'))}</p>
				</div>
			</div>
		</section>
	);
};

export default FAQ;
