/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-debugger */
import { FC, SyntheticEvent, ReactNode, useState, useEffect, useReducer, useCallback } from 'react';
import L from 'i18n-react';
import useSelect from 'hooks/useSelect';
import { useFormikContext, FormikContextType } from 'formik';
import { useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getIsolatedWalletsList } from 'redux/reducers/marginWallets/selectors';
import { transformMarginsIsolatedData } from 'services/utils/transformMarginsIsolatedData';
import { getAssetsList } from 'redux/reducers/assets/selectors';
import { IAssetsItem, IAssetsData } from 'redux/reducers/assets/types';
import Loader from 'ui/Loader';
import PersentSlider from 'components/Trade/TradesBox/PersentSlider';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import AssetDropItem from 'components/MarginWallets/modals/shared/AssetDropItem';
import NoBorrowLabel from '../NoBorrowLabel';
import { IRepayModalFormIsolated, RepayInfo, Values } from './types';

const RepayContentIsolated: FC<IRepayModalFormIsolated> = ({
	assetId,
	assetPairId,
	amountMin,
	repayInfo,
	isFetching,
	onClose,
	refetch,
}) => {
	const assetsList = useSelector(getAssetsList);
	const { avaliableBalance, interestA, borrowedB, totalDebt } = repayInfo;
	const [filteredAssetsList, setFilteredAssetsList] = useState<IAssetsData | null>(null);
	const { open, setOpen, toggleOpen, currentRef, dropRef } = useSelect();
	const { values, setValues, isValid, errors, isSubmitting }: FormikContextType<Values> =
		useFormikContext();

	const selectedAsset = assetsList?.find((asset: IAssetsItem) => asset.id === values.asset_id);
	const code = selectedAsset?.code;

	const marginIsolatedWallets = useSelector(getIsolatedWalletsList);
	const currentPair = marginIsolatedWallets?.isolated?.find((el) => el.pair.id === assetPairId);

	const pair = currentPair?.pair.code;

	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	// const getIsolated = useSelector(getIsolatedWallet);

	const isol = authIsAuthenticated ? marginIsolatedWallets?.isolated : null;
	const transformIsolated = transformMarginsIsolatedData(isol);

	const coinsIsolated = transformIsolated?.filter(
		(el: any) =>
			el?.base?.asset?.code === pair?.split('_')[0] &&
			el?.quote?.asset?.code === pair?.split('_')[1],
	);

	const listMarginCoins = coinsIsolated
		? [
				{ asset: { ...coinsIsolated[0]?.base?.asset }, ...coinsIsolated[0] },
				{ asset: { ...coinsIsolated[0]?.quote?.asset }, ...coinsIsolated[0] },
		  ]
		: [];
	const wallet = listMarginCoins?.find((el) => el.asset.code === code);

	const searchWalletBalance = (asset_code: string, asset_pair: string, item: any) => {
		if (asset_code && asset_pair && item) {
			return asset_code === asset_pair?.split('_')[0]
				? Number(item?.base?.balance)
				: Number(item?.quote?.balance);
		}

		return 0;
	};

	const available = code && pair && searchWalletBalance(code, pair, wallet);

	useEffect(() => {
		const isolatedWallets = marginIsolatedWallets?.isolated;
		const filteresIsolatedWallets = isolatedWallets?.filter((iw) => iw?.pair?.id === assetPairId);
		const filteredAssetIds = filteresIsolatedWallets?.map((iw) => iw?.asset?.id);
		const filteredAssetList = assetsList?.filter((a) => filteredAssetIds?.includes(a?.id)) || [];
		setFilteredAssetsList(filteredAssetList);
	}, [assetPairId, assetsList, marginIsolatedWallets?.isolated]);

	const handleAssetSelect: (item: IAssetsItem) => void = (item) => {
		const { id } = item;
		setValues({
			...values,
			asset_id: id,
		});
		refetch({ assetId: id, assetPairId });
		setOpen(false);
	};

	const handleAssetSearchInput = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: targetValue } = target;
		const filtered = targetValue
			? filteredAssetsList?.filter((item) => item.code.match(new RegExp(targetValue, 'gi')))
			: assetsList;
		filtered && setFilteredAssetsList(filtered);
	};

	const handleClose = () => {
		onClose && onClose();
	};

	const handleAmountChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: amount } = target;
		let newValues = {
			...values,
			amount,
		};
		if (Number(totalDebt)) {
			const percent = (Number(amount) * 100) / Number(totalDebt);
			newValues = {
				...newValues,
				percent,
			};
		}
		setValues(newValues);
	};

	// TODO: Удалить, оптимизировать слайдер
	const countOrder = (value: number) => {};

	const percentButtonCountValue = (percentValue: number): number => {
		if (!Number(percentValue)) {
			return 0;
		}
		return totalDebt ? (Number(totalDebt) * percentValue) / 100 : 0;
	};

	const handlePercentChange = (percent: number | number[]) => {
		if (!Array.isArray(percent)) {
			const value = percentButtonCountValue(Number(percent));
			setValues({
				...values,
				amount: value,
				percent,
			});
		}
	};

	const handleMaxAmount = () => {
		setValues({
			...values,
			amount: totalDebt,
			percent: 100,
		});
	};

	const submitDisabled =
		!isValid ||
		(!values.amount && values.amount !== 0) ||
		isSubmitting ||
		isFetching ||
		Number(borrowedB) === 0;

	return (
		<div className="popup">
			<button type="button" onClick={handleClose} className="popup__close">
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
						fill="#1C1C29"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
						fill="#1C1C29"
					/>
				</svg>
			</button>
			<div className="popup-header">
				<p className="popup-header__title">
					{String(L.translate('Wallets.MarginAccount.modals.repay'))}
				</p>
			</div>
			<div className="popup-body">
				{isSubmitting || isFetching ? (
					<div className="popup-loader">
						<Loader />
					</div>
				) : (
					<>
						<div className="popup-body-item">
							<div className="withdrawal-info-pop">
								<p>
									{String(L.translate('Wallets.MarginAccount.modals.available_balance'))}:{' '}
									<span>
										{avaliableBalance} {code?.toUpperCase()}
									</span>
								</p>
								<p>
									<span className="tooltip-wrap">
										{String(L.translate('Wallets.MarginAccount.modals.interest_a'))}:
										{/* <span className="tooltip-item">
											<span className="tooltip-item__title">
												<svg
													width="15"
													height="15"
													viewBox="0 0 15 15"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M2.74913 2.74912C3.96448 1.53379 5.64481 0.78125 7.5 0.78125C9.35519 0.78125 11.0355 1.53379 12.2509 2.74912L11.9194 3.08058L12.2509 2.74913C13.4662 3.96448 14.2188 5.64481 14.2188 7.5C14.2188 9.35519 13.4662 11.0355 12.2509 12.2509C11.0355 13.4662 9.35519 14.2188 7.5 14.2188C5.64481 14.2188 3.96448 13.4662 2.74913 12.2509L3.08058 11.9194L2.74912 12.2509C1.53379 11.0355 0.78125 9.35519 0.78125 7.5C0.78125 5.64481 1.53379 3.96448 2.74912 2.74913L2.74913 2.74912ZM7.5 1.71875C5.90344 1.71875 4.45877 2.36533 3.41203 3.41204C2.36532 4.45877 1.71875 5.90344 1.71875 7.5C1.71875 9.09656 2.36533 10.5412 3.41204 11.588C4.45877 12.6347 5.90344 13.2812 7.5 13.2812C9.09656 13.2812 10.5412 12.6347 11.5879 11.5879C12.6347 10.5412 13.2812 9.09656 13.2812 7.5C13.2812 5.90344 12.6347 4.45877 11.588 3.41204C10.5412 2.36533 9.09656 1.71875 7.5 1.71875Z"
														fill="#7D7D4D"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M7.5 3.4375C7.93147 3.4375 8.28125 3.78728 8.28125 4.21875C8.28125 4.65022 7.93147 5 7.5 5C7.06853 5 6.71875 4.65022 6.71875 4.21875C6.71875 3.78728 7.06853 3.4375 7.5 3.4375Z"
														fill="#7D7D4D"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M6.5625 6.25C6.5625 5.99112 6.77237 5.78125 7.03125 5.78125H7.65625C7.91513 5.78125 8.125 5.99112 8.125 6.25V10.625C8.125 10.8839 7.91513 11.0938 7.65625 11.0938C7.39737 11.0938 7.1875 10.8839 7.1875 10.625V6.71875H7.03125C6.77237 6.71875 6.5625 6.50888 6.5625 6.25Z"
														fill="#7D7D4D"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M6.09375 10.625C6.09375 10.3661 6.30362 10.1562 6.5625 10.1562H8.75C9.00888 10.1562 9.21875 10.3661 9.21875 10.625C9.21875 10.8839 9.00888 11.0938 8.75 11.0938H6.5625C6.30362 11.0938 6.09375 10.8839 6.09375 10.625Z"
														fill="#7D7D4D"
													/>
												</svg>
											</span>
											<span className="tooltip tooltip--bottom tooltip--left tooltip--arrow-top-left">
												<span className="tooltip__text">
													<span>
														Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet minus
														nihil neque deserunt nobis.
													</span>
												</span>
											</span>
										</span> */}
									</span>

									<span>
										{interestA} {code?.toUpperCase()}
									</span>
								</p>
								<p>
									{String(L.translate('Wallets.MarginAccount.modals.borrowed_b'))}:{' '}
									<span>
										{borrowedB} {code?.toUpperCase()}
									</span>
								</p>
								<p>
									{String(L.translate('Wallets.MarginAccount.modals.total_debt_a_+_b'))}:{' '}
									<span>
										{totalDebt} {code?.toUpperCase()}
									</span>
								</p>
							</div>
						</div>
						{Number(borrowedB) > 0 ? (
							<div className="flex-column">
								<div className="convert-form-item">
									<div className="convert-form-item__header">
										<p>{String(L.translate('Wallets.MarginAccount.modals.from'))}</p>
										<span>
											{/* {String(L.translate('Wallets.MarginAccount.modals.available'))}: {available}{' '}
											{code?.toUpperCase()} */}
											{String(L.translate('Wallets.MarginAccount.modals.total_debt_a_+_b'))}:{' '}
											{totalDebt} {code?.toUpperCase()}
										</span>
									</div>
									<div className="convert-form-item__field">
										<div className="input">
											<div className="input-wrapper">
												<input
													value={values.amount}
													onChange={handleAmountChange}
													className="input-item input-item--pr50"
													type="text"
													placeholder={
														(amountMin || amountMin === 0) && (totalDebt || totalDebt === 0)
															? String(
																	L.translate('Wallets.MarginAccount.modals.amount_placeholder', {
																		min: String(Number(amountMin)),
																		max: String(Number(totalDebt)),
																	}),
															  )
															: ''
													}
												/>
												<div className="input-icon input-icon--auto input-icon--right">
													<button type="button" onClick={handleMaxAmount} className="input-button">
														{L.translate('Wallets.MarginAccount.modals.max_button')}
													</button>
												</div>
											</div>
											<span className="input-notify">{errors?.amount}</span>
										</div>
										<div className="select-block">
											<div className={`select select--currensy ${open ? ' active' : ''}`}>
												<button
													type="button"
													onClick={toggleOpen}
													className="select__current"
													ref={currentRef}
												>
													<span className="select-current-currensy">
														<span className="select-current-currensy__icon">
															<img src={selectedAsset?.img_path} alt="coin" />
														</span>
														<span className="select-current-currensy__name">
															{selectedAsset?.code?.toUpperCase()}
														</span>
													</span>
													<span className="select__current-arrow">
														<svg
															width="10"
															height="6"
															viewBox="0 0 10 6"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path d="M10 0.833496H1H0L5 5.8335L10 0.833496Z" fill="#9D9DBC" />
														</svg>
													</span>
												</button>
												<div className="select__drop" ref={dropRef}>
													<div className="input">
														<div className="input-wrapper">
															<input
																onInput={handleAssetSearchInput}
																className="input-item"
																type="text"
																placeholder="Enter token name"
															/>
															<div className="input-icon input-icon--right">
																<svg
																	width="20"
																	height="21"
																	viewBox="0 0 20 21"
																	fill="none"
																	xmlns="http://www.w3.org/2000/svg"
																>
																	<path
																		fillRule="evenodd"
																		clipRule="evenodd"
																		d="M1.04199 9.24984C1.04199 4.99266 4.49315 1.5415 8.75033 1.5415C13.0075 1.5415 16.4587 4.99266 16.4587 9.24984C16.4587 13.507 13.0075 16.9582 8.75033 16.9582C4.49315 16.9582 1.04199 13.507 1.04199 9.24984ZM8.75033 2.7915C5.1835 2.7915 2.29199 5.68302 2.29199 9.24984C2.29199 12.8167 5.1835 15.7082 8.75033 15.7082C12.3171 15.7082 15.2087 12.8167 15.2087 9.24984C15.2087 5.68302 12.3171 2.7915 8.75033 2.7915Z"
																		fill="#9D9DBC"
																	/>
																	<path
																		fillRule="evenodd"
																		clipRule="evenodd"
																		d="M5.95065 6.03433C6.66631 5.31872 7.65675 4.875 8.74962 4.875C9.84249 4.875 10.8329 5.31872 11.5486 6.03435C11.7926 6.27843 11.7926 6.67416 11.5486 6.91823C11.3045 7.16231 10.9088 7.16231 10.6647 6.91823C10.1739 6.42745 9.49767 6.125 8.74962 6.125C8.00158 6.125 7.32534 6.42744 6.8345 6.91825C6.59042 7.16232 6.19469 7.1623 5.95062 6.91822C5.70655 6.67413 5.70657 6.2784 5.95065 6.03433Z"
																		fill="#9D9DBC"
																	/>
																	<path
																		fillRule="evenodd"
																		clipRule="evenodd"
																		d="M13.4008 13.9003C13.6449 13.6563 14.0406 13.6563 14.2847 13.9003L17.8203 17.4359C18.0643 17.68 18.0643 18.0757 17.8203 18.3198C17.5762 18.5638 17.1805 18.5638 16.9364 18.3198L13.4008 14.7842C13.1568 14.5401 13.1568 14.1444 13.4008 13.9003Z"
																		fill="#9D9DBC"
																	/>
																</svg>
															</div>
														</div>
													</div>
													<div className="select__drop-scroll">
														<div className="select__drop-item">
															<ul>
																{filteredAssetsList?.map((item) => (
																	<AssetDropItem
																		key={item.id}
																		item={item}
																		handleAssetSelect={handleAssetSelect}
																	/>
																))}
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<PersentSlider
									className="persent-slider"
									value={values.percent}
									onChange={handlePercentChange}
									countOrder={countOrder}
									percentButtonCountValue={percentButtonCountValue}
								/>
							</div>
						) : (
							code && <NoBorrowLabel code={code} />
						)}
					</>
				)}
			</div>
			<div className="popup-footer">
				<button
					type="submit"
					className="button button--size2 button--full-width"
					disabled={submitDisabled}
				>
					{L.translate('Wallets.MarginAccount.modals.confirm')}
				</button>
			</div>
		</div>
	);
};

export default RepayContentIsolated;
