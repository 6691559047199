import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { createNewOrderRequest } from 'redux/reducers/options/reducer';
import { showModal } from 'redux/reducers/options/selectors';
import { daysToExpiration } from 'services/utils/daysToExpiration';
import { notificationContainer } from 'services/utils/notificationContainer';
import reduceToValidNumber from 'services/utils/reduceToValidNumber';
import { BlackScholesService } from 'services/utils/BlackScholes';
import ConfirmPopup from './ConfirmPopup';
import { IOrderCreateData } from './ConfirmPopup/types';
import { IConfirmState } from './types';

/* eslint-disable */
// const impVol = require('implied-volatility');
// const blackSholesPrice = require('black-scholes');

let bs = new BlackScholesService();

const OrderForm = ({
	id,
	contract,
	markPrice,
	stockPrice,
	strikePrice,
	expDate,
}: {
	id: number | undefined;
	contract: string | undefined;
	markPrice: number;
	stockPrice: number;
	strikePrice: number;
	expDate: any;
}) => {
	const [ivActive, setIvActive] = useState<boolean>(false);
	const [quantity, setQuantity] = useState<string>('');
	const [price, setPrice] = useState<string>('');
	const [iv, setIv] = useState<string>('10.00');
	const [confirm, setConfirm] = useState<IConfirmState>('');
	const dispatch = useDispatch();
	const showPopup = useSelector(showModal);

	const [year, month, day] = expDate?.replace(/[%]/g, '')?.slice(0, 10)?.split('-') || [0, 0, 0];

	const preparedOrderData = (): IOrderCreateData => {
		return {
			option_id: id || 0,
			transactions_type: confirm.toLowerCase(),
			amount_asset_request: Number(reduceToValidNumber(quantity)),
			price_entry_for_order: Number(reduceToValidNumber(price)),
		};
	};

	const handleIVActive = (): void => {
		setIvActive(!ivActive);
	};
	const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = e.target;
		if (!value) {
			setQuantity('');
		}
		if (/^\d+[.,]?\d*$/.test(value)) {
			setQuantity(value);
		}
	};

	const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = e.target;
		if (!value) {
			setPrice('');
			setIv('10.00');
		}
		if (/^\d\d*$/.test(value)) {
			setPrice(value);
			let res;
			if (String(contract)[contract!.length - 1] === 'C') {
				res = bs.ivCall(
					stockPrice,
					strikePrice,
					0.001,
					daysToExpiration({ yyyy: year, mm: month, dd: day }),
					Number(value),
					0);
			} else { 
				res = bs.ivPut(
					stockPrice,
					strikePrice,
					0.001,
					daysToExpiration({ yyyy: year, mm: month, dd: day }),
					Number(value),
					0);
			}
			if (res === "Unable to calculate IV because entered values are incorrect") {
				setIv('0');
				return;
			}
			setIv(String(res));
		}
	};

	const handleIvChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = e.target;
		if (!value) {
			setIv('');
			setPrice('');
		}
		if (/^\d+[.,]?\d*$/.test(value)) {
			setIv(value);
			let result;
			if (String(contract)[contract!.length - 1] === 'C') {
				result = bs.bsCall(
					stockPrice,
					strikePrice,
					0.001,
					daysToExpiration({ yyyy: year, mm: month, dd: day }),
					Number(value)/100,
					0);
			} else {
				result = bs.bsPut(
					stockPrice,
					strikePrice,
					0.001,
					daysToExpiration({ yyyy: year, mm: month, dd: day }),
					Number(value)/100,
					0);
			}
			setPrice(result.mark_price.toFixed(0));
		}
	};

	const setDefault = () => {
		setPrice('');
		setQuantity('');
		setIv('10.00');
	};

	const handleBuyClick = () => {
		if (price && quantity) {
			if (ivActive && !iv) {
				notificationContainer(String(L.translate('Trade.Options.OrderForm.messages.set_implied_volatility')), 'info');
				return;
			}
			setConfirm('Buy');
			if (showPopup === false) {
				dispatch(
					createNewOrderRequest({
						option_id: id,
						transactions_type: 'buy',
						amount_asset_request: Number(reduceToValidNumber(quantity)),
						price_entry_for_order: Number(reduceToValidNumber(price)),
					}),
				);
				setDefault();
				return;
			} else return;
		}
		notificationContainer(String(L.translate('Trade.Options.OrderForm.messages.please_enter_qty_and_price')), 'info');
	};

	const handleSellClick = () => {
		if (price && quantity) {
			if (ivActive && !iv) {
				notificationContainer(String(L.translate('Trade.Options.OrderForm.messages.set_iv')), 'info');
				return;
			}
			setConfirm('Sell');
			if (showPopup === false) {
				dispatch(
					createNewOrderRequest({
						option_id: id,
						transactions_type: 'sell',
						amount_asset_request: Number(reduceToValidNumber(quantity)),
						price_entry_for_order: Number(reduceToValidNumber(price)),
					}),
				);
				setDefault();
				return;
			}
			return;
		}
		notificationContainer(String(L.translate('Trade.Options.OrderForm.messages.please_enter_order_quantity')), 'info');
	};

	const handleSellMargin = () => {
		return Math.abs(
			Number(markPrice * Number(quantity) - Number(price) * Number(quantity)),
		).toFixed(2);
	};

	return (
		<>
			<div className="orderform-content-item orderform-content-item--type2">
				<div>
					<div className="orderform-input-title">
						<p />
						<p>
							{String(L.translate('Trade.Options.OrderForm.position'))} <span className="grey"> 0</span>
						</p>
					</div>
					<label className="orderform-input">
						<span className="orderform-input__placeholder">{String(L.translate('Trade.Options.OrderForm.quantity'))}</span>
						<input
							className="orderform-input__data"
							type="text"
							placeholder="0.0"
							value={quantity}
							onChange={handleQuantityChange}
						/>
						<span className="orderform-input__placeholder fw-700">{String(L.translate('Global.btc'))}</span>
					</label>
				</div>
				<div>
					<label className="orderform-input">
						<span className="orderform-input__placeholder">{String(L.translate('Trade.Options.OrderForm.limit_price'))}</span>
						<input
							className="orderform-input__data"
							type="text"
							placeholder="0.0"
							value={price}
							onChange={handlePriceChange}
						/>
						<span className="orderform-input__placeholder fw-700">{String(L.translate('Global.usdt'))}</span>
					</label>
				</div>
			</div>
			<div className="orderform-content-item">
				<div className="switch">
					<label className="switch__label">
						<input
							type="checkbox"
							className="hidden"
							onChange={handleIVActive}
							checked={ivActive}
						/>
						<span className="switch__toggler" />
						<span className="switch__text">{String(L.translate('Trade.Options.OrderForm.iv'))}</span>
					</label>
				</div>
			</div>
			<div className="orderform-content-item orderform-content-item--type2">
				<label className="orderform-input">
					<span className="orderform-input__placeholder">{String(L.translate('Trade.Options.OrderForm.iv'))}</span>
					<input
						className="orderform-input__data"
						type="text"
						placeholder="0.0"
						disabled={!ivActive}
						value={iv}
						onChange={handleIvChange}
					/>
					<span className="orderform-input__placeholder fw-700">%</span>
				</label>
			</div>

			<div className="orderform-content-item orderform-content-item--type2">
				<span>
					<button
						className="button button--full-width button--type12"
						type="button"
						onClick={handleBuyClick}
					>
						{String(L.translate('Trade.Options.OrderForm.buy'))}
					</button>
					<span className="order-button-info">
						<span>{String(L.translate('Trade.Options.OrderForm.buy_margin'))}</span>
						<span>{handleSellMargin()}</span>
					</span>
				</span>
				<span>
					<button
						className="button button--full-width button--type13"
						type="button"
						onClick={handleSellClick}
					>
						{String(L.translate('Trade.Options.OrderForm.sell'))}
					</button>
					<span className="order-button-info order-button-info--ta-r">
						<span>{String(L.translate('Trade.Options.OrderForm.sell_margin'))}</span>
						<span>{handleSellMargin()}</span>
					</span>
				</span>
			</div>
			{confirm && showPopup && (
				<ConfirmPopup
					side={confirm}
					data={preparedOrderData()}
					closeModal={() => setConfirm('')}
					contract={contract}
					setDefault={setDefault}
				/>
			)}
		</>
	);
};

export default memo(OrderForm);
