/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getSpotOrderBookIsLoad } from 'redux/reducers/spotTrade/selectors';
import Loader from 'ui/Loader';
import OrderBookTableItem from '../OrderBookTableItem';
import { IOrderBookTableProps } from './types';

const OrderBookTable: FC<IOrderBookTableProps> = ({ data, type }) => {
	const orderBookListIsLoad = useSelector(getSpotOrderBookIsLoad);
	const totals = data?.map((item) => Number(item.total)) || [0];
	const maxValue = Math.max(...totals);

	return (
		<div
			className={`orderbook-body-item__scroll ${type === 'ask' ? 'orderbook-ask-table-body' : ''}`}
		>
			{data?.length &&
				!orderBookListIsLoad &&
				data.map((el) => (
					<OrderBookTableItem
						key={
							Number(el.price) + Number(el.total) + Number(el.quantity) + Number(el.quantity_left)
						}
						data={el}
						type={type}
						maxValue={maxValue}
					/>
				))}

			{!data?.length && orderBookListIsLoad && (
				<div className="orderbook-loader-wrapper">
					<Loader />
				</div>
			)}
		</div>
	);
};

export default OrderBookTable;
