import { FC } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getSpotRecentTradesIsLoad } from 'redux/reducers/spotTrade/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { getFirstPartPairCode, getLastPartPairCode } from 'services/utils/tradingPairHelpers';
import Loader from 'ui/Loader';
import RecentTradesTableItem from '../RecentTradesTableItem';
import { IRecentTradesTableProps } from './types';

const RecentTradesTable: FC<IRecentTradesTableProps> = ({ data }) => {
	const currentPair = useSelector(getCurrentPair);
	const recentTradesIsLoad = useSelector(getSpotRecentTradesIsLoad);
	return (
		<>
			<div className="trades-list__line trades-list__line--header">
				<span className="grey">
					{String(L.translate('Trade.Spot.SpotRecentTrades.RecentTradesColumnTitles.price_name'))}(
					{getLastPartPairCode(currentPair.pair || '')})
				</span>

				<span className="grey">
					{String(L.translate('Trade.Spot.SpotRecentTrades.RecentTradesColumnTitles.amount_name'))}(
					{getFirstPartPairCode(currentPair.pair || '')})
				</span>

				<span className="grey">
					{String(L.translate('Trade.Spot.SpotRecentTrades.RecentTradesColumnTitles.time_name'))}
				</span>
			</div>
			<div className="trades-list__scroll">
				{recentTradesIsLoad && !data && (
					<div className="recent-trades-loader-wrapper">
						<Loader />
					</div>
				)}

				{!recentTradesIsLoad &&
					data &&
					data.map((el) => <RecentTradesTableItem data={el} key={el.id} />)}
			</div>
		</>
	);
};

export default RecentTradesTable;
