import { FC } from 'react';
import { numberExists } from 'services/utils/numberValidation';
import { ITradeHistoryTabItem } from './types';

const TradeHistoryItem: FC<ITradeHistoryTabItem> = ({ item }) => {
	let direction: string | null = null;
	let directionColor: string | null = null;
	let orderId;
	if (item.transactions_type === 'buy') {
		orderId = item.buy_order_id;
		direction = 'Buy';
		directionColor = 'green';
	}
	if (item.transactions_type === 'sell') {
		orderId = item.sell_order_id;
		direction = 'Sell';
		directionColor = 'red';
	}

	const createdAt = String(item.created_at).split('T');

	const markPrice =
		typeof item.mark_price === 'number'
			? Number(item.mark_price)
					?.toFixed(2)
					?.replace(/\.0{2}$/, '')
			: '-';

	return (
		<div className="tr-wrap">
			<div className="tr">
				<div className="td td--left">
					<p className="td-hidden-name">Contract</p>
					<p className="upper-case">{item.contract_name?.toUpperCase()}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Direction</p>
					<p className={directionColor || ''}>{direction}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Filled Price</p>
					<p>{item.fill_price}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Avg. Filled Price</p>
					<p>{item.fill_price}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Qty (BTC)</p>
					<p>{item.amount_asset_filled}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Implied Volatility</p>
					<p>
						{numberExists(item.iv_coefficient)
							? `${String(item.iv_coefficient?.toFixed(2).replace(/\.00$/, ''))}%`
							: '-'}
					</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Underlying Price</p>
					<p>{item.index_price}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Index Price</p>
					<p>{item.index_price}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Mark Price</p>
					<p>{markPrice}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Fees</p>
					<p>{numberExists(item.fee_usdt) ? item.fee_usdt?.toFixed(3) : '-'}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Trade ID</p>
					<p>{numberExists(item.id) ? item.id : '-'}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Order ID</p>
					<p>{numberExists(orderId) ? orderId : '-'}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Trade Time</p>
					<p>{createdAt[0] || '-'}</p>
					<span>{createdAt[1].split('.')?.[0] || '-'}</span>
				</div>
			</div>
		</div>
	);
};

export default TradeHistoryItem;
