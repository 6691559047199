import PersentSlider from 'components/Trade/TradesBox/PersentSlider';
import { useEffect, useState } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseAsset, getPerpetualIsolatedLeverage } from 'redux/reducers/perpetual/selectors';
import { createOrderRequest } from 'redux/reducers/perpetual/reducer';
import reduceToNumber from 'services/utils/reduceToValidNumber';
import OrderFormButtons from '../OrderFormButtons';
import StopOrder from '../StopOrder';

const LimitOrderForm = ({ balance, priceFromOrderBook }: any) => {
	const asset = useSelector(getBaseAsset);
	const [percent, setPercent] = useState(0);
	const [size, setSize] = useState('');
	const [price, setPrice] = useState(String(asset?.last_price) || '');
	const [stopOrder, setStopOrder] = useState(false);
	const [takeProfit, setTakeProfit] = useState('');
	const [stopLose, setStopLose] = useState('');
	const availableBalance = balance?.available_balance_usdt.toFixed(6) || 0;
	const dispatch = useDispatch();
	const leverage = useSelector(getPerpetualIsolatedLeverage);

	const setInitState = () => {
		setPrice(String(Number(asset?.last_price || 0).toFixed(2)));
		setSize('');
		setTakeProfit('');
		setStopLose('');
		setStopOrder(false);
		setPercent(0);
	};

	const newOrderData = (type: 'buy' | 'sell') => {
		const orderData = {
			asset_future_settings_id: asset?.id,
			type_order: 'limit',
			trigger_by_type_price: 'last_price',
			transactions_type: type,
			amount_asset_request: Number(reduceToNumber(size)).toFixed(6),
			leverage_future_size: Number(leverage),
			price_entry_for_order: Number(reduceToNumber(price)).toFixed(2),
		};
		if (stopOrder) {
			if (takeProfit && !stopLose) {
				return { ...orderData, take_profit: Number(reduceToNumber(takeProfit)).toFixed(2) };
			}
			if (stopLose && !takeProfit) {
				return { ...orderData, stop_loss: Number(reduceToNumber(stopLose)).toFixed(2) };
			}
			return {
				...orderData,
				take_profit: Number(reduceToNumber(takeProfit)).toFixed(2),
				stop_loss: Number(reduceToNumber(stopLose)).toFixed(2),
			};
		}
		return orderData;
	};

	const handlePercentChange = (value: number | number[]) => {
		if (!Array.isArray(value)) {
			setPercent(value);
			setSize(String((((availableBalance * leverage) / Number(price)) * (value / 100)).toFixed(6)));
		}
	};

	const percentButtonCountValue = (percentValue: number) => {
		// Dont touch. Magic. Function is needed for slider
		return percentValue / 100;
	};

	const countOrder = (value: number) => {
		// Dont touch. Magic. Function is needed for slider
	};

	const handleSizeChange = (e: any) => {
		const { value } = e.target;
		const result = reduceToNumber(value);
		if (!value) {
			setSize('');
			setPercent(0);
		}
		if (/^\d+[.,]?\d*$/.test(value)) {
			setSize(String(value));
			setPercent((Number(result) * Number(price) * 100) / (availableBalance * leverage));
		}
	};

	const handlePriceChange = (e: any) => {
		const { value } = e.target;
		const result = reduceToNumber(value);
		if (!value) {
			setPrice('');
		}
		if (/^\d+[.,]?\d*$/.test(value)) {
			if (Number(value) === 0) {
				setPrice(value);
				return;
			}
			setPrice(value);
			setSize(
				String(((((availableBalance * leverage) / Number(result)) * percent) / 100).toFixed(6)),
			);
		}
	};

	const handleBuyClick = () => {
		dispatch(createOrderRequest(newOrderData('buy')));
		setInitState();
	};

	const handleSellClick = () => {
		dispatch(createOrderRequest(newOrderData('sell')));
		setInitState();
	};

	const handleStopOrderClick = () => {
		setStopOrder(!stopOrder);
	};

	const handleTakeProfitChange = (e: any) => {
		const { value } = e.target;
		if (!value) {
			setTakeProfit('');
		}
		if (/^\d+[.,]?\d*$/.test(value)) {
			setTakeProfit(value);
		}
	};

	const handleStopLoseChange = (e: any) => {
		const { value } = e.target;
		if (!value) {
			setStopLose('');
		}
		if (/^\d+[.,]?\d*$/.test(value)) {
			setStopLose(value);
		}
	};

	const allowLongOrShort = (type: string) => {
		if (Number(price) !== 0) {
			if (type === 'long') {
				const value = ((availableBalance * leverage) / Number(reduceToNumber(price))).toFixed(6);
				return `${value} ${asset?.base_asset.toUpperCase() || ''}`;
			}
			const value = ((availableBalance * leverage) / Number(reduceToNumber(price))).toFixed(6);
			return `${value} ${asset?.base_asset.toUpperCase() || ''}`;
		}
		return 0;
	};

	useEffect(() => {
		// If we changed leverage and size value is bigger than balance*leverage then set size to max value
		if (Number(size) > (availableBalance * leverage) / Number(price)) {
			setSize(String((availableBalance * leverage) / Number(price)));
		}
	}, [leverage, size, availableBalance, price]);

	useEffect(() => {
		// If we clicked on some row in orderbook then price field will get value of that row
		if (priceFromOrderBook) {
			setPrice(String(Number(priceFromOrderBook).toFixed(2)));
		}
	}, [priceFromOrderBook]);

	useEffect(() => {
		// After leverage changing all states will be changed to init state
		setPrice(String(Number(asset?.last_price || 0).toFixed(2)));
		setSize('');
		setTakeProfit('');
		setStopLose('');
		setStopOrder(false);
		setPercent(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leverage]);

	useEffect(() => {
		if (size === '0.000000') {
			setSize('');
		}
	}, [size]);

	return (
		<div className="orderform-content-item">
			<div className="orderform-content-item-top">
				<div className="orderform-input-title">
					<p>{String(L.translate('Trade.avbl'))}</p>
					<p>
						{availableBalance} <span className="grey">USDT</span>
					</p>
				</div>
				<label className="orderform-input">
					<span className="orderform-input__placeholder">
						{String(L.translate('Trade.Perpetual.OrderForm.price_field_placeholder'))}
					</span>
					<input
						className="orderform-input__data"
						type="text"
						placeholder="0.0"
						value={price}
						onChange={handlePriceChange}
					/>
					<span className="orderform-input__placeholder fw-700">USDT</span>
				</label>
				<label className="orderform-input">
					<span className="orderform-input__placeholder">
						{String(L.translate('Trade.Perpetual.OrderForm.size_field_placeholder'))}
					</span>
					<input
						className="orderform-input__data"
						type="text"
						placeholder="0.0"
						value={size}
						onChange={handleSizeChange}
					/>
					<span className="orderform-input__placeholder fw-700">
						{asset?.base_asset.toUpperCase() || '-'}
					</span>
				</label>
				<div className="slider-wrapper">
					<PersentSlider
						value={percent}
						onChange={handlePercentChange}
						countOrder={countOrder}
						percentButtonCountValue={percentButtonCountValue}
					/>
				</div>
				<div className="orderform-item">
					<div className="orderform-item__title">
						<p>{String(L.translate('Trade.Perpetual.OrderForm.available_title'))}</p>
					</div>
					<div className="checkbox checkbox--type3 checkbox--no-mb">
						<label className="checkbox__label">
							<input
								type="checkbox"
								className="hidden"
								onChange={handleStopOrderClick}
								checked={stopOrder}
							/>
							<span className=" checkbox__item">
								<span className="checkbox__item-icon">
									<svg
										width="17"
										height="17"
										viewBox="0 0 17 17"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M14.5426 4.58269C14.7501 4.79016 14.7501 5.12653 14.5426 5.33399L7.45931 12.4173C7.25184 12.6248 6.91547 12.6248 6.70801 12.4173L3.16634 8.87566C2.95888 8.66819 2.95888 8.33183 3.16634 8.12436C3.37381 7.91689 3.71018 7.91689 3.91764 8.12436L7.08366 11.2904L13.7913 4.58269C13.9988 4.37523 14.3352 4.37523 14.5426 4.58269Z"
											fill="#FF57D4"
										/>
									</svg>
								</span>
							</span>
							<span className="checkbox__text">
								{String(L.translate('Trade.Perpetual.OrderForm.stop_order_title'))}
							</span>
						</label>
					</div>
				</div>
			</div>
			{stopOrder ? (
				<StopOrder
					takeProfitValue={takeProfit}
					stopLoseValue={stopLose}
					takeProfitChanger={handleTakeProfitChange}
					stopLoseChanger={handleStopLoseChange}
				/>
			) : (
				''
			)}

			<div className="orderform-content-item-bottom">
				<div className="orderform-content-item-bottom__title">
					<p>{String(L.translate('Trade.Perpetual.OrderForm.type_title'))}</p>
				</div>
				<OrderFormButtons buyClick={handleBuyClick} sellClick={handleSellClick} />
				<div className="orderform-content-item-info">
					<p>
						{String(L.translate('Trade.Perpetual.OrderForm.allow_long'))}{' '}
						<span>{allowLongOrShort('long')}</span>
					</p>
					<p>
						{String(L.translate('Trade.Perpetual.OrderForm.allow_short'))}{' '}
						<span>{allowLongOrShort('short')}</span>
					</p>
				</div>
			</div>
		</div>
	);
};
export default LimitOrderForm;
