import { FC } from 'react';
import L from 'i18n-react';
import { api } from 'services';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { notificationContainer } from 'services/utils/notificationContainer';
import { IStopOrderHistoryTabItem } from './types';

const StopOrderHistoryTabItem: FC<IStopOrderHistoryTabItem> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td">
				{/* Contract */}
				<p>{item.base_asset?.toUpperCase()}/USDT</p>
			</div>
			<div className="td">
				{/* ID */}
				<p>{item.order_id}</p>
			</div>
			<div className="td">
				{/* Creared At */}
				<p>{item.created_at && customDateFormat(item.created_at)}</p>
			</div>
			<div className="td">
				{/* End Time */}
				<p>{item.updated_at && customDateFormat(item.updated_at)}</p>
			</div>
			<div className="td">
				{/* Side */}
				<p>
					{item.transactions_type === 'buy'
						? String(L.translate('Trade.Perpetual.long'))
						: String(L.translate('Trade.Perpetual.short'))}
				</p>
			</div>
			<div className="td">
				{/* Order Price */}
				<p>{item.price_avarage_completed}</p>
			</div>
			<div className="td">
				{/* Size */}
				<p>{item.amount_asset_request}</p>
			</div>
			<div className="td">
				{/* Order Filled */}
				<p>
					{item.amount_asset_filled === 0 || item.amount_asset_filled
						? `${String(item.amount_asset_filled)} ${
								item.base_asset ? String(item.base_asset) : ''
						  }`
						: ''}
				</p>
			</div>
			<div className="td">
				{/* Fill Price */}
				<p>{item.price_entry_for_order}</p>
			</div>
			<div className="td">
				{/* Stop Order */}
				<p>{item.stop_order}</p>
			</div>
			<div className="td td--right">
				{/* Close reason */}
				<p className="capitalize">{item.close_reason}</p>
			</div>
		</div>
	);
};
export default StopOrderHistoryTabItem;
