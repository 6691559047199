import { FC } from 'react';
import { Auth } from 'layouts/Auth';
import { CheckEmailConfirm } from 'components/Forms/CheckEmailConfirm';

export const CheckEmail: FC = () => {
	const handleSubmitEmail = (values: any) => {
		values;
		// dispatch(forgotPasswordRequest(values));
	};
	return (
		<Auth title="CheckEmail">
			<div className="authorization-section">
				<div className="authorization-section__container">
					<div className="authorization">
						<CheckEmailConfirm emailSubmit={handleSubmitEmail} />
					</div>
				</div>
			</div>
		</Auth>
	);
};
