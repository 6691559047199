import { optionsTrade } from './optionsTrade/index';
import { assets } from './assets';
import { auth } from './auth/index';
import { IApi } from './types';
import { settings } from './settings/index';
import { spotTrade } from './spotTrade';
import { wallets } from './wallets';
import { assetPairs } from './assetPairs';
import { marginWallets } from './marginWallets';
import { orders } from './orders';
import { tradingChart } from './tradingChart';
import { payments } from './payments';
import { convert } from './convert';
import { twoFa } from './twoFa';
import { perpetualTrade } from './perpetual';
import { analytics } from './analytics';

const api: IApi = {
	assets,
	assetPairs,
	auth,
	settings,
	spotTrade,
	tradingChart,
	orders,
	marginWallets,
	wallets,
	payments,
	convert,
	twoFa,
	perpetualTrade,
	optionsTrade,
	analytics,
};

export { api };
