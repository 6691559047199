import { FC, useEffect, useCallback, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import L from 'i18n-react';
import Loader from 'ui/Loader';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getPerpetualOpenPositions,
	getTransactionHistoryLoader,
	getPerpetualBaseAssets,
	getBaseAsset,
} from 'redux/reducers/perpetual/selectors';
import { getPerpetualOpenPositionsRequest } from 'redux/reducers/perpetual/reducer';
import LoginTradeTablePlug from '../TradeTablePlug';
import { POSITIONS } from '../mock';
import PositionsTabItem from './PositionsTabItem';
import { IPositionsTab } from './types';

const PositionTab: FC<IPositionsTab> = ({ showAll }) => {
	const dispatch = useDispatch();
	const openPositions = useSelector(getPerpetualOpenPositions);
	const loader = useSelector(getTransactionHistoryLoader);
	// const openPositions = POSITIONS;
	const baseAssets = useSelector(getPerpetualBaseAssets);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const baseAsset = useSelector(getBaseAsset);
	const { perPage, currentPage } = usePagination();

	const fetchOpenPositions = useCallback(() => {
		if (authIsAuthenticated && baseAsset?.base_asset) {
			dispatch(
				getPerpetualOpenPositionsRequest({
					is_show_all_asset: showAll ? 1 : 0,
					base_asset: baseAsset.base_asset,
					per_page: perPage,
					current_page: currentPage,
				}),
			);
		}
	}, [authIsAuthenticated, baseAsset?.base_asset, currentPage, dispatch, perPage, showAll]);

	useEffect(() => {
		fetchOpenPositions();
	}, [fetchOpenPositions]);

	const renderContent: () => ReactNode = () => {
		if (!authIsAuthenticated) {
			return <LoginTradeTablePlug />;
		}
		if (loader) {
			return (
				<div className="popup-loader">
					<Loader />
				</div>
			);
		}
		if (openPositions && openPositions?.data?.length > 0) {
			return openPositions?.data?.map((item) => (
				<PositionsTabItem
					key={item.id}
					item={item}
					baseAssets={baseAssets}
					refetch={fetchOpenPositions}
				/>
			));
		}
		return <TableEmptyPlug />;
	};

	return (
		<div className="table table--trade table--trade-perpetual-positions">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Positions.contract'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Positions.size'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Positions.value'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Positions.entry_price'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Positions.mark_price'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Positions.liquidation_price'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.margin'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Positions.unrealised_pnl'))}[ROE]</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Positions.realised_pnl'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Positions.price'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Positions.amount'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Positions.reduce_close'))}</p>
					</div>
					<div className="td td--right">
						<p>{String(L.translate('Trade.Perpetual.Positions.sl_tp'))}</p>
					</div>
				</div>
			</div>
			<div className="table-body">{renderContent()}</div>
			{openPositions?.last_page &&
				Number(openPositions?.total) > Number(openPositions?.per_page) && (
					<Pagination lastPage={openPositions.last_page} getCurrentHref={(s) => String(s)} />
				)}
		</div>
	);
};
export default PositionTab;
