import { FC, useEffect, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import L from 'i18n-react';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getOrderHistory,
	getOrderHistoryLoader,
	getBaseAsset,
} from 'redux/reducers/perpetual/selectors';
import { getOrderHistoryRequest } from 'redux/reducers/perpetual/reducer';
import { IPerpetualOrderHistoryItem } from 'redux/reducers/perpetual/types';
import Loader from 'ui/Loader';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';
import LoginTradeTablePlug from '../TradeTablePlug';
import OrderHistoryTabItem from './OrderHistoryTabItem';
import { IOrderHistoryTab } from './types';

const OrderHistoryTab: FC<IOrderHistoryTab> = ({ showAll }) => {
	const dispatch = useDispatch();
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const orderHistory = useSelector(getOrderHistory);
	const loader = useSelector(getOrderHistoryLoader);
	// const orderHistory = ORDER_HISTORY;
	const baseAsset = useSelector(getBaseAsset);

	const { perPage, currentPage } = usePagination();

	useEffect(() => {
		if (authIsAuthenticated) {
			if (baseAsset?.base_asset) {
				dispatch(
					getOrderHistoryRequest({
						is_show_all_asset: showAll ? 1 : 0,
						base_asset: baseAsset?.base_asset,
						per_page: perPage,
						current_page: currentPage,
					}),
				);
			}
		}
	}, [baseAsset?.base_asset, currentPage, dispatch, perPage, showAll, authIsAuthenticated]);

	const renderContent: () => ReactNode = () => {
		if (!authIsAuthenticated) {
			return <LoginTradeTablePlug />;
		}
		if (loader) {
			return (
				<div className="popup-loader">
					<Loader />
				</div>
			);
		}
		if (orderHistory && orderHistory?.data.length > 0) {
			return orderHistory?.data?.map((item: IPerpetualOrderHistoryItem, idx: number) => (
				// eslint-disable-next-line react/no-array-index-key
				<OrderHistoryTabItem key={idx} item={item} />
			));
		}
		return <TableEmptyPlug />;
	};

	return (
		<div className="table table--trade table--trade-perpetual-order-history">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.contract'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.id'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.created_at'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.end_time'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.side'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.order_price'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.size'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.order_filled'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.fill_price'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.stop_order'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.close_reason'))}</p>
					</div>
				</div>
			</div>
			<div className="table-body">{renderContent()}</div>
			{orderHistory?.last_page && Number(orderHistory?.total) > Number(orderHistory?.per_page) && (
				<Pagination lastPage={orderHistory.last_page} getCurrentHref={(s) => String(s)} />
			)}
		</div>
	);
};

export default OrderHistoryTab;
