import React from 'react';
import L from 'i18n-react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import { convertToFixed } from 'services/utils/convertToFixed';
import { IProps } from './index';

const Semicircle = ({ data }: IProps) => {
	Chart.register(ArcElement);
	return (
		<div className="analitics-block__item">
			<div className="container">
				<div className="change-row">
					{data?.map((e) => {
						return (
							<div key={e.id} className="price-change">
								<div className="price-change__graph">
									<Doughnut
										data={{
											labels: ['buy', 'sell'],
											datasets: [
												{
													data: [e.buy || 0, e.sell || 0],
													backgroundColor: ['#219653', '#eb5757'],
													// borderColor: '#2c2e3a',
												},
											],
										}}
										width={27}
										height={10}
										options={{
											rotation: -90,
											circumference: 180,
										}}
									/>
								</div>
								<div className="price-change__text">
									<span className="period">{e.period}</span>
									<span className="change-type">
										{String(L.translate('Analytics.Semicircle.change_type_buy'))}
									</span>
									<span className="change-value">{convertToFixed(e.buy)}</span>
									<span className="change-type change-type--type2">
										{String(L.translate('Analytics.Semicircle.change_type_sell'))}
									</span>
									<span className="change-value">{convertToFixed(e.sell)}</span>
									<span className="change-value">
										<span>{String(L.translate('Analytics.Semicircle.ratio'))}:</span> {e.ratio}
									</span>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Semicircle;
