/* eslint-disable no-debugger */
import { FC, Fragment, useState, useEffect, useMemo, useRef, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import L from 'i18n-react';
import Loader from 'ui/Loader';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getPositionsLoader,
	getPositions,
	getStructureLoader,
	getStructure,
	selectOrdersList,
} from 'redux/reducers/options/selectors';
import { getPositionsRequest, getStructureRequest } from 'redux/reducers/options/reducer';
import { IPositionsItem } from 'redux/reducers/options/types';
import LoginTradeTablePlug from 'components/Trade/TradeTablePlug';
import { useGetOptionsMap } from '../hooks';
import { IPosTabItemSummarized } from './PositionsTabItem/types';
import PositionsTabItem from './PositionsTabItem';
import { summarizeItems, summarizeFolders } from './PositionsTabItem/utils';

const PositionTab = () => {
	const dispatch = useDispatch();
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const positions = useSelector(getPositions);
	const positionLoader = useSelector(getPositionsLoader);
	const ordersList = useSelector(selectOrdersList);
	const structureLoader = useSelector(getStructureLoader);
	const structure = useSelector(getStructure);
	const loader = positionLoader || structureLoader;
	const [btcOpened, setBtcOpened] = useState(true);
	const [optionsOpened, setOptionsOpened] = useState(true);
	const [openedLevel3, setOpenedLevel3] = useState<number | null>(null);
	const optionsList = useGetOptionsMap();

	const toggleBtcOpened = () => {
		setBtcOpened((prev) => !prev);
	};

	const toggleOptionsOpened = () => {
		setOptionsOpened((prev) => !prev);
	};

	const toggleOpenedLevel3 = (id: number) => {
		setOpenedLevel3((prev) => (prev === id ? null : id));
	};

	useEffect(() => {
		dispatch(getPositionsRequest({}));
		dispatch(getStructureRequest({}));
	}, [dispatch]);

	const groupedPositions = useMemo(() => {
		const result = structure?.reduce((reslt: Record<number, IPositionsItem[]>, currentStruct) => {
			const { id: structId } = currentStruct;
			const currentPositions = positions?.filter((pos) => pos?.option_structure_id === structId);
			if (currentPositions && currentPositions?.length > 0) {
				return {
					...reslt,
					[structId]: currentPositions,
				};
			}
			return reslt;
		}, {});
		return result || {};
	}, [positions, structure]);

	const rootSummarized = useMemo(() => {
		const newRootValues = Object.keys(groupedPositions)?.reduce((acc, structureId) => {
			const summarized = summarizeItems(groupedPositions?.[+structureId], optionsList);
			return {
				...acc,
				[+structureId]: summarized,
			};
		}, {});
		return summarizeFolders(Object.values(newRootValues));
	}, [groupedPositions, optionsList]);

	const renderContent: () => ReactNode = () => {
		if (!authIsAuthenticated) {
			return <LoginTradeTablePlug />;
		}
		if (loader) {
			return (
				<div className="popup-loader">
					<Loader />
				</div>
			);
		}
		if (groupedPositions && Object.keys(groupedPositions)?.length) {
			return (
				<>
					<PositionsTabItem
						opened={btcOpened}
						onClick={toggleBtcOpened}
						level={1}
						title="BTC"
						summarized={rootSummarized}
						isFolder
						containsFolder
					/>
					{btcOpened && (
						<>
							<PositionsTabItem
								opened={optionsOpened}
								onClick={toggleOptionsOpened}
								level={2}
								title="Options"
								summarized={rootSummarized}
								isFolder
								containsFolder
							/>
							{optionsOpened &&
								Object.keys(groupedPositions)?.map((structureId, idx) => {
									const structureName = structure?.find(
										(strc) => strc?.id === +structureId,
									)?.name_structure;
									const level3Opened = +structureId === openedLevel3;
									const summarized = summarizeItems(groupedPositions?.[+structureId], optionsList);
									const level3Row = (
										<PositionsTabItem
											level={3}
											opened={+structureId === openedLevel3}
											onClick={() => toggleOpenedLevel3(+structureId)}
											title={structureName?.toUpperCase()}
											summarized={summarized}
											isFolder
											containsFolder
										/>
									);
									return (
										<Fragment key={structureId}>
											{level3Row}
											{level3Opened &&
												groupedPositions?.[+structureId]?.map((item) => (
													<PositionsTabItem
														key={item.id}
														level={4}
														avgPrice={summarized?.avgPrice}
														item={item}
													/>
												))}
										</Fragment>
									);
								})}
						</>
					)}
				</>
			);
		}

		return <TableEmptyPlug />;
	};

	return (
		<div className="table table--wallet table--options table--options-position">
			<div className="table-header">
				<div className="tr">
					<div className="td td--left">
						<p>{String(L.translate('Trade.Options.columns.contract'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.qty'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.entry_price'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.session_price'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.im'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.mm'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.delta'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.gamma'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.vega'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.theta'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.mark_price'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.unreleasedPnl'))}</p>
					</div>
					<div className="td td--right">
						<p>{String(L.translate('Trade.Options.columns.action'))}</p>
					</div>
				</div>
			</div>
			<div className="table-body">{renderContent()}</div>
		</div>
	);
};

export default PositionTab;
