import { FC } from 'react';
import L from 'i18n-react';
import { WalletLayout } from 'layouts/Wallet';
import MarketTypeToggle from 'components/MarginWallets/MarketTypeToggle';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import { getLocaleDateFromTimestamp } from 'services/utils/dateAndTimeHelpers';
import { isIsolated } from './guards';
import { IBorrowPage } from './types';

const BorrowPage: FC<IBorrowPage> = ({ borrow, isolated, loading }) => {
	return (
		<WalletLayout title={String(L.translate('Wallets.MarginAccount.borrowing.html_title'))}>
			<div className="wallet-content">
				<div className="wallet-content-header flex flex-sb">
					<div className="wallet-content-header__title">
						<p>{String(L.translate('Wallets.MarginAccount.borrowing.title'))}</p>
					</div>
					{/* <MarketTypeToggle /> */}
				</div>
				<div className="wallet-content-tables">
					<div
						className={`table table--wallet table--borrowing-${isolated ? 'isolated' : 'cross'}`}
					>
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.coin'))}</p>
								</div>
								{isolated && (
									<div className="td">
										<p>{String(L.translate('Wallets.MarginAccount.columns.pair'))}</p>
									</div>
								)}
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.date'))}</p>
								</div>
								<div className="td td--right">
									<p>{String(L.translate('Wallets.MarginAccount.columns.amount'))}</p>
								</div>
							</div>
						</div>
						<div className="table-body">
							{loading ? (
								<div className="popup-loader">
									<Loader />
								</div>
							) : (
								<>
									{!borrow?.data || borrow?.data?.length === 0 ? (
										<TableEmptyPlug />
									) : (
										borrow?.data?.map((item) => (
											<div key={item.id} className="tr">
												<div className="td">
													<p>{item.asset.code.toUpperCase()}</p>
												</div>
												{isIsolated(item) && (
													<div className="td">
														<p>{item.pair.code.toUpperCase()}</p>
													</div>
												)}
												<div className="td">
													<p className="grey">
														{getLocaleDateFromTimestamp(Number(item.created_at))}
													</p>
												</div>
												<div className="td td--right">
													<p>{item.amount}</p>
												</div>
											</div>
										))
									)}
								</>
							)}
						</div>
					</div>
				</div>
				{borrow?.last_page && Number(borrow?.total) > Number(borrow?.per_page) && (
					<Pagination lastPage={borrow.last_page} getCurrentHref={(s) => String(s)} />
				)}
			</div>
		</WalletLayout>
	);
};

export default BorrowPage;
