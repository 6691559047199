import { FC } from 'react';
import L from 'i18n-react';
import { roundingNumber } from 'services/utils/roundingNumber';
import { IAdjustLeverageMaticTableItem } from './types';

const AdjustLeverageTableItem: FC<IAdjustLeverageMaticTableItem> = ({
	leverage,
	margin,
	liquidationPrice,
}) => {
	return (
		<div className="adjust-leverage-table__line">
			<div className="adjust-leverage-table__line-item">
				{String(L.translate('Trade.Perpetual.Modals.new_leverage'))}
			</div>
			<div className="adjust-leverage-table__line-item">
				{String(L.translate('Trade.Perpetual.Modals.isolated'))} {leverage}X
			</div>
			<div className="adjust-leverage-table__line-item">
				{String(L.translate('Trade.Perpetual.Modals.new_margin'))}
			</div>
			{margin && (
				<div className="adjust-leverage-table__line-item">{roundingNumber(margin)} USDT</div>
			)}
			<div className="adjust-leverage-table__line-item">
				{String(L.translate('Trade.Perpetual.Modals.new_liquidation_price'))}
			</div>
			{liquidationPrice && (
				<div className="adjust-leverage-table__line-item">{roundingNumber(liquidationPrice)}</div>
			)}
		</div>
	);
};
export default AdjustLeverageTableItem;
