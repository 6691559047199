import L from 'i18n-react';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTwoFaEnableStatus, getTwoFaLoading } from 'redux/reducers/twoFa/selectors';
import { twoFaCheckEnabledRequest } from 'redux/reducers/twoFa/reducer';
import Loader from 'ui/Loader';
import EnableTwoFa from './EnableTwoFa';
import DisableTwoFa from './DisableTwoFa';

const AccountSettingsTwoFa = () => {
	const dispatch = useDispatch();
	const twoFaLoading = useSelector(getTwoFaLoading);
	const twoFaEnableStatus = useSelector(getTwoFaEnableStatus);
	useEffect(() => {
		dispatch(twoFaCheckEnabledRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const twoFaComponent = useMemo(() => {
		const twoFaStatus = twoFaEnableStatus ? twoFaEnableStatus.includes('disabled') : false;
		if (twoFaStatus) {
			return <EnableTwoFa />;
		}
		return <DisableTwoFa />;
	}, [twoFaEnableStatus]);

	return (
		<div className="setting-content">
			<div className="setting-content-header flex flex-sb">
				<div className="setting-content-header__title">
					<p>{String(L.translate('Account.TwoFactorAuth.two_factor_auth_page_title'))}</p>
				</div>
			</div>
			{twoFaLoading ? (
				<div className="table-loader-wrapper">
					<Loader />
				</div>
			) : (
				<div className="setting-content-body setting-content-body--type2">
					<div className="setting-content-body-left">{twoFaComponent}</div>
				</div>
			)}
		</div>
	);
};

export default AccountSettingsTwoFa;
