import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { IWalletNavLink } from './types';

const WalletNavLink: FC<IWalletNavLink> = ({ title, to, icon }) => {
	return (
		<NavLink to={to} className={(isActive) => `${isActive ? ' active' : ''}`}>
			<div className="sidebar-nav-item__icon">{icon}</div>
			<div className="sidebar-nav-item__text">
				<p>{title}</p>
			</div>
		</NavLink>
	);
};

export default WalletNavLink;
