import L from 'i18n-react';
import { Referral } from 'components/Referral';
import { Page } from 'layouts/page';
import { FC } from 'react';

export const ReferralPage: FC = () => {
	return (
		<Page title={String(L.translate('Account.Referral.helmet_title'))}>
			<Referral />
		</Page>
	);
};
