import { spawn } from 'redux-saga/effects';
import { tradingSettingsSaga } from './reducers/tradingSettings/saga';
import { assetsSaga } from './reducers/assets/saga';
import { spotTradeSaga } from './reducers/spotTrade/saga';
import { authSaga } from './reducers/auth/saga';
import { marginWalletsSaga } from './reducers/marginWallets/saga';
import { settingsSaga } from './reducers/settings/saga';
import { socketsSaga } from './reducers/sockets/saga';
import { walletsSaga } from './reducers/wallets/saga';
import { ordersSaga } from './reducers/orders/saga';
import { assetPairsSaga } from './reducers/assetPairs/saga';
import { twoFaSaga } from './reducers/twoFa/saga';
import { perpetualSaga } from './reducers/perpetual/saga';
import { optionsSaga } from './reducers/options/saga';
import { analyticsSaga } from './reducers/analytics/saga';
import { exchangeSaga } from './reducers/exchange/saga';

export function* rootSaga() {
	yield spawn(assetsSaga);
	yield spawn(authSaga);
	yield spawn(settingsSaga);
	yield spawn(socketsSaga);
	yield spawn(tradingSettingsSaga);
	yield spawn(spotTradeSaga);
	yield spawn(marginWalletsSaga);
	yield spawn(ordersSaga);
	yield spawn(walletsSaga);
	yield spawn(assetPairsSaga);
	yield spawn(twoFaSaga);
	yield spawn(perpetualSaga);
	yield spawn(optionsSaga);
	yield spawn(analyticsSaga);
	yield spawn(exchangeSaga);
}
