import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services';
import {
	IApiGetFavoritePairsResponse,
	IApiToggleFavoritePairParams,
} from 'services/api/assetPairs/types';
import { responseErrors } from 'services/http/responseErrors';
import {
	getAssetPairsRequest,
	getAssetPairsRequestFavorite,
	getAssetPairsSuccessFavorite,
	getAssetPairsSuccess,
	getAssetPairsFeesAndLimitsRequest,
	getAssetPairsFeesAndLimitsSuccess,
	assetPairsInitState,
	putAssetPairsRequestFavoriteId,
	getAssetPairsErrorFavorite,
	getTopAssetPairsRequest,
	getTopAssetPairsError,
	getTopAssetPairsSuccess,
	getVolume24Request,
	getVolume24Success,
} from './reducer';
import {
	IAssetPairsFeesAndLimitsResponsePayload,
	IAssetPairsResponsePayload,
	IAssetTopPairsItem,
} from './types';

function* assetPairsRequestWorkerFavorite() {
	try {
		yield put(showLoading());
		const data: IApiGetFavoritePairsResponse = yield call(api.assetPairs.getAssetPairsFavorite);
		yield put(getAssetPairsSuccessFavorite(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getAssetPairsErrorFavorite());
	} finally {
		yield put(hideLoading());
	}
}

function* assetPairsRequestWorkerFavoriteId(action: PayloadAction<IApiToggleFavoritePairParams>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.assetPairs.putAssetPairsFavorite, payload);
		yield put(getAssetPairsRequestFavorite());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* assetPairsRequestWorker() {
	try {
		yield put(showLoading());
		const response: IAssetPairsResponsePayload = yield call(api.assetPairs.getAssetPairs);
		yield put(getAssetPairsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(assetPairsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* assetPairsFeesAndLimitsRequestWorker() {
	try {
		yield put(showLoading());
		const response: IAssetPairsFeesAndLimitsResponsePayload = yield call(
			api.assetPairs.getAssetPairsFeesAndLimits,
		);
		yield put(getAssetPairsFeesAndLimitsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(assetPairsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getTopAssetPairsRequestWorker() {
	try {
		yield put(showLoading());
		const data: IAssetTopPairsItem[] = yield call(api.assetPairs.getTopAssetPairs);
		yield put(getTopAssetPairsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getTopAssetPairsError());
	} finally {
		yield put(hideLoading());
	}
}

function* getVolume24RequestWorker(): any {
	try {
		yield put(showLoading());
		const data: any = yield call(api.assetPairs.getMarket24VolumeUsd);
		yield put(getVolume24Success(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getTopAssetPairsError());
	} finally {
		yield put(hideLoading());
	}
}

export function* assetPairsSaga() {
	yield takeEvery(getAssetPairsRequest.type, assetPairsRequestWorker);
	yield takeLatest(getAssetPairsRequestFavorite, assetPairsRequestWorkerFavorite);
	yield takeLatest(putAssetPairsRequestFavoriteId, assetPairsRequestWorkerFavoriteId);
	yield takeEvery(getAssetPairsFeesAndLimitsRequest.type, assetPairsFeesAndLimitsRequestWorker);
	yield takeEvery(getTopAssetPairsRequest.type, getTopAssetPairsRequestWorker);
	yield takeEvery(getVolume24Request.type, getVolume24RequestWorker);
}
