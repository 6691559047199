import { FC, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import L from 'i18n-react';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getPerpetualMyOrders,
	getMyStopOrders,
	getBaseAsset,
} from 'redux/reducers/perpetual/selectors';
import {
	getMyOrdersRequest,
	getMyStopOrdersRequest,
	getPerpetualOpenPositionsRequest,
} from 'redux/reducers/perpetual/reducer';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getSearchParam, deleteSearchParam } from 'services/utils/url';
import ConfirmDeletePopup from 'ui/ConfirmDeletePopup';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import MyOrdersTab from './MyOrdersTab';
import TradeTableItem from './MyOrdersTab/TableItem';
import OrderHistoryTab from './OrderHistoryTab';
import PositionTab from './PositionsTab';
import TradeHistoryTab from './TradeHistoryTab';
import StopOrdersTab from './StopOrdersTab';
import StopOrderHistoryTab from './StopOrderHistoryTab';
import ClosedHistoryTab from './ClosedHistoryTab';
import LoginTradeTablePlug from './TradeTablePlug';
import TransactionsTab from './TransactionsTab';
import { MY_ORDERS } from './mock';

const TradeTables: FC = () => {
	const dispatch = useDispatch();
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const baseAssets = useSelector(getBaseAsset);
	const location = useLocation();
	const history = useHistory();
	const [openModal, setOpenModal] = useState(false);
	const [showAll, setShowAll] = useState(true);

	const { pathname } = location;

	const currentTableTab = getSearchParam('history');

	const removeCurrentPage = () => {
		const search = deleteSearchParam('currentPage');
		history.replace({
			pathname: location.pathname,
			search,
		});
	};

	const toggleHistoryTab: (_history: string) => void = (_history) => {
		history.replace({
			pathname,
			search: `?history=${_history}`,
		});
	};

	const handleCheck = () => {
		removeCurrentPage();
		setShowAll((prev) => !prev);
	};

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const closeModal = () => {
		setOpenModal(false);
	};

	const myOrders = useSelector(getPerpetualMyOrders);
	const stopOrders = useSelector(getMyStopOrders);

	const myOrdersTotal = myOrders?.total === 0 || myOrders?.total ? String(myOrders?.total) : null;

	const stopOrdersTotal =
		stopOrders?.total === 0 || stopOrders?.total ? String(stopOrders?.total) : null;

	// TODO: Количество OpenOrders и stopOrders должно передаваться по сокетам
	// useEffect(() => {
	// 	if (baseAssets?.base_asset && !myOrdersRequested) {
	// 		setMyOrdersRequested(true);
	// 		dispatch(
	// 			getMyOrdersRequest({
	// 				is_show_all_asset: showAll ? 1 : 0,
	// 				base_asset: baseAssets.base_asset,
	// 				per_page: 1,
	// 			}),
	// 		);
	// 		dispatch(
	// 			getMyStopOrdersRequest({
	// 				is_show_all_asset: showAll ? 1 : 0,
	// 				base_asset: baseAssets.base_asset,
	// 				per_page: 1,
	// 			}),
	// 		);
	// 	}
	// }, [baseAssets?.base_asset, dispatch, myOrdersRequested, showAll]);

	const handleTabChange = (e: any) => {
		const { name } = e.target;
		// setCurrentTab(name);
		toggleHistoryTab(name);
	};
	const tableTabs = {
		positions: <PositionTab showAll={showAll} />,
		closed: <ClosedHistoryTab showAll={showAll} />,
		myOrders: <MyOrdersTab showAll={showAll} />,
		orderHistory: <OrderHistoryTab showAll={showAll} />,
		stopOrders: <StopOrdersTab showAll={showAll} />,
		stopHistory: <StopOrderHistoryTab showAll={showAll} />,
		tradeHistory: <TradeHistoryTab showAll={showAll} />,
		transactions: <TransactionsTab showAll={showAll} />,
	};

	const handleCloseAll = async () => {
		if (authIsAuthenticated) {
			try {
				await api.perpetualTrade.closeAllPositionsByMarket({
					base_asset: baseAssets?.base_asset,
					is_show_all_asset: showAll ? 1 : 0,
				});
				notificationContainer(String(L.translate('Trade.Perpetual.messages.closed')), 'success');
				if (baseAssets?.base_asset) {
					dispatch(
						getPerpetualOpenPositionsRequest({
							is_show_all_asset: showAll ? 1 : 0,
							base_asset: baseAssets?.base_asset,
						}),
					);
				}
				setOpenModal(false);
			} catch (error) {
				notificationContainer(
					String(L.translate('Trade.Perpetual.messages.failed_to_close_position')),
					'error',
				);
				console.error(error);
			}
		}
	};

	const closeAllTitle = String(
		L.translate('Trade.Perpetual.messages.confirm_delete_all_positions'),
	);
	const closeAllBody = String(
		L.translate('Trade.Perpetual.messages.confirm_delete_all_positions_body_message'),
	);

	return (
		<SocketWithPrivateTopicConnectWrapper
			topicName={`opened_user_orders_future:future:${baseAssets?.base_asset || 'btc'}`}
		>
			<SocketWithPrivateTopicConnectWrapper
				topicName={`closed_user_orders_future:future:${baseAssets?.base_asset || 'btc'}`}
			>
				<div className="trade-tables">
					<div className="trade-tables-title">
						<p>{String(L.translate('Trade.Perpetual.Tables.transaction_history'))}</p>
					</div>
					<div className="trade-tables-nav flex flex-m flex-sb">
						<div className="table-nav flex flex--gap8" onClick={handleTabChange}>
							<button
								className={`button button--width-auto button--size3 ${
									currentTableTab === 'positions' || !currentTableTab ? '' : 'button--type11'
								}`}
								type="button"
								name="positions"
							>
								{String(L.translate('Trade.Perpetual.Tables.positions'))}
							</button>
							<button
								className={`button button--width-auto button--size3 ${
									currentTableTab === 'closed' ? '' : 'button--type11'
								}`}
								type="button"
								name="closed"
							>
								{String(L.translate('Trade.Perpetual.Tables.closed_history'))}
							</button>
							<button
								className={`button button--width-auto button--size3 ${
									currentTableTab === 'myOrders' ? '' : 'button--type11'
								}`}
								type="button"
								name="myOrders"
							>
								{String(L.translate('Trade.Perpetual.Tables.my_orders'))}
								{myOrdersTotal ? `(${myOrdersTotal})` : null}
							</button>
							<button
								className={`button button--width-auto button--size3 ${
									currentTableTab === 'orderHistory' ? '' : 'button--type11'
								}`}
								type="button"
								name="orderHistory"
							>
								{String(L.translate('Trade.Perpetual.Tables.order_history'))}
							</button>
							<button
								className={`button button--width-auto button--size3 ${
									currentTableTab === 'stopOrders' ? '' : 'button--type11'
								}`}
								type="button"
								name="stopOrders"
							>
								{String(L.translate('Trade.Perpetual.Tables.stop_orders'))}
								{stopOrdersTotal ? `(${stopOrdersTotal})` : null}
							</button>
							<button
								className={`button button--width-auto button--size3 ${
									currentTableTab === 'stopHistory' ? '' : 'button--type11'
								}`}
								type="button"
								name="stopHistory"
							>
								{String(L.translate('Trade.Perpetual.Tables.stopped_history'))}
							</button>
							<button
								className={`button button--width-auto button--size3 ${
									currentTableTab === 'tradeHistory' ? '' : 'button--type11'
								}`}
								type="button"
								name="tradeHistory"
							>
								{String(L.translate('Trade.Perpetual.Tables.trade_history'))}
							</button>
							<button
								className={`button button--width-auto button--size3 ${
									currentTableTab === 'transactions' ? '' : 'button--type11'
								}`}
								type="button"
								name="transactions"
							>
								{String(L.translate('Trade.Perpetual.Tables.transactions'))}
							</button>
						</div>
						<div className="flex flex-m flex--gap16">
							<div className="checkbox checkbox--type3 checkbox--no-mb">
								<label className="checkbox__label">
									<input
										type="checkbox"
										checked={!showAll}
										onChange={handleCheck}
										className="hidden"
									/>
									<span className=" checkbox__item">
										<span className="checkbox__item-icon">
											<svg
												width="17"
												height="17"
												viewBox="0 0 17 17"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M14.5426 4.58269C14.7501 4.79016 14.7501 5.12653 14.5426 5.33399L7.45931 12.4173C7.25184 12.6248 6.91547 12.6248 6.70801 12.4173L3.16634 8.87566C2.95888 8.66819 2.95888 8.33183 3.16634 8.12436C3.37381 7.91689 3.71018 7.91689 3.91764 8.12436L7.08366 11.2904L13.7913 4.58269C13.9988 4.37523 14.3352 4.37523 14.5426 4.58269Z"
													fill="#FF57D4"
												/>
											</svg>
										</span>
									</span>
									<span className="checkbox__text">
										{String(L.translate('Trade.Perpetual.Tables.current_only'))}
									</span>
								</label>
							</div>
							{currentTableTab === 'positions' && authIsAuthenticated && (
								<button
									onClick={handleOpenModal}
									className="button button--type-text button--type-text-fz12 fw-700"
									type="button"
								>
									{String(L.translate('Trade.Perpetual.Tables.close_all'))}
								</button>
							)}
						</div>
					</div>
					{tableTabs[(currentTableTab as keyof typeof tableTabs) || 'positions']}
					<ConfirmDeletePopup
						title={closeAllTitle}
						bodyMessage={closeAllBody}
						openModal={openModal}
						closeModal={closeModal}
						// eslint-disable-next-line @typescript-eslint/no-misused-promises
						handleDelete={handleCloseAll}
					/>
				</div>
			</SocketWithPrivateTopicConnectWrapper>
		</SocketWithPrivateTopicConnectWrapper>
	);
};
export default TradeTables;
