import { FC } from 'react';
import L from 'i18n-react';
import { WalletLayout } from 'layouts/Wallet';
import SpotWallets from 'components/Wallet/SpotWallets';

const Spot: FC = () => {
	return (
		<WalletLayout title={String(L.translate('SpotWallet.helmet_title'))}>
			<SpotWallets />
		</WalletLayout>
	);
};

export default Spot;
