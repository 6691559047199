import { FC, useEffect, useState } from 'react';
import Trading from 'layouts/Trading';
import TradeHeader from 'components/Trade/PerpetualTrades/TradeHeader';
import MobNav from 'components/Trade/PerpetualTrades/MobNav';
import OrderBook from 'components/Trade/PerpetualTrades/OrderBook';
import Chart from 'components/Chart';
import Asset from 'components/Trade/PerpetualTrades/Asset';
import TradeTables from 'components/Trade/PerpetualTrades/TradeTables';
import OrderForm from 'components/Trade/PerpetualTrades/OrderForm';
import PositionPopup from 'components/Trade/PerpetualTrades/Popups/PositionPTSL';
import AdjustMarginPopup from 'components/Trade/PerpetualTrades/Popups/AdjustMargin';
import AdjustLeveragePopup from 'components/Trade/PerpetualTrades/Popups/AdjustLeverage';
import AdjustLeverageMaticPopup from 'components/Trade/PerpetualTrades/Popups/AdjustLeverageMatic';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAvailableBalanceRequest,
	getBaseAssetStatisticsRequest,
	getOrderHistoryRequest,
	getPerpetualOrderBookRequest,
	getRecentPerpetualTradesRequest,
	getUnrealisedPnlRequest,
	getUserStatRequest,
} from 'redux/reducers/perpetual/reducer';

import { getBaseAsset, getPerpetualBaseAsset } from 'redux/reducers/perpetual/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { current } from '@reduxjs/toolkit';
import { setCurrentPair } from 'redux/reducers/tradingSettings/reducer';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';

const PerpetualTradingPage: FC = () => {
	const dispatch = useDispatch();
	const currentAsset = useSelector(getPerpetualBaseAsset);
	const [price, setPrice] = useState('');
	const currentPair = useSelector(getCurrentPair);
	const isAuth = useSelector(getAuthIsAuthenticated);
	const asset = useSelector(getBaseAsset);

	useEffect(() => {
		if (currentAsset?.id !== 0) {
			if (isAuth) {
				dispatch(getUserStatRequest({ base_asset: currentAsset?.base_asset || 'btc' }));
				dispatch(
					getOrderHistoryRequest({
						is_show_all_asset: 1,
						base_asset: currentAsset?.base_asset || 'btc',
					}),
				);
			}
			dispatch(
				getPerpetualOrderBookRequest({
					id: Number(currentAsset?.id || 1),
				}),
			);
		}
		dispatch(
			getRecentPerpetualTradesRequest({ limit: 10, base_asset: currentAsset?.base_asset || 'btc' }),
		);
		if (isAuth) {
			dispatch(getAvailableBalanceRequest());
			dispatch(getUnrealisedPnlRequest({ base_asset: asset?.base_asset || 'btc' }));
		}
		// if user is authentificated then dispatch getOrderHistoryRequest
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, isAuth, asset?.base_asset]);

	// useEffect(() => {
	// 	const orderBookInterval = setInterval(() => {
	// 		dispatch(getBaseAssetStatisticsRequest());
	// 		dispatch(
	// 			getPerpetualOrderBookRequest({
	// 				id: Number(currentAsset?.id || 1),
	// 			}),
	// 		);
	// 		dispatch(
	// 			getRecentPerpetualTradesRequest({
	// 				limit: 10,
	// 				base_asset: currentAsset?.base_asset || 'btc',
	// 			}),
	// 		);
	// 		if (isAuth) {
	// 			dispatch(getAvailableBalanceRequest());
	// 			dispatch(getUnrealisedPnlRequest({ base_asset: asset?.base_asset || 'btc' }));
	// 		}
	// 	}, 5000);
	// 	return () => clearInterval(orderBookInterval);
	// 	// if user is authentificated then dispatch getOrderHistoryRequest
	// }, [dispatch, currentAsset, isAuth, asset]);

	// TODO: implement 'allAsset' changing
	// TODO: dispatch all actions we need to get data

	return (
		<Trading title="Perpetual Trading">
			<main>
				<section className="trade-section">
					<TradeHeader />
					<MobNav />
					<div className="trade trade--type2">
						<div className="chart active">
							<Chart />
						</div>
						<OrderBook setPrice={setPrice} />
						<OrderForm price={price} />
						<TradeTables />
						<Asset />
					</div>
				</section>
			</main>
			{/* <PositionPopup /> */}
			{/* <AdjustMarginPopup /> */}
			{/* <AdjustLeveragePopup /> */}
			{/* <AdjustLeverageMaticPopup /> */}
		</Trading>
	);
};

export default PerpetualTradingPage;
