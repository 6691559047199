import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { getAnalyticsPair } from 'redux/reducers/analytics/selectors';
import { IProps } from './index';
import { roundingNumber } from '../../../../services/utils/roundingNumber';

const PriceVolume = ({ data, price, volume }: IProps) => {
	const pair = useSelector(getAnalyticsPair);
	const replacePersent = (pairText: string) => {
		return Number(pairText.replace('%', ''));
	};

	return (
		<div className="analitics-block__item">
			<div className="container">
				<div className="price-table">
					<div className="table table--value-analytics">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<p>Price/Volume</p>
								</div>
								{data?.map((e) => {
									return (
										<div key={uuidv4()} className="td">
											<p>{e.interval}</p>
										</div>
									);
								})}
							</div>
						</div>
						<div className="table-body">
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Price/Volume</p>
									<p>
										{roundingNumber(price, 7)} {pair.toUpperCase().split('_')[0]}
									</p>
								</div>
								{data?.map((e) => {
									return (
										<div key={uuidv4()} className="td">
											<p className="td-hidden-name">{e.interval}</p>
											<p
												className={`changing-value changing-value--type2 ${
													replacePersent(e.price) <= 0
														? 'changing-value--type3'
														: 'changing-value--type2'
												}`}
											>
												{e.price}
											</p>
										</div>
									);
								})}
							</div>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Price/Volume</p>
									<p>
										{roundingNumber(volume, 7)} {pair.toUpperCase().split('_')[0]}
									</p>
								</div>

								{data?.map((e) => {
									return (
										<div key={uuidv4()} className="td">
											<p className="td-hidden-name">{e.interval}</p>
											<p
												className={`changing-value changing-value--type2 ${
													replacePersent(e.volume) <= 0
														? 'changing-value--type3'
														: 'changing-value--type2'
												}`}
											>
												{Number(e.volume.replace('%', '')).toFixed(2)}%
											</p>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PriceVolume;
