import { useHistory, useLocation } from 'react-router';

const PER_PAGE = 'perPage';
const CURRENT_PAGE = 'currentPage';

const regExp = new RegExp(`${CURRENT_PAGE}=\\d+`);

const usePagination = () => {
	const location = useLocation();
	const history = useHistory();
	const { pathname, search } = location;
	const perPageStr = new URLSearchParams(location.search).get(PER_PAGE);
	const perPage = perPageStr ? Number(perPageStr) : 15;
	const currentPageStr = new URLSearchParams(location.search).get(CURRENT_PAGE);
	const currentPage = currentPageStr ? Number(currentPageStr) : 1;
	const onPageClick: (page: number) => void = (page) => {
		let newSearch = '';
		if (currentPageStr) {
			newSearch = search.replace(regExp, `${CURRENT_PAGE}=${page}`);
		} else {
			newSearch = search ? `${search}&${CURRENT_PAGE}=${page}` : `?${CURRENT_PAGE}=${page}`;
		}
		history.replace({
			pathname,
			search: newSearch,
		});
	};
	const getCurrentLink: (page: number) => string = (page) => {
		let newSearch = '';
		if (currentPageStr) {
			newSearch = search.replace(regExp, `${CURRENT_PAGE}=${page}`);
		} else {
			newSearch = `${search ? '&' : ''}${CURRENT_PAGE}=${page}`;
		}
		const resultLink = `${pathname}?${newSearch}`;
		return resultLink;
	};
	return { perPage, currentPage, onPageClick, getCurrentLink };
};

export default usePagination;
