/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from 'react';
import { transformFirstLetterToCapitalize } from 'services/utils/capitalize';
import {
	getLocaleDateFromTimestamp,
	getLocaleTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import { transformPairCode } from 'services/utils/tradingPairHelpers';
import { IOrdersHistoryItemProps } from './types';

const OrdersHistoryItem: FC<IOrdersHistoryItemProps> = ({ data }) => {
	const {
		created_at,
		// pair,
		type,
		price,
		price_average,
		// average,
		quantity,
		quantity_left,
		total,
		status,
		// filling,
		asset_pair,
		direction,
		trigger_stop_limit_price,
	} = data;
	const getType = (triggerValue: string | number | null, typeValue: string): string => {
		if (triggerValue) return 'Stop-Limit';

		return typeValue?.includes('Market') ? 'Market' : 'Limit';
	};

	const getMarketType = (typeValue: string): string => {
		if (typeValue?.includes('sell')) return 'Sell';

		if (typeValue?.includes('buy')) return 'Buy';

		return '-';
	};

	const getClassByType = (statusValue: string): string => {
		if (statusValue?.includes('sell' || 'Sell')) return 'td-name td-name--red';

		if (statusValue?.includes('buy' || 'Buy')) return 'td-name td-name--green';

		return 'td-name';
	};

	const getClassByStatus = (typeValue: string): string => {
		if (typeValue?.includes('cancelled')) return 'status-table-text';
		if (typeValue?.includes('executed')) return 'td-name td-name--green';

		if (typeValue?.includes('filled')) return 'td-name td-name--green';

		return 'td-name';
	};

	const filled = 100 - (Number(quantity_left) / Number(quantity)) * 100;

	const totalCalc = Number(quantity) * Number(price);

	return (
		<div className="tr tr--eleventhColumn">
			<div className="td">
				<span className="td-name td-name--grey">
					{getLocaleDateFromTimestamp(created_at)} {getLocaleTimeFromTimestamp(created_at)}
				</span>
			</div>
			<div className="td">
				<span className="td-name">{transformPairCode(asset_pair?.code)}</span>
			</div>
			<div className="td">
				<span className="td-name">{getType(trigger_stop_limit_price, type)}</span>
			</div>
			<div className="td">
				<span className={getClassByType(direction)}>{getMarketType(direction)}</span>
			</div>
			<div className="td">
				<span className="td-name">{Number(price_average).toFixed(6) || '-'}</span>
			</div>
			<div className="td">
				<span className="td-name">{Number(price).toFixed(6) || '-'}</span>
			</div>
			<div className="td">
				<span className="td-name">{Number(quantity).toFixed(6) || '-'}</span>
			</div>
			<div className="td">
				<span className="td-name">{filled ? `${Number(filled).toFixed(2)} %` : '-'}</span>
			</div>
			<div className="td">
				<span className="td-name">{Number(total || totalCalc).toFixed(6)}</span>
			</div>
			<div className="td">
				<span className="td-name">
					{trigger_stop_limit_price ? Number(trigger_stop_limit_price).toFixed(6) : '-'}
				</span>
			</div>
			<div className="td td--right">
				<p className={getClassByStatus(String(status))}>
					{transformFirstLetterToCapitalize(
						String(status.includes('executed') ? 'Filled' : status || '-'),
					)}
				</p>
			</div>
		</div>
	);
};

export default OrdersHistoryItem;
