import { FC } from 'react';
import L from 'i18n-react';
import useSelect from 'hooks/useSelect';
import { IPriceTypeSelect, PriceType } from './types';

const PriceTypeSelect: FC<IPriceTypeSelect> = ({ onChange, value }) => {
	const { open, setOpen, toggleOpen, currentRef, dropRef } = useSelect();

	const handleSelect = (type: string) => {
		onChange && onChange(type);
		setOpen(false);
	};

	return (
		<div className="select-block">
			<div className={`select ${open ? ' active' : ''}`}>
				<button onClick={toggleOpen} className="select__current" type="button" ref={currentRef}>
					{value === PriceType.MarkPrice &&
						String(L.translate('Trade.Perpetual.Positions.mark_price'))}
					{value === PriceType.LiquidationPrice &&
						String(L.translate('Trade.Perpetual.Positions.liquidation_price'))}
					<span className="select__current-arrow">
						<svg
							width="10"
							height="6"
							viewBox="0 0 10 6"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M10 0.333496H1H0L5 5.3335L10 0.333496Z" fill="#1C1C29" />
						</svg>
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								<li>
									<button type="button" onClick={() => handleSelect('markPrice')}>
										{String(L.translate('Trade.Perpetual.Positions.mark_price'))}
									</button>
								</li>
								<li>
									<button type="button" onClick={() => handleSelect('liquidationPrice')}>
										{String(L.translate('Trade.Perpetual.Positions.liquidation_price'))}
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { PriceType };

export default PriceTypeSelect;
