import { isAuth, isGuest } from 'routes/authMiddlewares';
import { Login } from 'pages/Auth/Login';
import { Registration } from 'pages/Auth/Registration';
import { CreateNewPassword } from 'pages/Auth/CreateNewPassword';
import { ReferralPage } from 'pages/Referral';
import { CheckEmail } from 'pages/Auth/CheckEmail';
import { Confirm } from 'pages/Auth/Confirm';
import EmailConfirm from 'pages/Auth/EmailConfirm';
import { ForgotPassword } from 'pages/Auth/ForgotPassword';
import EmailIsTaken from 'pages/Auth/EmailIsTaken';
// Static pages:
import { HomePage } from 'pages/HomePage';
import Trading from 'pages/Static/Trading';
import Convert from 'pages/Convert';
import ExchangePage from 'pages/Exchange';
import WalletSpot from 'pages/Wallet/Spot';
import MarginAccount from 'pages/Wallet/MarginAccount';
import OpenOrders from 'pages/Wallet/MarginAccount/OpenOrders';
import OrderHistory from 'pages/Wallet/MarginAccount/OrderHistory';
import TradeHistory from 'pages/Wallet/MarginAccount/TradeHistory';
import Borrowing from 'pages/Wallet/MarginAccount/Borrowing';
import Repayment from 'pages/Wallet/MarginAccount/Repayment';
import Interest from 'pages/Wallet/MarginAccount/Interest';
import MarginCalls from 'pages/Wallet/MarginAccount/Calls';
import LiquidationOrders from 'pages/Wallet/MarginAccount/LiquidationOrders';
import TransactionHistory from 'pages/Wallet/TransactionHistory';
import AccountSettings from 'pages/AccountSettings';
import PerpetualTradingPage from 'pages/Perpetual';
import OptionsTradingPage from 'pages/Options';
import Derivatives from 'pages/Wallet/Derivatives';
import { DepositCrypto } from 'pages/Wallet/DepositCrypto/DepositCrypto';
import WithdrawCryptoPage from 'pages/Wallet/WithdrawCryptoPage/WithdrawCryptoPage';
import { AccountSettingsAnalyticsPage } from 'pages/Analytics';
import { PairsSpotPage } from 'pages/PairsSpotPage/PairsSpotPage';
import AboutPage from 'pages/Static/About';
import FaqPage from 'pages/Static/Faq';
import CookiesPage from 'pages/Static/Cookies';
import TermsPage from 'pages/Static/Terms';
import GlossaryPage from 'pages/Static/Glossary';
import SpotMarginEtfPage from 'pages/Static/SpotMarginEtf';
import PerpetualPage from 'pages/Static/Perpetual';
import ContractPage from 'pages/Static/Contract';
import ArticlesPage from 'pages/Static/Articles';
import PrivacyPage from 'pages/Static/Privacy';
import AccountPage from 'pages/Static/Account';
import { ROUTES } from './constants';
import { IRouteItem } from './types';

// ==========================================:
const navList = {
	home: {
		path: '/',
		component: HomePage,
	},
	registration: {
		path: '/registration',
		component: isGuest(Registration),
	},
	login: {
		path: '/login',
		component: isGuest(Login),
	},
	twoFa: {
		path: '/2fa',
		component: isGuest(Login),
	},
	referral: {
		path: '/referral',
		component: isAuth(ReferralPage),
	},
	emailConfirm: {
		path: '/email-confirm',
		component: isGuest(EmailConfirm),
	},
	confirm: {
		path: '/confirm',
		component: isGuest(Confirm),
	},
	confirmCheck: {
		path: '/email-confirm-check',
		component: isGuest(CheckEmail),
	},
	emailIsTaken: {
		path: '/email-is-taken',
		component: isGuest(EmailIsTaken),
	},
	forgotPassword: {
		path: '/forgot-password',
		component: isGuest(ForgotPassword),
	},
	createNewPassword: {
		path: '/create-new-password',
		component: isGuest(CreateNewPassword),
	},
	spot: {
		path: '/spot/:pair',
		component: Trading,
	},
	convert: {
		path: '/convert',
		component: isAuth(ExchangePage),
	},
	walletSpot: {
		path: ROUTES.wallet.spot,
		component: isAuth(WalletSpot),
	},
	marginAccount: {
		path: ROUTES.wallet.marginAccount.root,
		component: isAuth(MarginAccount),
	},
	marginOpenOrders: {
		path: ROUTES.wallet.marginAccount.openOrders,
		component: isAuth(OpenOrders),
	},
	marginOrderHistory: {
		path: ROUTES.wallet.marginAccount.orderHistory,
		component: isAuth(OrderHistory),
	},
	marginTradeHistory: {
		path: ROUTES.wallet.marginAccount.tradeHistory,
		component: isAuth(TradeHistory),
	},
	marginBorrowing: {
		path: ROUTES.wallet.marginAccount.borrowing,
		component: isAuth(Borrowing),
	},
	marginRepayment: {
		path: ROUTES.wallet.marginAccount.repayment,
		component: isAuth(Repayment),
	},
	marginInterest: {
		path: ROUTES.wallet.marginAccount.interest,
		component: isAuth(Interest),
	},
	marginCalls: {
		path: ROUTES.wallet.marginAccount.calls,
		component: isAuth(MarginCalls),
	},
	liquidationOrders: {
		path: ROUTES.wallet.marginAccount.liquidationOrders,
		component: isAuth(LiquidationOrders),
	},
	transactionHistory: {
		path: ROUTES.wallet.transactionHistory,
		component: isAuth(TransactionHistory),
	},
	derivatives: {
		path: ROUTES.wallet.derivatives,
		component: isAuth(Derivatives),
	},
	depositCrypto: {
		path: ROUTES.wallet.depositCrypto,
		component: isAuth(DepositCrypto),
	},
	withdrawCrypto: {
		path: ROUTES.wallet.withdrawCrypto,
		component: isAuth(WithdrawCryptoPage),
	},
	accountSettings: {
		path: ROUTES.accountSettings.index,
		component: isAuth(AccountSettings),
		exact: false,
	},
	margin: {
		path: '/MarginTrading/:pair',
		component: Trading,
	},
	perpetual: {
		path: '/PerpetualTrading/',
		component: PerpetualTradingPage,
	},
	options: {
		path: '/options',
		component: OptionsTradingPage,
	},
	analytics: {
		path: '/analytics',
		component: isAuth(AccountSettingsAnalyticsPage),
		exact: false,
	},
	pairsSpot: {
		path: ROUTES.pairs.spot,
		component: PairsSpotPage,
	},
	about: {
		path: ROUTES.static.about,
		component: AboutPage,
	},
	faq: {
		path: ROUTES.static.faq,
		component: FaqPage,
	},
	cookies: {
		path: ROUTES.static.cookies,
		component: CookiesPage,
	},
	terms: {
		path: ROUTES.static.privacy,
		component: TermsPage,
	},
	glossary: {
		path: ROUTES.static.glossary,
		component: GlossaryPage,
	},
	spotMarginEtf: {
		path: ROUTES.static.spotMarginEtf,
		component: SpotMarginEtfPage,
	},
	perpetualPage: {
		path: ROUTES.static.perpetualPage,
		component: PerpetualPage,
	},
	contractPage: {
		path: ROUTES.static.contractPage,
		component: ContractPage,
	},
	articlesPage: {
		path: ROUTES.static.articles,
		component: ArticlesPage,
	},
	privacyPage: {
		path: ROUTES.static.terms,
		component: PrivacyPage,
	},
	accountSecurity: {
		path: ROUTES.static.security,
		component: AccountPage,
	},
};

// ==========================================:
const routesList: Array<IRouteItem> = [];

Object.keys(navList).forEach((item) => routesList.push(navList[item as keyof typeof navList]));

export { navList, routesList, ROUTES };
