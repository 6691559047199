import L from 'i18n-react';
import { FC } from 'react';
import { TProps } from './types';

const EmptyHistoryPlug: FC<TProps> = ({ text }) => {
	return (
		<div className="table-body-plug">
			<svg
				width="49"
				height="48"
				viewBox="0 0 49 48"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.5 5.5C7.67158 5.5 7 6.17158 7 7V41C7 41.8285 7.67156 42.5 8.5 42.5H34V7C34 6.17156 33.3285 5.5 32.5 5.5H8.5ZM4 7C4 4.51472 6.01472 2.5 8.5 2.5H32.5C34.9853 2.5 37 4.51474 37 7V44C37 44.8284 36.3284 45.5 35.5 45.5H8.5C6.01474 45.5 4 43.4853 4 41V7Z"
					fill="#9D9DBC"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M37.5 23.5C37.2238 23.5 37 23.7238 37 24V42.5H40.5C41.3285 42.5 42 41.8285 42 41V24C42 23.7238 41.7762 23.5 41.5 23.5H37.5ZM34 24C34 22.067 35.567 20.5 37.5 20.5H41.5C43.433 20.5 45 22.067 45 24V41C45 43.4853 42.9853 45.5 40.5 45.5H35.5C34.6716 45.5 34 44.8284 34 44V24Z"
					fill="#9D9DBC"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10 12C10 11.1716 10.6716 10.5 11.5 10.5H19.5C20.3284 10.5 21 11.1716 21 12C21 12.8284 20.3284 13.5 19.5 13.5H11.5C10.6716 13.5 10 12.8284 10 12Z"
					fill="#9D9DBC"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10 19C10 18.1716 10.6716 17.5 11.5 17.5H23.5C24.3284 17.5 25 18.1716 25 19C25 19.8284 24.3284 20.5 23.5 20.5H11.5C10.6716 20.5 10 19.8284 10 19Z"
					fill="#9D9DBC"
				/>
			</svg>
			<p>{text}</p>
		</div>
	);
};

export default EmptyHistoryPlug;
