/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import L from 'i18n-react';
import { IForgotPasswordForm } from './types';

// ==========================================:
export const CheckEmailConfirm: FC<IForgotPasswordForm> = () => {
	const [code, setCode] = useState('');
	// const initialValues = {
	// 	code: '',
	// };

	return (
		<>
			<div className="back-page">
				<Link to="/forgot-password" className="flex flex-m">
					<div className="back-page__icon">
						<svg
							width="32"
							height="32"
							viewBox="0 0 32 32"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M2.86621 16C2.86621 15.4477 3.31393 15 3.86621 15H27.8662C28.4185 15 28.8662 15.4477 28.8662 16C28.8662 16.5523 28.4185 17 27.8662 17H3.86621C3.31393 17 2.86621 16.5523 2.86621 16Z"
								fill="#7D7D4D"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M12.5733 7.29289C12.9638 7.68342 12.9638 8.31658 12.5733 8.70711L5.28042 16L12.5733 23.2929C12.9638 23.6834 12.9638 24.3166 12.5733 24.7071C12.1828 25.0976 11.5496 25.0976 11.1591 24.7071L3.1591 16.7071C2.76858 16.3166 2.76858 15.6834 3.1591 15.2929L11.1591 7.29289C11.5496 6.90237 12.1828 6.90237 12.5733 7.29289Z"
								fill="#7D7D4D"
							/>
						</svg>
					</div>
					<p>{String(L.translate('CheckEmailConfirm.prev_button'))}</p>
				</Link>
			</div>
			<p className="authorization__title">{String(L.translate('CheckEmailConfirm.title_0'))}</p>
			<div className="authorization__details">
				<p>{String(L.translate('CheckEmailConfirm.title_1'))}</p>
			</div>
			<div className="authorization__form">
				<div className="authorization__field">
					<div className="input">
						<div className="input-wrapper">
							<input
								className="input-item input-item--pr125"
								type="text"
								value={code}
								onChange={(e) => setCode(e.currentTarget.value)}
								placeholder={String(L.translate('CheckEmailConfirm.code'))}
								name="code"
							/>
							<span className="input-icon-text">
								<button type="button" className="button button--type-text2 fw-500">
									{String(L.translate('CheckEmailConfirm.title_2'))}
								</button>
							</span>
							<div className="input-icon input-icon--right">
								<svg
									width="20"
									height="21"
									viewBox="0 0 20 21"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M2.29199 11.7501C2.29199 7.4929 5.74315 4.04175 10.0003 4.04175C14.2575 4.04175 17.7087 7.4929 17.7087 11.7501C17.7087 16.0073 14.2575 19.4584 10.0003 19.4584C5.74315 19.4584 2.29199 16.0073 2.29199 11.7501ZM10.0003 5.29175C6.4335 5.29175 3.54199 8.18326 3.54199 11.7501C3.54199 15.3169 6.4335 18.2084 10.0003 18.2084C13.5671 18.2084 16.4587 15.3169 16.4587 11.7501C16.4587 8.18326 13.5671 5.29175 10.0003 5.29175Z"
										fill="#7D7D4D"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M6.45801 2.16675C6.45801 1.82157 6.73783 1.54175 7.08301 1.54175H12.9163C13.2615 1.54175 13.5413 1.82157 13.5413 2.16675C13.5413 2.51193 13.2615 2.79175 12.9163 2.79175H7.08301C6.73783 2.79175 6.45801 2.51193 6.45801 2.16675Z"
										fill="#7D7D4D"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M16.2749 4.22481C16.519 4.46888 16.519 4.86461 16.2749 5.10869L15.0249 6.35869C14.7809 6.60277 14.3851 6.60277 14.1411 6.35869C13.897 6.11461 13.897 5.71888 14.1411 5.47481L15.3911 4.22481C15.6351 3.98073 16.0309 3.98073 16.2749 4.22481Z"
										fill="#7D7D4D"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M10 7.375C10.3452 7.375 10.625 7.65482 10.625 8V11.75C10.625 12.0952 10.3452 12.375 10 12.375C9.65482 12.375 9.375 12.0952 9.375 11.75V8C9.375 7.65482 9.65482 7.375 10 7.375Z"
										fill="#7D7D4D"
									/>
								</svg>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="form-submit">
				<button
					type="button"
					// disabled={!(isValid && dirty) || isSubmitting}
					className="button button--wide button--size2"
					aria-label="form-submit"
				>
					{String(L.translate('CheckEmailConfirm.title_3'))}
				</button>
			</div>
		</>
	);
};
