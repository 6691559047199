import SocketWithTopicConnectWrapper from 'components/SocketWithTopicConnectWrapper';
import { FC, MouseEvent, useEffect, useState } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getSpotRecentTrades,
	getSpotUserTradesCurrentPair,
} from 'redux/reducers/spotTrade/selectors';
import { getCurrentPair, getWorkspaceSettings } from 'redux/reducers/tradingSettings/selectors';
import LoginOrRegister from 'ui/LoginOrRegister';
import { getUserTradesCurrentPairRequest } from 'redux/reducers/spotTrade/reducer';
import RecentTradesTable from './RecentTradesTable';
import UserTrades from './UserTrades';

const SpotRecentTrades: FC = () => {
	const dispatch = useDispatch();
	const workspaceSettings = useSelector(getWorkspaceSettings);
	const recentTrades = useSelector(getSpotRecentTrades);
	const currentPair = useSelector(getCurrentPair);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const userTrades = useSelector(getSpotUserTradesCurrentPair);

	const [currentTradesTab, setCurrentTradesTab] = useState('market');

	const handleChangeCurrentTrades = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;

		setCurrentTradesTab(name);
	};

	useEffect(() => {
		if (authIsAuthenticated) {
			dispatch(
				getUserTradesCurrentPairRequest({
					params: {
						per_page: 15,
						transaction_type: 'spot',
						hide_other_pairs: 0,
					},
				}),
			);
		}
	}, [dispatch, authIsAuthenticated, currentPair]);

	return (
		<SocketWithTopicConnectWrapper topicName={`trade_history:spot:${String(currentPair.pair)}`}>
			<div className="trades">
				<div className="trades-nav">
					<button
						className={`trades-nav__item ${currentTradesTab === 'market' ? 'active' : ''}`}
						type="button"
						name="market"
						onClick={handleChangeCurrentTrades}
					>
						{String(L.translate('Trade.Spot.SpotRecentTrades.market_trade_tab_name'))}
					</button>
					<button
						className={`trades-nav__item ${currentTradesTab === 'users' ? 'active' : ''}`}
						type="button"
						name="users"
						onClick={handleChangeCurrentTrades}
					>
						{String(L.translate('Trade.Spot.SpotRecentTrades.user_trade_tab_name'))}
					</button>
				</div>
				<div className="trades-list">
					{currentTradesTab === 'market' ? <RecentTradesTable data={recentTrades} /> : null}

					{currentTradesTab === 'users' && !authIsAuthenticated && (
						<div
							className={`user-trades-wrapper ${
								!workspaceSettings?.orderBookSection && workspaceSettings?.tradingPairsSection
									? 'user-trades-wrapper--small'
									: ''
							} `}
						>
							<div className="user-trades-history-login-register-wrapper">
								<LoginOrRegister />
							</div>
						</div>
					)}

					{currentTradesTab === 'users' && authIsAuthenticated && <UserTrades data={userTrades} />}
				</div>
			</div>
		</SocketWithTopicConnectWrapper>
	);
};

export default SpotRecentTrades;
