import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	IMarginWalletsStore,
	IMarginCrossWallets,
	IMarginIsolatedWallets,
	IMaximumCrossBorrowAmount,
	IBorrowAvailable,
	IIndexPrices,
	ITransferHistoryData,
	IBorrowCrossWalletsResponsePayload,
	IBorrowIsolatedWalletsResponsePayload,
	IRepayCrossWalletsResponsePayload,
	IRepayIsolatedWalletsResponsePayload,
	IInterestCrossWalletsResponsePayload,
	IInterestIsolatedWalletsResponsePayload,
	ICallsCrossWalletsResponsePayload,
	ICallsIsolatedWalletsResponsePayload,
	ILiquidationWalletsCrossResponsePayload,
	ILiquidationWalletsIsolatedResponsePayload,
} from './types';

// ==========================================:
const getWalletsState = (state: TStoreState): IMarginWalletsStore => state.marginWallets;
export const getMarginWallets = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore) => marginWallets,
);

// ====================================================:
export const getMarginCrossWalletsList = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): null | IMarginCrossWallets =>
		marginWallets.marginCrossWallets,
);
// ====================================================:
export const getIsolatedWalletsList = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): null | IMarginIsolatedWallets =>
		marginWallets.marginIsolatedWallets,
);

// ====================================================:
export const getMaximumCrossBorrowAmount = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): null | IMaximumCrossBorrowAmount =>
		marginWallets.marginMaximumCrossBorrowAmount,
);
// ====================================================:
export const getMaximumIsolatedBorrowAmount = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): null | IBorrowAvailable =>
		marginWallets.marginMaximumIsolatedBorrowAmount,
);
// ====================================================:
export const getMarginIndexPrices = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): null | IIndexPrices => marginWallets.marginIndexPrices,
);

// ====================================================:
export const getTransferHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): ITransferHistoryData => marginWallets.transferHistory,
);

// ====================================================:
export const getTransferHistoryIsLoader = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): boolean => marginWallets.transferHistoryLoader,
);

// ====================================================:
export const getBorrowingHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): IBorrowCrossWalletsResponsePayload | null =>
		marginWallets.marginCrossBorrowList,
);

// ====================================================:
export const getIsolatedBorrowingHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): IBorrowIsolatedWalletsResponsePayload | null =>
		marginWallets.marginIsolatedBorrowList,
);

// ====================================================:
export const getRepaymentHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): IRepayCrossWalletsResponsePayload | null =>
		marginWallets.marginCrossRepayList,
);

export const getRepaymentIsolatedHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): IRepayIsolatedWalletsResponsePayload | null =>
		marginWallets.marginIsolatedRepayList,
);
// ====================================================:
export const getInterestedHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): IInterestCrossWalletsResponsePayload | null =>
		marginWallets.marginCrossInterestList,
);

export const getIsolatedInterestedHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): IInterestIsolatedWalletsResponsePayload | null =>
		marginWallets.marginIsolatedInterestList,
);

// ====================================================:
export const getMarginCrossCallHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): ICallsCrossWalletsResponsePayload | null =>
		marginWallets.marginCrossMarginCallsList,
);

// ====================================================:
export const getMarginIsolatedCallHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): ICallsIsolatedWalletsResponsePayload | null =>
		marginWallets.marginIsolatedMarginCallsList,
);

// ====================================================:
export const getLiquidationHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): ILiquidationWalletsCrossResponsePayload | null =>
		marginWallets.marginLiquidationList,
);

export const getLiquidationIsolatedHistory = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): ILiquidationWalletsIsolatedResponsePayload | null =>
		marginWallets.marginLiquidationIsolatedList,
);

// ====================================================:
export const getMarginWalletsIsLoader = createSelector(
	[getWalletsState],
	(marginWallets: IMarginWalletsStore): boolean => marginWallets.marginWalletsLoader,
);
