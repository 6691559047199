import { FC } from 'react';
import { ILayoutBarProps } from './types';

const LayoutBar: FC<ILayoutBarProps> = ({ currentLayoutTab, handleChangeLayoutBar }) => {
	return (
		<div className="orderbook-header__buttons">
			<button
				type="button"
				className={`orderbook-header__button-item ${
					currentLayoutTab === 'general' ? 'active' : ''
				}`}
				name="general"
				onClick={handleChangeLayoutBar}
			>
				<svg
					width="12"
					height="10"
					viewBox="0 0 12 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect width="12" height="2" fill="#EB5757" />
					<rect y="4" width="12" height="2" fill="#7C7CA6" />
					<rect y="8" width="12" height="2" fill="#219653" />
				</svg>
			</button>
			<button
				type="button"
				className={`orderbook-header__button-item ${currentLayoutTab === 'bid' ? 'active' : ''}`}
				name="bid"
				onClick={handleChangeLayoutBar}
			>
				<svg
					width="12"
					height="10"
					viewBox="0 0 12 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect width="12" height="2" fill="#7C7CA6" />
					<rect y="4" width="12" height="2" fill="#7C7CA6" />
					<rect y="8" width="12" height="2" fill="#219653" />
				</svg>
			</button>
			<button
				type="button"
				className={`orderbook-header__button-item ${currentLayoutTab === 'ask' ? 'active' : ''}`}
				name="ask"
				onClick={handleChangeLayoutBar}
			>
				<svg
					width="12"
					height="10"
					viewBox="0 0 12 10"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect width="12" height="2" fill="#EB5757" />
					<rect y="4" width="12" height="2" fill="#7C7CA6" />
					<rect y="8" width="12" height="2" fill="#7C7CA6" />
				</svg>
			</button>
		</div>
	);
};

export default LayoutBar;
