import { call, put, takeEvery, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import L from 'i18n-react';
import axios from 'axios';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getActiveBaseAsset } from 'redux/reducers/perpetual/selectors';
import { IOptionsAllStatistics } from 'redux/reducers/sockets/types';
import { IActiveBaseAsset } from 'redux/reducers/perpetual/types';
import { getActiveOrders } from 'redux/reducers/options/selectors';
import {
	createNewOrderRequest,
	createNewOrderSuccess,
	getOrdersListRequest,
	getStatisticRequest,
	updateOrderList,
	getStatisticSuccess,
	showHello,
	getOrdersListSuccess,
	getStructureRequest,
	getStructureSuccess,
	getOptionsAllRequest,
	getOptionsAllSuccess,
	getPositionsRequest,
	getPositionsSuccess,
	getActiveOrdersRequest,
	getActiveOrdersSuccess,
	getOrderHistoryRequest,
	getOrderHistorySuccess,
	getTradeHistoryRequest,
	getTradeHistorySuccess,
	getOrderbookTradesRequest,
	getOrderbookTradesSuccess,
	getUserImMmRequest,
	setUserImMm,
	receiveLastPrice,
	setPrevLastPrice,
	setLastPrice,
	updateActiveOrdersOnCancel,
	updateActiveOrdersOnOpen,
	deleteActiveOrder,
} from './reducer';
import {
	IOptionsListItem,
	IOptionOrderPosition,
	IStatisticsResponse,
	IOrder,
	ICreateOptionResponse,
} from './types';

function* showHelloWorker() {
	yield console.log('hello world');
}

function* getOrdersListRequestWorker() {
	try {
		const response: IOptionsListItem[] = yield call(api.optionsTrade.getAllOptions);
		yield put(getOrdersListSuccess(response));
	} catch (error) {
		notificationContainer('Options orders not found', 'error');
	}
}

function* updateOrderListWorker(action: PayloadAction): any {
	const payload = action.payload as any;
	const state = yield select();
	const {
		optionsTrade: { ordersList, positions },
	} = state;
	const newOrdersList: IOptionsListItem[] = ordersList.map((orderListItem: IOptionsListItem) => {
		const { options_by_date: optionsByDate } = orderListItem;
		const { options_positions: optionsPositions } = optionsByDate;
		const newOptionsPositions = optionsPositions.map((optPosItem: IOptionOrderPosition) => {
			const optPositionUpdates = payload[optPosItem?.id] as IOptionsAllStatistics;
			const newOptPosItem: IOptionOrderPosition = {
				...optPosItem,
				ask_price: optPositionUpdates.ask,
				ask_size: optPositionUpdates.ask_size,
				bid_price: optPositionUpdates.bid,
				bid_size: +optPositionUpdates.bid_size,
				delta: optPositionUpdates.delta,
				gamma: optPositionUpdates.gamma,
				mark_price: optPositionUpdates.mark_price,
				open_size: optPositionUpdates.open,
				strike_price: optPositionUpdates.strike_price,
				teta: optPositionUpdates.theta,
				vega: optPositionUpdates.vega,
				iv: optPositionUpdates.iv,
				bid_iv: optPositionUpdates.bid_iv,
				ask_iv: optPositionUpdates.ask_iv,
			};
			return newOptPosItem;
		});
		return {
			...orderListItem,
			options_by_date: {
				...orderListItem.options_by_date,
				options_positions: newOptionsPositions,
			},
		};
	});
	yield put(getOrdersListSuccess(newOrdersList));
	// console.log('newOrdersList-----', newOrdersList);
}

function* getStatisticWorker(action: any) {
	const { payload } = action;
	try {
		const response: IStatisticsResponse = yield call(api.optionsTrade.getOptionStatistic, payload);
		yield put(getStatisticSuccess(response));
	} catch (error) {
		console.log(error);
	}
}

function* createNewOrderWorker(action: any) {
	const { payload } = action;
	try {
		const response: ICreateOptionResponse = yield call(api.optionsTrade.createOrder, payload);
		if (!response.error) {
			yield put(createNewOrderSuccess(response));
			notificationContainer('Order succesfully created', 'success');
		}
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			const { errors } = error?.response?.data || {};
			if (errors) {
				if (errors?.includes('error_min_price_option')) {
					const index = errors?.indexOf('error_min_price_option');
					const activeBaseAsset: IActiveBaseAsset[] = yield select(getActiveBaseAsset);
					const btcAsset = activeBaseAsset?.find(
						(assetItem: IActiveBaseAsset) => assetItem.base_asset === 'btc',
					);
					const minPrice = btcAsset?.min_price_option;
					notificationContainer(
						String(L.translate('Errors.error_min_price_option', { value: minPrice })),
						'error',
					);
					errors.splice(index, 1);
					if (errors.length === 0) {
						return;
					}
				}
			}
			responseErrors(error);
		}
	}
}

function* getStructureRequestWorker(): any {
	try {
		const response = yield call(api.optionsTrade.getStructure);
		yield put(getStructureSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getOptionsAllRequestWorker(): any {
	try {
		const response = yield call(api.optionsTrade.getAllOptions);
		yield put(getOptionsAllSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getPositionsRequestWorker(): any {
	try {
		const response = yield call(api.optionsTrade.getPositions);
		yield put(getPositionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getActiveOrdersRequestWorker(): any {
	try {
		const response = yield call(api.optionsTrade.getActiveOrders);
		yield put(getActiveOrdersSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getOrderHistoryRequestWorker(action: PayloadAction): any {
	const { payload } = action;
	try {
		const response = yield call(api.optionsTrade.getOrdersHistory, payload);
		yield put(getOrderHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getTradeHistoryRequestWorker(action: PayloadAction): any {
	const { payload } = action;
	try {
		const response = yield call(api.optionsTrade.getTradeHistory, payload);
		yield put(getTradeHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getUserImMmRequestWorker(): any {
	try {
		const response = yield call(api.optionsTrade.getAccountImMm);
		yield put(setUserImMm(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* receiveLastPriceWorker(action: PayloadAction): any {
	const { payload } = action;
	const state = yield select();
	const {
		optionsTrade: { lastPrice },
	} = state;
	yield put(setPrevLastPrice(lastPrice));
	yield put(setLastPrice(payload));
}

function* getOrderbookTradesWorker(action: any) {
	const { payload } = action;
	try {
		const response: IStatisticsResponse = yield call(api.optionsTrade.getOrderbookTrades, payload);
		yield put(getOrderbookTradesSuccess(response));
	} catch (error) {
		console.error(error);
	}
}

function* updateActiveOrdersOnOpenWorker(action: PayloadAction): any {
	const payload = action.payload as any;
	const state = yield select();
	const {
		optionsTrade: { activeOrders },
	} = state;
	const activeOrderIds: number[] = activeOrders?.map((item: any) => item.id);
	const resultOrders = payload?.orders?.reduce((acc: any, curr: any) => {
		if (!activeOrderIds?.includes(curr.id)) {
			return [curr, ...acc];
		}
		return acc;
	}, activeOrders || []);
	yield put(getActiveOrdersSuccess(resultOrders));
}

function* updateActiveOrdersOnCancelWorker(action: PayloadAction): any {
	const payload = action.payload as any;
	const state = yield select();
	const {
		optionsTrade: { activeOrders },
	} = state;
	const payloadIds: number[] = payload?.orders?.map((item: any) => item.id);
	const filteredActiveOrders = activeOrders?.filter(
		(item: IOrder) => !payloadIds.includes(item.id),
	);
	yield put(getActiveOrdersSuccess(filteredActiveOrders));
}

function* deleteActiveOrderWorker(action: PayloadAction): any {
	const payload = action.payload as any;
	const { id } = payload;
	const activeOrders = yield select(getActiveOrders);
	const filteredActiveOrders = activeOrders?.filter((order: IOrder) => order.id !== id);
	yield put(getActiveOrdersSuccess(filteredActiveOrders));
}

export function* optionsSaga() {
	yield takeEvery(showHello.type, showHelloWorker);
	yield takeEvery(getOrdersListRequest.type, getOrdersListRequestWorker);
	yield takeEvery(updateOrderList.type, updateOrderListWorker);
	yield takeEvery(getStatisticRequest.type, getStatisticWorker);
	yield takeEvery(createNewOrderRequest.type, createNewOrderWorker);
	yield takeEvery(getStructureRequest.type, getStructureRequestWorker);
	yield takeEvery(getOptionsAllRequest.type, getOptionsAllRequestWorker);
	yield takeEvery(getPositionsRequest.type, getPositionsRequestWorker);
	yield takeEvery(getActiveOrdersRequest.type, getActiveOrdersRequestWorker);
	yield takeEvery(getOrderHistoryRequest.type, getOrderHistoryRequestWorker);
	yield takeEvery(getTradeHistoryRequest.type, getTradeHistoryRequestWorker);
	yield takeEvery(getUserImMmRequest.type, getUserImMmRequestWorker);
	yield takeEvery(receiveLastPrice.type, receiveLastPriceWorker);
	yield takeEvery(getOrderbookTradesRequest.type, getOrderbookTradesWorker);
	// yield takeEvery(updateActiveOrdersOnCancel.type, updateActiveOrdersOnCancelWorker);
	yield takeEvery(updateActiveOrdersOnOpen.type, updateActiveOrdersOnOpenWorker);
	yield takeEvery(deleteActiveOrder.type, deleteActiveOrderWorker);
}
