import React from 'react';
import Trading from 'layouts/Trading';
import Account from 'components/StaticPages/account';

const AccountPage = () => {
	return (
		<Trading title="Account Security">
			<Account />
		</Trading>
	);
};

export default AccountPage;
