import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import L from 'i18n-react';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import { getIsolatedWalletsList } from 'redux/reducers/marginWallets/selectors';
import { getFirstPartPairCode, getLastPartPairCode } from 'services/utils/tradingPairHelpers';
import { IWalletsDataType } from 'redux/reducers/marginWallets/types';
import { IWalletsData } from 'redux/reducers/wallets/types';
import { transformMarginsIsolatedData } from 'services/utils/transformMarginsIsolatedData';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import SwitchOrderType from '../SwitchOrderType';
import { IBuySellWrapperProps, ICurrentPairBalanceData, ISpotData, ICrossData } from './types';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */

const getCurrentPairBalance = (
	spotDataValue: ISpotData | ICrossData | 0 | undefined,
	buyCode: string,
	sellCode: string,
	pair: string,
): ICurrentPairBalanceData => {
	if (pair && spotDataValue && spotDataValue[buyCode] && spotDataValue[sellCode]) {
		return {
			buy: { code: buyCode, balance: Number(spotDataValue[buyCode].balance) },
			sell: { code: sellCode, balance: Number(spotDataValue[sellCode].balance) },
		};
	}

	return {
		buy: { code: buyCode, balance: 0 },
		sell: { code: sellCode, balance: 0 },
	};
};

const currentPairBalanceIsolated = (isolated: any, pair: string) => {
	const buyCode = pair?.split('_')[1];
	const sellCode = pair?.split('_')[0];

	const currentIsolatedPair = isolated?.find((el: any) => el?.base?.pair?.code === pair);

	if (pair && !!isolated?.length && currentIsolatedPair) {
		return {
			buy: {
				code: buyCode,
				balance: currentIsolatedPair?.quote?.balance,
			},
			sell: { code: sellCode, balance: currentIsolatedPair?.base?.balance },
		};
	}
	return {
		buy: { code: buyCode, balance: 0 },
		sell: { code: sellCode, balance: 0 },
	};
};

const BuySellWrapper: FC<IBuySellWrapperProps> = ({ orderType, mode, marketType }) => {
	const walletsList = useSelector(getWalletsList);
	const currentPair = useSelector(getCurrentPair);
	const isolatedWallets = useSelector(getIsolatedWalletsList);
	const isAuth = useSelector(getAuthIsAuthenticated);
	const isolated = transformMarginsIsolatedData(isolatedWallets?.isolated);

	const [balance, setBalance] = useState(0);
	const [code, setCode] = useState('');

	const reduceWallet = (list: any) => {
		return (
			list?.length &&
			list[0].asset &&
			list.reduce((acc: any, el: any) => {
				acc[el.asset.code] = el;

				return acc;
			}, {} as ISpotData | ICrossData)
		);
	};

	const walletsData = useMemo(() => {
		const wallets: IWalletsData[] | IWalletsDataType[] | Array<any> | null | undefined | false =
			marketType === 'spot' && walletsList;
		return reduceWallet(wallets);
	}, [walletsList, marketType]);

	const buyAssetCode = getLastPartPairCode(currentPair.pair)?.toLowerCase();
	const sellAssetCode = getFirstPartPairCode(currentPair.pair)?.toLowerCase();

	const currentPairBalance =
		marketType === 'isolated'
			? currentPairBalanceIsolated(isolated, currentPair.pair)
			: getCurrentPairBalance(walletsData, buyAssetCode, sellAssetCode, currentPair.pair);

	useEffect(() => {
		if (mode === 'Buy') {
			setBalance(currentPairBalance?.buy?.balance);
		} else {
			setBalance(currentPairBalance?.sell?.balance);
		}
		if (mode === 'Buy') {
			setCode(currentPairBalance?.buy?.code?.toUpperCase());
		} else {
			setCode(currentPairBalance?.sell?.code?.toUpperCase());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuth, marketType, currentPair.pair, walletsData, isolated, isolatedWallets]);

	return (
		<div className="orderform-content-item">
			<div className="orderform-input-title">
				<p>{String(L.translate('Trade.avbl'))}</p>
				<p className="trade-form__text">
					{isAuth && (
						<>
							{balance} {code}
						</>
					)}
				</p>
			</div>
			<SwitchOrderType
				orderType={orderType}
				mode={mode}
				asset={currentPairBalance}
				marketType={marketType}
			/>
		</div>
	);
};

export default memo(BuySellWrapper);
