import { FC } from 'react';
import L from 'i18n-react';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { ITransactionsTabItem } from './types';

const TransactionsTabItem: FC<ITransactionsTabItem> = ({ item }) => {
	let amountUsdt = Number(item.amount_usdt)?.toString();
	amountUsdt = amountUsdt?.slice(0, Number(amountUsdt?.indexOf('.')) + 7);
	const actionTypeEditView = (transactions_type: string | null) => {
		if (transactions_type === 'write_in') {
			return String(L.translate('Trade.Perpetual.Tables.deposit_to_futures'));
		}
		if (transactions_type === 'commission') {
			return String(L.translate('Trade.Perpetual.Tables.commission'));
		}
		if (transactions_type === 'realized_pnl') {
			return String(L.translate('Trade.Perpetual.Tables.realized_pnl'));
		}
		return transactions_type;
	};
	return (
		<div className="tr">
			<div className="td">
				{/* Time */}
				<p>{item.created_at && customDateFormat(new Date(item.created_at), true)}</p>
			</div>
			<div className="td">
				{/* Asset */}
				<p>{item.asset?.toUpperCase()}</p>
			</div>
			<div className="td">
				{/* Action Type */}
				<p>{actionTypeEditView(item.transactions_type)}</p>
			</div>
			<div className="td">
				{/* Amount My Funds */}
				<p>{amountUsdt}</p>
			</div>
			<div className="td td--right">
				{/* Remarks */}
				<p>
					USDT
					{item.order_id ? `/${item.order_id}` : null}
				</p>
			</div>
		</div>
	);
};
export default TransactionsTabItem;
