import { useState } from 'react';
import L from 'i18n-react';
import {
	getBaseAsset,
	getPerpetualBalance,
	getPerpetualBaseAssetsLoader,
} from 'redux/reducers/perpetual/selectors';
import { useSelector } from 'react-redux';
import Loader from 'ui/Loader';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import LimitOrderForm from './Limit';
import MarketOrderForm from './Market';
import StopLimitOrderForm from './StopLimit';

const OrderForm = ({ price }: { price: string }) => {
	const [orderTab, setOrderTab] = useState('limit');
	const balance = useSelector(getPerpetualBalance);
	const assetLoader = useSelector(getPerpetualBaseAssetsLoader);

	const handleOnModeClick = (e: any) => {
		const { name } = e.target;
		if (name === 'limit' || name === 'market' || name === 'stopLimit') {
			setOrderTab(name);
		}
	};

	const orderFormTabs = {
		limit: <LimitOrderForm balance={balance} priceFromOrderBook={price} />,
		market: <MarketOrderForm balance={balance} />,
		stopLimit: <StopLimitOrderForm balance={balance} priceFromOrderBook={price} />,
	};

	return (
		<SocketWithPrivateTopicConnectWrapper topicName="derivative_wallet:derivative">
			<div className="orderform">
				<div className="orderform-tabs">
					<div className="table-nav flex flex--gap4" onClick={handleOnModeClick}>
						<button
							className={`button button--width-auto button--size3 ${
								orderTab === 'limit' ? '' : 'button--type11'
							}`}
							type="button"
							name="limit"
						>
							{String(L.translate('Trade.Perpetual.OrderForm.limit_button'))}
						</button>
						<button
							className={`button button--width-auto button--size3 ${
								orderTab === 'market' ? '' : 'button--type11'
							}`}
							type="button"
							name="market"
						>
							{String(L.translate('Trade.Perpetual.OrderForm.market_button'))}
						</button>
						<button
							className={`button button--width-auto button--size3 flex--gap8 ${
								orderTab === 'stopLimit' ? '' : 'button--type11'
							}`}
							type="button"
							name="stopLimit"
						>
							{String(L.translate('Trade.Perpetual.OrderForm.stop_limit_button'))}
							<svg
								width="15"
								height="16"
								viewBox="0 0 15 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2.74913 3.24912C3.96448 2.03379 5.64481 1.28125 7.5 1.28125C9.35519 1.28125 11.0355 2.03379 12.2509 3.24912L11.9194 3.58058L12.2509 3.24913C13.4662 4.46448 14.2188 6.14481 14.2188 8C14.2188 9.85519 13.4662 11.5355 12.2509 12.7509C11.0355 13.9662 9.35519 14.7188 7.5 14.7188C5.64481 14.7188 3.96448 13.9662 2.74913 12.7509L3.08058 12.4194L2.74912 12.7509C1.53379 11.5355 0.78125 9.85519 0.78125 8C0.78125 6.14481 1.53379 4.46448 2.74912 3.24913L2.74913 3.24912ZM7.5 2.21875C5.90344 2.21875 4.45877 2.86533 3.41203 3.91204C2.36532 4.95877 1.71875 6.40344 1.71875 8C1.71875 9.59656 2.36533 11.0412 3.41204 12.088C4.45877 13.1347 5.90344 13.7812 7.5 13.7812C9.09656 13.7812 10.5412 13.1347 11.5879 12.0879C12.6347 11.0412 13.2812 9.59656 13.2812 8C13.2812 6.40344 12.6347 4.95877 11.588 3.91204C10.5412 2.86533 9.09656 2.21875 7.5 2.21875Z"
									fill="#1C1C29"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M5.15625 6.32031C5.15625 5.0259 6.20559 3.97656 7.5 3.97656C8.79441 3.97656 9.84375 5.0259 9.84375 6.32031C9.84375 7.4542 9.03855 8.40002 7.96875 8.61718V9.44531C7.96875 9.7042 7.75888 9.91406 7.5 9.91406C7.24112 9.91406 7.03125 9.7042 7.03125 9.44531V8.19531C7.03125 7.93643 7.24112 7.72656 7.5 7.72656C8.27665 7.72656 8.90625 7.09696 8.90625 6.32031C8.90625 5.54367 8.27665 4.91406 7.5 4.91406C6.72335 4.91406 6.09375 5.54367 6.09375 6.32031C6.09375 6.5792 5.88388 6.78906 5.625 6.78906C5.36612 6.78906 5.15625 6.5792 5.15625 6.32031Z"
									fill="#1C1C29"
								/>
								<path
									d="M8.03125 11.4766C8.03125 11.77 7.7934 12.0078 7.5 12.0078C7.2066 12.0078 6.96875 11.77 6.96875 11.4766C6.96875 11.1832 7.2066 10.9453 7.5 10.9453C7.7934 10.9453 8.03125 11.1832 8.03125 11.4766Z"
									fill="#1C1C29"
									stroke="#1C1C29"
									strokeWidth="0.5"
								/>
							</svg>
						</button>
					</div>
				</div>
				<div className="orderform-content orderform-content--type2">
					{assetLoader ? (
						<div className="orderbook-loader-wrapper">
							<Loader />
						</div>
					) : (
						orderFormTabs[orderTab as keyof typeof orderFormTabs]
					)}
				</div>
			</div>
		</SocketWithPrivateTopicConnectWrapper>
	);
};

export default OrderForm;
