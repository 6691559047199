import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { IOptionsTableRow } from 'redux/reducers/options/types';
import { updateCreateOrder } from 'redux/reducers/options/reducer';
import { numberExists } from 'services/utils/numberValidation';
import TableCell from './TableCell/TableCell';

const TableRow: FC<IOptionsTableRow> = ({ options_call, options_put }) => {
	const dispatch = useDispatch();
	const onCallDoubleClick = () => {
		dispatch(updateCreateOrder(options_call));
	};
	const onPutDoubleClick = () => {
		dispatch(updateCreateOrder(options_put));
	};

	console.log(options_call?.iv);

	return (
		<div className="tr option-trades-tr">
			<div className="tr-level2" onDoubleClick={onCallDoubleClick}>
				<TableCell>
					<p className="td-hidden-name">Open</p>
					<p>{Number(options_call?.open_size).toFixed(1) || '-'}</p>
				</TableCell>
				<TableCell>
					<p className="td-hidden-name">Delta</p>
					<p>{options_call?.delta !== undefined ? Number(options_call?.delta).toFixed(2) : '-'}</p>
				</TableCell>
				<TableCell>
					<p className="td-hidden-name">Bid Size</p>
					<p>{Number(options_call?.bid_size).toFixed(2) || '-'}</p>
				</TableCell>
				<TableCell>
					<p className="td-hidden-name">Bid</p>
					<p className="green">
						{numberExists(options_call?.bid_price) ? options_call?.bid_price : '-'}
					</p>
					<span>
						{numberExists(options_call?.bid_iv)
							? `${String(options_call?.bid_iv?.toFixed(2)?.replace(/\.00$/, ''))}%`
							: '-'}
					</span>
				</TableCell>
				<TableCell>
					<p className="td-hidden-name">Mark Price</p>
					<p>
						{options_call?.mark_price !== undefined
							? Number(options_call?.mark_price).toFixed(2)
							: '-'}
					</p>
					<span>
						{!Number.isNaN(Number(options_call?.iv)) && numberExists(options_call?.iv)
							? `${String(Number(options_call?.iv).toFixed(2)?.replace(/\.00$/, ''))}%`
							: '-'}
					</span>
				</TableCell>
				<TableCell>
					<p className="td-hidden-name">Ask</p>
					<p className="red">
						{numberExists(options_call?.ask_price) ? options_call?.ask_price : '-'}
					</p>
					<span>
						{numberExists(options_call?.ask_iv)
							? `${String(options_call?.ask_iv?.toFixed(2)?.replace(/\.00$/, ''))}%`
							: '-'}
					</span>
				</TableCell>
				<TableCell>
					<p className="td-hidden-name">Ask Size</p>
					<p>{Number(options_call?.ask_size).toFixed(2) || '-'}</p>
				</TableCell>
				<TableCell>
					<p className="td-hidden-name">Position</p>
					<p>{options_call?.teta !== undefined ? Number(options_call?.teta).toFixed(2) : '-'}</p>
				</TableCell>
			</div>

			<div className="tr-level2 tr-level2--center">
				<TableCell>
					<p className="td-hidden-name">Strike</p>
					<p className="fw-700">{Number(options_call?.strike_price).toFixed(0) || '-'}</p>
				</TableCell>
			</div>

			<div className="tr-level2 tr-level2--right" onDoubleClick={onPutDoubleClick}>
				<TableCell>
					<p className="td-hidden-name">Position</p>
					<p>{options_put?.teta !== undefined ? Number(options_put?.teta).toFixed(2) : '-'}</p>
				</TableCell>
				<TableCell>
					<p className="td-hidden-name">Bid Size</p>
					<p>{Number(options_put?.bid_size).toFixed(2) || '-'}</p>
				</TableCell>
				<TableCell>
					<p className="td-hidden-name">Bid</p>
					<p className="green">
						{numberExists(options_put?.bid_price) ? options_put?.bid_price : '-'}
					</p>
					<span>
						{numberExists(options_put?.bid_iv)
							? `${String(Number(options_put?.bid_iv).toFixed(2)?.replace(/\.00$/, ''))}%`
							: ''}
					</span>
				</TableCell>
				<TableCell>
					<p className="td-hidden-name">Mark Price</p>
					<p>
						{options_put?.mark_price !== undefined
							? Number(options_put?.mark_price).toFixed(2)
							: '-'}
					</p>
					<span>
						{!Number.isNaN(Number(options_put?.iv)) && numberExists(options_put?.iv)
							? `${String(Number(options_put?.iv).toFixed(2)?.replace(/\.00$/, ''))}%`
							: '-'}
					</span>
				</TableCell>
				<TableCell>
					<p className="td-hidden-name">Ask</p>
					<p className="red">
						{numberExists(options_put?.ask_price) ? options_put?.ask_price : '-'}
					</p>
					<span>
						{numberExists(options_put?.ask_iv)
							? `${String(options_put?.ask_iv?.toFixed(2)?.replace(/\.00$/, ''))}%`
							: '-'}
					</span>
				</TableCell>
				<TableCell>
					<p className="td-hidden-name">Ask Size</p>
					<p>{Number(options_put?.ask_size).toFixed(2) || '-'}</p>
				</TableCell>
				<TableCell>
					<p className="td-hidden-name">Delta</p>
					<p>{options_put?.teta !== undefined ? Number(options_put?.teta).toFixed(2) : '-'}</p>
				</TableCell>
				<TableCell>
					<p className="td-hidden-name">Open</p>
					<p>{Number(options_put?.open_size).toFixed(1) || '-'}</p>
				</TableCell>
			</div>
		</div>
	);
};

export default TableRow;
