import { FC } from 'react';
import { SpotTradeWithOrderBook } from './SpotTradeWithOrderBook';

export const Trade: FC = () => {
	// const workspaceSettings = useSelector(getWorkspaceSettings);

	// const spotTradeWithOrderBook =
	// 	workspaceSettings?.orderBookSection && !workspaceSettings?.tradingPairsSection;

	// const spotTradeWithTradingPairs =
	// 	workspaceSettings?.tradingPairsSection && !workspaceSettings?.orderBookSection;

	// const spotTradeWithOrderBookAndTradingPairs =
	// 	workspaceSettings?.tradingPairsSection && workspaceSettings?.orderBookSection;

	// const spotTradeWithoutOrderBookAndTradingPairs =
	// 	!workspaceSettings?.tradingPairsSection && !workspaceSettings?.orderBookSection;
	// workspaceSettings;
	return (
		<>
			<SpotTradeWithOrderBook />
			{/* {spotTradeWithTradingPairs && <SpotTradeWithTradingPairs />}
			{spotTradeWithOrderBookAndTradingPairs && <SpotTradeWithOrderBookAndTradingPairs />}
			{spotTradeWithoutOrderBookAndTradingPairs && <SpotTradeWithoutOrderBookAndTradingPairs />} */}
		</>
	);
};
