/* eslint-disable no-debugger */
import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import L from 'i18n-react';
import {
	getPerpetualMarketType,
	getPerpetualIsolatedLeverage,
	getPerpetualMyOrders,
	getPerpetualOpenPositions,
	getBaseAsset,
	getPerpetualRecentTrades,
} from 'redux/reducers/perpetual/selectors';
import { setIsolatedLeverage } from 'redux/reducers/perpetual/reducer';
import AdjustLeveragePopup from 'components/Trade/PerpetualTrades/Popups/AdjustLeverage';
import AdjustLeverageMaticPopup from 'components/Trade/PerpetualTrades/Popups/AdjustLeverageMatic';
import SocketWithTopicConnectWrapper from 'components/SocketWithTopicConnectWrapper';
import { getDateDiffFormatted } from './utils';
import PerpetualSelect from './PerpetualSelect';

const TradeHeader = () => {
	const dispatch = useDispatch();
	const [lastPriceChange, setLastPriceChange] = useState(0);
	const [leverageOpen, setLeverageOpen] = useState(false);
	const [leverageMarticOpen, setLeverageMaticOpen] = useState(false);
	const [restTime, setRestTime] = useState<string>('00:00:00');
	const intervalRef = useRef<number | null>(null);
	const marketType = useSelector(getPerpetualMarketType);
	const leverage = useSelector(getPerpetualIsolatedLeverage);
	const myOrders = useSelector(getPerpetualMyOrders);
	const openPositions = useSelector(getPerpetualOpenPositions);
	const baseAsset = useSelector(getBaseAsset);
	const recentTrades = useSelector(getPerpetualRecentTrades);

	const changeHolder = () => {
		if (lastPriceChange >= 1) {
			return ((lastPriceChange - 1) * 100).toFixed(2);
		}
		return ((1 - lastPriceChange) * 100).toFixed(2);
	};

	useEffect(() => {
		if (recentTrades) {
			const lastPrice = recentTrades[0]?.fill_price || 1;
			const prevPrice = recentTrades[1]?.fill_price || 1;
			setLastPriceChange(lastPrice / prevPrice);
		}
	}, [recentTrades]);

	const intervalCallback = useCallback(() => {
		const date = new Date();
		const hoursUTC = date.getUTCHours();
		const minutesUTC = date.getUTCMinutes();
		const secondsUTC = date.getUTCSeconds();
		if (minutesUTC === 0 && secondsUTC === 0 && [0, 8, 16].includes(hoursUTC)) {
			setRestTime('00:00:00');
		}
		if (hoursUTC < 8) {
			const staticDate = new Date(
				Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 8, 0, 0),
			);
			const { hh, mm, ss } = getDateDiffFormatted(staticDate, date);
			setRestTime(`${hh}:${mm}:${ss}`);
		}
		if (hoursUTC >= 8 && hoursUTC < 16) {
			const staticDate = new Date(
				Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 16, 0, 0),
			);
			const { hh, mm, ss } = getDateDiffFormatted(staticDate, date);
			setRestTime(`${hh}:${mm}:${ss}`);
		}
		if (hoursUTC >= 16 && hoursUTC <= 23) {
			const staticDate = new Date(
				Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 16, 0, 0),
			);
			const { hh, mm, ss } = getDateDiffFormatted(date, staticDate);
			const diffDate = new Date(
				Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), +hh, +mm, +ss),
			);
			const staticDate1 = new Date(
				Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 8, 0, 0),
			);
			const { hh: hh1, mm: mm1, ss: ss1 } = getDateDiffFormatted(staticDate1, diffDate);
			setRestTime(`${hh1}:${mm1}:${ss1}`);
		}
	}, []);

	const onLoad = useCallback(() => {
		if (!intervalRef.current) {
			intervalRef.current = window.setInterval(intervalCallback, 1000);
		}
	}, [intervalCallback]);

	useEffect(() => {
		if (intervalRef.current) {
			window.clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
		onLoad();
		return () => {
			if (intervalRef.current) {
				window.clearInterval(intervalRef.current);
				intervalRef.current = null;
			}
		};
	}, [onLoad]);

	const [newLeverage, setNewLeverage] = useState<number>(
		!leverage && leverage !== 0 ? 1 : leverage,
	);
	const [oldLeverage, setOldLeverage] = useState<number>(1);

	const handleLeverageOpen = () => {
		setLeverageOpen(true);
	};

	const handleLeverageClose = () => {
		setLeverageOpen(false);
	};

	const handleLeverageMaticClose = () => {
		dispatch(setIsolatedLeverage(oldLeverage));
		setLeverageMaticOpen(false);
	};

	const handleConfirmLeverage = () => {
		if (openPositions && openPositions?.data?.length > 0) {
			setLeverageMaticOpen(true);
		}
		dispatch(setIsolatedLeverage(newLeverage));
	};

	const handleSetLeverage = () => {
		dispatch(setIsolatedLeverage(newLeverage));
		setLeverageMaticOpen(false);
	};

	const changePrice = Number(changeHolder())?.toFixed(2);

	return (
		<SocketWithTopicConnectWrapper topicName="assets_pairs:future">
			<div className="trade-header trade-header--type2">
				<div className="trade-header-item flex flex-m flex--gap16">
					<PerpetualSelect />
					<div className="select-block">
						<div className="select select--trade">
							<button className="select__current" type="button" onClick={handleLeverageOpen}>
								{String(L.translate(`Trade.Perpetual.${String(marketType)}`))} {leverage}X
							</button>
						</div>
					</div>
					<div className="trade-header-price">
						<p className="trade-header-price__change green">
							<span className="trade-header-price__change-icon">
								<svg
									className={`perpetualHeaderArrow{${Number(changePrice) >= 0 ? 'Up' : 'Down'}}`}
									width="24"
									height="25"
									viewBox="0 0 24 25"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M12.0039 5.80029C12.4181 5.80029 12.7539 6.13608 12.7539 6.55029V18.5C12.7539 18.9143 12.4181 19.25 12.0039 19.25C11.5897 19.25 11.2539 18.9143 11.2539 18.5V6.55029C11.2539 6.13608 11.5897 5.80029 12.0039 5.80029Z"
										fill={Number(changePrice) >= 0 ? '#219653' : '#EB5757'}
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M11.4697 5.96967C11.7626 5.67678 12.2374 5.67678 12.5303 5.96967L18.5303 11.9697C18.8232 12.2626 18.8232 12.7374 18.5303 13.0303C18.2374 13.3232 17.7626 13.3232 17.4697 13.0303L12 7.56066L6.53033 13.0303C6.23744 13.3232 5.76256 13.3232 5.46967 13.0303C5.17678 12.7374 5.17678 12.2626 5.46967 11.9697L11.4697 5.96967Z"
										fill={Number(changePrice) >= 0 ? '#219653' : '#EB5757'}
									/>
								</svg>
							</span>
							<span style={{ color: Number(changePrice) >= 0 ? '#219653' : '#EB5757' }}>
								{baseAsset?.last_price}
							</span>
						</p>
					</div>
				</div>
				<div className="trade-header-item">
					<div className="trade-header-info">
						<p>{String(L.translate('Trade.Perpetual.Header.mark_price'))} (USDT)</p>
						<span>{baseAsset?.mark_price?.toFixed(2)}</span>
					</div>
				</div>
				<div className="trade-header-item">
					<div className="trade-header-info">
						<p>{String(L.translate('Trade.Perpetual.Header.change'))}</p>
						<span className={`${lastPriceChange >= 1 ? 'green' : 'red'}`}>{changeHolder()} %</span>
					</div>
				</div>
				<div className="trade-header-item">
					<div className="trade-header-info">
						<p>{String(L.translate('Trade.Perpetual.Header.open_interest'))} (USDT)</p>
						<span>{baseAsset?.open_interest}</span>
					</div>
				</div>
				<div className="trade-header-item">
					<div className="trade-header-info">
						<p>
							<span className="funding-rate">
								{String(L.translate('Trade.Perpetual.Header.funding_rate'))}
								<span className="funding-rate__drop">
									{String(L.translate('Trade.Perpetual.Header.its_mechanics'))}
								</span>
							</span>
						</p>
						<span>
							{baseAsset?.funding_rate}% <span className="funding-rate-time">({restTime})</span>
						</span>
					</div>
				</div>
				<div className="trade-header-item">
					<div className="trade-header-info">
						<p>{String(L.translate('Trade.Perpetual.Header.volume'))} (USDT)</p>
						<span>{baseAsset?.['24h_volume']?.toFixed(2)}</span>
					</div>
				</div>
			</div>
			{leverageOpen && (
				<AdjustLeveragePopup
					leverage={newLeverage}
					setOldLeverage={setOldLeverage}
					setLeverage={setNewLeverage}
					onSubmit={handleConfirmLeverage}
					onClose={handleLeverageClose}
				/>
			)}
			{leverageMarticOpen && (
				<AdjustLeverageMaticPopup
					leverage={leverage}
					onClose={handleLeverageMaticClose}
					onSubmit={handleSetLeverage}
				/>
			)}
		</SocketWithTopicConnectWrapper>
	);
};
export default TradeHeader;
