import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNewPasswordRequest, userSettingsRequest } from 'redux/reducers/settings/reducer';
import { getUsersSettings } from 'redux/reducers/settings/selectors';
import AccountSettingsGeneralItem from './AccountSettingsGeneralIteam';
import { IChangePasswordValue } from './AccountSettingsGeneralPassword/types';

const AccountSettingsGeneral = () => {
	const dispatch = useDispatch();
	const user = useSelector(getUsersSettings);
	const changePasswordSubmit = (value: IChangePasswordValue) => {
		dispatch(setNewPasswordRequest(value));
	};

	useEffect(() => {
		dispatch(userSettingsRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<AccountSettingsGeneralItem user={user} changePasswordSubmit={changePasswordSubmit} />
		</>
	);
};

export default AccountSettingsGeneral;
