import { FC } from 'react';
import { useSelector } from 'react-redux';
import Footer from 'layouts/Footer';
import Helmet from 'react-helmet';
import { Header } from 'layouts-elements/Header';
import { getTheme } from 'redux/reducers/settings/selectors';
import { Content } from 'layouts-elements/Content';
import { APP_NAME } from 'services/constants/env';

import { IAuth } from './types';

// ==========================================:
export const Auth: FC<IAuth> = ({ children, title }) => {
	const theme = useSelector(getTheme);
	const pageTitle = title ? `| ${title}` : '';

	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet>
			<div className={`wrapper ${theme}`}>
				<Header />
				<Content>{children}</Content>
				<Footer />
			</div>
		</>
	);
};
