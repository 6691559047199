/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPair } from 'redux/reducers/tradingSettings/reducer';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { getAssetPairsListFavorite } from 'redux/reducers/assetPairs/selectors';
import { putAssetPairsRequestFavoriteId } from 'redux/reducers/assetPairs/reducer';
import { useHistory, useParams } from 'react-router';
import { IPairsTableItemProps } from './types';

const PairsTableItem: FC<IPairsTableItemProps> = ({ pair }) => {
	const { code, change24, last_price, view_decimal, id } = pair;
	const dispatch = useDispatch();
	const favoritePairs = useSelector(getAssetPairsListFavorite);
	const route = useHistory();

	const getIsFavorite = () => {
		if (!favoritePairs) return false;
		return Object.values(favoritePairs).some((favCode) => favCode === code);
	};
	const isFavorite = getIsFavorite();

	const transformAssetPairCode = code?.replace('_', '/').toUpperCase();
	const getTableValueClass = (value: number): string => {
		switch (true) {
			case value > 0:
				return 'green';
			case value < 0:
				return 'red';

			default:
				return 'table-value';
		}
	};

	// const getActiveMargin = (cross: number, isolated: number) => {
	// 	switch (true) {
	// 		case Boolean(cross) && Boolean(!isolated):
	// 			return <span className="margin-x">{pair?.shoulder_cross || 0}x</span>;
	// 		case Boolean(!cross) && Boolean(isolated):
	// 			return <span className="margin-x">{pair?.shoulder_isolate || 0}x</span>;
	// 		case Boolean(cross) && Boolean(isolated):
	// 			return (
	// 				<span className="margin-x">
	// 					{pair?.shoulder_cross > pair?.shoulder_isolate
	// 						? pair?.shoulder_cross
	// 						: pair?.shoulder_isolate || 0}
	// 					x
	// 				</span>
	// 			);
	// 		default:
	// 			return null;
	// 	}
	// };

	const getActiveMargin = (cross: number, isolated: number) => {
		switch (true) {
			case Boolean(route.location.pathname.includes('MarginTrading')) && Boolean(isolated):
				return <span className="margin-x">{pair?.shoulder_isolate || 0}x</span>;
			case Boolean(route.location.pathname.includes('spot')):
				return null;
			default:
				return null;
		}
	};

	const handleChangePair = () => {
		dispatch(setCurrentPair({ pair: code, id: Number(id) }));
	};
	const item = (
		<div
			className="market-list__line"
			onClick={(e) => {
				e.stopPropagation();
				handleChangePair();
			}}
		>
			<span>
				<button
					onClick={(e) => {
						dispatch(putAssetPairsRequestFavoriteId({ code, is_choosen: isFavorite ? 0 : 1 }));
						e.stopPropagation();
					}}
					type="button"
				>
					<span className="svg-star">
						<svg
							width="10"
							height="11"
							viewBox="0 0 10 11"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M4.46355 0.847861L3.24299 3.43348L0.512158 3.84944C0.0224388 3.92365 -0.173823 4.55443 0.181317 4.91572L2.15701 6.92719L1.68973 9.76863C1.60561 10.2822 2.12337 10.667 2.55701 10.4268L5 9.08512L7.44299 10.4268C7.87663 10.665 8.39439 10.2822 8.31028 9.76863L7.84299 6.92719L9.81868 4.91572C10.1738 4.55443 9.97756 3.92365 9.48784 3.84944L6.75701 3.43348L5.53645 0.847861C5.31776 0.38698 4.68411 0.381122 4.46355 0.847861Z"
								fill={isFavorite ? '#FF57D4' : '#9D9DBC'}
							/>
						</svg>
					</span>
				</button>

				<span className="favorites-name__text">{transformAssetPairCode}</span>
				{getActiveMargin(pair?.active_cross, pair?.active_isolate)}
			</span>

			<span className={getTableValueClass(Number(last_price))}>
				{fixedCropNumber(last_price, view_decimal) || last_price}
			</span>

			<span className={getTableValueClass(Number(change24))}>{change24}%</span>
		</div>
	);
	if (route.location.pathname.includes('MarginTrading') && pair.active_isolate) {
		return item;
	}
	if (route.location.pathname.includes('spot') && pair.active) {
		return item;
	}
	return null;
};

export default PairsTableItem;
