import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { PayloadAction } from '@reduxjs/toolkit';
import {
	twoFaCheckEnabledRequest,
	twoFaCheckEnabledSuccess,
	twoFaGenerateKeyRequest,
	twoFaGenerateKeySuccess,
	twoFaEnableRequest,
	twoFaDisableRequest,
	twoFaLoadingDisable,
	twoFaSmsEnableRequest,
	twoFaSmsDisableRequest,
	twoFaInitState,
	twoFaCheckEnabledError,
} from './reducer';
import { ITwoFaResponse, ITwoFaSmsEnableRequest, ITwoFaStatusResponse } from './types';
// =============================================================:

// =============================================================:
function* twoFaCheckEnabledRequestWorker({ payload }: PayloadAction<string>) {
	try {
		const response: ITwoFaStatusResponse = yield call(api.twoFa.twoFaCheckEnabled, payload);
		yield put(twoFaCheckEnabledSuccess(response));
	} catch (error) {
		yield put(twoFaCheckEnabledError());
	} finally {
		yield put(twoFaLoadingDisable());
	}
}

function* twoFaGenerateKeyRequestWorker() {
	try {
		const response: ITwoFaResponse = yield call(api.twoFa.twoFaGenerateKey);
		yield put(twoFaGenerateKeySuccess(response));
	} catch (error) {
		notificationContainer('2FA Key not found', 'error');
		yield put(twoFaInitState());
	}
}

function* twoFaEnableRequestWorker({ payload }: PayloadAction<string>) {
	try {
		yield call(api.twoFa.twoFaEnable, { totp: payload });
		notificationContainer('2FA successfully activated', 'success');
	} catch (error) {
		notificationContainer('2FA code entered incorrectly', 'error');
	} finally {
		yield put(twoFaCheckEnabledRequest());
	}
}

function* twoFaDisableRequestWorker({ payload }: PayloadAction<string>) {
	try {
		yield call(api.twoFa.twoFaDisable, { totp: payload });
		notificationContainer('2FA successfully turned off', 'success');
	} catch (error) {
		notificationContainer('2FA code entered incorrectly', 'error');
	} finally {
		yield put(twoFaCheckEnabledRequest());
	}
}

function* twoFaSmsEnableRequestWorker({ payload }: PayloadAction<ITwoFaSmsEnableRequest>) {
	try {
		yield call(api.twoFa.twoFaSmsEnable, payload);
		notificationContainer('2FA successfully activated', 'success');
	} catch (error) {
		notificationContainer('2FA code entered incorrectly', 'error');
	} finally {
		yield put(twoFaCheckEnabledRequest());
	}
}

function* twoFaSmsDisableRequestWorker({ payload }: PayloadAction<string>) {
	try {
		yield call(api.twoFa.twoFaSmsDisable, { sms_totp: payload });
		notificationContainer('2FA successfully turned off', 'success');
	} catch (error) {
		notificationContainer('2FA code entered incorrectly', 'error');
	} finally {
		yield put(twoFaCheckEnabledRequest());
	}
}
// =============================================================:

// =============================================================:
export function* twoFaSaga() {
	yield takeEvery(twoFaCheckEnabledRequest, twoFaCheckEnabledRequestWorker);
	yield takeEvery(twoFaGenerateKeyRequest, twoFaGenerateKeyRequestWorker);
	yield takeEvery(twoFaEnableRequest, twoFaEnableRequestWorker);
	yield takeEvery(twoFaDisableRequest, twoFaDisableRequestWorker);
	yield takeEvery(twoFaSmsEnableRequest, twoFaSmsEnableRequestWorker);
	yield takeEvery(twoFaSmsDisableRequest, twoFaSmsDisableRequestWorker);
}
