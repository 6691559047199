import { useSelector } from 'react-redux';
import L from 'i18n-react';
import {
	getBaseAsset,
	getPerpetualBaseAsset,
	getPerpetualRecentTrades,
	getPerpRecentTradesLoader,
} from 'redux/reducers/perpetual/selectors';
import SocketWithTopicConnectWrapper from 'components/SocketWithTopicConnectWrapper';
import Loader from 'ui/Loader';
import TradeTableItem from './TradeTableItem';

const TradeBook = () => {
	const recentTrades = useSelector(getPerpetualRecentTrades);
	const currentAsset = useSelector(getPerpetualBaseAsset);
	const recentTradesLoader = useSelector(getPerpRecentTradesLoader);

	const renderItems = () => {
		if (recentTrades) {
			let prevPrice = 0;
			const trades = JSON.parse(JSON.stringify(recentTrades)).reverse();
			return trades
				.map((element: any, index: number) => {
					if (index === 0) {
						prevPrice = element.fill_price;
						return (
							<TradeTableItem
								color="green"
								price={element.fill_price}
								amount={element.amount_asset_filled}
								time={element.created_at}
								key={Number(element.fill_price) + Number(element.created_at) * Math.random()}
							/>
						);
					}
					if (element.fill_price >= prevPrice) {
						prevPrice = element.fill_price;
						return (
							<TradeTableItem
								color="green"
								price={element.fill_price}
								amount={element.amount_asset_filled}
								time={element.created_at}
								key={Number(element.fill_price) + Number(element.created_at) * Math.random()}
							/>
						);
					}
					prevPrice = element.fill_price;
					return (
						<TradeTableItem
							color="red"
							price={element.fill_price}
							amount={element.amount_asset_filled}
							time={element.created_at}
							key={Number(element.fill_price) + Number(element.created_at) * Math.random()}
						/>
					);
				})
				.reverse();
		}
		return null;
	};
	return (
		<SocketWithTopicConnectWrapper
			// topicName={`trade_history:future:${String(currentAsset?.base_asset)}`}
			topicName="future_recent_trades:future"
		>
			<div className="trades">
				<div className="trades-nav">
					<p className="trades-nav__item">
						{String(L.translate('Trade.Perpetual.recent_trades_title'))}
					</p>
				</div>
				<div className="trades-list">
					<div className="trades-list__line trades-list__line--header">
						<span className="grey">
							{String(L.translate('Trade.Perpetual.OrderBook.price_title'))}(USDT)
						</span>
						<span className="grey">
							{String(L.translate('Trade.Perpetual.OrderBook.amount_title'))} (
							{currentAsset?.base_asset.toUpperCase()})
						</span>
						<span className="grey">{String(L.translate('Trade.Perpetual.time'))}</span>
					</div>
					<div className="trades-list__scroll">
						{recentTradesLoader ? (
							<div className="recent-trades-loader-wrapper">
								<Loader />
							</div>
						) : (
							renderItems()
						)}
					</div>
				</div>
			</div>
		</SocketWithTopicConnectWrapper>
	);
};

export default TradeBook;
