import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import L from 'i18n-react';
/* eslint-disable no-debugger */
import { getForgotPassIsLoad } from 'redux/reducers/auth/selectors';
import { forgotPasswordRequest } from 'redux/reducers/auth/reducer';
import { Auth } from 'layouts/Auth';
import ForgotPassForm from 'components/Forms/ForgotPassForm';
import { IForgotPassSubmitValue } from 'components/Forms/ForgotPassForm/types';
import GoogleCaptcha from 'components/GoogleCaptcha';
import Loader from 'ui/Loader';
import { useHistory } from 'react-router';

// ==========================================:
export const ForgotPassword: FC = () => {
	const forgotPassIsLoad = useSelector(getForgotPassIsLoad);
	const dispatch = useDispatch();
	const history = useHistory();
	const [open, setOpen] = useState(false);
	const [forgotPassData, setForgotPassData] = useState<null | IForgotPassSubmitValue>(null);

	const handleSubmitEmail = (values: IForgotPassSubmitValue) => {
		setForgotPassData(values);
		setOpen(true);
	};

	const hangleCaptchaClose = (): void => {
		setForgotPassData(null);
		setOpen(false);
	};

	const handleCaptcha = (value: string): void => {
		if (forgotPassData && value) {
			dispatch(forgotPasswordRequest({ data: forgotPassData, history }));
		}

		setForgotPassData(null);
		setOpen(false);
	};

	return (
		<Auth title="forgot password">
			<div className="authorization-section">
				<div className="authorization-section__container">
					<div className="authorization">
						<ForgotPassForm emailSubmit={handleSubmitEmail} />
						{forgotPassIsLoad && (
							<div className="auth-loader-wrapper">
								<Loader />
							</div>
						)}
					</div>
				</div>
			</div>
			{open && (
				<div className="popup-window popup-window--captcha">
					<div className="popup-window__inside">
						<GoogleCaptcha setCaptcha={handleCaptcha} captchaClose={hangleCaptchaClose} />
						<div className="popup-window__close" onClick={hangleCaptchaClose} />
					</div>
				</div>
			)}
		</Auth>
	);
};
