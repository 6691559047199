import { FC } from 'react';
import L from 'i18n-react';
import { IOrderBookItem } from './types';

const AskItem: FC<IOrderBookItem> = ({ size: _size, price: _price, total: _total }) => {
	let size: number | string = _size;
	if (typeof size === 'number') {
		size = Number(size)?.toFixed(6);
	}
	size = size?.replace(/\.0{2}$/, '');

	let price: number | string = _price;
	if (typeof price === 'number') {
		price = Number(price)?.toFixed(2);
	}
	price = price?.replace(/\.0{2}$/, '');

	let total: number | string = _total;
	if (typeof total === 'number') {
		total = Number(total)?.toFixed(6);
	}
	total = total?.replace(/\.0{2}$/, '');

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">
					{String(L.translate('Trade.Options.OrderForm.OrderBook.bid_prise'))}
				</p>
				<p className="red">{price || 0}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">
					{String(L.translate('Trade.Options.OrderForm.OrderBook.bid_size'))}
				</p>
				<p>{size || 0}</p>
			</div>
			<div className="td td-width">
				<p className="td-hidden-name">
					{String(L.translate('Trade.Options.OrderForm.OrderBook.total'))}
				</p>
				<p>{total || 0}</p>
			</div>
		</div>
	);
};

export default AskItem;
