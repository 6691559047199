import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMarginCallHistoryRequest } from 'redux/reducers/marginWallets/reducer';
import {
	getMarginWalletsIsLoader,
	getMarginIsolatedCallHistory,
} from 'redux/reducers/marginWallets/selectors';
import usePagination from 'hooks/usePagination';
import MarginCallsPage from 'components/MarginWallets/CallsPage';

const CallsIsolated: FC = () => {
	const dispatch = useDispatch();
	const { perPage, currentPage } = usePagination();
	const loading = useSelector(getMarginWalletsIsLoader);
	const calls = useSelector(getMarginIsolatedCallHistory);

	useEffect(() => {
		dispatch(
			getMarginCallHistoryRequest({
				isolated: true,
				params: {
					per_page: perPage,
					current_page: currentPage,
				},
			}),
		);
	}, [currentPage, dispatch, perPage]);

	return <MarginCallsPage calls={calls} isolated loading={loading} />;
};

export default CallsIsolated;
