import { useMemo } from 'react';

const ModalHeader = ({ data }: { data: string | undefined }) => {
	const header = data?.split('-');

	const side = useMemo(() => {
		if (header?.[3]) {
			if (header[3] === 'C') {
				return 'Call';
			}
			return 'Put';
		}
		return '--';
	}, [header]);

	return (
		<div className="popup-header">
			<div className="options-details-header">
				<div className="options-details-header__info">
					<p>{header?.[0].toUpperCase() || '--'}</p>
				</div>
				<div className="select-block">
					<div className="select select--type6">
						<span className="select__current">{header?.[1].toUpperCase() || '--'}</span>
					</div>
				</div>
				<div className="select-block">
					<div className="select select--type6">
						<span className="select__current">{header?.[2] || '--'}</span>
					</div>
				</div>
				<div className="select-block">
					<div className="select select--type6">
						<span className="select__current">{side}</span>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ModalHeader;
