import React, { useEffect } from 'react';
import L from 'i18n-react';

const About = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="static-section section-gradient">
			<div className="inside">
				<p className="section-title">{String(L.translate('StaticPages.AboutUsPage.about_us'))}</p>
				<div className="static-content">
					<p>
						<strong>{String(L.translate('StaticPages.AboutUsPage.1ex_is'))}</strong>{' '}
						{String(L.translate('StaticPages.AboutUsPage.paragraph_1'))}
					</p>
					<p>{String(L.translate('StaticPages.AboutUsPage.paragraph_2'))}</p>
					<h3>{String(L.translate('StaticPages.AboutUsPage.header_1'))}</h3>
					<p>{String(L.translate('StaticPages.AboutUsPage.paragraph_3'))}</p>
					<p>{String(L.translate('StaticPages.AboutUsPage.paragraph_4'))}</p>
					<h3>{String(L.translate('StaticPages.AboutUsPage.header_2'))}</h3>
					<p>{String(L.translate('StaticPages.AboutUsPage.paragraph_5'))}</p>
				</div>
			</div>
		</section>
	);
};

export default About;
