import L from 'i18n-react';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'ui/Loader';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import PastDaysSelect from 'components/TransactionHistory/PastDaysSelect';
import AssetSelect from 'components/TransactionHistory/AssetSelect';
import TransactionTypeSelect from 'components/TransactionHistory/TransactionTypeSelect';
import TableRow from 'components/TransactionHistory/FiatTableRow';
import { deleteSearchParam } from 'services/utils/url';
import { getAssetsList } from 'redux/reducers/assets/selectors';
import { getFiatHistoryRequest } from 'redux/reducers/wallets/reducer';
import { getWalletsFiatHistory, getWalletsHistoryLoader } from 'redux/reducers/wallets/selectors';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';

const TransactionHistoryFiat = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const [pastDays, setPastDays] = useState<number | null>(null);
	const [assetId, setAssetId] = useState<number | null>(null);
	const [type, setType] = useState<string | null>(null);
	const { perPage, currentPage } = usePagination();
	const loader = useSelector(getWalletsHistoryLoader);

	const assetsList = useSelector(getAssetsList)?.filter((assetItem) => !assetItem?.is_crypto);
	const fiatHistory = useSelector(getWalletsFiatHistory);

	const removeCurrentPage = () => {
		const search = deleteSearchParam('currentPage');
		history.replace({
			pathname: location.pathname,
			search,
		});
	};

	useEffect(() => {
		dispatch(
			getFiatHistoryRequest({
				params: {
					current_page: currentPage,
					per_page: perPage,
					past_days: pastDays || undefined,
					asset_id: assetId || undefined,
					type: type || undefined,
				},
			}),
		);
	}, [assetId, currentPage, dispatch, pastDays, perPage, type]);

	const onPastDaysChange = (_pastDays: number | null) => {
		removeCurrentPage();
		setPastDays(_pastDays);
	};

	const onAssetChange = (id: number | null) => {
		removeCurrentPage();
		setAssetId(id);
	};

	const onTypeChange = (_type: string | null) => {
		removeCurrentPage();
		setType(_type);
	};

	const resetFilter = () => {
		removeCurrentPage();
		setType(null);
		setPastDays(null);
		setAssetId(null);
	};

	const renderTableBody = () => {
		if (loader) {
			return (
				<div className="popup-loader">
					<Loader />
				</div>
			);
		}
		if (fiatHistory?.total === 0) {
			return <TableEmptyPlug />;
		}
		return (
			<div className="table-body">
				{fiatHistory?.data?.map((item) => (
					<TableRow key={item.id} item={item} />
				))}
			</div>
		);
	};

	return (
		<div className="table table--transaction-history">
			<div className="table-filter flex flex-sb">
				<div className="table-filter-left flex flex-m flex--gap24">
					<TransactionTypeSelect
						label={String(L.translate('TransactionHistory.filters.type'))}
						value={type}
						onChange={onTypeChange}
					/>
					<PastDaysSelect
						label={String(L.translate('TransactionHistory.filters.time'))}
						value={pastDays}
						onChange={onPastDaysChange}
					/>
					<AssetSelect
						label={String(L.translate('TransactionHistory.filters.fiat'))}
						assetsList={assetsList || []}
						value={assetId}
						onChange={onAssetChange}
					/>
				</div>
				<div className="table-filter-right">
					<button type="button" onClick={resetFilter} className="button button--type3">
						{String(L.translate('TransactionHistory.filters.resetFilters'))}
					</button>
				</div>
			</div>
			<div className="table table--transaction-history table--transaction-history-fiat">
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<p>{String(L.translate('TransactionHistory.table.date'))}</p>
						</div>
						<div className="td">
							<p>{String(L.translate('TransactionHistory.table.currency'))}</p>
						</div>
						<div className="td">
							<p>{String(L.translate('TransactionHistory.table.payment_method'))}</p>
						</div>
						<div className="td">
							<p>{String(L.translate('TransactionHistory.table.indicated_amount'))}</p>
						</div>
						<div className="td">
							<p>{String(L.translate('TransactionHistory.table.fee'))}</p>
						</div>
						<div className="td">
							<p>{String(L.translate('TransactionHistory.table.amount'))}</p>
						</div>
						<div className="td td--right">
							<p>{String(L.translate('TransactionHistory.table.status'))}</p>
						</div>
					</div>
				</div>
				{renderTableBody()}
				{fiatHistory?.last_page && Number(fiatHistory?.total) > Number(fiatHistory?.per_page) && (
					<Pagination lastPage={fiatHistory.last_page} />
				)}
			</div>
		</div>
	);
};

export default TransactionHistoryFiat;
