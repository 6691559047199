import { FC, useMemo } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import Loader from 'ui/Loader';
// import { getUserSettingsDataLoader, getReferrals } from 'redux/reducers/settings/selectors';
import { TInvitationsAll } from 'redux/reducers/settings/types';
import { TableItem } from './TableItem';
import { TTable } from './type';
/* eslint-disable no-debugger */

export const RefferalsTable: FC<TTable> = ({ data }) => {
	// const dataLoad = useSelector(getUserSettingsDataLoader);
	// const referralsInfo = useSelector(getReferrals);
	// debugger;

	return (
		<div className="table table--refferals table--refferals-bonuses">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p>{L.translate('Account.Referral.referral_list_email')}</p>
					</div>
					<div className="td">
						<p>{L.translate('Account.Referral.referral_list_date')}</p>
					</div>
					<div className="td td--right">
						<p>{L.translate('Account.Referral.referral_list_status')}</p>
					</div>
				</div>
			</div>
			<div className="table-body">
				{/* {dataLoad ? (
										<div className="table-loader-wrapper">
											<Loader small />
										</div>
									) : null} */}
				{data
					? data?.map((item: TInvitationsAll) => (
							<TableItem key={String(item?.id) + item.email} item={item} />
					  ))
					: null}
			</div>
		</div>
	);
};
