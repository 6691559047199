import { useState } from 'react';
import { useDispatch } from 'react-redux';
import L from 'i18n-react';
import { createNewOrderRequest, showConfirmModal } from 'redux/reducers/options/reducer';
import CloseModalButton from '../../CloseModalButton';
import { IOrderCreateData } from './types';

const ConfirmPopup = ({
	closeModal,
	side,
	data,
	contract,
	setDefault,
}: {
	closeModal: any;
	side: string;
	data: IOrderCreateData;
	contract: string | undefined;
	setDefault: any;
}) => {
	const [check, setCheck] = useState(false);
	const dispatch = useDispatch();

	const handleShowConfirmModal = () => {
		setCheck(!check);
	};

	const handleCloseModal = () => {
		dispatch(showConfirmModal(!check));
		closeModal();
	};

	const handleOrderCreate = () => {
		dispatch(createNewOrderRequest(data));
		setDefault();
		handleCloseModal();
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup">
					<CloseModalButton closeModal={handleCloseModal} />
					<div className="popup-header">
						<p className="popup-header__title">
							{String(L.translate('Trade.Options.OrderForm.ConfirmPopup.title'))}
						</p>
					</div>
					<div className="popup-body">
						<div className="flex-column">
							<div className="withdrawal-info-pop withdrawal-info-pop--type2">
								<p>
									{String(L.translate('Trade.Options.OrderForm.ConfirmPopup.instrument'))}:{' '}
									<span>{contract}</span>
								</p>
								<p>
									{String(L.translate('Trade.Options.OrderForm.ConfirmPopup.order_type'))}:{' '}
									<span>{String(L.translate('Trade.Options.OrderForm.ConfirmPopup.limit'))}</span>
								</p>
								<p>
									{String(L.translate('Trade.Options.OrderForm.ConfirmPopup.side'))}:{' '}
									<span>{side}</span>
								</p>
								<p>
									{String(L.translate('Trade.Options.OrderForm.ConfirmPopup.size'))}:{' '}
									<span>{data.amount_asset_request}</span>
								</p>
								<p>
									{String(L.translate('Trade.Options.OrderForm.ConfirmPopup.price'))}:{' '}
									<span>{data.price_entry_for_order}</span>
								</p>
							</div>
							<div className="checkbox checkbox--no-mb">
								<label className="checkbox__label flex-t">
									<input type="checkbox" className="hidden" onChange={handleShowConfirmModal} />
									<span className=" checkbox__item">
										<span className="checkbox__item-icon">
											<svg
												width="17"
												height="17"
												viewBox="0 0 17 17"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M14.5426 4.58269C14.7501 4.79016 14.7501 5.12653 14.5426 5.33399L7.45931 12.4173C7.25184 12.6248 6.91547 12.6248 6.70801 12.4173L3.16634 8.87566C2.95888 8.66819 2.95888 8.33183 3.16634 8.12436C3.37381 7.91689 3.71018 7.91689 3.91764 8.12436L7.08366 11.2904L13.7913 4.58269C13.9988 4.37523 14.3352 4.37523 14.5426 4.58269Z"
													fill="#FF57D4"
												/>
											</svg>
										</span>
									</span>
									<span className="checkbox__text">
										{String(L.translate('Trade.Options.dont_show_it_again'))}
									</span>
								</label>
							</div>
						</div>
					</div>
					<div className="popup-footer popup-footer--item2">
						<button
							className="button button--type3 button--size2 button--full-width"
							type="button"
							onClick={handleCloseModal}
						>
							{String(L.translate('Global.cancel'))}
						</button>
						<button
							className="button button--size2 button--full-width"
							type="button"
							onClick={handleOrderCreate}
						>
							{String(L.translate('Trade.Options.OrderForm.ConfirmPopup.create_a_nth_order'))}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConfirmPopup;
