import React, { useEffect } from 'react';
import L from 'i18n-react';

const Glossary = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const renderItems = () => {
		const result = [];
		for (let item = 1; item <= 143; item += 1) {
			result.push(
				<p>
					<strong>{String(L.translate(`StaticPages.GlossaryPage.title_${item}`))}</strong>{' '}
					{String(L.translate(`StaticPages.GlossaryPage.paragraph_${item}`))}
				</p>,
			);
		}
		return result;
	};

	return (
		<section className="static-section section-gradient">
			<div className="inside">
				<p className="section-title">
					{String(L.translate('StaticPages.GlossaryPage.glossary_title'))}
				</p>
				<div className="static-content">{renderItems()}</div>
			</div>
		</section>
	);
};

export default Glossary;
