import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import { ChangeEvent, FC, MouseEvent, useState, useEffect } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getSpotUserOpenOrders } from 'redux/reducers/spotTrade/selectors';
import { getAssetPairsRequestFavorite } from 'redux/reducers/assetPairs/reducer';
import LoginOrRegister from 'ui/LoginOrRegister';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { getSearchParam, changeSearchParams, deleteSearchParam } from 'services/utils/url';
import OpenOrders from './OpenOrders';
import { OrdersHistory } from './OrdersHistory';
import TradesHitory from './TradesHitory';

const SpotHistoryTables: FC = () => {
	const currentPair = useSelector(getCurrentPair);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const openOrders = useSelector(getSpotUserOpenOrders);
	const [currentType, setCurrentType] = useState('spot');
	const location = useLocation();
	const history = useHistory();

	const { pathname } = location;

	const [otherPairsIsHide, setOtherPairsIsHide] = useState(false);
	const dispatch = useDispatch();

	const removeCurrentPage = () => {
		const search = deleteSearchParam('currentPage');
		history.replace({
			pathname,
			search,
		});
	};

	useEffect(() => {
		dispatch(getAssetPairsRequestFavorite());
	}, [dispatch]);

	useEffect(() => {
		if (location.pathname.includes('MarginTrading')) {
			setCurrentType('margin');
		} else {
			setCurrentType('spot');
		}
	}, [location.pathname, location?.search]);

	const toggleHistoryTab: (_history: string) => void = (_history) => {
		const newUrl = changeSearchParams({ history: _history }, ['currentPage']);
		history.replace({
			pathname,
			search: newUrl.slice(newUrl.indexOf('?')),
		});
	};

	const handleChangeCurrentTableTab = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;

		// setCurrentTableTab(name);
		toggleHistoryTab(name);
	};

	const currentTableTab = getSearchParam('history');

	const totalOpenOrders = openOrders?.total || 0;

	const currentTable = {
		open: <OpenOrders otherPairsIsHide={otherPairsIsHide} />,
		closed: <OrdersHistory otherPairsIsHide={otherPairsIsHide} />,
		trades: <TradesHitory otherPairsIsHide={otherPairsIsHide} />,
	};

	const handleChangeOtherPairsIsHide = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target;
		removeCurrentPage();
		setOtherPairsIsHide(checked);
	};

	return (
		<SocketWithPrivateTopicConnectWrapper topicName="private_common_trade_history:spot">
			<SocketWithPrivateTopicConnectWrapper
				topicName={`private_trade_history:spot:${String(currentPair.pair)}`}
			>
				<SocketWithPrivateTopicConnectWrapper topicName={`opened_user_orders:${currentType}`}>
					<SocketWithPrivateTopicConnectWrapper topicName={`closed_user_orders:${currentType}`}>
						<div className="trade-tables">
							<div className="trade-tables-nav flex flex-m flex-sb">
								<div className="table-nav flex flex--gap8">
									<button
										type="button"
										className={`button button--width-auto button--size3 ${
											!currentTableTab || currentTableTab === 'open' ? '' : 'button--type11'
										}`}
										name="open"
										onClick={handleChangeCurrentTableTab}
									>
										{String(L.translate('Trade.Spot.SpotHistoryTables.open_orders_tab_name'))} (
										{totalOpenOrders})
									</button>
									<button
										type="button"
										className={`button button--width-auto button--size3 ${
											currentTableTab === 'closed' ? '' : 'button--type11'
										}`}
										name="closed"
										onClick={handleChangeCurrentTableTab}
									>
										{String(L.translate('Trade.Spot.SpotHistoryTables.order_history_tab_name'))}
									</button>
									<button
										type="button"
										className={`button button--width-auto button--size3 ${
											currentTableTab === 'trades' ? '' : 'button--type11'
										}`}
										name="trades"
										onClick={handleChangeCurrentTableTab}
									>
										{String(L.translate('Trade.Spot.SpotHistoryTables.trade_history_tab_name'))}
									</button>
								</div>
								<div className="checkbox checkbox--type3 checkbox--no-mb">
									<label className="checkbox__label">
										<input
											type="checkbox"
											className="hidden"
											checked={otherPairsIsHide}
											onChange={handleChangeOtherPairsIsHide}
										/>
										<span className=" checkbox__item">
											<span className="checkbox__item-icon">
												<svg
													width="17"
													height="17"
													viewBox="0 0 17 17"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M14.5426 4.58269C14.7501 4.79016 14.7501 5.12653 14.5426 5.33399L7.45931 12.4173C7.25184 12.6248 6.91547 12.6248 6.70801 12.4173L3.16634 8.87566C2.95888 8.66819 2.95888 8.33183 3.16634 8.12436C3.37381 7.91689 3.71018 7.91689 3.91764 8.12436L7.08366 11.2904L13.7913 4.58269C13.9988 4.37523 14.3352 4.37523 14.5426 4.58269Z"
														fill="#FF57D4"
													/>
												</svg>
											</span>
										</span>
										<span className="checkbox__text">
											{String(
												L.translate('Trade.Spot.SpotHistoryTables.hide_other_pairs_checkbox'),
											)}
										</span>
									</label>
								</div>
							</div>
							{authIsAuthenticated ? (
								currentTable[(currentTableTab as keyof typeof currentTable) || 'open']
							) : (
								<div className="table-history-login-register-wrapper">
									<LoginOrRegister />
								</div>
							)}
						</div>
					</SocketWithPrivateTopicConnectWrapper>
				</SocketWithPrivateTopicConnectWrapper>
			</SocketWithPrivateTopicConnectWrapper>
		</SocketWithPrivateTopicConnectWrapper>
	);
};

export default SpotHistoryTables;
