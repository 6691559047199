import { FC } from 'react';
import L from 'i18n-react';
import { ITransferTableRow } from './types';

const WALLETS = ['spot', 'isolated', 'perpetual'];

const TransferTableRow: FC<ITransferTableRow> = ({ item }) => {
	const { date, asset, from, to, amount } = item;
	const fromWallet = WALLETS.includes(from?.toLowerCase() || '')
		? String(L.translate(`TransactionHistory.Wallets.${String(from?.toLowerCase())}`))
		: from;

	const toWallet = WALLETS.includes(to?.toLowerCase() || '')
		? String(L.translate(String(`TransactionHistory.Wallets.${String(to?.toLowerCase())}`)))
		: to;

	return (
		<div className="tr">
			<div className="td">
				<p className="grey">{date}</p>
			</div>
			<div className="td">
				<p>{asset?.code?.toUpperCase()}</p>
			</div>
			<div className="td capitalize">
				<p>{fromWallet}</p>
			</div>
			<div className="td capitalize">
				<p>{toWallet}</p>
			</div>
			<div className="td td--right">
				<p>{amount}</p>
			</div>
		</div>
	);
};

export default TransferTableRow;
