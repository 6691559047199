import React, { useEffect } from 'react';
import L from 'i18n-react';

const CookiesPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="static-section section-gradient">
			<section className="static-section section-gradient">
				<div className="inside">
					<p className="section-title">
						{String(L.translate('StaticPages.CookiesPage.cookies_title'))}
					</p>
					<div className="static-content">
						<p>{String(L.translate('StaticPages.CookiesPage.paragraph_1'))}</p>
						<p>{String(L.translate('StaticPages.CookiesPage.paragraph_2'))}</p>

						<h3>{String(L.translate('StaticPages.CookiesPage.header_1'))}</h3>
						<p>{String(L.translate('StaticPages.CookiesPage.paragraph_3'))}</p>
						<p>{String(L.translate('StaticPages.CookiesPage.paragraph_4'))}</p>
						<p>{String(L.translate('StaticPages.CookiesPage.paragraph_5'))}</p>
						<p>
							{String(L.translate('StaticPages.CookiesPage.paragraph_6'))}{' '}
							<a href="http://www.allaboutcookies.org/" rel="noreferrer" target="_blank">
								All About Cookies
							</a>{' '}
							or{' '}
							<a href="https://www.aboutcookies.org/" rel="noreferrer" target="_blank">
								AboutCookies.org.
							</a>
						</p>

						<h3>{String(L.translate('StaticPages.CookiesPage.header_2'))}</h3>
						<p>{String(L.translate('StaticPages.CookiesPage.paragraph_7'))}</p>
						<p>{String(L.translate('StaticPages.CookiesPage.paragraph_8'))}</p>
						<p>{String(L.translate('StaticPages.CookiesPage.paragraph_9'))}</p>
						<p>{String(L.translate('StaticPages.CookiesPage.paragraph_10'))}</p>
						<p>{String(L.translate('StaticPages.CookiesPage.paragraph_11'))}</p>
						<p>{String(L.translate('StaticPages.CookiesPage.paragraph_12'))}</p>
						<ul>
							<li>
								<a
									href="https://support.google.com/chrome/answer/95647"
									rel="noreferrer"
									target="_blank"
								>
									Google Chrome
								</a>
							</li>
							<li>
								<a
									href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
									rel="noreferrer"
									target="_blank"
								>
									Mozilla Firefox
								</a>
							</li>
							<li>
								<a
									href="https://support.microsoft.com/en-gb/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
									rel="noreferrer"
									target="_blank"
								>
									Microsoft Edge
								</a>
							</li>
							<li>
								<a
									href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
									rel="noreferrer"
									target="_blank"
								>
									Safari (macOS)
								</a>
							</li>
							<li>
								<a href="https://support.apple.com/en-gb/HT201265" rel="noreferrer" target="_blank">
									Safari (iOS)
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
		</section>
	);
};

export default CookiesPage;
