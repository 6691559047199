export interface IPerpetualTradeStore {
	orderBook: IOrderbook | null;
	orderBookLoader: boolean;
	recentTrades: any | null;
	recentTradesLoader: boolean;
	orderHistory: any;
	orderHistoryLoader: boolean;
	marketType: MarketType;
	isolatedLeverage: number;
	baseAssetStatisticsLoader: boolean;
	baseAssetStatistics: IBaseAssetResponse | null;
	baseAsset: IBaseAssetItem | null;
	balance: any | null;
	transactionHistoryLoader: boolean;
	openPositions: IOpenPositions | null;
	myOrdersLoader: boolean;
	myOrders: IPerpetualMyOrdersResponse | null;
	tradeHistoryLoader: boolean;
	tradeHistory: ITradeHistory | null;
	transactionsLoader: boolean;
	transactions: any;
	unrealisedPnlLoader: boolean;
	unrealisedPnl: any;
	stopOrdersLoader: boolean;
	stopOrders: IStopOrders | null;
	stopOrderHistoryLoader: boolean;
	stopOrderHistory: IStopOrderHistory | null;
	closedOrderHistoryLoader: boolean;
	closedOrderHistory: IClosedOrderHistory | null;
	userStat: null | { total_assets: number; cumulative_realised_pnl: number };
	activeBaseAssetLoader: boolean;
	activeBaseAsset: IActiveBaseAsset[] | null;
	derivativeAccountLoader: boolean;
	derivativeAccount: IDerivativeAccount | null;
}

export interface IActiveBaseAsset {
	id: number;
	base_asset: string;
	base_asset_id: number;
	maker_fee_option?: number;
	min_price_option?: number;
}

export interface IOrderbook {
	ask: Array<IOrderBookItem>;
	bid: Array<IOrderBookItem>;
}

export interface IOrderBookItem {
	amount_asset_request: number | null;
	amount_asset_request_left: number | null;
	price_entry_for_order: number;
	total: string | number | null;
}

export interface IOrderHistoryResponse {
	current_page: number;
	data: Array<IPerpetualOrderHistoryItem>;
	first_page_url: string;
	from: any;
	last_page: number;
	last_page_url: string;
	links: any;
	next_page_url: any;
	path: string;
	per_page: number;
	prev_page_url: any;
	to: any;
	total: number;
}

export interface IBaseAssetItem {
	id: number;
	base_asset: string;
	mark_price: number;
	open_interest: number;
	funding_rate: number;
	'24h_volume': number;
	last_price: number;
}

export type IBaseAssetResponse = Array<IBaseAssetItem>;

export enum MarketType {
	Cross = 'cross',
	Isolated = 'isolated',
}

export interface IBalanceResponse {
	available_balance_usdt: number | null;
}

export interface ICreateOrderResponse {
	error?: any;
	amount_asset_request: number;
	amount_asset_request_left: number;
	amount_executed_in_asset: number | null;
	amount_executed_in_usdt: number | null;
	amount_leverage_deposit_in_usdt: number;
	asset_future_settings_id: number;
	asset_settings: {
		base_asset: string;
		base_asset_id: number;
		created_at: null;
		funding_rate: number;
		id: number;
		impact_margin_notional: number;
		interest_base_index: number;
		interest_quote_index: number;
		is_active: number;
		maintenance_margin_rate: number;
		maker_fee: number;
		max_funding_rate: number;
		max_leverage: number;
		min_funding_rate: number;
		min_lot: number;
		price_decimals: number;
		quantity_decimals: number;
		taker_fee: number;
		updated_at: null;
	};
	base_asset: string;
	created_at: number;
	created_from_entity_id: null;
	entity_creator_class_name: null;
	future_position_id: null;
	id: number;
	leverage_future_size: number;
	pre_freeze_in_usdt: number;
	price_avarage_completed: null;
	price_entry_for_order: number;
	price_entry_for_stop_order: null;
	status: string;
	stop_loss: null;
	symbol_tp_sl: null;
	take_profit: null;
	transactions_type: string;
	trigger_by_type_price: string;
	type_isolated: string;
	type_order: string;
	updated_at: number;
	user_id: number;
}

export interface IOpenPositionsResponse {
	id: number;
}

export interface IMyOrdersResponse {
	id: number;
}

export interface ITransactionHistoryReqPayload {
	is_show_all_asset: 0 | 1;
	base_asset: string;
}

export interface IOpenPositionItem {
	id: number;
	base_asset: string | null;
	transactions_type: string | null;
	amount_base_asset: number | null;
	amount_exequted_in_usdt: number | null;
	amount_leverage_deposit_in_usdt: number | null;
	middle_leverage_size: number | null;
	entry_price: number | null;
	liquidation_price: number | null;
	margin: number;
	pnl_realized: number | null;
}

export interface IPerpetualMyOrdersResponse {
	data: Array<IPerpetualMyOrdersItem>;
	total: number;
	last_page: number;
	per_page: number;
}

export interface ITradeHistory {
	data: {
		total: number;
		per_page: number;
		last_page: number;
		current_page: number;
		orders: Array<ITradeHistoryOrder>;
	};
}

interface PaginatedData<T> {
	total: number;
	per_page: number;
	last_page: number;
	current_page: number;
	data: Array<T>;
}

export type IOpenPositions = PaginatedData<IOpenPositionItem>;

export type ITransactions = PaginatedData<ITransactionItem>;

export type IStopOrders = PaginatedData<IStopOrdersItem>;

export type IStopOrderHistory = PaginatedData<IStopOrderHistoryItem>;

export type IClosedOrderHistory = PaginatedData<IClosedOrderHistoryItem>;

export interface IPerpetualMyOrdersItem {
	id: number;
	base_asset: string | null;
	created_at: number | string | null;
	// Side
	transactions_type: string | null;
	// Price
	price_entry_for_order: number | null;
	// Size
	amount_asset_request: number | null;
	// Unfilled
	amount_asset_request_left: number | null;
	// Fill Price
	price_avarage_completed: number | null;
	// Stop Order
	symbol_tp_sl: string | null;
	take_profit: number | null;
	stop_loss: number | null;
}

export interface IPerpetualOrderHistoryItem {
	// ID
	order_id?: number;
	// Base Asset
	base_asset: string | null;
	// Created At
	created_at: number | string | null;
	// End Time
	updated_at: number | string | null;
	// Side
	transactions_type: string | null;
	// Order Price
	price_entry_for_order: number | string | null;
	// Size
	amount_asset_request: number | null;
	// Order Filled
	amount_asset_filled: number | null;
	// Fill Price
	price_avarage_completed: number | string | null;
	// Stop Order
	stop_order: string | null;
	// Close reason
	close_reason: string | null;
	status: string | null;
}

export interface ITradeHistoryOrder {
	// Contract
	contract: string | null;
	// ROLE/ID
	order_id: number | null;
	// Size
	size: number | null;
	// Fill Price
	file_price: number | null;
	// Role
	role: string | null;
	// Created At
	created_at: number | string | null;
}

export interface ITransactionItem {
	id: number;
	// Time
	created_at: number | string | null;
	// Asset
	asset: string | null;
	// Action Type
	transactions_type: string | null;
	// Amount My Funds
	amount_usdt: number | null;
	// Remarks
	order_id?: number | null;
}

export interface IStopOrdersItem {
	// Contract
	base_asset: string | null;
	// ROLE/ID
	id: number;
	// Created At
	created_at: string | number | null;
	// Side
	transactions_type: string | null;
	type_order: string | null;
	take_profit: number | null;
	stop_loss: number | null;
	// Trigger Price
	trigger_by_type_price: string | null;
	// Order Price
	price_entry_for_order: number | null;
	// Size
	amount_asset_request: number | string | null;
}

export interface IStopOrderHistoryItem {
	// Contract
	base_asset: string | null;
	// ID
	order_id: number | null;
	// Created At
	created_at: string | number | null;
	// End Time
	updated_at: string | number | null;
	// Side
	transactions_type: string | null;
	// Trigger Price
	price_avarage_completed: string | number | null;
	// Order Price
	price_entry_for_order: number | null;
	// Size
	amount_asset_filled: number | string | null;
	amount_asset_request: number | string | null;
	take_profit: number | null;
	stop_loss: number | null;
	stop_order: string | null;
	close_reason: string | null;
}

export interface IClosedOrderHistoryItem {
	// Time
	created_at: number | string | null;
	// Contract
	base_asset: string | null;
	// Side
	transactions_type: string | null;
	// Profit/Loss
	stop_order: string | null;
	// Source
}

export interface IDerivativeAccount {
	balance_usdt: number;
	balance_usd: number;
	available_balance_usdt: number;
	available_balance_usd: number;
	unrealized_pnl: number;
	unrealized_pnl_usd: number;
	option_margin: number;
	option_margin_usd: number;
	future_margin: number;
	future_margin_usd: number;
}
