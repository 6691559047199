import { FC } from 'react';
import { convertToFixed } from 'services/utils/convertToFixed';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { numberExists } from 'services/utils/numberValidation';
import { IConvertTableRow } from './types';

const ConvertTableRow: FC<IConvertTableRow> = ({
	created_at,
	fee,
	from_asset_code,
	to_asset_code,
	toIsCrypto,
	amount,
}) => {
	return (
		<div className="tr">
			<div className="td">
				<p className="grey">{customDateFormat(created_at, false, true)}</p>
			</div>
			<div className="td">
				<p>{convertToFixed(fee)}</p>
			</div>
			<div className="td">
				<p>{from_asset_code.toUpperCase()}</p>
			</div>
			<div className="td">
				<p>{to_asset_code.toUpperCase()}</p>
			</div>
			<div className="td td--right">
				<p>{convertToFixed(amount, toIsCrypto ? 6 : 2)}</p>
			</div>
		</div>
	);
};

export default ConvertTableRow;
