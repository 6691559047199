import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOptionOrderPosition, IOptionsListItem, IOptionsTradeStore } from './types';

const initialState: IOptionsTradeStore = {
	createOrder: null,
	ordersList: null,
	ordersListLoading: false,
	statisticLoader: false,
	newOrderLoader: false,
	showModal: true,
	structure: null,
	structureLoader: false,
	optionsAllLoader: false,
	optionsAll: null,
	positionsLoader: false,
	positions: null,
	activeOrdersLoader: false,
	activeOrders: null,
	orderHistoryLoader: false,
	orderHistory: null,
	tradeHistoryLoader: false,
	tradeHistory: null,
	showCancelOrderConfirm: true,
	orderBook: null,
	orderBookLoader: false,
	optionStatistics: null,
	recentTrades: null,
	userImMm: null,
	lastPrice: 0,
	prevLastPrice: 0,
};

const optionsTrade = createSlice({
	name: '@@optionsTrade',
	initialState,
	reducers: {
		updateCreateOrder: (state, { payload }: PayloadAction<IOptionOrderPosition | null>) => {
			const changeCreateOrderState = state;
			changeCreateOrderState.createOrder = payload;
		},
		getOrdersListRequest: (state) => {
			const getOrdersListState = state;
			getOrdersListState.ordersListLoading = true;
		},
		getOrdersListSuccess: (state, { payload }: PayloadAction<IOptionsListItem[]>) => {
			const getOrdersListState = state;
			getOrdersListState.ordersList = payload;
			getOrdersListState.ordersListLoading = false;
		},
		updateOrderList: (state, action) => {},
		showHello: (state) => {
			console.log(state);
		},
		getStatisticRequest: (state, action) => {
			const statisticState = state;
			statisticState.statisticLoader = true;
		},
		getStatisticSuccess: (state, { payload }) => {
			const statisticState = state;
			statisticState.statisticLoader = false;
			statisticState.optionStatistics = payload;
		},
		createNewOrderRequest: (state, action) => {
			const orderState = state;
			orderState.newOrderLoader = true;
		},
		createNewOrderSuccess: (state, action) => {
			const { payload } = action;
			const orderState = state;
			orderState.newOrderLoader = false;
		},
		showConfirmModal: (state, action) => {
			const { payload } = action;
			const confirmModalState = state;
			confirmModalState.showModal = !!payload;
		},
		getStructureRequest: (state, action) => {
			const tradeHistoryState = state;

			tradeHistoryState.structureLoader = true;
		},
		getStructureSuccess: (state, action) => {
			const tradeHistoryState = state;
			const { payload } = action;

			tradeHistoryState.structure = payload;
			tradeHistoryState.structureLoader = false;
		},
		getOptionsAllRequest: (state, action) => {
			const positionsAllState = state;

			positionsAllState.optionsAllLoader = true;
		},
		getOptionsAllSuccess: (state, action) => {
			const positionsAllState = state;
			const { payload } = action;

			positionsAllState.optionsAll = payload;
			positionsAllState.optionsAllLoader = false;
		},
		getPositionsRequest: (state, action) => {
			const positionsState = state;

			positionsState.positionsLoader = true;
		},
		getPositionsSuccess: (state, action) => {
			const positionsState = state;
			const { payload } = action;

			positionsState.positions = payload;
			positionsState.positionsLoader = false;
		},
		getActiveOrdersRequest: (state, action) => {
			const positionsAllState = state;

			positionsAllState.activeOrdersLoader = true;
		},
		getActiveOrdersSuccess: (state, action) => {
			const positionsAllState = state;
			const { payload } = action;

			positionsAllState.activeOrders = payload;
			positionsAllState.activeOrdersLoader = false;
		},
		updateActiveOrdersOnOpen: (state, action) => {},
		updateActiveOrdersOnCancel: (state, action) => {},
		deleteActiveOrder: (state, action) => {},
		getOrderHistoryRequest: (state, action) => {
			const orderHistoryState = state;

			orderHistoryState.orderHistoryLoader = true;
		},
		getOrderHistorySuccess: (state, action) => {
			const orderHistoryState = state;
			const { payload } = action;

			orderHistoryState.orderHistory = payload;
			orderHistoryState.orderHistoryLoader = false;
		},
		getTradeHistoryRequest: (state, action) => {
			const tradeHistoryState = state;

			tradeHistoryState.tradeHistoryLoader = true;
		},
		getTradeHistorySuccess: (state, action) => {
			const tradeHistoryState = state;
			const { payload } = action;

			tradeHistoryState.tradeHistory = payload;
			tradeHistoryState.tradeHistoryLoader = false;
		},
		setShowCancelOrderConfirm: (state, action) => {
			const showCancelOrderConfirmState = state;
			const { payload } = action;

			showCancelOrderConfirmState.showCancelOrderConfirm = payload;
		},
		setOrderBook: (state, action) => {
			const orderBookState = state;
			const { payload } = action;
			orderBookState.orderBook = payload;
		},
		setOptionStatistics: (state, action) => {
			const statisticsState = state;
			const { payload } = action;
			console.log(payload);
			statisticsState.optionStatistics = payload;
		},
		getUserImMmRequest: (state, action) => {},
		setRecentTrades: (state, action) => {
			const { payload } = action;
			const recentTradesState = state;
			recentTradesState.recentTrades = payload;
		},
		setUserImMm: (state, action) => {
			const userImMmState = state;
			const { payload } = action;
			userImMmState.userImMm = payload;
		},
		getOrderbookTradesRequest: (state, action) => {},
		getOrderbookTradesSuccess: (state, action) => {
			const { payload } = action;
			const orderBookState = state;
			orderBookState.orderBook = payload.order_book;
			orderBookState.recentTrades = payload.recent_trades;
		},
		receiveLastPrice: (state, action) => {},
		setLastPrice: (state, action) => {
			const lastPriceState = state;
			const { payload } = action;

			lastPriceState.lastPrice = payload;
		},
		setPrevLastPrice: (state, action) => {
			const lastPriceState = state;
			const { payload } = action;

			lastPriceState.prevLastPrice = payload;
		},
	},
});

export default optionsTrade.reducer;
export const {
	updateCreateOrder,
	getOrdersListRequest,
	getOrdersListSuccess,
	getStatisticRequest,
	getStatisticSuccess,
	createNewOrderRequest,
	createNewOrderSuccess,
	showConfirmModal,
	showHello,
	getStructureRequest,
	getStructureSuccess,
	getOptionsAllRequest,
	getOptionsAllSuccess,
	getPositionsRequest,
	getPositionsSuccess,
	getActiveOrdersRequest,
	getActiveOrdersSuccess,
	getTradeHistoryRequest,
	getTradeHistorySuccess,
	getOrderHistoryRequest,
	getOrderHistorySuccess,
	setShowCancelOrderConfirm,
	setOrderBook,
	updateOrderList,
	setOptionStatistics,
	setUserImMm,
	getUserImMmRequest,
	receiveLastPrice,
	setLastPrice,
	setPrevLastPrice,
	setRecentTrades,
	getOrderbookTradesRequest,
	getOrderbookTradesSuccess,
	updateActiveOrdersOnCancel,
	updateActiveOrdersOnOpen,
	deleteActiveOrder,
} = optionsTrade.actions;
