import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import axios from 'axios';
import { analyticsSuccess, analyticsRequest, analyticsInitState } from './reducer';
import { IAnalyticsResponse } from './types';
import { IOrderbookRequestPayload, IOrderbookResponsePayload } from '../spotTrade/types';
import { getOrderBookSuccess } from '../spotTrade/reducer';
import { responseErrors } from '../../../services/http/responseErrors';
import { assetsInitState } from '../assets/reducer';

// =============================================================:
// =============================================================:

function* analyticsRequestWorker(action: PayloadAction<any>) {
	const { payload } = action;
	const params = { code: payload };
	try {
		yield put(showLoading());
		const response: IAnalyticsResponse = yield call(api.analytics.analytics, params);
		yield put(analyticsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(analyticsInitState());
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:

// =============================================================:
export function* analyticsSaga() {
	yield takeEvery(analyticsRequest, analyticsRequestWorker);
}
