import L from 'i18n-react';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'ui/Loader';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import PastDaysSelect from 'components/TransactionHistory/PastDaysSelect';
import AssetSelect from 'components/TransactionHistory/AssetSelect';
import TransferFromSelect from 'components/TransactionHistory/TransferFromSelect';
import TableRow from 'components/TransactionHistory/TransferTableRow';
import { deleteSearchParam } from 'services/utils/url';
import { getAssetsList } from 'redux/reducers/assets/selectors';
import { getTransferHistoryRequest } from 'redux/reducers/wallets/reducer';
import {
	getWalletsTransferHistory,
	getWalletsHistoryLoader,
} from 'redux/reducers/wallets/selectors';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';

const TransactionHistoryTransfer = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const [pastDays, setPastDays] = useState<number | null>(null);
	const [assetId, setAssetId] = useState<number | null>(null);
	const [fromWallet, setFromWallet] = useState<string | null>(null);
	const { perPage, currentPage } = usePagination();
	const loader = useSelector(getWalletsHistoryLoader);

	const assetsList = useSelector(getAssetsList);
	const transferHistory = useSelector(getWalletsTransferHistory);

	const assetCode = assetsList?.find((asset) => asset?.id === assetId)?.code;

	const removeCurrentPage = () => {
		const search = deleteSearchParam('currentPage');
		history.replace({
			pathname: location.pathname,
			search,
		});
	};

	useEffect(() => {
		dispatch(
			getTransferHistoryRequest({
				params: {
					current_page: currentPage,
					per_page: perPage,
					from_wallet: fromWallet || undefined,
					past_days: pastDays || undefined,
					asset_code: assetCode || undefined,
				},
			}),
		);
	}, [assetCode, currentPage, dispatch, fromWallet, pastDays, perPage]);

	const onFromWalletChange = (_from: string | null) => {
		removeCurrentPage();
		setFromWallet(_from);
	};

	const onPastDaysChange = (_pastDays: number | null) => {
		removeCurrentPage();
		setPastDays(_pastDays);
	};

	const onAssetChange = (id: number | null) => {
		removeCurrentPage();
		setAssetId(id);
	};

	const resetFilter = () => {
		removeCurrentPage();
		setPastDays(null);
		setAssetId(null);
		setFromWallet(null);
	};

	const renderTableBody = () => {
		if (loader) {
			return (
				<div className="popup-loader">
					<Loader />
				</div>
			);
		}
		if (transferHistory?.total === 0) {
			return <TableEmptyPlug />;
		}
		return (
			<div className="table-body">
				{transferHistory?.data?.map((item) => (
					<TableRow key={item.id} item={item} />
				))}
			</div>
		);
	};

	return (
		<div className="table table--transaction-history">
			<div className="table-filter flex flex-sb">
				<div className="table-filter-left flex flex-m flex--gap24">
					<TransferFromSelect
						label={String(L.translate('TransactionHistory.filters.from'))}
						value={fromWallet}
						onChange={onFromWalletChange}
					/>
					<PastDaysSelect
						label={String(L.translate('TransactionHistory.filters.time'))}
						value={pastDays}
						onChange={onPastDaysChange}
					/>
					<AssetSelect
						label={String(L.translate('TransactionHistory.filters.fiat'))}
						assetsList={assetsList || []}
						value={assetId}
						onChange={onAssetChange}
					/>
				</div>
				<div className="table-filter-right">
					<button type="button" onClick={resetFilter} className="button button--type3">
						{String(L.translate('TransactionHistory.filters.resetFilters'))}
					</button>
				</div>
			</div>
			<div className="table table--transaction-history table--transaction-history-transfer">
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<p>{String(L.translate('TransactionHistory.table.date'))}</p>
						</div>
						<div className="td">
							<p>{String(L.translate('TransactionHistory.table.coin'))}</p>
						</div>
						<div className="td">
							<p>{String(L.translate('TransactionHistory.table.from_wallet'))}</p>
						</div>
						<div className="td">
							<p>{String(L.translate('TransactionHistory.table.to_wallet'))}</p>
						</div>
						<div className="td td--right">
							<p>{String(L.translate('TransactionHistory.table.amount'))}</p>
						</div>
					</div>
				</div>
				{renderTableBody()}
				{transferHistory?.last_page &&
					Number(transferHistory?.total) > Number(transferHistory?.per_page) && (
						<Pagination lastPage={transferHistory.last_page} getCurrentHref={(s) => String(s)} />
					)}
			</div>
		</div>
	);
};

export default TransactionHistoryTransfer;
