import { FC } from 'react';
import L from 'i18n-react';
import { NavLink } from 'react-router-dom';

export const StaticHeader: FC = () => {
	return (
		<>
			<NavLink to="/login" className="button button--type3">
				{String(L.translate('Header.login'))}
			</NavLink>
			<NavLink to="/registration" className="button">
				{String(L.translate('Header.signup'))}
			</NavLink>
		</>
	);
};
