import L from 'i18n-react';

const MobNav = () => {
	return (
		<div className="trade-mob-nav">
			<button className="trade-mob-nav__item active" type="button">
				{String(L.translate('Trade.Perpetual.MobNav.chart'))}
			</button>
			<button className="trade-mob-nav__item" type="button">
				{String(L.translate('Trade.Perpetual.MobNav.orderbook'))}
			</button>
			<button className="trade-mob-nav__item" type="button">
				{String(L.translate('Trade.Perpetual.MobNav.trades'))}
			</button>
		</div>
	);
};
export default MobNav;
