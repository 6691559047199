import { FC, useEffect, ReactNode } from 'react';
import L from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getTradeHistory,
	getTradeHistoryLoader,
	getBaseAsset,
} from 'redux/reducers/perpetual/selectors';
import { getTradeHistoryRequest } from 'redux/reducers/perpetual/reducer';
import Loader from 'ui/Loader';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';
import LoginTradeTablePlug from '../TradeTablePlug';
import TradeHistoryTabItem from './TradeHistoryTabItem';
import { ITradeHistoryTab } from './types';

const TradeHistoryTab: FC<ITradeHistoryTab> = ({ showAll }) => {
	const dispatch = useDispatch();
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const tradeHistory = useSelector(getTradeHistory);
	// const tradeHistory = TRADE_HISTORY;
	const loader = useSelector(getTradeHistoryLoader);
	const baseAsset = useSelector(getBaseAsset);

	const { perPage, currentPage } = usePagination();

	useEffect(() => {
		if (baseAsset?.base_asset) {
			dispatch(
				getTradeHistoryRequest({
					is_show_all_asset: showAll ? 1 : 0,
					base_asset: baseAsset.base_asset,
					per_page: perPage,
					current_page: currentPage,
				}),
			);
		}
	}, [baseAsset?.base_asset, currentPage, dispatch, perPage, showAll]);

	const renderContent: () => ReactNode = () => {
		if (!authIsAuthenticated) {
			return <LoginTradeTablePlug />;
		}
		if (loader) {
			return (
				<div className="popup-loader">
					<Loader />
				</div>
			);
		}
		if (tradeHistory && tradeHistory?.data?.orders?.length > 0) {
			return tradeHistory?.data?.orders?.map((item, idx) => (
				// eslint-disable-next-line react/no-array-index-key
				<TradeHistoryTabItem key={idx} item={item} />
			));
		}
		return <TableEmptyPlug />;
	};

	return (
		<div className="table table--trade table--trade-perpetual-trade-history">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Tables.contract'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Tables.role'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Tables.size'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Tables.fill_price'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Tables.role_0'))}</p>
					</div>
					<div className="td td--right">
						<p>{String(L.translate('Trade.Perpetual.Tables.created_at'))}</p>
					</div>
				</div>
			</div>
			<div className="table-body">{renderContent()}</div>
			{tradeHistory?.data?.last_page &&
				Number(tradeHistory?.data?.total) > Number(tradeHistory?.data?.per_page) && (
					<Pagination lastPage={tradeHistory?.data.last_page} getCurrentHref={(s) => String(s)} />
				)}
		</div>
	);
};
export default TradeHistoryTab;
