import { useSelector } from 'react-redux';
import { selectOrdersList } from 'redux/reducers/options/selectors';
import ListTable from './ListTable/ListTable';

const ListTables = () => {
	const orderList = useSelector(selectOrdersList);

	return (
		<div className="options-list">
			{!!orderList &&
				!!orderList.length &&
				orderList.map((el) => <ListTable key={el.options_by_date.option_structure_id} {...el} />)}
		</div>
	);
};

export default ListTables;
