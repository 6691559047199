import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import { ITwoFoStore } from './types';

// ==========================================:
const getTwoFaState = (state: TStoreState): ITwoFoStore => state.twoFa;
export const getTwoFa = createSelector([getTwoFaState], (twoFa: ITwoFoStore) => twoFa);

// ====================================================:
export const getTwoFaData = createSelector([getTwoFa], (twoFa: ITwoFoStore) => twoFa.twoFaDate);
export const getTwoFaLoading = createSelector(
	[getTwoFa],
	(twoFa: ITwoFoStore) => twoFa.twoFaLoading,
);
export const getTwoFaEnableStatus = createSelector(
	[getTwoFa],
	(twoFa: ITwoFoStore) => twoFa.twoFaEnabledStatus,
);
