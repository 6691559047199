import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import L from 'i18n-react';
import { getUserImMm, getLastPrice, getPrevLastPrice } from 'redux/reducers/options/selectors';
import { getUserImMmRequest } from 'redux/reducers/options/reducer';

const OptionsHeader = () => {
	const dispatch = useDispatch();
	const data = useSelector(getUserImMm);
	const lastPrice = useSelector(getLastPrice);
	const prevLastPrice = useSelector(getPrevLastPrice);

	useEffect(() => {
		dispatch(getUserImMmRequest({}));
	}, [dispatch]);

	const imWidth =
		Number(data?.account_im.account_im_percent) > 100 ? '100' : data?.account_im.account_im_percent;

	const mmWidth =
		Number(data?.account_mm.account_mm_percent) > 100 ? '100' : data?.account_mm.account_mm_percent;

	return (
		<div className="options-header">
			<div className="options-header__left">
				<div className="options-header__title">
					<p>{String(L.translate('Trade.Options.Header.title'))}</p>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89136 2.75 2.75 6.89136 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75Z"
							fill="#7D7D4D"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M11.8498 6C11.4565 6 11.1377 6.31881 11.1377 6.71207V6.96552H10.8845C9.29143 6.96552 8 8.25695 8 9.85003C8 11.4416 9.28899 12.7321 10.88 12.7346L10.8847 12.7346H11.1377V15.6553H9.19501C8.80178 15.6553 8.48291 15.9741 8.48291 16.3674C8.48291 16.7608 8.80197 17.0795 9.1951 17.0795H11.1377V17.3329C11.1377 17.7262 11.4565 18.045 11.8498 18.045C12.243 18.045 12.5618 17.7262 12.5618 17.3329V17.0795H12.8157C14.4088 17.0795 15.7002 15.788 15.7002 14.1949C15.7002 12.6019 14.4088 11.3104 12.8157 11.3104H12.5618V8.38966H14.5052C14.8985 8.38966 15.2173 8.07089 15.2173 7.67759C15.2173 7.28418 14.8983 6.96552 14.5052 6.96552H12.5618V6.71207C12.5618 6.31881 12.243 6 11.8498 6ZM9.42415 9.85003C9.42415 9.04349 10.078 8.38966 10.8845 8.38966H11.1377V11.3104H10.8882L10.8845 11.3104C10.078 11.3104 9.42415 10.6566 9.42415 9.85003ZM12.5618 15.6553V12.7346H12.8157C13.6223 12.7346 14.2761 13.3884 14.2761 14.1949C14.2761 15.0015 13.6223 15.6553 12.8157 15.6553H12.5618Z"
							fill="#7D7D4D"
						/>
					</svg>
				</div>
				<div className={`options-header__price ${lastPrice < prevLastPrice ? 'red' : 'green'}`}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M12.0039 5.30078C12.4181 5.30078 12.7539 5.63657 12.7539 6.05078V18.0005C12.7539 18.4147 12.4181 18.7505 12.0039 18.7505C11.5897 18.7505 11.2539 18.4147 11.2539 18.0005V6.05078C11.2539 5.63657 11.5897 5.30078 12.0039 5.30078Z"
							fill={lastPrice < prevLastPrice ? '#EB5757' : '#219653'}
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M11.4697 5.46967C11.7626 5.17678 12.2374 5.17678 12.5303 5.46967L18.5303 11.4697C18.8232 11.7626 18.8232 12.2374 18.5303 12.5303C18.2374 12.8232 17.7626 12.8232 17.4697 12.5303L12 7.06066L6.53033 12.5303C6.23744 12.8232 5.76256 12.8232 5.46967 12.5303C5.17678 12.2374 5.17678 11.7626 5.46967 11.4697L11.4697 5.46967Z"
							fill={lastPrice < prevLastPrice ? '#EB5757' : '#219653'}
						/>
					</svg>
					<p>{lastPrice?.toFixed(2)}</p>
				</div>
			</div>
			<div className="options-header__right">
				<div className="options-header-info">
					<div className="options-header-info__item">
						<div className="options-header-info__balance">
							<span>{String(L.translate('Trade.Options.Header.margin_balance'))}</span>
							<p>{data?.balance_usdt.toFixed(2) || '0.00'}</p>
						</div>
						<div className="options-header-info__percentage">
							<span>{String(L.translate('Trade.Options.Header.im'))}</span>
							<p>{data?.account_im.account_im_percent.toFixed(2) || '0.00'}%</p>
							<div className="options-header-info__line">
								<div
									className="options-header-info__line-progres"
									style={{
										width: `${String(imWidth)}%`,
									}}
								/>
							</div>
						</div>
					</div>
					<div className="options-header-info__item">
						<div className="options-header-info__balance">
							<span>{String(L.translate('Trade.Options.Header.available_balance'))}</span>
							<p>{data?.available_balance_usdt.toFixed(2) || '0.00'}</p>
						</div>
						<div className="options-header-info__percentage">
							<span>{String(L.translate('Trade.Options.Header.mm'))}</span>
							<p>{data?.account_mm.account_mm_percent.toFixed(2) || '0.00'}%</p>
							<div className="options-header-info__line">
								<div
									className="options-header-info__line-progres"
									style={{
										width: `${String(mmWidth)}%`,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="options-header-type">
					<p>{String(L.translate('Trade.Options.Header.rm'))}</p>
				</div>
			</div>
		</div>
	);
};

export default OptionsHeader;
