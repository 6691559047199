/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { IAnalyticsResponse, IAnalyticsStore } from './types';

// ==========================================:
export const initialState: IAnalyticsStore = {
	analyticsPair: 'btc',
	analyticsData: null,
	analyticsLoading: false,
};
// ==========================================:
const analytics = createSlice({
	name: '@@analytics',
	initialState,
	reducers: {
		analyticsPairChange: (state, { payload }: PayloadAction<string>) => {
			console.log(payload);
			const stateAnalytics = state;
			stateAnalytics.analyticsPair = payload;
		},
		analyticsRequest: (state, { payload }: PayloadAction<string | undefined>) => {
			const stateAnalytics = state;
			stateAnalytics.analyticsLoading = true;
		},
		analyticsSuccess: (state, action: PayloadAction<IAnalyticsResponse>) => {
			const { payload } = action;
			const stateAnalytics = state;
			console.log(payload);
			stateAnalytics.analyticsData = payload;
			stateAnalytics.analyticsLoading = false;
		},
		analyticsInitState: () => initialState,
	},
});

export default analytics.reducer;
export const { analyticsPairChange, analyticsRequest, analyticsSuccess, analyticsInitState } =
	analytics.actions;
