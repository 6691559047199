import { FC } from 'react';
import L from 'i18n-react';
import { WalletLayout } from 'layouts/Wallet';
import MarketTypeToggle from 'components/MarginWallets/MarketTypeToggle';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { isIsolated } from './guards';
import { IRepayPage } from './types';

const RepaymentPage: FC<IRepayPage> = ({ repay, isolated, loading }) => {
	return (
		<WalletLayout title={String(L.translate('Wallets.MarginAccount.repayment.html_title'))}>
			<div className="wallet-content">
				<div className="wallet-content-header flex flex-sb">
					<div className="wallet-content-header__title">
						<p>{String(L.translate('Wallets.MarginAccount.repayment.title'))}</p>
					</div>
					{/* <MarketTypeToggle /> */}
				</div>
				<div className="wallet-content-tables">
					<div
						className={`table table--wallet table--repayment-${isolated ? 'isolated' : 'cross'}`}
					>
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.coin'))}</p>
								</div>
								{isolated && (
									<div className="td">
										<p>{String(L.translate('Wallets.MarginAccount.columns.pair'))}</p>
									</div>
								)}
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.date'))}</p>
								</div>
								<div className="td td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.principal_amount'))}</p>
								</div>
								<div className="td td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.interest'))}</p>
								</div>
								<div className="td td--right">
									<p>{String(L.translate('Wallets.MarginAccount.columns.total'))}</p>
								</div>
							</div>
						</div>
						<div className="table-body">
							{loading ? (
								<div className="popup-loader">
									<Loader />
								</div>
							) : (
								<>
									{!repay?.data || repay?.data?.length === 0 ? (
										<TableEmptyPlug />
									) : (
										repay?.data?.map((item) => (
											<div key={item.id} className="tr">
												<div className="td">
													<p>{item.asset.code.toUpperCase()}</p>
												</div>
												{isIsolated(item) && (
													<div className="td">
														<p>{item.pair.code.toUpperCase()}</p>
													</div>
												)}
												<div className="td">
													<p className="grey">{customDateFormat(item.date)}</p>
												</div>
												<div className="td">
													<p>{item.principal_amount}</p>
												</div>
												<div className="td">
													<p>{item.interest_amount}</p>
												</div>
												<div className="td td--right">
													<p>{item.total}</p>
												</div>
											</div>
										))
									)}
								</>
							)}
						</div>
					</div>
				</div>
				{repay?.last_page && Number(repay?.total) > Number(repay?.per_page) && (
					<Pagination lastPage={repay.last_page} getCurrentHref={(s) => String(s)} />
				)}
			</div>
		</WalletLayout>
	);
};

export default RepaymentPage;
