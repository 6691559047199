import { FC } from 'react';
import L from 'i18n-react';
import { getPerpetualBaseAsset } from 'redux/reducers/perpetual/selectors';
import { useSelector } from 'react-redux';

type IProps = {
	mob?: true | false;
};

const OrderBookTable: FC<IProps> = ({ children, mob }) => {
	const currentAsset = useSelector(getPerpetualBaseAsset);
	return (
		<div className="orderbook-body-item">
			<div
				className={`orderbook-body-item__line orderbook-body-item__line--header ${
					mob ? 'mob' : ''
				}`}
			>
				<span className="grey">
					{String(L.translate('Trade.Perpetual.OrderBook.price_title'))}(USDT)
				</span>
				<span className="grey">
					{String(L.translate('Trade.Perpetual.OrderBook.amount_title'))}(
					{currentAsset?.base_asset.toUpperCase()})
				</span>
				<span className="grey text-align-right">
					{String(L.translate('Trade.Perpetual.OrderBook.total_title'))}
				</span>
			</div>
			{children}
		</div>
	);
};

OrderBookTable.defaultProps = {
	mob: false,
};

export default OrderBookTable;
