import React from 'react';

const AccountSettingsPayments = () => {
	return (
		<div className="setting-content">
			<div className="setting-content-header flex flex-sb">
				<div className="setting-content-header__title">
					<p>Payments</p>
				</div>
			</div>
		</div>
	);
};

export default AccountSettingsPayments;
