import SocketWithTopicConnectWrapper from 'components/SocketWithTopicConnectWrapper';
import { ChangeEvent, MouseEvent, FC, useState } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getWorkspaceSettings } from 'redux/reducers/tradingSettings/selectors';
import AssetBar from './AssetBar';
import MarginBar from './MarginBar';
import PairsTable from './PairsTable';

const SpotPairs: FC = () => {
	const workspaceSettings = useSelector(getWorkspaceSettings);
	const [search, setSearch] = useState('');
	const [currentAssetTab, setCurrentAssetTab] = useState('all');
	const [selectDropdown, setSelectDropdown] = useState(false);
	const [marginPairsTab, setMarginPairsTab] = useState('all');

	const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		setSearch(value);
	};
	const [star, changeStar] = useState(false);
	const changeStarFunc = () => {
		changeStar(!star);
	};
	const handleFilteredAssetPairs = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;

		setCurrentAssetTab(name);
		setSelectDropdown(false);
	};

	const changeMarginPairsTabs = (e: MouseEvent<HTMLButtonElement>) => {
		setMarginPairsTab(e.currentTarget.name);
		setSearch('');
	};

	return (
		<SocketWithTopicConnectWrapper topicName="assets_pairs:spot">
			<div className="market">
				<div className="market-search">
					<div className="input input--no-mb">
						<div className="input-wrapper">
							<input
								className="input-item input-item--h40"
								type="text"
								placeholder={String(
									L.translate('Trade.Spot.SpotPairs.search_pair_placeholder_text'),
								)}
								value={search}
								onChange={handleSearch}
							/>
							<button type="button" className="input-icon input-icon--right">
								<svg
									width="15"
									height="15"
									viewBox="0 0 15 15"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M0.78125 6.5625C0.78125 3.36962 3.36962 0.78125 6.5625 0.78125C9.75538 0.78125 12.3438 3.36962 12.3438 6.5625C12.3438 9.75538 9.75538 12.3438 6.5625 12.3438C3.36962 12.3438 0.78125 9.75538 0.78125 6.5625ZM6.5625 1.71875C3.88738 1.71875 1.71875 3.88738 1.71875 6.5625C1.71875 9.23762 3.88738 11.4062 6.5625 11.4062C9.23762 11.4062 11.4062 9.23762 11.4062 6.5625C11.4062 3.88738 9.23762 1.71875 6.5625 1.71875Z"
										fill="#9D9DBC"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M4.46348 4.15075C5.00022 3.61404 5.74305 3.28125 6.5627 3.28125C7.38236 3.28125 8.12519 3.61404 8.66191 4.15076C8.84497 4.33382 8.84497 4.63062 8.66191 4.81367C8.47885 4.99673 8.18206 4.99673 7.999 4.81367C7.63091 4.44559 7.12374 4.21875 6.5627 4.21875C6.00167 4.21875 5.49449 4.44558 5.12637 4.81369C4.9433 4.99674 4.64651 4.99673 4.46345 4.81366C4.2804 4.6306 4.28041 4.3338 4.46348 4.15075Z"
										fill="#9D9DBC"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M10.0504 10.0504C10.2334 9.86732 10.5302 9.86732 10.7133 10.0504L13.3649 12.702C13.548 12.8851 13.548 13.1819 13.3649 13.3649C13.1819 13.548 12.8851 13.548 12.702 13.3649L10.0504 10.7133C9.86732 10.5302 9.86732 10.2334 10.0504 10.0504Z"
										fill="#9D9DBC"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>
				<AssetBar
					star={star}
					changeStar={changeStarFunc}
					currentAssetTab={currentAssetTab}
					handleFilteredAssetPairs={handleFilteredAssetPairs}
					selectDropdown={selectDropdown}
					setSelectDropdown={setSelectDropdown}
				/>
				{currentAssetTab === 'margin' && (
					<MarginBar
						marginPairsTab={marginPairsTab}
						changeMarginPairsTabs={changeMarginPairsTabs}
					/>
				)}

				<div className="market-list">
					<PairsTable
						star={star}
						workspaceSettings={workspaceSettings}
						searchData={search}
						currentAssetData={currentAssetTab}
						marginPairsTab={marginPairsTab}
					/>
				</div>
			</div>
		</SocketWithTopicConnectWrapper>
	);
};

export default SpotPairs;
