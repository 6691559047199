import { FC } from 'react';
// import { Redirect } from 'react-router-dom';
// import { useLocation } from 'react-router';
// import { MarketTypes } from 'components/MarginWallets/MarketTypeToggle';
// import LiquidationCross from './cross';
import LiquidationIsolated from './isolated';

const MARKET_TYPE = 'marketType';

const Liquidation: FC = () => {
	// const location = useLocation();

	// const marketType = new URLSearchParams(location.search).get(MARKET_TYPE);

	// if (!marketType || marketType === MarketTypes.Cross) {
	// 	return <LiquidationCross />;
	// }

	// if (marketType === MarketTypes.Isolated) {
	// 	return <LiquidationIsolated />;
	// }

	// return <Redirect to="/wallet/margin-account" />;
	return <LiquidationIsolated />;
};

export default Liquidation;
