import React, { FC, useState } from 'react';

const TableCell: FC = ({ children }) => {
	const [active, setActive] = useState(false);
	const toggleActiveHandler = () => {
		setActive(!active);
	};
	return (
		<div
			className={`td td--heightMax td--center ${active ? 'active' : ''}`}
			onClick={toggleActiveHandler}
		>
			{children}
		</div>
	);
};

export default TableCell;
