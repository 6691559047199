/* eslint-disable @typescript-eslint/no-misused-promises */
import { FC } from 'react';
import L from 'i18n-react';
import { numberExists } from 'services/utils/numberValidation';
import StatusChip from '../StatusChip';
import { ICryptoTableRow } from './types';
import { getFormattedTxID, getFormattedDate } from './utils';

const CryptoTableRow: FC<ICryptoTableRow> = ({
	item: {
		created_at: createdAt,
		type,
		asset,
		amount,
		transaction_hash: txId,
		status: { type_name: status },
	},
}) => {
	const handleCopy = async () => {
		if (txId) {
			try {
				await navigator.clipboard.writeText(txId);
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error('Async: Could not copy text: ', err);
			}
		}
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="grey">{getFormattedDate(createdAt * 1000)}</p>
			</div>
			<div className="td">
				<p>{type}</p>
			</div>
			<div className="td">
				<p>{L.translate('TransactionHistory.table.wallet_spot')}</p>
			</div>
			<div className="td">
				<p>{asset?.name}</p>
			</div>
			<div className="td">
				<p>{numberExists(+amount) ? Number(amount)?.toFixed(6) : '-'}</p>
			</div>
			<div className="td">
				<StatusChip status={status} />
			</div>
			<div className="td td--right">
				<button
					onClick={handleCopy}
					type="button"
					className="button button--type-text button--type-text-grey fw-400"
				>
					<span className="text-overflow">{txId ? getFormattedTxID(txId) : '-'}</span>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M7.90625 3.25C7.54381 3.25 7.25 3.54381 7.25 3.90625V6.2158C7.25 6.63001 6.91421 6.9658 6.5 6.9658C6.08579 6.9658 5.75 6.63001 5.75 6.2158V3.90625C5.75 2.71539 6.71539 1.75 7.90625 1.75H20.0938C21.2846 1.75 22.25 2.71539 22.25 3.90625V16.0938C22.25 17.2846 21.2846 18.25 20.0938 18.25H17.7582C17.3439 18.25 17.0082 17.9142 17.0082 17.5C17.0082 17.0858 17.3439 16.75 17.7582 16.75H20.0938C20.4562 16.75 20.75 16.4562 20.75 16.0938V3.90625C20.75 3.54381 20.4562 3.25 20.0938 3.25H7.90625Z"
							fill="#7C7CA6"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M1.75 7.90625C1.75 6.71539 2.71539 5.75 3.90625 5.75H16.0938C17.2846 5.75 18.25 6.71539 18.25 7.90625V20.0938C18.25 21.2846 17.2846 22.25 16.0938 22.25H3.90625C2.71539 22.25 1.75 21.2846 1.75 20.0938V7.90625ZM3.90625 7.25C3.54381 7.25 3.25 7.54381 3.25 7.90625V20.0938C3.25 20.4562 3.54381 20.75 3.90625 20.75H16.0938C16.4562 20.75 16.75 20.4562 16.75 20.0938V7.90625C16.75 7.54381 16.4562 7.25 16.0938 7.25H3.90625Z"
							fill="#7C7CA6"
						/>
					</svg>
				</button>
			</div>
		</div>
	);
};

export default CryptoTableRow;
