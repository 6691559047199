import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRepayHistoryRequest } from 'redux/reducers/marginWallets/reducer';
import {
	getMarginWalletsIsLoader,
	getRepaymentIsolatedHistory,
} from 'redux/reducers/marginWallets/selectors';
import usePagination from 'hooks/usePagination';
import RepaymentPage from 'components/MarginWallets/RepaymentPage';

const RepaymentIsolated: FC = () => {
	const dispatch = useDispatch();
	const { perPage, currentPage } = usePagination();
	const loading = useSelector(getMarginWalletsIsLoader);
	const isolatedRepayment = useSelector(getRepaymentIsolatedHistory);

	useEffect(() => {
		dispatch(
			getRepayHistoryRequest({
				isolated: true,
				params: {
					margin_type: 'isolated',
					per_page: perPage,
					current_page: currentPage,
				},
			}),
		);
	}, [currentPage, dispatch, perPage]);

	return <RepaymentPage isolated repay={isolatedRepayment} loading={loading} />;
};

export default RepaymentIsolated;
