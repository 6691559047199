import { FC, useEffect, useState, useMemo } from 'react';
import L from 'i18n-react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getUserTradesCurrentPairRequest,
	getUserTradesRequest,
} from 'redux/reducers/spotTrade/reducer';
import { useLocation, useHistory } from 'react-router-dom';
import { getSpotUserTrades, getSpotUserTradesIsLoad } from 'redux/reducers/spotTrade/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { deleteSearchParam } from 'services/utils/url';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';
import { IUserTradesRequestParamsData } from 'redux/reducers/spotTrade/types';
import FilteredBar from '../FilteredBar';
import TradesHistoryItem from '../TradesHistoryItem';
import { ITradeHistoryProps } from './types';
import EmptyHistoryPlug from '../EmptyHistoryPlug';

const TradesHitory: FC<ITradeHistoryProps> = ({ otherPairsIsHide }) => {
	const tradesHistory = useSelector(getSpotUserTrades);
	const tradesHistoryIsLoad = useSelector(getSpotUserTradesIsLoad);

	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);

	const currentPair = useSelector(getCurrentPair);
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const { perPage, currentPage } = usePagination();
	const marginTypeSearch = new URLSearchParams(location.search).get('type') || null;

	const [currentPeriod, setCurrentPeriod] = useState<null | number>(null);
	const [selectedDate, setSelectedDate] = useState<[Date | null, Date | null]>([null, null]);

	const removeCurrentPage = () => {
		const search = deleteSearchParam('currentPage');
		history.replace({
			pathname: location.pathname,
			search,
		});
	};

	useEffect(() => {
		if (authIsAuthenticated) {
			const [startDate, endDate] = selectedDate;
			if (startDate || endDate) {
				if (startDate && !endDate) {
					if (marginTypeSearch === 'cross' || marginTypeSearch === 'isolated') {
						dispatch(
							getUserTradesRequest({
								params: {
									per_page: perPage,
									current_page: currentPage,
									start_date: format(startDate, 'yyyy-MM-dd'),
									[`${marginTypeSearch === 'cross' ? 'margin' : 'isolated'}`]: `${
										marginTypeSearch === 'cross' ? 'margin' : 'isolated'
									}`,
									transaction_type: 'spot',
									asset_pair_code: otherPairsIsHide ? currentPair.pair : undefined,
									hide_other_pairs: Number(otherPairsIsHide),
								},
							}),
						);
						setCurrentPeriod(null);
						return;
					}
					dispatch(
						getUserTradesRequest({
							params: {
								per_page: perPage,
								current_page: currentPage,
								start_date: format(startDate, 'yyyy-MM-dd'),
								transaction_type: 'spot',
								asset_pair_code: otherPairsIsHide ? currentPair.pair : undefined,
								hide_other_pairs: Number(otherPairsIsHide),
							},
						}),
					);

					setCurrentPeriod(null);
					return;
				}

				if (startDate && endDate) {
					if (marginTypeSearch === 'cross' || marginTypeSearch === 'isolated') {
						dispatch(
							getUserTradesRequest({
								params: {
									per_page: perPage,
									current_page: currentPage,
									start_date: format(startDate, 'yyyy-MM-dd'),
									end_date: format(endDate, 'yyyy-MM-dd'),
									[`${marginTypeSearch === 'cross' ? 'margin' : 'isolated'}`]: `${
										marginTypeSearch === 'cross' ? 'margin' : 'isolated'
									}`,
									transaction_type: 'spot',
									asset_pair_code: otherPairsIsHide ? currentPair.pair : undefined,
									hide_other_pairs: Number(otherPairsIsHide),
								},
							}),
						);
						setCurrentPeriod(null);
						return;
					}
					dispatch(
						getUserTradesRequest({
							params: {
								per_page: perPage,
								current_page: currentPage,
								start_date: format(startDate, 'yyyy-MM-dd'),
								end_date: format(endDate, 'yyyy-MM-dd'),
								transaction_type: 'spot',
								asset_pair_code: otherPairsIsHide ? currentPair.pair : undefined,
								hide_other_pairs: Number(otherPairsIsHide),
							},
						}),
					);
				}
				return;
			}
			if (marginTypeSearch === 'cross') {
				dispatch(
					getUserTradesRequest({
						params: {
							per_page: perPage,
							current_page: currentPage,
							transaction_type: 'cross',
							asset_pair_code: otherPairsIsHide ? currentPair.pair : undefined,
							hide_other_pairs: Number(otherPairsIsHide),
						},
					}),
				);
				return;
			}
			if (marginTypeSearch === 'isolated') {
				dispatch(
					getUserTradesRequest({
						params: {
							per_page: perPage,
							current_page: currentPage,
							transaction_type: 'isolated',
							asset_pair_code: otherPairsIsHide ? currentPair.pair : undefined,
							hide_other_pairs: Number(otherPairsIsHide),
						},
					}),
				);
				return;
			}
			const param: IUserTradesRequestParamsData = {
				per_page: perPage,
				current_page: currentPage,
				transaction_type: 'spot',
				hide_other_pairs: 0,
			};
			if (otherPairsIsHide) {
				param.hide_other_pairs = 1;
				param.asset_pair_code = currentPair.pair;
			}
			dispatch(
				getUserTradesRequest({
					params: { ...param },
				}),
			);
		}
	}, [
		authIsAuthenticated,
		currentPage,
		currentPair.pair,
		dispatch,
		history,
		location.pathname,
		marginTypeSearch,
		otherPairsIsHide,
		perPage,
		selectedDate,
	]);

	const hadleSearchDataPerDate = (date: [Date | null, Date | null]) => {
		removeCurrentPage();
		setSelectedDate(date);
	};

	return (
		<>
			{/* <FilteredBar
				handleSearch={hadleSearchDataPerDate}
				period={currentPeriod}
				setCurrentPeriod={setCurrentPeriod}
				currentClass="table-date-filter"
			/> */}

			<div className="table table--trade table--trade-order-history table--trade-order-history--trade_history">
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.TradesHitoryTable.date_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.TradesHitoryTable.pair_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.TradesHitoryTable.side_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.TradesHitoryTable.price_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate(
										'Trade.Spot.SpotHistoryTables.TradesHitoryTable.executed_column_name',
									),
								)}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.TradesHitoryTable.fee_column_name'),
								)}
							</span>
						</div>
						<div className="td td--right">
							<span className="table-header__name">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.TradesHitoryTable.total_column_name'),
								)}
							</span>
						</div>
					</div>
				</div>
				<div className="table-body">
					{tradesHistoryIsLoad && (
						<div className="open-orders-history-loader-wrapper">
							<Loader />
						</div>
					)}
					{!tradesHistoryIsLoad && tradesHistory?.data?.length
						? tradesHistory?.data?.map((order: any) => (
								<TradesHistoryItem key={order.id} data={order} />
						  ))
						: null}

					{!tradesHistoryIsLoad && tradesHistory?.data?.length === 0 && (
						<EmptyHistoryPlug
							text={String(
								L.translate(
									'Trade.Spot.SpotHistoryTables.TradesHitoryTable.no_trades_history_text',
								),
							)}
						/>
					)}
					{tradesHistory?.last_page &&
						Number(tradesHistory?.total) > Number(tradesHistory?.per_page) && (
							<Pagination lastPage={tradesHistory.last_page} getCurrentHref={(s) => String(s)} />
						)}
				</div>
			</div>
		</>
	);
};

export default TradesHitory;
