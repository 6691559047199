/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, ChangeEvent, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { DebounceInput } from 'react-debounce-input';
import { Link } from 'react-router-dom';
import { navList } from 'routes/routesList';
import { getMarginIsolatedWalletsRequest } from 'redux/reducers/marginWallets/reducer';
import { getIsolatedWalletsList } from 'redux/reducers/marginWallets/selectors';
import { WalletLayout } from 'layouts/Wallet';
import WalletsContentHeader from 'components/Wallet/ContentHeader';
import TransferModal from 'components/Wallet/modals/TransferEnhanced';
import MarketTypeToggle from 'components/MarginWallets/MarketTypeToggle';
import TableRow from 'components/MarginWallets/Isolated/TableRow';

const WalletMarginIsolated: FC = () => {
	const dispatch = useDispatch();
	const marginIsolatedWallets = useSelector(getIsolatedWalletsList);
	const [transferOpened, setTransferOpened] = useState(false);
	const [search, setSearch] = useState<string>('');

	const openTransfer = () => {
		setTransferOpened(true);
	};

	const closeTransfer = () => {
		setTransferOpened(false);
	};

	const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSearch(value);
	};

	const fetchIsolated = useCallback(() => {
		dispatch(getMarginIsolatedWalletsRequest({ search_coin: search }));
	}, [dispatch, search]);

	useEffect(() => {
		fetchIsolated();
	}, [fetchIsolated]);

	return (
		<WalletLayout title={String(L.translate('Wallets.MarginAccount.title'))}>
			<div className="wallet-content">
				<div className="wallet-content-header flex flex-sb">
					<div className="wallet-content-header__title">
						<p>{String(L.translate('Wallets.MarginAccount.title'))}</p>
					</div>
					<div className="wallet-content-header__buttons flex flex-e flex-m">
						<a href={navList.depositCrypto.path} className="button button--type8">
							{String(L.translate('Wallets.actions.deposit'))}
						</a>
						<a href={navList.withdrawCrypto.path} className="button button--type8">
							{String(L.translate('Wallets.actions.withdraw'))}
						</a>
						<a onClick={openTransfer} className="button button--type8">
							{String(L.translate('Wallets.actions.transfer'))}
						</a>
					</div>
				</div>
				<div className="wallet-content-balance flex">
					<div className="wallet-content-balance-item">
						<div className="wallet-content-balance-item__title">
							<p>{String(L.translate('Wallets.MarginAccount.isolated.isolated_assets'))}</p>
						</div>
						<div className="wallet-content-balance-item__value">
							<p>
								{Number(marginIsolatedWallets?.total_assets.btc).toFixed(6)} <span>BTC</span>
							</p>
						</div>
						<div className="wallet-content-balance-item__user-currensy">
							<p>≈ ${Number(marginIsolatedWallets?.total_assets.usd).toFixed(6)}</p>
						</div>
					</div>
				</div>
				{/* <MarketTypeToggle /> */}
				<div className="wallet-content-tables">
					<div className="wallet-content-tables-header-search wallet-content-tables-header-search--input-full">
						<div className="input input--no-mb">
							<div className="input-wrapper">
								<DebounceInput
									debounceTimeout={200}
									type="text"
									placeholder={String(L.translate('Wallets.MarginAccount.search_placeholder'))}
									className="input-item input-item--h40"
									name="search"
									value={search}
									onChange={handleSearchInput}
									autoComplete="off"
								/>
								<div className="input-icon input-icon--right">
									<svg
										width="15"
										height="15"
										viewBox="0 0 15 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M0.78125 6.5625C0.78125 3.36962 3.36962 0.78125 6.5625 0.78125C9.75538 0.78125 12.3438 3.36962 12.3438 6.5625C12.3438 9.75538 9.75538 12.3438 6.5625 12.3438C3.36962 12.3438 0.78125 9.75538 0.78125 6.5625ZM6.5625 1.71875C3.88738 1.71875 1.71875 3.88738 1.71875 6.5625C1.71875 9.23762 3.88738 11.4062 6.5625 11.4062C9.23762 11.4062 11.4062 9.23762 11.4062 6.5625C11.4062 3.88738 9.23762 1.71875 6.5625 1.71875Z"
											fill="#9D9DBC"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M4.46348 4.15075C5.00022 3.61404 5.74305 3.28125 6.5627 3.28125C7.38236 3.28125 8.12519 3.61404 8.66191 4.15076C8.84497 4.33382 8.84497 4.63062 8.66191 4.81367C8.47885 4.99673 8.18206 4.99673 7.999 4.81367C7.63091 4.44559 7.12374 4.21875 6.5627 4.21875C6.00167 4.21875 5.49449 4.44558 5.12637 4.81369C4.9433 4.99674 4.64651 4.99673 4.46345 4.81366C4.2804 4.6306 4.28041 4.3338 4.46348 4.15075Z"
											fill="#9D9DBC"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M10.0504 10.0504C10.2334 9.86732 10.5302 9.86732 10.7133 10.0504L13.3649 12.702C13.548 12.8851 13.548 13.1819 13.3649 13.3649C13.1819 13.548 12.8851 13.548 12.702 13.3649L10.0504 10.7133C9.86732 10.5302 9.86732 10.2334 10.0504 10.0504Z"
											fill="#9D9DBC"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>
					<div className="table table--wallet table--margin-account-new-insolated">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.pair'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.risk_rate'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.effective_multiple'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.coin'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.total_balance'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.available_balance'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.borrowed'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.interest'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.equity_value_btc'))}</p>
								</div>
								<div className="td td--right">
									<p>{String(L.translate('Wallets.MarginAccount.columns.action'))}</p>
								</div>
							</div>
						</div>
						<div className="table-body">
							{marginIsolatedWallets?.isolated?.map((item) => (
								<TableRow key={item.id} item={item} refetch={fetchIsolated} />
							))}
						</div>
					</div>
				</div>
			</div>
			{transferOpened && <TransferModal onClose={closeTransfer} refetch={fetchIsolated} />}
		</WalletLayout>
	);
};

export default WalletMarginIsolated;
