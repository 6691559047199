import { FC } from 'react';
import L from 'i18n-react';

const ConvertTo: FC = () => {
	return (
		<div className="convert-form-item">
			<div className="convert-form-item__header">
				<p>{String(L.translate('Wallets.MarginAccount.modals.to'))}</p>
			</div>
			<div className="input">
				<div className="input-wrapper">
					<div className="transfer-destination input-item input-item--left-icon">
						<span>{String(L.translate('Wallets.MarginAccount.modals.margin_wallet'))}</span>
					</div>
					<div className="input-icon input-icon--auto">
						<svg
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M4.25 4C4.25 3.58579 4.58579 3.25 5 3.25H19C19.4142 3.25 19.75 3.58579 19.75 4V8C19.75 8.41421 19.4142 8.75 19 8.75C18.5858 8.75 18.25 8.41421 18.25 8V4.75H5.75V8C5.75 8.41421 5.41421 8.75 5 8.75C4.58579 8.75 4.25 8.41421 4.25 8V4Z"
								fill="#7D7D4D"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M1.75 8C1.75 7.58579 2.08579 7.25 2.5 7.25H21.5C21.9142 7.25 22.25 7.58579 22.25 8V14C22.25 14.4142 21.9142 14.75 21.5 14.75C21.0858 14.75 20.75 14.4142 20.75 14V8.75H3.25V20.25H21.5C21.9142 20.25 22.25 20.5858 22.25 21C22.25 21.4142 21.9142 21.75 21.5 21.75H2.5C2.08579 21.75 1.75 21.4142 1.75 21V8Z"
								fill="#7D7D4D"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M14.9359 13.3898C15.273 13.6305 15.3511 14.0989 15.1103 14.436L13.4574 16.7501H21.5C21.9142 16.7501 22.25 17.0858 22.25 17.5001C22.25 17.9143 21.9142 18.2501 21.5 18.2501H12C11.7191 18.2501 11.4617 18.093 11.3331 17.8432C11.2046 17.5934 11.2264 17.2927 11.3897 17.0641L13.8897 13.5641C14.1305 13.2271 14.5989 13.149 14.9359 13.3898Z"
								fill="#7D7D4D"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M10.25 11C10.25 10.5858 10.5858 10.25 11 10.25H13C13.4142 10.25 13.75 10.5858 13.75 11C13.75 11.4142 13.4142 11.75 13 11.75H11C10.5858 11.75 10.25 11.4142 10.25 11Z"
								fill="#7D7D4D"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M5.25 11C5.25 10.5858 5.58579 10.25 6 10.25H8C8.41421 10.25 8.75 10.5858 8.75 11C8.75 11.4142 8.41421 11.75 8 11.75H6C5.58579 11.75 5.25 11.4142 5.25 11Z"
								fill="#7D7D4D"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConvertTo;
