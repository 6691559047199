import { FC, useState, MouseEvent, useEffect } from 'react';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { getWorkspaceSettings, getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import BuySellWrapper from './BuySellWrapper';
import BaloonTip from './BaloonTip';
// import MarginTransferModal from '../MarginModal/CrossModal/MarginTransferModal';
// import IsolatedTransferModal from '../MarginModal/IsolatedModal/IsolatedTransferModal';
// import CrossMargin from '../MarginModal/CrossModal';
// import IsolatedModal from '../MarginModal/IsolatedModal';
// import RiskMargin from './RiskMargin';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

const TradesBox: FC = () => {
	const workspaceSettings = useSelector(getWorkspaceSettings);
	const currentPair = useSelector(getCurrentPair);
	const [orderType, setOrderType] = useState('limit_order');
	const [currentOrderTypeTab, setCurrentOrderTypeTab] = useState('buy');
	const [marketType, setMarketType] = useState('spot');
	const [openModal, setOpenModal] = useState(false);
	const [openBorrowModal, setOpenBorrowModal] = useState(false);
	const [showTip, setShowTip] = useState(false);
	const { orderBookSection, tradingPairsSection } = workspaceSettings;

	const closeModal = (): void => {
		setOpenModal(false);
		setOpenBorrowModal(false);
	};

	const location = useLocation();
	/* eslint-disable no-debugger */
	const marginTypeSearch = new URLSearchParams(location.search).get('type');

	useEffect(() => {
		if (marginTypeSearch) {
			setMarketType(marginTypeSearch);
		}
	}, [marginTypeSearch]);

	const handleChangeTrade = (name: any): void => {
		if (marketType === name) {
			return;
		}
		setMarketType(name);
	};

	const handleChangeOrderType = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;

		setOrderType(name);
	};

	const handleChangeCurrentOrderTypeTab = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;

		setCurrentOrderTypeTab(name);
	};

	const handleTipFocus = () => {
		setShowTip(true);
	};

	const handleTipBlur = () => {
		setShowTip(false);
	};

	useEffect(() => {
		if (location.pathname.includes('MarginTrading')) {
			setMarketType('isolated');
		} else {
			setMarketType('spot');
		}
	}, [location.pathname, location?.search]);

	const buyCode = currentPair?.pair?.split('_')[1];
	const sellCode = currentPair?.pair?.split('_')[0];

	return (
		<SocketWithPrivateTopicConnectWrapper
			topicName={`balance:${marketType}${
				marketType === 'isolated' ? `:${currentPair.pair}` : ''
			}:${buyCode}`}
		>
			<SocketWithPrivateTopicConnectWrapper
				topicName={`balance:${marketType}${
					marketType === 'isolated' ? `:${currentPair.pair}` : ''
				}:${sellCode}`}
			>
				<div className="orderform">
					{marketType === 'spot' ? (
						<div className="orderform-nav">
							<div className="orderform-nav__item">
								<NavLink
									to={`/spot/${currentPair?.pair?.toUpperCase()}`}
									onClick={() => handleChangeTrade('spot')}
									className={`orderform-nav__button ${marketType === 'spot' ? 'active' : ''}`}
								>
									{L.translate('Trade.Spot.SpotTradeBox.spot_trading')}
									<span className="orderform-nav__button-line" />
								</NavLink>
							</div>
							<div className="orderform-nav__item">
								<NavLink
									to={{
										pathname: `/MarginTrading/${currentPair?.pair?.toUpperCase()}`,
										search: '?type=isolated',
									}}
									onClick={() => handleChangeTrade('isolated')}
									className="orderform-nav__button"
								>
									{L.translate('Trade.Spot.SpotTradeBox.margin_trading')}
									<span className="orderform-nav__button-line" />
								</NavLink>
							</div>
						</div>
					) : (
						<div className="orderform-nav">
							<div className="orderform-nav__item">
								<NavLink
									to={`/spot/${currentPair?.pair?.toUpperCase()}`}
									onClick={() => handleChangeTrade('spot')}
									className={`orderform-nav__button ${marketType === 'spot' ? 'active' : ''}`}
								>
									{L.translate('Trade.Spot.SpotTradeBox.spot_trading')}
									<span className="orderform-nav__button-line" />
								</NavLink>
							</div>
							{/* <div className="orderform-nav__item">
						<NavLink
							to={`${currentPair?.pair?.toUpperCase()}?type=cross`}
							onClick={handleChangeTrade}
							className={`orderform-nav__button ${
								marketType !== 'cross' ? 'orderform-nav__button--color' : ''
							}`}
						>
							{L.translate('Trade.Margin.TradeBox.cross_trading')} 3x
							<span className={`${marketType === 'cross' ? 'orderform-nav__button-line' : ''}`} />
						</NavLink>
					</div> */}
							<div className="orderform-nav__item">
								<NavLink
									to={`/MarginTrading/${currentPair?.pair?.toUpperCase()}?type=isolated`}
									onClick={() => handleChangeTrade('isolated')}
									className={`orderform-nav__button ${
										marketType !== 'isolated' ? 'orderform-nav__button--color' : ''
									}`}
								>
									{L.translate('Trade.Margin.TradeBox.isolated_trading')} 5x
									<span
										className={`${marketType === 'isolated' ? 'orderform-nav__button-line' : ''}`}
									/>
								</NavLink>
							</div>
						</div>
					)}

					<div className="orderform-tabs">
						<div className="table-nav flex flex--gap4">
							<button
								className={`button button--width-auto button--size3 ${
									orderType === 'limit_order' ? '' : 'button--type11'
								}`}
								type="button"
								name="limit_order"
								onClick={handleChangeOrderType}
							>
								{L.translate('Trade.Spot.SpotTradeBox.limit_order_name')}
							</button>
							<button
								className={`button button--width-auto button--size3  ${
									orderType === 'market_order' ? '' : 'button--type11'
								}`}
								type="button"
								name="market_order"
								onClick={handleChangeOrderType}
							>
								{L.translate('Trade.Spot.SpotTradeBox.market_order_name')}
							</button>
							<button
								className={`button button--width-auto button--size3 flex--gap8  ${
									orderType === 'stop_limit_order' ? '' : 'button--type11'
								}`}
								type="button"
								name="stop_limit_order"
								onClick={handleChangeOrderType}
							>
								{L.translate('Trade.Spot.SpotTradeBox.stop_limit_order_name')}
								<svg
									width="15"
									height="16"
									viewBox="0 0 15 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									onMouseOver={handleTipFocus}
									onMouseOut={handleTipBlur}
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M2.74913 3.24912C3.96448 2.03379 5.64481 1.28125 7.5 1.28125C9.35519 1.28125 11.0355 2.03379 12.2509 3.24912L11.9194 3.58058L12.2509 3.24913C13.4662 4.46448 14.2188 6.14481 14.2188 8C14.2188 9.85519 13.4662 11.5355 12.2509 12.7509C11.0355 13.9662 9.35519 14.7188 7.5 14.7188C5.64481 14.7188 3.96448 13.9662 2.74913 12.7509L3.08058 12.4194L2.74912 12.7509C1.53379 11.5355 0.78125 9.85519 0.78125 8C0.78125 6.14481 1.53379 4.46448 2.74912 3.24913L2.74913 3.24912ZM7.5 2.21875C5.90344 2.21875 4.45877 2.86533 3.41203 3.91204C2.36532 4.95877 1.71875 6.40344 1.71875 8C1.71875 9.59656 2.36533 11.0412 3.41204 12.088C4.45877 13.1347 5.90344 13.7812 7.5 13.7812C9.09656 13.7812 10.5412 13.1347 11.5879 12.0879C12.6347 11.0412 13.2812 9.59656 13.2812 8C13.2812 6.40344 12.6347 4.95877 11.588 3.91204C10.5412 2.86533 9.09656 2.21875 7.5 2.21875Z"
										fill="#1C1C29"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M5.15625 6.32031C5.15625 5.0259 6.20559 3.97656 7.5 3.97656C8.79441 3.97656 9.84375 5.0259 9.84375 6.32031C9.84375 7.4542 9.03855 8.40002 7.96875 8.61718V9.44531C7.96875 9.7042 7.75888 9.91406 7.5 9.91406C7.24112 9.91406 7.03125 9.7042 7.03125 9.44531V8.19531C7.03125 7.93643 7.24112 7.72656 7.5 7.72656C8.27665 7.72656 8.90625 7.09696 8.90625 6.32031C8.90625 5.54367 8.27665 4.91406 7.5 4.91406C6.72335 4.91406 6.09375 5.54367 6.09375 6.32031C6.09375 6.5792 5.88388 6.78906 5.625 6.78906C5.36612 6.78906 5.15625 6.5792 5.15625 6.32031Z"
										fill="#1C1C29"
									/>
									<path
										d="M8.03125 11.4766C8.03125 11.77 7.7934 12.0078 7.5 12.0078C7.2066 12.0078 6.96875 11.77 6.96875 11.4766C6.96875 11.1832 7.2066 10.9453 7.5 10.9453C7.7934 10.9453 8.03125 11.1832 8.03125 11.4766Z"
										fill="#1C1C29"
										stroke="#1C1C29"
										strokeWidth="0.5"
									/>
								</svg>
							</button>
							<BaloonTip
								display={showTip}
								text="It is the mechanics used to tether to spot price, applied every 8 hours. Funding payment is
				based on the position value a user holds at the applying timestamp. If funding rate is
				positive, longs pay shorts; if negative, shorts pay longs."
							/>
						</div>
						{/* {marketType !== 'spot' && (
						<div className="trade-tabs">
							<RiskMargin type={marketType} pair={currentPair} />
							<button
								onClick={() => setOpenModal((prevOpenModal) => !prevOpenModal)}
								type="button"
								className="trade-tabs__item"
							>
								Transfer
							</button>
							<button
								onClick={() => setOpenBorrowModal((prevOpenModal) => !prevOpenModal)}
								type="button"
								className="trade-tabs__item"
							>
								Borrow
							</button>
							{marketType === 'cross' ? (
								<>
									<MarginTransferModal
										openModal={openModal}
										closeModal={closeModal}
										assetCode={openModal ? currentPair?.split('_')[0] : ''}
									/>
									<CrossMargin
										openModal={openBorrowModal}
										closeModal={closeModal}
										active="borrow"
										assetCode={openModal ? currentPair?.split('_')[0] : ''}
									/>
								</>
							) : (
								<>
									<IsolatedTransferModal
										openModal={openModal}
										closeModal={closeModal}
										assetCode={openModal ? currentPair?.split('_')[0] || 'btc' : ''}
										assetPair={openModal ? currentPair : ''}
									/>
									<IsolatedModal
										openModal={openBorrowModal}
										closeModal={closeModal}
										assetPair={openModal ? currentPair : ''}
										active="borrow"
										assetCode={openModal ? currentPair?.split('_')[0] || 'btc' : ''}
									/>
								</>
							)}
						</div>
					)} */}
					</div>
					<div className="orderform-content">
						<BuySellWrapper
							orderType={orderType}
							mode={currentOrderTypeTab === 'buy' ? 'Buy' : 'Sell'}
							marketType={marketType}
						/>
						{orderBookSection && tradingPairsSection && (
							<BuySellWrapper orderType={orderType} mode="Sell" marketType={marketType} />
						)}
					</div>
				</div>
			</SocketWithPrivateTopicConnectWrapper>
		</SocketWithPrivateTopicConnectWrapper>
	);
};

export default TradesBox;
