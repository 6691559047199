import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postKycValidationRequest } from 'redux/reducers/settings/reducer';
import { getKycLoading } from 'redux/reducers/settings/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import Loader from 'ui/Loader';
import BirthSelect from './birthSelect';

const initState = {
	first_name: '',
	last_name: '',
	date_birth: { day: '', month: '', year: '' },
};

const VerificateKycModal = ({ closeModal }: any) => {
	const [userData, setUserData] = useState({
		...initState,
		date_birth: { ...initState.date_birth },
	});
	const dispatch = useDispatch();
	const loader = useSelector(getKycLoading);

	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	const isNotDisabled =
		userData.first_name &&
		userData.last_name &&
		userData.date_birth.day &&
		userData.date_birth.month &&
		userData.date_birth.year;

	const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (!value || /^(([a-zA-Z-]{1,30}))$/u.test(value)) {
			setUserData({ ...userData, first_name: value });
		}
	};
	const handleLastNameChange = (e: any) => {
		const { value } = e.target;
		if (!value || /^(([a-zA-Z-]{1,30}))$/u.test(value)) {
			setUserData({ ...userData, last_name: value });
		}
	};
	const handleDateSelect = (value: string) => {
		setUserData({ ...userData, date_birth: { ...userData.date_birth, day: value } });
	};
	const handleMonthSelect = (value: string) => {
		setUserData({ ...userData, date_birth: { ...userData.date_birth, month: value } });
	};
	const handleYearSelect = (value: string) => {
		setUserData({ ...userData, date_birth: { ...userData.date_birth, year: value } });
	};
	const handleConfirmClick = () => {
		if (userData.first_name.length < 2) {
			return notificationContainer('First name most be at least 2 characters', 'error');
		}
		if (userData.last_name.length < 2) {
			return notificationContainer('Last name most be at least 2 characters', 'error');
		}
		const verificationData = {
			first_name: userData.first_name,
			last_name: userData.last_name,
			date_birth: `${userData.date_birth.year}-${
				1 + months.indexOf(userData.date_birth.month) <= 9 ? '0' : ''
			}${months.indexOf(userData.date_birth.month) + 1}-${
				Number(userData.date_birth.day) <= 9 ? '0' : ''
			}${userData.date_birth.day}`,
		};
		dispatch(postKycValidationRequest(verificationData));
		return setUserData(initState);
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				{loader ? (
					<Loader />
				) : (
					<div className="popup popup--width670">
						<button className="popup__close" type="button" onClick={closeModal}>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
									fill="#1C1C29"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
									fill="#1C1C29"
								/>
							</svg>
						</button>
						<div className="popup-header">
							<p className="popup-header__title">Identity Verification</p>
						</div>
						<div className="popup-body">
							<div className="popup-text">
								<p>Fill the fields below to start verification process</p>
							</div>
							<div className="popup-body-item">
								<div className="input">
									<div className="input-wrapper">
										<input
											onChange={handleFirstNameChange}
											value={userData.first_name}
											className="input-item"
											type="text"
											placeholder="First Name"
										/>
									</div>
								</div>
							</div>
							<div className="popup-body-item">
								<div className="input">
									<div className="input-wrapper">
										<input
											onChange={handleLastNameChange}
											value={userData.last_name}
											className="input-item"
											type="text"
											placeholder="Last Name"
										/>
									</div>
								</div>
							</div>
							<div className="popup-body-item select-item3">
								<BirthSelect
									key="1"
									title="Day of Birth"
									range={[1, 31]}
									select={handleDateSelect}
									selected={userData.date_birth.day}
								/>
								<BirthSelect
									key="2"
									title="Month Of Birth"
									range={months}
									select={handleMonthSelect}
									selected={userData.date_birth.month}
								/>
								<BirthSelect
									key="3"
									title="Year Of Birth"
									range={[1910, 2022]}
									select={handleYearSelect}
									selected={userData.date_birth.year}
								/>
							</div>
						</div>
						<div className="popup-footer">
							<div className="popup-submit popup-submit--sb">
								<button
									className="button button--size2 button--full-width"
									disabled={!isNotDisabled}
									type="button"
									onClick={handleConfirmClick}
								>
									Confirm
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default VerificateKycModal;
