import classNames from 'classnames';
import { FC } from 'react';
import { ISortingThProps } from './types';

const SortingTh: FC<ISortingThProps> = ({ title, fieldName, sortParams, onChangeSort }) => {
	const handleClick = () => {
		if (fieldName !== sortParams.field) {
			onChangeSort({ field: fieldName, isAsc: false });
			return;
		}
		if (fieldName === sortParams.field && !sortParams.isAsc) {
			onChangeSort({ field: fieldName, isAsc: true });
			return;
		}
		onChangeSort({ field: null });
	};

	return (
		<div className="td">
			<button type="button" className="td-sorting-arrow-button" onClick={handleClick}>
				{title}

				<span className="td-sorting-arrow">
					<span
						className={classNames('td-sorting-arrow__item', {
							active: fieldName === sortParams.field && sortParams.isAsc,
						})}
					>
						<svg
							width="6"
							height="3"
							viewBox="0 0 6 3"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M0 3L5.4 3L6 3L3 -2.62268e-07L0 3Z" fill="#9D9DBC" />
						</svg>
					</span>

					<span
						className={classNames('td-sorting-arrow__item', {
							active: fieldName === sortParams.field && !sortParams.isAsc,
						})}
					>
						<svg
							width="6"
							height="3"
							viewBox="0 0 6 3"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M6 0H0.6H0L3 3L6 0Z" fill="#9D9DBC" />
						</svg>
					</span>
				</span>
			</button>
		</div>
	);
};

export default SortingTh;
