import { TDefaultTransactionSpeed } from './types';

export const THEME_LIGHT = 'light';
export const THEME_DARK = 'dark';
export const DEFAULT_TRANSACTION_SPEED_STANDARD = 'Standard (5)';
export const DEFAULT_TRANSACTION_SPEED_FAST = 'Fast (6)';
export const DEFAULT_TRANSACTION_SPEED_INSTANT = 'Instant (7)';
export const defaultTransactionSpeedArray: TDefaultTransactionSpeed[] = [
	DEFAULT_TRANSACTION_SPEED_STANDARD,
	DEFAULT_TRANSACTION_SPEED_FAST,
	DEFAULT_TRANSACTION_SPEED_INSTANT,
];
