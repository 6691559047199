/* eslint-disable react/jsx-props-no-spreading */
import { ChangeEvent, FC } from 'react';
import FormControl from 'ui/Formik/FormConrol';
import GetCodeButton from 'ui/GetCodeButton/GetCodeButton';
import { IAdminInput } from './types';
// ==========================================:
const Input: FC<IAdminInput> = (props) => {
	const {
		ariaLabel,
		type,
		name,
		field,
		placeholder,
		inputMode,
		onKeyUp,
		customOnChange,
		getCodeHandler,
		codeLength,
		className,
	} = props;

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (codeLength) {
			const { value } = event.target;
			if (value.length > codeLength) {
				return;
			}
			if (/^[0-9]*$/.test(value) || !value) {
				field.onChange({ target: { value, name: field.name } });
				!!customOnChange && customOnChange(value);
				return;
			}
		}
		field.onChange({ target: { value: event.target.value, name: field.name } });
		!!customOnChange && customOnChange(event.target.value);
	};
	return (
		<FormControl ariaLabel={ariaLabel} {...props}>
			{type !== 'textarea' ? (
				<>
					<input
						className={`input-item ${name === 'password' ? 'input-item--right-icon' : ''} ${
							String(className) || ''
						}`}
						{...field}
						type={type}
						placeholder={placeholder}
						onKeyUp={onKeyUp}
						inputMode={inputMode || null}
						onChange={onChange}
					/>
					{!!getCodeHandler && <GetCodeButton onClick={getCodeHandler} />}
				</>
			) : (
				<textarea
					className={`input-item ${name === 'password' ? 'input-item--right-icon' : ''} ${
						String(className) || ''
					}`}
					{...field}
					placeholder={placeholder}
					onKeyUp={onKeyUp}
					inputMode={inputMode || null}
					onChange={onChange}
				/>
			)}
		</FormControl>
	);
};

export default Input;
