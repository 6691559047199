import { FC, useEffect } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
	getSpotUserOpenOrdersIsLoad,
	getSpotUserOpenOrders,
} from 'redux/reducers/spotTrade/selectors';
import { getOpenOrdersRequest } from 'redux/reducers/spotTrade/reducer';
import { WalletLayout } from 'layouts/Wallet';
import MarketTypeToggle, {
	MarketTypes,
	MARKET_TYPE,
} from 'components/MarginWallets/MarketTypeToggle';
import TableRow from 'components/MarginWallets/orderHistory/TableRow';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import usePagination from 'hooks/usePagination';

const OpenHistory: FC = () => {
	const dispatch = useDispatch();
	const { perPage, currentPage } = usePagination();
	const loading = useSelector(getSpotUserOpenOrdersIsLoad);
	const allOrders = useSelector(getSpotUserOpenOrders);

	const location = useLocation();

	// const marketType = new URLSearchParams(location.search).get(MARKET_TYPE);

	const marketType = 'isolated';

	useEffect(() => {
		dispatch(
			getOpenOrdersRequest({
				params: {
					order_type: marketType === MarketTypes.Isolated ? 'isolated' : 'cross',
					status: 'all',
					per_page: perPage,
					current_page: currentPage,
				},
			}),
		);
	}, [currentPage, dispatch, marketType, perPage]);

	return (
		<WalletLayout title={String(L.translate('Wallets.MarginAccount.orderHistory.html_title'))}>
			<div className="wallet-content">
				<div className="wallet-content-header flex flex-sb">
					<div className="wallet-content-header__title">
						<p>{String(L.translate('Wallets.MarginAccount.orderHistory.title'))}</p>
					</div>
					{/* <MarketTypeToggle /> */}
				</div>
				<div className="wallet-content-tables">
					<div className="table table--wallet table--margin-open-history-cross">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.date'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.pair'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.type'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.side'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.average'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.price'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.executed'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.amount'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.total'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.trigger_conditions'))}</p>
								</div>
								<div className="td td--right">
									<p>{String(L.translate('Wallets.MarginAccount.columns.status'))}</p>
								</div>
							</div>
						</div>
						<div className="table-body">
							{loading ? (
								<div className="popup-loader">
									<Loader />
								</div>
							) : (
								<>
									{allOrders?.data.length === 0 ? (
										<TableEmptyPlug />
									) : (
										allOrders?.data.map((item) => <TableRow key={item.id} item={item} />)
									)}
								</>
							)}
						</div>
					</div>
				</div>
				{allOrders?.last_page && Number(allOrders?.total) > Number(allOrders?.per_page) && (
					<Pagination lastPage={allOrders.last_page} getCurrentHref={(s) => String(s)} />
				)}
			</div>
		</WalletLayout>
	);
};

export default OpenHistory;
