import {
	ILiquidationWalletCross,
	ILiquidationWalletIsolated,
} from 'redux/reducers/marginWallets/types';

export function isIsolated(
	item: ILiquidationWalletCross | ILiquidationWalletIsolated | null,
): item is ILiquidationWalletIsolated {
	return (item as ILiquidationWalletIsolated).pair !== undefined;
}
