import L from 'i18n-react';
import { useState, useEffect, SyntheticEvent } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';
import Loader from 'ui/Loader';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import TransactionTypeSelect from 'components/TransactionHistory/TransactionTypeSelect';
import PastDaysSelect from 'components/TransactionHistory/PastDaysSelect';
import AssetSelect from 'components/TransactionHistory/AssetSelect';
import StatusSelect, { TransactionStatuses } from 'components/TransactionHistory/StatusSelect';
import TableRow from 'components/TransactionHistory/CryptoTableRow';
import { getAssetsList } from 'redux/reducers/assets/selectors';
import { getWalletsCryptoHistoryRequest } from 'redux/reducers/wallets/reducer';
import {
	getWalletsCryptoHistoryList,
	getWalletsHistoryLoader,
} from 'redux/reducers/wallets/selectors';
import { deleteSearchParam } from 'services/utils/url';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';

const TransactionHistoryCrypto = () => {
	const dispatch = useDispatch();
	const [type, setType] = useState<string | null>(null);
	const [pastDays, setPastDays] = useState<number | null>(null);
	const [assetId, setAssetId] = useState<number | null>(null);
	const [statusId, setStatusId] = useState<TransactionStatuses | null>(null);
	const [txId, setTxId] = useState<string>('');
	const history = useHistory();
	const location = useLocation();
	const { perPage, currentPage } = usePagination();
	const loader = useSelector(getWalletsHistoryLoader);

	const assetsList = useSelector(getAssetsList)?.filter((assetItem) => assetItem?.is_crypto);
	const cryptoHistory = useSelector(getWalletsCryptoHistoryList);

	const removeCurrentPage = () => {
		const search = deleteSearchParam('currentPage');
		history.replace({
			pathname: location.pathname,
			search,
		});
	};

	useEffect(() => {
		dispatch(
			getWalletsCryptoHistoryRequest({
				params: {
					current_page: currentPage,
					per_page: perPage,
					transaction_type: type ? String(type) : undefined,
					past_days: pastDays || undefined,
					asset_id: assetId || undefined,
					status_id: statusId ? String(statusId) : undefined,
					tx_id: txId || undefined,
				},
			}),
		);
	}, [assetId, currentPage, dispatch, pastDays, perPage, statusId, txId, type]);

	const onTypeChange = (_type: string | null) => {
		removeCurrentPage();
		setType(_type);
	};

	const onPastDaysChange = (_pastDays: number | null) => {
		removeCurrentPage();
		setPastDays(_pastDays);
	};

	const onAssetChange = (id: number | null) => {
		removeCurrentPage();
		setAssetId(id);
	};

	const onStatusChange = (_statusId: TransactionStatuses | null) => {
		removeCurrentPage();
		setStatusId(_statusId);
	};

	const onTxIdChange = (e: SyntheticEvent<HTMLInputElement>) => {
		removeCurrentPage();
		const target = e.target as HTMLButtonElement;
		setTxId(target.value);
	};

	const resetFilter = () => {
		removeCurrentPage();
		setType(null);
		setPastDays(null);
		setAssetId(null);
		setStatusId(null);
		setTxId('');
	};

	const renderTableBody = () => {
		if (loader) {
			return (
				<div className="popup-loader">
					<Loader />
				</div>
			);
		}
		if (cryptoHistory?.total === 0) {
			return <TableEmptyPlug />;
		}
		return (
			<div className="table-body">
				{cryptoHistory?.data.map((ch) => (
					<TableRow key={ch.id} item={ch} />
				))}
			</div>
		);
	};

	return (
		<div className="table table--transaction-history">
			<div className="table-filter flex flex-sb">
				<div className="table-filter-left flex flex-m flex--gap24">
					<TransactionTypeSelect
						label={String(L.translate('TransactionHistory.filters.type'))}
						value={type}
						onChange={onTypeChange}
						withdrawal
					/>
					<PastDaysSelect
						label={String(L.translate('TransactionHistory.filters.time'))}
						value={pastDays}
						onChange={onPastDaysChange}
					/>
					<AssetSelect
						label={String(L.translate('TransactionHistory.filters.asset'))}
						assetsList={assetsList || []}
						value={assetId}
						onChange={onAssetChange}
					/>
					<StatusSelect
						label={String(L.translate('TransactionHistory.filters.status'))}
						value={statusId}
						onChange={onStatusChange}
					/>
					<div className="input input--auto input--no-mb">
						<div className="input__name">
							<p>{String(L.translate('TransactionHistory.filters.tx_id'))}</p>
						</div>
						<div className="input-wrapper">
							<DebounceInput
								debounceTimeout={200}
								type="text"
								placeholder={String(L.translate('TransactionHistory.filters.tx_id_placeholder'))}
								className="input-item input-item--type2"
								name="txId"
								value={txId}
								onChange={onTxIdChange}
								autoComplete="off"
							/>
						</div>
					</div>
				</div>
				<div className="table-filter-right">
					<button type="button" onClick={resetFilter} className="button button--type3">
						{String(L.translate('TransactionHistory.filters.resetFilters'))}
					</button>
				</div>
			</div>
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p>{String(L.translate('TransactionHistory.table.time'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('TransactionHistory.table.type'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('TransactionHistory.table.depositWallet'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('TransactionHistory.table.asset'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('TransactionHistory.table.amount'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('TransactionHistory.table.status'))}</p>
					</div>
					<div className="td td--right">
						<p>{String(L.translate('TransactionHistory.table.txID'))}</p>
					</div>
				</div>
			</div>
			{renderTableBody()}
			{cryptoHistory?.last_page &&
				Number(cryptoHistory?.total) > Number(cryptoHistory?.per_page) && (
					<Pagination lastPage={cryptoHistory.last_page} />
				)}
		</div>
	);
};

export default TransactionHistoryCrypto;
