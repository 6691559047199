import { FC } from 'react';
import L from 'i18n-react';

type IStopOrderProps = {
	takeProfitValue: number | string | null;
	takeProfitChanger: any;
	stopLoseValue: number | string | null;
	stopLoseChanger: any;
};

const StopOrder: FC<IStopOrderProps> = ({
	takeProfitValue,
	takeProfitChanger,
	stopLoseValue,
	stopLoseChanger,
}) => {
	return (
		<div>
			<label className="orderform-input">
				<span className="orderform-input__placeholder">
					{String(L.translate('Trade.Perpetual.OrderForm.take_profit_title'))}
				</span>
				<input
					className="orderform-input__data"
					type="text"
					placeholder="0.0"
					value={takeProfitValue || ''}
					onChange={takeProfitChanger}
				/>
				<span className="orderform-input__placeholder fw-700">USDT</span>
			</label>
			<label className="orderform-input">
				<span className="orderform-input__placeholder">
					{String(L.translate('Trade.Perpetual.OrderForm.stop_lose_title'))}
				</span>
				<input
					className="orderform-input__data"
					type="text"
					placeholder="0.0"
					value={stopLoseValue || ''}
					onChange={stopLoseChanger}
				/>
				<span className="orderform-input__placeholder fw-700">USDT</span>
			</label>
		</div>
	);
};

export default StopOrder;
