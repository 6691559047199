import { FC } from 'react';
import { IPerpetialSelectItem } from './types';

const PerpetualSelectItem: FC<IPerpetialSelectItem> = ({ item, onClick }) => {
	// const formattedAmount = String(item['24h_volume'])
	// 	.split('')
	// 	.reduce((acc, curr, idx) => {
	// 		let result: string = acc;
	// 		if (idx > 0 && idx % 3 === 0) {
	// 			result += ',';
	// 		}
	// 		result += curr;
	// 		return result;
	// 	}, '');

	const handleSelect = () => {
		onClick && onClick(item);
	};

	return (
		<div className="trade-drop-list__line" onClick={handleSelect}>
			<span>
				<button type="button">
					<span className="svg-star">
						<svg
							width="10"
							height="11"
							viewBox="0 0 10 11"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M4.46355 0.847861L3.24299 3.43348L0.512158 3.84944C0.0224388 3.92365 -0.173823 4.55443 0.181317 4.91572L2.15701 6.92719L1.68973 9.76863C1.60561 10.2822 2.12337 10.667 2.55701 10.4268L5 9.08512L7.44299 10.4268C7.87663 10.665 8.39439 10.2822 8.31028 9.76863L7.84299 6.92719L9.81868 4.91572C10.1738 4.55443 9.97756 3.92365 9.48784 3.84944L6.75701 3.43348L5.53645 0.847861C5.31776 0.38698 4.68411 0.381122 4.46355 0.847861Z"
								fill="#9D9DBC"
							/>
						</svg>
					</span>
				</button>
				{`${item.base_asset?.toUpperCase()}/USDT`}
			</span>
			<span className="green">{item.last_price}</span>
			<span>--</span>
			<span className="text-align-right">{item['24h_volume']}</span>
		</div>
	);
};

export default PerpetualSelectItem;
