import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import { ITradingSettings, ITradingSettingsStore, TCurrentPairObj } from './types';
/* eslint-disable no-debugger */

// ==========================================:
const getTradingSettingsState = (state: TStoreState): ITradingSettingsStore =>
	state.tradingSettings;
export const getTradingSettings = createSelector(
	[getTradingSettingsState],
	(tradingSettings: ITradingSettingsStore) => tradingSettings,
);

// ====================================================:
export const getWorkspaceSettings = createSelector(
	[getTradingSettings],
	(tradingSettings: ITradingSettingsStore): ITradingSettings => tradingSettings.workspaceSettings,
);

// ====================================================:
export const getCurrentPair = createSelector(
	[getTradingSettings],
	(tradingSettings: ITradingSettingsStore): TCurrentPairObj => {
		return tradingSettings.currentPair;
	},
);

// ====================================================:
export const getCurrentInterval = createSelector(
	[getTradingSettings],
	(tradingSettings: ITradingSettingsStore): string => tradingSettings.interval,
);
