import L from 'i18n-react';
import { TransactionStatuses } from './types';

export const getLabel = (value: TransactionStatuses | null) => {
	switch (value) {
		case TransactionStatuses.Pending:
			return String(L.translate('TransactionHistory.filters.status_pending'));
		case TransactionStatuses.Processing:
			return String(L.translate('TransactionHistory.filters.status_processing'));
		case TransactionStatuses.Completed:
			return String(L.translate('TransactionHistory.filters.status_completed'));
		case TransactionStatuses.Canceled:
			return String(L.translate('TransactionHistory.filters.status_canceled'));
		case null:
			return String(L.translate('TransactionHistory.filters.status_all'));
		default:
			return '';
	}
};
