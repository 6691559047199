import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notificationContainer } from '../../../services/utils/notificationContainer';

const initialState = {
	limits: null,
	limitsLoader: false,
	rate: null,
	rateLoader: false,
	convertLoader: false,
};

const exchange = createSlice({
	name: '@@exchange',
	initialState,
	reducers: {
		getLimitsRequest: (state, { payload }) => {
			const exchangeState = state;
			exchangeState.limitsLoader = true;
		},
		getLimitsSuccess: (state, { payload }) => {
			const exchangeState = state;
			exchangeState.limitsLoader = false;
			exchangeState.limits = payload;
		},
		getCurrencyRateRequest: (state, { payload }) => {
			const exchangeState = state;
			exchangeState.rateLoader = true;
		},
		getCurrencyRateSuccess: (state, { payload }) => {
			const exchangeState = state;
			exchangeState.rateLoader = false;
			exchangeState.rate = payload;
		},
		putConvertRequest: (state, { payload }) => {
			const exchangeState = state;
			exchangeState.convertLoader = true;
		},
		putConvertSuccess: (state, { payload }) => {
			const exchangeState = state;
			exchangeState.convertLoader = false;
			notificationContainer('Success', 'success');
		},
	},
});

export default exchange.reducer;

export const {
	getLimitsRequest,
	getLimitsSuccess,
	getCurrencyRateRequest,
	getCurrencyRateSuccess,
	putConvertRequest,
	putConvertSuccess,
} = exchange.actions;
