import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import GetCodeButton from 'ui/GetCodeButton/GetCodeButton';
import { useDispatch, useSelector } from 'react-redux';
import { ETwoFaEnabledStatus } from 'redux/reducers/twoFa/types';
import { generateSmsRequest } from 'redux/reducers/auth/reducer';
import { getAntiphishingStatus, getUsersSettings } from 'redux/reducers/settings/selectors';
import { getTwoFaEnableStatus } from 'redux/reducers/twoFa/selectors';
import { twoFaDisableRequest, twoFaSmsDisableRequest } from 'redux/reducers/twoFa/reducer';
import { TFormTwoFa } from 'components/AccountSettings/AccountSettingsTwoFa/type';
import L from 'i18n-react';
import { disableAntiPhishingRequest } from 'redux/reducers/settings/reducer';

const TwoFaDisableForm: FC<TFormTwoFa> = ({ onClose }) => {
	const dispatch = useDispatch();
	const userData = useSelector(getUsersSettings);
	const twoFaEnableStatus = useSelector(getTwoFaEnableStatus);
	// const antiPhishingStatus = useSelector(getAntiphishingStatus);
	const antiPhishingStatus = true;

	console.log('antiPhishingStatus', antiPhishingStatus);

	const phoneToView = useMemo(() => {
		if (userData?.data.phone) {
			const phoneStartNumbers: string = userData?.data.phone.slice(0, 4);
			const phoneEndNumbers: string = userData?.data.phone.slice(-4);
			return `${phoneStartNumbers}**${phoneEndNumbers}`;
		}
		return '';
	}, [userData?.data.phone]);

	const handleGetPhoneCode = () => {
		dispatch(generateSmsRequest({ phone: userData?.data.phone }));
	};

	const [codeInput, setCodeInput] = useState('');
	const setCodeInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		if (/^[0-9]*$/.test(value) && value.length <= 6) {
			setCodeInput(event.target.value);
		}
	};
	const confirmHandler = () => {
		if (antiPhishingStatus) {
			dispatch(disableAntiPhishingRequest());
		}
		dispatch(twoFaDisableRequest(codeInput));
	};
	const confirmSmsHandler = () => {
		dispatch(twoFaSmsDisableRequest(codeInput));
	};
	return (
		<div className="setting-content-body-item">
			<div className="setting-content-body-item-header setting-content-body-item-header--type2">
				<p>{String(L.translate('Account.TwoFactorAuth.two_factor_auth_form_disable_title'))}</p>
			</div>
			<div className="settings-2fa">
				<div className="settings-2fa__text">
					{String(L.translate('Account.TwoFactorAuth.two_factor_auth_form_disable_subtitle'))}
				</div>
				<div className="settings-2fa-item">
					<div className="input">
						<div className="input-wrapper">
							<input
								type="text"
								value={codeInput}
								onChange={setCodeInputHandler}
								className="input-item input-item--pr125"
								placeholder={String(
									L.translate(
										'Account.TwoFactorAuth.two_factor_auth_form_disable_field_placeholder',
									),
								)}
							/>
							{twoFaEnableStatus === ETwoFaEnabledStatus.SMS_TWO_FA_ENABLED && (
								<GetCodeButton onClick={handleGetPhoneCode} />
							)}
						</div>
						<div className="input__notification input__notification--type2">
							{twoFaEnableStatus === ETwoFaEnabledStatus.GOOGLE_TWO_FA_ENABLED && (
								<p>
									{String(
										L.translate(
											'Account.TwoFactorAuth.two_factor_auth_form_disable_field_prompt_google',
										),
									)}
								</p>
							)}
							{twoFaEnableStatus === ETwoFaEnabledStatus.SMS_TWO_FA_ENABLED && (
								<p>
									{String(
										L.translate(
											'Account.TwoFactorAuth.two_factor_auth_form_disable_field_prompt_sms',
										),
									)}{' '}
									{phoneToView}.
								</p>
							)}
						</div>
					</div>
				</div>
				<div className="settings-2fa-item settings-2fa-item--type2">
					<button
						type="button"
						onClick={onClose}
						className="button button--size2 button--full-width button--type3"
					>
						Cancel
					</button>
					{twoFaEnableStatus === ETwoFaEnabledStatus.GOOGLE_TWO_FA_ENABLED && (
						<button
							type="button"
							onClick={confirmHandler}
							disabled={!(codeInput !== '')}
							className="button button--size2 button--full-width"
						>
							{String(
								L.translate('Account.TwoFactorAuth.two_factor_auth_form_disable_confirm_btn'),
							)}
						</button>
					)}
					{twoFaEnableStatus === ETwoFaEnabledStatus.SMS_TWO_FA_ENABLED && (
						<button
							type="button"
							onClick={confirmSmsHandler}
							disabled={!(codeInput !== '')}
							className="button button--size2 button--full-width"
						>
							{String(
								L.translate('Account.TwoFactorAuth.two_factor_auth_form_disable_confirm_btn'),
							)}
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default TwoFaDisableForm;
