/* eslint-disable no-debugger */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	getMarginCrossWalletsRequest,
	getMarginCrossWalletsSuccess,
	getMarginIndexPricesRequest,
	getMarginIsolatedWalletsSuccess,
	getMarginIndexPricesSuccess,
	postMarginTransferRequest,
	getMarginIsolatedWalletsRequest,
	getMaximumBorrowAmoiuntRequest,
	getMaximumBorrowAmountSuccess,
	postTakeBorrowRequest,
	getborrowAvailableRequest,
	getborrowAvailableSuccess,
	postTakeBorrowIsolateRequest,
	getTransferHistorySuccess,
	getTransferHistoryRequest,
	getMarginWalletBorrowHistorySuccess,
	getMarginWalletBorrowIsolatedHistorySuccess,
	getMarginWalletBorrowHistoryRequest,
	getRepayHistorySuccess,
	getRepayHistoryRequest,
	getInterestedHistorySuccess,
	getInterestedIsolatedHistorySuccess,
	getRepayIsolatedHistorySuccess,
	getInterestedHistoryRequest,
	getMarginCallHistoryRequest,
	getMarginCallHistoryCrossSuccess,
	getMarginCallHistoryIsolatedSuccess,
	getLiqudationCrossHistorySuccess,
	getLiqudationIsolatedHistorySuccess,
	getLiqudationHistoryRequest,
} from './reducer';
import {
	IMarginCrossWallets,
	IIndexPrices,
	IGetMarginWalletsPayload,
	ITransferMagin,
	IMarginIsolatedWallets,
	IMaximumCrossBorrowAmount,
	ITakeBorrow,
	IBorrowAvailable,
	ITakeIsolatedBorrow,
	ITransferHistoryResponsePayload,
	ITransferHistoryRequestPayload,
	IUserMarginWalletResponsePayload,
	IUserMarginWalletRequestPayload,
	IUserLiquidationWalletResponsePayload,
	IBorrowCrossWalletsList,
	IBorrowIsolatedWalletsList,
	IBorrowCrossWalletsResponsePayload,
	IBorrowIsolatedWalletsResponsePayload,
	IRepayCrossWalletsResponsePayload,
	IRepayIsolatedWalletsResponsePayload,
	IInterestCrossWalletsResponsePayload,
	IInterestIsolatedWalletsResponsePayload,
	IRepayCrossWalletsList,
	IRepayIsolatedWalletsList,
	IInterestCrossWalletsList,
	IInterestIsolatedWalletsList,
	ICallsCrossWalletsResponsePayload,
	ICallsIsolatedWalletsResponsePayload,
	ILiquidationWalletsCrossResponsePayload,
	ILiquidationWalletsIsolatedResponsePayload,
} from './types';
import {
	isBorrowIsolated,
	isRepayIsolated,
	isInterstIsolated,
	isCallsIsolated,
	isLiquidationIsolated,
} from './guards';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// =============================================================:
function* marginWalletsRequestWorker(action: PayloadAction<IGetMarginWalletsPayload>) {
	try {
		yield put(showLoading());
		const { payload } = action;
		const response: IMarginCrossWallets = yield call(
			api.marginWallets.getMarginCrossWallets,
			payload,
		);

		yield put(getMarginCrossWalletsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(tradingSettingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* marginIsolatedWalletsRequestWorker(action: PayloadAction<IGetMarginWalletsPayload>) {
	try {
		yield put(showLoading());
		const { payload } = action;
		const response: IMarginIsolatedWallets = yield call(
			api.marginWallets.getMarginIsolatedWallets,
			payload,
		);

		yield put(getMarginIsolatedWalletsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(tradingSettingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* marginindexPricesWorker() {
	try {
		yield put(showLoading());
		const response: IIndexPrices = yield call(api.marginWallets.getIndexPrices);

		yield put(getMarginIndexPricesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* marginTransferRequestWorker(action: PayloadAction<ITransferMagin>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.marginWallets.postTransferMargin, payload);

		notificationContainer('Transfer was send!', 'success');
		payload.closeModal();
		payload.setIsDisabled(false);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			payload.setIsDisabled(false);
		}
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* maximumBorrowAmountWorker(action: PayloadAction<number>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IMaximumCrossBorrowAmount = yield call(
			api.marginWallets.getMaximumBorrowAmount,
			payload,
		);
		yield put(getMaximumBorrowAmountSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* takeBorrowWorker(action: PayloadAction<ITakeBorrow>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.marginWallets.postBorrowTake, payload);

		notificationContainer(
			payload.type === 'borrow' ? 'Borrow cross was send!' : 'Repay cross was send!',
			'success',
		);
		payload.closeModal();
		payload.setIsDisabled(false);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* borrowAvailableWorker(action: PayloadAction<any>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IBorrowAvailable = yield call(api.marginWallets.getBorrowAvailable, payload);
		yield put(getborrowAvailableSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* takeBorrowIsolateWorker(action: PayloadAction<ITakeIsolatedBorrow>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.marginWallets.postBorrowIsolatedTake, payload);

		notificationContainer(
			payload.type === 'borrow' ? 'Borrow isolated was send!' : 'Repay isolated was send!',
			'success',
		);
		payload.closeModal();
		payload.setIsDisabled(false);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getTransferHistoryRequestWorker(action: PayloadAction<ITransferHistoryRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ITransferHistoryResponsePayload = yield call(
			api.marginWallets.getTransferHistory,
			payload,
		);
		if (response?.status === 'success') {
			notificationContainer('Transfer transaction history was sent to your email!', 'info');
		} else {
			yield put(getTransferHistorySuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getMarginWalletBorrowWorker(action: PayloadAction<IUserMarginWalletRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IBorrowCrossWalletsResponsePayload | IBorrowIsolatedWalletsResponsePayload =
			yield call(api.marginWallets.getBorrowHisotry, payload);

		if (payload.isolated && isBorrowIsolated(response)) {
			yield put(getMarginWalletBorrowIsolatedHistorySuccess(response));
		} else {
			yield put(getMarginWalletBorrowHistorySuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(spotTradeInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getMarginWalletRepayWorker(action: PayloadAction<IUserMarginWalletRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IRepayCrossWalletsResponsePayload | IRepayIsolatedWalletsResponsePayload =
			yield call(api.marginWallets.getRepayHisotry, payload);

		if (payload.isolated && isRepayIsolated(response)) {
			yield put(getRepayIsolatedHistorySuccess(response));
		} else {
			yield put(getRepayHistorySuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(spotTradeInitState());
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* getMarginWalletIntrestedWorker(action: PayloadAction<IUserMarginWalletRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IInterestCrossWalletsResponsePayload | IInterestIsolatedWalletsResponsePayload =
			yield call(api.marginWallets.getInterestHisotry, payload);

		if (payload.isolated && isInterstIsolated(response)) {
			yield put(getInterestedIsolatedHistorySuccess(response));
		} else {
			yield put(getInterestedHistorySuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(spotTradeInitState());
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* getMarginWalletCallWorker(action: PayloadAction<IUserMarginWalletRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ICallsCrossWalletsResponsePayload | ICallsIsolatedWalletsResponsePayload =
			yield call(api.marginWallets.getMarginCallHisotry, payload);

		if (payload.isolated && isCallsIsolated(response)) {
			yield put(getMarginCallHistoryIsolatedSuccess(response));
		} else {
			yield put(getMarginCallHistoryCrossSuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(spotTradeInitState());
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* getMarginWalletLiqudityWorker(action: PayloadAction<IUserMarginWalletRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response:
			| ILiquidationWalletsCrossResponsePayload
			| ILiquidationWalletsIsolatedResponsePayload = yield call(
			api.marginWallets.getLiqudityHisotry,
			payload,
		);

		if (payload.isolated && isLiquidationIsolated(response)) {
			yield put(getLiqudationIsolatedHistorySuccess(response));
		} else {
			yield put(getLiqudationCrossHistorySuccess(response));
		}

		// yield put(getLiqudationHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(spotTradeInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* marginWalletsSaga() {
	yield takeEvery(getMarginCrossWalletsRequest.type, marginWalletsRequestWorker);
	yield takeEvery(getMarginIndexPricesRequest.type, marginindexPricesWorker);
	yield takeEvery(postMarginTransferRequest.type, marginTransferRequestWorker);
	yield takeEvery(getMarginIsolatedWalletsRequest.type, marginIsolatedWalletsRequestWorker);
	yield takeEvery(getMaximumBorrowAmoiuntRequest.type, maximumBorrowAmountWorker);
	yield takeEvery(postTakeBorrowRequest.type, takeBorrowWorker);
	yield takeEvery(getborrowAvailableRequest.type, borrowAvailableWorker);
	yield takeEvery(postTakeBorrowIsolateRequest.type, takeBorrowIsolateWorker);
	yield takeEvery(getTransferHistoryRequest.type, getTransferHistoryRequestWorker);
	yield takeEvery(getMarginWalletBorrowHistoryRequest.type, getMarginWalletBorrowWorker);
	yield takeEvery(getRepayHistoryRequest.type, getMarginWalletRepayWorker);
	yield takeEvery(getInterestedHistoryRequest.type, getMarginWalletIntrestedWorker);
	yield takeEvery(getMarginCallHistoryRequest.type, getMarginWalletCallWorker);
	yield takeEvery(getLiqudationHistoryRequest.type, getMarginWalletLiqudityWorker);
}
