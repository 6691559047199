import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import { IAnalyticsStore } from './types';

// ==========================================:
const getAnalyticsState = (state: TStoreState): any => state.analytics;
export const getAnalytics = createSelector(
	[getAnalyticsState],
	(analytics: IAnalyticsStore) => analytics,
);

// ====================================================:
export const getAnalyticsPair = createSelector(
	[getAnalytics],
	(analytics: any) => analytics.analyticsPair,
);

export const analyticsData = createSelector(
	[getAnalytics],
	(analytics: any) => analytics.analyticsData,
);
export const analyticsDataLoading = createSelector(
	[getAnalytics],
	(analytics: any) => analytics.analyticsLoading,
);
