import { FC, useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import L from 'i18n-react';
import Input from 'ui/Formik/Input';
import UploadedFileInput from 'ui/UploadedFileInput';
import UploadFileInput from 'ui/UploadFileInput';
import { toBase64 } from 'services/utils/toBase64';
import { widthFile } from 'services/utils/fileWidth';
import { notificationContainer } from 'services/utils/notificationContainer';
import { IForgotTwofaSubmitValue, IForgotTwofaForm } from './types';

/* eslint-disable @typescript-eslint/no-explicit-any */
// ==========================================:
export const ResetFormTwoFa: FC<IForgotTwofaForm> = ({ twoFaSubmit, closeModal }) => {
	const [selfie, setSelfie] = useState<string | null>(null);
	const [file, setFile] = useState<File | null | string>(null);
	const [errorSelfie, setErrorSelfie] = useState(false);

	const initialValues = {
		email: '',
		message: '',
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required(String(L.translate('Errors.required_email')))
			.email(String(L.translate('Errors.email_format')))
			.max(60, String(L.translate('Errors.email_max_characters'))),
		message: yup
			.string()
			.required(String(L.translate('Errors.required_text')))
			.max(500, String(L.translate('Errors.text_max_characters'))),
	});

	useEffect(() => {
		if (!file) return;
		const getImg: any = async () => {
			try {
				const img: any = await toBase64(file);
				if (img) {
					setSelfie(img);
					setErrorSelfie(false);
				}
			} catch {
				setErrorSelfie(true);
				notificationContainer(String(L.translate(`Errors.smg_went_wrong`)), 'error');
			}
		};
		getImg();
	}, [file]);

	const handleDeleteFile = () => {
		setFile(null);
		setSelfie(null);
	};

	const handleUploadFile = (e: React.FormEvent<HTMLInputElement>) => {
		const tragetFile: File | null | undefined = e.currentTarget.files?.item(0);
		if (!widthFile(tragetFile?.size)) {
			setErrorSelfie(true);
			notificationContainer(String(L.translate(`Errors.img_width_wrong`)), 'error'); // error if file size bigger then 3 MB);
			return;
		}

		if (tragetFile) {
			setErrorSelfie(false);
			setFile(tragetFile);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IForgotTwofaSubmitValue, { resetForm, setSubmitting }) => {
				twoFaSubmit({ values, selfie });
				setSubmitting(false);
				resetForm();
				setFile(null);
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty }) => {
				return (
					<Form>
						<div className="popup">
							<button type="button" onClick={closeModal} className="popup__close">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
										fill="#1C1C29"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
										fill="#1C1C29"
									/>
								</svg>
							</button>
							<div className="popup-header">
								<p className="popup-header__title">
									{String(L.translate('Forms.Forgot2fa.forgot_2fa_title'))}
								</p>
							</div>
							<div className="popup-body">
								<div className="popup-text">
									<p>{String(L.translate('Forms.Forgot2fa.forgot_2fa_text'))}</p>
								</div>
								<div className="popup-body-item">
									<Field
										type="email"
										placeholder={String(L.translate('Forms.Forgot2fa.forgot_password_field_email'))}
										name="email"
										required
										component={Input}
									/>
									{/* <div className="input">
										<div className="input-wrapper">
											<input className="input-item" type="text" placeholder="Email" />
										</div>
									</div> */}
								</div>
								<div className="popup-body-item">
									<Field
										type="textarea"
										className="form-item form-item--area input-item--textarea"
										name="message"
										placeholder={String(L.translate('Forms.Forgot2fa.forgot_2fa_field_text'))}
										required
										component={Input}
									/>
									{/* <div className="input">
										<div className="input-wrapper">
											<div className="textarea">
												<textarea placeholder="Message" />
											</div>
										</div>
									</div> */}
								</div>
								<div className="popup-body-item">
									{file ? (
										<UploadedFileInput file={file} handleDeleteFile={handleDeleteFile} />
									) : (
										<UploadFileInput
											errorSelfie={errorSelfie}
											handleUploadFile={handleUploadFile}
										/>
									)}
								</div>
							</div>
							<div className="popup-footer">
								<div className="popup-submit popup-submit--sb">
									<button
										type="submit"
										disabled={!(isValid && dirty) || isSubmitting || !selfie}
										className="button button--size2 button--full-width"
										aria-label="form-submit"
									>
										{String(L.translate('Forms.Forgot2fa.forgot_password_btn_submit'))}
									</button>
								</div>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
