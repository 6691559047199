import { IWalletsNetwork } from 'redux/reducers/wallets/types';
import { toMaxDecimals } from 'services/utils/toMaxDecimals';

export const validateNumberInput = (text: string): boolean => {
	if (text?.length >= 2 && text?.[0] === '0' && text?.[1] !== '.') return false;

	return /^[0-9]*\.?[0-9]*$/.test(text);
};

export const validateAmount = (amount: string, balance?: string, withdrawMin?: number) => {
	if (!amount) return null;

	const amountNum = amount === '.' ? 0 : Number(amount);

	if (amountNum > Number(balance)) {
		return 'Amount must be less than your balance';
	}
	if (amountNum < Number(withdrawMin)) {
		return 'Amount must be greater than minimum withdrawal';
	}
	return null;
};

export const getNetworkFeeRate = (network: IWalletsNetwork | null, assetCode?: string) => {
	if (network?.withdrawal_fee_is_percent) {
		return `${network.withdrawal_fee_amount_percent || 0} %`;
	}
	return `${network?.withdrawal_fee_amount || 0} ${assetCode || ''}`;
};

export const getReceiveAmount = (network: IWalletsNetwork | null, amountNum: number) => {
	if (network?.withdrawal_fee_is_percent) {
		return toMaxDecimals(amountNum * (1 - Number(network.withdrawal_fee_amount_percent) / 100), 6);
	}
	return toMaxDecimals(amountNum - Number(network?.withdrawal_fee_amount), 6);
};

export const getNetworkFeeValue = (network: IWalletsNetwork | null, amountNum: number) => {
	if (network?.withdrawal_fee_is_percent) {
		return toMaxDecimals((amountNum / 100) * Number(network.withdrawal_fee_amount_percent), 6);
	}
	return toMaxDecimals(Number(network?.withdrawal_fee_amount), 6);
};
