const reduceToValidNumber = (value: string) => {
	let result: string | string[] = String(value).split('');
	if (!value) {
		return '';
	}
	if (/^\d+[.,]?\d*$/.test(value)) {
		const index = result.indexOf(',');
		if (index !== -1) {
			result[index] = '.';
		}
		result = result
			.map((item) => {
				if (/\d+/.test(item)) {
					return item;
				}
				if (/\./.test(item)) {
					return '.';
				}
				return null;
			})
			.filter(Boolean)
			.join('');
	}
	return result;
};

export default reduceToValidNumber;
