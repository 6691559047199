import { FC, ReactNode, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import L from 'i18n-react';
import { ROUTES } from 'routes/routesList';
import WalletNavLink from '../WalletNavLink';

const WalletSidePanel: FC = () => {
	const [walletsActive, setWalletsActive] = useState<boolean>(true);
	const [marginMenuActive, setMarginMenuActive] = useState<boolean>(false);
	const [depositActive, setDepositActive] = useState<boolean>(true);

	const toggleWallets = (): void => {
		setWalletsActive((prev) => !prev);
	};

	const toggleDeposit = (): void => {
		setDepositActive((prev) => !prev);
	};

	const location = useLocation();

	if (/^\/wallet\/margin-account(.)*$/.test(location.pathname)) {
		if (!marginMenuActive) {
			setMarginMenuActive(true);
		}
	} else if (marginMenuActive) {
		setMarginMenuActive(false);
	}

	const arrow: ReactNode = (
		<div className="sidebar-nav-item__button-arrow">
			<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 6.5L10.8 6.5L12 6.5L6 0.499999L0 6.5Z" fill="#1C1C29" />
			</svg>
		</div>
	);

	return (
		<div className="sidebar-nav">
			<div className={`sidebar-nav-item ${walletsActive ? 'active' : ''}`}>
				<button type="button" className="sidebar-nav-item__button" onClick={toggleWallets}>
					{String(L.translate('Menu.WalletsLayout.title'))}
					{arrow}
				</button>
				<nav className="sidebar-nav-item__list">
					<ul>
						<li>
							<WalletNavLink
								title={String(L.translate('Menu.WalletsLayout.spot_account'))}
								to="/wallet/spot"
								icon={
									<svg
										width="24"
										height="25"
										viewBox="0 0 24 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M1.25 12.5C1.25 6.56294 6.06294 1.75 12 1.75C17.9371 1.75 22.75 6.56294 22.75 12.5C22.75 18.4371 17.9371 23.25 12 23.25C6.06294 23.25 1.25 18.4371 1.25 12.5ZM12 3.25C6.89136 3.25 2.75 7.39136 2.75 12.5C2.75 17.6086 6.89136 21.75 12 21.75C17.1086 21.75 21.25 17.6086 21.25 12.5C21.25 7.39136 17.1086 3.25 12 3.25Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M12.1768 10.2024C12.0791 10.1048 11.9209 10.1048 11.8232 10.2024L9.7019 12.3237C9.60427 12.4213 9.60427 12.5796 9.7019 12.6773L11.8232 14.7986C11.9209 14.8962 12.0791 14.8962 12.1768 14.7986L14.2981 12.6773C14.3957 12.5796 14.3957 12.4213 14.2981 12.3237L12.1768 10.2024ZM10.7626 9.14173C11.446 8.45832 12.554 8.45832 13.2374 9.14173L15.3588 11.2631C16.0422 11.9465 16.0422 13.0545 15.3588 13.7379L13.2374 15.8592C12.554 16.5427 11.446 16.5427 10.7626 15.8592L8.64124 13.7379C7.95783 13.0545 7.95783 11.9465 8.64124 11.2631L10.7626 9.14173Z"
											fill="#7C7CA6"
										/>
									</svg>
								}
							/>
						</li>
						<li>
							<WalletNavLink
								title={String(L.translate('Menu.WalletsLayout.margin_account'))}
								to={ROUTES.wallet.marginAccount.root}
								icon={
									<svg
										width="24"
										height="25"
										viewBox="0 0 24 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M2.25 10C2.25 9.58579 2.58579 9.25 3 9.25H21C21.4142 9.25 21.75 9.58579 21.75 10C21.75 10.4142 21.4142 10.75 21 10.75H3C2.58579 10.75 2.25 10.4142 2.25 10Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M14.4697 3.46967C14.7626 3.17678 15.2374 3.17678 15.5303 3.46967L21.5303 9.46967C21.8232 9.76256 21.8232 10.2374 21.5303 10.5303C21.2374 10.8232 20.7626 10.8232 20.4697 10.5303L14.4697 4.53033C14.1768 4.23744 14.1768 3.76256 14.4697 3.46967Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M2.64941 15C2.64941 14.5858 2.9852 14.25 3.39941 14.25H21.3994C21.8136 14.25 22.1494 14.5858 22.1494 15C22.1494 15.4142 21.8136 15.75 21.3994 15.75H3.39941C2.9852 15.75 2.64941 15.4142 2.64941 15Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M2.86908 14.4697C3.16198 14.1768 3.63685 14.1768 3.92974 14.4697L9.92977 20.4697C10.2227 20.7626 10.2227 21.2374 9.92977 21.5303C9.63688 21.8232 9.162 21.8232 8.86911 21.5303L2.86909 15.5303C2.57619 15.2374 2.57619 14.7626 2.86908 14.4697Z"
											fill="#7C7CA6"
										/>
									</svg>
								}
							/>
							{marginMenuActive && (
								<>
									<NavLink
										to={ROUTES.wallet.marginAccount.openOrders}
										className="sidebar-nav-item__list-level2"
									>
										<div className="sidebar-nav-item__icon" />
										<div className="sidebar-nav-item__text">
											<p>{String(L.translate('Menu.WalletsLayout.open_orders'))}</p>
										</div>
									</NavLink>
									<NavLink
										to={ROUTES.wallet.marginAccount.orderHistory}
										className="sidebar-nav-item__list-level2"
									>
										<div className="sidebar-nav-item__icon" />
										<div className="sidebar-nav-item__text">
											<p>{String(L.translate('Menu.WalletsLayout.order_history'))}</p>
										</div>
									</NavLink>
									<NavLink
										to={ROUTES.wallet.marginAccount.tradeHistory}
										className="sidebar-nav-item__list-level2"
									>
										<div className="sidebar-nav-item__icon" />
										<div className="sidebar-nav-item__text">
											<p>{String(L.translate('Menu.WalletsLayout.trade_history'))}</p>
										</div>
									</NavLink>
									<NavLink
										to={ROUTES.wallet.marginAccount.borrowing}
										className="sidebar-nav-item__list-level2"
									>
										<div className="sidebar-nav-item__icon" />
										<div className="sidebar-nav-item__text">
											<p>{String(L.translate('Menu.WalletsLayout.borrowing'))}</p>
										</div>
									</NavLink>
									<NavLink
										to={ROUTES.wallet.marginAccount.repayment}
										className="sidebar-nav-item__list-level2"
									>
										<div className="sidebar-nav-item__icon" />
										<div className="sidebar-nav-item__text">
											<p>{String(L.translate('Menu.WalletsLayout.repayment'))}</p>
										</div>
									</NavLink>
									<NavLink
										to={ROUTES.wallet.marginAccount.interest}
										className="sidebar-nav-item__list-level2"
									>
										<div className="sidebar-nav-item__icon" />
										<div className="sidebar-nav-item__text">
											<p>{String(L.translate('Menu.WalletsLayout.interest'))}</p>
										</div>
									</NavLink>
									<NavLink
										to={ROUTES.wallet.marginAccount.calls}
										className="sidebar-nav-item__list-level2"
									>
										<div className="sidebar-nav-item__icon" />
										<div className="sidebar-nav-item__text">
											<p>{String(L.translate('Menu.WalletsLayout.margin_calls'))}</p>
										</div>
									</NavLink>
									<NavLink
										to={ROUTES.wallet.marginAccount.liquidationOrders}
										className="sidebar-nav-item__list-level2"
									>
										<div className="sidebar-nav-item__icon" />
										<div className="sidebar-nav-item__text">
											<p>{String(L.translate('Menu.WalletsLayout.liquidation_orders'))}</p>
										</div>
									</NavLink>
								</>
							)}
						</li>
						{/* <li>
							<WalletNavLink
								title="Option Account"
								to="/wallet/option"
								icon={
									<svg
										width="24"
										height="25"
										viewBox="0 0 24 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M7.75 7C7.75 4.65279 9.65279 2.75 12 2.75C14.3472 2.75 16.25 4.65279 16.25 7C16.25 9.34721 14.3472 11.25 12 11.25C9.65279 11.25 7.75 9.34721 7.75 7ZM12 4.25C10.4812 4.25 9.25 5.48122 9.25 7C9.25 8.51879 10.4812 9.75 12 9.75C13.5188 9.75 14.75 8.51879 14.75 7C14.75 5.48122 13.5188 4.25 12 4.25Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M6.61514 3.69795C6.85211 4.03768 6.7688 4.50519 6.42907 4.74216C5.71479 5.24038 5.25 6.06578 5.25 6.99968C5.25 7.99912 5.78272 8.87489 6.58328 9.35729C6.93807 9.57107 7.05237 10.032 6.83859 10.3868C6.62481 10.7415 6.1639 10.8558 5.80912 10.6421C4.57688 9.89956 3.75 8.54654 3.75 6.99968C3.75 5.55478 4.47171 4.27861 5.57093 3.51188C5.91066 3.27491 6.37817 3.35821 6.61514 3.69795Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M17.3849 3.69795C17.6219 3.35821 18.0894 3.27491 18.4291 3.51188C19.5284 4.27861 20.2501 5.55478 20.2501 6.99968C20.2501 8.44452 19.5284 9.72077 18.4291 10.4875C18.0894 10.7244 17.6219 10.6411 17.3849 10.3014C17.148 9.96165 17.2313 9.49414 17.571 9.25718C18.2853 8.75899 18.7501 7.93354 18.7501 6.99968C18.7501 6.06578 18.2853 5.24038 17.571 4.74216C17.2313 4.50519 17.148 4.03768 17.3849 3.69795Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M11.9726 13.75H12.0274C12.9361 13.75 13.6562 13.75 14.2397 13.7898C14.8357 13.8305 15.3436 13.9152 15.8178 14.1116C16.9816 14.5937 17.9063 15.5184 18.3884 16.6822C18.5848 17.1564 18.6695 17.6643 18.7102 18.2603C18.75 18.8438 18.75 19.5639 18.75 20.4726V21.5C18.75 21.9142 18.4142 22.25 18 22.25H6C5.58579 22.25 5.25 21.9142 5.25 21.5L5.25 20.4726C5.25 19.5639 5.24999 18.8438 5.2898 18.2603C5.33047 17.6643 5.41518 17.1564 5.61159 16.6822C6.09365 15.5184 7.01836 14.5937 8.18223 14.1116M11.9726 13.75C11.0639 13.75 10.3438 13.75 9.76033 13.7898L11.9726 13.75ZM9.76033 13.7898C9.16431 13.8305 8.65639 13.9152 8.18223 14.1116L9.76033 13.7898ZM9.86244 15.2863C9.34279 15.3218 9.0172 15.3893 8.75627 15.4974C7.95995 15.8272 7.32724 16.4599 6.99742 17.2563C6.88933 17.5172 6.82178 17.8428 6.78632 18.3624C6.75041 18.8888 6.75 19.5579 6.75 20.5V20.75H17.25V20.5C17.25 19.5579 17.2496 18.8888 17.2137 18.3624C17.1782 17.8428 17.1107 17.5172 17.0026 17.2563C16.6728 16.46 16.0401 15.8272 15.2438 15.4974C14.9828 15.3893 14.6572 15.3218 14.1376 15.2863C13.6112 15.2504 12.9421 15.25 12 15.25C11.0579 15.25 10.3888 15.2504 9.86244 15.2863Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M19.1473 14.5952C19.3354 14.2261 19.787 14.0794 20.1561 14.2675C21.0499 14.7229 21.7765 15.4495 22.2319 16.3433C22.5128 16.8945 22.6344 17.4981 22.6927 18.2115C22.7496 18.9091 22.7496 19.7746 22.7496 20.8668V21.4998C22.7496 21.914 22.4139 22.2498 21.9996 22.2498C21.5854 22.2498 21.2496 21.914 21.2496 21.4998V20.8998C21.2496 19.7673 21.2491 18.963 21.1976 18.3336C21.1469 17.713 21.0506 17.3288 20.8954 17.0243M19.1473 14.5952C18.9593 14.9643 19.106 15.4159 19.4751 15.604L19.1473 14.5952ZM19.4751 15.604C19.7897 15.7643 20.0741 15.9737 20.3185 16.2226C20.5491 16.4574 20.7442 16.7274 20.8954 17.0243"
											fill="#7C7CA6"
										/>
										<path
											d="M20.3185 16.2226L19.1473 14.5952L19.4751 15.604C19.7897 15.7643 20.0741 15.9737 20.3185 16.2226Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M4.85229 14.5952C5.04034 14.9643 4.8936 15.4159 4.52453 15.604C3.91302 15.9155 3.41582 16.4128 3.10423 17.0243C2.94909 17.3288 2.85271 17.713 2.80201 18.3336C2.75058 18.963 2.75 19.7673 2.75 20.8998V21.4998C2.75 21.914 2.41421 22.2498 2 22.2498C1.58579 22.2498 1.25 21.914 1.25 21.4998L1.25 20.8668C1.24999 19.7746 1.24999 18.9091 1.30699 18.2115C1.36527 17.4981 1.48688 16.8945 1.76772 16.3433C2.22311 15.4495 2.94977 14.7229 3.84354 14.2675C4.2126 14.0794 4.66424 14.2261 4.85229 14.5952Z"
											fill="#7C7CA6"
										/>
									</svg>
								}
							/>
						</li> */}
						<li>
							<WalletNavLink
								title={String(L.translate('Menu.WalletsLayout.derivatives_account'))}
								to={ROUTES.wallet.derivatives}
								icon={
									<svg
										width="24"
										height="25"
										viewBox="0 0 24 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M5 3.25C4.86193 3.25 4.75 3.36193 4.75 3.5V21.5C4.75 21.6381 4.86193 21.75 5 21.75H19C19.1381 21.75 19.25 21.6381 19.25 21.5V3.5C19.25 3.36193 19.1381 3.25 19 3.25H5ZM3.25 3.5C3.25 2.5335 4.0335 1.75 5 1.75H19C19.9665 1.75 20.75 2.5335 20.75 3.5V21.5C20.75 22.4665 19.9665 23.25 19 23.25H5C4.0335 23.25 3.25 22.4665 3.25 21.5V3.5Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M7.25 2.5C7.25 2.08579 7.58579 1.75 8 1.75H12.5C12.9142 1.75 13.25 2.08579 13.25 2.5V10.5C13.25 10.7953 13.0767 11.0632 12.8072 11.1842C12.5378 11.3052 12.2225 11.2568 12.0017 11.0606L10.25 9.50347L8.49827 11.0606C8.27753 11.2568 7.96218 11.3052 7.69276 11.1842C7.42333 11.0632 7.25 10.7953 7.25 10.5V2.5ZM8.75 3.25V8.82987L9.75173 7.93944C10.0359 7.68685 10.4641 7.68685 10.7483 7.93944L11.75 8.82987V3.25H8.75Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M7.25 14.5C7.25 14.0858 7.58579 13.75 8 13.75H13C13.4142 13.75 13.75 14.0858 13.75 14.5C13.75 14.9142 13.4142 15.25 13 15.25H8C7.58579 15.25 7.25 14.9142 7.25 14.5Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M7.25 17.5C7.25 17.0858 7.58579 16.75 8 16.75H16C16.4142 16.75 16.75 17.0858 16.75 17.5C16.75 17.9142 16.4142 18.25 16 18.25H8C7.58579 18.25 7.25 17.9142 7.25 17.5Z"
											fill="#7C7CA6"
										/>
									</svg>
								}
							/>
						</li>
						<li>
							<WalletNavLink
								title={String(L.translate('Menu.WalletsLayout.transaction_history'))}
								to="/wallet/transaction-history/crypto"
								icon={
									<svg
										width="24"
										height="25"
										viewBox="0 0 24 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M2.25 5C2.25 3.75736 3.25736 2.75 4.5 2.75H19.5C20.7427 2.75 21.75 3.75737 21.75 5V12.5C21.75 12.9142 21.4142 13.25 21 13.25C20.5858 13.25 20.25 12.9142 20.25 12.5V5C20.25 4.58578 19.9142 4.25 19.5 4.25H4.5C4.08579 4.25 3.75 4.58579 3.75 5V20C3.75 20.4142 4.08578 20.75 4.5 20.75H12C12.4142 20.75 12.75 21.0858 12.75 21.5C12.75 21.9142 12.4142 22.25 12 22.25H4.5C3.25737 22.25 2.25 21.2427 2.25 20V5Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M16 14.25C14.7574 14.25 13.75 15.2574 13.75 16.5C13.75 17.7426 14.7574 18.75 16 18.75C17.2426 18.75 18.25 17.7426 18.25 16.5C18.25 15.2574 17.2426 14.25 16 14.25ZM12.25 16.5C12.25 14.4289 13.9289 12.75 16 12.75C18.0711 12.75 19.75 14.4289 19.75 16.5C19.75 18.5711 18.0711 20.25 16 20.25C13.9289 20.25 12.25 18.5711 12.25 16.5Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M17.9144 18.0315C18.1731 17.7081 18.6451 17.6556 18.9685 17.9144L21.4685 19.9144C21.792 20.1731 21.8444 20.6451 21.5857 20.9685C21.3269 21.292 20.855 21.3444 20.5315 21.0857L18.0315 19.0857C17.7081 18.8269 17.6556 18.355 17.9144 18.0315Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M6.25 8.5C6.25 8.08579 6.58579 7.75 7 7.75H17C17.4142 7.75 17.75 8.08579 17.75 8.5C17.75 8.91421 17.4142 9.25 17 9.25H7C6.58579 9.25 6.25 8.91421 6.25 8.5Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M6.25 12.5C6.25 12.0858 6.58579 11.75 7 11.75H11C11.4142 11.75 11.75 12.0858 11.75 12.5C11.75 12.9142 11.4142 13.25 11 13.25H7C6.58579 13.25 6.25 12.9142 6.25 12.5Z"
											fill="#7C7CA6"
										/>
									</svg>
								}
							/>
						</li>
					</ul>
				</nav>
			</div>
			<div className={`sidebar-nav-item ${depositActive ? 'active' : ''}`}>
				<button type="button" className="sidebar-nav-item__button" onClick={toggleDeposit}>
					{String(L.translate('Menu.WalletsLayout.deposit_withdrawal'))}
					{arrow}
				</button>
				<nav className="sidebar-nav-item__list">
					<ul>
						<li>
							<WalletNavLink
								title={String(L.translate('Menu.WalletsLayout.deposit'))}
								to={ROUTES.wallet.depositCrypto}
								icon={
									<svg
										width="24"
										height="25"
										viewBox="0 0 24 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M14.75 17.5C14.75 17.0858 15.0858 16.75 15.5 16.75H21.5C21.9142 16.75 22.25 17.0858 22.25 17.5C22.25 17.9142 21.9142 18.25 21.5 18.25H15.5C15.0858 18.25 14.75 17.9142 14.75 17.5Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M1.75 5.5C1.75 4.25736 2.75736 3.25 4 3.25H20C21.2427 3.25 22.25 4.25737 22.25 5.5V13.5C22.25 13.9142 21.9142 14.25 21.5 14.25C21.0858 14.25 20.75 13.9142 20.75 13.5V5.5C20.75 5.08578 20.4142 4.75 20 4.75H4C3.58579 4.75 3.25 5.08579 3.25 5.5V19.5C3.25 19.9142 3.58578 20.25 4 20.25H14.2353C14.6495 20.25 14.9853 20.5858 14.9853 21C14.9853 21.4142 14.6495 21.75 14.2353 21.75H4C2.75737 21.75 1.75 20.7427 1.75 19.5V5.5Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M18.5301 14.4696C18.823 14.7625 18.823 15.2374 18.5302 15.5303L16.5606 17.5L18.5303 19.4697C18.8232 19.7626 18.8232 20.2374 18.5303 20.5303C18.2374 20.8232 17.7626 20.8232 17.4697 20.5303L14.9697 18.0303C14.6768 17.7374 14.6768 17.2626 14.9696 16.9697L17.4694 14.4697C17.7623 14.1768 18.2372 14.1768 18.5301 14.4696Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M9.84977 6.15039C9.4565 6.15039 9.1377 6.4692 9.1377 6.86246V7.11591H8.88452C7.29143 7.11591 6 8.40734 6 10.0004C6 11.592 7.28899 12.8825 8.88001 12.8849L8.88466 12.885H9.1377V15.8057H7.19501C6.80178 15.8057 6.48291 16.1245 6.48291 16.5178C6.48291 16.9112 6.80197 17.2299 7.1951 17.2299H9.1377V17.4833C9.1377 17.8766 9.4565 18.1954 9.84977 18.1954C10.243 18.1954 10.5618 17.8766 10.5618 17.4833V17.2299H10.8157C12.4088 17.2299 13.7002 15.9384 13.7002 14.3453C13.7002 12.7523 12.4088 11.4608 10.8157 11.4608H10.5618V8.54005H12.5052C12.8985 8.54005 13.2173 8.22128 13.2173 7.82798C13.2173 7.43457 12.8983 7.11591 12.5052 7.11591H10.5618V6.86246C10.5618 6.4692 10.243 6.15039 9.84977 6.15039ZM7.42415 10.0004C7.42415 9.19388 8.07797 8.54005 8.88452 8.54005H9.1377V11.4608H8.88818L8.88452 11.4608C8.07797 11.4608 7.42415 10.807 7.42415 10.0004ZM10.5618 15.8057V12.885H10.8157C11.6223 12.885 12.2761 13.5388 12.2761 14.3453C12.2761 15.1519 11.6223 15.8057 10.8157 15.8057H10.5618Z"
											fill="#7C7CA6"
										/>
									</svg>
								}
							/>
						</li>
						<li>
							<WalletNavLink
								title={String(L.translate('Menu.WalletsLayout.withdrawal'))}
								to={ROUTES.wallet.withdrawCrypto}
								icon={
									<svg
										width="24"
										height="25"
										viewBox="0 0 24 25"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M14.75 17.5C14.75 17.0858 15.0858 16.75 15.5 16.75H21.5C21.9142 16.75 22.25 17.0858 22.25 17.5C22.25 17.9142 21.9142 18.25 21.5 18.25H15.5C15.0858 18.25 14.75 17.9142 14.75 17.5Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M18.4699 14.4696C18.7628 14.1768 19.2377 14.1768 19.5306 14.4697L22.0304 16.9697C22.3232 17.2626 22.3232 17.7374 22.0303 18.0303L19.5303 20.5303C19.2374 20.8232 18.7626 20.8232 18.4697 20.5303C18.1768 20.2374 18.1768 19.7626 18.4697 19.4697L20.4394 17.5L18.4698 15.5303C18.177 15.2374 18.177 14.7625 18.4699 14.4696Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M1.75 5.5C1.75 4.25736 2.75736 3.25 4 3.25H20C21.2427 3.25 22.25 4.25737 22.25 5.5V13.5C22.25 13.9142 21.9142 14.25 21.5 14.25C21.0858 14.25 20.75 13.9142 20.75 13.5V5.5C20.75 5.08578 20.4142 4.75 20 4.75H4C3.58579 4.75 3.25 5.08579 3.25 5.5V19.5C3.25 19.9142 3.58578 20.25 4 20.25H14.2353C14.6495 20.25 14.9853 20.5858 14.9853 21C14.9853 21.4142 14.6495 21.75 14.2353 21.75H4C2.75737 21.75 1.75 20.7427 1.75 19.5V5.5Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M9.50016 6.15039C9.10689 6.15039 8.78809 6.4692 8.78809 6.86246V7.11591H8.53491C6.94183 7.11591 5.65039 8.40734 5.65039 10.0004C5.65039 11.592 6.93938 12.8825 8.5304 12.8849L8.53505 12.885H8.78809V15.8057H6.8454C6.45217 15.8057 6.1333 16.1245 6.1333 16.5178C6.1333 16.9112 6.45236 17.2299 6.84549 17.2299H8.78809V17.4833C8.78809 17.8766 9.10689 18.1954 9.50016 18.1954C9.89343 18.1954 10.2122 17.8766 10.2122 17.4833V17.2299H10.4661C12.0592 17.2299 13.3506 15.9384 13.3506 14.3453C13.3506 12.7523 12.0592 11.4608 10.4661 11.4608H10.2122V8.54005H12.1556C12.5489 8.54005 12.8677 8.22128 12.8677 7.82798C12.8677 7.43457 12.5487 7.11591 12.1555 7.11591H10.2122V6.86246C10.2122 6.4692 9.89343 6.15039 9.50016 6.15039ZM7.07454 10.0004C7.07454 9.19388 7.72836 8.54005 8.53491 8.54005H8.78809V11.4608H8.53857L8.53491 11.4608C7.72836 11.4608 7.07454 10.807 7.07454 10.0004ZM10.2122 15.8057V12.885H10.4661C11.2727 12.885 11.9265 13.5388 11.9265 14.3453C11.9265 15.1519 11.2727 15.8057 10.4661 15.8057H10.2122Z"
											fill="#7C7CA6"
										/>
									</svg>
								}
							/>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
};

export default WalletSidePanel;
