import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import L from 'i18n-react';
import { Link, NavLink } from 'react-router-dom';
import { setCurrentPair } from 'redux/reducers/tradingSettings/reducer';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { ROUTES } from 'routes/constants';
import DerivativeSection from './DerivativeSection';
/* eslint-disable no-debugger */
export const MainNav: FC = () => {
	const dispatch = useDispatch();
	const currentPair = useSelector(getCurrentPair);
	const isAuth = useSelector(getAuthIsAuthenticated);

	if (currentPair?.pair.startsWith('undefined')) {
		dispatch(setCurrentPair({ pair: 'btc_usdt', id: 1 }));
	}

	const transformCurrrentPair = currentPair?.pair?.toUpperCase();
	return (
		<div className="header__nav">
			<nav>
				<ul className="flex flex-m">
					<li>
						<NavLink to="/convert">{String(L.translate('Header.buy_crypto'))}</NavLink>
					</li>
					<li>
						<button type="button">
							{String(L.translate('Header.trade'))}
							<svg
								width="10"
								height="6"
								viewBox="0 0 10 6"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M10 0.333328H1H0L5 5.33333L10 0.333328Z" fill="#1C1C29" />
							</svg>
						</button>
						<div className="header__nav-drop header__nav-drop--trade">
							<div className="nav-drop-block">
								<div className="nav-drop-block-item">
									<div className="nav-drop-block-item__title">
										<p>{String(L.translate('Menu.spot_section'))}</p>
									</div>
									<NavLink
										to={`/spot/${String(transformCurrrentPair)}`}
										className="nav-drop-block-item__elemet"
									>
										<div className="nav-drop-block-item__icon">
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M9.5 2.25C9.91421 2.25 10.25 2.58579 10.25 3V21C10.25 21.4142 9.91421 21.75 9.5 21.75C9.08579 21.75 8.75 21.4142 8.75 21V3C8.75 2.58579 9.08579 2.25 9.5 2.25Z"
													fill="#FF57D4"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M10.0303 2.41941C10.3232 2.71231 10.3232 3.18718 10.0303 3.48008L4.03033 9.48008C3.73744 9.77297 3.26256 9.77297 2.96967 9.48008C2.67678 9.18719 2.67678 8.71231 2.96967 8.41942L8.96967 2.41942C9.26256 2.12652 9.73744 2.12652 10.0303 2.41941Z"
													fill="#FF57D4"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M14.5 2.30025C14.9142 2.30025 15.25 2.63604 15.25 3.05025V21.0502C15.25 21.4645 14.9142 21.8002 14.5 21.8002C14.0858 21.8002 13.75 21.4645 13.75 21.0502V3.05025C13.75 2.63604 14.0858 2.30025 14.5 2.30025Z"
													fill="#FF57D4"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M21.0303 14.5199C21.3232 14.8128 21.3232 15.2877 21.0303 15.5806L15.0303 21.5806C14.7374 21.8735 14.2626 21.8735 13.9697 21.5806C13.6768 21.2877 13.6768 20.8128 13.9697 20.5199L19.9697 14.5199C20.2626 14.227 20.7374 14.227 21.0303 14.5199Z"
													fill="#FF57D4"
												/>
											</svg>
										</div>
										<div className="nav-drop-block-item__info">
											<p>{String(L.translate('Menu.Spot.title'))}</p>
											<span>{String(L.translate('Menu.Spot.description'))}</span>
										</div>
									</NavLink>
									<NavLink
										to={`/MarginTrading/${String(transformCurrrentPair)}?type=isolated`}
										className="nav-drop-block-item__elemet"
									>
										<div className="nav-drop-block-item__icon">
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M5.5 3.75C4.5335 3.75 3.75 4.5335 3.75 5.5C3.75 6.4665 4.5335 7.25 5.5 7.25C6.4665 7.25 7.25 6.4665 7.25 5.5C7.25 4.5335 6.4665 3.75 5.5 3.75ZM2.25 5.5C2.25 3.70507 3.70507 2.25 5.5 2.25C7.29493 2.25 8.75 3.70507 8.75 5.5C8.75 7.29493 7.29493 8.75 5.5 8.75C3.70507 8.75 2.25 7.29493 2.25 5.5Z"
													fill="#FF57D4"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M18.5 16.75C17.5335 16.75 16.75 17.5335 16.75 18.5C16.75 19.4665 17.5335 20.25 18.5 20.25C19.4665 20.25 20.25 19.4665 20.25 18.5C20.25 17.5335 19.4665 16.75 18.5 16.75ZM15.25 18.5C15.25 16.7051 16.7051 15.25 18.5 15.25C20.2949 15.25 21.75 16.7051 21.75 18.5C21.75 20.2949 20.2949 21.75 18.5 21.75C16.7051 21.75 15.25 20.2949 15.25 18.5Z"
													fill="#FF57D4"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M21.5303 2.46967C21.8232 2.76256 21.8232 3.23744 21.5303 3.53033L3.53033 21.5303C3.23744 21.8232 2.76256 21.8232 2.46967 21.5303C2.17678 21.2374 2.17678 20.7626 2.46967 20.4697L20.4697 2.46967C20.7626 2.17678 21.2374 2.17678 21.5303 2.46967Z"
													fill="#FF57D4"
												/>
											</svg>
										</div>
										<div className="nav-drop-block-item__info">
											<p>{String(L.translate('Menu.MarginTrading.title'))}</p>
											<span>{String(L.translate('Menu.MarginTrading.description'))}</span>
										</div>
									</NavLink>
								</div>
								<DerivativeSection />
								<div className="nav-drop-block-item">
									<div className="nav-drop-block-item__elemet">
										<div className="nav-drop-block-item__icon">
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M4.66777 2.12656C4.80151 1.89363 5.0496 1.75 5.31819 1.75H18.6818C18.9504 1.75 19.1985 1.89363 19.3322 2.12656L23.1504 8.77656C23.308 9.05096 23.2764 9.39473 23.0714 9.6358L12.5714 21.9858C12.4289 22.1534 12.22 22.25 12 22.25C11.78 22.25 11.5711 22.1534 11.4286 21.9858L0.928609 9.6358C0.723651 9.39473 0.692037 9.05096 0.849592 8.77656L4.66777 2.12656ZM5.7524 3.25L2.41305 9.06604L12 20.3421L21.587 9.06604L18.2476 3.25H5.7524Z"
													fill="#FF57D4"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M5.06926 1.79247C5.46001 1.65505 5.88818 1.86042 6.0256 2.25117L11.9999 19.2393L17.9742 2.25117C18.1116 1.86042 18.5398 1.65505 18.9305 1.79247C19.3213 1.92989 19.5267 2.35805 19.3892 2.74881L12.7074 21.7488C12.6018 22.0491 12.3182 22.25 11.9999 22.25C11.6816 22.25 11.398 22.0491 11.2924 21.7488L4.61055 2.74881C4.47313 2.35805 4.6785 1.92989 5.06926 1.79247Z"
													fill="#FF57D4"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M0.75 9.14999C0.75 8.73578 1.08579 8.39999 1.5 8.39999H22.5C22.9142 8.39999 23.25 8.73578 23.25 9.14999C23.25 9.56421 22.9142 9.89999 22.5 9.89999H1.5C1.08579 9.89999 0.75 9.56421 0.75 9.14999Z"
													fill="#FF57D4"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M11.9999 1.75C12.2545 1.75 12.4917 1.87918 12.6299 2.09306L16.9253 8.74306C17.1501 9.091 17.0502 9.55525 16.7023 9.78C16.3543 10.0047 15.8901 9.90488 15.6653 9.55694L11.9999 3.88227L8.33444 9.55694C8.10969 9.90488 7.64544 10.0047 7.2975 9.78C6.94956 9.55525 6.84969 9.091 7.07443 8.74306L11.3699 2.09306C11.508 1.87918 11.7453 1.75 11.9999 1.75Z"
													fill="#FF57D4"
												/>
											</svg>
										</div>
										<div className="nav-drop-block-item__info">
											<p>{String(L.translate('Menu.scalping_tool'))}</p>

											<button
												type="button"
												className="button button--size2 button--regular button--type2"
											>
												<svg
													width="21"
													height="20"
													viewBox="0 0 21 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M4.86739 1.70022C4.98578 1.54763 5.16806 1.45834 5.3612 1.45834H15.6389C15.8324 1.45834 16.015 1.54793 16.1333 1.70095L18.2446 4.43053C18.4558 4.70357 18.4057 5.0961 18.1327 5.3073C17.8596 5.51849 17.4671 5.46835 17.2559 5.19532L15.3322 2.70834H5.66734L3.73724 5.19605C3.52565 5.46877 3.13304 5.51832 2.86032 5.30673C2.5876 5.09514 2.53804 4.70253 2.74963 4.42981L4.86739 1.70022Z"
														fill="white"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M3.83333 5.20834C3.71827 5.20834 3.625 5.30161 3.625 5.41668V16.6667C3.625 17.0119 3.90482 17.2917 4.25 17.2917H16.75C17.0952 17.2917 17.375 17.0119 17.375 16.6667V5.41668C17.375 5.3016 17.2817 5.20834 17.1667 5.20834H3.83333ZM2.375 5.41668C2.375 4.61124 3.02792 3.95834 3.83333 3.95834H17.1667C17.9721 3.95834 18.625 4.61125 18.625 5.41668V16.6667C18.625 17.7022 17.7856 18.5417 16.75 18.5417H4.25C3.21447 18.5417 2.375 17.7022 2.375 16.6667V5.41668Z"
														fill="white"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M6.72481 10.8081C6.96888 10.564 7.36461 10.564 7.60869 10.8081L10.5001 13.6995L13.3915 10.8081C13.6356 10.564 14.0313 10.564 14.2754 10.8081C14.5194 11.0521 14.5194 11.4479 14.2754 11.6919L10.942 15.0253C10.6979 15.2694 10.3022 15.2694 10.0581 15.0253L6.72481 11.6919C6.48073 11.4479 6.48073 11.0521 6.72481 10.8081Z"
														fill="white"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M10.4966 7.29166C10.8418 7.29166 11.1216 7.57148 11.1216 7.91666V14.5833C11.1216 14.9285 10.8418 15.2083 10.4966 15.2083C10.1514 15.2083 9.87158 14.9285 9.87158 14.5833V7.91666C9.87158 7.57148 10.1514 7.29166 10.4966 7.29166Z"
														fill="white"
													/>
												</svg>
												{String(L.translate('Menu.download'))}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li>
						<button type="button">
							{String(L.translate('Menu.markets'))}
							<svg
								width="10"
								height="6"
								viewBox="0 0 10 6"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M10 0.333328H1H0L5 5.33333L10 0.333328Z" fill="#1C1C29" />
							</svg>
						</button>
						<div className="header__nav-drop">
							<ul>
								<li>
									<Link to={ROUTES.pairs.spot}>{String(L.translate('Menu.market_price'))}</Link>
								</li>
								<li>
									<Link to="/analytics">{String(L.translate('Menu.value_analytics'))}</Link>
								</li>
							</ul>
						</div>
					</li>
					<li>
						<button type="button">
							{String(L.translate('Menu.download_software'))}
							<svg
								width="10"
								height="6"
								viewBox="0 0 10 6"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M10 0.333328H1H0L5 5.33333L10 0.333328Z" fill="#1C1C29" />
							</svg>
						</button>
						<div className="header__nav-drop">
							<ul>
								<li>
									<NavLink to="">Trade</NavLink>
								</li>
								<li>
									<NavLink to="">Trade Trade</NavLink>
								</li>
								<li>
									<NavLink to="">Trade</NavLink>
								</li>
								<li>
									<NavLink to="">Trade</NavLink>
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</nav>
		</div>
	);
};
