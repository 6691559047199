import React from 'react';
import { Auth } from 'layouts/Auth';
import { Redirect, Route, Switch } from 'react-router';
import AccountSettingsNavbar from 'components/AccountSettings/AccountSettingsNavbar';
import AccountSettingsTwoFa from 'components/AccountSettings/AccountSettingsTwoFa';
import AccountSettingsGeneral from 'components/AccountSettings/AccountSettingsGeneral';
import AccountSettingsPayments from 'components/AccountSettings/AccountSettingsPayments';
import { ROUTES } from 'routes/constants';

const AccountSettings = () => {
	return (
		<Auth title="General">
			<section className="setting-section section-gradient">
				<div className="page-wrap">
					<AccountSettingsNavbar />
					<Switch>
						<Route exact path={ROUTES.accountSettings.general} component={AccountSettingsGeneral} />
						<Route exact path={ROUTES.accountSettings.twoFa} component={AccountSettingsTwoFa} />
						<Route
							exact
							path={ROUTES.accountSettings.payments}
							component={AccountSettingsPayments}
						/>
						<Redirect to={ROUTES.accountSettings.general} />
					</Switch>
				</div>
			</section>
		</Auth>
	);
};

export default AccountSettings;
