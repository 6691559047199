import { FC, useState, useEffect, useReducer, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import L from 'i18n-react';
import { Formik, Form } from 'formik';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import { api } from 'services';
import yup from 'services/utils/capsLockValidate';
import { notificationContainer } from 'services/utils/notificationContainer';
import RepayContentCross from './RepayContentCross';
import RepayContentIsolated from './RepayContentIsolated';
import { IRepayModalFormIsolated, RepayInfo } from './RepayContentIsolated/types';
import { IRepayModal } from './types';

const RepayModal: FC<IRepayModal> = ({ onClose, item, assetId, assetPairId, mode, refetch }) => {
	const dispatch = useDispatch();
	const [isFetching, setIsFetching] = useState<boolean>(false);
	const [state, setState] = useReducer(
		(_state: RepayInfo, newState: RepayInfo) => ({
			..._state,
			...newState,
		}),
		{},
	);
	const isAuth = useSelector(getAuthIsAuthenticated);
	const history = useHistory();
	const amountMin = item?.pair?.setting?.amount_min || 0;

	const handleClose = () => {
		onClose && onClose();
	};

	const fetchRepayInfo: (params: Pick<IRepayModalFormIsolated, 'assetId' | 'assetPairId'>) => void =
		useCallback(({ assetId: _assetId, assetPairId: _assetPairId }) => {
			setIsFetching(true);
			api.marginWallets
				.getRepayIsolated({
					asset_id: _assetId,
					asset_pair_id: _assetPairId,
				})
				.then((response) => {
					const { availableAmount, borrowed, interestAmount, repayAmount } = response;
					setState({
						avaliableBalance: availableAmount,
						interestA: interestAmount,
						borrowedB: borrowed,
						totalDebt: repayAmount,
					});
					setIsFetching(false);
				})
				.catch((err) => {
					setIsFetching(false);
					notificationContainer(
						String(L.translate('Wallets.MarginAccount.messages.failed_to_fetch_repay_info')),
						'error',
					);
				});
		}, []);

	useEffect(() => {
		if (assetPairId || assetPairId === 0) {
			fetchRepayInfo({ assetId, assetPairId });
		}
	}, [assetId, assetPairId, fetchRepayInfo]);

	useEffect(() => {
		dispatch(getAssetsRequest());
	}, [dispatch]);

	const initialValues = {
		asset_pair_id: assetPairId,
		asset_id: assetId,
		amount: '',
		percent: 0,
	};

	const validationSchema = yup.object().shape({
		amount: yup
			.number()
			.min(
				Number(amountMin),
				String(
					L.translate('Wallets.MarginAccount.messages.amount_not_less_than', {
						minAmount: String(Number(amountMin)),
					}),
				),
			)
			.max(
				Number(state.totalDebt),
				String(
					L.translate('Wallets.MarginAccount.messages.amount_not_greater_than', {
						maxAmount: String(Number(state.totalDebt)),
					}),
				),
			)
			.typeError(String(L.translate('Wallets.MarginAccount.messages.amount_must_be_a_number')))
			.required(String(L.translate('Wallets.MarginAccount.messages.amount_is_required'))),
	});

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					validateOnBlur
					onSubmit={async (values, { resetForm }) => {
						if (!isAuth) {
							history.push('/login');
							return;
						}
						// eslint-disable-next-line @typescript-eslint/naming-convention
						const { asset_pair_id, asset_id } = values;
						try {
							if (mode === 'isolated') {
								if (!asset_pair_id) {
									throw new Error(
										String(L.translate('Wallets.MarginAccount.messages.asset_pair_id_is_required')),
									);
								}
								await api.marginWallets.postRepayIsolated({
									asset_pair_id,
									asset_id,
									amount: Number(values.amount),
								});
							} else {
								await api.marginWallets.payBorrowCross({
									asset_id,
									amount: Number(values.amount),
								});
							}
							notificationContainer(
								String(L.translate('Wallets.MarginAccount.messages.repay_was_confirmed')),
								'success',
							);
							resetForm();
							onClose && onClose();
							refetch && refetch();
						} catch (error) {
							notificationContainer(
								String(L.translate('Wallets.MarginAccount.messages.failed_to_confirm_repay')),
								'error',
							);
						}
					}}
				>
					<Form>
						{mode === 'isolated' ? (
							assetPairId && (
								<RepayContentIsolated
									onClose={handleClose}
									amountMin={amountMin}
									assetPairId={assetPairId}
									assetId={assetId}
									mode={mode}
									repayInfo={state}
									isFetching={isFetching}
									refetch={fetchRepayInfo}
								/>
							)
						) : (
							<RepayContentCross onClose={handleClose} assetId={assetId} mode={mode} />
						)}
					</Form>
				</Formik>
			</div>
		</div>
	);
};

export default RepayModal;
