/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import L from 'i18n-react';
import { NavLink } from 'react-router-dom';

const getUrl: (history?: string) => string = (history) => {
	return `/PerpetualTrading${history ? `?history=${history}` : ''}`;
};

const DerivativeSection: FC = () => {
	return (
		<div className="nav-drop-block-item">
			<div className="nav-drop-block-item__title">
				<p>{String(L.translate('Menu.derivative_section'))}</p>
			</div>
			<div className="nav-drop-block-item__elemet-wrap">
				<NavLink to={getUrl('positions')} className="nav-drop-block-item__elemet">
					<div className="nav-drop-block-item__icon">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M6.5 3.25C5.25736 3.25 4.25 4.25736 4.25 5.5H3.5H2.75C2.75 3.42893 4.42893 1.75 6.5 1.75H17.5C17.9142 1.75 18.25 2.08579 18.25 2.5V15.5C18.25 15.9142 17.9142 16.25 17.5 16.25H6.5C5.26293 16.25 4.25 17.2576 4.25 18.5C4.25 18.9142 3.91421 19.25 3.5 19.25C3.08579 19.25 2.75 18.9142 2.75 18.5V5.5C2.75 5.49993 2.75 5.5 3.5 5.5C4.25 5.5 4.25 5.49993 4.25 5.5V15.5017C4.87779 15.0296 5.65755 14.75 6.5 14.75H16.75V3.25H6.5Z"
								fill="#FF57D4"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.5 2.75C20.9142 2.75 21.25 3.08579 21.25 3.5V21.5C21.25 21.9142 20.9142 22.25 20.5 22.25H6.5C4.42893 22.25 2.75 20.5711 2.75 18.5C2.75 16.4289 4.42893 14.75 6.5 14.75H17.4995C17.4996 14.75 17.5 14.75 17.5 15.5C17.5 16.25 17.5 16.25 17.4999 16.25H6.5C5.25736 16.25 4.25 17.2574 4.25 18.5C4.25 19.7426 5.25736 20.75 6.5 20.75H19.75V3.5C19.75 3.08579 20.0858 2.75 20.5 2.75ZM18.25 15.5C18.25 15.9142 17.9141 16.25 17.4999 16.25L17.5 15.5L17.4995 14.75C17.9137 14.75 18.25 15.0858 18.25 15.5Z"
								fill="#FF57D4"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M6.25 18.5C6.25 18.0858 6.58579 17.75 7 17.75H17C17.4142 17.75 17.75 18.0858 17.75 18.5C17.75 18.9142 17.4142 19.25 17 19.25H7C6.58579 19.25 6.25 18.9142 6.25 18.5Z"
								fill="#FF57D4"
							/>
						</svg>
					</div>
					<div className="nav-drop-block-item__info">
						<p>{String(L.translate('Menu.Perpetual.title'))}</p>
						<span>{String(L.translate('Menu.Perpetual.description'))}</span>
					</div>
					<div className="nav-drop-block-item__elemet-arrow">
						<svg
							width="10"
							height="6"
							viewBox="0 0 10 6"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M0 5.16602L9 5.16602L10 5.16602L5 0.166015L0 5.16602Z" fill="#1C1C29" />
						</svg>
					</div>
				</NavLink>
				<div className="nav-drop-block-item__elemet-drop">
					<NavLink to={getUrl('positions')} href="" className="nav-drop-block-item__elemet">
						<div className="nav-drop-block-item__icon">
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.5 4.25C3.08579 4.25 2.75 4.58579 2.75 5V20C2.75 20.4142 3.08579 20.75 3.5 20.75H20.5C20.9142 20.75 21.25 20.4142 21.25 20V5C21.25 4.58579 20.9142 4.25 20.5 4.25H3.5ZM1.25 5C1.25 3.75736 2.25736 2.75 3.5 2.75H20.5C21.7426 2.75 22.75 3.75736 22.75 5V20C22.75 21.2426 21.7426 22.25 20.5 22.25H3.5C2.25736 22.25 1.25 21.2426 1.25 20V5Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M1.25 7.5C1.25 7.08579 1.58579 6.75 2 6.75H22C22.4142 6.75 22.75 7.08579 22.75 7.5C22.75 7.91421 22.4142 8.25 22 8.25H2C1.58579 8.25 1.25 7.91421 1.25 7.5Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M9.25 12.5C9.25 12.0858 9.58579 11.75 10 11.75H18C18.4142 11.75 18.75 12.0858 18.75 12.5C18.75 12.9142 18.4142 13.25 18 13.25H10C9.58579 13.25 9.25 12.9142 9.25 12.5Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M9.25 16.5C9.25 16.0858 9.58579 15.75 10 15.75H18C18.4142 15.75 18.75 16.0858 18.75 16.5C18.75 16.9142 18.4142 17.25 18 17.25H10C9.58579 17.25 9.25 16.9142 9.25 16.5Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M5.25 12.5C5.25 12.0858 5.58579 11.75 6 11.75H7C7.41421 11.75 7.75 12.0858 7.75 12.5C7.75 12.9142 7.41421 13.25 7 13.25H6C5.58579 13.25 5.25 12.9142 5.25 12.5Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M5.25 16.5C5.25 16.0858 5.58579 15.75 6 15.75H7C7.41421 15.75 7.75 16.0858 7.75 16.5C7.75 16.9142 7.41421 17.25 7 17.25H6C5.58579 17.25 5.25 16.9142 5.25 16.5Z"
									fill="#FF57D4"
								/>
							</svg>
						</div>
						<div className="nav-drop-block-item__info">
							<p>{String(L.translate('Menu.Perpetual.positions.title'))}</p>
							<span>{String(L.translate('Menu.Perpetual.positions.description'))}</span>
						</div>
					</NavLink>
					<NavLink to={getUrl('closed')} className="nav-drop-block-item__elemet">
						<div className="nav-drop-block-item__icon">
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.25 2.5C3.25 2.08579 3.58579 1.75 4 1.75H15.5C15.7189 1.75 15.927 1.84567 16.0694 2.01191L20.5694 7.26191C20.686 7.39784 20.75 7.57097 20.75 7.75V22.5C20.75 22.9142 20.4142 23.25 20 23.25H4C3.58579 23.25 3.25 22.9142 3.25 22.5V2.5ZM4.75 3.25V21.75H19.25V8.02744L15.155 3.25H4.75Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12 8.75C9.65281 8.75 7.75 10.6528 7.75 13C7.75 15.3472 9.65281 17.25 12 17.25C14.3472 17.25 16.25 15.3472 16.25 13C16.25 11.7825 15.739 10.6855 14.9177 9.90974L14.9176 9.90973C14.1558 9.19009 13.1301 8.75 12 8.75ZM6.25 13C6.25 9.82439 8.82439 7.25 12 7.25C13.5281 7.25 14.9184 7.8471 15.9476 8.81926C17.0565 9.86659 17.75 11.3529 17.75 13C17.75 16.1756 15.1756 18.75 12 18.75C8.82439 18.75 6.25 16.1756 6.25 13Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7.96967 8.96967C8.26256 8.67678 8.73744 8.67678 9.03033 8.96967L16.0303 15.9697C16.3232 16.2626 16.3232 16.7374 16.0303 17.0303C15.7374 17.3232 15.2626 17.3232 14.9697 17.0303L7.96967 10.0303C7.67678 9.73744 7.67678 9.26256 7.96967 8.96967Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M17 12.25C17.4142 12.25 17.75 12.5858 17.75 13C17.75 16.1756 15.1756 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18C11.25 17.5858 11.5858 17.25 12 17.25C14.3472 17.25 16.25 15.3472 16.25 13C16.25 12.5858 16.5858 12.25 17 12.25Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12 8.75C9.65281 8.75 7.75 10.6528 7.75 13C7.75 13.4142 7.41421 13.75 7 13.75C6.58579 13.75 6.25 13.4142 6.25 13C6.25 9.82439 8.82439 7.25 12 7.25C12.4142 7.25 12.75 7.58579 12.75 8C12.75 8.41421 12.4142 8.75 12 8.75Z"
									fill="#FF57D4"
								/>
							</svg>
						</div>
						<div className="nav-drop-block-item__info">
							<p>{String(L.translate('Menu.Perpetual.closedHistory.title'))}</p>
							<span>{String(L.translate('Menu.Perpetual.closedHistory.description'))}</span>
						</div>
					</NavLink>
					<NavLink to={getUrl('myOrders')} className="nav-drop-block-item__elemet">
						<div className="nav-drop-block-item__icon">
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.25 3.5C3.25 2.5335 4.0335 1.75 5 1.75H15.5C15.7126 1.75 15.9152 1.84024 16.0575 1.99828L20.5575 6.99828C20.6814 7.13599 20.75 7.31472 20.75 7.5V21.5C20.75 22.4665 19.9665 23.25 19 23.25H5C4.03351 23.25 3.25 22.4665 3.25 21.5V3.5ZM5 3.25C4.86193 3.25 4.75 3.36193 4.75 3.5V21.5C4.75 21.6381 4.86192 21.75 5 21.75H19C19.1381 21.75 19.25 21.6381 19.25 21.5V7.7878L15.166 3.25H5Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12 11.25C11.3096 11.25 10.75 11.8096 10.75 12.5C10.75 13.1904 11.3096 13.75 12 13.75C12.6904 13.75 13.25 13.1904 13.25 12.5C13.25 11.8096 12.6904 11.25 12 11.25ZM9.25 12.5C9.25 10.9812 10.4812 9.75 12 9.75C13.5188 9.75 14.75 10.9812 14.75 12.5C14.75 14.0188 13.5188 15.25 12 15.25C10.4812 15.25 9.25 14.0188 9.25 12.5Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7.25 18.5C7.25 15.8766 9.37664 13.75 12 13.75C14.6234 13.75 16.75 15.8766 16.75 18.5C16.75 18.9142 16.4142 19.25 16 19.25C15.5858 19.25 15.25 18.9142 15.25 18.5C15.25 16.7051 13.7949 15.25 12 15.25C10.2051 15.25 8.75 16.7051 8.75 18.5C8.75 18.9142 8.41421 19.25 8 19.25C7.58579 19.25 7.25 18.9142 7.25 18.5Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M15 1.75C15.4142 1.75 15.75 2.08579 15.75 2.5V6.75H20C20.4142 6.75 20.75 7.08579 20.75 7.5C20.75 7.91421 20.4142 8.25 20 8.25H15C14.5858 8.25 14.25 7.91421 14.25 7.5V2.5C14.25 2.08579 14.5858 1.75 15 1.75Z"
									fill="#FF57D4"
								/>
							</svg>
						</div>
						<div className="nav-drop-block-item__info">
							<p>{String(L.translate('Menu.Perpetual.myOrders.title'))}</p>
							<span>{String(L.translate('Menu.Perpetual.myOrders.description'))}</span>
						</div>
					</NavLink>
					<NavLink to={getUrl('orderHistory')} className="nav-drop-block-item__elemet">
						<div className="nav-drop-block-item__icon">
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.25 3.5C3.25 2.5335 4.0335 1.75 5 1.75H15.5C15.7126 1.75 15.9152 1.84024 16.0575 1.99828L20.5575 6.99828C20.6814 7.13599 20.75 7.31472 20.75 7.5V12C20.75 12.4142 20.4142 12.75 20 12.75C19.5858 12.75 19.25 12.4142 19.25 12V7.7878L15.166 3.25H5C4.86193 3.25 4.75 3.36193 4.75 3.5V21.5C4.75 21.6381 4.86192 21.75 5 21.75H11C11.4142 21.75 11.75 22.0858 11.75 22.5C11.75 22.9142 11.4142 23.25 11 23.25H5C4.03351 23.25 3.25 22.4665 3.25 21.5V3.5Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M20.9749 15.9196C21.2955 16.1819 21.3428 16.6544 21.0805 16.975L16.5805 22.475C16.4538 22.6298 16.2705 22.7276 16.0713 22.7466C15.8721 22.7656 15.6736 22.7043 15.5199 22.5762L12.5199 20.0762C12.2017 19.811 12.1587 19.3381 12.4239 19.0199C12.689 18.7017 13.1619 18.6587 13.4802 18.9239L15.8988 20.9394L19.9196 16.0251C20.1818 15.7045 20.6544 15.6573 20.9749 15.9196Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M15 1.75C15.4142 1.75 15.75 2.08579 15.75 2.5V6.75H20C20.4142 6.75 20.75 7.08579 20.75 7.5C20.75 7.91421 20.4142 8.25 20 8.25H15C14.5858 8.25 14.25 7.91421 14.25 7.5V2.5C14.25 2.08579 14.5858 1.75 15 1.75Z"
									fill="#FF57D4"
								/>
							</svg>
						</div>
						<div className="nav-drop-block-item__info">
							<p>{String(L.translate('Menu.Perpetual.orderHistory.title'))}</p>
							<span>{String(L.translate('Menu.Perpetual.orderHistory.description'))}</span>
						</div>
					</NavLink>
					<NavLink to={getUrl('stopOrders')} className="nav-drop-block-item__elemet">
						<div className="nav-drop-block-item__icon">
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.25 3.5C3.25 2.5335 4.0335 1.75 5 1.75H15.5C15.7126 1.75 15.9152 1.84024 16.0575 1.99828L20.5575 6.99828C20.6814 7.13599 20.75 7.31472 20.75 7.5V12C20.75 12.4142 20.4142 12.75 20 12.75C19.5858 12.75 19.25 12.4142 19.25 12V7.7878L15.166 3.25H5C4.86193 3.25 4.75 3.36193 4.75 3.5V21.5C4.75 21.6381 4.86192 21.75 5 21.75H11C11.4142 21.75 11.75 22.0858 11.75 22.5C11.75 22.9142 11.4142 23.25 11 23.25H5C4.03351 23.25 3.25 22.4665 3.25 21.5V3.5Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M16.5 12.75C16.9142 12.75 17.25 13.0858 17.25 13.5V18.5C17.25 18.9142 16.9142 19.25 16.5 19.25C16.0858 19.25 15.75 18.9142 15.75 18.5V13.5C15.75 13.0858 16.0858 12.75 16.5 12.75Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M16.5 23.5C17.1904 23.5 17.75 22.9404 17.75 22.25C17.75 21.5597 17.1904 21 16.5 21C15.8097 21 15.25 21.5597 15.25 22.25C15.25 22.9404 15.8097 23.5 16.5 23.5Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M15 1.75C15.4142 1.75 15.75 2.08579 15.75 2.5V6.75H20C20.4142 6.75 20.75 7.08579 20.75 7.5C20.75 7.91421 20.4142 8.25 20 8.25H15C14.5858 8.25 14.25 7.91421 14.25 7.5V2.5C14.25 2.08579 14.5858 1.75 15 1.75Z"
									fill="#FF57D4"
								/>
							</svg>
						</div>
						<div className="nav-drop-block-item__info">
							<p>{String(L.translate('Menu.Perpetual.stopOrders.title'))}</p>
							<span>{String(L.translate('Menu.Perpetual.stopOrders.description'))}</span>
						</div>
					</NavLink>
					<NavLink to={getUrl('tradeHistory')} className="nav-drop-block-item__elemet">
						<div className="nav-drop-block-item__icon">
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2.25 6C2.25 4.75736 3.25736 3.75 4.5 3.75H18C18.4142 3.75 18.75 4.08579 18.75 4.5C18.75 4.91421 18.4142 5.25 18 5.25H4.5C4.08579 5.25 3.75 5.58579 3.75 6V20C3.75 20.4142 4.08578 20.75 4.5 20.75H19.5C19.9142 20.75 20.25 20.4142 20.25 20V9.5C20.25 9.08579 20.5858 8.75 21 8.75C21.4142 8.75 21.75 9.08579 21.75 9.5V20C21.75 21.2427 20.7427 22.25 19.5 22.25H4.5C3.25737 22.25 2.25 21.2427 2.25 20V6Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M15.5908 1.96967C15.8837 1.67678 16.3585 1.67678 16.6514 1.96967L18.7727 4.09099C19.0656 4.38388 19.0656 4.85875 18.7727 5.15165L16.6514 7.27297C16.3585 7.56587 15.8837 7.56587 15.5908 7.27298C15.2979 6.98009 15.2979 6.50521 15.5908 6.21232L17.1817 4.62132L15.5908 3.03033C15.2979 2.73743 15.2979 2.26256 15.5908 1.96967Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12 8.25C12.4142 8.25 12.75 8.58579 12.75 9V17.5C12.75 17.9142 12.4142 18.25 12 18.25C11.5858 18.25 11.25 17.9142 11.25 17.5V9C11.25 8.58579 11.5858 8.25 12 8.25Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M16 11.75C16.4142 11.75 16.75 12.0858 16.75 12.5V17.5C16.75 17.9142 16.4142 18.25 16 18.25C15.5858 18.25 15.25 17.9142 15.25 17.5V12.5C15.25 12.0858 15.5858 11.75 16 11.75Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M8 11.75C8.41421 11.75 8.75 12.0858 8.75 12.5V17.5C8.75 17.9142 8.41421 18.25 8 18.25C7.58579 18.25 7.25 17.9142 7.25 17.5V12.5C7.25 12.0858 7.58579 11.75 8 11.75Z"
									fill="#FF57D4"
								/>
							</svg>
						</div>
						<div className="nav-drop-block-item__info">
							<p>{String(L.translate('Menu.Perpetual.tradeHistory.title'))}</p>
							<span>{String(L.translate('Menu.Perpetual.tradeHistory.description'))}</span>
						</div>
					</NavLink>
					<NavLink to={getUrl('transactions')} className="nav-drop-block-item__elemet">
						<div className="nav-drop-block-item__icon">
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.25 3.5C3.25 2.5335 4.0335 1.75 5 1.75H15.5C15.7126 1.75 15.9152 1.84024 16.0575 1.99828L20.5575 6.99828C20.6814 7.13599 20.75 7.31472 20.75 7.5V21.5C20.75 22.4665 19.9665 23.25 19 23.25H5C4.03351 23.25 3.25 22.4665 3.25 21.5V3.5ZM5 3.25C4.86193 3.25 4.75 3.36193 4.75 3.5V21.5C4.75 21.6381 4.86192 21.75 5 21.75H19C19.1381 21.75 19.25 21.6381 19.25 21.5V7.7878L15.166 3.25H5Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7.75 13C7.75 12.5858 8.08579 12.25 8.5 12.25H15.5C15.9142 12.25 16.25 12.5858 16.25 13C16.25 13.4142 15.9142 13.75 15.5 13.75H8.5C8.08579 13.75 7.75 13.4142 7.75 13Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7.75 16C7.75 15.5858 8.08579 15.25 8.5 15.25H15.5C15.9142 15.25 16.25 15.5858 16.25 16C16.25 16.4142 15.9142 16.75 15.5 16.75H8.5C8.08579 16.75 7.75 16.4142 7.75 16Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12.4697 9.96967C12.7626 9.67678 13.2374 9.67678 13.5303 9.96967L16.0303 12.4697C16.3232 12.7626 16.3232 13.2374 16.0303 13.5303C15.7374 13.8232 15.2626 13.8232 14.9697 13.5303L12.4697 11.0303C12.1768 10.7374 12.1768 10.2626 12.4697 9.96967Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M7.96967 15.4697C8.26256 15.1768 8.73744 15.1768 9.03033 15.4697L11.5303 17.9697C11.8232 18.2626 11.8232 18.7374 11.5303 19.0303C11.2374 19.3232 10.7626 19.3232 10.4697 19.0303L7.96967 16.5303C7.67678 16.2374 7.67678 15.7626 7.96967 15.4697Z"
									fill="#FF57D4"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M15 1.75C15.4142 1.75 15.75 2.08579 15.75 2.5V6.75H20C20.4142 6.75 20.75 7.08579 20.75 7.5C20.75 7.91421 20.4142 8.25 20 8.25H15C14.5858 8.25 14.25 7.91421 14.25 7.5V2.5C14.25 2.08579 14.5858 1.75 15 1.75Z"
									fill="#FF57D4"
								/>
							</svg>
						</div>
						<div className="nav-drop-block-item__info">
							<p>{String(L.translate('Menu.Perpetual.transactions.title'))}</p>
							<span>{String(L.translate('Menu.Perpetual.transactions.description'))}</span>
						</div>
					</NavLink>
				</div>
			</div>
			<NavLink to="/options" className="nav-drop-block-item__elemet">
				<div className="nav-drop-block-item__icon">
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.39185 4.40536C6.33694 2.45685 9.02825 1.25 12 1.25C14.9717 1.25 17.6631 2.45685 19.6081 4.40536C21.5487 6.34936 22.75 9.03515 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12C1.25 9.0351 2.45126 6.34936 4.39185 4.40536ZM12 2.75C9.44254 2.75 7.12866 3.78692 5.45344 5.46509C3.78213 7.13934 2.75 9.4484 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 9.44845 20.2179 7.13934 18.5466 5.46509C16.8713 3.78692 14.5575 2.75 12 2.75Z"
							fill="#FF57D4"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M1.25 12C1.25 11.5858 1.58579 11.25 2 11.25H4C4.41421 11.25 4.75 11.5858 4.75 12C4.75 12.4142 4.41421 12.75 4 12.75H2C1.58579 12.75 1.25 12.4142 1.25 12Z"
							fill="#FF57D4"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.39228 4.40489C4.68518 4.112 5.16005 4.11201 5.45294 4.4049L7.01769 5.96968C7.31058 6.26257 7.31057 6.73745 7.01768 7.03034C6.72478 7.32323 6.24991 7.32322 5.95702 7.03033L4.39227 5.46555C4.09938 5.17266 4.09939 4.69778 4.39228 4.40489Z"
							fill="#FF57D4"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M12 1.25C12.4142 1.25 12.75 1.58579 12.75 2V4C12.75 4.41421 12.4142 4.75 12 4.75C11.5858 4.75 11.25 4.41421 11.25 4V2C11.25 1.58579 11.5858 1.25 12 1.25Z"
							fill="#FF57D4"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M19.25 12C19.25 11.5858 19.5858 11.25 20 11.25H22C22.4142 11.25 22.75 11.5858 22.75 12C22.75 12.4142 22.4142 12.75 22 12.75H20C19.5858 12.75 19.25 12.4142 19.25 12Z"
							fill="#FF57D4"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M19.6078 4.40489C19.9007 4.69778 19.9007 5.17266 19.6078 5.46555L18.043 7.03033C17.7501 7.32322 17.2753 7.32323 16.9824 7.03034C16.6895 6.73744 16.6895 6.26257 16.9824 5.96968L18.5471 4.4049C18.84 4.112 19.3149 4.112 19.6078 4.40489Z"
							fill="#FF57D4"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M12 9.25C12.4142 9.25 12.75 9.58579 12.75 10V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V10C11.25 9.58579 11.5858 9.25 12 9.25Z"
							fill="#FF57D4"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.24792 18.3223C6.93653 20.1251 9.33682 21.25 12.0001 21.25C14.6634 21.25 17.0637 20.1251 18.7523 18.3223C16.7176 17.3158 14.4258 16.75 12.0001 16.75C9.57442 16.75 7.28262 17.3158 5.24792 18.3223ZM3.70189 17.4469C6.14879 16.0487 8.98234 15.25 12.0001 15.25C15.0179 15.25 17.8514 16.0487 20.2983 17.4469C20.4866 17.5544 20.6191 17.7382 20.6617 17.9508C20.7042 18.1634 20.6527 18.3841 20.5204 18.5558C18.556 21.1052 15.47 22.75 12.0001 22.75C8.53022 22.75 5.44418 21.1052 3.47988 18.5558C3.34754 18.384 3.29601 18.1634 3.33857 17.9508C3.38114 17.7382 3.51363 17.5544 3.70189 17.4469Z"
							fill="#FF57D4"
						/>
					</svg>
				</div>
				<div className="nav-drop-block-item__info">
					<p>{String(L.translate('Menu.Options.title'))}</p>
					<span>{String(L.translate('Menu.Options.description'))}</span>
				</div>
			</NavLink>
		</div>
	);
};

export default DerivativeSection;
