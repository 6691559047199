import { FC } from 'react';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { numberExists } from 'services/utils/numberValidation';
import { IOrderHistoryTabItem } from './types';

const OrderHistoryTabItem: FC<IOrderHistoryTabItem> = ({ item }) => {
	let direction: string | null = null;
	let directionColor: string | null = null;
	if (item.transactions_type === 'buy') {
		direction = 'Buy';
		directionColor = 'green';
	}
	if (item.transactions_type === 'sell') {
		direction = 'Sell';
		directionColor = 'red';
	}

	const cashFlow =
		numberExists(item.price_avarage_completed) &&
		numberExists(item.amount_exequted_in_asset) &&
		numberExists(item.fee)
			? Number(item.price_avarage_completed) * Number(item.amount_exequted_in_asset) +
			  Number(item.fee)
			: null;

	let cashFlowColor = '';

	if (numberExists(cashFlow)) {
		cashFlowColor = item.transactions_type === 'buy' ? 'red' : 'green';
	}

	const createdAt = (Number(item.created_at) && customDateFormat(Number(item.created_at))) || '';

	return (
		<div className="tr-wrap">
			<div className="tr">
				<div className="td td--left">
					<p className="td-hidden-name">Contract</p>
					<p className="upper-case">{item.contract_name?.toUpperCase()}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Direction</p>
					<p className={directionColor || ''}>{direction}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Order Price</p>
					<p>{numberExists(item.price_entry_for_order) ? item.price_entry_for_order : '-'}</p>
					<span>
						{numberExists(item.iv_coefficient)
							? `${String(item.iv_coefficient?.toFixed(2).replace(/\.00$/, ''))}%`
							: '-'}
					</span>
				</div>
				<div className="td">
					<p className="td-hidden-name">Qty (BTC)</p>
					<p>{numberExists(item.amount_asset_request) ? item.amount_asset_request : '-'}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Avg. Filled Price</p>
					<p>{numberExists(item.price_avarage_completed) ? item.price_avarage_completed : '-'}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Filled Qty (BTC)</p>
					<p>{numberExists(item.amount_exequted_in_asset) ? item.amount_exequted_in_asset : '-'}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Fees</p>
					<p>{numberExists(item.fee) ? item.fee?.toFixed(3) : '-'}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Order P&L</p>
					<p className="red">
						{numberExists(item.pnl_realized)
							? Number(item.pnl_realized)?.toFixed(2).replace(/\.00$/, '')
							: '-'}
					</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Cash Flow</p>
					<p className={cashFlowColor}>
						{numberExists(cashFlow)
							? `${item.transactions_type === 'buy' ? '-' : ''}${String(cashFlow)}`
							: '-'}
					</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Order ID</p>
					<p>{item.id}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Order Type</p>
					<p className="capitalize">{item.type_order}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Time in Force</p>
					<p>GTC</p>
				</div>
				<div className="td">
					<p className="td-hidden-name capitalize">Status</p>
					<p className="capitalize">{item.status}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Order Time</p>
					<p>{createdAt?.split(' ')?.[0] || '-'}</p>
					<span>{createdAt?.split(' ')?.[1] || '-'}</span>
				</div>
			</div>
		</div>
	);
};

export default OrderHistoryTabItem;
