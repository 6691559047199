import React, { useEffect } from 'react';
import L from 'i18n-react';

const Account = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="static-section section-gradient">
			<div className="inside">
				<p className="section-title">
					{String(L.translate('StaticPages.AccountSecurityPage.paragraph_1'))}
				</p>
				<div className="static-content">
					<p>{String(L.translate('StaticPages.AccountSecurityPage.paragraph_2'))}</p>

					<h3>{String(L.translate('StaticPages.AccountSecurityPage.header_1'))}</h3>
					<p>{String(L.translate('StaticPages.AccountSecurityPage.paragraph_3'))}</p>

					<h3>{String(L.translate('StaticPages.AccountSecurityPage.header_2'))}</h3>
					<p>{String(L.translate('StaticPages.AccountSecurityPage.paragraph_4'))}</p>

					<h3>{String(L.translate('StaticPages.AccountSecurityPage.header_3'))}</h3>
					<p>{String(L.translate('StaticPages.AccountSecurityPage.paragraph_6'))}</p>

					<h3>{String(L.translate('StaticPages.AccountSecurityPage.header_5'))}</h3>
					<p>{String(L.translate('StaticPages.AccountSecurityPage.paragraph_7'))}</p>

					<h3>{String(L.translate('StaticPages.AccountSecurityPage.header_6'))}</h3>
					<p>{String(L.translate('StaticPages.AccountSecurityPage.paragraph_8'))}</p>

					<h3>{String(L.translate('StaticPages.AccountSecurityPage.header_7'))}</h3>
					<p>{String(L.translate('StaticPages.AccountSecurityPage.paragraph_9'))}</p>

					<h3>{String(L.translate('StaticPages.AccountSecurityPage.header_8'))}</h3>

					<h3>{String(L.translate('StaticPages.AccountSecurityPage.header_9'))}</h3>
					<p>{String(L.translate('StaticPages.AccountSecurityPage.paragraph_10'))}</p>
					<p>{String(L.translate('StaticPages.AccountSecurityPage.paragraph_11'))}</p>
				</div>
			</div>
		</section>
	);
};

export default Account;
