import {
	TDefaultTransactionSpeed,
	TSettingsStore,
	TInvitationsData,
	TTheme,
	IUserSettingsData,
} from 'redux/reducers/settings/types';
import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';

// ==========================================:
const getSettingsState = (state: TStoreState): TSettingsStore => state.settings;
export const getSettings = createSelector(
	[getSettingsState],
	(settings: TSettingsStore) => settings,
);

// ====================================================:
export const getTheme = createSelector(
	[getSettings],
	(settingsTheme: TSettingsStore): TTheme => settingsTheme.theme,
);

// ====================================================:
export const getSlippageTolerance = createSelector(
	[getSettings],
	(settings: TSettingsStore): number => settings.slippageTolerance,
);

// ====================================================:
export const getDefaultTransactionSpeed = createSelector(
	[getSettings],
	(settings: TSettingsStore): TDefaultTransactionSpeed => settings.defaultTransactionSpeed,
);

// ====================================================:
export const getPartialOrderExecution = createSelector(
	[getSettings],
	(settings: TSettingsStore): boolean => settings.partialOrderExecution,
);

// ====================================================:
export const getInvitations = createSelector(
	[getSettings],
	(settings: TSettingsStore): TInvitationsData | null => settings.invitations,
);

// ====================================================:
export const getUserSettingsDataLoader = createSelector(
	[getSettings],
	(settings: TSettingsStore): boolean => settings.invitationsLoader,
);

export const getReferralBonuses = (state: TStoreState) => {
	return getSettingsState(state).referralBonuses;
};

export const getReferralBonusesLoading = (state: TStoreState) => {
	return getSettingsState(state).referralBonusesLoading;
};

export const getUsersSettings = createSelector(
	[getSettings],
	(settings: TSettingsStore): IUserSettingsData | null => settings.userSettingsData,
);

// ====================================================:
export const getUsersSettingsLoading = createSelector(
	[getSettings],
	(settings: TSettingsStore): boolean => settings.userSettingsDataLoader,
);

export const getKycLoading = createSelector(
	[getSettings],
	(settings: TSettingsStore): boolean => settings.kycLoader,
);

export const getSmsCode = createSelector(
	[getSettings],
	(settings: TSettingsStore): any => settings.smsCode,
);

export const getAntiphishingStatus = createSelector(
	[getSettings],
	(settings: TSettingsStore): any => settings.antiPhishingEnabled,
);

export const getAntiphishingLoader = createSelector(
	[getSettings],
	(settings: TSettingsStore): any => settings.antiPhishingLoader,
);
