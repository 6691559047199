import { FC, useState } from 'react';
import GoogleCaptcha from 'components/GoogleCaptcha';
import { useDispatch } from 'react-redux';
import { ResetFormTwoFa } from 'components/Forms/ResetFormTwoFa';
import { forgotTwoFaRequest } from 'redux/reducers/auth/reducer';
import { IForgotTwofaFormItem } from 'components/Forms/ResetFormTwoFa/types';
import { useHistory, useLocation } from 'react-router';
import Popup from 'reactjs-popup';
import { IResetTwofa } from './types';
// ==========================================:
export const Reset2Fa: FC<IResetTwofa> = ({ openModal, closeModal }) => {
	const [open, setOpen] = useState(false);
	// const [message, setMessage] = useState('');
	const [loginData, setLoginData] = useState<null | IForgotTwofaFormItem>(null);
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const handleTwoFaSubmit = (values: any): void => {
		const file = values.selfie;
		setLoginData({ ...values.values, file });
		setOpen(true);
	};

	const hangleCaptchaClose = (): void => {
		setLoginData(null);
		setOpen(false);
	};
	const handleCaptcha = (value: string): void => {
		if (loginData && value) {
			dispatch(
				forgotTwoFaRequest({
					data: {
						...loginData,
						captcha: value,
					},
					history,
				}),
			);
		}

		setLoginData(null);
		setOpen(false);
	};
	// useEffect(() => {
	// 	if (location.state === '2fa_success') {
	// 		setMessage('2fa_success');
	// 		return;
	// 	}
	// 	setMessage('');
	// }, [location.state]);
	return (
		<Popup open={openModal} closeOnDocumentClick onClose={closeModal} closeOnEscape>
			<div className="popup-window">
				<div className="popup-window__inside">
					<ResetFormTwoFa twoFaSubmit={handleTwoFaSubmit} closeModal={closeModal} />
				</div>
			</div>
			{open && (
				<div className="popup-window popup-window--captcha">
					<div className="popup-window__inside">
						<GoogleCaptcha setCaptcha={handleCaptcha} captchaClose={hangleCaptchaClose} />
						<div className="popup-window__close" onClick={hangleCaptchaClose} />
					</div>
				</div>
			)}
		</Popup>
	);
};
