import L from 'i18n-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getBaseAsset, getPerpetualRecentTrades } from 'redux/reducers/perpetual/selectors';

const LastPrice = () => {
	const baseAsset = useSelector(getBaseAsset);
	const recentTrades = useSelector(getPerpetualRecentTrades);
	const [lastPriceChange, setLastPriceChange] = useState(0);

	const changeHolder = () => {
		if (lastPriceChange >= 1) {
			return ((lastPriceChange - 1) * 100).toFixed(2);
		}
		return ((1 - lastPriceChange) * 100).toFixed(2);
	};

	useEffect(() => {
		if (recentTrades) {
			const lastPrice = recentTrades[0]?.fill_price || 1;
			const prevPrice = recentTrades[1]?.fill_price || 1;
			setLastPriceChange(lastPrice / prevPrice);
		}
	}, [recentTrades]);

	return (
		<div className="orderbook-body-info">
			<div className="orderbook-body-info__line orderbook-body-info__line--header">
				<span className="grey">{String(L.translate('Trade.Perpetual.OrderBook.last_price'))}</span>
				<span className="grey">USDT</span>
				<span className="grey">{String(L.translate('Trade.Perpetual.OrderBook.change'))}</span>
			</div>
			<div className="orderbook-body-info__line">
				<span className={`fw-700 ${lastPriceChange >= 1 ? 'green' : 'red'}`}>
					{recentTrades?.[0]?.fill_price || 0}
				</span>
				<span>{baseAsset?.mark_price?.toFixed(2)}</span>
				<span className={`${lastPriceChange >= 1 ? 'green' : 'red'}`}>{changeHolder()} %</span>
			</div>
		</div>
	);
};
export default LastPrice;
