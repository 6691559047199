import { FC } from 'react';
import { useHistory, useParams } from 'react-router';
import L from 'i18n-react';
import { navList } from 'routes/routesList';

const NavButtons: FC = () => {
	const routeParams = useParams<{ transactionType?: string }>();
	const { transactionType } = routeParams;
	const history = useHistory();

	const navButtons = [
		{
			routeParam: 'crypto',
			name: String(L.translate('TransactionHistory.navButtons.crypto')),
		},
		{
			routeParam: 'fiat',
			name: L.translate('TransactionHistory.navButtons.fiat'),
		},
		{
			routeParam: 'transfer',
			name: L.translate('TransactionHistory.navButtons.transfer'),
		},
		{
			routeParam: 'convert',
			name: L.translate('TransactionHistory.navButtons.convert'),
		},
	];

	const onClick: (routeParam: string) => void = (routeParam) => {
		history.replace(navList.transactionHistory.path.replace(':transactionType', routeParam));
	};

	return (
		<div className="table-nav flex flex--gap16">
			{navButtons.map((navButton) => (
				<button
					key={navButton.routeParam}
					onClick={() => onClick(navButton.routeParam)}
					type="button"
					className={`button ${navButton.routeParam === transactionType ? '' : 'button--type3'}`}
				>
					{navButton.name}
				</button>
			))}
		</div>
	);
};

export default NavButtons;
