import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import L from 'i18n-react';
import { Formik, Form } from 'formik';
import Portal from 'ui/Portal';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import { api } from 'services';
import yup from 'services/utils/capsLockValidate';
import { notificationContainer } from 'services/utils/notificationContainer';
import TransferContentIsolated from './TransferContent/TransferContentIsolated';
import { ITransferEnhancedModal } from './types';
import { Values } from './TransferContent/TransferContentIsolated/types';
import { SPOT, MARGIN, PERPETUALS } from './TransferContent/TransferContentIsolated/constants';

const TransferEnhancedModal: FC<ITransferEnhancedModal> = ({ onClose, refetch }) => {
	const dispatch = useDispatch();
	const isAuth = useSelector(getAuthIsAuthenticated);
	const history = useHistory();

	const handleClose = () => {
		onClose && onClose();
	};

	useEffect(() => {
		dispatch(getAssetsRequest());
	}, [dispatch]);

	const initialValues: Values = {};

	const validationSchema = yup.object().shape({
		amount: yup
			.number()
			.moreThan(0, String(L.translate('Wallets.MarginAccount.messages.amount_should_more_zero')))
			.typeError(String(L.translate('Wallets.MarginAccount.messages.amount_must_be_a_number')))
			.required(String(L.translate('Wallets.MarginAccount.messages.amount_is_required'))),
	});

	return (
		<Portal>
			<div className="popup-window general-wallets-transfer">
				<div className="popup-window__inside">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						validateOnChange
						onSubmit={async (values, { resetForm }) => {
							if (!isAuth) {
								history.push('/login');
								return;
							}
							// eslint-disable-next-line @typescript-eslint/naming-convention
							const { asset_pair_id, asset_id, amount, from, to } = values;
							let endpoint;
							let params;
							if (from?.id === SPOT) {
								if (to?.id === MARGIN) {
									endpoint = api.marginWallets.transferFromSpotToIsolated;
									params = {
										asset_id,
										asset_pair_id,
										amount: Number(amount),
									};
								}
								if (to?.id === PERPETUALS) {
									endpoint = api.perpetualTrade.transferFromSpot;
									params = {
										amount_usdt: Number(amount),
										type: 'to_futures',
									};
								}
							}
							if (from?.id === MARGIN) {
								if (to?.id === SPOT) {
									endpoint = api.marginWallets.transferFromIsolatedToSpot;
									params = {
										asset_id,
										asset_pair_id,
										amount: Number(amount),
									};
								}
							}
							if (from?.id === PERPETUALS) {
								if (to?.id === SPOT) {
									endpoint = api.perpetualTrade.transferToSpot;
									params = {
										amount_usdt: amount,
										type: 'to_spot',
									};
								}
							}
							try {
								if (endpoint && params) {
									await endpoint(params);
									notificationContainer(
										String(L.translate('Wallets.MarginAccount.messages.transfer_was_completed')),
										'success',
									);
								}
								onClose && onClose();
								refetch && refetch();
							} catch (error) {
								notificationContainer(
									String(L.translate('Wallets.MarginAccount.messages.failed_to_transfer')),
									'error',
								);
								console.error(error);
							}
						}}
					>
						<Form>
							<TransferContentIsolated onClose={handleClose} />
						</Form>
					</Formik>
				</div>
			</div>
		</Portal>
	);
};

export default TransferEnhancedModal;
