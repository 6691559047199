import React, { useEffect } from 'react';
import L from 'i18n-react';

const SpotMarginEtf = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="static-section section-gradient">
			<div className="inside">
				<p className="section-title">
					{String(L.translate('StaticPages.SpotMarketPage.spot_title'))}
				</p>
				<div className="static-content">
					<h3>{String(L.translate('StaticPages.SpotMarketPage.title_1'))}</h3>
					<p>{String(L.translate('StaticPages.SpotMarketPage.paragraph_1'))}</p>
					<h3>{String(L.translate('StaticPages.SpotMarketPage.title_2'))}</h3>
					<p>{String(L.translate('StaticPages.SpotMarketPage.paragraph_2'))}</p>
					<p>{String(L.translate('StaticPages.SpotMarketPage.paragraph_3'))}</p>
					<p>{String(L.translate('StaticPages.SpotMarketPage.paragraph_4'))}</p>
					<p>{String(L.translate('StaticPages.SpotMarketPage.paragraph_5'))}</p>
					<ul>
						<li>{String(L.translate('StaticPages.SpotMarketPage.li_1'))}</li>
						<li>{String(L.translate('StaticPages.SpotMarketPage.li_2'))}</li>
					</ul>
					<p>{String(L.translate('StaticPages.SpotMarketPage.paragraph_6'))}</p>
					<p>{String(L.translate('StaticPages.SpotMarketPage.paragraph_7'))}</p>
					<h3>{String(L.translate('StaticPages.SpotMarketPage.title_3'))}</h3>
					<p>{String(L.translate('StaticPages.SpotMarketPage.paragraph_8'))}</p>
					<p>{String(L.translate('StaticPages.SpotMarketPage.paragraph_9'))}</p>
					<p>{String(L.translate('StaticPages.SpotMarketPage.paragraph_10'))}</p>
					<p>{String(L.translate('StaticPages.SpotMarketPage.paragraph_11'))}</p>
					<p>{String(L.translate('StaticPages.SpotMarketPage.paragraph_12'))}</p>
					<p>{String(L.translate('StaticPages.SpotMarketPage.paragraph_13'))}</p>
					<p>{String(L.translate('StaticPages.SpotMarketPage.paragraph_14'))}</p>
				</div>
			</div>
		</section>
	);
};

export default SpotMarginEtf;
