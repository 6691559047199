import classNames from 'classnames';
import { FC } from 'react';
import useSelect from 'hooks/useSelect';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { CheckIcon, TriangleArrowDownIcon } from 'assets/inline-svg';
import { IWithdrawSelectProps } from './types';

const WalletSelect: FC<IWithdrawSelectProps> = ({ current, onChange, wallets }) => {
	const { open, setOpen, currentRef, dropRef } = useSelect();

	const handleClick = () => {
		if (!open && wallets?.length) {
			setOpen(true);
			return;
		}
		setOpen(false);
	};

	const handleChange = (newWallet: IWalletItem) => {
		onChange(newWallet);
		setOpen(false);
	};

	return (
		<div className={classNames('select', { active: open })}>
			<button
				type="button"
				ref={currentRef}
				onClick={handleClick}
				className="select__current select-current-currensy--type2"
			>
				{current ? (
					<span className="select-current-currensy">
						<span className="select-current-currensy__icon">
							<img src={current.asset.img_path} alt="coin" />
						</span>
						<span className="select-current-currensy__name">
							{current.asset.code.toUpperCase()}
						</span>
					</span>
				) : (
					<span>Select coin</span>
				)}

				<TriangleArrowDownIcon />
			</button>

			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll">
					<div className="select__drop-item">
						<ul>
							{wallets?.map((wallet) => (
								<li key={wallet.id}>
									<button type="button" onClick={() => handleChange(wallet)}>
										<span className="select-current-currensy">
											{wallet.id === current?.id && (
												<span className="select-current-currensy__icon">
													<CheckIcon />
												</span>
											)}

											<span className="select-current-currensy__icon">
												<img src={wallet.asset.img_path} alt="coin" />
											</span>

											<span className="select-current-currensy__name">
												{wallet.asset.code.toUpperCase()}
											</span>
										</span>
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WalletSelect;
