import L from 'i18n-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetsList } from 'redux/reducers/assets/selectors';
import { getWalletsConvertHistoryRequest } from 'redux/reducers/wallets/reducer';
import {
	getWalletsConvertHistoryList,
	getWalletsHistoryLoader,
} from 'redux/reducers/wallets/selectors';
import { IAssetsData } from 'redux/reducers/assets/types';
import Loader from 'ui/Loader';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import TableRow from 'components/TransactionHistory/ConvertTableRow/ConvertTableRow';
import PastDaysSelect from 'components/TransactionHistory/PastDaysSelect';
import AssetSelect from 'components/TransactionHistory/AssetSelect';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';

const TransactionHistoryCrypto = () => {
	const dispatch = useDispatch();
	const { perPage, currentPage } = usePagination();
	const [pastDays, setPastDays] = useState<number | null>(null);
	const [activeAssetFrom, setActiveAssetFrom] = useState<number | null>(null);
	const [activeAssetTo, setActiveAssetTo] = useState<number | null>(null);
	const [assetListFrom, setAssetListFrom] = useState<IAssetsData | null>(null);
	const [assetListTo, setAssetListTo] = useState<IAssetsData | null>(null);

	const assetsList = useSelector(getAssetsList);
	const convertHistory = useSelector(getWalletsConvertHistoryList);
	const loader = useSelector(getWalletsHistoryLoader);

	useEffect(() => {
		dispatch(
			getWalletsConvertHistoryRequest({
				current_page: currentPage,
				per_page: perPage,
				from_asset_id: activeAssetFrom || undefined,
				to_asset_id: activeAssetTo || undefined,
				past_days: pastDays || undefined,
			}),
		);
	}, [activeAssetFrom, activeAssetTo, currentPage, dispatch, pastDays, perPage]);

	useEffect(() => {
		setAssetListFrom(assetsList);
		setAssetListTo(assetsList);
		setActiveAssetFrom(null);
		setActiveAssetTo(null);
	}, [assetsList]);

	const onPastDaysChange = (_pastDays: number | null) => {
		setPastDays(_pastDays);
	};

	const filterAssetList = (id: number, type: 'from' | 'to') => {
		if (type === 'to') {
			const newAssetListFrom = !!assetsList?.length && assetsList.filter((el) => el.id !== id);
			newAssetListFrom && setAssetListFrom(newAssetListFrom);
			if (activeAssetFrom) {
				const newAssetListTo =
					!!assetsList?.length && assetsList.filter((el) => el.id !== activeAssetFrom);
				newAssetListTo && setAssetListTo(newAssetListTo);
			}
			return;
		}
		if (type === 'from') {
			const newAssetListTo = !!assetListTo?.length && assetListTo.filter((el) => el.id !== id);
			newAssetListTo && setAssetListTo(newAssetListTo);
			if (activeAssetTo) {
				const newAssetListFrom =
					!!assetsList?.length && assetsList.filter((el) => el.id !== activeAssetTo);
				newAssetListFrom && setAssetListFrom(newAssetListFrom);
			}
		}
	};

	const onAssetFromChange = (id: number | null) => {
		setActiveAssetFrom(id);

		id != null && filterAssetList(id, 'from');
	};

	const onAssetToChange = (id: number | null) => {
		setActiveAssetTo(id);

		id != null && filterAssetList(id, 'to');
	};

	const resetFilters = () => {
		setPastDays(null);
		setAssetListFrom(assetsList);
		setAssetListTo(assetsList);
		setActiveAssetFrom(null);
		setActiveAssetTo(null);
	};

	const renderTableBody = () => {
		if (loader) {
			return (
				<div className="popup-loader">
					<Loader />
				</div>
			);
		}
		if (convertHistory?.total === 0) {
			return <TableEmptyPlug />;
		}
		return (
			<div className="table-body">
				{!!convertHistory?.data?.length &&
					convertHistory.data.map((el) => {
						const toIsCrypto = assetsList?.find(
							(assetItem) => assetItem?.code === el.to_asset_code,
						)?.is_crypto;
						return <TableRow key={el.id} toIsCrypto={!!toIsCrypto} {...el} />;
					})}
			</div>
		);
	};

	return (
		<div className="table table--transaction-history table--transaction-convert-history">
			<div className="table-filter flex flex-sb">
				<div className="table-filter-left flex flex-m flex--gap24">
					<PastDaysSelect
						label={String(L.translate('TransactionHistory.filters_convert.time'))}
						value={pastDays}
						onChange={onPastDaysChange}
					/>
					<AssetSelect
						label={String(L.translate('TransactionHistory.filters_convert.from'))}
						assetsList={assetListFrom}
						value={activeAssetFrom}
						onChange={onAssetFromChange}
					/>
					{/* <AssetSelect
						label={String(L.translate('TransactionHistory.filters_convert.to'))}
						assetsList={assetListTo}
						value={activeAssetTo}
						onChange={onAssetToChange}
					/> */}
				</div>
				<div className="table-filter-right">
					<button type="button" onClick={resetFilters} className="button button--type3">
						{String(L.translate('TransactionHistory.filters.resetFilters'))}
					</button>
				</div>
			</div>
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p>{String(L.translate('TransactionHistory.table_convert.date'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('TransactionHistory.table_convert.fee'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('TransactionHistory.table_convert.from'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('TransactionHistory.table_convert.to'))}</p>
					</div>
					<div className="td td--right">
						<p>{String(L.translate('TransactionHistory.table_convert.amount'))}</p>
					</div>
				</div>
			</div>
			{renderTableBody()}
			{convertHistory?.last_page &&
				Number(convertHistory?.total) > Number(convertHistory?.per_page) && (
					<Pagination lastPage={convertHistory.last_page} getCurrentHref={(s) => String(s)} />
				)}
		</div>
	);
};

export default TransactionHistoryCrypto;
