import L from 'i18n-react';

const LoginTradeTablePlug = () => {
	return (
		<div className="table-body-plug">
			<svg
				width="49"
				height="48"
				viewBox="0 0 49 48"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8.5 23.5C8.22386 23.5 8 23.7239 8 24V42C8 42.2761 8.22386 42.5 8.5 42.5H40.5C40.7761 42.5 41 42.2761 41 42V24C41 23.7239 40.7761 23.5 40.5 23.5H8.5ZM5 24C5 22.067 6.567 20.5 8.5 20.5H40.5C42.433 20.5 44 22.067 44 24V42C44 43.933 42.433 45.5 40.5 45.5H8.5C6.567 45.5 5 43.933 5 42V24Z"
					fill="#9D9DBC"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M24.5 5.5C19.8056 5.5 16 9.30558 16 14V22C16 22.8284 15.3284 23.5 14.5 23.5C13.6716 23.5 13 22.8284 13 22V14C13 7.64872 18.1488 2.5 24.5 2.5C30.8512 2.5 36 7.64872 36 14V22C36 22.8284 35.3284 23.5 34.5 23.5C33.6716 23.5 33 22.8284 33 22V14C33 9.30558 29.1944 5.5 24.5 5.5Z"
					fill="#9D9DBC"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M24.5 28.5C25.3284 28.5 26 29.1716 26 30V36C26 36.8284 25.3284 37.5 24.5 37.5C23.6716 37.5 23 36.8284 23 36V30C23 29.1716 23.6716 28.5 24.5 28.5Z"
					fill="#9D9DBC"
				/>
			</svg>
			<p>{String(L.translate('Trade.Perpetual.Tables.log_or_sign'))}</p>
		</div>
	);
};
export default LoginTradeTablePlug;
