import React, { FC } from 'react';
import L from 'i18n-react';
import useTimer from 'hooks/useTimer';

interface IGetCodeButton {
	onClick: () => void;
}

const GetCodeButton: FC<IGetCodeButton> = ({ onClick }) => {
	const [timerValue, timerReset] = useTimer();
	const getCodeHandler = () => {
		onClick();
		timerReset();
	};
	return (
		<>
			{timerValue ? (
				<>
					<span className="input-icon-text">
						{timerValue} {L.translate('UI.GetCodeButton.sec')}
					</span>
					<div className="input-icon input-icon--right">
						<svg
							width="20"
							height="21"
							viewBox="0 0 20 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M2.29199 11.7501C2.29199 7.4929 5.74315 4.04175 10.0003 4.04175C14.2575 4.04175 17.7087 7.4929 17.7087 11.7501C17.7087 16.0073 14.2575 19.4584 10.0003 19.4584C5.74315 19.4584 2.29199 16.0073 2.29199 11.7501ZM10.0003 5.29175C6.4335 5.29175 3.54199 8.18326 3.54199 11.7501C3.54199 15.3169 6.4335 18.2084 10.0003 18.2084C13.5671 18.2084 16.4587 15.3169 16.4587 11.7501C16.4587 8.18326 13.5671 5.29175 10.0003 5.29175Z"
								fill="#7D7D4D"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M6.45801 2.16675C6.45801 1.82157 6.73783 1.54175 7.08301 1.54175H12.9163C13.2615 1.54175 13.5413 1.82157 13.5413 2.16675C13.5413 2.51193 13.2615 2.79175 12.9163 2.79175H7.08301C6.73783 2.79175 6.45801 2.51193 6.45801 2.16675Z"
								fill="#7D7D4D"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M16.2749 4.22481C16.519 4.46888 16.519 4.86461 16.2749 5.10869L15.0249 6.35869C14.7809 6.60277 14.3851 6.60277 14.1411 6.35869C13.897 6.11461 13.897 5.71888 14.1411 5.47481L15.3911 4.22481C15.6351 3.98073 16.0309 3.98073 16.2749 4.22481Z"
								fill="#7D7D4D"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M10 7.375C10.3452 7.375 10.625 7.65482 10.625 8V11.75C10.625 12.0952 10.3452 12.375 10 12.375C9.65482 12.375 9.375 12.0952 9.375 11.75V8C9.375 7.65482 9.65482 7.375 10 7.375Z"
								fill="#7D7D4D"
							/>
						</svg>
					</div>
				</>
			) : (
				<span className="input-icon-text input-icon-text--type2">
					<button
						type="button"
						onClick={getCodeHandler}
						className="button button--type-text2 fw-500"
					>
						{L.translate('UI.GetCodeButton.get_code')}
					</button>
				</span>
			)}
		</>
	);
};

export default GetCodeButton;
