import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
	getCurrencyRateRequest,
	getCurrencyRateSuccess,
	putConvertRequest,
	putConvertSuccess,
} from 'redux/reducers/exchange/reducer';
import { call, put, takeEvery } from 'redux-saga/effects';
import L from 'i18n-react';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getLimitsRequest, getLimitsSuccess } from './reducer';
import { IRecentTradesResponse } from '../sockets/types';

function* getLimitsWorker(action: any) {
	const { payload } = action;
	try {
		const response: IRecentTradesResponse = yield call(api.orders.getExchangeInfo, payload);
		yield put(getLimitsSuccess(response));
	} catch (error) {
		console.log(error);
	}
}

function* getRateWorker(action: any) {
	const { payload } = action;
	try {
		const response: IRecentTradesResponse = yield call(api.orders.getExchangeRate, payload);
		yield put(getCurrencyRateSuccess(response));
	} catch (error) {
		console.log(error);
	}
}

function* putConvertWorker(action: any) {
	const { payload } = action;
	try {
		const response: IRecentTradesResponse = yield call(api.orders.makeExchange, payload);
		yield put(putConvertSuccess(response));
		yield put(getWalletsRequest());
	} catch (error: any) {
		let code = error.response.data.errors;
		if (error.response.data.errors.quantity) {
			for (let i = 0; i < error.response.data.errors.quantity.length; i += 1) {
				code = String(error.response.data.errors.quantity[i]);
				notificationContainer(String(L.translate(`Errors.${code}`)), 'error');
			}
			return;
		}
		notificationContainer(String(L.translate(`Errors.${code}`)), 'error');
	}
}

export function* exchangeSaga() {
	yield takeEvery(getLimitsRequest.type, getLimitsWorker);
	yield takeEvery(getCurrencyRateRequest.type, getRateWorker);
	yield takeEvery(putConvertRequest.type, putConvertWorker);
}
