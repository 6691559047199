/* eslint-disable @typescript-eslint/no-shadow */
import { FC, SyntheticEvent } from 'react';
import L from 'i18n-react';
import useSelect from 'hooks/useSelect';
import { ITransferFromSelect } from './types';

const TransferFromSelect: FC<ITransferFromSelect> = ({ label, value = 'spot', onChange }) => {
	const { open, setOpen, toggleOpen, currentRef, dropRef } = useSelect();

	const items = [
		{
			code: 'spot',
			name: String(L.translate('TransactionHistory.filters.transfer_from_spot')),
		},
		{
			code: 'isolated',
			name: String(L.translate('TransactionHistory.filters.transfer_from_isolated')),
		},
		{
			code: 'derivative',
			name: String(L.translate('TransactionHistory.filters.transfer_from_derivative')),
		},
	];

	const handleFromChoose = (e: SyntheticEvent<HTMLButtonElement>) => {
		const currentTarget = e.currentTarget as HTMLButtonElement;
		const { value: targetValue } = currentTarget;
		if (onChange) {
			onChange(String(targetValue));
		}
		setOpen(false);
	};

	const handleAllSelect = () => {
		if (onChange) {
			onChange(null);
		}
		setOpen(false);
	};

	const selectedItem = (value && items?.find((item) => item.code === value)) || null;

	return (
		<div className="select-block">
			<p className="select-block__name">{label}</p>
			<div className={`select select--type3 ${open ? ' active' : ''}`}>
				<button type="button" onClick={toggleOpen} className="select__current" ref={currentRef}>
					<span className="select-current-currensy">
						<p>
							{value != null
								? selectedItem?.name
								: String(L.translate('TransactionHistory.filters.asset_all'))}
						</p>
					</span>
					<span className="select__current-arrow">
						<svg
							width="10"
							height="6"
							viewBox="0 0 10 6"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M10 0.833008H1H0L5 5.83301L10 0.833008Z" fill="#1C1C29" />
						</svg>
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								<li>
									<button onClick={handleAllSelect} type="button">
										{String(L.translate('TransactionHistory.filters.asset_all'))}
									</button>
								</li>
							</ul>
						</div>
						{items?.map((item) => {
							return (
								<div key={String(item.code)} className="select__drop-item">
									<ul>
										<li>
											<button type="button" value={item.code} onClick={handleFromChoose}>
												{item.name}
											</button>
										</li>
									</ul>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TransferFromSelect;
