import { useCallback, useEffect, useState } from 'react';

type TUseTimer = (initTime?: number) => [time: number, resetTimer: (time?: number) => void];

const useTimer: TUseTimer = (initTime) => {
	const [timer, setTimer] = useState(initTime || 0);
	const timeOutCallback = useCallback(() => setTimer((currTimer) => currTimer - 1), []);

	useEffect(() => {
		timer > 0 && setTimeout(timeOutCallback, 1000);
	}, [timer, timeOutCallback]);

	const resetTimer = (time?: number) => {
		if (!timer) {
			setTimer(time || 60);
		}
	};

	return [timer, resetTimer];
};

export default useTimer;
