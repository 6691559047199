/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiGetFavoritePairsResponse,
	IApiToggleFavoritePairParams,
} from 'services/api/assetPairs/types';
import {
	IAssetPairsFeesAndLimitsResponsePayload,
	IAssetPairsResponsePayload,
	IAssetPairsStore,
	IAssetTopPairsItem,
} from './types';

// ==========================================:
export const initialState: IAssetPairsStore = {
	assetPairs: null,
	assetPairsFavorite: null,
	assetPairsLoader: false,
	assetPairsFeesAndLimits: null,
	assetPairsFeesAndLimitsLoader: false,
	assetTopPairs: null,
	assetTopPairsLoader: false,
	volume24: null,
};
// ==========================================:
const assetPairs = createSlice({
	name: '@@assetPairs',
	initialState,
	reducers: {
		getAssetPairsRequest: (state) => {
			const assetPairsRequestState = state;

			assetPairsRequestState.assetPairsLoader = true;
		},
		getAssetPairsSuccess: (state, action: PayloadAction<IAssetPairsResponsePayload>) => {
			const { payload } = action;
			const assetPairsSuccessState = state;

			assetPairsSuccessState.assetPairs = payload;
			assetPairsSuccessState.assetPairsLoader = false;
		},
		updateAssetPairsSuccess: (state, action: PayloadAction<IAssetPairsResponsePayload>) => {
			const { payload } = action;
			const assetPairsUpdateState = state;

			assetPairsUpdateState.assetPairs = payload;
			assetPairsUpdateState.assetPairsLoader = false;
		},

		getAssetPairsRequestFavorite: () => {},
		getAssetPairsSuccessFavorite: (state, action: PayloadAction<IApiGetFavoritePairsResponse>) => {
			state.assetPairsFavorite = action.payload;
		},
		getAssetPairsErrorFavorite: (state) => {
			state.assetPairsFavorite = null;
		},

		putAssetPairsRequestFavoriteId: (_, __: PayloadAction<IApiToggleFavoritePairParams>) => {},

		getAssetPairsFeesAndLimitsRequest: (state) => {
			const assetPairsFeesAndLimitsRequestState = state;

			assetPairsFeesAndLimitsRequestState.assetPairsFeesAndLimitsLoader = true;
		},
		getAssetPairsFeesAndLimitsSuccess: (
			state,
			action: PayloadAction<IAssetPairsFeesAndLimitsResponsePayload>,
		) => {
			const { payload } = action;
			const assetPairsFeesAndLimitsSuccessState = state;

			assetPairsFeesAndLimitsSuccessState.assetPairsFeesAndLimits = payload;
			assetPairsFeesAndLimitsSuccessState.assetPairsFeesAndLimitsLoader = false;
		},

		getTopAssetPairsRequest: (state) => {
			const assetPairsRequestState = state;
			assetPairsRequestState.assetTopPairsLoader = true;
		},
		getTopAssetPairsSuccess: (state, action: PayloadAction<IAssetTopPairsItem[]>) => {
			const { payload } = action;
			const assetPairsSuccessState = state;

			assetPairsSuccessState.assetTopPairs = payload;
			assetPairsSuccessState.assetTopPairsLoader = false;
		},
		getTopAssetPairsError: (state) => {
			const assetPairsErrorState = state;
			assetPairsErrorState.assetTopPairsLoader = false;
		},

		getVolume24Request: () => {},

		getVolume24Success: (state, action: PayloadAction<any>) => {
			const { payload } = action;
			const volume24UsdState = state;

			volume24UsdState.volume24 = payload;
		},

		assetPairsInitState: () => initialState,
	},
});

export default assetPairs.reducer;
export const {
	getAssetPairsRequest,
	putAssetPairsRequestFavoriteId,
	getAssetPairsRequestFavorite,
	getAssetPairsSuccessFavorite,
	getAssetPairsErrorFavorite,
	getAssetPairsSuccess,
	updateAssetPairsSuccess,
	getAssetPairsFeesAndLimitsRequest,
	getAssetPairsFeesAndLimitsSuccess,
	getTopAssetPairsRequest,
	getTopAssetPairsSuccess,
	getTopAssetPairsError,
	assetPairsInitState,
	getVolume24Request,
	getVolume24Success,
} = assetPairs.actions;
