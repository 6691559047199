import { FC, useState } from 'react';
import L from 'i18n-react';
import BorrowModal from 'components/MarginWallets/modals/Borrow';
import RepayModal from 'components/MarginWallets/modals/Repay';
import TransferModal from 'components/MarginWallets/modals/Transfer';
import { riskSwitcher } from 'services/utils/riskSwitcher';
import { IMarginWalletIsolatedTableRow } from './types';

const MarginWalletIsolatedTableRow: FC<IMarginWalletIsolatedTableRow> = ({ item, refetch }) => {
	const {
		pair: { id: assetPairId, code: pair, shoulder_isolate: shoulderIsolate },
		risk_rate: riskRate,
		asset: { id: assetId, name: coin, code, img_path: imgPath, is_crypto: isCrypto },
		total,
		balance,
		borrow,
		borrow_in_btc: borrowInBtc,
		interest_amount: interestAmount,
		interest_amount_in_btc: interestAmountInBtc,
		btc_value: btcValue,
		usd_value: usdValue,
	} = item;

	const [borrowOpened, setBorrowOpened] = useState<boolean>(false);
	const [repayOpened, setRepayOpened] = useState<boolean>(false);
	const [transferOpened, setTransferOpened] = useState<boolean>(false);

	const openBorrow = () => {
		setBorrowOpened(true);
	};

	const closeBorrow = () => {
		setBorrowOpened(false);
	};

	const openRepay = () => {
		setRepayOpened(true);
	};

	const closeRepay = () => {
		setRepayOpened(false);
	};

	const openTransfer = () => {
		setTransferOpened(true);
	};

	const closeTransfer = () => {
		setTransferOpened(false);
	};

	const risk = riskSwitcher(riskRate);

	return (
		<>
			<div className="tr">
				<div className="td">
					<p>{pair?.replace('_', '/').toUpperCase()}</p>
				</div>
				<div className="td">
					<p style={{ color: risk.color }}>{riskRate}</p>
					<span style={{ color: risk.color }}>{risk.label}</span>
				</div>
				<div className="td">
					<p>{shoulderIsolate}</p>
				</div>
				<div className="td">
					<div className="currency-item currency-item--type2">
						<div className="currency-item__icon">
							<img src={imgPath} alt="" />
						</div>
						<div className="currency-item__name">
							<p>{code?.toUpperCase()}</p>
							<div className="currency-item__short-name">
								<p>{coin}</p>
							</div>
						</div>
					</div>
				</div>
				<div className="td">
					<p>{Number(total).toFixed(isCrypto ? 6 : 2)}</p>
					<span>{Number(btcValue).toFixed(isCrypto ? 6 : 2)}</span>
				</div>
				<div className="td">
					<p>{Number(balance).toFixed(6)}</p>
					{/* TODO: Find out what field have be here */}
					<span>{Number(balance).toFixed(6)}</span>
				</div>
				<div className="td">
					<p>{Number(borrow).toFixed(6)}</p>
					<span>{Number(borrowInBtc).toFixed(6)}</span>
				</div>
				<div className="td">
					<p>{Number(interestAmount).toFixed(6)}</p>
					<span>{Number(interestAmountInBtc).toFixed(6)}</span>
				</div>
				<div className="td">
					<p>{Number(btcValue).toFixed(6)}</p>
					<span>{Number(usdValue).toFixed(6)}</span>
				</div>
				<div className="td">
					<div className="flex flex-e flex--gap24">
						<button type="button" onClick={openBorrow} className="button button--type-text">
							{String(L.translate('Wallets.actions.borrow'))}
						</button>
						<button type="button" onClick={openRepay} className="button button--type-text">
							{String(L.translate('Wallets.actions.repay'))}
						</button>
						<button type="button" onClick={openTransfer} className="button button--type-text">
							{String(L.translate('Wallets.actions.transfer'))}
						</button>
					</div>
				</div>
			</div>
			{borrowOpened && (
				<BorrowModal
					onClose={closeBorrow}
					item={item}
					assetId={assetId}
					assetPairId={assetPairId}
					mode="isolated"
					refetch={refetch}
				/>
			)}
			{repayOpened && (
				<RepayModal
					onClose={closeRepay}
					item={item}
					assetId={assetId}
					assetPairId={assetPairId}
					mode="isolated"
					refetch={refetch}
				/>
			)}
			{transferOpened && (
				<TransferModal
					onClose={closeTransfer}
					assetId={assetId}
					assetPairId={assetPairId}
					mode="isolated"
					refetch={refetch}
				/>
			)}
		</>
	);
};

export default MarginWalletIsolatedTableRow;
