/* eslint-disable no-debugger */
import { FC, SyntheticEvent, useState, useEffect, useMemo } from 'react';
import L from 'i18n-react';
import useSelect from 'hooks/useSelect';
import { useFormikContext, FormikContextType } from 'formik';
import Loader from 'ui/Loader';
import PersentSlider from 'components/Trade/TradesBox/PersentSlider';
import { useSelector, useDispatch } from 'react-redux';
import { calculateTransformIsolate } from 'services/utils/calculateTransfer';
import { transformMarginsIsolatedData } from 'services/utils/transformMarginsIsolatedData';
import { IIsolatedWalletsData } from 'redux/reducers/marginWallets/types';
import { IWalletItem } from 'redux/reducers/wallets/types';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
// import { getMarginIndexPricesRequest } from 'redux/reducers/marginWallets/reducer';
// ------------------------------------------------------------------------------
import { getAssetsIsLoad, getAssetsList } from 'redux/reducers/assets/selectors';
import { getAssetPairsIsLoad, getAssetPairs } from 'redux/reducers/assetPairs/selectors';
import { getWalletsIsLoad, getWalletsList } from 'redux/reducers/wallets/selectors';
import {
	getMarginWalletsIsLoader,
	getIsolatedWalletsList,
} from 'redux/reducers/marginWallets/selectors';
import { getPerpetualBalance } from 'redux/reducers/perpetual/selectors';
import { getAvailableBalanceRequest } from 'redux/reducers/perpetual/reducer';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import { getAssetPairsRequest } from 'redux/reducers/assetPairs/reducer';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import { getMarginIsolatedWalletsRequest } from 'redux/reducers/marginWallets/reducer';
import { IAssetsItem, IAssetsData } from 'redux/reducers/assets/types';
import { IAssetPairsArray, IAssetPairsItem } from 'redux/reducers/assetPairs/types';
import ConvertSwitch from 'components/MarginWallets/modals/Transfer/TransferContent/ConvertSwitch';
import AssetDropItem from 'components/MarginWallets/modals/shared/AssetDropItem';
import WalletsList, { Item as WalletItem } from '../WalletsSelect';
import AssetsSelect from '../AssetsSelect';
import AssetsPairSelect from '../AssetsPairSelect';
import { SPOT, MARGIN, PERPETUALS } from './constants';
import { ITransferModalFormIsolated, Values } from './types';
import { getTransferWallets } from './utils';

const TransferContentIsolated: FC<ITransferModalFormIsolated> = ({ onClose }) => {
	const dispatch = useDispatch();

	const [assetsRequested, setAssetsRequested] = useState(false);
	const [pairsRequested, setPairsRequested] = useState(false);
	const [spotRequested, setSpotRequested] = useState(false);
	const [isolatedRequested, setIsolatedRequested] = useState(false);

	const assetsIsLoad = useSelector(getAssetsIsLoad);
	const assetPairsIsLoad = useSelector(getAssetPairsIsLoad);
	const spotWalletsIsLoad = useSelector(getWalletsIsLoad);
	const isolatedWalletsIsLoad = useSelector(getMarginWalletsIsLoader);

	const isFetching = assetsIsLoad || assetPairsIsLoad || spotWalletsIsLoad || isolatedWalletsIsLoad;

	const assets = useSelector(getAssetsList);
	const assetPairs = useSelector(getAssetPairs)?.assetPairs;
	const spotWallets = useSelector(getWalletsList);
	const isolatedWallets = useSelector(getIsolatedWalletsList)?.isolated;
	const perpetualBalance: number | null = useSelector(getPerpetualBalance)?.available_balance_usdt;

	const {
		values,
		setValues,
		isValid,
		errors,
		validateField,
		isSubmitting,
	}: FormikContextType<Values> = useFormikContext();

	const wallets = getTransferWallets();

	const spotWalletAssets = spotWallets?.map((sw) => sw.asset);

	const isolatedAssets = isolatedWallets?.map((iw) => iw.asset);

	const isolatedPairs = isolatedWallets?.map((iw) => iw.pair);

	const [filteredAssetsList, setFilteredAssetsList] = useState<IAssetsData | null>(null);

	const [filteredAssetPairsList, setFilteredAssetPairsList] = useState<IAssetPairsArray>(null);

	const selectedAsset = (values.asset_id && assets?.find((a) => a?.id === values.asset_id)) || null;

	const selectedAssetPair =
		(values.asset_pair_id &&
			assetPairs?.find((a: IAssetPairsItem) => a?.id === values.asset_pair_id)) ||
		null;

	const handleAssetSearchInput = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: targetValue } = target;
		const { asset_pair_id: assetPairId } = values;
		const filteredIsolatedWallets = isolatedWallets?.filter((iw) => iw?.pair?.id === assetPairId);
		const filteredAssetIds = filteredIsolatedWallets?.map((iw) => iw?.asset?.id) || [];
		const newFilteredAssetsList = assets?.filter((al) => filteredAssetIds.includes(al.id)) || [];
		const filtered = targetValue
			? newFilteredAssetsList?.filter((item) => item.code.match(new RegExp(targetValue, 'gi')))
			: newFilteredAssetsList;
		filtered && setFilteredAssetsList(filtered);
	};

	const handleAssetPairSearchInput = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: targetValue } = target;
		const filtered = targetValue
			? filteredAssetPairsList?.filter((item) => item.code.match(new RegExp(targetValue, 'gi')))
			: assetPairs;
		filtered && setFilteredAssetPairsList(filtered);
	};

	// -------------------------------------------------------------------------------

	useEffect(() => {
		if (filteredAssetsList === null && spotWalletAssets) {
			setFilteredAssetsList(
				assets?.filter((item) => {
					return spotWalletAssets?.find((swa) => swa.id === item.id);
				}) || [],
			);
		}
	}, [assets, filteredAssetsList, spotWalletAssets]);

	useEffect(() => {
		if (filteredAssetPairsList === null && isolatedPairs) {
			setFilteredAssetPairsList(
				assetPairs?.filter((item) => {
					return isolatedPairs?.find((iw) => iw.id === item.id);
				}) || [],
			);
		}
	}, [assetPairs, filteredAssetPairsList, isolatedPairs]);

	useEffect(() => {
		dispatch(getAvailableBalanceRequest());
	}, [dispatch]);

	useEffect(() => {
		if ((!assets || assets?.length === 0) && !assetsIsLoad && !assetsRequested) {
			dispatch(getAssetsRequest());
			setAssetsRequested(true);
		}
	}, [assets, assetsIsLoad, assetsRequested, dispatch]);

	useEffect(() => {
		if ((!assetPairs || assetPairs?.length === 0) && !assetPairsIsLoad && !pairsRequested) {
			dispatch(getAssetPairsRequest());
			setPairsRequested(true);
		}
	}, [assetPairs, assetPairsIsLoad, dispatch, pairsRequested]);

	useEffect(() => {
		if ((!spotWallets || spotWallets?.length === 0) && !spotWalletsIsLoad && !spotRequested) {
			dispatch(getWalletsRequest());
			setSpotRequested(true);
		}
	}, [dispatch, spotRequested, spotWallets, spotWalletsIsLoad]);

	useEffect(() => {
		if (
			(!isolatedWallets || isolatedWallets?.length === 0) &&
			!isolatedWalletsIsLoad &&
			!isolatedRequested
		) {
			dispatch(getMarginIsolatedWalletsRequest({}));
			setIsolatedRequested(true);
		}
	}, [dispatch, isolatedRequested, isolatedWallets, isolatedWalletsIsLoad]);

	// -------------------------------------------------------------------------------

	const { from, to } = values;

	const fromWallets = wallets.filter((item) => {
		if (to) {
			if (to?.id === PERPETUALS) {
				return item.id !== to?.id && item.id !== MARGIN;
			}
			return item.id !== to?.id;
		}
		return item;
	});

	const toWallets = wallets.filter((item) => {
		if (from) {
			if (from?.id === PERPETUALS) {
				return item.id !== from?.id && item.id !== MARGIN;
			}
			return item.id !== from?.id;
		}
		return item;
	});

	const handleFromSelect = (item: WalletItem) => {
		let { to: newTo } = values;
		if (item?.id === PERPETUALS && to?.id === MARGIN) {
			newTo = null;
		}
		setValues(
			{
				...values,
				from: item,
				to: newTo,
			},
			false,
		);
	};

	const handleToSelect = (item: WalletItem) => {
		let { from: newFrom } = values;
		if (item?.id === PERPETUALS && from?.id === MARGIN) {
			newFrom = null;
		}
		setValues(
			{
				...values,
				to: item,
				from: newFrom,
			},
			false,
		);
	};

	const handleFromClear = () => {
		setValues(
			{
				...values,
				from: null,
			},
			false,
		);
	};

	const handleToClear = () => {
		setValues(
			{
				...values,
				to: null,
			},
			false,
		);
	};

	const available = useMemo(() => {
		if (from?.id === SPOT) {
			if (to?.id === MARGIN) {
				const selectedSpot = spotWallets?.find((sw) => sw.asset.id === selectedAsset?.id);
				const balance = Number(selectedSpot?.balance);
				return balance || balance === 0 ? balance : null;
			}
			if (to?.id === PERPETUALS) {
				const selectedSpot = spotWallets?.find((sw) => sw.asset.code === 'usdt');
				const balance = Number(selectedSpot?.balance);
				return balance || balance === 0 ? balance : null;
			}
		}
		if (from?.id === MARGIN) {
			let selectedIsolated;
			if (selectedAsset) {
				selectedIsolated = isolatedWallets
					?.filter((iw) => iw.pair.id === selectedAssetPair?.id)
					.find((item) => item.asset.id === selectedAsset.id);
			} else {
				selectedIsolated = isolatedWallets?.find((iw) => iw.pair.id === selectedAssetPair?.id);
			}
			return selectedIsolated?.balance || null;
		}
		if (from?.id === PERPETUALS) {
			return perpetualBalance;
		}
		return null;
	}, [
		from?.id,
		isolatedWallets,
		perpetualBalance,
		selectedAsset,
		selectedAssetPair,
		spotWallets,
		to?.id,
	]);

	const availableCode = useMemo(() => {
		if (from?.id === SPOT) {
			if (to?.id === MARGIN) {
				return selectedAsset?.code;
			}
			if (to?.id === PERPETUALS) {
				return 'usdt';
			}
		}
		if (from?.id === MARGIN) {
			const selectedIsolated = isolatedWallets?.find((iw) => iw.pair.id === selectedAssetPair?.id);
			return selectedAsset?.code || selectedIsolated?.asset?.code;
		}
		if (from?.id === PERPETUALS) {
			return 'usdt';
		}
		return null;
	}, [from?.id, isolatedWallets, selectedAsset?.code, selectedAssetPair?.id, to?.id]);

	const handleClose = () => {
		onClose && onClose();
	};

	const handleSwitch = () => {
		const prevFrom = from;
		setValues(
			{
				...values,
				from: to,
				to: prevFrom,
			},
			false,
		);
	};

	const handleAmountChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: amountTarget } = target;
		if (amountTarget && !/^\d+((\.|,)\d*)?$/.test(amountTarget)) {
			setValues({
				...values,
				amount: values.amount || '',
			});
			return;
		}
		const amount = amountTarget.replace(',', '.');
		let newValues = {
			...values,
			amount,
		};
		if (Number(available)) {
			const percent = (Number(amount) * 100) / Number(available);
			newValues = {
				...newValues,
				percent,
			};
		}
		setValues(newValues);
	};

	// TODO: Удалить, оптимизировать слайдер
	const countOrder = (value: number) => {};

	const percentButtonCountValue = (percentValue: number): number => {
		if (!Number(percentValue)) {
			return 0;
		}
		return available ? (Number(available) * percentValue) / 100 : 0;
	};

	const handlePercentChange = (percent: number | number[]) => {
		if (!Array.isArray(percent)) {
			const value = percentButtonCountValue(Number(percent));
			setValues({
				...values,
				amount: value,
				percent,
			});
		}
	};

	const handleAssetChange: (item: IAssetsItem) => void = (item) => {
		const { id } = item;
		setValues(
			{
				...values,
				asset_id: id,
			},
			false,
		);
	};

	const handleAssetPairChange: (item: IAssetPairsItem) => void = (item) => {
		const { id } = item;
		setValues(
			{
				...values,
				asset_pair_id: id,
			},
			false,
		);
		const filteredIsolatedWallets = isolatedWallets?.filter((iw) => iw?.pair?.id === id);
		const filteredAssetIds = filteredIsolatedWallets?.map((iw) => iw?.asset?.id) || [];
		const newFilteredAssetsList = assets?.filter((al) => filteredAssetIds.includes(al.id)) || [];
		setFilteredAssetsList(newFilteredAssetsList);
	};

	const handleMaxAmount = () => {
		setValues({
			...values,
			amount: available || 0,
			percent: 100,
		});
	};

	const submitDisabled = !isValid || (!values.amount && values.amount !== 0) || isSubmitting;

	// const currentAssetCode = assetsList?.find((el) => el.id === values.asset_id)?.code;

	const perpetualSelected = from?.id === PERPETUALS || to?.id === PERPETUALS;

	const amountDisabled = useMemo(() => {
		if (from?.id === PERPETUALS || to?.id === PERPETUALS) {
			return !from && !to;
		}
		return !from || !to || !values.asset_id || !values.asset_pair_id;
	}, [from, to, values.asset_id, values.asset_pair_id]);

	// console.log(available);
	// console.log(availableCode);
	// console.log('Selected Asset', selectedAsset);

	return (
		<div className="popup">
			<button type="button" onClick={handleClose} className="popup__close">
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
						fill="#1C1C29"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
						fill="#1C1C29"
					/>
				</svg>
			</button>
			<div className="popup-header">
				<p className="popup-header__title">
					{String(L.translate('Wallets.MarginAccount.modals.transfer'))}
				</p>
			</div>
			<div className="popup-body">
				{isSubmitting || isFetching ? (
					<div className="popup-loader">
						<Loader />
					</div>
				) : (
					<>
						<div className="flex-column">
							<WalletsList
								value={from?.title}
								placeholder={String(L.translate('Wallets.MarginAccount.modals.from'))}
								onClear={handleFromClear}
								items={fromWallets}
								onSelect={handleFromSelect}
							/>
							<ConvertSwitch handleSwitch={handleSwitch} />
							<WalletsList
								value={to?.title}
								items={toWallets}
								placeholder={String(L.translate('Wallets.MarginAccount.modals.to'))}
								onClear={handleToClear}
								onSelect={handleToSelect}
							/>
							{!perpetualSelected && (
								<>
									<div className="convert-form-item">
										<div className="convert-form-item__header">
											<p>{L.translate('Wallets.MarginAccount.modals.asset_pair')}</p>
										</div>
										<AssetsPairSelect
											value={selectedAssetPair}
											assetPairsList={filteredAssetPairsList}
											onSelect={handleAssetPairChange}
											onSearch={handleAssetPairSearchInput}
										/>
									</div>
									<div className="convert-form-item">
										<div className="convert-form-item__header">
											<p>{L.translate('Wallets.MarginAccount.modals.asset')}</p>
										</div>
										<AssetsSelect
											value={selectedAsset}
											assetsList={filteredAssetsList}
											onSelect={handleAssetChange}
											onSearch={handleAssetSearchInput}
											disabled={!values.asset_pair_id}
										/>
									</div>
								</>
							)}
							<div className="convert-form-item">
								<div className="convert-form-item__header">
									<p>{String(L.translate('Wallets.MarginAccount.modals.amount'))}</p>
									<span>
										{String(L.translate('Wallets.MarginAccount.modals.available'))}:{' '}
										{available || available === 0 ? available : '--'} {availableCode?.toUpperCase()}
									</span>
								</div>
								<div className="convert-form-item__field">
									<div className="input">
										<div className="input-wrapper">
											<input
												value={values.amount}
												onChange={handleAmountChange}
												className="input-item input-item--pr50"
												type="text"
												disabled={amountDisabled}
												placeholder={
													available
														? String(
																L.translate('Wallets.MarginAccount.modals.available_placeholder', {
																	available: String(available),
																}),
														  )
														: ''
												}
											/>
											<div className="input-icon input-icon--auto input-icon--right">
												<button type="button" onClick={handleMaxAmount} className="input-button">
													{L.translate('Wallets.MarginAccount.modals.max_button')}
												</button>
											</div>
										</div>
										<span className="input-notify">{errors?.amount}</span>
									</div>
								</div>
							</div>
							<PersentSlider
								className="persent-slider"
								value={values.percent}
								onChange={handlePercentChange}
								countOrder={countOrder}
								percentButtonCountValue={percentButtonCountValue}
							/>
						</div>
					</>
				)}
			</div>
			<div className="popup-footer">
				<button
					type="submit"
					className="button button--size2 button--full-width"
					disabled={submitDisabled || amountDisabled}
				>
					{String(L.translate('Wallets.MarginAccount.modals.confirm'))}
				</button>
			</div>
		</div>
	);
};

export default TransferContentIsolated;
