import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import L from 'i18n-react';
import { navList } from 'routes/routesList';
import { getAssetPairsList } from 'redux/reducers/assetPairs/selectors';
import { setCurrentPair } from 'redux/reducers/tradingSettings/reducer';
import { ISpotWalletsItemProps } from './types';

export const SpotWalletsItem: FC<ISpotWalletsItemProps> = ({ item }) => {
	const {
		total,
		balance,
		frozen_balance: frozenBalance,
		btc_value: btcValue,
		usd_value: usdValue,
		asset: { name, img_path: imgPath },
	} = item;

	const dispatch = useDispatch();
	const history = useHistory();

	const assetPairs = useSelector(getAssetPairsList);

	const pairWithUsdt = assetPairs?.find(({ code }) => code === `${item.asset.code}_usdt`);
	const isCrypto = !!item.asset.is_crypto;

	const handleTrade = () => {
		if (!pairWithUsdt) return;
		const { code, id } = pairWithUsdt;

		dispatch(setCurrentPair({ pair: code, id }));
		history.push(`/spot/${code.toUpperCase()}`);
	};

	const handleDeposit = () => {
		if (isCrypto) {
			history.push(navList.depositCrypto.path, { initAsset: item.asset });
		}
	};

	const handleWithdraw = () => {
		if (isCrypto) {
			history.push(navList.withdrawCrypto.path, { initWallet: item });
		}
	};

	return (
		<div className="tr">
			<div className="td">
				<div className="currency-item currency-item--type2">
					<div className="currency-item__icon">
						<img src={imgPath} alt="" />
					</div>
					<div className="currency-item__name">
						<p>{item.asset.code.toUpperCase()}</p>
						<div className="currency-item__short-name">
							<p>{name}</p>
						</div>
					</div>
				</div>
			</div>

			<div className="td">
				<p>{Number(total).toFixed(2)}</p>
			</div>

			<div className="td">
				<p>{Number(balance).toFixed(2)}</p>
			</div>

			<div className="td">
				<p>{Number(frozenBalance).toFixed(2)}</p>
			</div>

			<div className="td">
				<p>{Number(btcValue).toFixed(2)}</p>
				<span>≈ ${Number(usdValue).toFixed(2)}</span>
			</div>

			<div className="td">
				<div className="flex flex-e flex--gap24">
					{!!item.asset.depositable && (
						<button type="button" className="button button--type-text" onClick={handleDeposit}>
							{L.translate('SpotWallet.button_deposit')}
						</button>
					)}

					{!!item.asset.withdrawable && (
						<button type="button" className="button button--type-text" onClick={handleWithdraw}>
							{L.translate('SpotWallet.button_withdraw')}
						</button>
					)}

					{isCrypto && pairWithUsdt && (
						<button type="button" className="button button--type-text" onClick={handleTrade}>
							{L.translate('SpotWallet.button_trade')}
						</button>
					)}
				</div>
			</div>
		</div>
	);
};
