import { FC, useState, useRef, useEffect } from 'react';
import L from 'i18n-react';
import { getTwoFaEnableStatus } from 'redux/reducers/twoFa/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { twoFaCheckEnabledRequest } from 'redux/reducers/twoFa/reducer';
import {
	checkAntiPhishingStateRequest,
	disableAntiPhishingRequest,
} from 'redux/reducers/settings/reducer';
import { getAntiphishingLoader, getAntiphishingStatus } from 'redux/reducers/settings/selectors';
import Loader from 'ui/Loader';

import AccountSettingsGeneralPassword from '../AccountSettingsGeneralPassword';
import VerificateKycModal from '../Modals/KYCVerification';
import AntiPhishing from '../Modals/Antiphishing';
import ChangePhonePopup from '../ChangePhonePopup/ChangePhonePopup';
import { IUsersSettingsProps } from './types';
import SetPhonePopup from '../SetPhonePopup';

const AccountSettingsGeneralItem: FC<IUsersSettingsProps> = ({ user, changePasswordSubmit }) => {
	const dispatch = useDispatch();
	const [startVerification, setStartVerification] = useState<boolean>(false);
	const [enableAntiphishing, setEnableAntiphishing] = useState<boolean>(false);
	const [newPhonePopup, setNewPhonePopup] = useState(false);
	const twoFaEnableStatus = useSelector(getTwoFaEnableStatus);
	// const antiPhishingStatus = useSelector(getAntiphishingStatus);
	const antiPhishingStatus = true;
	const loader = useSelector(getAntiphishingLoader);

	useEffect(() => {
		dispatch(twoFaCheckEnabledRequest());
		dispatch(checkAntiPhishingStateRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleStartVerification = () => {
		setStartVerification(true);
	};
	const handleEnableAntiphishing = () => {
		setEnableAntiphishing(!enableAntiphishing);
	};

	useEffect(() => {
		setNewPhonePopup(false);
	}, [user?.data.phone]);

	const [phoneChangePopup, setPhoneChangePopup] = useState(false);

	const openPhoneChangePopup = () => {
		if (user?.data.phone) {
			setNewPhonePopup(true);
			return;
		}
		setPhoneChangePopup(true);
	};
	const closePhoneChangePopup = () => {
		setPhoneChangePopup(false);
	};

	const disableAntiPhishing = () => {
		dispatch(disableAntiPhishingRequest());
	};

	const antiPhishingButtonClick = () => {
		if (!antiPhishingStatus && twoFaEnableStatus === 'google2fa_enabled') {
			handleEnableAntiphishing();
		}
		if (antiPhishingStatus && twoFaEnableStatus === 'google2fa_enabled') {
			disableAntiPhishing();
		}
	};

	return (
		<>
			<div className="setting-content">
				<div className="setting-content-header flex flex-sb">
					<div className="setting-content-header__title">
						<p>{String(L.translate('Account.AccountSettings.general'))}</p>
					</div>
				</div>
				<div className="setting-content-body">
					<div className="setting-content-body-left">
						<div className="setting-content-body-item">
							<div className="setting-content-body-item-header">
								<div className="setting-content-body-item-header__icon">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89136 2.75 2.75 6.89136 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M8.75 9C8.75 7.20509 10.2051 5.75 12 5.75C13.7949 5.75 15.25 7.20509 15.25 9C15.25 10.7949 13.7949 12.25 12 12.25C10.2051 12.25 8.75 10.7949 8.75 9ZM12 7.25C11.0335 7.25 10.25 8.03351 10.25 9C10.25 9.96649 11.0335 10.75 12 10.75C12.9665 10.75 13.75 9.96649 13.75 9C13.75 8.03351 12.9665 7.25 12 7.25Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M9.99977 15.25C7.74825 15.25 5.90517 17.0012 5.75914 19.2154C5.73189 19.6287 5.37473 19.9416 4.96141 19.9144C4.5481 19.8871 4.23514 19.53 4.2624 19.1166C4.46007 16.1194 6.95289 13.75 9.99977 13.75H13.9998C17.0426 13.75 19.5329 16.1131 19.7364 19.1048C19.7645 19.518 19.4523 19.8758 19.039 19.9039C18.6258 19.932 18.268 19.6198 18.2398 19.2065C18.0895 16.9966 16.2483 15.25 13.9998 15.25H9.99977Z"
											fill="#7C7CA6"
										/>
									</svg>
								</div>
								<p>{String(L.translate('Account.AccountSettings.user_data'))}</p>
							</div>
							<div className="setting-user-data">
								<div className="setting-user-data-item">
									<div className="setting-user-data-item__title">
										<p>{String(L.translate('Account.AccountSettings.first_name'))}:</p>
									</div>
									<div className="setting-user-data-item__info">
										<p>{user?.data.first_name ? user?.data.first_name : '-'}</p>
									</div>
								</div>
								<div className="setting-user-data-item">
									<div className="setting-user-data-item__title">
										<p>{String(L.translate('Account.AccountSettings.last_name'))}:</p>
									</div>
									<div className="setting-user-data-item__info">
										<p>{user?.data.last_name ? user?.data.last_name : '-'}</p>
									</div>
								</div>
								<div className="setting-user-data-item">
									<div className="setting-user-data-item__title">
										<p>{String(L.translate('Account.AccountSettings.address'))}:</p>
									</div>
									<div className="setting-user-data-item__info">
										<p>{user?.data.address ? user?.data.address : '-'}</p>
									</div>
								</div>
								<div className="setting-user-data-item">
									<div className="setting-user-data-item__title">
										<p>{String(L.translate('Account.AccountSettings.phone_number'))}:</p>
									</div>
									<div className="setting-user-data-item__info">
										<p>{user?.data?.phone}</p>
										{user?.data?.phone ? (
											<>
												<button
													type="submit"
													onClick={openPhoneChangePopup}
													className="button button--type-text"
												>
													<svg
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M2.75 21C2.75 20.5858 3.08579 20.25 3.5 20.25H21.5C21.9142 20.25 22.25 20.5858 22.25 21C22.25 21.4142 21.9142 21.75 21.5 21.75H3.5C3.08579 21.75 2.75 21.4142 2.75 21Z"
															fill="#FF57D4"
														/>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M15.8474 2.25C16.0464 2.24996 16.2373 2.32903 16.378 2.46979L20.0304 6.12384C20.3232 6.4167 20.3232 6.8914 20.0304 7.18426L9.68905 17.5302C9.54838 17.6709 9.35757 17.75 9.1586 17.75H5.5C5.08579 17.75 4.75 17.4142 4.75 17V13.36C4.75 13.1612 4.82889 12.9706 4.96935 12.8299L15.3169 2.46999C15.4575 2.32918 15.6484 2.25004 15.8474 2.25ZM15.8478 4.06109L6.25 13.6703V16.25H8.84784L18.4396 6.65405L15.8478 4.06109Z"
															fill="#FF57D4"
														/>
													</svg>
												</button>
											</>
										) : (
											<>
												<button
													onClick={openPhoneChangePopup}
													type="button"
													className="button button--type2"
												>
													{String(L.translate('Account.AccountSettings.add_phone_button'))}
												</button>
											</>
										)}
									</div>
								</div>
								<div className="setting-user-data-item">
									<div className="setting-user-data-item__title">
										<p>{String(L.translate('Account.AccountSettings.date_of_birth'))}:</p>
									</div>
									<div className="setting-user-data-item__info">
										<p>{user?.data.dob ? user?.data.dob : '-'}</p>
									</div>
								</div>
								<div className="setting-user-data-item">
									<div className="setting-user-data-item__title">
										<p>{String(L.translate('Account.AccountSettings.email'))}:</p>
									</div>
									<div className="setting-user-data-item__info">
										<p>{user?.email ? user?.email : '-'}</p>
									</div>
								</div>
								<div className="setting-user-data-item">
									<div className="setting-user-data-item__title">
										<p>{String(L.translate('Account.AccountSettings.kyc'))}:</p>
									</div>
									<div className="setting-user-data-item__info">
										<p>
											<span
												className={`${
													!user?.status.name ||
													user?.status.name === 'unverified' ||
													user?.status.name === 'rejected'
														? 'red'
														: ''
												}${user?.status.name === 'pending' ? 'yellow' : ''}${
													user?.status.name === 'approved' ? 'green' : ''
												}`}
											>
												{user?.status?.name === null ? '' : user?.status?.name.toUpperCase()}
											</span>
										</p>
										<button
											type="button"
											disabled={user?.status.name === 'pending' || user?.status.name === 'approved'}
											onClick={handleStartVerification}
											className="button button--type2"
										>
											{String(L.translate('Account.AccountSettings.start_verification'))}
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="setting-content-body-item">
							<div className="setting-content-body-item-header">
								<div className="setting-content-body-item-header__icon">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89136 2.75 2.75 6.89136 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M8.75 9C8.75 7.20509 10.2051 5.75 12 5.75C13.7949 5.75 15.25 7.20509 15.25 9C15.25 10.7949 13.7949 12.25 12 12.25C10.2051 12.25 8.75 10.7949 8.75 9ZM12 7.25C11.0335 7.25 10.25 8.03351 10.25 9C10.25 9.96649 11.0335 10.75 12 10.75C12.9665 10.75 13.75 9.96649 13.75 9C13.75 8.03351 12.9665 7.25 12 7.25Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M9.99977 15.25C7.74825 15.25 5.90517 17.0012 5.75914 19.2154C5.73189 19.6287 5.37473 19.9416 4.96141 19.9144C4.5481 19.8871 4.23514 19.53 4.2624 19.1166C4.46007 16.1194 6.95289 13.75 9.99977 13.75H13.9998C17.0426 13.75 19.5329 16.1131 19.7364 19.1048C19.7645 19.518 19.4523 19.8758 19.039 19.9039C18.6258 19.932 18.268 19.6198 18.2398 19.2065C18.0895 16.9966 16.2483 15.25 13.9998 15.25H9.99977Z"
											fill="#7C7CA6"
										/>
									</svg>
								</div>
								<p>{String(L.translate('Account.AccountSettings.enable_antiphishing'))}</p>
							</div>
							<div className="setting-anti-phishing">
								<div className="setting-anti-phishing__text">
									<p>{String(L.translate('Account.AccountSettings.antiphishing_code_message'))}</p>
								</div>
								{loader ? (
									<div className="recent-trades-loader-wrapper">
										<Loader />
									</div>
								) : (
									<button
										type="button"
										disabled={twoFaEnableStatus !== 'google2fa_enabled'}
										onClick={antiPhishingButtonClick}
										className="button button--size2 button--full-width"
									>
										{!antiPhishingStatus &&
											(twoFaEnableStatus === 'google2fa_enabled' ||
												twoFaEnableStatus === 'sms2fa_enabled') &&
											String(L.translate('Account.AccountSettings.enable_antiphishing'))}
										{antiPhishingStatus &&
											(twoFaEnableStatus === 'google2fa_enabled' ||
												twoFaEnableStatus === 'sms2fa_enabled') &&
											String(L.translate('Account.AccountSettings.disable_antiphishing'))}
										{!antiPhishingStatus &&
											twoFaEnableStatus === '2fa_disabled' &&
											String(L.translate('Account.AccountSettings.you_have_disabled_google2fa'))}
									</button>
								)}
							</div>
						</div>
					</div>
					<div className="setting-content-body-right">
						<div className="setting-content-body-item">
							<div className="setting-content-body-item-header">
								<div className="setting-content-body-item-header__icon">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M4 11.75C3.86193 11.75 3.75 11.8619 3.75 12V21C3.75 21.1381 3.86193 21.25 4 21.25H20C20.1381 21.25 20.25 21.1381 20.25 21V12C20.25 11.8619 20.1381 11.75 20 11.75H4ZM2.25 12C2.25 11.0335 3.0335 10.25 4 10.25H20C20.9665 10.25 21.75 11.0335 21.75 12V21C21.75 21.9665 20.9665 22.75 20 22.75H4C3.0335 22.75 2.25 21.9665 2.25 21V12Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M12 2.75C9.65281 2.75 7.75 4.65279 7.75 7V11C7.75 11.4142 7.41421 11.75 7 11.75C6.58579 11.75 6.25 11.4142 6.25 11V7C6.25 3.82436 8.82439 1.25 12 1.25C15.1756 1.25 17.75 3.82436 17.75 7V11C17.75 11.4142 17.4142 11.75 17 11.75C16.5858 11.75 16.25 11.4142 16.25 11V7C16.25 4.65279 14.3472 2.75 12 2.75Z"
											fill="#7C7CA6"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M12 14.25C12.4142 14.25 12.75 14.5858 12.75 15V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V15C11.25 14.5858 11.5858 14.25 12 14.25Z"
											fill="#7C7CA6"
										/>
									</svg>
								</div>
								<p>{String(L.translate('Account.AccountSettings.change_password'))}</p>
							</div>
							<AccountSettingsGeneralPassword changePasswordSubmit={changePasswordSubmit} />
						</div>
					</div>
				</div>
			</div>
			{startVerification && <VerificateKycModal closeModal={() => setStartVerification(false)} />}
			{enableAntiphishing && <AntiPhishing closeModal={() => setEnableAntiphishing(false)} />}
			{newPhonePopup && <SetPhonePopup closeModal={() => setNewPhonePopup(false)} />}
			<ChangePhonePopup
				phoneChangePopup={phoneChangePopup}
				closePhoneChangePopup={closePhoneChangePopup}
			/>
		</>
	);
};

export default AccountSettingsGeneralItem;
