import WithdrawCrypto from 'components/Wallet/WithdrawCrypto/WithdrawCrypto';
import { WalletLayout } from 'layouts/Wallet';

const WithdrawCryptoPage = () => {
	return (
		<WalletLayout title="Withdrawal Crypto">
			<WithdrawCrypto />
		</WalletLayout>
	);
};

export default WithdrawCryptoPage;
