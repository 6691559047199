import { FC, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import L from 'i18n-react';
import yup from 'services/utils/capsLockValidate';
import emailRegex from 'services/utils/emailRegex';
import Input from 'ui/Formik/Input';
import { ILoginForm, ILoginSubmitValue } from './types';

export const LoginForm: FC<ILoginForm> = ({ loginSubmit }) => {
	const [isShowPass, setIsShowPass] = useState(false);

	const initialValues = {
		email: '',
		password: '',
		totp: '',
	};
	/* eslint-disable no-debugger */
	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required(String(L.translate('Forms.Validations.email_required')))
			.email(String(L.translate('Forms.Validations.email_invalid')))
			.matches(emailRegex, String(L.translate('Forms.Validations.email_invalid')))
			.max(60, String(L.translate('Forms.Validations.email_max_length', { maxLength: 60 }))),
		password: yup
			.string()
			.required(String(L.translate('Forms.Validations.password_required')))
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				String(L.translate('Forms.Validations.password_pattern_1')),
			)
			.max(25, String(L.translate('Forms.Validations.password_max_length', { maxLength: 25 }))),
	});

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: ILoginSubmitValue, { resetForm, setSubmitting }) => {
				loginSubmit(values);
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty }) => (
				<Form>
					<p className="authorization__title">{L.translate('Forms.Login.title')}</p>
					<div className="authorization__details">
						<p>{L.translate('Forms.Login.subtitle')}</p>
					</div>

					<div className="authorization__form">
						<Field
							type="email"
							placeholder={L.translate('Forms.Login.email_placeholder')}
							name="email"
							required
							component={Input}
						/>
						<Field
							type={isShowPass ? 'text' : 'password'}
							placeholder={L.translate('Forms.Login.password_placeholder')}
							name="password"
							required
							component={Input}
							ariaLabel="show-password"
							showPass={isShowPass}
							setIsShowPass={handlePassDisplay}
						/>
					</div>
					<div className="authorization__options">
						<Link to="/forgot-password" className="authorization__forgot-link">
							{L.translate('Forms.Login.forgot_password')}
						</Link>
					</div>
					<div className="form-submit">
						<button
							disabled={!(isValid && dirty) || isSubmitting}
							type="submit"
							className="button  button--wide button--size2"
						>
							{L.translate('Forms.Login.submit_btn')}
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};
