import React from 'react';
import Trading from 'layouts/Trading';
import Glossary from 'components/StaticPages/glosary';

const GlossaryPage = () => {
	return (
		<Trading title="Glossary">
			<Glossary />
		</Trading>
	);
};

export default GlossaryPage;
