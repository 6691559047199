import React from 'react';
import Trading from 'layouts/Trading';
import Perpetual from 'components/StaticPages/perpetual';

const PerpetualPage = () => {
	return (
		<Trading title="Perpetual">
			<Perpetual />
		</Trading>
	);
};

export default PerpetualPage;
