import { FC } from 'react';
import { useSelector } from 'react-redux';
import L from 'i18n-react';
import { getReferralBonuses, getReferralBonusesLoading } from 'redux/reducers/settings/selectors';
import { TableItem } from './TableItem';

export const BonusesTable: FC = () => {
	const bonuses = useSelector(getReferralBonuses);
	const loading = useSelector(getReferralBonusesLoading);

	return (
		<div className="table table--refferals table--refferals-bonuses">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p>{L.translate('Account.Referral.bonuses_email')}</p>
					</div>
					<div className="td">
						<p>{L.translate('Account.Referral.bonuses_phone')}</p>
					</div>
					<div className="td td--right">
						<p>{L.translate('Account.Referral.bonuses_total')}</p>
					</div>
				</div>
			</div>

			<div className="table-body">
				{!loading && bonuses?.data.map((item) => <TableItem key={item.user_id} item={item} />)}
				{bonuses && bonuses.data.length === 0 && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							paddingTop: '20px',
						}}
					>
						There is no bonuses
					</div>
				)}
			</div>
		</div>
	);
};
