import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	ICalculateLimitOrderData,
	ICalculateMarketOrderData,
	IOrdersStore,
	IAllOrderTypes,
} from './types';

// ==========================================:
const getOrdersState = (state: TStoreState): IOrdersStore => state.orders;
export const getOrders = createSelector([getOrdersState], (orders: IOrdersStore) => orders);

// ====================================================:
export const getCalculateLimitOrder = createSelector(
	[getOrders],
	(orders: IOrdersStore): ICalculateLimitOrderData | null => orders.calculate.limitOrder,
);

// ====================================================:
export const getCalculateMarketOrder = createSelector(
	[getOrders],
	(orders: IOrdersStore): ICalculateMarketOrderData | null => orders.calculate.marketOrder,
);

// ====================================================:
export const getCalculateMarketLoader = createSelector(
	[getOrders],
	(orders: IOrdersStore): boolean => orders.dataLoader,
);

// ====================================================:
export const getTempOrderPrice = createSelector(
	[getOrders],
	(orders: IOrdersStore): number => orders.tempOrderPrice,
);

// ====================================================:
export const getAllOrderTypes = createSelector(
	[getOrders],
	(orders: IOrdersStore): IAllOrderTypes | null => orders.allOrderTypes,
);
