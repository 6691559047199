import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAntiPhishingCodeRequest } from 'redux/reducers/settings/reducer';

const AntiPhishing = ({ closeModal }: any) => {
	const [code, setCode] = useState<string>('');
	const [totp, setTotp] = useState('');
	const dispatch = useDispatch();

	const handleCodeChange = (e: any) => {
		const { value } = e.target;
		if (value.length > 20) {
			return;
		}
		if (/^[a-zA-Z0-9]*$/.test(value)) {
			setCode(value);
		}
	};

	const handleTotpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (value.length > 6) {
			return;
		}
		if (/^[0-9]*$/.test(value) && value.length <= 6) {
			setTotp(value);
		}
	};

	const handleConfirm = () => {
		dispatch(setAntiPhishingCodeRequest({ code, totp }));
		setCode('');
		setTotp('');
		closeModal();
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup">
					<button className="popup__close" type="button" onClick={closeModal}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
								fill="#1C1C29"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
								fill="#1C1C29"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title">Create Anti-Phishing Code</p>
					</div>
					<div className="popup-body">
						<div className="popup-text">
							<p>Authentic emails from 1EX will have this code</p>
						</div>
						<div className="popup-body-item">
							<div className="input">
								<div className="input-wrapper">
									<input
										value={code}
										onChange={handleCodeChange}
										className="input-item"
										type="text"
										placeholder="Enter your new anti-phishing code"
									/>
								</div>
								<div className="input__notification input__notification--type2">
									<p>
										<span className="red">*</span> Enter up to 20 numbers or letters
									</p>
								</div>
							</div>
						</div>
						<div className="popup-body-item">
							<div className="input">
								<div className="input-wrapper">
									<input
										value={totp}
										onChange={handleTotpChange}
										className="input-item"
										type="text"
										placeholder="Please enter the 6-digit code"
									/>
								</div>
								<div className="input__notification input__notification--type2">
									<p>
										<span className="red">*</span> Please, enter 6-numbers code!
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="popup-footer">
						<div className="popup-submit popup-submit--sb">
							<button
								className="button button--size2 button--full-width"
								onClick={handleConfirm}
								disabled={totp.length !== 6 || !code || code.length > 20}
								type="button"
							>
								Confirm
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default AntiPhishing;
