import L from 'i18n-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUsersSettings } from 'redux/reducers/settings/selectors';
import ModalTwoFaOption from 'components/AccountSettings/AccountSettingsTwoFa/EnableTwoFa/ModalTwoFaOption';
import TwoFaPhoneForm from 'components/AccountSettings/AccountSettingsTwoFa/EnableTwoFa/TwoFaPhoneForm';
import TwoFaAppForm from 'components/AccountSettings/AccountSettingsTwoFa/EnableTwoFa/TwoFaAppForm';
import { ETwoFaOption } from 'components/AccountSettings/AccountSettingsTwoFa/type';
import ChangePhonePopup from '../../AccountSettingsGeneral/ChangePhonePopup/ChangePhonePopup';

const EnableTwoFa = () => {
	const userData = useSelector(getUsersSettings);
	const [modalOpen, setModalOpen] = useState(false);
	const [appFormOpen, setAppFormOpen] = useState(false);
	const [phoneFormOpen, setPhoneFormOpen] = useState(false);
	const [addPhoneNumberForm, setAddPhoneNumberForm] = useState(false);
	const setModalOpenHandler = () => {
		setModalOpen(true);
	};
	const setModalCloseHandler = () => {
		setModalOpen(false);
	};
	const setAddPhoneNumberModalOpen = () => {
		setAddPhoneNumberForm(true);
	};
	const setAddPhoneNumberModalClose = () => {
		setAddPhoneNumberForm(false);
	};
	const onAddPhoneNumberSuccess = () => {
		setPhoneFormOpen(true);
	};
	const openForm = (type: ETwoFaOption) => {
		if (type === ETwoFaOption.APP) {
			setAppFormOpen(true);
		}
		if (type === ETwoFaOption.PHONE) {
			if (userData && !userData.data?.phone) {
				setAddPhoneNumberModalOpen();
			} else {
				setPhoneFormOpen(true);
			}
		}
	};
	const closeForm = () => {
		setAppFormOpen(false);
		setPhoneFormOpen(false);
	};
	return (
		<div>
			{!appFormOpen && !phoneFormOpen && (
				<div className="setting-content-body-item">
					<div className="setting-content-body-item-header">
						<div className="setting-content-body-item-header__icon">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2.25 3C2.25 2.58579 2.58579 2.25 3 2.25H7.5C7.91421 2.25 8.25 2.58579 8.25 3C8.25 3.41421 7.91421 3.75 7.5 3.75H3.75V7.5C3.75 7.91421 3.41421 8.25 3 8.25C2.58579 8.25 2.25 7.91421 2.25 7.5V3Z"
									fill="#7C7CA6"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3 15.75C3.41421 15.75 3.75 16.0858 3.75 16.5V20.25H7.5C7.91421 20.25 8.25 20.5858 8.25 21C8.25 21.4142 7.91421 21.75 7.5 21.75H3C2.58579 21.75 2.25 21.4142 2.25 21V16.5C2.25 16.0858 2.58579 15.75 3 15.75Z"
									fill="#7C7CA6"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M21 15.75C21.4142 15.75 21.75 16.0858 21.75 16.5V21C21.75 21.4142 21.4142 21.75 21 21.75H16.5C16.0858 21.75 15.75 21.4142 15.75 21C15.75 20.5858 16.0858 20.25 16.5 20.25H20.25V16.5C20.25 16.0858 20.5858 15.75 21 15.75Z"
									fill="#7C7CA6"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M15.75 3C15.75 2.58579 16.0858 2.25 16.5 2.25H21C21.4142 2.25 21.75 2.58579 21.75 3V7.5C21.75 7.91421 21.4142 8.25 21 8.25C20.5858 8.25 20.25 7.91421 20.25 7.5V3.75H16.5C16.0858 3.75 15.75 3.41421 15.75 3Z"
									fill="#7C7CA6"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M5.25 12C5.25 7.83242 8.16779 4.25 12 4.25C15.8322 4.25 18.75 7.83242 18.75 12C18.75 16.1676 15.8322 19.75 12 19.75C8.16779 19.75 5.25 16.1676 5.25 12ZM12 5.75C9.20481 5.75 6.75 8.43558 6.75 12C6.75 15.5644 9.20481 18.25 12 18.25C14.7952 18.25 17.25 15.5644 17.25 12C17.25 8.43558 14.7952 5.75 12 5.75Z"
									fill="#7C7CA6"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H21C21.4142 11.25 21.75 11.5858 21.75 12C21.75 12.4142 21.4142 12.75 21 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12Z"
									fill="#7C7CA6"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M14.6339 14.6229C14.8713 14.9623 14.7887 15.4299 14.4493 15.6674C13.6802 16.2053 12.86 16.5057 12.0003 16.5057C11.138 16.5057 10.3301 16.2035 9.58933 15.6559C9.25624 15.4097 9.18581 14.9401 9.43202 14.607C9.67824 14.2739 10.1479 14.2035 10.481 14.4497C11.0081 14.8393 11.5102 15.0057 12.0003 15.0057C12.4929 15.0057 13.0188 14.8375 13.5894 14.4382C13.9288 14.2008 14.3965 14.2835 14.6339 14.6229Z"
									fill="#7C7CA6"
								/>
							</svg>
						</div>
						<p>{String(L.translate('Account.TwoFactorAuth.two_factor_auth_enable_title'))}</p>
					</div>
					<div className="settings-2fa">
						<div className="settings-2fa__text">
							<p>{String(L.translate('Account.TwoFactorAuth.two_factor_auth_enable_text'))}</p>
						</div>
						<div className="settings-2fa-item">
							<button
								type="button"
								onClick={setModalOpenHandler}
								className="button button--size2 button--full-width"
							>
								{String(L.translate('Account.TwoFactorAuth.two_factor_auth_enable_btn'))}
							</button>
						</div>
					</div>
				</div>
			)}
			{modalOpen && <ModalTwoFaOption onClose={setModalCloseHandler} onConfirm={openForm} />}
			{appFormOpen && <TwoFaAppForm onClose={closeForm} />}
			{phoneFormOpen && <TwoFaPhoneForm onClose={closeForm} />}

			{addPhoneNumberForm && (
				<ChangePhonePopup
					phoneChangePopup={addPhoneNumberForm}
					closePhoneChangePopup={setAddPhoneNumberModalClose}
					onSuccess={onAddPhoneNumberSuccess}
				/>
			)}
		</div>
	);
};

export default EnableTwoFa;
