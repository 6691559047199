import { FC, useState } from 'react';
import L from 'i18n-react';

const ContractDetails: FC<any> = ({ data }) => {
	const [showDetails, setShowDetails] = useState<boolean>(false);

	const showDetailsClick = () => {
		setShowDetails(!showDetails);
	};

	let dateExpires = data?.date_expires.replace(/[%]/g, '');

	if (dateExpires) {
		const dateExpFirst = dateExpires.slice(0, dateExpires.indexOf(':') - 2);
		const dateExpSecond = dateExpires.slice(dateExpires.indexOf(':') - 2);
		dateExpires = `${String(dateExpFirst)} ${String(dateExpSecond)}`;
	}

	return (
		<div className="orderform-content-item">
			<div className={`options-contract-details ${showDetails ? 'active' : ''}`}>
				<button
					type="button"
					className="options-contract-details__header"
					onClick={showDetailsClick}
				>
					<svg
						width="20"
						height="21"
						viewBox="0 0 20 21"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M15.4419 7.97603C15.686 8.2201 15.686 8.61583 15.4419 8.85991L10.4419 13.8599C10.1979 14.104 9.80214 14.104 9.55806 13.8599L4.55806 8.85991C4.31398 8.61583 4.31398 8.2201 4.55806 7.97603C4.80214 7.73195 5.19786 7.73195 5.44194 7.97603L10 12.5341L14.5581 7.97603C14.8021 7.73195 15.1979 7.73195 15.4419 7.97603Z"
							fill="#7C7CA6"
						/>
					</svg>
					<p>{String(L.translate('Trade.Options.OrderForm.ContractDetails.title'))}</p>
				</button>
				<div
					className="options-contract-details__list orderbook-body-item__scroll "
					style={{
						visibility: showDetails ? 'visible' : 'hidden',
						height: '100%',
						maxHeight: '265px',
					}}
				>
					{/* <div className="options-contract-details__item">
						<p>Price Source</p>
						<span>{data.mark_price}</span>
					</div>
					<div className="options-contract-details__item">
						<p>Contract Size</p>
						<span>{data.open_size}</span>
					</div>
					<div className="options-contract-details__item">
						<p>Min Order Size</p>
						<span>1ex BTC Index</span>
					</div>
					<div className="options-contract-details__item">
						<p>Tick Size</p>
						<span>1ex BTC Index</span>
					</div>
					<div className="options-contract-details__item">
						<p>Settlement Currency</p>
						<span>1ex BTC Index</span>
					</div> */}

					<div className="options-contract-details__item">
						<p>{String(L.translate('Trade.Options.OrderForm.ContractDetails.ask_price'))}</p>
						<span>{data?.ask_price || 0}</span>
					</div>
					<div className="options-contract-details__item">
						<p>{String(L.translate('Trade.Options.OrderForm.ContractDetails.ask_size'))}</p>
						<span>{data?.ask_size || 0}</span>
					</div>
					<div className="options-contract-details__item">
						<p>
							{String(L.translate('Trade.Options.OrderForm.ContractDetails.settlement_currency'))}
						</p>
						<span>{data?.asset_underlying || 0}</span>
					</div>
					<div className="options-contract-details__item">
						<p>{String(L.translate('Trade.Options.OrderForm.ContractDetails.bid_price'))}</p>
						<span>{data?.bid_price || 0}</span>
					</div>
					<div className="options-contract-details__item">
						<p>{String(L.translate('Trade.Options.OrderForm.ContractDetails.bid_size'))}</p>
						<span>{data?.bid_size || 0}</span>
					</div>
					<div className="options-contract-details__item">
						<p>{String(L.translate('Trade.Options.OrderForm.ContractDetails.expiration_date'))}</p>
						<span>{dateExpires || 0}</span>
					</div>
					<div className="options-contract-details__item">
						<p>{String(L.translate('Trade.Options.OrderForm.ContractDetails.delta'))}</p>
						<span>
							{Number(data?.delta)
								?.toFixed(2)
								.replace(/\.0{2}$/, '') || 0}
						</span>
					</div>
					<div className="options-contract-details__item">
						<p>{String(L.translate('Trade.Options.OrderForm.ContractDetails.gamma'))}</p>
						<span>
							{Number(data?.gamma)
								?.toFixed(6)
								.replace(/\.0{6}$/, '') || 0}
						</span>
					</div>
					<div className="options-contract-details__item">
						<p>{String(L.translate('Trade.Options.OrderForm.ContractDetails.expiration_type'))}</p>
						<span className="capitalize">{data?.name_expires || 0}</span>
					</div>
					<div className="options-contract-details__item">
						<p>{String(L.translate('Trade.Options.OrderForm.ContractDetails.mark_price'))}</p>
						<span>
							{Number(data?.mark_price)
								?.toFixed(2)
								.replace(/\.0{2}$/, '') || 0}
						</span>
					</div>
					<div className="options-contract-details__item">
						<p>{String(L.translate('Trade.Options.OrderForm.ContractDetails.open_size'))}</p>
						<span>{data?.open_size || 0}</span>
					</div>
					<div className="options-contract-details__item">
						<p>{String(L.translate('Trade.Options.OrderForm.ContractDetails.strike_price'))}</p>
						<span>{data?.strike_price || 0}</span>
					</div>
					<div className="options-contract-details__item">
						<p>{String(L.translate('Trade.Options.OrderForm.ContractDetails.teta'))}</p>
						<span>
							{Number(data?.teta)
								?.toFixed(4)
								.replace(/\.0{4}$/, '') || 0}
						</span>
					</div>
					<div className="options-contract-details__item">
						<p>{String(L.translate('Trade.Options.OrderForm.ContractDetails.vega'))}</p>
						<span>
							{Number(data?.vega)
								?.toFixed(2)
								.replace(/\.0{2}$/, '') || 0}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContractDetails;
