// import { useDispatch, useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';
import L from 'i18n-react';
// import { TTheme } from 'redux/reducers/settings/types';
import AppLogo from 'ui/AppLogo';
// import { changeTheme } from 'redux/reducers/settings/reducer';
// import { THEME_DARK, THEME_LIGHT } from 'redux/reducers/settings/constants';
// import logo from 'assets/img/logo.svg';
// import headerCount from 'assets/img/header-count.png';
// import { getTheme } from 'redux/reducers/settings/selectors';

const Footer = () => {
	// const dispatch = useDispatch();
	// const switchTheme = useSelector(getTheme);
	// const changeThemes = (theme: TTheme) => {
	// 	dispatch(changeTheme(theme));
	// };

	return (
		<footer>
			<div className="footer">
				<div className="footer__main flex flex-m flex-sb">
					<div className="footer__main-left flex flex-m">
						<AppLogo />
						<div className="footer-nav">
							<nav>
								<ul className="flex flex-m">
									<li>
										<NavLink to="/about">About us</NavLink>
									</li>
									<li>
										<NavLink to="/privacy">Privacy Policy</NavLink>
									</li>
									<li>
										<NavLink to="/terms">Terms of Use</NavLink>
									</li>
									<li>
										<NavLink to="/faq">FAQs</NavLink>
									</li>
									<li>
										<NavLink to="/security">AML Policy</NavLink>
									</li>
									<li>
										<NavLink to="/cookies">Cookie policy</NavLink>
									</li>
								</ul>
							</nav>
						</div>
					</div>
					<div className="footer__main-right">
						<div className="footer-nav">
							<nav>
								<ul>
									<a
										href="http://support.1ex.com/"
										target="_blank"
										rel="noreferrer"
										className="active"
									>
										{String(L.translate('Header.support'))}
									</a>
								</ul>
							</nav>
						</div>
					</div>
				</div>
				<div className="footer__bottom flex flex-m flex-sb">
					<div className="copyright flex flex-m">
						<div className="copyright__item">
							<p>© Copyright 2022</p>
						</div>
						<div className="copyright__item">
							<p>|</p>
						</div>
						<div className="copyright__item">
							<p>Trading Company</p>
						</div>
						<div className="copyright__item">
							<p>|</p>
						</div>
						<div className="copyright__item">
							<p>All Rights Reserved</p>
						</div>
					</div>
					<div className="social flex flex-m">
						<a href="https://www.facebook.com/1exboard">
							<svg
								width="26"
								height="26"
								viewBox="0 0 26 26"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="13" cy="13" r="13" fill="white" />
								<path
									className="fill"
									d="M16.4102 14.4375L16.8769 11.7229H13.9589V9.96123C13.9589 9.21855 14.3665 8.49463 15.6734 8.49463H17V6.1834C17 6.1834 15.7961 6 14.6451 6C12.242 6 10.6712 7.3002 10.6712 9.65391V11.7229H8V14.4375H10.6712V21H13.9589V14.4375H16.4102Z"
									fill="#1C1C29"
								/>
							</svg>
						</a>
						<a href="https://twitter.com/1exboard">
							<svg
								width="26"
								height="26"
								viewBox="0 0 26 26"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="13" cy="13" r="13" fill="white" />
								<path
									className="fill"
									d="M19.3554 10.2398C19.3655 10.382 19.3655 10.5242 19.3655 10.6664C19.3655 15.0031 16.066 20 10.0356 20C8.17767 20 6.45179 19.4617 5 18.5274C5.26397 18.5578 5.51775 18.568 5.79188 18.568C7.32485 18.568 8.73605 18.05 9.86296 17.1664C8.42133 17.1359 7.2132 16.1914 6.79694 14.8914C7.00001 14.9219 7.20304 14.9422 7.41626 14.9422C7.71067 14.9422 8.00511 14.9015 8.2792 14.8305C6.77666 14.5258 5.64972 13.2055 5.64972 11.6109V11.5703C6.08625 11.8141 6.59391 11.9664 7.13195 11.9867C6.24869 11.3976 5.67003 10.3922 5.67003 9.25466C5.67003 8.6453 5.83244 8.08671 6.11672 7.5992C7.73095 9.58983 10.1574 10.8898 12.8782 11.032C12.8274 10.7883 12.7969 10.5344 12.7969 10.2805C12.7969 8.47264 14.2589 7 16.0761 7C17.0203 7 17.8731 7.39609 18.4721 8.03594C19.2132 7.89376 19.9238 7.61952 20.5533 7.24375C20.3096 8.00549 19.7919 8.64533 19.1117 9.05155C19.7716 8.98049 20.4112 8.79764 21 8.54376C20.5534 9.19373 19.995 9.77262 19.3554 10.2398Z"
									fill="#1C1C29"
								/>
							</svg>
						</a>
						<a href="https://instagram.com/1exboard">
							<svg
								width="26"
								height="26"
								viewBox="0 0 26 26"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									className="fill"
									d="M13.0018 8.8978C10.7316 8.8978 8.9005 10.7293 8.9005 13C8.9005 15.2707 10.7316 17.1022 13.0018 17.1022C15.2719 17.1022 17.1031 15.2707 17.1031 13C17.1031 10.7293 15.2719 8.8978 13.0018 8.8978ZM13.0018 15.667C11.5347 15.667 10.3354 14.4709 10.3354 13C10.3354 11.5291 11.5312 10.333 13.0018 10.333C14.4724 10.333 15.6682 11.5291 15.6682 13C15.6682 14.4709 14.4688 15.667 13.0018 15.667ZM18.2274 8.73C18.2274 9.26197 17.7991 9.68682 17.2708 9.68682C16.739 9.68682 16.3142 9.2584 16.3142 8.73C16.3142 8.20161 16.7426 7.77318 17.2708 7.77318C17.7991 7.77318 18.2274 8.20161 18.2274 8.73ZM20.9438 9.7011C20.8831 8.41939 20.5904 7.28406 19.6516 6.34866C18.7165 5.41325 17.5814 5.1205 16.2999 5.05623C14.9793 4.98126 11.0207 4.98126 9.70006 5.05623C8.4222 5.11693 7.28712 5.40968 6.34835 6.34509C5.40959 7.28049 5.12047 8.41582 5.05622 9.69753C4.98126 11.0185 4.98126 14.9779 5.05622 16.2989C5.1169 17.5806 5.40959 18.7159 6.34835 19.6513C7.28712 20.5867 8.41863 20.8795 9.70006 20.9438C11.0207 21.0187 14.9793 21.0187 16.2999 20.9438C17.5814 20.8831 18.7165 20.5903 19.6516 19.6513C20.5868 18.7159 20.8795 17.5806 20.9438 16.2989C21.0187 14.9779 21.0187 11.0221 20.9438 9.7011ZM19.2376 17.7163C18.9592 18.416 18.4202 18.9551 17.717 19.2372C16.664 19.6549 14.1654 19.5585 13.0018 19.5585C11.8381 19.5585 9.33597 19.6513 8.28656 19.2372C7.58695 18.9587 7.04796 18.4196 6.76598 17.7163C6.34835 16.6631 6.44473 14.1639 6.44473 13C6.44473 11.8361 6.35192 9.33337 6.76598 8.28372C7.04439 7.58396 7.58338 7.04485 8.28656 6.7628C9.33954 6.34509 11.8381 6.44148 13.0018 6.44148C14.1654 6.44148 16.6676 6.34866 17.717 6.7628C18.4166 7.04128 18.9556 7.58039 19.2376 8.28372C19.6552 9.33694 19.5588 11.8361 19.5588 13C19.5588 14.1639 19.6552 16.6666 19.2376 17.7163Z"
									fill="#1C1C29"
								/>
							</svg>
						</a>
						<a href="https://www.linkedin.com/company/1exboard/">
							<svg
								width="24"
								height="24"
								viewBox="0 0 30 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="13" cy="13" r="13" fill="white" />
								<path
									className="fill"
									d="M23.9938 24V23.999H24V15.4253C24 11.231 23.0438 8 17.8511 8C15.3548 8 13.6796 9.29356 12.9957 10.5199H12.9235V8.39157H8V23.999H13.1267V16.2708C13.1267 14.236 13.5352 12.2684 16.2037 12.2684C18.8331 12.2684 18.8723 14.5905 18.8723 16.4013V24H23.9938Z"
									fill="#222531"
								/>
								<path className="fill" d="M0 8H5V24H0V8Z" fill="#222531" />
								<path
									className="fill"
									d="M3 0C1.34386 0 0 1.33759 0 2.98601C0 4.63443 1.34386 6 3 6C4.65614 6 6 4.63443 6 2.98601C5.99896 1.33759 4.6551 0 3 0V0Z"
									fill="#222531"
								/>
							</svg>
						</a>
						<a href="https://t.me/oneexboard">
							<svg
								width="26"
								height="26"
								viewBox="0 0 26 26"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<circle cx="13" cy="13" r="13" fill="white" />
								<path
									className="fill"
									d="M21.9459 8.18348L19.0786 19.2153C18.8623 19.9939 18.2982 20.1876 17.4966 19.8208L13.1279 17.1944L11.0199 18.8485C10.7866 19.0388 10.5915 19.198 10.1419 19.198L10.4558 15.568L18.5527 9.59879C18.9047 9.34272 18.4764 9.20084 18.0056 9.45691L7.99574 14.5991L3.68642 13.4987C2.74906 13.2599 2.7321 12.7339 3.88153 12.3671L20.737 7.06923C21.5175 6.83046 22.2003 7.21111 21.9459 8.18348Z"
									fill="#1C1C29"
								/>
							</svg>
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
