import React, { FC, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import emailRegex from 'services/utils/emailRegex';
import Input from 'ui/Formik/Input';
import L from 'i18n-react';
import { IForgotPassSubmitValue, IForgotPasswordForm } from './types';
import TwoFaFormPopup from './TwoFaFormPopup';

// ==========================================:
const ForgotPassForm: FC<IForgotPasswordForm> = ({ emailSubmit }) => {
	const initialValues = {
		email: '',
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required(`${String(L.translate('ForgotPassword.form_1'))}`)
			.email(`${String(L.translate('ForgotPassword.form_2'))}`)
			.matches(emailRegex, `${String(L.translate('ForgotPassword.form_2'))}`)
			.max(60, `${String(L.translate('ForgotPassword.form_3'))}`),
	});

	const [email, setEmail] = useState('');
	const [popupOpen, setPopupOpen] = useState(false);
	const openPopup = () => {
		setPopupOpen(true);
	};
	const closePopup = () => {
		setPopupOpen(false);
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values: IForgotPassSubmitValue, { resetForm, setSubmitting }) => {
					setEmail(values.email);
					setSubmitting(false);
					resetForm();
					openPopup();
				}}
				validateOnBlur
				enableReinitialize
			>
				{({ isSubmitting, isValid, dirty }) => {
					return (
						<Form>
							<div className="back-page">
								<Link to="/login" className="flex flex-m">
									<div className="back-page__icon">
										<svg
											width="32"
											height="32"
											viewBox="0 0 32 32"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M2.86621 16C2.86621 15.4477 3.31393 15 3.86621 15H27.8662C28.4185 15 28.8662 15.4477 28.8662 16C28.8662 16.5523 28.4185 17 27.8662 17H3.86621C3.31393 17 2.86621 16.5523 2.86621 16Z"
												fill="#7D7D4D"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M12.5733 7.29289C12.9638 7.68342 12.9638 8.31658 12.5733 8.70711L5.28042 16L12.5733 23.2929C12.9638 23.6834 12.9638 24.3166 12.5733 24.7071C12.1828 25.0976 11.5496 25.0976 11.1591 24.7071L3.1591 16.7071C2.76858 16.3166 2.76858 15.6834 3.1591 15.2929L11.1591 7.29289C11.5496 6.90237 12.1828 6.90237 12.5733 7.29289Z"
												fill="#7D7D4D"
											/>
										</svg>
									</div>
									<p>{String(L.translate('ForgotPassword.title_0'))}</p>
								</Link>
							</div>
							<p className="authorization__title">
								{String(L.translate('ForgotPassword.title_1'))}
							</p>
							<div className="authorization__details">
								<p>{String(L.translate('ForgotPassword.title_2'))}</p>
							</div>
							<div className="authorization__form">
								<div className="authorization__field">
									<Field
										type="email"
										placeholder={`${String(L.translate('Forms.Login.email_placeholder'))}`}
										name="email"
										required
										component={Input}
									/>
								</div>
							</div>
							<div className="form-submit">
								<button
									type="submit"
									disabled={!(isValid && dirty) || isSubmitting}
									className="button button--wide button--size2"
									aria-label="form-submit"
								>
									{String(L.translate('ForgotPassword.title_3'))}
								</button>
							</div>
						</Form>
					);
				}}
			</Formik>
			{popupOpen && (
				<TwoFaFormPopup
					email={email}
					emailSubmit={emailSubmit}
					twoFaFormPopup={popupOpen}
					closeTwoFaFormPopup={closePopup}
				/>
			)}
		</>
	);
};

export default ForgotPassForm;
