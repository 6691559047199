import { useState, useEffect, useCallback, useRef } from 'react';

const useSelect = () => {
	const [open, setOpen] = useState<boolean>(false);
	const currentRef = useRef(null);
	const dropRef = useRef(null);
	const toggleOpen = () => {
		setOpen((prev) => !prev);
	};

	const onWindowClick = useCallback(
		(e: Event) => {
			const path = e.composedPath();
			if (dropRef?.current && currentRef?.current) {
				if (!path.includes(dropRef?.current) && !path.includes(currentRef?.current) && open) {
					setOpen(false);
				}
			}
		},
		[open],
	);

	useEffect(() => {
		window.addEventListener('click', onWindowClick, true);
		return () => window.removeEventListener('click', onWindowClick);
	}, [onWindowClick]);

	return {
		open,
		setOpen,
		toggleOpen,
		currentRef,
		dropRef,
	};
};

export default useSelect;
