import { useSelector, useDispatch } from 'react-redux';
import { selectOrdersList } from 'redux/reducers/options/selectors';

export const useGetOptionsMap = () => {
	const ordersList = useSelector(selectOrdersList);
	const optionsMap = ordersList?.reduce((acc: any, curr) => {
		// eslint-disable-next-line no-unsafe-optional-chaining
		const optionPositionsMap = curr?.options_by_date?.options_positions.reduce(
			(_acc: any, _curr) => {
				return { ..._acc, [_curr.id]: _curr };
			},
			{},
		);
		return { ...acc, ...optionPositionsMap };
	}, {});

	return optionsMap;
};
