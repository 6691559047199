import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import L from 'i18n-react';
import { Formik, Form } from 'formik';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import { api } from 'services';
import yup from 'services/utils/capsLockValidate';
import { notificationContainer } from 'services/utils/notificationContainer';
import TransferContentCross from './TransferContent/TransferContentCross';
import TransferContentIsolated from './TransferContent/TransferContentIsolated';
import { ITransferModal } from './types';

const TransferModal: FC<ITransferModal> = ({ onClose, assetId, assetPairId, mode, refetch }) => {
	const dispatch = useDispatch();
	const isAuth = useSelector(getAuthIsAuthenticated);
	const history = useHistory();

	const handleClose = () => {
		onClose && onClose();
	};

	useEffect(() => {
		dispatch(getAssetsRequest());
	}, [dispatch]);

	const initialValues = {
		asset_pair_id: assetPairId,
		asset_id: assetId,
		amount: null,
		reverse: false,
		percent: 0,
	};

	const validationSchema = yup.object().shape({
		amount: yup
			.number()
			.typeError(String(L.translate('Wallets.MarginAccount.messages.amount_must_be_a_number')))
			.required(String(L.translate('Wallets.MarginAccount.messages.amount_is_required'))),
	});

	return (
		<div className="popup-window margin-wallets-transfer">
			<div className="popup-window__inside">
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					validateOnBlur
					onSubmit={async (values, { resetForm }) => {
						if (!isAuth) {
							history.push('/login');
							return;
						}
						// eslint-disable-next-line @typescript-eslint/naming-convention
						const { asset_pair_id, asset_id, reverse } = values;
						let endpoint;
						try {
							if (mode === 'isolated') {
								if (!asset_pair_id) {
									throw new Error(
										String(L.translate('Wallets.MarginAccount.messages.asset_pair_id_is_required')),
									);
								}
								endpoint = api.marginWallets.transferFromSpotToIsolated;
								if (reverse) {
									endpoint = api.marginWallets.transferFromIsolatedToSpot;
								}
								await endpoint({
									asset_pair_id,
									asset_id,
									amount: Number(values.amount),
								});
							} else {
								endpoint = api.marginWallets.transferFromSpotToCross;
								if (reverse) {
									endpoint = api.marginWallets.transferFromCrossToSpot;
								}
								await endpoint({
									asset_id,
									amount: Number(values.amount),
								});
							}
							resetForm();
							notificationContainer(
								String(L.translate('Wallets.MarginAccount.messages.transfer_was_completed')),
								'success',
							);
							resetForm();
							onClose && onClose();
							refetch && refetch();
						} catch (error) {
							notificationContainer(
								String(L.translate('Wallets.MarginAccount.messages.failed_to_transfer')),
								'error',
							);
						}
					}}
				>
					<Form>
						{mode === 'isolated' ? (
							assetPairId && (
								<TransferContentIsolated
									onClose={handleClose}
									assetId={assetId}
									assetPairId={assetPairId}
								/>
							)
						) : (
							<TransferContentCross onClose={handleClose} assetId={assetId} />
						)}
					</Form>
				</Formik>
			</div>
		</div>
	);
};

export default TransferModal;
