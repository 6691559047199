import { FC, useEffect, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getPerpetualMyOrders,
	getPerpetualMyOrdersLoader,
	getBaseAsset,
} from 'redux/reducers/perpetual/selectors';
import { IPerpetualMyOrdersItem } from 'redux/reducers/perpetual/types';
import { getMyOrdersRequest } from 'redux/reducers/perpetual/reducer';
import Loader from 'ui/Loader';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';
import LoginTradeTablePlug from '../TradeTablePlug';
import MyOrdersTableItem from './TableItem';
import { IMyOrdersTab } from './types';

const MyOrdersTab: FC<IMyOrdersTab> = ({ showAll }) => {
	const dispatch = useDispatch();
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const baseAsset = useSelector(getBaseAsset);
	const loader = useSelector(getPerpetualMyOrdersLoader);

	const myOrders = useSelector(getPerpetualMyOrders);

	const { perPage, currentPage } = usePagination();

	// const myOrders = MY_ORDERS;

	useEffect(() => {
		if (baseAsset?.base_asset) {
			dispatch(
				getMyOrdersRequest({
					is_show_all_asset: showAll ? 1 : 0,
					base_asset: baseAsset.base_asset,
					per_page: perPage,
					current_page: currentPage,
				}),
			);
		}
	}, [baseAsset?.base_asset, currentPage, dispatch, perPage, showAll]);

	const renderContent: () => ReactNode = () => {
		if (!authIsAuthenticated) {
			return <LoginTradeTablePlug />;
		}
		if (loader) {
			return (
				<div className="popup-loader">
					<Loader />
				</div>
			);
		}
		if (myOrders && myOrders?.data.length > 0) {
			return myOrders?.data?.map((item) => <MyOrdersTableItem key={item.id} item={item} />);
		}
		return <TableEmptyPlug />;
	};

	return (
		<div className="table table--trade table--trade-perpetual-my-orders">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.contract'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.id'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.created_at'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.side'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.price'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.size'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.unfilled'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.fill_price'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.columns.stop_order'))}</p>
					</div>
					<div className="td td--right">
						<p>{String(L.translate('Trade.Perpetual.columns.cancel_all'))}</p>
					</div>
				</div>
			</div>
			<div className="table-body">{renderContent()}</div>
			{myOrders?.last_page && Number(myOrders?.total) > Number(myOrders?.per_page) && (
				<Pagination lastPage={myOrders.last_page} getCurrentHref={(s) => String(s)} />
			)}
		</div>
	);
};
export default MyOrdersTab;
