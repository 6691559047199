/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
export const transformMarginsIsolatedData = (data: any) => {
	if (data?.find((el: any) => el?.base || el?.quote)) {
		return data;
	}
	const dataPairs = data
		?.filter(
			(el: any, idx: number) =>
				Number(idx) % 2 === 0 && Number(el?.pair?.id !== idx % 2) !== 0 && el?.pair?.id,
		)
		.map((el: any) => el?.pair?.code);

	const dataNew = dataPairs?.map((el: string | any) => ({
		base: data?.find(
			(item: any) => el === item?.pair?.code && el.split('_')[0] === item?.asset?.code,
		),
		quote: data?.find(
			(item: any) => el === item?.pair?.code && el.split('_')[1] === item?.asset?.code,
		),
	}));

	return dataNew;
};
