import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
/* eslint-disable */

import {
	emailResetConfirmTokenRequest,
	emailConfirmStatusCallback,
} from 'redux/reducers/auth/reducer';
import { getEmailConfirm, getEmailResetConfirmTokenStatus } from 'redux/reducers/auth/selectors';
import L from "i18n-react";

// ==========================================:
const EmailConfirmError: FC = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const emailConfirm = useSelector(getEmailConfirm);
	const emailConfirmStatus = useSelector(getEmailResetConfirmTokenStatus);

	const queryPar = queryString.parse(location.search);

	const handleResetEmailConfirm = () => {
		dispatch(emailResetConfirmTokenRequest({ token: queryPar.token, history }));
	};

	useEffect(() => {
		dispatch(emailConfirmStatusCallback());
	}, [dispatch]);

	return (
		<div className="authorization-section">
			{emailConfirm ? (
				<div className="authorization-section__container">
					<div className="authorization">
						<div className="authorization__icon">
							<svg
								width="56"
								height="56"
								viewBox="0 0 56 56"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M10.2632 10.2634C14.8006 5.72614 21.0738 2.91667 27.9998 2.91667C34.9259 2.91667 41.1991 5.72614 45.7364 10.2634L44.499 11.5008L45.7364 10.2634C50.2737 14.8007 53.0832 21.074 53.0832 28C53.0832 34.926 50.2737 41.1993 45.7364 45.7366C41.1991 50.2738 34.9259 53.0833 27.9998 53.0833C21.0738 53.0833 14.8006 50.2738 10.2632 45.7366L11.5007 44.4991L10.2632 45.7366C5.72597 41.1993 2.9165 34.926 2.9165 28C2.9165 21.074 5.72597 14.8007 10.2632 10.2634L10.2632 10.2634ZM27.9998 6.41667C22.0393 6.41667 16.6459 8.83055 12.7381 12.7383C8.83036 16.6461 6.4165 22.0395 6.4165 28C6.4165 33.9605 8.83039 39.3539 12.7381 43.2617C16.6459 47.1694 22.0393 49.5833 27.9998 49.5833C33.9603 49.5833 39.3538 47.1694 43.2615 43.2617C47.1693 39.3539 49.5832 33.9605 49.5832 28C49.5832 22.0395 47.1693 16.6461 43.2615 12.7383C39.3538 8.83055 33.9603 6.41667 27.9998 6.41667Z"
									fill="#219653"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M40.9039 19.7626C41.5874 20.446 41.5874 21.554 40.9039 22.2374L26.9039 36.2374C26.2205 36.9209 25.1125 36.9209 24.4291 36.2374L17.4291 29.2374C16.7456 28.554 16.7456 27.446 17.4291 26.7626C18.1125 26.0791 19.2205 26.0791 19.9039 26.7626L25.6665 32.5251L38.4291 19.7626C39.1125 19.0791 40.2205 19.0791 40.9039 19.7626Z"
									fill="#219653"
								/>
							</svg>
						</div>
						<p className="authorization__title authorization__title--center">
							{String(L.translate('EmailVerified.title_0'))}
						</p>
						<p className="authorization__details authorization__details--center">
							{String(L.translate('EmailVerified.title_1'))}
						</p>
						<div className="form-submit">
							<Link to="/login" className="button button--full-width button--size2">
								{String(L.translate('EmailVerified.button'))}
							</Link>
						</div>
					</div>
				</div>
			) : (
				<>
					{emailConfirmStatus ? (
						<div className="authorization-section__container">
							<div className="authorization">
								<div className="authorization__icon">
									<svg
										width="56"
										height="56"
										viewBox="0 0 56 56"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M10.2632 10.2634C14.8006 5.72614 21.0738 2.91667 27.9998 2.91667C34.9259 2.91667 41.1991 5.72614 45.7364 10.2634L44.499 11.5008L45.7364 10.2634C50.2737 14.8007 53.0832 21.074 53.0832 28C53.0832 34.926 50.2737 41.1993 45.7364 45.7366C41.1991 50.2738 34.9259 53.0833 27.9998 53.0833C21.0738 53.0833 14.8006 50.2738 10.2632 45.7366L11.5007 44.4991L10.2632 45.7366C5.72597 41.1993 2.9165 34.926 2.9165 28C2.9165 21.074 5.72597 14.8007 10.2632 10.2634L10.2632 10.2634ZM27.9998 6.41667C22.0393 6.41667 16.6459 8.83055 12.7381 12.7383C8.83036 16.6461 6.4165 22.0395 6.4165 28C6.4165 33.9605 8.83039 39.3539 12.7381 43.2617C16.6459 47.1694 22.0393 49.5833 27.9998 49.5833C33.9603 49.5833 39.3538 47.1694 43.2615 43.2617C47.1693 39.3539 49.5832 33.9605 49.5832 28C49.5832 22.0395 47.1693 16.6461 43.2615 12.7383C39.3538 8.83055 33.9603 6.41667 27.9998 6.41667Z"
											fill="#219653"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M40.9039 19.7626C41.5874 20.446 41.5874 21.554 40.9039 22.2374L26.9039 36.2374C26.2205 36.9209 25.1125 36.9209 24.4291 36.2374L17.4291 29.2374C16.7456 28.554 16.7456 27.446 17.4291 26.7626C18.1125 26.0791 19.2205 26.0791 19.9039 26.7626L25.6665 32.5251L38.4291 19.7626C39.1125 19.0791 40.2205 19.0791 40.9039 19.7626Z"
											fill="#219653"
										/>
									</svg>
								</div>
								<p className="additional__desc">
									{String(L.translate('EmailVerified.title_2'))}
								</p>
							</div>
						</div>
					) : (
						<div className="authorization-section__container">
							<div className="authorization">
								<h3 className="authorization__title authorization__title--center">
									{String(L.translate('EmailVerified.title_3'))}
								</h3>
								<div className="authorization__details authorization__details--center">
									<p>
										{String(L.translate('EmailVerified.title_4'))}
									</p>
								</div>
								<div className="form-submit">
									<button
										type="button"
										onClick={handleResetEmailConfirm}
										className="button button--full-width button--size2"
									>
										{String(L.translate('EmailVerified.button_2'))}
									</button>
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default EmailConfirmError;
