import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import { IPerpetualTradeStore, IPerpetualMyOrdersResponse } from './types';

const getPerpetualTradeState = (state: TStoreState): IPerpetualTradeStore => state.perpetualTrade;
export const getPerpetualTrade = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade,
);
export const getPerpetualOrderBook = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.orderBook,
);
export const getOrderHistoryLoader = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.orderHistoryLoader,
);
export const getOrderHistory = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.orderHistory,
);
export const getPerpetualRecentTrades = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.recentTrades,
);
export const getPerpetualMarketType = createSelector(
	[getPerpetualTrade],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.marketType,
);
export const getPerpetualIsolatedLeverage = createSelector(
	[getPerpetualTrade],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.isolatedLeverage,
);
export const getPerpetualBaseAssetsLoader = createSelector(
	[getPerpetualTrade],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.baseAssetStatisticsLoader,
);
export const getPerpetualBaseAssets = createSelector(
	[getPerpetualTrade],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.baseAssetStatistics,
);
export const getBaseAsset = createSelector(
	[getPerpetualTrade],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.baseAsset,
);
export const getPerpetualBaseAsset = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.baseAsset,
);
export const getPerpetualBalance = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.balance,
);
export const getTransactionHistoryLoader = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.transactionHistoryLoader,
);
export const getPerpetualOpenPositions = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.openPositions,
);

export const getPerpetualMyOrdersLoader = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.myOrdersLoader,
);

export const getPerpetualMyOrders = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.myOrders,
);

export const getTradeHistoryLoader = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.tradeHistoryLoader,
);

export const getTradeHistory = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.tradeHistory,
);

export const getTransactionsLoader = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.transactionsLoader,
);

export const getTransactions = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.transactions,
);

export const getUnrealizedPnl = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.unrealisedPnl,
);

export const getMyStopOrdersLoader = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.stopOrdersLoader,
);

export const getMyStopOrders = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.stopOrders,
);

export const getStopOrderHistoryLoader = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.stopOrderHistoryLoader,
);

export const getStopOrderHistory = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.stopOrderHistory,
);

export const getClosedOrderHistoryLoader = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.closedOrderHistoryLoader,
);

export const getClosedOrderHistory = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.closedOrderHistory,
);
export const getPerpOrderBookLoader = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.orderBookLoader,
);
export const getPerpRecentTradesLoader = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.recentTradesLoader,
);
export const getUnrealisedPnlLoader = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.unrealisedPnlLoader,
);
export const getUserStat = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.userStat,
);

// ------------------------------------------------------
export const getActiveBaseAssetLoader = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.activeBaseAssetLoader,
);

export const getActiveBaseAsset = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.activeBaseAsset,
);

// ---------------------------------------------------------
export const getDerivativeAccountLoader = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.derivativeAccountLoader,
);

export const getDerivativeAccount = createSelector(
	[getPerpetualTradeState],
	(perpetualTrade: IPerpetualTradeStore) => perpetualTrade.derivativeAccount,
);
