import React from 'react';
import Trading from 'layouts/Trading';
import Terms from 'components/StaticPages/terms';

const TermsPage = () => {
	return (
		<Trading title="Privacy">
			<Terms />
		</Trading>
	);
};

export default TermsPage;
