import { FC } from 'react';
import L from 'i18n-react';
import { IStatusChip } from './types';

const StatusChip: FC<IStatusChip> = ({ status }) => {
	let color;
	switch (status) {
		case 'pending':
			color = 'yellow';
			break;
		case 'processing':
			color = 'grey';
			break;
		case 'completed':
			color = 'green';
			break;
		case 'canceled':
			color = 'red';
			break;
		default:
			color = '';
			break;
	}

	return (
		<span className={`${color} ${color}-bg fw-700 fz12 capitalize`}>
			{/* @typescript-eslint/restrict-template-expressions */}
			{String(L.translate(`Status.${status}`))}
		</span>
	);
};

export default StatusChip;
