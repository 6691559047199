import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	getAssetPairsIsLoad,
	getAssetPairsList,
	getAssetPairsListFavorite,
} from 'redux/reducers/assetPairs/selectors';
import L from 'i18n-react';
import Loader from 'ui/Loader';
import { IAssetPairsItem } from 'redux/reducers/assetPairs/types';

import PairsTableItem from '../PairsTableItem';
import { IPairsTableProps } from './types';

const PairsTable: FC<IPairsTableProps> = ({
	star,
	searchData,
	currentAssetData,
	marginPairsTab,
}) => {
	const assetPairsList = useSelector(getAssetPairsList);
	const assetPairsIsLoad = useSelector(getAssetPairsIsLoad);
	const favoriteCodes = useSelector(getAssetPairsListFavorite);
	const filteredAssetPairsBySearchData = useMemo(() => {
		const pairSearch = (pair: IAssetPairsItem) => {
			return pair.code
				.toLowerCase()
				.replace('_', '/')
				.includes(searchData.toLowerCase().split(' ').join('/').trim());
		};
		if (assetPairsList?.length) {
			if (currentAssetData === 'margin') {
				if (marginPairsTab === 'cross') {
					return searchData
						? assetPairsList?.filter((pair) => pair.active_cross && pairSearch(pair))
						: assetPairsList?.filter((pair) => pair.active_cross);
				}

				if (marginPairsTab === 'isolated') {
					return searchData
						? assetPairsList?.filter((pair) => {
								return pair.active_isolate && pairSearch(pair);
						  })
						: assetPairsList?.filter((pair) => pair.active_isolate);
				}
				return searchData
					? assetPairsList?.filter((pair) => {
							return (
								(pair.active_cross && pairSearch(pair)) || (pair.active_isolate && pairSearch(pair))
							);
					  })
					: assetPairsList?.filter((pair) => pair.active_cross || pair.active_isolate);
			}
			if (currentAssetData !== 'all' && searchData) {
				return assetPairsList?.filter(
					(pair) => pair.code.toLowerCase().includes(currentAssetData) && pairSearch(pair),
				);
			}

			if (currentAssetData !== 'all') {
				return assetPairsList?.filter((pair) => pair.code.toLowerCase().includes(currentAssetData));
			}

			return assetPairsList?.filter((pair) => {
				return pairSearch(pair);
			});
		}
		return [];
	}, [assetPairsList, searchData, currentAssetData, marginPairsTab]);

	const starArrayFunc = () => {
		const favoriteCodesArr = favoriteCodes ? Object.values(favoriteCodes) : [];

		return filteredAssetPairsBySearchData?.filter(({ code }) => {
			return !star || favoriteCodesArr.includes(code);
		});
	};
	const newArray = starArrayFunc();
	const [filterBlock, setFilterBlock] = useState('pair');
	const [filterBlockArrows, setFilterBlockArrows] = useState(0);
	const filterArrayBlock = (text: string, arrows: number) => {
		if (text === 'pair' && arrows === 1) {
			return newArray.sort((a, b) => (a.code > b.code ? 1 : -1));
		}
		if (text === 'pair' && arrows === 2) {
			return newArray.sort((a, b) => (b.code > a.code ? 1 : -1));
		}
		if (text === 'price' && arrows === 1) {
			return newArray.sort((a, b) => (a.last_price > b.last_price ? 1 : -1));
		}
		if (text === 'price' && arrows === 2) {
			return newArray.sort((a, b) => (b.last_price > a.last_price ? 1 : -1));
		}
		if (text === 'volume' && arrows === 1) {
			return newArray.sort((a, b) => (a.change24 > b.change24 ? 1 : -1));
		}
		if (text === 'volume' && arrows === 2) {
			return newArray.sort((a, b) => (b.change24 > a.change24 ? 1 : -1));
		}
		return newArray;
	};
	useMemo(() => {
		filterArrayBlock(filterBlock, filterBlockArrows);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterBlock, filterBlockArrows, newArray]);
	const filterPairPriceVolume = (text: string) => {
		if (filterBlockArrows + 1 === 3) {
			setFilterBlockArrows(0);
			if (text !== filterBlock) {
				setFilterBlockArrows(1);
			}
		} else {
			setFilterBlockArrows(filterBlockArrows + 1);
			if (text !== filterBlock) {
				setFilterBlockArrows(1);
			}
		}
		setFilterBlock(text);
	};

	return (
		<>
			<div className="market-list__line market-list__line--header line-market-flex">
				<button
					onClick={() => {
						filterPairPriceVolume('pair');
					}}
					type="button"
					className="td-sorting-arrow-button grey"
				>
					{String(L.translate('Trade.Spot.SpotPairs.PairsTableColumnTitles.pair_name'))}
					<span className="td-sorting-arrow">
						<span
							className={`td-sorting-arrow__item ${
								filterBlock === 'pair' && filterBlockArrows === 1 ? 'active' : ''
							}`}
						>
							<svg
								width="6"
								height="3"
								viewBox="0 0 6 3"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M0 3L5.4 3L6 3L3 -2.62268e-07L0 3Z" fill="#9D9DBC" />
							</svg>
						</span>
						<span
							className={`td-sorting-arrow__item ${
								filterBlock === 'pair' && filterBlockArrows === 2 ? 'active' : ''
							}`}
						>
							<svg
								width="6"
								height="3"
								viewBox="0 0 6 3"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M6 0H0.6H0L3 3L6 0Z" fill="#9D9DBC" />
							</svg>
						</span>
					</span>
				</button>
				<button
					onClick={() => {
						filterPairPriceVolume('price');
					}}
					type="button"
					className="td-sorting-arrow-button grey"
				>
					{String(L.translate('Trade.Spot.SpotPairs.PairsTableColumnTitles.price_name'))}
					<span className="td-sorting-arrow">
						<span
							className={`td-sorting-arrow__item ${
								filterBlock === 'price' && filterBlockArrows === 1 ? 'active' : ''
							}`}
						>
							<svg
								width="6"
								height="3"
								viewBox="0 0 6 3"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M0 3L5.4 3L6 3L3 -2.62268e-07L0 3Z" fill="#9D9DBC" />
							</svg>
						</span>
						<span
							className={`td-sorting-arrow__item ${
								filterBlock === 'price' && filterBlockArrows === 2 ? 'active' : ''
							}`}
						>
							<svg
								width="6"
								height="3"
								viewBox="0 0 6 3"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M6 0H0.6H0L3 3L6 0Z" fill="#9D9DBC" />
							</svg>
						</span>
					</span>
				</button>
				<button
					onClick={() => {
						filterPairPriceVolume('volume');
					}}
					type="button"
					className="td-sorting-arrow-button grey"
				>
					{String(L.translate('Trade.Spot.SpotPairs.PairsTableColumnTitles.change_name'))}
					<span className="td-sorting-arrow">
						<span
							className={`td-sorting-arrow__item ${
								filterBlock === 'volume' && filterBlockArrows === 1 ? 'active' : ''
							}`}
						>
							<svg
								width="6"
								height="3"
								viewBox="0 0 6 3"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M0 3L5.4 3L6 3L3 -2.62268e-07L0 3Z" fill="#9D9DBC" />
							</svg>
						</span>
						<span
							className={`td-sorting-arrow__item ${
								filterBlock === 'volume' && filterBlockArrows === 2 ? 'active' : ''
							}`}
						>
							<svg
								width="6"
								height="3"
								viewBox="0 0 6 3"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M6 0H0.6H0L3 3L6 0Z" fill="#9D9DBC" />
							</svg>
						</span>
					</span>
				</button>
			</div>
			<div className="market-list__scroll">
				{newArray?.length &&
				!assetPairsIsLoad /* eslint-disable  @typescript-eslint/no-explicit-any */
					? newArray.map((pair: any) => <PairsTableItem key={pair.id} pair={pair} />)
					: null}

				{!filteredAssetPairsBySearchData?.length && !assetPairsIsLoad && (
					<div className="asset-pairs-loader-wrapper">
						{String(L.translate('Trade.Spot.SpotPairs.no_pairs_data'))}
					</div>
				)}

				{assetPairsIsLoad && !filteredAssetPairsBySearchData?.length && (
					<div className="asset-pairs-loader-wrapper">
						<Loader />
					</div>
				)}
			</div>
		</>
	);
};

export default PairsTable;
