import { ArrowLeftIcon } from 'assets/inline-svg';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import L from 'i18n-react';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import { twoFaCheckEnabledRequest } from 'redux/reducers/twoFa/reducer';
import { getTwoFaEnableStatus } from 'redux/reducers/twoFa/selectors';
import {
	checkCryptoAddressDebounce,
	getWalletNetworksRequest,
	getWalletsRequest,
	withdrawCryptoRequest,
} from 'redux/reducers/wallets/reducer';
import { getCheckCryptoAddress, getWalletsList } from 'redux/reducers/wallets/selectors';
import {
	ICheckCryptoAddressPayload,
	IWalletItem,
	IWalletsNetwork,
	IWithdrawCryptoPayload,
} from 'redux/reducers/wallets/types';
import { navList } from 'routes/routesList';
import { notificationContainer } from 'services/utils/notificationContainer';
import { toMaxDecimals } from 'services/utils/toMaxDecimals';
import TextError from 'ui/Formik/TextError';
import WalletSelect from '../WalletSelect/WalletSelect';
import ConfirmWithdrawModal from './ConfirmWithdrawModal/ConfirmWithdrawModal';
import { TConfirmActionType } from './ConfirmWithdrawModal/types';
import NetworkSelect from './NetworkSelect/NetworkSelect';
import {
	getNetworkFeeRate,
	getNetworkFeeValue,
	getReceiveAmount,
	validateAmount,
	validateNumberInput,
} from './utils';

const WithdrawCrypto = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation<{ initWallet?: IWalletItem }>();

	const wallets = useSelector(getWalletsList);
	const twoFaStatus = useSelector(getTwoFaEnableStatus);
	const checkAddress = useSelector(getCheckCryptoAddress);

	const initWallet = location.state?.initWallet || null;

	const [wallet, setWallet] = useState<IWalletItem | null>(initWallet);
	const [network, setNetwork] = useState<IWalletsNetwork | null>(null);
	const [address, setAddress] = useState('');
	const [amount, setAmount] = useState('');
	const [confirmAction, setConfirmAction] = useState<TConfirmActionType | null>(null);

	const assetCode = wallet?.asset.code.toUpperCase();
	const amountNum = amount === '.' ? 0 : Number(amount);
	const balance = toMaxDecimals(wallet?.balance || 0, 10);
	const networkFeeRate = getNetworkFeeRate(network, assetCode);
	const isAddressNotValidChecked = checkAddress?.is_valid === false;
	const isShowAmount = !!address && !!checkAddress?.is_valid;
	const receiveAmount = getReceiveAmount(network, amountNum);
	const networkFeeValue = getNetworkFeeValue(network, amountNum);
	const isTwoFaEnabled = twoFaStatus === 'sms2fa_enabled' || twoFaStatus === 'google2fa_enabled';

	const walletsToShow = useMemo(() => {
		return wallets?.filter(({ asset }) => asset.is_crypto && asset.withdrawable);
	}, [wallets]);

	const amountError = useMemo(() => {
		return validateAmount(amount, wallet?.balance, network?.withdraw_min);
	}, [amount, wallet, network]);

	const goBack = () => {
		history.goBack();
	};

	const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (validateNumberInput(e.target.value)) {
			setAmount(e.target.value);
		}
	};

	const handleConfirmWithdraw = (code: string) => {
		if (confirmAction === 'confirm' && twoFaStatus === 'sms2fa_enabled') {
			setConfirmAction('2fa-sms');
			return;
		}
		if (confirmAction === 'confirm' && twoFaStatus === 'google2fa_enabled') {
			setConfirmAction('2fa-google');
			return;
		}
		if (!wallet || !network || !address || !amount) {
			return;
		}
		const payload: IWithdrawCryptoPayload = {
			apiParams: {
				asset_id: wallet.asset.id,
				wallet_id: wallet.id,
				amount: Number(amount),
				address,
				network: network.network,
				totp: code,
			},
			onSuccess: () => {
				notificationContainer('Withdrawal successfully', 'success');
				history.push(navList.walletSpot.path);
			},
		};
		dispatch(withdrawCryptoRequest(payload));
	};

	useEffect(() => {
		dispatch(getWalletsRequest());
		dispatch(userSettingsRequest());
		dispatch(twoFaCheckEnabledRequest());
	}, [dispatch]);

	useEffect(() => {
		if (wallet) {
			dispatch(getWalletNetworksRequest({ wallet_id: wallet.id, asset_id: wallet.asset.id }));
		}
		setNetwork(null);
	}, [wallet, dispatch]);

	useEffect(() => {
		setAddress('');
	}, [network]);

	useEffect(() => {
		const payload: ICheckCryptoAddressPayload = {
			apiParams: {
				address,
				asset_id: wallet?.asset.id || 0,
				network: network?.network || '',
			},
			isReset: !address || !wallet || !network,
		};
		dispatch(checkCryptoAddressDebounce(payload));
	}, [address, wallet, network, dispatch]);

	useEffect(() => {
		if (!isShowAmount) {
			setAmount('');
		}
	}, [isShowAmount]);

	return (
		<div className="deposit-content">
			<div className="deposit-content-inside">
				<div className="deposit-content-header">
					<button type="button" onClick={goBack} className="deposit-content-header__back">
						<ArrowLeftIcon />
					</button>
					<p>{String(L.translate('WithdrawCrypto.withdraw_crypto_page_title'))}</p>
				</div>

				<div className="deposit-content-item">
					<div className="deposit-content-item__title">
						<p>{String(L.translate('WithdrawCrypto.select_coin'))}</p>
					</div>
					<div className="select-block">
						<div className="select-block__name">
							<p>{String(L.translate('WithdrawCrypto.coin'))}</p>
						</div>
						<WalletSelect wallets={walletsToShow} current={wallet} onChange={setWallet} />
						{!isTwoFaEnabled && (
							<TextError>{String(L.translate('WithdrawCrypto.Messages.enable2fa'))}</TextError>
						)}
					</div>
				</div>

				{/* TODO: replace isTwoFaEnabled */}
				{wallet && isTwoFaEnabled && (
					<div className="deposit-content-item">
						<div className="deposit-content-item__title">
							<p>1. {String(L.translate('WithdrawCrypto.withdraw_to'))}</p>
						</div>
						<NetworkSelect current={network} onChange={setNetwork} />

						{network && (
							<>
								<div className="withdraw-to">
									<div className="withdraw-to__item">
										<p>
											{assetCode} {String(L.translate('WithdrawCrypto.spot_balance'))}
										</p>
										<span>
											{balance} {assetCode}
										</span>
									</div>
									<div className="withdraw-to__item">
										<p>{String(L.translate('WithdrawCrypto.minimum_withdrawal'))}</p>
										<span>
											{toMaxDecimals(network.withdraw_min, 10)} {assetCode}
										</span>
									</div>
									<div className="withdraw-to__item">
										<p>{String(L.translate('WithdrawCrypto.network_fee'))}</p>
										<span>{networkFeeRate}</span>
									</div>
								</div>
								<div className="input">
									<div className="input__name">
										<p>{String(L.translate('WithdrawCrypto.address'))}</p>
									</div>
									<div className="input-wrapper">
										<input
											className="input-item"
											type="text"
											placeholder="Enter address here"
											value={address}
											onChange={(e) => setAddress(e.target.value)}
										/>
									</div>
									{isAddressNotValidChecked && (
										<TextError>
											{String(L.translate('WithdrawCrypto.Messages.invalid_address_format'))}
										</TextError>
									)}
								</div>
							</>
						)}
					</div>
				)}

				{isShowAmount && (
					<div className="deposit-content-item">
						<div className="deposit-content-item__title">
							<p>2. {String(L.translate('WithdrawCrypto.withdrawal_info'))}</p>
						</div>
						<div className="input">
							<div className="input__name">
								<p>{String(L.translate('WithdrawCrypto.amount'))}</p>
							</div>
							<div className="input-wrapper">
								<input
									className="input-item"
									type="text"
									placeholder={String(L.translate('WithdrawCrypto.amount'))}
									value={amount}
									onChange={handleAmountChange}
								/>
								<div className="input-item-info flex flex--gap8 flex-m">
									<button
										type="button"
										className="button button--size2 button--type-text"
										onClick={() => setAmount(balance)}
									>
										{String(L.translate('Global.max_button'))}
									</button>
									<p>{assetCode}</p>
								</div>
							</div>
							{amountError && <TextError>{amountError}</TextError>}
						</div>
					</div>
				)}

				{amount && !amountError && (
					<div className="deposit-content-item">
						<div className="deposit-content-item__title">
							<p>3. {String(L.translate('WithdrawCrypto.receive_amount'))}</p>
						</div>
						<div className="receive-amount">
							<p>
								{receiveAmount} {assetCode}
							</p>
							<span>
								{networkFeeValue} {assetCode}{' '}
								{String(L.translate('WithdrawCrypto.Messages.network_fee_included'))}
							</span>
						</div>
						<button
							type="button"
							className="button button--size2 button--full-width"
							onClick={() => setConfirmAction('confirm')}
						>
							{String(L.translate('WithdrawCrypto.withdraw'))}
						</button>
					</div>
				)}
			</div>

			<ConfirmWithdrawModal
				actionType={confirmAction}
				onClose={() => setConfirmAction(null)}
				onConfirm={handleConfirmWithdraw}
				receiveAmount={receiveAmount}
				assetCode={assetCode || ''}
				networkFee={networkFeeValue}
				address={address}
				network={network?.network_full || ''}
				networkName={network?.network_name.toUpperCase() || ''}
			/>
		</div>
	);
};

export default WithdrawCrypto;
