import SocketWithTopicConnectWrapper from 'components/SocketWithTopicConnectWrapper';
/* eslint-disable no-debugger */
import { FC, MouseEvent, useMemo, useState } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getSpotOrderBook } from 'redux/reducers/spotTrade/selectors';
import { getCurrentPair, getWorkspaceSettings } from 'redux/reducers/tradingSettings/selectors';
import { filteredOrderbookDataByTab } from 'services/utils/orderBookTradingDataHelepers';
import { getFirstPartPairCode, getLastPartPairCode } from 'services/utils/tradingPairHelpers';
import LastTradeBlock from './LastTradeBlock';
import LayoutBar from './LayoutBar';
import OrderBookTable from './OrderBookTable';

const SpotOrderBook: FC = () => {
	const workspaceSettings = useSelector(getWorkspaceSettings);
	const orderBookList = useSelector(getSpotOrderBook);
	const currentPair = useSelector(getCurrentPair);
	const [currentLayoutTab, setCurrentLayoutTab] = useState('general');

	const handleCurrentLayoutTab = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;

		setCurrentLayoutTab(name);
	};

	const filteredOrderbookAskDataByLayoutTab = useMemo(
		() => filteredOrderbookDataByTab(orderBookList?.ask, currentLayoutTab, 'ask'),
		[orderBookList?.ask, currentLayoutTab],
	);

	const filteredOrderbookBidDataByLayoutTab = useMemo(
		() => filteredOrderbookDataByTab(orderBookList?.bid, currentLayoutTab, 'bid'),
		[orderBookList?.bid, currentLayoutTab],
	);

	const getTableClasses = (
		orderBookSection: boolean,
		tradingPairsSection: boolean,
		layout: string,
	): string => {
		switch (true) {
			case orderBookSection && tradingPairsSection:
				return 'table table--stats orderbook__table orderbook__table--small';
			case layout === 'ask' || layout === 'bid':
				return 'orderbook-body-item orderbook-body-item--type2';

			default:
				return 'orderbook-body-item';
		}
	};

	return (
		<SocketWithTopicConnectWrapper topicName={`order_book:spot:${String(currentPair?.pair)}`}>
			<div className="orderbook">
				<div className="orderbook-header">
					<LayoutBar
						currentLayoutTab={currentLayoutTab}
						handleChangeLayoutBar={handleCurrentLayoutTab}
					/>
				</div>
				<div className="orderbook-body">
					<div
						className={getTableClasses(
							workspaceSettings?.orderBookSection,
							!workspaceSettings?.tradingPairsSection,
							currentLayoutTab,
						)}
					>
						<div className="orderbook-body-item__line orderbook-body-item__line--header">
							<span className="grey">
								{String(L.translate('Trade.Spot.SpotOrderBook.OrderBookColumnTitles.price_name'))}(
								{getLastPartPairCode(currentPair.pair || '')})
							</span>
							<span className="grey">
								{String(L.translate('Trade.Spot.SpotOrderBook.OrderBookColumnTitles.amount_name'))}(
								{getFirstPartPairCode(currentPair.pair || '')})
							</span>
							<span className="grey text-align-right">
								{String(L.translate('Trade.Spot.SpotOrderBook.OrderBookColumnTitles.total_name'))}
							</span>
						</div>
						{(currentLayoutTab === 'general' || currentLayoutTab === 'ask') && (
							<OrderBookTable data={filteredOrderbookAskDataByLayoutTab} type="ask" />
						)}
					</div>
					<LastTradeBlock />
					{(currentLayoutTab === 'general' || currentLayoutTab === 'bid') && (
						<div
							className={getTableClasses(
								workspaceSettings?.orderBookSection,
								!workspaceSettings?.tradingPairsSection,
								currentLayoutTab,
							)}
						>
							<OrderBookTable data={filteredOrderbookBidDataByLayoutTab} type="bid" />
						</div>
					)}
				</div>
			</div>
		</SocketWithTopicConnectWrapper>
	);
};

export default SpotOrderBook;
