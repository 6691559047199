/* eslint-disable @typescript-eslint/no-misused-promises */
import { FC, SyntheticEvent, useState } from 'react';
import { api } from 'services';
import L from 'i18n-react';
import PositionPopup from 'components/Trade/PerpetualTrades/Popups/PositionPTSL';
import AdjustMarginPopup from 'components/Trade/PerpetualTrades/Popups/AdjustMargin';
import { notificationContainer } from 'services/utils/notificationContainer';
import { roundingNumber } from 'services/utils/roundingNumber';
import { IPositionsTabItem } from './types';

const PositionsTabItem: FC<IPositionsTabItem> = ({ item, baseAssets, refetch }) => {
	const [values, setValues] = useState<{ price: string; amount: string }>({
		price: '',
		amount: '',
	});
	const [errors, setErrors] = useState<{ price: string; amount: string }>({
		price: '',
		amount: '',
	});
	const [posPopupOpen, setPosPopupOpen] = useState(false);
	const [adjustMarginOpened, setAdjustMarginOpened] = useState(false);
	const openAdjustMargin = () => {
		setAdjustMarginOpened(true);
	};
	const closeAdjustMargin = () => {
		setAdjustMarginOpened(false);
	};
	const closeByMarket = async () => {
		try {
			await api.perpetualTrade.closePositionByMarket({
				future_position_id: item.id,
			});
			notificationContainer(
				String(L.translate('Trade.Perpetual.messages.successfully_closed')),
				'success',
			);
		} catch (error) {
			notificationContainer(
				String(L.translate('Trade.Perpetual.messages.failed_to_close_position')),
				'error',
			);
			console.error(error);
		}
	};

	const closeByLimit = async () => {
		let emptyFields = false;
		let newErrors = { ...errors };
		if (values.price === '') {
			newErrors = {
				...newErrors,
				price: String(L.translate('Trade.Perpetual.messages.price_is_required')),
			};
			emptyFields = true;
		}
		if (values.amount === '') {
			newErrors = {
				...newErrors,
				amount: String(L.translate('Trade.Perpetual.messages.amount_is_required')),
			};
			emptyFields = true;
		}
		setErrors(newErrors);
		if (errors.price || errors.amount || emptyFields) {
			return;
		}
		try {
			await api.perpetualTrade.closePositionByLimit({
				future_position_id: item.id,
				price_entry_for_order: Number(values.price),
				amount_asset_request: Number(values.amount),
			});
			notificationContainer(
				String(L.translate('Trade.Perpetual.messages.successfully_closed')),
				'success',
			);
		} catch (error) {
			notificationContainer(
				String(L.translate('Trade.Perpetual.messages.failed_to_close_position')),
				'error',
			);
			console.error(error);
		}
	};

	const closeByTpSl = () => {
		setPosPopupOpen(true);
	};

	const handlePriceChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: targetValue } = target;
		setValues({
			...values,
			price: targetValue,
		});
		if (targetValue && !Number(targetValue)) {
			setErrors({
				...errors,
				price: String(L.translate('Trade.Perpetual.messages.price_must_be_a_number')),
			});
		}
		if (!targetValue || Number(targetValue)) {
			setErrors({
				...errors,
				price: '',
			});
		}
	};

	const handleAmountChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: targetValue } = target;
		setValues({
			...values,
			amount: targetValue,
		});
		if (targetValue && !Number(targetValue)) {
			setErrors({
				...errors,
				amount: String(L.translate('Trade.Perpetual.messages.amount_must_be_a_number')),
			});
		}
		if (!targetValue || Number(targetValue)) {
			setErrors({
				...errors,
				amount: '',
			});
		}
	};

	const handlePopupClose = () => {
		setPosPopupOpen(false);
	};

	const markPrice = baseAssets?.find((ba) => ba.base_asset === item.base_asset)?.mark_price;
	const lastPrice = baseAssets?.find((ba) => ba.base_asset === item.base_asset)?.last_price;
	const transactionType = item.transactions_type;
	let unreliazedPnl: number | null = null;

	if (transactionType === 'buy') {
		unreliazedPnl = Number(item.amount_base_asset) * (Number(lastPrice) - Number(item.entry_price));
	} else {
		unreliazedPnl = Number(item.amount_base_asset) * (Number(item.entry_price) - Number(lastPrice));
	}

	const unreliazedPnlPercent =
		item.amount_leverage_deposit_in_usdt &&
		(unreliazedPnl / item.amount_leverage_deposit_in_usdt) * 100;

	return (
		<>
			<div className="tr">
				<div className="td">
					{/* Contract */}
					<p>{item.base_asset}/USDT</p>
					{/* TODO: Плече */}
					<p className="table-details">[Isolated {item.middle_leverage_size?.toFixed(1)}x]</p>
				</div>
				<div className="td">
					<p>
						{/* Size */}
						{item.amount_base_asset} {item.base_asset}
					</p>
				</div>
				<div className="td">
					{/* Value */}
					<p>{item.amount_exequted_in_usdt?.toFixed(2)} USDT</p>
				</div>
				<div className="td">
					{/* Entry Price */}
					<p>{item.entry_price?.toFixed(2)}</p>
				</div>
				<div className="td">
					{/* Mark Price */}
					<p>{markPrice}</p>
				</div>
				<div className="td">
					{/* Liquidation Price */}
					<p>{item.liquidation_price?.toFixed(2)}</p>
				</div>
				{/* Margin */}
				<div className="td cursorPointer" onClick={openAdjustMargin}>
					<p>{roundingNumber(item.amount_leverage_deposit_in_usdt)}</p>
				</div>
				<div className="td">
					{/* Unrealised PNL (ROE) */}
					<p>{unreliazedPnl?.toFixed(2)}</p>
					<p className="table-details">[{unreliazedPnlPercent?.toFixed(2)}%]</p>
				</div>
				<div className="td">
					{/* Realised PNL */}
					<p>{item.pnl_realized} USDT</p>
				</div>
				<div className="td td-input">
					<input value={values.price} onChange={handlePriceChange} type="text" />
					<span className="input-notify">{errors.price}</span>
				</div>
				<div className="td td-input">
					<input value={values.amount} onChange={handleAmountChange} type="text" />
					<span className="input-notify">{errors.amount}</span>
				</div>
				<div className="td td-button">
					<button type="button" className="td-main-button" onClick={closeByLimit}>
						{String(L.translate('Trade.Perpetual.Positions.limit'))}
					</button>
					<button type="button" className="td-main-button" onClick={closeByMarket}>
						{String(L.translate('Trade.Perpetual.Positions.market'))}
					</button>
				</div>
				<div className="td td--right td-button td-button--right">
					<button type="button" onClick={closeByTpSl}>
						{String(L.translate('Trade.Perpetual.Positions.sl_tp'))}
					</button>
				</div>
			</div>
			{posPopupOpen && (
				<PositionPopup item={item} markPrice={markPrice} onClose={handlePopupClose} />
			)}
			{adjustMarginOpened && (
				<AdjustMarginPopup item={item} onClose={closeAdjustMargin} refetch={refetch} />
			)}
		</>
	);
};

export default PositionsTabItem;
