import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersSettings } from 'redux/reducers/settings/selectors';
import { twoFaSmsEnableRequest } from 'redux/reducers/twoFa/reducer';
import { generateEmailTotpRequest, generateSmsRequest } from 'redux/reducers/auth/reducer';
import { TFormTwoFa } from 'components/AccountSettings/AccountSettingsTwoFa/type';
import GetCodeButton from 'ui/GetCodeButton/GetCodeButton';
import L from 'i18n-react';

const TwoFaPhoneForm: FC<TFormTwoFa> = ({ onClose }) => {
	const dispatch = useDispatch();
	const userData = useSelector(getUsersSettings);

	const phoneToView = useMemo(() => {
		const phoneStartNumbers: string = userData?.data?.phone?.slice(0, 4);
		const phoneEndNumbers: string = userData?.data?.phone?.slice(-4);
		return `${phoneStartNumbers}**${phoneEndNumbers}`;
	}, [userData?.data?.phone]);

	const emailToView = useMemo(() => {
		const emailStartNumbers: string = userData?.email.slice(0, 4) || '';
		const emailEndNumbers: string = userData?.email.split('@')[1] || '';
		return `${emailStartNumbers}**@${emailEndNumbers}`;
	}, [userData?.email]);

	const handleGetPhoneCode = () => {
		dispatch(generateSmsRequest({ email: userData?.email, phone: userData?.data.phone }));
	};
	const handleGetEmailCode = () => {
		dispatch(generateEmailTotpRequest());
	};

	const [phoneCodeInputValue, setPhoneCodeInputValue] = useState('');
	const [emailCodeInputValue, setEmailCodeInputValue] = useState('');
	const [phoneCodeInputValueValidate, setPhoneCodeInputValueValidate] = useState(false);
	const [emailCodeInputValueValidate, setEmailCodeInputValueValidate] = useState(false);

	const validateFormHandler = (value: string) => {
		const regex = /^\d{6}$/;
		return !!regex?.exec(value);
	};

	const changePhoneCodeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
		setPhoneCodeInputValue(event.target.value);
		setPhoneCodeInputValueValidate(validateFormHandler(event.target.value));
	};

	const changeEmailCodeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
		setEmailCodeInputValue(event.target.value);
		setEmailCodeInputValueValidate(validateFormHandler(event.target.value));
	};

	const onEnableClick = () => {
		dispatch(
			twoFaSmsEnableRequest({ sms_totp: phoneCodeInputValue, email_totp: emailCodeInputValue }),
		);
		onClose();
	};

	return (
		<>
			<div className="setting-content-body-item">
				<div className="setting-content-body-item-header setting-content-body-item-header--type2">
					<p>{String(L.translate('Forms.Sms2fa.sms_2fa_form_title'))}</p>
				</div>
				<div className="settings-2fa">
					<div className="settings-2fa__text">
						{String(L.translate('Forms.Sms2fa.sms_2fa_form_subtitle'))}
					</div>
					<div className="settings-2fa-item">
						<div className="input">
							<div className="input-wrapper">
								<input
									type="text"
									className="input-item input-item--pr125"
									placeholder={String(L.translate('Forms.Sms2fa.sms_2fa_form_field_placeholder'))}
									onChange={changePhoneCodeInputValue}
									value={phoneCodeInputValue}
								/>
								<GetCodeButton onClick={handleGetPhoneCode} />
							</div>
							<div className="input__notification input__notification--type2">
								<p>
									{String(L.translate('Forms.Sms2fa.sms_2fa_form_field_prompt'))} {phoneToView}.
								</p>
							</div>
						</div>
					</div>
					<div className="settings-2fa-item">
						<div className="input">
							<div className="input-wrapper">
								<input
									type="text"
									className="input-item input-item--pr125"
									placeholder={String(L.translate('Forms.Sms2fa.sms_2fa_form_field_placeholder'))}
									onChange={changeEmailCodeInputValue}
									value={emailCodeInputValue}
								/>
								<GetCodeButton onClick={handleGetEmailCode} />
							</div>
							<div className="input__notification input__notification--type2">
								<p>
									{String(L.translate('Forms.Sms2fa.sms_2fa_form_field_prompt'))} {emailToView}.
								</p>
							</div>
						</div>
					</div>
					<div className="settings-2fa-item settings-2fa-item--type2">
						<button
							type="button"
							onClick={onClose}
							className="button button--size2 button--full-width button--type3"
						>
							{String(L.translate('Forms.Sms2fa.sms_2fa_form_btn_cancel'))}
						</button>
						<button
							type="button"
							onClick={onEnableClick}
							disabled={!(phoneCodeInputValueValidate && emailCodeInputValueValidate)}
							className="button button--size2 button--full-width"
						>
							{String(L.translate('Forms.Sms2fa.sms_2fa_form_btn_enable'))}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default TwoFaPhoneForm;
