import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';

const getExchangeState = (state: TStoreState) => state.exchange;

export const getState = createSelector([getExchangeState], (exchange) => exchange);

export const getLimitsState = createSelector([getExchangeState], (exchange) => exchange.limits);

export const getRateState = createSelector([getExchangeState], (exchange) => exchange.rate);
