import { FC } from 'react';
import L from 'i18n-react';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { IClosedHistoryTabItem } from './types';

const ClosedHistoryTabItem: FC<IClosedHistoryTabItem> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td">
				{/* Time */}
				<p>{item.created_at && customDateFormat(item.created_at)}</p>
			</div>
			<div className="td">
				{/* Contract */}
				<p>{item.base_asset?.toUpperCase()}/USDT</p>
			</div>
			<div className="td">
				{/* Side */}
				<p>
					{item.transactions_type === 'buy'
						? String(L.translate('Trade.Perpetual.long'))
						: String(L.translate('Trade.Perpetual.short'))}
				</p>
			</div>
			<div className="td">
				{/* Profit/Loss */}
				<p>{item.stop_order}</p>
			</div>
			<div className="td td--right">
				{/* Source */}
				<p>SL/TP</p>
			</div>
		</div>
	);
};

export default ClosedHistoryTabItem;
