import { FC, useMemo } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import useDecimals from 'hooks/useDecimals';
import { getAssetPairsList } from 'redux/reducers/assetPairs/selectors';
import { getSpotRecentTrades } from 'redux/reducers/spotTrade/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';

const LastTradeBlock: FC = () => {
	const currentPair = useSelector(getCurrentPair);
	const assetPairsList = useSelector(getAssetPairsList);
	const recentTrades = useSelector(getSpotRecentTrades);

	const [priceDecimal] = useDecimals();

	const currentAssetPairData = useMemo(() => {
		return assetPairsList?.length
			? assetPairsList.find((el) => el.code === currentPair.pair)
			: null;
	}, [assetPairsList, currentPair]);

	const firstRecentTradeItem = recentTrades?.length && recentTrades[0]?.price;
	const secondRecentTradesItem = recentTrades?.length && recentTrades[1]?.price;

	const lastRecentTradeIsLow = Number(firstRecentTradeItem) < Number(secondRecentTradesItem);

	const getMarketValueColorClass = (value: boolean): string => {
		switch (value) {
			case true:
				return 'red fw-700';

			case false:
				return 'green fw-700';

			default:
				return 'fw-700';
		}
	};

	return (
		<div className="orderbook-body-info">
			<div className="orderbook-body-info__line orderbook-body-info__line--header">
				<span className="grey">{String(L.translate('Trade.Spot.last_price'))}</span>
				<span className="grey">USDT</span>
				<span className="grey">{String(L.translate('Trade.Spot.change'))}</span>
			</div>
			<div className="orderbook-body-info__line">
				<span className={getMarketValueColorClass(lastRecentTradeIsLow)}>
					{fixedCropNumber(Number(firstRecentTradeItem), priceDecimal) || firstRecentTradeItem}
				</span>
				<span>
					{(currentAssetPairData &&
						fixedCropNumber(Number(currentAssetPairData?.last_price), priceDecimal)) ||
						currentAssetPairData?.last_price}
				</span>
				<span
					className={`${
						currentAssetPairData?.change24 && currentAssetPairData?.change24 > 0 ? 'green' : 'red'
					}`}
				>
					{currentAssetPairData?.change24} %
				</span>
			</div>
		</div>
	);
};

export default LastTradeBlock;
