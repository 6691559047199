/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import {
	getAssetPairsFeesAndLimitsList,
	getAssetPairsList,
} from 'redux/reducers/assetPairs/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { createOrderRequest } from 'redux/reducers/orders/reducer';
import { getMarginIndexPrices } from 'redux/reducers/marginWallets/selectors';
import { getCalculateMarketLoader, getCalculateMarketOrder } from 'redux/reducers/orders/selectors';
import { DebounceInput } from 'react-debounce-input';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { numberValidation } from 'services/utils/numberValidation';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { getSpotOrderBook } from 'redux/reducers/spotTrade/selectors';
import TextError from 'ui/Formik/TextError';
import LoginOrRegister from 'ui/LoginOrRegister';
import PersentSlider from 'components/Trade/TradesBox/PersentSlider';
import reduceToValidNumber from 'services/utils/reduceToValidNumber';
import { IOrderBookBidData } from 'redux/reducers/spotTrade/types';
import { convertToFixed } from 'services/utils/convertToFixed';
import { IMarketOrderProps } from './types';

/* eslint-disable no-debugger */
/* eslint-disable */
const MarketOrder: FC<IMarketOrderProps> = ({
	mode,
	assetToTradeCode,
	assetBalanceCode,
	balance,
	marketType,
}) => {
	const orderBookList = useSelector(getSpotOrderBook);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const currentPair = useSelector(getCurrentPair);
	const calculateMarketOrderData = useSelector(getCalculateMarketOrder);
	const assetPairsFeesAndLimit = useSelector(getAssetPairsFeesAndLimitsList);
	const marginIndexPrice = useSelector(getMarginIndexPrices);
	const calculateLoader = useSelector(getCalculateMarketLoader);
	const assetPairsList = useSelector(getAssetPairsList);
	// debugger;
	// const assetPairsList = useSelector(getAssetPairsList);

	const dispatch = useDispatch();
	const initState = {
		order_type_id: 1,
		assets_pairs_id: Number(currentPair.id),
		quantity: '',
		direction: mode.toLowerCase(),
		total: '',
	};

	const [marketOrderState, setMarketOrderState] = useState({ ...initState });
	const [pairPrice, setPairPrice] = useState<number>(
		marginIndexPrice
			? Number(
					Object.entries(marginIndexPrice)?.filter(
						(pair) => pair[0] === currentPair?.pair?.toLocaleUpperCase(),
					)[0][1],
			  )
			: 43186.931928,
	);
	const [percent, setPercent] = useState(0);
	const [showError, setShowError] = useState(false);

	const { quantity_decimals } = assetPairsList?.find((item) => item.id === currentPair.id)
		?.setting || { quantity_decimals: 10 };

	const currentAssetPairLimits = useMemo(() => {
		return assetPairsFeesAndLimit?.length
			? assetPairsFeesAndLimit.find((pair) => pair.code === currentPair?.pair)
			: null;
	}, [assetPairsFeesAndLimit, currentPair]);

	// const currentAssetPairLimitsAmountMin = currentAssetPairLimits?.amount_min || 0;
	// const currentAssetPairLimitsAmountMax = currentAssetPairLimits?.amount_max || 0;

	// useEffect(() => {
	// 	if (numberValidationNext(marketOrderState.quantity)) {
	// 		setMarketOrderState({ ...marketOrderState, total: '', loader: calculateLoader });
	// 		return;
	// 	}
	// 	if (marketOrderState.type === calculateMarketOrderData?.type) {
	// 		setMarketOrderState({
	// 			...marketOrderState,
	// 			total: calculateMarketOrderData?.total,
	// 			loader: calculateLoader,
	// 		});
	// 	}
	// }, [calculateMarketOrderData, marketOrderState.quantity]);

	// const isMaxAmountBuyError =
	// 	authIsAuthenticated &&
	// 	Number(marketOrderState.quantity) &&
	// 	calculateMarketOrderData?.total &&
	// 	Number(calculateMarketOrderData.type === 'market_buy' ? calculateMarketOrderData?.total : 0) >
	// 		currentAssetPairLimitsAmountMax;

	// const isMaxAmountBuyError =
	// 	authIsAuthenticated &&
	// 	Number(marketOrderState.quantity) &&
	// 	marketOrderState.direction === 'buy' &&
	// 	currentAssetPairLimitsAmountMax * pairPrice < Number(marketOrderState.quantity);

	const isMinAmountBuyError =
		authIsAuthenticated &&
		marketOrderState.quantity &&
		Number(reduceToValidNumber(marketOrderState.quantity)) &&
		Number(reduceToValidNumber(marketOrderState.quantity)) >= 0.0001;

	// const isMaxAmountSellError =
	// 	authIsAuthenticated &&
	// 	Number(marketOrderState.quantity) &&
	// 	Number(marketOrderState.quantity) > currentAssetPairLimitsAmountMax;

	const isMinAmountSellError =
		authIsAuthenticated &&
		Number(reduceToValidNumber(marketOrderState.total)) &&
		Number(reduceToValidNumber(marketOrderState.total)) >= 0.0001;

	const isMaxAmountBuyBalanceError =
		authIsAuthenticated &&
		Number(reduceToValidNumber(marketOrderState.quantity)) &&
		Number(balance) &&
		Number(reduceToValidNumber(marketOrderState.quantity)) <= balance;

	const isMaxAmountSellBalanceError =
		authIsAuthenticated &&
		Number(reduceToValidNumber(marketOrderState.quantity)) &&
		Number(balance) &&
		Number(reduceToValidNumber(marketOrderState.quantity)) <= balance;

	const isEnabled =
		!!marketOrderState.quantity &&
		(mode === 'Buy'
			? isMaxAmountBuyBalanceError && isMinAmountBuyError && !!orderBookList?.ask.length
			: isMaxAmountSellBalanceError && isMinAmountSellError && !!orderBookList?.bid.length);

	useEffect(() => {
		setMarketOrderState({
			...initState,
			assets_pairs_id: Number(currentPair?.id),
		});
	}, [currentPair]);

	useEffect(() => {
		if (marginIndexPrice) {
			const currentPairPrice = Object.entries(marginIndexPrice)?.filter(
				(pair) => pair[0] === currentPair?.pair?.toLocaleUpperCase(),
			);
			setPairPrice(() => Number(currentPairPrice[0][1]));
		}
	}, [marginIndexPrice]);

	const calculate = (value: number, type: string) => {
		if (type.toLocaleLowerCase() === 'buy') {
			let total = 0;
			let quantityLeft = value;
			if (orderBookList?.ask) {
				const newArray: IOrderBookBidData[] = [...orderBookList?.ask];
				const askArray: IOrderBookBidData[] = newArray?.reverse();
				for (let x = 0; x < askArray.length; x++) {
					if (quantityLeft > Number(askArray[x].quantity_left) * Number(askArray[x].price)) {
						quantityLeft -= Number(askArray[x].quantity_left) * Number(askArray[x].price);
						total += Number(askArray[x].quantity_left);
					} else {
						total += quantityLeft / Number(askArray[x].price);
						break;
					}
				}
			}

			setMarketOrderState({
				...marketOrderState,
				// quantity: String(value),
				total: String(fixedCropNumber(Number(total), 6)),
			});
			return;
		}

		if (type.toLocaleLowerCase() === 'sell') {
			let total = 0;
			let quantityLeft = value;
			if (orderBookList?.bid) {
				const newArray: IOrderBookBidData[] = [...orderBookList?.bid];
				for (let x = 0; x < newArray?.length; x++) {
					if (quantityLeft > Number(newArray[x].quantity_left)) {
						quantityLeft -= Number(newArray[x].quantity_left);
						total += Number(newArray[x].price) * Number(newArray[x].quantity_left);
					} else {
						total += Number(newArray[x].price) * quantityLeft;
						break;
					}
				}
			}

			setMarketOrderState({
				...marketOrderState,
				// quantity: String(value),
				total: String(fixedCropNumber(Number(total), 6)),
			});
			return;
		}
	};

	useEffect(() => {
		calculate(Number(reduceToValidNumber(marketOrderState.quantity)), mode);
	}, [marketOrderState.quantity]);

	// useEffect(() => {
	// 	const { quantity } = marketOrderState;

	// 	if (
	// 		authIsAuthenticated &&
	// 		Number(quantity) &&
	// 		mode &&
	// 		!isMaxAmountBuyBalanceError &&
	// 		!isMaxAmountSellBalanceError
	// 	) {
	// 		const caclulateRequestData = {
	// 			assets_pairs_id: initState.assets_pairs_id,
	// 			quantity,
	// 			type: `market_${mode.toLowerCase()}`,
	// 			market_type: marketType,
	// 		};

	// 		// calculateMarketOrder(caclulateRequestData);
	// 		//dispatch(calculateMarketOrderRequest(caclulateRequestData));
	// 		setMarketOrderState({
	// 			...marketOrderState,
	// 			// loader: true,
	// 		});
	// 	}
	// }, [currentPair, marketOrderState.quantity, mode]);

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		if (numberValidation(value) || value === '') {
			setMarketOrderState({
				...marketOrderState,
				[name]: value,
			});
		}
	};

	const handleTradeClick = () => {
		// if (marketType === 'cross' || marketType === 'isolated') {
		// 	if (mode === 'Buy') {
		// 		if (calculateMarketOrderData?.total) {
		// 			const createOrderData = {
		// 				...marketOrderState,
		// 				quantity: calculateMarketOrderData?.total,
		// 				[`${marketType === 'cross' ? 'is_margin' : 'is_isolated'}`]: true,
		// 			};
		// 			// dispatch(createOrderRequest(createOrderData));
		// 		}
		// 	} else {
		// 		const { quantity } = marketOrderState;

		// 		if (quantity) {
		// 			const createOrderData = {
		// 				...marketOrderState,
		// 				quantity,
		// 				[`${marketType === 'cross' ? 'is_margin' : 'is_isolated'}`]: true,
		// 			};

		// 			// dispatch(createOrderRequest(createOrderData));
		// 		}
		// 	}
		// 	setMarketOrderState({
		// 		...initState,
		// 	});
		// 	return;
		// }
		let marketOrderType = 1;
		if (marketType === 'isolated') {
			marketOrderType = 3;
		}

		if (mode === 'Buy') {
			// if (calculateMarketOrderData?.total) {
			// 	const createOrderData = {
			// 		...marketOrderState,
			// 		quantity: String(marketOrderState?.total),
			// 	};

			// 	dispatch(createOrderRequest(createOrderData));
			// }
			const orderData = {
				order_type_id: marketOrderType,
				direction: marketOrderState.direction,
				assets_pairs_id: marketOrderState.assets_pairs_id,
				quantity: Number(reduceToValidNumber(marketOrderState.total)),
			};

			dispatch(createOrderRequest(orderData));
		} else {
			const { quantity } = marketOrderState;

			if (quantity) {
				const orderData = {
					order_type_id: marketOrderType,
					direction: marketOrderState.direction,
					assets_pairs_id: marketOrderState.assets_pairs_id,
					quantity: Number(
						convertToFixed(
							Number(reduceToValidNumber(marketOrderState.quantity)),
							quantity_decimals,
						),
					),
				};

				dispatch(createOrderRequest(orderData));
			}
		}
		setMarketOrderState({
			...initState,
		});
		setPercent(0);
	};

	const countOrder = (value: number) => {
		setMarketOrderState({
			...marketOrderState,
			quantity: String(Number(value / 100)),
		});
	};

	const percentButtonCountValue = (percentValue: number): number => {
		if (!balance || !Number(percentValue)) {
			return 0;
		}

		return balance * percentValue;
	};

	const handlePercentChange = (value: number | number[]) => {
		if (!Array.isArray(value)) {
			setPercent(value);
		}
	};

	const getFieldClass = (fieldName: string): string => {
		switch (fieldName) {
			case 'amount':
				if (mode === 'Buy') {
					return isMinAmountBuyError || isMaxAmountBuyBalanceError
						? 'trade-form__input input-form__item--error'
						: 'trade-form__input';
				}
				return isMinAmountSellError || isMaxAmountSellBalanceError
					? 'trade-form__input input-form__item--error'
					: 'trade-form__input';

			default:
				return 'trade-form__input';
		}
	};

	// const bitCoinPrice = 43186.931928;
	// const bitCoinPrice = assetPairsList?.find((item: any) => item.code === initState.pair_code)?.last_price || 0;

	// const errorLimitMax = (valueCoin: string, amountLimit: number) => {
	// 	if (
	// 		Number(marketOrderState.quantity) > Number(currentAssetPairLimitsAmountMax) * bitCoinPrice &&
	// 		mode === 'Buy'
	// 	) {
	// 		return (
	// 			<TextError>
	// 				{L.translate('Trade.Spot.SpotTradeBox.MarketOrder.max_amount_error_text')}{' '}
	// 				{amountLimit * bitCoinPrice} {valueCoin}
	// 			</TextError>
	// 		);
	// 	}
	// 	if (
	// 			currentAssetPairLimitsAmountMax * pairPrice < Number(marketOrderState.quantity) &&
	// 			marketOrderState.direction === 'buy'
	// 	) {
	// 		return (
	// 			<TextError>
	// 				{L.translate('Trade.Spot.SpotTradeBox.MarketOrder.max_amount_error_text')}{' '}
	// 				{convertExponentialToDecimal(amountLimit * pairPrice)} {valueCoin}
	// 			</TextError>
	// 		);
	// 	}
	// 	return null;
	// };

	const errorLimitMin = (valueCoin: string, amountLimit: number) => {
		if (!isMinAmountBuyError || !isMinAmountSellError) {
			return (
				<TextError>
					{L.translate('Trade.Spot.SpotTradeBox.MarketOrder.min_amount_error_text')}{' '}
					{convertExponentialToDecimal(amountLimit)} {valueCoin}
				</TextError>
			);
		}
		return null;
	};
	return (
		<>
			<label className="orderform-input">
				<span className="orderform-input__placeholder">
					{L.translate('Trade.Spot.SpotTradeBox.MarketOrder.amount_field_name')}
				</span>
				<DebounceInput
					debounceTimeout={200}
					type="text"
					placeholder="0.00"
					className="orderform-input__data"
					name="quantity"
					value={marketOrderState.quantity}
					onChange={handleChangeInput}
					autoComplete="off"
				/>
				<span className="orderform-input__placeholder fw-700">
					{mode === 'Buy' ? assetBalanceCode?.toUpperCase() : assetToTradeCode?.toUpperCase()}
				</span>
			</label>
			{showError && mode === 'Buy' && !isMinAmountBuyError
				? errorLimitMin(assetBalanceCode?.toUpperCase(), 0.0001)
				: null}
			{/*{mode === 'Buy' && isMaxAmountBuyError*/}
			{/*	? errorLimitMax(*/}
			{/*			mode === 'Buy' ? assetBalanceCode?.toUpperCase() : assetToTradeCode?.toUpperCase(),*/}
			{/*			currentAssetPairLimitsAmountMax,*/}
			{/*	  )*/}
			{/*	: null}*/}
			{showError && mode === 'Buy' && !isMaxAmountBuyBalanceError ? (
				<>
					<TextError>
						{String(L.translate('Trade.Spot.Errors.amount_must_be_less_than_your_balance'))}
					</TextError>
				</>
			) : null}
			{showError && mode === 'Buy' && !orderBookList?.ask.length ? (
				<>
					<TextError>{String(L.translate('Trade.Spot.Errors.empty_order_book'))}</TextError>
				</>
			) : null}
			{showError && mode === 'Sell' && !orderBookList?.bid.length ? (
				<>
					<TextError>{String(L.translate('Trade.Spot.Errors.empty_order_book'))}</TextError>
				</>
			) : null}
			{/*{mode === 'Sell' && isMaxAmountSellError ? (*/}
			{/*	<TextError>*/}
			{/*		{L.translate('Trade.Spot.SpotTradeBox.MarketOrder.max_amount_error_text')}{' '}*/}
			{/*		{currentAssetPairLimitsAmountMax} {assetToTradeCode?.toUpperCase()}*/}
			{/*	</TextError>*/}
			{/*) : null}*/}
			{showError && mode === 'Sell' && !isMinAmountSellError
				? errorLimitMin(assetBalanceCode?.toUpperCase(), 0.0001)
				: null}
			{showError && mode === 'Sell' && !isMaxAmountSellBalanceError ? (
				<TextError>
					{String(L.translate('Trade.Spot.Errors.amount_must_be_less_than_your_balance'))}
				</TextError>
			) : null}
			{/*
			<div className="trade-form__item">
				<div className="input">
					<div className="input-wrapper">
						<label className={getFieldClass('amount')}>
							<p className="input__name">
								{L.translate('Trade.Spot.SpotTradeBox.MarketOrder.amount_field_name')}
							</p>
							<DebounceInput
								debounceTimeout={200}
								type="text"
								placeholder="0.00"
								className="input-item input-item--transparent input-item--right"
								name="quantity"
								value={marketOrderState.quantity}
								onChange={handleChangeInput}
								autoComplete="off"
							/>
							<p className="input__name">
								{mode === 'Buy' ? assetBalanceCode?.toUpperCase() : assetToTradeCode?.toUpperCase()}
							</p>
						</label>
						{mode === 'Buy' && isMinAmountBuyError
							? errorLimitMin(
									mode === 'Buy'
										? assetBalanceCode?.toUpperCase()
										: assetToTradeCode?.toUpperCase(),
									currentAssetPairLimitsAmountMin,
							  )
							: null}
						{mode === 'Buy'
							? errorLimitMax(
									mode === 'Buy'
										? assetBalanceCode?.toUpperCase()
										: assetToTradeCode?.toUpperCase(),
									currentAssetPairLimitsAmountMax,
							  )
							: null}
						{mode === 'Buy' && isMaxAmountBuyBalanceError ? (
							<>
								<TextError>Amount must be less than your balance!</TextError>
							</>
						) : null}
						{mode === 'Buy' && !orderBookList?.ask.length ? (
							<>
								<TextError>The order book empty!</TextError>
							</>
						) : null}
						{mode === 'Sell' && !orderBookList?.bid.length ? (
							<>
								<TextError>The order book empty!</TextError>
							</>
						) : null}
						{mode === 'Sell' && isMaxAmountSellError ? (
							<TextError>
								{L.translate('Trade.Spot.SpotTradeBox.MarketOrder.max_amount_error_text')}{' '}
								{currentAssetPairLimitsAmountMax} {assetToTradeCode?.toUpperCase()}
							</TextError>
						) : null}
						{mode === 'Sell' && isMinAmountSellError ? (
							<TextError>
								{L.translate('Trade.Spot.SpotTradeBox.MarketOrder.min_amount_error_text')}{' '}
								{convertExponentialToDecimal(currentAssetPairLimitsAmountMin)}{' '}
								{assetToTradeCode?.toUpperCase()}
							</TextError>
						) : null}
						{mode === 'Sell' && isMaxAmountSellBalanceError ? (
							<TextError>Amount must be less than your balance!</TextError>
						) : null}
					</div>
				</div>
			</div> */}
			{/*<label className="orderform-input">*/}
			{/*	<span className="orderform-input__placeholder">*/}
			{/*		{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.total_field_name')}*/}
			{/*	</span>*/}
			{/*	<input*/}
			{/*		className="orderform-input__data"*/}
			{/*		type="text"*/}
			{/*		placeholder="0.00"*/}
			{/*		autoComplete="off"*/}
			{/*		name="total"*/}
			{/*		readOnly*/}
			{/*		value={marketOrderState?.total}*/}
			{/*	/>*/}
			{/*	<span className="orderform-input__placeholder fw-700">*/}
			{/*		{mode === 'Buy' ? assetToTradeCode?.toUpperCase() : assetBalanceCode?.toUpperCase()}*/}
			{/*	</span>*/}
			{/*</label>*/}
			{/* <div className="trade-form__item">
				<div className="input input-order-item">
					<div className="input-wrapper">
						<label className={getFieldClass('total')}>
							<p className="input__name">
								{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.total_field_name')}
							</p>
							<input
								className="input-item input-item--transparent input-item--right"
								type="text"
								placeholder="0.00"
								autoComplete="off"
								name="total"
								readOnly
								value={marketOrderState.loader ? 'calculating...' : marketOrderState?.total}
							/>
							<p className="input__name">
								{mode === 'Buy' ? assetToTradeCode?.toUpperCase() : assetBalanceCode?.toUpperCase()}
							</p>
						</label>
					</div>
				</div>
			</div> */}
			{/* <PercentRadioButtons
				countOrder={countOrder}
				percentButtonCountValue={percentButtonCountValue}
				mode={mode}
				amount={marketOrderState.quantity}
				precents={[0, 0.25, 0.5, 0.75, 1]}
			/> */}
			<div className="orderform-slider">
				<PersentSlider
					value={percent}
					onChange={handlePercentChange}
					countOrder={countOrder}
					percentButtonCountValue={percentButtonCountValue}
				/>
			</div>
			{authIsAuthenticated ? (
				<button
					className={`button button--full-width ${
						mode === 'Buy' ? 'button--type12' : 'button--type13'
					} trade-form__btn`}
					type="button"
					onClick={handleTradeClick}
					disabled={!isEnabled}
				>
					{mode === 'Buy'
						? L.translate('Trade.Spot.SpotTradeBox.OrderButtons.buy_button')
						: L.translate('Trade.Spot.SpotTradeBox.OrderButtons.sell_button')}{' '}
					{assetToTradeCode?.toUpperCase()}
				</button>
			) : (
				<div className="create-order-btn-login-register-wrapper">
					<LoginOrRegister />
				</div>
			)}
		</>
	);
};

export default memo(MarketOrder);
