import { FC, useEffect } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { WalletLayout } from 'layouts/Wallet';
import { getSpotUserTradesIsLoad, getSpotUserTrades } from 'redux/reducers/spotTrade/selectors';
import { getUserTradesRequest } from 'redux/reducers/spotTrade/reducer';
import MarketTypeToggle, {
	MarketTypes,
	MARKET_TYPE,
} from 'components/MarginWallets/MarketTypeToggle';
import TableRow from 'components/MarginWallets/tradeHistory/TableRow';
import Pagination from 'ui/Pagination';
import Loader from 'ui/Loader';
import TableEmptyPlug from 'ui/TableEmptyPlug';

const TradeHistory: FC = () => {
	const dispatch = useDispatch();
	const loading = useSelector(getSpotUserTradesIsLoad);
	const tradeHistory = useSelector(getSpotUserTrades);

	const location = useLocation();

	// const marketType = new URLSearchParams(location.search).get(MARKET_TYPE);

	const marketType = 'isolated';

	useEffect(() => {
		dispatch(
			getUserTradesRequest({
				params: {
					transaction_type: marketType === MarketTypes.Isolated ? 'isolated' : 'cross',
				},
			}),
		);
	}, [dispatch, marketType]);

	return (
		<WalletLayout title={String(L.translate('Wallets.MarginAccount.tradeHistory.html_title'))}>
			<div className="wallet-content">
				<div className="wallet-content-header flex flex-sb">
					<div className="wallet-content-header__title">
						<p>{String(L.translate('Wallets.MarginAccount.tradeHistory.title'))}</p>
					</div>
					{/* <MarketTypeToggle /> */}
				</div>
				<div className="wallet-content-tables">
					<div className="table table--wallet table--margin-trade-history-cross">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.date'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.pair'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.side'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.price'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.executed'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.fee'))}</p>
								</div>
								<div className="td td--right">
									<p>{String(L.translate('Wallets.MarginAccount.columns.total'))}</p>
								</div>
							</div>
						</div>
						<div className="table-body">
							{loading ? (
								<div className="popup-loader">
									<Loader />
								</div>
							) : (
								<>
									{tradeHistory?.data?.length === 0 ? (
										<TableEmptyPlug />
									) : (
										tradeHistory?.data.map((item: any) => <TableRow key={item.id} item={item} />)
									)}
								</>
							)}
						</div>
					</div>
				</div>
				{tradeHistory?.last_page &&
					Number(tradeHistory?.total) > Number(tradeHistory?.per_page) && (
						<Pagination lastPage={tradeHistory.last_page} getCurrentHref={(s) => String(s)} />
					)}
			</div>
		</WalletLayout>
	);
};

export default TradeHistory;
