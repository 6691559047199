import { FC } from 'react';
import L from 'i18n-react';
import SortingTh from './SortingTh';
import { ISortingTableHeaderProps } from './types';

const SortingTableHeader: FC<ISortingTableHeaderProps> = ({ sortParams, onChangeSort }) => {
	return (
		<div className="table-header">
			<div className="tr">
				<SortingTh
					title={String(L.translate('SpotWallet.balance_table.coin_cell'))}
					fieldName="asset_code"
					sortParams={sortParams}
					onChangeSort={onChangeSort}
				/>

				<SortingTh
					title={String(L.translate('SpotWallet.balance_table.total_cell'))}
					fieldName="total"
					sortParams={sortParams}
					onChangeSort={onChangeSort}
				/>

				<SortingTh
					title={String(L.translate('SpotWallet.balance_table.available_cell'))}
					fieldName="balance"
					sortParams={sortParams}
					onChangeSort={onChangeSort}
				/>

				<SortingTh
					title={String(L.translate('SpotWallet.balance_table.order_cell'))}
					fieldName="frozen_balance"
					sortParams={sortParams}
					onChangeSort={onChangeSort}
				/>

				<SortingTh
					title={String(L.translate('SpotWallet.balance_table.btc_value_cell'))}
					fieldName="btc_value"
					sortParams={sortParams}
					onChangeSort={onChangeSort}
				/>

				<div className="td td--right">
					<p>{L.translate('SpotWallet.balance_table.action_cell')}</p>
				</div>
			</div>
		</div>
	);
};

export default SortingTableHeader;
