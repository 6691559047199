import { FC, SyntheticEvent } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { useFormikContext, FormikContextType } from 'formik';
import Loader from 'ui/Loader';
import { getOptionStatistics } from 'redux/reducers/options/selectors';
import { daysToExpiration } from 'services/utils/daysToExpiration';
import { monthNames } from './constants';
import { IEditOrderModalContent, Values } from './types';

/* eslint-disable */
const impVol = require('implied-volatility');

const EditOrderModalContent: FC<IEditOrderModalContent> = ({ item, strikePrice, onClose }) => {
	const { values, setValues, isValid, errors, isSubmitting }: FormikContextType<Values> =
		useFormikContext();

	const statistic = useSelector(getOptionStatistics);

	const stockPrice = statistic?.mark_price || 0;

	const contractName = item?.contract_name;

	const dateSegment = contractName?.split('-')?.[1];

	const day = dateSegment?.slice(0, 2) && +dateSegment?.slice(0, 2);

	const monthShortName = dateSegment?.slice(2, dateSegment?.length - 2);
	const monthNum = monthShortName && monthNames[monthShortName];

	const year = +`20${dateSegment?.slice(dateSegment?.length - 2)}`;

	const iv =
		values?.price &&
		monthNum &&
		day &&
		year &&
		item.type_option &&
		impVol.getImpliedVolatility(
			+values?.price,
			stockPrice,
			strikePrice,
			daysToExpiration({ yyyy: year, mm: monthNum, dd: day }),
			0.0015,
			item.type_option,
		);

	const handleClose = () => {
		onClose && onClose();
	};

	const handlePriceChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: price } = target;
		if (Number(price) || Number(price) === 0 || price === '') {
			setValues({
				...values,
				price,
			});
		}
	};

	const handleIvChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: iv } = target;
		if (Number(iv) || Number(iv) === 0 || iv === '') {
			setValues({
				...values,
				iv,
			});
		}
	};

	const handleQuantityChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: quantity } = target;
		if (Number(quantity) || Number(quantity) === 0 || quantity === '') {
			setValues({
				...values,
				quantity,
			});
		}
	};

	const submitDisabled = !isValid || !values.price || !values.quantity || isSubmitting;

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup">
					<button type="button" className="popup__close" onClick={handleClose}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
								fill="#1C1C29"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
								fill="#1C1C29"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title">Edit Order</p>
					</div>
					{isSubmitting ? (
						<div className="popup-loader">
							<Loader />
						</div>
					) : (
						<div className="popup-body">
							<div className="flex-column">
								<div className="input">
									<div className="input-wrapper">
										<input
											className="input-item input-item--h40 input-item--size2"
											type="text"
											placeholder="Price"
											value={values.price}
											onChange={handlePriceChange}
										/>
										<div className="input-item-info flex flex--gap8 flex-m">
											<p>USD</p>
										</div>
									</div>
								</div>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="input-item input-item--h40 input-item--size2"
											type="text"
											placeholder="IV"
											value={iv}
											disabled
											onChange={handleIvChange}
										/>
										<div className="input-item-info  flex flex--gap8 flex-m">
											<span>{iv}</span>
											<p>%</p>
										</div>
									</div>
								</div>
								<div className="input">
									<div className="input-info-title">
										<p />
										<p>
											<span>Position</span> --
										</p>
									</div>
									<div className="input-wrapper">
										<input
											className="input-item input-item--h40 input-item--size2"
											type="text"
											placeholder="Quantity"
											value={values.quantity}
											onChange={handleQuantityChange}
										/>
										<div className="input-item-info  flex flex--gap8 flex-m">
											<p>BTC</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					<div className="popup-footer popup-footer--item2">
						<button
							type="button"
							className="button button--type3 button--size2 button--full-width"
							onClick={handleClose}
						>
							Cancel
						</button>
						<button
							type="submit"
							className="button button--size2 button--full-width"
							disabled={submitDisabled}
						>
							Confrim
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditOrderModalContent;
