import React, { useEffect } from 'react';
import L from 'i18n-react';

const Privacy = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="static-section section-gradient">
			<div className="inside">
				<p className="section-title">
					{String(L.translate('StaticPages.PrivacyPolicyPage.header_1'))}
				</p>
				<div className="static-content">
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_1'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_2'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_3'))}</p>
					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.header_2'))}</h3>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_4'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_5'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_6'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_7'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_8'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_9'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_10'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_11'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_12'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_13'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_14'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_15'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_16'))}</p>

					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.header_3'))}</h3>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_17'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_18'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_19'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_20'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_21'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_22'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_23'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_24'))}</p>
					<p>
						<strong>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_25'))}</strong>
					</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_26'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_27'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_28'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_29'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_30'))}</p>
					<p>
						{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_31'))}(
						<a href="https://1ex.com/" target="_blank" rel="noreferrer">
							1EX.com
						</a>
						) {String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_32'))}
					</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_33'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_34'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_35'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_36'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_37'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_38'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_39'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_40'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_41'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_42'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_43'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_44'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_45'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_46'))}</p>
					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_47'))}</h3>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_48'))}</p>
					<p>
						{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_49'))} <br />
						{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_50'))}{' '}
					</p>
					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_51'))}</h3>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_52'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_53'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_54'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_55'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_56'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_57'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_58'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_59'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_60'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_61'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_62'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_63'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_64'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_65'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_66'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_67'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_68'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_69'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_70'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_71'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_72'))}</p>

					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_73'))}</h3>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_74'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_75'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_76'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_77'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_78'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_79'))}</p>
					<p>
						{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_80'))}{' '}
						<a href="http://www.1ex.com/ru/">1EX.com</a>.{' '}
						{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_81'))}
					</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_82'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_83'))}</p>

					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_84'))}</h3>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_85'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_86'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_87'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_88'))}</p>

					<h4>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_89'))}</h4>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_90'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_91'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_92'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_93'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_94'))}</p>

					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_95'))}</h3>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_96'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_97'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_98'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_99'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_100'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_101'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_102'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_103'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_104'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_105'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_106'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_107'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_108'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_109'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_110'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_111'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_112'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_113'))}</p>
					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_114'))}</h3>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_115'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_116'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_117'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_118'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_119'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_120'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_121'))}</p>
					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_122'))}</h3>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_123'))}</p>
					<p>
						<strong>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_124'))}</strong>
					</p>
					<p>
						<strong>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_125'))}</strong>
					</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_126'))}</p>
					<p>
						<strong>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_127'))}</strong>
					</p>
					<p>
						<strong>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_128'))}</strong>
					</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_129'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_130'))}</p>
					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_131'))}</h3>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_132'))}</p>
					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_133'))}</h3>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_134'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_135'))}</p>
					<ul>
						<li>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_136'))}</li>
						<li>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_137'))}</li>
						<li>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_138'))}</li>
						<li>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_140'))}</li>
					</ul>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_141'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_142'))}</p>
					<ul>
						<li>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_143'))}</li>
						<li>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_144'))}</li>
						<li>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_145'))}</li>
						<li>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_146'))}</li>
						<li>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_147'))}</li>
						<li>
							{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_148'))} <br />
							{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_149'))}
						</li>
					</ul>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_150'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_151'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_152'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_153'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_154'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_155'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_156'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_157'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_158'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_159'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_160'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_161'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_162'))}</p>
					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_163'))}</h3>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_164'))}</p>
					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_165'))}</h3>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_166'))}</p>
					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_167'))}</h3>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_168'))}</p>
					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_169'))}</h3>
					<p>
						<strong>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_170'))}</strong>
					</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_171'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_172'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_173'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_174'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_175'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_176'))}</p>
					<h3>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_177'))}</h3>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_178'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_179'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_180'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_181'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_182'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_183'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_184'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_185'))}</p>
					<p>{String(L.translate('StaticPages.PrivacyPolicyPage.paragraph_186'))}</p>
				</div>
			</div>
		</section>
	);
};

export default Privacy;
