export interface ITwoFoStore {
	twoFaDate: ITwoFoDate | null;
	twoFaLoading: boolean;
	twoFaEnabledStatus: ETwoFaEnabledStatus | null;
}

export enum ETwoFaEnabledStatus {
	GOOGLE_TWO_FA_ENABLED = 'google2fa_enabled',
	SMS_TWO_FA_ENABLED = 'sms2fa_enabled',
	TWO_FA_DISABLED = '2fa_disabled',
}

export interface ITwoFoDate extends ITwoFaResponse {
	twoFaDateLoading: boolean;
}

export interface ITwoFaRequest {
	totp: string;
}

export interface ITwoFaResponse {
	secret: string;
	QR_Image: string;
	reauthenticating: string;
}

export interface ITwoFaStatusResponse {
	status: ETwoFaEnabledStatus;
}

export interface ITwoFaSmsEnableRequest {
	sms_totp: string;
	email_totp: string;
}

export interface ITwoFaSmsDisableRequest {
	sms_totp: string;
}
