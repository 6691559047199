import { endpoint } from 'services/endpoint';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import L from 'i18n-react';
import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { responseErrors } from 'services/http/responseErrors';
import {
	getPerpOrderBookSuccess,
	getPerpetualOrderBookRequest,
	getRecentPerpetualTradesRequest,
	getRecentPerpetualTradesSuccess,
	perpetualTradeInitState,
	getOrderHistoryRequest,
	getOrderHistorySuccess,
	getBaseAssetStatisticsRequest,
	getBaseAssetStatisticsSuccess,
	getAvailableBalanceRequest,
	getAvailableBalanceSuccess,
	createOrderRequest,
	getPerpetualOpenPositionsRequest,
	getPerpetualOpenPositionsSuccess,
	getMyOrdersRequest,
	getMyOrdersSuccess,
	getTradeHistoryRequest,
	getTradeHistorySuccess,
	getTransactionsRequest,
	getTransactionsSuccess,
	getUnrealisedPnlRequest,
	getUnrealisedPnlSuccess,
	getMyStopOrdersRequest,
	getMyStopOrdersSuccess,
	getStopOrderHistoryRequest,
	getStopOrderHistorySuccess,
	getClosedOrderHistoryRequest,
	getClosedOrderHistorySuccess,
	getUserStatRequest,
	getUserStatSuccess,
	getActiveBaseAssetRequest,
	getActiveBaseAssetSuccess,
	getDerivativeAccountRequest,
	getDerivativeAccountSuccess,
} from './reducer';
import { IOrderbookResponsePayload } from '../spotTrade/types';
import {
	IOrderHistoryResponse,
	IBaseAssetResponse,
	IBalanceResponse,
	ICreateOrderResponse,
	IOpenPositionsResponse,
	ITransactionHistoryReqPayload,
	IMyOrdersResponse,
	ITradeHistory,
	ITransactions,
	IStopOrders,
	IStopOrderHistory,
	IClosedOrderHistory,
	IActiveBaseAsset,
} from './types';

function* orderBookRequestWorker(action: PayloadAction) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IOrderbookResponsePayload = yield call(
			api.perpetualTrade.getOrderbook,
			payload,
		);
		yield put(getPerpOrderBookSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(perpetualTradeInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* recentTradesRequestWorker(action: PayloadAction) {
	const { payload } = action;
	try {
		const response: IOrderbookResponsePayload = yield call(
			// TODO: type is not valid for this worker
			api.perpetualTrade.getRecentTrades,
			payload,
		);
		yield put(getRecentPerpetualTradesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(perpetualTradeInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* orderHistoryRequestWorker(action: PayloadAction) {
	const { payload } = action;
	try {
		const response: IOrderHistoryResponse = yield call(api.perpetualTrade.getOrderHistory, payload);
		yield put(getOrderHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(perpetualTradeInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getBaseAssetStatisticsRequestWorker() {
	try {
		const response: IBaseAssetResponse = yield call(api.perpetualTrade.getBaseAssetStatistics);
		yield put(getBaseAssetStatisticsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
function* getBalanceRequestWorker() {
	try {
		const response: IBalanceResponse = yield call(api.perpetualTrade.getBalance);
		yield put(getAvailableBalanceSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* createOrderRequestWorker(action: any) {
	try {
		const { payload } = action;
		const response: ICreateOrderResponse = yield call(api.perpetualTrade.createOrder, payload);
		if (!response.error) {
			notificationContainer('Order created', 'success');
		}
	} catch (error: any) {
		// notificationContainer(String(error?.response?.data.errors[0]), 'error');
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getPerpetualOpenPositionsWorker(action: PayloadAction<ITransactionHistoryReqPayload>) {
	try {
		const { payload } = action;
		const response: IOpenPositionsResponse = yield call(
			api.perpetualTrade.getOpenPositions,
			payload,
		);
		yield put(getPerpetualOpenPositionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getMyOrdersRequestWorker(action: PayloadAction<ITransactionHistoryReqPayload>) {
	try {
		const { payload } = action;
		const response: IMyOrdersResponse = yield call(api.perpetualTrade.getMyOrders, payload);
		yield put(getMyOrdersSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getTradeHistoryRequestWorker(action: PayloadAction<ITransactionHistoryReqPayload>) {
	const { payload } = action;
	try {
		const response: ITradeHistory = yield call(api.perpetualTrade.getTradeHistory, payload);
		yield put(getTradeHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getTransactionsRequestWorker(action: PayloadAction<ITransactionHistoryReqPayload>) {
	const { payload } = action;
	try {
		const response: ITransactions = yield call(api.perpetualTrade.getTransactions, payload);
		yield put(getTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getUnrealisedPnlRequestWorker(action: any) {
	const { payload } = action;
	try {
		const response: ITransactions = yield call(api.perpetualTrade.getUnrealisedPnl, payload);
		yield put(getUnrealisedPnlSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(perpetualTradeInitState());
	}
}

function* getMyStopOrdersRequestWorker(action: PayloadAction<ITransactionHistoryReqPayload>) {
	const { payload } = action;
	try {
		const response: IStopOrders = yield call(api.perpetualTrade.getMyStopOrders, payload);
		yield put(getMyStopOrdersSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(perpetualTradeInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getStopOrderHistoryRequestWorker(action: PayloadAction<ITransactionHistoryReqPayload>) {
	const { payload } = action;
	try {
		const response: IStopOrderHistory = yield call(api.perpetualTrade.getStopOrderHistory, payload);
		yield put(getStopOrderHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(perpetualTradeInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getClosedOrderHistoryRequestWorker(action: PayloadAction<IClosedOrderHistory>) {
	const { payload } = action;
	try {
		const response: IStopOrderHistory = yield call(
			api.perpetualTrade.getClosedOrderHistory,
			payload,
		);
		yield put(getClosedOrderHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(perpetualTradeInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getUserStatWorker(action: any) {
	const { payload } = action;
	try {
		const response: IStopOrderHistory = yield call(api.perpetualTrade.getUserStat, payload);
		yield put(getUserStatSuccess(response.data));
	} catch (error) {
		console.error(error);
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getActiveBaseAssetRequestWorker(action: any) {
	const { payload } = action;
	try {
		const response: { data: IActiveBaseAsset[] } = yield call(
			api.perpetualTrade.getActiveBaseAsset,
			payload,
		);
		yield put(getActiveBaseAssetSuccess(response.data));
	} catch (error) {
		notificationContainer(
			String(L.translate('Wallets.Derivatives.messages.failed_to_fetch')),
			'error',
		);
		console.log(error);
	}
}

function* getDerivativeAccountRequestWorker(): any {
	try {
		const response = yield call(api.perpetualTrade.getDerivativeAccount);
		yield put(getDerivativeAccountSuccess(response.data));
	} catch (error) {
		console.log(error);
	}
}

export function* perpetualSaga() {
	yield takeEvery(getPerpetualOrderBookRequest.type, orderBookRequestWorker);
	yield takeEvery(getRecentPerpetualTradesRequest.type, recentTradesRequestWorker);
	yield takeEvery(getOrderHistoryRequest.type, orderHistoryRequestWorker);
	yield takeEvery(getBaseAssetStatisticsRequest.type, getBaseAssetStatisticsRequestWorker);
	yield takeEvery(getAvailableBalanceRequest.type, getBalanceRequestWorker);
	yield takeEvery(createOrderRequest.type, createOrderRequestWorker);
	yield takeEvery(getPerpetualOpenPositionsRequest.type, getPerpetualOpenPositionsWorker);
	yield takeEvery(getMyOrdersRequest.type, getMyOrdersRequestWorker);
	yield takeEvery(getTradeHistoryRequest.type, getTradeHistoryRequestWorker);
	yield takeEvery(getTransactionsRequest.type, getTransactionsRequestWorker);
	yield takeEvery(getUnrealisedPnlRequest.type, getUnrealisedPnlRequestWorker);
	yield takeEvery(getMyStopOrdersRequest.type, getMyStopOrdersRequestWorker);
	yield takeEvery(getStopOrderHistoryRequest.type, getStopOrderHistoryRequestWorker);
	yield takeEvery(getClosedOrderHistoryRequest.type, getClosedOrderHistoryRequestWorker);
	yield takeEvery(getUserStatRequest.type, getUserStatWorker);
	yield takeEvery(getActiveBaseAssetRequest.type, getActiveBaseAssetRequestWorker);
	yield takeEvery(getDerivativeAccountRequest.type, getDerivativeAccountRequestWorker);
}
