/* eslint-disable @typescript-eslint/no-shadow */
import { FC, SyntheticEvent } from 'react';
import L from 'i18n-react';
import useSelect from 'hooks/useSelect';
import { IAssetSelect } from './types';

const AssetSelect: FC<IAssetSelect> = ({ assetsList, label, value = null, onChange }) => {
	const { open, setOpen, toggleOpen, currentRef, dropRef } = useSelect();

	const handleTypeChoose = (e: SyntheticEvent<HTMLButtonElement>) => {
		const currentTarget = e.currentTarget as HTMLButtonElement;
		const { value: targetValue } = currentTarget;
		if (onChange) {
			onChange(Number(targetValue));
		}
		setOpen(false);
	};

	const handleAllSelect = () => {
		if (onChange) {
			onChange(null);
		}
		setOpen(false);
	};

	const selectedAsset = (value && assetsList?.find((assetItem) => assetItem.id === value)) || null;

	return (
		<div className="select-block">
			<p className="select-block__name">{label}</p>
			<div className={`select select--type3 ${open ? ' active' : ''}`}>
				<button type="button" onClick={toggleOpen} className="select__current" ref={currentRef}>
					<span className="select-current-currensy">
						{value == null ? (
							<p>{String(L.translate('TransactionHistory.filters.asset_all'))}</p>
						) : (
							<>
								<span className="select-current-currensy__icon">
									<img src={selectedAsset?.img_path} alt="coin" />
								</span>
								<span className="select-current-currensy__name">
									{selectedAsset?.code?.toUpperCase()}
								</span>
							</>
						)}
					</span>
					<span className="select__current-arrow">
						<svg
							width="10"
							height="6"
							viewBox="0 0 10 6"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M10 0.833008H1H0L5 5.83301L10 0.833008Z" fill="#1C1C29" />
						</svg>
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								<li>
									<button onClick={handleAllSelect} type="button">
										{L.translate('TransactionHistory.filters.asset_all')}
									</button>
								</li>
							</ul>
						</div>
						{assetsList?.map((assetItem) => {
							const { id, code, img_path: imgPath } = assetItem;
							return (
								<div key={id} className="select__drop-item">
									<ul>
										<li>
											<button value={id} onClick={handleTypeChoose} type="button">
												<span className="select-current-currensy">
													<span className="select-current-currensy__icon">
														<img src={imgPath} alt="coin" />
													</span>
													<span className="select-current-currensy__name">
														{code?.toUpperCase()}
													</span>
												</span>
											</button>
										</li>
									</ul>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AssetSelect;
