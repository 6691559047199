/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApiCheckCryptoAddressResponse } from 'services/api/wallets/types';
import {
	IGenerateAddressWalletPayload,
	IGenerateAddressWalletData,
	IWalletsResponsePayload,
	IWalletsCryptoHistoryRequestPayload,
	IWalletsDepositHistoryRequestPayload,
	IWalletsCryptoHistoryResponsePayload,
	IWalletsDepositHistoryResponsePayload,
	IWalletsStore,
	IWalletsNetwork,
	IWalletsNetworkRequestPayload,
	IWalletItem,
	IWithdrawCryptoPayload,
	ICheckCryptoAddressPayload,
	IGetWalletsConvertHistoryRequestPayload,
	IWalletsConvertHistory,
} from './types';

// ==========================================:
export const initialState: IWalletsStore = {
	walletsList: null,
	generateAddress: null,
	walletsLoader: false,
	generateAddressLoader: false,
	walletsCryptoHistory: null,
	walletsConvertHistory: null,
	walletsDepositHistory: null,
	walletsTransferHistory: null,
	walletsFiatHistory: null,
	walletsHistoryLoader: false,
	currentWalletNetworks: null,
	currentWallet: null,
	withdrawCryptoLoader: false,
	checkCryptoAddress: null,
};

// ==========================================:
const wallets = createSlice({
	name: '@@wallets',
	initialState,
	reducers: {
		getWalletsRequest: (state) => {
			const walletsRequestState = state;

			walletsRequestState.walletsLoader = true;
		},

		getWalletsSuccess: (state, action: PayloadAction<IWalletsResponsePayload>) => {
			const { payload } = action;
			const walletsSuccessState = state;

			walletsSuccessState.walletsList = payload;
			walletsSuccessState.walletsLoader = false;
		},

		updateWalletsSuccess: (state, action: PayloadAction<IWalletsResponsePayload>) => {
			const { payload } = action;
			const updateWalletsSuccessState = state;

			updateWalletsSuccessState.walletsList = payload;
			updateWalletsSuccessState.walletsLoader = false;
		},

		getGenerateAddressRequest: (state, action: PayloadAction<IGenerateAddressWalletPayload>) => {
			const generateAddressState = state;

			generateAddressState.generateAddressLoader = true;
		},

		getGenerateAddressSuccess: (state, action: PayloadAction<IGenerateAddressWalletData>) => {
			const { payload } = action;
			const generateAddressState = state;

			generateAddressState.generateAddressLoader = false;
			generateAddressState.generateAddress = payload;
		},
		clearWalletAddress: (state) => {
			const clearWalletAddressState = state;
			clearWalletAddressState.generateAddress = null;
		},

		getWalletsCryptoHistoryRequest: (
			state,
			action: PayloadAction<IWalletsCryptoHistoryRequestPayload>,
		) => {
			const walletsCryptoHistoryRequestState = state;

			walletsCryptoHistoryRequestState.walletsHistoryLoader = true;
		},

		getWalletsCryptoHistorySuccess: (
			state,
			action: PayloadAction<IWalletsCryptoHistoryResponsePayload>,
		) => {
			const { payload } = action;
			const walletsCryptoHistorySuccessState = state;

			walletsCryptoHistorySuccessState.walletsCryptoHistory = payload;
			walletsCryptoHistorySuccessState.walletsHistoryLoader = false;
		},

		getTransferHistoryRequest: (state, action: PayloadAction<any>) => {
			const walletsCryptoHistoryRequestState = state;

			walletsCryptoHistoryRequestState.walletsHistoryLoader = true;
		},

		getTransferHistorySuccess: (state, action: PayloadAction<any>) => {
			const { payload } = action;
			const walletsCryptoHistorySuccessState = state;

			walletsCryptoHistorySuccessState.walletsTransferHistory = payload;
			walletsCryptoHistorySuccessState.walletsHistoryLoader = false;
		},

		getFiatHistoryRequest: (state, action: PayloadAction<any>) => {
			const walletsCryptoHistoryRequestState = state;

			walletsCryptoHistoryRequestState.walletsHistoryLoader = true;
		},

		getFiatHistorySuccess: (state, action: PayloadAction<any>) => {
			const { payload } = action;
			const walletsFiatHistorySuccessState = state;

			walletsFiatHistorySuccessState.walletsFiatHistory = payload;
			walletsFiatHistorySuccessState.walletsHistoryLoader = false;
		},

		exportWalletsCryptoHistory: (
			state,
			action: PayloadAction<IWalletsCryptoHistoryRequestPayload>,
		) => {},

		getWalletsDepositHistoryRequest: (
			state,
			action: PayloadAction<IWalletsDepositHistoryRequestPayload>,
		) => {
			const walletsDepositHistoryRequestState = state;
			if (action.payload?.params.export) {
				return;
			}
			walletsDepositHistoryRequestState.walletsHistoryLoader = true;
		},

		getWalletsDepositHistorySuccess: (
			state,
			action: PayloadAction<IWalletsDepositHistoryResponsePayload>,
		) => {
			const { payload } = action;
			const walletsDepositHistorySuccessState = state;

			walletsDepositHistorySuccessState.walletsDepositHistory = payload;
			walletsDepositHistorySuccessState.walletsHistoryLoader = false;
		},

		getWalletsConvertHistoryRequest: (
			state,
			// action: PayloadAction<IGetWalletsConvertHistoryRequestPayload>,
			action: any,
		) => {
			const walletsConvertHistoryRequestState = state;
			walletsConvertHistoryRequestState.walletsHistoryLoader = true;
		},
		getWalletsConvertHistorySuccess: (
			state,
			{ payload }: PayloadAction<IWalletsConvertHistory>,
		) => {
			const walletsConvertHistoryRequestState = state;
			walletsConvertHistoryRequestState.walletsConvertHistory = payload;
			walletsConvertHistoryRequestState.walletsHistoryLoader = false;
		},

		exportWalletsDepositHistory: (
			state,
			action: PayloadAction<IWalletsCryptoHistoryRequestPayload>,
		) => {},

		getWalletToWalletNetworksRequest: (state, action: PayloadAction<string>) => state,
		getWalletSuccess: (state, { payload }: PayloadAction<IWalletItem>) => {
			const walletState = state;
			walletState.currentWallet = payload;
		},

		getWalletNetworksRequest: (state, action: PayloadAction<IWalletsNetworkRequestPayload>) =>
			state,
		getWalletNetworksSuccess: (state, { payload }: PayloadAction<IWalletsNetwork[]>) => {
			const walletNetworksState = state;
			walletNetworksState.currentWalletNetworks = payload;
		},

		withdrawCryptoRequest: (state, _: PayloadAction<IWithdrawCryptoPayload>) => {
			state.withdrawCryptoLoader = true;
		},
		withdrawCryptoSuccess: (state) => {
			state.withdrawCryptoLoader = false;
		},
		withdrawCryptoError: (state) => {
			state.withdrawCryptoLoader = false;
		},

		checkCryptoAddressDebounce: (state, _: PayloadAction<ICheckCryptoAddressPayload>) => state,
		checkCryptoAddressSuccess: (state, action: PayloadAction<IApiCheckCryptoAddressResponse>) => {
			state.checkCryptoAddress = action.payload;
		},
		checkCryptoAddressReset: (state) => {
			state.checkCryptoAddress = null;
		},

		walletsDisableHistoryLoading: (state) => {
			const newState = state;
			newState.walletsHistoryLoader = false;
		},
		walletsInitState: () => initialState,
	},
});

export default wallets.reducer;
export const {
	getWalletsRequest,
	getWalletsSuccess,
	updateWalletsSuccess,
	getGenerateAddressRequest,
	getGenerateAddressSuccess,
	clearWalletAddress,
	getWalletsCryptoHistoryRequest,
	getWalletsDepositHistoryRequest,
	getWalletsDepositHistorySuccess,
	getWalletsCryptoHistorySuccess,
	getWalletsConvertHistoryRequest,
	getWalletsConvertHistorySuccess,
	getTransferHistoryRequest,
	getTransferHistorySuccess,
	getFiatHistoryRequest,
	getFiatHistorySuccess,
	walletsDisableHistoryLoading,
	exportWalletsCryptoHistory,
	getWalletToWalletNetworksRequest,
	getWalletSuccess,
	getWalletNetworksRequest,
	getWalletNetworksSuccess,
	walletsInitState,
	withdrawCryptoSuccess,
	withdrawCryptoRequest,
	withdrawCryptoError,
	checkCryptoAddressDebounce,
	checkCryptoAddressSuccess,
	checkCryptoAddressReset,
} = wallets.actions;
