import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsPairChange } from 'redux/reducers/analytics/reducer';
import { getAssetsList } from 'redux/reducers/assets/selectors';
import { v4 as uuidv4 } from 'uuid';
import { getAnalyticsPair } from '../../../../redux/reducers/analytics/selectors';

const AnalyticsPair = () => {
	const pair = useSelector(getAnalyticsPair);
	const dispatch = useDispatch();

	const assets = useSelector(getAssetsList);
	const arrPair = assets?.map((item) => {
		return {
			pairs: item.code,
			id: item.id,
		};
	}) || [{ pairs: 'btc', id: 1 }];

	const replacePair = (pairText: string) => {
		return pairText.toUpperCase();
	};

	const pairView = () => {
		return arrPair?.map((e: { id: number; pairs: string }) => {
			if (pair === e.pairs) {
				return (
					<button
						key={uuidv4()}
						onClick={() => {
							dispatch(analyticsPairChange(e.pairs));
						}}
						type="button"
					>
						{replacePair(e.pairs)}
					</button>
				);
			}
			return '';
		});
	};
	const pairViewCheck = () => {
		return (
			<div className="header__lang-drop">
				{arrPair.map((e) => {
					return (
						<button
							key={uuidv4()}
							onClick={() => {
								dispatch(analyticsPairChange(e.pairs));
							}}
							type="button"
						>
							{replacePair(e.pairs)}
						</button>
					);
				})}
			</div>
		);
	};
	return (
		<>
			<div style={{ display: 'flex' }} className="header__lang pairs-analytics">
				{pairView()}
				{pairViewCheck()}
				<svg
					style={{ marginTop: '5px', marginLeft: '10px' }}
					width="10"
					height="6"
					viewBox="0 0 10 6"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M10 0.333328H1H0L5 5.33333L10 0.333328Z" fill="#1C1C29" />
				</svg>
			</div>
		</>
	);
};
export default AnalyticsPair;
