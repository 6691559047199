import { FC } from 'react';
import L from 'i18n-react';
import { WalletLayout } from 'layouts/Wallet';
import MarketTypeToggle from 'components/MarginWallets/MarketTypeToggle';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { convertToFixed } from 'services/utils/convertToFixed';
import { isIsolated } from './guards';
import { ILiquidationPage } from './types';

const LiquidationPage: FC<ILiquidationPage> = ({ liquidation, isolated, loading }) => {
	return (
		<WalletLayout
			title={String(L.translate('Wallets.MarginAccount.liquidation_orders.html_title'))}
		>
			<div className="wallet-content">
				<div className="wallet-content-header flex flex-sb">
					<div className="wallet-content-header__title">
						<p>{String(L.translate('Wallets.MarginAccount.liquidation_orders.title'))}</p>
					</div>
					{/* <MarketTypeToggle /> */}
				</div>
				<div className="wallet-content-tables">
					<div className="table table--wallet table--liquidation-orders-cross">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.date'))}</p>
								</div>
								{isolated && (
									<div className="td">
										<p>{String(L.translate('Wallets.MarginAccount.columns.pair'))}</p>
									</div>
								)}
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.price'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.amount'))}</p>
								</div>
								<div className="td td--right">
									<p>{String(L.translate('Wallets.MarginAccount.columns.total'))}</p>
								</div>
							</div>
						</div>
						<div className="table-body">
							{loading ? (
								<div className="popup-loader">
									<Loader />
								</div>
							) : (
								<>
									{!liquidation?.data || liquidation?.data?.length === 0 ? (
										<TableEmptyPlug />
									) : (
										liquidation?.data?.map((item) => (
											<div className="tr">
												<div className="td">
													<p>{customDateFormat(item.created_at)}</p>
												</div>
												{isolated && isIsolated(item) && (
													<div className="td">
														<p>{item.pair?.toUpperCase()}</p>
													</div>
												)}
												<div className="td">
													<p>{item.price && convertToFixed(item.price, 6)}</p>
												</div>
												<div className="td">
													<p>{item.amount && convertToFixed(item.amount, 6)}</p>
												</div>
												<div className="td td--right">
													<p>{item.total && convertToFixed(item.total, 2)}</p>
												</div>
											</div>
										))
									)}
								</>
							)}
						</div>
					</div>
				</div>
				{liquidation?.last_page && Number(liquidation?.total) > Number(liquidation?.per_page) && (
					<Pagination lastPage={liquidation.last_page} getCurrentHref={(s) => String(s)} />
				)}
			</div>
		</WalletLayout>
	);
};

export default LiquidationPage;
