import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import L from 'i18n-react';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, debounce, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { api } from 'services';
import { IApiCheckCryptoAddressResponse } from 'services/api/wallets/types';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	getGenerateAddressRequest,
	getGenerateAddressSuccess,
	getWalletsRequest,
	getWalletsSuccess,
	walletsInitState,
	getWalletsCryptoHistorySuccess,
	getWalletsCryptoHistoryRequest,
	exportWalletsCryptoHistory,
	getWalletsDepositHistoryRequest,
	getWalletsDepositHistorySuccess,
	getWalletNetworksRequest,
	getWalletToWalletNetworksRequest,
	getWalletNetworksSuccess,
	getWalletSuccess,
	withdrawCryptoRequest,
	withdrawCryptoSuccess,
	withdrawCryptoError,
	checkCryptoAddressSuccess,
	checkCryptoAddressReset,
	checkCryptoAddressDebounce,
	getWalletsConvertHistoryRequest,
	getWalletsConvertHistorySuccess,
	walletsDisableHistoryLoading,
	getTransferHistoryRequest,
	getTransferHistorySuccess,
	getFiatHistoryRequest,
	getFiatHistorySuccess,
} from './reducer';
import {
	IGenerateAddressWalletPayload,
	IGenerateAddressWalletData,
	IWalletsDepositHistoryData,
	IWalletsDepositHistoryRequestPayload,
	IWalletsResponsePayload,
	IWalletsCryptoHistoryData,
	IWalletsCryptoHistoryRequestPayload,
	IWalletsNetworkRequestPayload,
	IWalletsNetwork,
	IWithdrawCryptoPayload,
	ICheckCryptoAddressPayload,
	IGetWalletsConvertHistoryRequestPayload,
	IWalletsConvertHistory,
} from './types';

// =============================================================:
function* walletsRequestWorker() {
	try {
		yield put(showLoading());
		const response: IWalletsResponsePayload = yield call(api.wallets.getWallets);

		yield put(getWalletsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getGenerateAddressRequestWorker(action: PayloadAction<IGenerateAddressWalletPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IGenerateAddressWalletData = yield call(api.wallets.generateAddress, payload);
		yield put(getGenerateAddressSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error.response?.data.errors[0] === 'deposit disabled') {
				notificationContainer(String(L.translate('Errors.deposit_disabled')), 'error');
				return;
			}
			responseErrors(error);
		}
		yield put(walletsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getWalletsCryptoHistoryRequestWorker(
	action: PayloadAction<IWalletsCryptoHistoryRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IWalletsCryptoHistoryData = yield call(
			api.wallets.getWalletsCryptoHistory,
			payload,
		);

		yield put(getWalletsCryptoHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getWalletsDepositHistoryRequestWorker(
	action: PayloadAction<IWalletsDepositHistoryRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IWalletsDepositHistoryData = yield call(
			api.payments.getWalletsDepositHistory,
			payload,
		);
		if (response?.status === 'success') {
			notificationContainer('Fiat transaction history was sent to your email!', 'info');
		} else {
			yield put(getWalletsDepositHistorySuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getWalletsConvertHistoryRequestWorker({
	payload,
}: PayloadAction<IGetWalletsConvertHistoryRequestPayload>) {
	try {
		yield put(showLoading());
		const response: IWalletsConvertHistory = yield call(
			api.wallets.getWalletsConvertHistory,
			payload,
		);
		yield put(getWalletsConvertHistorySuccess(response));
	} catch (error) {
		notificationContainer('History of convert transaction not found!', 'info');
		yield put(walletsDisableHistoryLoading());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getTransferHistoryRequestWorker(action: PayloadAction<any>): any {
	const { payload } = action;

	try {
		const response: any = yield call(api.wallets.getTransferHistory, payload);
		yield put(getTransferHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// ================================================================
function* getFiatHistoryRequestWorker(action: PayloadAction<any>): any {
	const { payload } = action;

	try {
		const response: any = yield call(api.wallets.getFiatHistory, payload);
		const dataItem = {
			id: 1,
			user_id: 100,
			asset_id: 3,
			amount: 100,
			amount_fee: 0,
			payment_system: 'Card',
			status: 'processing',
			type: 'deposit',
			description: '',
			comment: '',
			to: '',
			withdraw_payment_system: '',
			refund_id: '',
			created_at: 1665088162,
			updated_at: 1665088162,
			indicated_amount: 100,
			asset: {
				id: 3,
				code: 'usd',
				name: 'United States Dollar',
				active: 1,
				icon: 'usd.png',
				img_path: 'http://localhost/storage/assets/usd.png',
			},
		};
		yield put(getFiatHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* exportWalletsCryptoHistoryWorker(
	action: PayloadAction<IWalletsCryptoHistoryRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.wallets.getWalletsCryptoHistory, payload);

		notificationContainer('Crypto transaction history was sent to your email!', 'info');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getWalletToWalletNetworksWorker({ payload }: PayloadAction<string>) {
	try {
		yield put(showLoading());
		const response: IWalletsResponsePayload = yield call(api.wallets.getWallets, payload);
		if (response) {
			yield put(
				getWalletNetworksRequest({ asset_id: response[0].asset.id, wallet_id: response[0].id }),
			);
			yield put(getWalletSuccess(response[0]));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getWalletNetworksWorker({ payload }: PayloadAction<IWalletsNetworkRequestPayload>) {
	try {
		yield put(showLoading());
		const response: IWalletsNetwork[] = yield call(api.wallets.getWalletsNetworks, payload);
		yield put(getWalletNetworksSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* withdrawCryptoWorker(action: PayloadAction<IWithdrawCryptoPayload>) {
	const { apiParams, onSuccess } = action.payload;

	try {
		yield call(api.wallets.withdrawCrypto, apiParams);

		yield put(withdrawCryptoSuccess());
		onSuccess?.();
	} catch (error) {
		yield put(withdrawCryptoError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* checkCryptoAddressWorker({ payload }: PayloadAction<ICheckCryptoAddressPayload>) {
	const { apiParams, isReset } = payload;

	if (isReset) {
		yield put(checkCryptoAddressReset());
		return;
	}
	try {
		const data: IApiCheckCryptoAddressResponse = yield call(
			api.wallets.checkCryptoAddress,
			apiParams,
		);
		yield put(checkCryptoAddressSuccess(data));
	} catch (error) {
		yield put(checkCryptoAddressReset());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

// =============================================================:
export function* walletsSaga() {
	yield takeEvery(getWalletsRequest.type, walletsRequestWorker);
	yield takeEvery(getGenerateAddressRequest.type, getGenerateAddressRequestWorker);
	yield takeEvery(getWalletsCryptoHistoryRequest.type, getWalletsCryptoHistoryRequestWorker);
	yield takeEvery(getWalletsDepositHistoryRequest.type, getWalletsDepositHistoryRequestWorker);
	yield takeEvery(getWalletsConvertHistoryRequest.type, getWalletsConvertHistoryRequestWorker);
	yield takeEvery(getTransferHistoryRequest, getTransferHistoryRequestWorker);
	yield takeEvery(getFiatHistoryRequest, getFiatHistoryRequestWorker);
	yield takeEvery(exportWalletsCryptoHistory.type, exportWalletsCryptoHistoryWorker);
	yield takeEvery(getWalletToWalletNetworksRequest.type, getWalletToWalletNetworksWorker);
	yield takeEvery(getWalletNetworksRequest.type, getWalletNetworksWorker);
	yield takeLeading(withdrawCryptoRequest, withdrawCryptoWorker);
	yield debounce(400, checkCryptoAddressDebounce, checkCryptoAddressWorker);
}
