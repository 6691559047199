/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	ICreateNewPasswordFormData,
	IEmailConfirmPayload,
	IEmailResetConfirmTokenPayload,
	IForgotEmail,
	IForgotPasswordResponse,
	IipConfirmPayloadData,
	ILoginPayload,
	IResetTwoFaPayload,
	ILoginResponse,
	IRegistrationData,
	IRegistrResponse,
} from 'redux/reducers/auth/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IAuthApi } from './types';

// ==========================================:
export const auth: IAuthApi = {
	login: (payload) =>
		http
			.post<ILoginPayload, { data: ILoginResponse }>(endpoint.auth.LOGIN, payload)
			.then((response) => {
				return response.data;
			}),

	registration: (payload) =>
		http
			.post<IRegistrationData, { data: IRegistrResponse }>(endpoint.auth.REGISTRATION, payload)
			.then((response) => response.data),

	forgotPassword: (payload) =>
		http
			.post<IForgotEmail, { data: IForgotPasswordResponse }>(endpoint.auth.RESET_PASSWORD, payload)
			.then((response) => response.data),

	emailConfirm: (payload) => http.put<IEmailConfirmPayload>(endpoint.auth.EMAIL_CONFIRM, payload),
	confirmPhone: (payload) => http.post<any>(endpoint.auth.CONFIRM_PHONE, payload),

	emailResetConfirmToken: (payload) =>
		http.post<IEmailResetConfirmTokenPayload>(endpoint.auth.RESET_EMAIL_CONFIRM, {
			token: payload.token,
		}),

	ipConfirm: (payload) => http.put<IipConfirmPayloadData>(endpoint.auth.IP_CONFIRM, payload),

	newPassword: (payload) =>
		http
			.put<ICreateNewPasswordFormData, { data: IRegistrResponse }>(
				endpoint.auth.NEW_PASSWORD,
				payload,
			)
			.then((response) => response.data),

	logout: () => http.post(endpoint.auth.LOGOUT),

	generateSms: (payload) =>
		http.post(endpoint.auth.GENERATE_SMS, payload).then((response) => response.data),
	generateEmailTotp: () =>
		http.post(endpoint.auth.GENERATE_EMAIL_TOTP).then((response) => response.data),
	resetTwoFa: (payload) =>
		http.post<IResetTwoFaPayload>(endpoint.auth.RESET_TWOFA, { ...payload?.data }),
	checkTwoFa: (params: any) => http.get(endpoint.auth.CHECK_TWOFA, { params }),
};
