import { ITwoFaApi } from './types';
import { http } from '../../http';
import { endpoint } from '../../endpoint';

// ==========================================:
// ==========================================:
export const twoFa: ITwoFaApi = {
	twoFaCheckEnabled: (payload?: string) =>
		http.get(endpoint.settings.CHECK_ENABLED_2FA(payload)).then((response) => {
			return response.data;
		}),
	twoFaGenerateKey: () =>
		http.get(endpoint.settings.GENERATE_2FA_KEY).then((response) => {
			return response.data;
		}),
	twoFaEnable: (payload) =>
		http.post(endpoint.settings.ENABLE_2FA, payload).then((response) => {
			return response.data;
		}),
	twoFaDisable: (payload) =>
		http.post(endpoint.settings.DISABLE_2FA, payload).then((response) => {
			return response.data;
		}),
	twoFaSmsEnable: (payload) =>
		http.post(endpoint.settings.ENABLE_SMS_2FA, payload).then((response) => {
			return response.data;
		}),
	twoFaSmsDisable: (payload) =>
		http.post(endpoint.settings.DISABLE_SMS_2FA, payload).then((response) => {
			return response.data;
		}),
};
