/* eslint-disable @typescript-eslint/naming-convention */
import useDecimals from 'hooks/useDecimals';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { setTempOrderPrice } from 'redux/reducers/orders/reducer';
import { getLocaleTimeFromTimestamp } from 'services/utils/dateAndTimeHelpers';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { IUserTradesTableItemProps } from './types';

const UserTradesTableItem: FC<IUserTradesTableItemProps> = ({ data }) => {
	// const { price, amount_sold_asset, amount_buy_asset, created_at, side } = data;
	const { price, created_at, type, executed } = data;
	const dispatch = useDispatch();

	const [priceDecimal] = useDecimals();

	const getPriceColorClass = (typeValue: string): string => {
		return `table-value ${typeValue?.includes('taker') ? 'red' : 'green'}`;
	};

	const handleSetTempOrderPrice = () => {
		dispatch(setTempOrderPrice(Number(price)));
	};

	return (
		<div className="trades-list__line" onClick={handleSetTempOrderPrice}>
			<span className={getPriceColorClass(type)}>
				{fixedCropNumber(Number(price), priceDecimal) || price}
			</span>

			<span>
				{executed?.toFixed(6)}
				{/* {side?.includes('sell') && Number(amount_sold_asset).toFixed(6)}
				{side?.includes('buy') && Number(amount_buy_asset * price).toFixed(6)} */}
			</span>

			<span>{getLocaleTimeFromTimestamp(created_at)}</span>
		</div>
	);
};

export default UserTradesTableItem;
