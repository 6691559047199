import { FC } from 'react';
import { Auth } from 'layouts/Auth';
import { Confirmated } from 'components/Forms/Confirmated';

export const Confirm: FC = () => {
	return (
		<Auth title="Confirm">
			<section className="authorization-section">
				<Confirmated />
			</section>
		</Auth>
	);
};
