import { FC } from 'react';
import L from 'i18n-react';

const TableEmptyPlug: FC = () => {
	return (
		<div className="table-body-plug">
			<svg
				width="49"
				height="48"
				viewBox="0 0 49 48"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M7 6C7 4.067 8.567 2.5 10.5 2.5H38.5C40.433 2.5 42 4.06702 42 6V27C42 27.8284 41.3284 28.5 40.5 28.5C39.6716 28.5 39 27.8284 39 27V6C39 5.72384 38.7762 5.5 38.5 5.5H10.5C10.2239 5.5 10 5.72386 10 6V42C10 42.2762 10.2238 42.5 10.5 42.5H21.5C22.3284 42.5 23 43.1716 23 44C23 44.8284 22.3284 45.5 21.5 45.5H10.5C8.56702 45.5 7 43.933 7 42V6Z"
					fill="#9D9DBC"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16 12C16 11.1716 16.6716 10.5 17.5 10.5H31.5C32.3284 10.5 33 11.1716 33 12C33 12.8284 32.3284 13.5 31.5 13.5H17.5C16.6716 13.5 16 12.8284 16 12Z"
					fill="#9D9DBC"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16 20C16 19.1716 16.6716 18.5 17.5 18.5H31.5C32.3284 18.5 33 19.1716 33 20C33 20.8284 32.3284 21.5 31.5 21.5H17.5C16.6716 21.5 16 20.8284 16 20Z"
					fill="#9D9DBC"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16 28C16 27.1716 16.6716 26.5 17.5 26.5H23.5C24.3284 26.5 25 27.1716 25 28C25 28.8284 24.3284 29.5 23.5 29.5H17.5C16.6716 29.5 16 28.8284 16 28Z"
					fill="#9D9DBC"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M26 37C26 33.4102 28.9102 30.5 32.5 30.5C36.0898 30.5 39 33.4102 39 37C39 38.2508 38.6458 39.4203 38.0332 40.4119L40.5607 42.9393C41.1464 43.5251 41.1464 44.4749 40.5607 45.0607C39.9749 45.6464 39.0251 45.6464 38.4393 45.0607L35.9119 42.5332C34.9203 43.1458 33.7508 43.5 32.5 43.5C28.9102 43.5 26 40.5898 26 37ZM34.9749 39.4748C35.6099 38.8398 36 37.9669 36 37C36 35.067 34.433 33.5 32.5 33.5C30.567 33.5 29 35.067 29 37C29 38.933 30.567 40.5 32.5 40.5C33.4669 40.5 34.3398 40.1099 34.9748 39.4749C34.9748 39.4749 34.9748 39.4749 34.9748 39.4748L34.9749 39.4748Z"
					fill="#9D9DBC"
				/>
			</svg>
			<p>{String(L.translate('Global.no_records_found'))}</p>
		</div>
	);
};

export default TableEmptyPlug;
