/* eslint-disable @typescript-eslint/no-explicit-any */
export const getLocaleTimeFromTimestamp = (timestamp: number): string =>
	new Date(timestamp * 1000).toLocaleTimeString();
export const getLocaleDateFromTimestamp = (timestamp: number): string =>
	new Date(timestamp * 1000).toLocaleDateString();
export const getTimeParserDateFromTimestamp = (timestamp: string): string => {
	return new Date(timestamp).toLocaleDateString();
};
export const getTimeParserTimeFromTimestamp = (timestamp: string): string => {
	return new Date(timestamp).toLocaleTimeString();
};

export const getTimeSum = (timeStart: any): string => {
	const one: any = new Date(timeStart);
	const two: any = new Date();
	const milliseconds = two - one;
	const seconds = milliseconds / 1000;
	const minutes = seconds / 60;
	const hours = minutes / 60;
	const days = hours / 24;
	return days < 1 ? 'today' : String(Math.floor(days));
};

export const customDateFormat: (
	timestamp: string | number | Date,
	isDate?: boolean,
	isIsoString?: boolean,
) => string = (timestamp, isDate, isIsoString) => {
	if (!timestamp) {
		return '-';
	}
	if (!isIsoString && typeof timestamp === 'string' && !Number(timestamp)) {
		return timestamp;
	}

	let date = isDate ? new Date(timestamp) : new Date(Number(timestamp) * 1000);

	if (isIsoString) {
		date = new Date(timestamp);
	}

	const year = date.getFullYear();
	let month = String(date.getMonth() + 1);
	if (month.length === 1) {
		month = `0${month}`;
	}
	let day = String(date.getDate());
	if (day.length === 1) {
		day = `0${day}`;
	}
	let hours: string | number = date.getHours();
	if (String(hours).length === 1) {
		hours = `0${hours}`;
	}
	let minutes: string | number = date.getMinutes();
	if (String(minutes).length === 1) {
		minutes = `0${minutes}`;
	}
	let seconds: string | number = date.getSeconds();
	if (String(seconds).length === 1) {
		seconds = `0${seconds}`;
	}

	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const convertMonthNumberToAbbreviatedMonthName = (monthNumber: number): string => {
	const monthNames = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'June',
		'July',
		'Aug',
		'Sept',
		'Oct',
		'Nov',
		'Dec',
	];

	return `${monthNames[monthNumber - 1]}`;
};

export const getTimeToDate = (futureDate: Date) => {
	const dateNow = new Date();
	let diffInMilliSeconds = Math.abs(futureDate.getTime() - dateNow.getTime()) / 1000;

	const days = Math.floor(diffInMilliSeconds / 86400);
	diffInMilliSeconds -= days * 86400;
	const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
	diffInMilliSeconds -= hours * 3600;
	const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
	diffInMilliSeconds -= minutes * 60;

	if (days) {
		return `${days}d`;
	}
	if (hours) {
		return `${hours}h`;
	}
	if (minutes) {
		return `${minutes}m`;
	}

	return '0m';
};
