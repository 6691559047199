const BaloonTip = (props: any) => {
	const { display, text } = props;
	const styles = {
		display: `${display ? 'block' : 'none'}`,
		opacity: 1,
	};
	return (
		<span className="funding-rate">
			<span className="baloon_tip__drop" style={styles}>
				{text}
			</span>
		</span>
	);
};

export default BaloonTip;
