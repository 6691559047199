import { FC, MouseEvent } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getAssetsList } from 'redux/reducers/assets/selectors';
import { IAssetBarProps } from './types';
/* eslint-disable no-debugger */

const AssetBar: FC<IAssetBarProps> = ({
	currentAssetTab,
	handleFilteredAssetPairs,
	selectDropdown,
	setSelectDropdown,
	changeStar,
	star,
}) => {
	const assetsList = useSelector(getAssetsList);

	const assetsListForHeader = assetsList?.slice(0, 6);
	// const assetsListForDropDown = assetsList?.slice(4);
	const assetsListForDropDown = assetsList;

	const handleSelectAsset = (e: MouseEvent<HTMLButtonElement>) => {
		handleFilteredAssetPairs(e);

		setSelectDropdown(false);
	};
	return (
		<div className="market-nav">
			<div className="market-nav__buttons">
				<button
					className={`market-nav__item ${
						currentAssetTab === 'star' ? 'market-nav__item--star-button' : ''
					}`}
					type="button"
					name="star"
					onClick={changeStar}
				>
					<span className="svg-star">
						<svg
							width="10"
							height="11"
							viewBox="0 0 10 11"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M4.46355 0.847861L3.24299 3.43348L0.512158 3.84944C0.0224388 3.92365 -0.173823 4.55443 0.181317 4.91572L2.15701 6.92719L1.68973 9.76863C1.60561 10.2822 2.12337 10.667 2.55701 10.4268L5 9.08512L7.44299 10.4268C7.87663 10.665 8.39439 10.2822 8.31028 9.76863L7.84299 6.92719L9.81868 4.91572C10.1738 4.55443 9.97756 3.92365 9.48784 3.84944L6.75701 3.43348L5.53645 0.847861C5.31776 0.38698 4.68411 0.381122 4.46355 0.847861Z"
								fill={star ? '#FF57D4' : '#9D9DBC'}
							/>
						</svg>
					</span>
				</button>

				{/* <button
					className={`market-nav__item ${
						currentAssetTab === 'margin' ? 'active' : ''
					}`}
					type="button"
					name="margin"
					onClick={handleFilteredAssetPairs}
				>
					{String(L.translate('Trade.Spot.SpotPairs.margin_pair_title'))}
				</button> */}
				<button
					className={`market-nav__item ${currentAssetTab === 'all' ? 'active' : ''}`}
					type="button"
					name="all"
					onClick={handleFilteredAssetPairs}
				>
					{String(L.translate('Trade.Spot.SpotPairs.all_pairs_title'))}
				</button>
				{assetsListForHeader?.length
					? assetsListForHeader?.map((asset) => (
							<button
								key={asset.id}
								className={`market-nav__item ${currentAssetTab === asset.code ? 'active' : ''}`}
								type="button"
								name={asset.code}
								onClick={handleFilteredAssetPairs}
							>
								{asset.code.toUpperCase()}
							</button>
					  ))
					: null}
			</div>
			<div className="select-block">
				<div className="select select--type5">
					<button
						className="select__current"
						type="button"
						onClick={() => {
							setSelectDropdown(!selectDropdown);
						}}
					>
						<span className="select__current-arrow">
							<svg
								width="10"
								height="6"
								viewBox="0 0 10 6"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M10 0.333496H1H0L5 5.3335L10 0.333496Z" fill="#1C1C29" />
							</svg>
						</span>
					</button>

					{selectDropdown && assetsListForDropDown?.length ? (
						<div className="select__drop active">
							<div className="select__drop-scroll">
								<div className="market-drop-list">
									{assetsListForDropDown.map((asset) => (
										<button
											key={asset.id}
											type="button"
											className={`market-drop-list__item ${
												currentAssetTab === asset.code ? 'active' : ''
											}`}
											name={asset.code}
											onClick={handleSelectAsset}
										>
											{asset.code.toUpperCase()}
										</button>
									))}
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default AssetBar;
