import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { twoFaEnableRequest, twoFaGenerateKeyRequest } from 'redux/reducers/twoFa/reducer';
import { getTwoFaData } from 'redux/reducers/twoFa/selectors';
import Loader from 'ui/Loader';

import appleTwoFaApp from 'assets/img/settings-2fa-app1.png';
import androidTwoFaApp from 'assets/img/settings-2fa-app2.png';

import { TFormTwoFa } from 'components/AccountSettings/AccountSettingsTwoFa/type';
import L from 'i18n-react';

const TwoFaAppForm: FC<TFormTwoFa> = ({ onClose }) => {
	const dispatch = useDispatch();
	const twoFaData = useSelector(getTwoFaData);
	const [codeInput, setCodeInput] = useState('');
	const setCodeInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		if (/^[0-9]*$/.test(value) && value.length <= 6) {
			setCodeInput(event.target.value);
		}
	};
	const confirmHandler = () => {
		dispatch(twoFaEnableRequest(codeInput));
	};
	useEffect(() => {
		dispatch(twoFaGenerateKeyRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="setting-content-body-item">
			<div className="settings-2fa">
				<div className="settings-2fa__title">
					<div className="settings-2fa__title-number">
						<p>1</p>
					</div>
					<p>{String(L.translate('Forms.Google2fa.google_2fa_form_step_one_title'))}</p>
				</div>
				<div className="settings-2fa__text">
					<p>{String(L.translate('Forms.Google2fa.google_2fa_form_step_one_text'))}</p>
				</div>
				<div className="settings-2fa-app flex flex-m">
					<a
						href="https://apps.apple.com/us/app/google-authenticator/id388497605"
						className="settings-2fa-app__item"
						target="_blank"
						rel="noreferrer"
					>
						<img src={appleTwoFaApp} alt="Apple TwoFa App" />
					</a>
					<a
						href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru&gl=US"
						className="settings-2fa-app__item"
						target="_blank"
						rel="noreferrer"
					>
						<img src={androidTwoFaApp} alt="Android TwoFa App" />
					</a>
				</div>

				<div className="settings-2fa__title">
					<div className="settings-2fa__title-number">
						<p>2</p>
					</div>
					<p>{String(L.translate('Forms.Google2fa.google_2fa_form_step_two_title'))}</p>
				</div>
				<div className="settings-2fa__text">
					<p>{String(L.translate('Forms.Google2fa.google_2fa_form_step_two_text'))}</p>
				</div>

				{twoFaData?.twoFaDateLoading ? (
					<div className="settings-2fa-loader">
						<Loader />
					</div>
				) : (
					<div className="settings-2fa-qr-code">
						<div className="settings-2fa-qr-code__photo">
							<img src={twoFaData?.QR_Image} alt="QR Code" />
						</div>
						<div className="settings-2fa-qr-code__info">
							<div className="input">
								<div className="input__name">
									<p>{String(L.translate('Forms.Google2fa.google_2fa_form_key_field_title'))}</p>
								</div>
								<div className="input-wrapper">
									<input
										type="text"
										className="input-item input-item--main-color2"
										value={twoFaData ? twoFaData.secret : ''}
										readOnly
									/>
								</div>
								<div className="input__notification input__notification--type3 flex-m">
									<span>
										<svg
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M3.66534 3.66582C5.28581 2.04537 7.52625 1.04199 9.99984 1.04199C12.4734 1.04199 14.7139 2.04537 16.3343 3.66582L15.8924 4.10777L16.3343 3.66583C17.9548 5.2863 18.9582 7.52674 18.9582 10.0003C18.9582 12.4739 17.9548 14.7144 16.3343 16.3348C14.7139 17.9553 12.4734 18.9587 9.99984 18.9587C7.52625 18.9587 5.28581 17.9553 3.66534 16.3348L4.10728 15.8929L3.66534 16.3348C2.04489 14.7144 1.0415 12.4739 1.0415 10.0003C1.0415 7.52674 2.04489 5.2863 3.66533 3.66583L3.66534 3.66582ZM9.99984 2.29199C7.87109 2.29199 5.94486 3.15409 4.54922 4.54971C3.1536 5.94536 2.2915 7.87158 2.2915 10.0003C2.2915 12.1291 3.1536 14.0553 4.54922 15.4509C5.94486 16.8466 7.87109 17.7087 9.99984 17.7087C12.1286 17.7087 14.0548 16.8466 15.4504 15.4509C16.8461 14.0553 17.7082 12.1291 17.7082 10.0003C17.7082 7.87158 16.8461 5.94535 15.4504 4.54971C14.0548 3.15409 12.1286 2.29199 9.99984 2.29199Z"
												fill="#EB5757"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M10.0002 15.4163C10.5755 15.4163 11.0418 14.95 11.0418 14.3747C11.0418 13.7994 10.5755 13.333 10.0002 13.333C9.42487 13.333 8.9585 13.7994 8.9585 14.3747C8.9585 14.95 9.42487 15.4163 10.0002 15.4163Z"
												fill="#EB5757"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M10 4.375C10.3452 4.375 10.625 4.65482 10.625 5V11.6667C10.625 12.0118 10.3452 12.2917 10 12.2917C9.65482 12.2917 9.375 12.0118 9.375 11.6667V5C9.375 4.65482 9.65482 4.375 10 4.375Z"
												fill="#EB5757"
											/>
										</svg>
									</span>
									<p>{String(L.translate('Forms.Google2fa.google_2fa_form_key_field_prompt'))}</p>
								</div>
							</div>
						</div>
					</div>
				)}
				<div className="settings-2fa__title">
					<div className="settings-2fa__title-number">
						<p>3</p>
					</div>
					<p>{String(L.translate('Forms.Google2fa.google_2fa_form_step_tree_title'))}</p>
				</div>
				<div className="settings-2fa-item">
					<div className="input">
						<div className="input__name">
							<p>{String(L.translate('Forms.Google2fa.google_2fa_form_field_title'))}</p>
						</div>
						<div className="input-wrapper">
							<input
								type="text"
								value={codeInput}
								onChange={setCodeInputHandler}
								className="input-item"
								placeholder={String(L.translate('Forms.Google2fa.google_2fa_form_field_text'))}
							/>
						</div>
						<div className="input__notification input__notification--type2">
							<p>
								<span className="red">* </span>
								{String(L.translate('Forms.Google2fa.google_2fa_form_field_prompt'))}
							</p>
						</div>
					</div>
				</div>
				<div className="settings-2fa-item settings-2fa-item--type2">
					<button
						type="button"
						onClick={onClose}
						className="button button--size2 button--full-width button--type3"
					>
						{String(L.translate('Forms.Google2fa.google_2fa_form_btn_cancel'))}
					</button>
					<button
						type="button"
						onClick={confirmHandler}
						disabled={!(codeInput !== '')}
						className="button button--size2 button--full-width"
					>
						{String(L.translate('Forms.Google2fa.google_2fa_form_btn_enable'))}
					</button>
				</div>
			</div>
		</div>
	);
};

export default TwoFaAppForm;
