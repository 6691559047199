/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TApiGetReferralBonusesResponse } from 'services/api/settings/types';
import { DEFAULT_TRANSACTION_SPEED_STANDARD, THEME_DARK } from './constants';
import {
	TDefaultTransactionSpeed,
	TSettingsStore,
	TTheme,
	TInvitationsData,
	IUserSettingsData,
	TInvitationsAll,
	TRefMemeber,
	TChangePhoneRequest,
} from './types';
// ==========================================:
export const initialState: TSettingsStore = {
	userSettingsData: null,
	userSettingsDataLoader: false,
	theme: THEME_DARK,
	slippageTolerance: 0.1,
	defaultTransactionSpeed: DEFAULT_TRANSACTION_SPEED_STANDARD,
	partialOrderExecution: false,
	invitations: null,
	invitationsLoader: false,
	referralBonuses: null,
	referralBonusesLoading: false,
	kycLoader: false,
	phoneLoader: false,
	smsCode: null,
	antiPhishingLoader: false,
	antiPhishingEnabled: false,
};
/* eslint-disable no-debugger */
// ==========================================:
const settings = createSlice({
	name: '@@settings',
	initialState,
	reducers: {
		userSettingsRequest: (state) => {
			const userSettingsState = state;
			userSettingsState.userSettingsDataLoader = true;
		},
		userSettingsSuccess: (state, action: PayloadAction<IUserSettingsData>) => {
			const { payload } = action;
			const userSettingsState = state;
			userSettingsState.userSettingsDataLoader = false;
			userSettingsState.userSettingsData = payload;
		},
		changeTheme: (state, action: PayloadAction<TTheme>) => {
			const { payload } = action;
			const settingsState = state;
			settingsState.theme = payload;
		},
		changeSlippageTolerance: (state, action: PayloadAction<number>) => {
			const { payload } = action;
			const settingsState = state;
			settingsState.slippageTolerance = payload;
		},
		setDefaultTransactionSpeed: (state, action: PayloadAction<TDefaultTransactionSpeed>) => {
			const { payload } = action;
			const settingsState = state;
			settingsState.defaultTransactionSpeed = payload;
		},
		setPartialOrderExecution: (state, action: PayloadAction<boolean>) => {
			const { payload } = action;
			const settingsState = state;
			settingsState.partialOrderExecution = payload;
		},
		getUserReferralsInfoRequest: (state, action?: PayloadAction<boolean>) => {
			const settingsState = state;
			if (!action) {
				settingsState.invitationsLoader = true;
			}
		},

		getUserReferralsInfoSuccess: (state, action: PayloadAction<TInvitationsData>) => {
			const { payload } = action;
			const settingsState = state;
			settingsState.invitations = payload;

			settingsState.invitationsLoader = false;
		},

		getReferralBonusesRequest: (state) => {
			state.referralBonusesLoading = true;
		},
		getReferralBonusesSuccess: (state, action: PayloadAction<TApiGetReferralBonusesResponse>) => {
			const { payload } = action;
			state.referralBonuses = payload;
			state.referralBonusesLoading = false;
		},
		getReferralBonusesError: (state) => {
			state.referralBonuses = null;
			state.referralBonusesLoading = false;
		},

		postInvitationEmailSendRequest: (state, action: PayloadAction<TRefMemeber>) => {
			// const settingsState = state;
		},
		postInvitationEmailSendSuccess: (state, action: PayloadAction<TInvitationsAll>) => {
			// const { payload } = action;
			// const settingsState = state;
			// if (payload) {
			// 	settingsState.invitations?.invitations_all.push(payload);
			// }
		},
		postKycValidationRequest: (state, action) => {
			state.kycLoader = true;
		},
		postKycValidationSuccess: (state, action) => {
			const { payload } = action;
			state.kycLoader = false;
			window.location.href = payload.data.url;
		},
		setPhoneRequest: (state, action: PayloadAction<TChangePhoneRequest>) => state,
		setAntiPhishingCodeRequest: (state, action) => {},
		checkAntiPhishingStateRequest: (state) => {
			state.antiPhishingLoader = true;
		},
		checkAntiPhishingStateSuccess: (state, action) => {
			const { payload } = action;
			state.antiPhishingEnabled = payload;
			state.antiPhishingLoader = false;
		},
		disableAntiPhishingRequest: (state) => {
			state.antiPhishingLoader = true;
		},
		disableAntiPhishingSuccess: (state) => {
			state.antiPhishingEnabled = false;
			state.antiPhishingLoader = false;
		},
		setNewPasswordRequest: (state, action) => {},

		themeInitState: () => initialState,

		settingsInitState: () => initialState,
	},
});

export default settings.reducer;
export const {
	changeTheme,
	getUserReferralsInfoRequest,
	getUserReferralsInfoSuccess,
	getReferralBonusesRequest,
	getReferralBonusesSuccess,
	getReferralBonusesError,
	userSettingsRequest,
	userSettingsSuccess,
	settingsInitState,
	postInvitationEmailSendRequest,
	postInvitationEmailSendSuccess,
	postKycValidationRequest,
	postKycValidationSuccess,
	setPhoneRequest,
	setAntiPhishingCodeRequest,
	checkAntiPhishingStateRequest,
	checkAntiPhishingStateSuccess,
	disableAntiPhishingRequest,
	disableAntiPhishingSuccess,
	setNewPasswordRequest,
} = settings.actions;
