import { FC, SyntheticEvent } from 'react';
import L from 'i18n-react';
import { useFormikContext, FormikContextType } from 'formik';
import { useSelector } from 'react-redux';
import { getPerpetualBalance } from 'redux/reducers/perpetual/selectors';
import { IAdjustMarginForm } from './types';

const AdjustMarginForm: FC<IAdjustMarginForm> = ({ onClose, item }) => {
	const { values, setValues, isValid, errors, isSubmitting }: FormikContextType<{ price: string }> =
		useFormikContext();

	const available = useSelector(getPerpetualBalance);

	const handleClose = () => {
		onClose && onClose();
	};

	const handlePriceChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: targetValue } = target;
		setValues({
			...values,
			price: targetValue,
		});
	};

	console.log(available);

	return (
		<>
			<button className="popup__close" type="button" onClick={handleClose}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
						fill="#1C1C29"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
						fill="#1C1C29"
					/>
				</svg>
			</button>
			<div className="popup-header">
				<p className="popup-header__title">
					{String(L.translate('Trade.Perpetual.Modals.adjust_margin'))}
				</p>
			</div>
			<div className="popup-body">
				<div className="adjust-margin-pop">
					<div className="adjust-margin-pop-info">
						<div className="adjust-margin-pop-info__item">
							<p>{String(L.translate('Trade.Perpetual.Modals.current_position'))}</p>
							<span>
								{item.amount_base_asset} {item.base_asset?.toUpperCase()} [
								{Math.ceil(Number(item.middle_leverage_size))}X]
							</span>
						</div>
						<div className="adjust-margin-pop-info__item">
							<p>{String(L.translate('Trade.Perpetual.Modals.currently_assigned_margin'))}</p>
							<span>{item.amount_leverage_deposit_in_usdt?.toFixed(2)} USDT</span>
						</div>
						<div className="adjust-margin-pop-info__item">
							<p>{String(L.translate('Trade.Perpetual.Modals.available_margin'))}</p>
							<span>{available?.available_balance_usdt.toFixed(2) || '--'} USDT</span>
						</div>
					</div>
					<label className="orderform-input orderform-input--type2">
						<span className="orderform-input__placeholder">
							{String(L.translate('Trade.Perpetual.Modals.price'))}
						</span>
						<input
							className="orderform-input__data"
							type="text"
							placeholder="0.0"
							value={values.price}
							onChange={handlePriceChange}
						/>
						<span className="orderform-input__placeholder">USDT</span>
					</label>
					<span className="input-notify">{errors?.price}</span>
				</div>
			</div>
			<div className="popup-footer popup-footer--item2">
				<button
					className="button button--type3 button--size2 button--full-width"
					onClick={handleClose}
					type="button"
				>
					{String(L.translate('Trade.Perpetual.Modals.cancel'))}
				</button>
				<button className="button button--size2 button--full-width" type="submit">
					{String(L.translate('Trade.Perpetual.Modals.confirm'))}
				</button>
			</div>
		</>
	);
};

export default AdjustMarginForm;
