/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC, useEffect, useState, MouseEvent, ChangeEvent } from 'react';
import Loader from 'ui/Loader';
import { useDispatch, useSelector } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import L from 'i18n-react';
import {
	getReferralBonusesRequest,
	getUserReferralsInfoRequest,
	postInvitationEmailSendRequest,
} from 'redux/reducers/settings/reducer';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getInvitations, getUserSettingsDataLoader } from 'redux/reducers/settings/selectors';
// import { trimLink } from 'services/utils/textSlice';
// import referImg from 'assets/img/refer-icon.svg';
// import gatoEarning from 'assets/img/gato-earnigs.png';
import copy from 'assets/img/copied.svg';
import { TInvitationsAll } from 'redux/reducers/settings/types';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import yup from 'services/utils/capsLockValidate';
import TextError from 'ui/Formik/TextError';
import emailRegex from 'services/utils/emailRegex';
import Input from 'ui/Formik/Input';

import { RefferalsTable } from './Refferals';
import { BonusesTable } from './Bonuses';
/* eslint-disable no-debugger */

export const Referral: FC = () => {
	const [currentTab, setCurrentTab] = useState('refferals');
	const dispatch = useDispatch();
	const dataLoad = useSelector(getUserSettingsDataLoader);
	const invitation = useSelector(getInvitations);
	const [referral, setReferral] = useState(invitation?.invite_url || '');
	const [isCapsLockOn, setIsCapsLockOn] = useState(false);

	useEffect(() => {
		if (invitation) {
			setReferral(invitation.invite_url);
		}
	}, [invitation]);

	/// debugger;
	// `${String(user?.referral_link)}`
	// const [isCopied, setCopied] = useClipboard(String(invitation?.invite_url) || '');
	const data: Array<TInvitationsAll> | null = invitation && invitation.invitations_all;
	// debugger;
	useEffect(() => {
		dispatch(getUserReferralsInfoRequest(false));
		dispatch(getReferralBonusesRequest());
	}, [dispatch]);

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.capsLockValidate(isCapsLockOn, 'CapsLock is on')
			.required('Please, enter email of referral user!')
			.email('Invalid email format!')
			.matches(emailRegex, 'Invalid email format!')
			.max(60, 'email max characters'),
	});

	const tabs: any = {
		refferals: <RefferalsTable data={data} />,
		bonuses: <BonusesTable />,
	};

	const handleTab = (e: MouseEvent<HTMLButtonElement>) => {
		setCurrentTab(e.currentTarget.name);
	};

	const handleAddressCopy = () => {
		notificationContainer(String(L.translate('Account.Referral.link_copied')), 'info');
	};

	const sendEmail = (email: string | null) => {
		if (!email) return;
		dispatch(postInvitationEmailSendRequest({ email }));
	};

	const handleCapsLockOn = (e: KeyboardEvent) => {
		if (e.getModifierState('CapsLock')) {
			setIsCapsLockOn(true);
		} else {
			setIsCapsLockOn(false);
		}
	};

	return (
		<section className="referral-program-section section-gradient">
			{dataLoad ? (
				<div className="table-loader-wrapper">
					<Loader />
				</div>
			) : (
				<div className="inside">
					<div className="refer-box">
						<div className="referral-program">
							<div className="referral-program-form">
								<div className="referral-program-form__inside">
									<div className="referral-program-form__title">
										<p>{L.translate('Account.Referral.send_link_via_email')}</p>
									</div>
									<div className="referral-program-form__input">
										<Formik
											initialValues={{ email: '' }}
											validationSchema={validationSchema}
											onSubmit={(values, { resetForm, setSubmitting }) => {
												sendEmail(values.email);
												setSubmitting(false);
												resetForm();
											}}
											validateOnBlur
											enableReinitialize
										>
											{({ errors, touched, isSubmitting, isValid, dirty }) => {
												return (
													<Form style={{ display: 'contents' }}>
														<div className="input">
															<div className="input-wrapper">
																<Field
																	type="email"
																	placeholder="Email"
																	name="email"
																	title={String(L.translate('Forms.Refferal.email'))}
																	required
																	onKeyUp={handleCapsLockOn}
																	component={Input}
																	className="input-item"
																/>
															</div>
														</div>
														<button
															disabled={!(isValid && dirty) || isSubmitting}
															type="submit"
															className="button button--size2"
														>
															{L.translate('Account.Referral.send_button')}
														</button>
													</Form>
												);
											}}
										</Formik>
									</div>
									<div className="referral-program-form__text">
										<p>{L.translate('Account.Referral.copy_link')}</p>
									</div>
									<CopyToClipboard text={referral || ''} onCopy={handleAddressCopy}>
										<button type="button" className="button button--type-text2">
											{/* <img className="check-img" src={copy} alt="copy" /> */}
											{referral || ''}
											<svg
												width="25"
												height="25"
												viewBox="0 0 25 25"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M8.40625 3.75C8.04381 3.75 7.75 4.04381 7.75 4.40625V6.7158C7.75 7.13001 7.41421 7.4658 7 7.4658C6.58579 7.4658 6.25 7.13001 6.25 6.7158V4.40625C6.25 3.21539 7.21539 2.25 8.40625 2.25H20.5938C21.7846 2.25 22.75 3.21539 22.75 4.40625V16.5938C22.75 17.7846 21.7846 18.75 20.5938 18.75H18.2582C17.8439 18.75 17.5082 18.4142 17.5082 18C17.5082 17.5858 17.8439 17.25 18.2582 17.25H20.5938C20.9562 17.25 21.25 16.9562 21.25 16.5938V4.40625C21.25 4.04381 20.9562 3.75 20.5938 3.75H8.40625Z"
													fill="#7D7D4D"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M2.25 8.40625C2.25 7.21539 3.21539 6.25 4.40625 6.25H16.5938C17.7846 6.25 18.75 7.21539 18.75 8.40625V20.5938C18.75 21.7846 17.7846 22.75 16.5938 22.75H4.40625C3.21539 22.75 2.25 21.7846 2.25 20.5938V8.40625ZM4.40625 7.75C4.04381 7.75 3.75 8.04381 3.75 8.40625V20.5938C3.75 20.9562 4.04381 21.25 4.40625 21.25H16.5938C16.9562 21.25 17.25 20.9562 17.25 20.5938V8.40625C17.25 8.04381 16.9562 7.75 16.5938 7.75H4.40625Z"
													fill="#7D7D4D"
												/>
											</svg>
										</button>
									</CopyToClipboard>
								</div>
							</div>
							<div className="referral-program-info">
								<div className="referral-program-info__item">
									<div className="referral-program-info__title">
										{L.translate('Account.Referral.invitations_sent')}
									</div>
									<div className="referral-program-info__number-block">
										<div className="referral-program-info__number-counter">
											<p>{invitation?.invitations_by_status?.sent || 0}</p>
										</div>
										<div className="referral-program-info__number-text">
											<p>{L.translate('Account.Referral.invitations_sent_comment')}</p>
										</div>
									</div>
								</div>
								<div className="referral-program-info__item">
									<div className="referral-program-info__title">
										{L.translate('Account.Referral.invitations_waiting')}
									</div>
									<div className="referral-program-info__number-block">
										<div className="referral-program-info__number-counter">
											<p>{invitation?.invitations_by_status?.pending || 0}</p>
										</div>
										<div className="referral-program-info__number-text">
											<p>{L.translate('Account.Referral.invitations_waiting_comment')}</p>
										</div>
									</div>
								</div>
								<div className="referral-program-info__item">
									<div className="referral-program-info__title">
										{L.translate('Account.Referral.invitations_completed')}
									</div>
									<div className="referral-program-info__number-block">
										<div className="referral-program-info__number-counter">
											<p>{invitation?.invitations_by_status?.completed || 0}</p>
										</div>
										<div className="referral-program-info__number-text">
											<p>{L.translate('Account.Referral.invitations_completed_comment')}</p>
										</div>
									</div>
								</div>
							</div>

							<div className="referral-program-table">
								<div className="referral-program-table-nav flex flex--gap8">
									<button
										name="refferals"
										onClick={handleTab}
										type="button"
										className={`button ${
											currentTab === 'refferals' ? '' : 'button--type5 button--type5--black'
										}`}
									>
										{L.translate('Account.Referral.referral_list')}
									</button>

									<button
										name="bonuses"
										onClick={handleTab}
										type="button"
										className={`button ${
											currentTab === 'refferals' ? 'button--type5 button--type5--black' : ''
										}`}
									>
										{L.translate('Account.Referral.bonuses')}
									</button>
								</div>

								{tabs[currentTab]}
							</div>
						</div>
					</div>
				</div>
			)}
		</section>
	);
};
