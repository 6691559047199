import SocketWithTopicConnectWrapper from 'components/SocketWithTopicConnectWrapper';
import { useSelector } from 'react-redux';
import L from 'i18n-react';
import {
	getBaseAsset,
	getPerpetualOrderBook,
	getPerpOrderBookLoader,
} from 'redux/reducers/perpetual/selectors';
import Loader from 'ui/Loader';
import LastPrice from './LastPrice';
import OrderBookItem from './OrderBookItem';
import TradeBook from './OrderBookItem/TradeTable';
import OrderBookTable from './OrderBookTable';

const OrderBook = ({ setPrice }: { setPrice: any }) => {
	const orderbook = useSelector(getPerpetualOrderBook);
	const asset = useSelector(getBaseAsset);
	const orderBookLoader = useSelector(getPerpOrderBookLoader);

	const askOrderBookItems = () => {
		const totals = orderbook?.ask.map((item) => Number(item.total)) || [0];
		const maxValue = Math.max(...totals);
		return orderbook?.ask.map((item, index: number) => {
			return (
				<OrderBookItem
					colour="red"
					item={item}
					maxTotal={maxValue}
					key={String(
						((item.price_entry_for_order * item.amount_asset_request!) / index) * Math.random(),
					)}
				/>
			);
		});
	};
	const bidOrderBookItems = () => {
		const totals = orderbook?.bid.map((item) => Number(item.total)) || [0];
		const maxValue = Math.max(...totals);
		return orderbook?.bid.map((item, index: number) => {
			return (
				<OrderBookItem
					colour="green"
					item={item}
					maxTotal={maxValue}
					key={String(
						((item.price_entry_for_order * item.amount_asset_request!) / index) * Math.random(),
					)}
				/>
			);
		});
	};

	const handleItemClick = (e: any) => {
		const { item } = e.target.dataset;
		setPrice(item);
	};

	return (
		<SocketWithTopicConnectWrapper
			topicName={`order_book:future:${String(asset?.base_asset || 'btc')}`}
		>
			<div className="orderbook-trades-wrap">
				<div className="orderbook">
					<div className="orderbook-header">
						<div className="orderbook-header__title">
							<p>{String(L.translate('Trade.Perpetual.OrderBook.orderbook_title'))}</p>
						</div>
					</div>
					<div className="orderbook-body" onClick={handleItemClick}>
						<OrderBookTable>
							<div className="orderbook-body-item__scroll orderbook-ask-table-body">
								{orderBookLoader ? (
									<div className="orderbook-loader-wrapper">
										<Loader />
									</div>
								) : (
									askOrderBookItems()
								)}
							</div>
						</OrderBookTable>
						<LastPrice />
						<OrderBookTable mob>
							<div className="orderbook-body-item__scroll orderbook-body-item__scroll--type2">
								{orderBookLoader ? (
									<div className="orderbook-loader-wrapper">
										<Loader />
									</div>
								) : (
									bidOrderBookItems()
								)}
							</div>
						</OrderBookTable>
					</div>
				</div>
				<TradeBook />
			</div>
		</SocketWithTopicConnectWrapper>
	);
};
export default OrderBook;
