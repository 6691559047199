import {
	IGenerateAddressWalletPayload,
	IGenerateAddressWalletData,
	IWalletsResponsePayload,
	IWalletsCryptoHistoryResponsePayload,
	IWalletsNetwork,
	IWalletsConvertHistory,
} from 'redux/reducers/wallets/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IWalletsApi } from './types';

// ==========================================:
export const wallets: IWalletsApi = {
	getWallets: (payload) =>
		http
			.get<IWalletsResponsePayload>(endpoint.wallets.WALLETS(payload))
			.then((response) => response.data),

	generateAddress: (payload) =>
		http
			.get<IGenerateAddressWalletPayload, { data: IGenerateAddressWalletData }>(
				endpoint.wallets.GENERATE_ADDRESS(payload.wallet_id, payload.network, payload.asset_id),
			)
			.then((response) => response.data),

	getWalletsCryptoHistory: (payload) =>
		http
			.get<IWalletsCryptoHistoryResponsePayload>(endpoint.wallets.CRYPTO_HISTORY, payload)
			.then((response) => response.data),
	getWalletsConvertHistory: (payload) =>
		http
			.get<IWalletsConvertHistory>(
				endpoint.wallets.CONVERT_HISTORY(
					payload.from_asset_id,
					payload.to_asset_id,
					payload.past_days,
					payload.current_page,
					payload.per_page,
				),
			)
			.then((response) => response.data),
	getTransferHistory: (payload) =>
		http.get(endpoint.wallets.TRANSFER_HISTORY, payload).then((response) => response.data),
	getFiatHistory: (payload) =>
		http.get(endpoint.wallets.FIAT_HISTORY, payload).then((response) => response.data),
	getWalletsNetworks: ({ wallet_id, asset_id }) =>
		http
			.get<IWalletsNetwork[]>(endpoint.wallets.GET_WALLET_NETWORKS(wallet_id, asset_id))
			.then((response) => response.data),

	withdrawCrypto(params) {
		return http.post(endpoint.wallets.WITHDRAW_CRYPTO, params).then(({ data }) => data);
	},

	checkCryptoAddress(params) {
		return http.get(endpoint.wallets.CHECK_CRYPTO_ADDRESS, { params }).then(({ data }) => data);
	},
};
