import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import L from 'i18n-react';
import { api } from 'services';
import yup from 'services/utils/capsLockValidate';
import { notificationContainer } from 'services/utils/notificationContainer';
import AdjustMarginForm from './AdjustMarginForm';
import { IAdjustMarginPopup } from './types';

const initialValues = {
	amount: '',
};

const AdjustMarginPopup: FC<IAdjustMarginPopup> = ({ item, onClose, refetch }) => {
	const dispatch = useDispatch();
	const validationSchema = yup.object().shape({
		price: yup
			.number()
			.positive(String(L.translate('Trade.Perpetual.messages.price_can_not_be_negative')))
			.typeError(String(L.translate('Trade.Perpetual.messages.price_must_be_a_number')))
			.required(String(L.translate('Trade.Perpetual.messages.price_is_required'))),
	});

	return (
		<div className="popup-window adjust-margin-form">
			<div className="popup-window__inside">
				<div className="popup">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={async (values: any, { resetForm, setValues, setSubmitting }) => {
							try {
								await api.perpetualTrade.addMargin({
									future_position_id: item.id,
									added_leverage_deposit: Number(values.price),
								});
								notificationContainer(
									String(L.translate('Trade.Perpetual.messages.successfully_added_margin')),
									'success',
								);
								onClose && onClose();
								refetch && refetch();
							} catch (error) {
								notificationContainer(
									String(L.translate('Trade.Perpetual.messages.failed_to_add_margin')),
									'success',
								);
								console.error(error);
							}
						}}
					>
						<Form>
							<AdjustMarginForm item={item} onClose={onClose} />
						</Form>
					</Formik>
				</div>
			</div>
		</div>
	);
};
export default AdjustMarginPopup;
