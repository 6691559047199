import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { History } from 'history';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import assetPairs from 'redux/reducers/assetPairs/reducer';
import assets from 'redux/reducers/assets/reducer';
import spotTrade from 'redux/reducers/spotTrade/reducer';
import wallets from 'redux/reducers/wallets/reducer';
import sockets from 'redux/reducers/sockets/reducer';
import app from 'redux/reducers/app/reducer';
import tradingSettings from 'redux/reducers/tradingSettings/reducer';
import orders from 'redux/reducers/orders/reducer';
import marginWallets from 'redux/reducers/marginWallets/reducer';
import perpetualTrade from 'redux/reducers/perpetual/reducer';
import settings from './reducers/settings/reducer';
import auth from './reducers/auth/reducer';
import twoFa from './reducers/twoFa/reducer';
import optionsTrade from './reducers/options/reducer';
import analytics from './reducers/analytics/reducer';
import exchange from './reducers/exchange/reducer';

const appPersistConfig = {
	key: 'app',
	storage,
	whitelist: ['lang'],
};

const optionsPersistConfig = {
	key: 'optionsTrade',
	storage,
	whitelist: ['showModal', 'showCancelOrderConfirm'],
};

const createRootReducer = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		loadingBar: loadingBarReducer,
		app: persistReducer(appPersistConfig, app),
		assets,
		settings,
		sockets,
		auth,
		assetPairs,
		orders,
		wallets,
		tradingSettings,
		marginWallets,
		spotTrade,
		twoFa,
		perpetualTrade,
		optionsTrade: persistReducer(optionsPersistConfig, optionsTrade),
		analytics,
		exchange,
	});

export default createRootReducer;
