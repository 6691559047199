import { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import L from 'i18n-react';
import { getSearchParam } from 'services/utils/url';
import PositionsTab from './PositionsTab';
import ActiveOrder from './ActiveOrderTab';
import OrderHistory from './OrderHistory';
import TradeHistory from './TradeHistory';

const TradeTables: FC = () => {
	const location = useLocation();
	const history = useHistory();
	const { pathname } = location;

	const currentTableTab = getSearchParam('history');

	const tableTabs = {
		positions: <PositionsTab />,
		activeOrder: <ActiveOrder />,
		orderHistory: <OrderHistory />,
		tradeHistory: <TradeHistory />,
	};

	const toggleHistoryTab: (_history: string) => void = (_history) => {
		history.replace({
			pathname,
			search: `?history=${_history}`,
		});
	};

	const handleTabChange = (e: any) => {
		const { name } = e.target;
		name && toggleHistoryTab(name);
	};

	return (
		<div className="options-table">
			<div className="table-nav flex flex--gap4" onClick={handleTabChange}>
				<button
					type="button"
					name="positions"
					className={`button button--width-auto button--size3${
						!currentTableTab || currentTableTab === 'positions' ? '' : ' button--type11'
					}`}
				>
					{String(L.translate('Trade.Options.historyTabs.positions'))}
				</button>
				<button
					type="button"
					name="activeOrder"
					className={`button button--width-auto button--size3${
						currentTableTab === 'activeOrder' ? '' : ' button--type11'
					}`}
				>
					{String(L.translate('Trade.Options.historyTabs.active_order'))}
				</button>
				<button
					type="button"
					name="orderHistory"
					className={`button button--width-auto button--size3${
						currentTableTab === 'orderHistory' ? '' : ' button--type11'
					}`}
				>
					{String(L.translate('Trade.Options.historyTabs.order_history'))}
				</button>
				<button
					type="button"
					name="tradeHistory"
					className={`button button--width-auto button--size3${
						currentTableTab === 'tradeHistory' ? '' : ' button--type11'
					}`}
				>
					{String(L.translate('Trade.Options.historyTabs.trade_history'))}
				</button>
			</div>
			{tableTabs[(currentTableTab as keyof typeof tableTabs) || 'positions']}
		</div>
	);
};

export default TradeTables;
