import { FC, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { RegistrForm } from 'components/Forms/RegistrForm';
import queryString from 'query-string';
import { Auth } from 'layouts/Auth';
import { IRegistrValues } from 'components/Forms/RegistrForm/types';
import GoogleCaptcha from 'components/GoogleCaptcha';
import { registrationRequest } from 'redux/reducers/auth/reducer';
import { getRegistrIsLoad } from 'redux/reducers/auth/selectors';
import Loader from 'ui/Loader';

export const Registration: FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const { search } = location;

	const [open, setOpen] = useState(false);
	const [registData, setRegistrData] = useState<null | IRegistrValues>(null);

	const registrIsLoad = useSelector(getRegistrIsLoad);

	const referral = queryString.parse(search).ref || null;

	const handleRegistrSubmit = (values: IRegistrValues): void => {
		setRegistrData(values);
		setOpen(true);
	};

	const hangleCaptchaClose = (): void => {
		setRegistrData(null);
		setOpen(false);
	};

	const handleCaptcha = (value: string): void => {
		if (registData && value) {
			const data = { ...registData, captcha: value };
			if (referral) {
				data.referral = String(referral);
			}
			dispatch(
				registrationRequest({
					data,
					history,
				}),
			);
		}

		setRegistrData(null);
		setOpen(false);
	};

	return (
		<Auth title={String(L.translate('RegisterPage.helmet_title'))}>
			<div className="authorization-section">
				<div className="authorization-section__container">
					<div className="authorization">
						<RegistrForm registrSubmit={handleRegistrSubmit} />
						{registrIsLoad && (
							<div className="auth-loader-wrapper">
								<Loader />
							</div>
						)}
					</div>
				</div>
			</div>
			{open && (
				<div className="popup-window popup-window--captcha">
					<div className="popup-window__inside">
						<GoogleCaptcha setCaptcha={handleCaptcha} captchaClose={hangleCaptchaClose} />
						<div className="popup-window__close" onClick={hangleCaptchaClose} />
					</div>
				</div>
			)}
		</Auth>
	);
};
