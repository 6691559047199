import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMarginWalletBorrowHistoryRequest } from 'redux/reducers/marginWallets/reducer';
import {
	getMarginWalletsIsLoader,
	getIsolatedBorrowingHistory,
} from 'redux/reducers/marginWallets/selectors';
import usePagination from 'hooks/usePagination';
import BorrowPage from 'components/MarginWallets/BorrowPage';

const BorrowIsolated: FC = () => {
	const dispatch = useDispatch();
	const { perPage, currentPage } = usePagination();
	const loading = useSelector(getMarginWalletsIsLoader);
	const isolatedWallets = useSelector(getIsolatedBorrowingHistory);

	useEffect(() => {
		dispatch(
			getMarginWalletBorrowHistoryRequest({
				isolated: true,
				params: {
					margin_type: 'isolated',
					per_page: perPage,
					current_page: currentPage,
				},
			}),
		);
	}, [currentPage, dispatch, perPage]);

	return <BorrowPage isolated borrow={isolatedWallets} loading={loading} />;
};

export default BorrowIsolated;
