import { FC, useState, KeyboardEvent } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import L from 'i18n-react';
import yup from 'services/utils/capsLockValidate';
import emailRegex from 'services/utils/emailRegex';
import Input from 'ui/Formik/Input';
import TextError from 'ui/Formik/TextError';
import { NavLink, useLocation } from 'react-router-dom';
import { IRegistrValues, IRegistrForm } from './types';

export const RegistrForm: FC<IRegistrForm> = ({ registrSubmit }) => {
	const [isShowPass, setIsShowPass] = useState(false);
	const [isCapsLockOn, setIsCapsLockOn] = useState(false);
	const location = useLocation();

	const initialValues = {
		email: '',
		password: '',
		captcha: '00000000',
		acceptTerms: false,
		cookiePolicy: false,
		acceptPromocod: new URLSearchParams(location.search).get('ref') || '',
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.capsLockValidate(isCapsLockOn, String(L.translate('Forms.Validations.caps_lock_on')))
			.required(String(L.translate('Forms.Validations.email_required')))
			.email(String(L.translate('Forms.Validations.email_invalid')))
			.matches(emailRegex, String(L.translate('Forms.Validations.email_invalid')))
			.max(60, String(L.translate('Forms.Validations.email_max_length', { maxLength: 60 }))),
		password: yup
			.string()
			.required(String(L.translate('Forms.Validations.password_required')))
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				String(L.translate('Forms.Validations.password_pattern_1')),
			)
			.max(25, String(L.translate('Forms.Validations.password_max_length', { maxLength: 26 }))),
		acceptTerms: yup
			.bool()
			.oneOf([true], String(L.translate('Forms.Validations.agree_terms_conditions'))),
		cookiePolicy: yup
			.bool()
			.oneOf([true], String(L.translate('Forms.Validations.agree_cookie_policy'))),
	});

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handleCapsLockOn = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.getModifierState('CapsLock')) {
			setIsCapsLockOn(true);
		} else {
			setIsCapsLockOn(false);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IRegistrValues, { resetForm, setSubmitting }) => {
				registrSubmit(values);
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({
				errors,
				touched,
				isSubmitting,
				isValid,
				dirty,
				setFieldTouched,
				setFieldValue,
				values,
			}) => {
				return (
					<Form>
						<p className="authorization__title">{L.translate('Forms.Register.title')}</p>
						<div className="authorization__details">
							<p>{L.translate('Forms.Register.subtitle')}</p>
						</div>

						<div className="authorization__form">
							<Field
								type="email"
								placeholder={L.translate('Forms.Register.email_placeholder')}
								name="email"
								title={String(L.translate('Forms.Register.email'))}
								required
								onKeyUp={handleCapsLockOn}
								component={Input}
							/>
							<Field
								type={isShowPass ? 'text' : 'password'}
								placeholder={L.translate('Forms.Register.password_placeholder')}
								name="password"
								required
								title={String(L.translate('Forms.Register.password'))}
								component={Input}
								ariaLabel="show-password"
								showPass={isShowPass}
								setIsShowPass={handlePassDisplay}
							/>
							<Field
								type="text"
								placeholder={L.translate('Forms.Register.referral_code_placeholder')}
								name="acceptPromocod"
								aria-label="accept-promo"
								title={String(L.translate('Forms.Register.promo'))}
								component={Input}
								id="accept-promo"
							/>

							{/* <div className="authorization__options">
								<div className="checkbox checkbox--no-mb">
									<label htmlFor="accept-promo" className="checkbox__label">
										<Field
											type="checkbox"
											name="acceptPromocod"
											aria-label="accept-promo"
											className="hidden"
											id="accept-promo"
										/>
										<span className=" checkbox__item">
											<span className="checkbox__item-icon">
												<svg
													width="17"
													height="17"
													viewBox="0 0 17 17"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M14.5426 4.58269C14.7501 4.79016 14.7501 5.12653 14.5426 5.33399L7.45931 12.4173C7.25184 12.6248 6.91547 12.6248 6.70801 12.4173L3.16634 8.87566C2.95888 8.66819 2.95888 8.33183 3.16634 8.12436C3.37381 7.91689 3.71018 7.91689 3.91764 8.12436L7.08366 11.2904L13.7913 4.58269C13.9988 4.37523 14.3352 4.37523 14.5426 4.58269Z"
														fill="#FF57D4"
													/>
												</svg>
											</span>
										</span>

										<span className="checkbox__text">I have a promocode</span>
									</label>
								</div>
							</div> */}
							<div className="authorization__options">
								<div className="checkbox checkbox--no-mb">
									<label htmlFor="terms-conditions" className="checkbox__label">
										<Field
											type="checkbox"
											name="acceptTerms"
											aria-label="accept-terms"
											onClick={() => {
												setFieldValue('acceptTerms', !values.acceptTerms);
												setFieldTouched('acceptTerms', true);
											}}
											className="hidden"
											id="terms-conditions"
										/>
										<span className=" checkbox__item">
											<span className="checkbox__item-icon">
												<svg
													width="17"
													height="17"
													viewBox="0 0 17 17"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M14.5426 4.58269C14.7501 4.79016 14.7501 5.12653 14.5426 5.33399L7.45931 12.4173C7.25184 12.6248 6.91547 12.6248 6.70801 12.4173L3.16634 8.87566C2.95888 8.66819 2.95888 8.33183 3.16634 8.12436C3.37381 7.91689 3.71018 7.91689 3.91764 8.12436L7.08366 11.2904L13.7913 4.58269C13.9988 4.37523 14.3352 4.37523 14.5426 4.58269Z"
														fill="#FF57D4"
													/>
												</svg>
											</span>
										</span>
										<div
											className={`checkbox ${
												errors?.acceptTerms && touched?.acceptTerms ? ' input--error' : ''
											}`}
										/>

										<span className="checkbox__text">
											{L.translate('Forms.Register.agree_terms_conditions_part_1')}{' '}
											<NavLink to="/">
												{L.translate('Forms.Register.agree_terms_conditions_part_2')}
											</NavLink>{' '}
											{L.translate('Forms.Register.agree_terms_conditions_part_3')}{' '}
											<NavLink to="/">
												{L.translate('Forms.Register.agree_terms_conditions_part_4')}
											</NavLink>
										</span>
									</label>
									<ErrorMessage name="acceptTerms" component={TextError} />
								</div>
							</div>
							<div className="authorization__options">
								<div className="checkbox checkbox--no-mb">
									<label htmlFor="cookie-policy" className="checkbox__label">
										<Field
											type="checkbox"
											name="cookiePolicy"
											aria-label="cookie-policy"
											onClick={() => {
												setFieldValue('cookiePolicy', !values.cookiePolicy);
												setFieldTouched('cookiePolicy', true);
											}}
											className="hidden"
											id="cookie-policy"
										/>
										<span className=" checkbox__item">
											<span className="checkbox__item-icon">
												<svg
													width="17"
													height="17"
													viewBox="0 0 17 17"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M14.5426 4.58269C14.7501 4.79016 14.7501 5.12653 14.5426 5.33399L7.45931 12.4173C7.25184 12.6248 6.91547 12.6248 6.70801 12.4173L3.16634 8.87566C2.95888 8.66819 2.95888 8.33183 3.16634 8.12436C3.37381 7.91689 3.71018 7.91689 3.91764 8.12436L7.08366 11.2904L13.7913 4.58269C13.9988 4.37523 14.3352 4.37523 14.5426 4.58269Z"
														fill="#FF57D4"
													/>
												</svg>
											</span>
										</span>
										<div
											className={`checkbox ${
												errors?.cookiePolicy && touched?.cookiePolicy ? ' input--error' : ''
											}`}
										/>

										<span className="checkbox__text">
											{L.translate('Forms.Register.agree_cookie_policy_part_1')}{' '}
											<NavLink to="/">
												{L.translate('Forms.Register.agree_cookie_policy_part_2')}
											</NavLink>
										</span>
									</label>
									<ErrorMessage name="cookiePolicy" component={TextError} />
								</div>
							</div>
						</div>

						<div className="form-submit">
							<button
								disabled={!(isValid && dirty) || isSubmitting}
								type="submit"
								className="button button--wide button--size2"
							>
								{L.translate('Forms.Register.submit_btn')}
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
