import { FC, useState } from 'react';
import L from 'i18n-react';
import { useDispatch } from 'react-redux';
import ConfirmDeletePopup from 'ui/ConfirmDeletePopup';
import { getOpenOrdersRequest, removeOpenOrdersRequest } from 'redux/reducers/spotTrade/reducer';
import usePagination from 'hooks/usePagination';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { IOpenOrdersTableRow } from './types';

const marketType = 'isolated';

const OpenOrdersTableRow: FC<IOpenOrdersTableRow> = ({ item }) => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState<boolean>(false);
	const { perPage, currentPage } = usePagination();

	const openModal = () => {
		setOpen(true);
	};

	const closeModal = () => {
		setOpen(false);
	};

	const handleRemoveUserOpenOrder = () => {
		dispatch(
			removeOpenOrdersRequest({
				order_id: item.id,
				callback: () => {
					dispatch(
						getOpenOrdersRequest({
							params: {
								order_type: marketType,
								status: 'opened',
								per_page: perPage,
								current_page: currentPage,
							},
						}),
					);
				},
			}),
		);
		setOpen(false);
	};

	const filled = !!item.quantity && item.quantity_left / item.quantity;

	return (
		<>
			<div className="tr">
				<div className="td">
					<p className="grey">{item.created_at && customDateFormat(item.created_at)}</p>
				</div>
				<div className="td">
					<p>{item?.asset_pair?.code && item?.asset_pair?.code?.replace('_', '/').toUpperCase()}</p>
				</div>
				<div className="td">
					<p>{item.type}</p>
				</div>
				<div className={`td ${item.direction === 'buy' ? 'green' : 'red'} capitalize`}>
					<p>{item.direction}</p>
				</div>
				<div className="td">
					<p>{item.price}</p>
				</div>
				<div className="td">
					<p>{Number(filled)?.toFixed(2)}%</p>
				</div>
				<div className="td">
					<p>{Number(item.total)?.toFixed(2)}</p>
				</div>
				<div className="td">
					<p>{item.trigger_stop_limit_price}</p>
				</div>
				<div className="td">
					<div className="flex flex-e flex--gap24">
						<button
							type="button"
							onClick={openModal}
							className="button button--type-text green fw-400"
						>
							{String(L.translate('Wallets.actions.cancel'))}
						</button>
					</div>
				</div>
			</div>
			<ConfirmDeletePopup
				title={String(
					L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.confirm_delete_order_title'),
				)}
				bodyMessage={String(
					L.translate(
						'Trade.Spot.SpotHistoryTables.OpenOrdersTable.confirm_delete_order_body_message',
					),
				)}
				openModal={open}
				closeModal={closeModal}
				handleDelete={handleRemoveUserOpenOrder}
			/>
		</>
	);
};

export default OpenOrdersTableRow;
