/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setTempOrderPrice } from 'redux/reducers/orders/reducer';
import { IOrderBookTableItemProps } from './types';

const MAXWIDTH = 85;

const OrderBookTableItem: FC<IOrderBookTableItemProps> = ({ data, type, maxValue }) => {
	const { price, quantity_left, total } = data;
	const dispatch = useDispatch();

	const width = useMemo(
		() => MAXWIDTH * (Number(data?.total || 1) / Number(maxValue || 1)),
		[data?.total, maxValue],
	);

	const getClassByType = (valueType: string): string => {
		switch (valueType) {
			case 'ask':
				return 'red';
			case 'bid':
				return 'green';

			default:
				return '';
		}
	};

	const handleSetTempOrderPrice = () => {
		dispatch(setTempOrderPrice(Number(price)));
	};

	return (
		<div className="orderbook-body-item__line" onClick={handleSetTempOrderPrice}>
			<span className={getClassByType(type)}>{Number(price) || price}</span>
			{/* {Number(quantity_left).toFixed(6).length > 10 ? (
				<Hover onHover={<span className="table-value">{Number(quantity_left).toFixed(6)}</span>}>
					{`${Number(quantity_left).toFixed(6).slice(0, 10)}...`}
				</Hover>
			) : (
				<span className="table-value">{Number(quantity_left).toFixed(6)}</span>
			)} */}
			<span className="table-value">{Number(quantity_left).toFixed(6)}</span>
			{/* {total.length > 10 ? (
				<Hover onHover={<span className="text-align-right table-value">{total}</span>}>
					{`${total.slice(0, 10)}...`}
				</Hover>
			) : (
				<span className="text-align-right table-value">{total}</span>
			)} */}
			<span className="text-align-right table-value">{Number(total).toFixed(6)}</span>
			<span
				style={{ width: `${width}%` }}
				className={`orderbook-body-item__line-progres ${
					getClassByType(type) === 'green' ? 'orderbook-body-item__line-progres--green' : ''
				}`}
			/>
		</div>
	);
};

export default OrderBookTableItem;
