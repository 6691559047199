import React from 'react';
import Trading from 'layouts/Trading';
import Privacy from 'components/StaticPages/privacy';

const PrivacyPage = () => {
	return (
		<Trading title="Terms">
			<Privacy />
		</Trading>
	);
};

export default PrivacyPage;
