import { FC, SyntheticEvent, useState, useEffect } from 'react';
import L from 'i18n-react';
import useSelect from 'hooks/useSelect';
import { useFormikContext, FormikContextType } from 'formik';
import Loader from 'ui/Loader';
import PersentSlider from 'components/Trade/TradesBox/PersentSlider';
import { useSelector } from 'react-redux';
import { calculateTransform } from 'services/utils/calculateTransfer';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getMarginIndexPrices,
	getMarginCrossWalletsList,
} from 'redux/reducers/marginWallets/selectors';
import { getWalletsList } from 'redux/reducers/wallets/selectors';
import { getAssetsList } from 'redux/reducers/assets/selectors';
import { IAssetsItem, IAssetsData } from 'redux/reducers/assets/types';
import ConvertFrom from 'components/MarginWallets/modals/Transfer/TransferContent/ConvertFrom';
import ConvertTo from 'components/MarginWallets/modals/Transfer/TransferContent/ConvertTo';
import ConvertSwitch from 'components/MarginWallets/modals/Transfer/TransferContent/ConvertSwitch';
import AssetDropItem from 'components/MarginWallets/modals/shared/AssetDropItem';
import { ITransferModalFormCross, Values } from './types';

const TransferContentCross: FC<ITransferModalFormCross> = ({ onClose }) => {
	const assetsList = useSelector(getAssetsList);
	const [filteredAssetsList, setFilteredAssetsList] = useState<IAssetsData>(assetsList);

	const { open, setOpen, toggleOpen, currentRef, dropRef } = useSelect();
	const { values, setValues, isValid, errors, isSubmitting }: FormikContextType<Values> =
		useFormikContext();

	const { reverse } = values;

	const selectedAsset = assetsList?.find((asset: IAssetsItem) => asset.id === values.asset_id);

	const code = assetsList?.find((el) => el.id === values.asset_id)?.code;

	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const marginIndexPrice: any = useSelector(getMarginIndexPrices);

	const crossWallet = useSelector(getMarginCrossWalletsList);
	const marginWallets = authIsAuthenticated ? crossWallet : null;
	const spotWallet = useSelector(getWalletsList);
	const spot = authIsAuthenticated ? spotWallet : null;

	const listCoins = !reverse ? spot : marginWallets?.cross;
	const listMarginCoins: Array<any> | null | undefined = listCoins;

	const balance = listMarginCoins?.find((el) => el.asset.code === code)
		? listMarginCoins?.find((el) => el.asset.code === code)?.balance
		: 0;

	const available = !reverse
		? balance
		: (code && calculateTransform(balance, marginWallets, marginIndexPrice, code)) || 0;

	useEffect(() => {
		setFilteredAssetsList(assetsList);
	}, [assetsList]);

	const handleClose = () => {
		onClose && onClose();
	};

	const handleSwitch = () => {
		setValues({
			...values,
			amount: 0,
			percent: 0,
			reverse: !values.reverse,
		});
	};

	const handleAmountChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: amount } = target;
		let newValues = {
			...values,
			amount,
		};
		if (Number(available)) {
			const percent = (Number(amount) * 100) / Number(available);
			newValues = {
				...newValues,
				percent,
			};
		}
		setValues(newValues);
	};

	// TODO: Удалить, оптимизировать слайдер
	const countOrder = (value: number) => {};

	const percentButtonCountValue = (percentValue: number): number => {
		if (!Number(percentValue)) {
			return 0;
		}
		return available ? (available * percentValue) / 100 : 0;
	};

	const handlePercentChange = (percent: number | number[]) => {
		if (!Array.isArray(percent)) {
			const value = percentButtonCountValue(Number(percent));
			setValues({
				...values,
				amount: value,
				percent,
			});
		}
	};

	const handleAssetSelect: (item: IAssetsItem) => void = (item) => {
		const { id } = item;
		setValues({
			...values,
			asset_id: id,
		});
		setOpen(false);
	};

	const handleAssetSearchInput = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: targetValue } = target;
		const filtered = targetValue
			? filteredAssetsList?.filter((item) => item.code.match(new RegExp(targetValue, 'gi')))
			: assetsList;
		filtered && setFilteredAssetsList(filtered);
	};

	const handleMaxAmount = () => {
		setValues({
			...values,
			amount: available,
			percent: 100,
		});
	};

	const submitDisabled = !isValid || (!values.amount && values.amount !== 0) || isSubmitting;

	const currentAssetCode = assetsList?.find((el) => el.id === values.asset_id)?.code;

	return (
		<div className="popup">
			<button type="button" onClick={handleClose} className="popup__close">
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
						fill="#1C1C29"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
						fill="#1C1C29"
					/>
				</svg>
			</button>
			<div className="popup-header">
				<p className="popup-header__title">
					{String(L.translate('Wallets.MarginAccount.modals.transfer'))}
				</p>
			</div>
			<div className="popup-body">
				{isSubmitting ? (
					<div className="popup-loader">
						<Loader />
					</div>
				) : (
					<>
						<div className="flex-column">
							{reverse ? <ConvertTo /> : <ConvertFrom />}
							<ConvertSwitch handleSwitch={handleSwitch} />
							{reverse ? <ConvertFrom /> : <ConvertTo />}
							<div className="convert-form-item">
								<div className="convert-form-item__header">
									<p>{String(L.translate('Wallets.MarginAccount.modals.amount'))}</p>
									<span>
										{String(L.translate('Wallets.MarginAccount.modals.available'))}: {available}{' '}
										{currentAssetCode?.toUpperCase()}
									</span>
								</div>
								<div className="convert-form-item__field">
									<div className="input">
										<div className="input-wrapper">
											<input
												value={values.amount}
												onChange={handleAmountChange}
												className="input-item input-item--pr50"
												type="text"
												placeholder="Please enter 20 - 25 000 000"
											/>
											<div className="input-icon input-icon--auto input-icon--right">
												<button type="button" onClick={handleMaxAmount} className="input-button">
													MAX
												</button>
											</div>
										</div>
										<span className="input-notify">{errors?.amount}</span>
									</div>
									<div className="select-block">
										<div className={`select select--currensy ${open ? ' active' : ''}`}>
											<button
												type="button"
												onClick={toggleOpen}
												className="select__current"
												ref={currentRef}
											>
												<span className="select-current-currensy">
													<span className="select-current-currensy__icon">
														<img src={selectedAsset?.img_path} alt="coin" />
													</span>
													<span className="select-current-currensy__name">
														{selectedAsset?.code?.toUpperCase()}
													</span>
												</span>
												<span className="select__current-arrow">
													<svg
														width="10"
														height="6"
														viewBox="0 0 10 6"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path d="M10 0.833496H1H0L5 5.8335L10 0.833496Z" fill="#9D9DBC" />
													</svg>
												</span>
											</button>
											<div className="select__drop" ref={dropRef}>
												<div className="input">
													<div className="input-wrapper">
														<input
															onInput={handleAssetSearchInput}
															className="input-item"
															type="text"
															placeholder="Enter token name"
														/>
														<div className="input-icon input-icon--right">
															<svg
																width="20"
																height="21"
																viewBox="0 0 20 21"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M1.04199 9.24984C1.04199 4.99266 4.49315 1.5415 8.75033 1.5415C13.0075 1.5415 16.4587 4.99266 16.4587 9.24984C16.4587 13.507 13.0075 16.9582 8.75033 16.9582C4.49315 16.9582 1.04199 13.507 1.04199 9.24984ZM8.75033 2.7915C5.1835 2.7915 2.29199 5.68302 2.29199 9.24984C2.29199 12.8167 5.1835 15.7082 8.75033 15.7082C12.3171 15.7082 15.2087 12.8167 15.2087 9.24984C15.2087 5.68302 12.3171 2.7915 8.75033 2.7915Z"
																	fill="#9D9DBC"
																/>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M5.95065 6.03433C6.66631 5.31872 7.65675 4.875 8.74962 4.875C9.84249 4.875 10.8329 5.31872 11.5486 6.03435C11.7926 6.27843 11.7926 6.67416 11.5486 6.91823C11.3045 7.16231 10.9088 7.16231 10.6647 6.91823C10.1739 6.42745 9.49767 6.125 8.74962 6.125C8.00158 6.125 7.32534 6.42744 6.8345 6.91825C6.59042 7.16232 6.19469 7.1623 5.95062 6.91822C5.70655 6.67413 5.70657 6.2784 5.95065 6.03433Z"
																	fill="#9D9DBC"
																/>
																<path
																	fillRule="evenodd"
																	clipRule="evenodd"
																	d="M13.4008 13.9003C13.6449 13.6563 14.0406 13.6563 14.2847 13.9003L17.8203 17.4359C18.0643 17.68 18.0643 18.0757 17.8203 18.3198C17.5762 18.5638 17.1805 18.5638 16.9364 18.3198L13.4008 14.7842C13.1568 14.5401 13.1568 14.1444 13.4008 13.9003Z"
																	fill="#9D9DBC"
																/>
															</svg>
														</div>
													</div>
												</div>
												<div className="select__drop-scroll">
													<div className="select__drop-item">
														<ul>
															{filteredAssetsList?.map((item) => (
																<AssetDropItem
																	key={item.id}
																	item={item}
																	handleAssetSelect={handleAssetSelect}
																/>
															))}
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<PersentSlider
								className="persent-slider"
								value={values.percent}
								onChange={handlePercentChange}
								countOrder={countOrder}
								percentButtonCountValue={percentButtonCountValue}
							/>
						</div>
					</>
				)}
			</div>
			<div className="popup-footer">
				<button
					type="submit"
					className="button button--size2 button--full-width"
					disabled={submitDisabled}
				>
					{String(L.translate('Wallets.MarginAccount.modals.confirm'))}
				</button>
			</div>
		</div>
	);
};

export default TransferContentCross;
