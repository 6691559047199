import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import { MarketTypes } from 'components/MarginWallets/MarketTypeToggle';
import RepaymentCross from './cross';
import RepaymentIsolated from './isolated';

const MARKET_TYPE = 'marketType';

const MarginAccount: FC = () => {
	// const location = useLocation();

	// const marketType = new URLSearchParams(location.search).get(MARKET_TYPE);

	// if (!marketType || marketType === MarketTypes.Cross) {
	// 	return <RepaymentCross />;
	// }

	// if (marketType === MarketTypes.Isolated) {
	// 	return <RepaymentIsolated />;
	// }

	// return <Redirect to="/wallet/margin-account" />;
	return <RepaymentIsolated />;
};

export default MarginAccount;
