import React, { FC } from 'react';
import PhoneInput from 'react-phone-input-2';

interface IPhoneInputField {
	value: string;
	children: React.ReactNode;
	onChange: (inputValue: string) => void;
}

const PhoneField: FC<IPhoneInputField> = ({ children, onChange, value }) => {
	const onPhonePrefixChange = (inputValue: string) => {
		onChange(inputValue);
	};

	return (
		<div className="react-tel-input-wrapper">
			{children}
			<PhoneInput country="us" value={value} onChange={onPhonePrefixChange} />
		</div>
	);
};

export default PhoneField;
