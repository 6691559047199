import L from 'i18n-react';
import { FC } from 'react';
import Popup from 'reactjs-popup';
import { useSelector } from 'react-redux';
import { getWalletNetworks } from 'redux/reducers/wallets/selectors';
import { IWalletsNetwork } from 'redux/reducers/wallets/types';

interface IChooseNetworkPopup {
	opened: boolean;
	closePopup: () => void;
	onNetworkChange: (network: IWalletsNetwork) => void;
}
export const ChooseNetworkPopup: FC<IChooseNetworkPopup> = ({
	opened,
	closePopup,
	onNetworkChange,
}) => {
	const walletNetworks = useSelector(getWalletNetworks);
	const handleSelectNetwork = (network: IWalletsNetwork) => {
		onNetworkChange(network);
		closePopup();
	};
	return (
		<Popup open={opened} onClose={closePopup} closeOnDocumentClick closeOnEscape>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup popup--width465">
						<button type="button" onClick={closePopup} className="popup__close">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
									fill="#1C1C29"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
									fill="#1C1C29"
								/>
							</svg>
						</button>
						<div className="popup-header">
							<p className="popup-header__title">
								{String(L.translate('DepositCrypto.deposit_crypto_select_network'))}
							</p>
						</div>
						<div className="popup-body">
							<div className="popup-text">
								<p>
									{String(L.translate('DepositCrypto.deposit_crypto_select_network_description'))}
								</p>
							</div>
							<div className="select-network">
								{!!walletNetworks?.length &&
									walletNetworks.map((network) => (
										<button
											type="button"
											key={network.network_name}
											className="select-network__item"
											onClick={() => handleSelectNetwork(network)}
										>
											<p>{network.network_name.toUpperCase()}</p>
											<span>{network.network_full}</span>
										</button>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
