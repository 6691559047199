/* eslint-disable no-debugger */
import { FC, useState, useEffect, useCallback, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import L from 'i18n-react';
import Loader from 'ui/Loader';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getActiveOrders, getActiveOrdersLoader } from 'redux/reducers/options/selectors';
import { getActiveOrdersRequest } from 'redux/reducers/options/reducer';
import LoginTradeTablePlug from 'components/Trade/TradeTablePlug';
import ActiveOrderTabItem from './ActiveOrderTabItem';

const ActiveOrderTab = () => {
	const dispatch = useDispatch();
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const loader = useSelector(getActiveOrdersLoader);
	const activeOrders = useSelector(getActiveOrders);

	useEffect(() => {
		dispatch(getActiveOrdersRequest({}));
	}, [dispatch]);

	const renderContent: () => ReactNode = () => {
		if (!authIsAuthenticated) {
			return <LoginTradeTablePlug />;
		}
		if (loader) {
			return (
				<div className="popup-loader">
					<Loader />
				</div>
			);
		}
		if (activeOrders && activeOrders?.length > 0) {
			return activeOrders?.map((item) => {
				return <ActiveOrderTabItem key={item.id} item={item} />;
			});
		}
		return <TableEmptyPlug />;
	};

	return (
		<div className="table table--wallet table--options table--options-active-order">
			<div className="table-header">
				<div className="tr">
					<div className="td td--left">
						<p>{String(L.translate('Trade.Options.columns.contract'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.direction'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.order_price'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.qty_btc'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.filled_qty_btc'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.unfilled_qty_btc'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.initial_margin'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.order_id'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.order_type'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.order_time'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.time_in_force'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.action'))}</p>
					</div>
				</div>
			</div>
			<div className="table-body">{renderContent()}</div>
		</div>
	);
};

export default ActiveOrderTab;
