import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IGetRequestPayload } from './types';
// import { IPerpetualApi } from './types';

export const perpetualTrade = {
	getOrderbook: ({ id }: { id: number }) =>
		http.get(endpoint.perpetualTrade.ORDER_BOOK(Number(id))).then((response) => response.data),
	getRecentTrades: ({ limit, base_asset }: { limit: number; base_asset: string }) =>
		http
			.get(endpoint.perpetualTrade.RECENT_TRADES(limit, base_asset))
			.then((response) => response.data),
	getOrderHistory: (params: IGetRequestPayload) =>
		http.get(endpoint.perpetualTrade.ORDER_HISTORY, { params }).then((response) => response.data),
	getBaseAssetStatistics: () =>
		http
			.get(endpoint.perpetualTrade.ACTIVE_BASE_ASSET_STATISTICS)
			.then((response) => response.data),
	getBalance: () => http.get(endpoint.perpetualTrade.BALANCE).then((response) => response.data),
	createOrder: (payload: any) =>
		http.post<any>(endpoint.perpetualTrade.CREATE_ORDER, payload).then((response) => response.data),
	getOpenPositions: (params: { is_show_all_asset: 0 | 1; base_asset: string }) =>
		http
			.get(endpoint.perpetualTrade.GET_OPEN_POSITIONS, { params })
			.then((response) => response.data),
	getMyOrders: (params: IGetRequestPayload) =>
		http.get(endpoint.perpetualTrade.GET_MY_ORDERS, { params }).then((response) => response.data),
	getTradeHistory: (params: IGetRequestPayload) =>
		http
			.get(endpoint.perpetualTrade.GET_TRADE_HISTORY, { params })
			.then((response) => response.data),
	getTransactions: (params: IGetRequestPayload) =>
		http
			.get(endpoint.perpetualTrade.GET_TRANSACTIONS, { params })
			.then((response) => response.data),
	getMyStopOrders: (params: IGetRequestPayload) =>
		http
			.get(endpoint.perpetualTrade.GET_MY_STOP_ORDERS, { params })
			.then((response) => response.data),
	getStopOrderHistory: (params: IGetRequestPayload) =>
		http
			.get(endpoint.perpetualTrade.GET_STOP_ORDER_HISTORY, { params })
			.then((response) => response.data),
	getClosedOrderHistory: (params: IGetRequestPayload) =>
		http
			.get(endpoint.perpetualTrade.GET_CLOSED_ORDER_HISTORY, { params })
			.then((response) => response.data),
	closePositionByMarket: (payload: any) =>
		http
			.post<any>(endpoint.perpetualTrade.CLOSE_POSITION_BY_MARKET, payload)
			.then((response) => response.data),
	closePositionByLimit: (payload: any) =>
		http
			.post<any>(endpoint.perpetualTrade.CLOSE_POSITION_BY_LIMIT, payload)
			.then((response) => response.data),
	closePositionByTpSl: (payload: any) =>
		http
			.post<any>(endpoint.perpetualTrade.CLOSE_POSITION_BY_TP_SL, payload)
			.then((response) => response.data),
	closeAllPositionsByMarket: (payload: any) =>
		http
			.post<any>(endpoint.perpetualTrade.CLOSE_ALL_POSITIONS_BY_MARKET, payload)
			.then((response) => response.data),
	cancelOrder: (payload: any) =>
		http.put<any>(endpoint.perpetualTrade.CANCEL_ORDER, payload).then((response) => response.data),
	getUnrealisedPnl: (params: any) =>
		http.get(endpoint.perpetualTrade.UNREALISED_PNL, { params }).then((response) => response.data),
	addMargin: (payload: any) =>
		http.put<any>(endpoint.perpetualTrade.ADD_MARGIN, payload).then((response) => response.data),
	transferFromSpot: (params: any) =>
		http
			.get<any>(endpoint.perpetualTrade.TRANSFER_FROM_SPOT, { params })
			.then((response) => response.data),
	transferToSpot: (params: any) =>
		http
			.get<any>(endpoint.perpetualTrade.TRANSFER_TO_SPOT, { params })
			.then((response) => response.data),
	getUserStat: (params: any) => http.get(endpoint.perpetualTrade.GET_USER_STAT, { params }),
	getActiveBaseAsset: () => http.get(endpoint.perpetualTrade.ACTIVE_BASE_ASSET),
	getDerivativeAccount: () => http.get(endpoint.perpetualTrade.DERIVATIVE_ACCOUNT),
};
