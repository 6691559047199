import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import L from 'i18n-react';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import HeaderDropdown from 'ui/HeaderDropdown';
import { ROUTES } from 'routes/constants';
import { IHeaderDropdownList } from './types';

export const AuthHeader: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const handleSelect = (value: IHeaderDropdownList): void => {
		if (value.link === '/logout') {
			dispatch(logoutRequest({ history }));
		}

		history.push(value.link);
	};

	const accountList = [
		{
			order: 1,
			name: String(L.translate('Menu.Account.account_settings')),
			icon: 'icon-settings',
			link: ROUTES.accountSettings.index,
		},
		{
			order: 2,
			name: String(L.translate('Menu.Account.referal_system')),
			icon: 'icon-settings',
			link: '/referral',
		},
		{
			order: 3,
			name: String(L.translate('Header.logout')),
			icon: 'icon-Logout',
			link: '/logout',
		},
	];

	// const walletList = [
	// 	{
	// 		order: 1,
	// 		name: `${String(L.translate('Menu.Wallet.fiat_and_spot'))}`,
	// 		icon: 'icon-money-recive',
	// 		link: '/fiat-and-spot',
	// 	},
	// 	{
	// 		order: 3,
	// 		name: `${String(L.translate('Menu.Wallet.margin'))}`,
	// 		icon: 'icon-trade',
	// 		link: `/margin`,
	// 	},
	// 	{
	// 		order: 2,
	// 		name: 'P2P',
	// 		icon: 'icon-user',
	// 		link: '/p2p',
	// 	},
	// 	{
	// 		order: 4,
	// 		name: `${String(L.translate('Menu.Wallet.transaction_history'))}`,
	// 		icon: 'icon-Paper',
	// 		link: '/transaction-history',
	// 	},
	// ];
	return (
		<>
			<HeaderDropdown
				currentClass="header__select"
				placeholder={String(L.translate('Header.account_placeholder'))}
				placeholderIcon="icon-profile-circle"
				onChange={handleSelect}
				options={accountList}
			/>
		</>
	);
};
