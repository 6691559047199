/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IWalletsDepositHistoryResponsePayload } from 'redux/reducers/wallets/types';

// ==========================================:
export const payments: any = {
	paymentsPayPal: (amount: number, assetId: number) =>
		http.post<any>(endpoint.payments.PAYMENTS_PAYPAL(amount, assetId)).then((response) => {
			return response.data;
		}),
	getWalletsDepositHistory: (payload: any) =>
		http
			.get<IWalletsDepositHistoryResponsePayload>(endpoint.payments.PAYMENTS_HISTORY, payload)
			.then((response) => response.data),
};
