/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import L from 'i18n-react';
import { check2faEnabledRequest, loginRequest } from 'redux/reducers/auth/reducer';
import { Auth } from 'layouts/Auth';
import GoogleCaptcha from 'components/GoogleCaptcha';
import { getLoginIsLoad } from 'redux/reducers/auth/selectors';
import { ILoginSubmitValue } from 'components/Forms/LoginForm/types';
import Loader from 'ui/Loader';
import { LoginSection } from 'components/Forms/LoginSection';

/* eslint-disable no-debugger */
export const Login: FC = () => {
	const [open, setOpen] = useState(false);
	const [loginData, setLoginData] = useState<null | ILoginSubmitValue>(null);

	const history = useHistory();

	// const [openModal, setOpenModal] = useState<boolean>(false);
	// const closeModal = (): void => {
	// 	setOpenModal(false);
	// };

	const loginIsLoad = useSelector(getLoginIsLoad);

	const dispatch = useDispatch();

	const handleLoginSubmit = (values: ILoginSubmitValue): any => {
		setLoginData(values);
		setOpen(true);
	};

	const hangleCaptchaClose = (): void => {
		setLoginData(null);
		setOpen(false);
	};

	const handleCaptcha = (value: string): void => {
		if (loginData && value) {
			dispatch(
				loginRequest({
					data: {
						...loginData,
						captcha: value,
					},
					history,
				}),
			);
			// dispatch(getAssetsRequest());
		}

		setLoginData(null);
		setOpen(false);
	};

	return (
		<Auth title={String(L.translate('LoginPage.helmet_title'))}>
			<section className="authorization-section">
				<div className="authorization-section__container">
					<div className="authorization">
						<LoginSection
							loginSubmit={handleLoginSubmit}
							data={loginData}
							// setOpenModal={setOpenModal}
						/>
						{/* <LoginForm  /> */}
						{loginIsLoad && (
							<div className="auth-loader-wrapper">
								<Loader />
							</div>
						)}
					</div>
				</div>
			</section>
			{open && (
				<div className="popup-window popup-window--captcha">
					<div className="popup-window__inside">
						<GoogleCaptcha setCaptcha={handleCaptcha} captchaClose={hangleCaptchaClose} />
						<div className="popup-window__close" onClick={hangleCaptchaClose} />
					</div>
				</div>
			)}
		</Auth>
	);
};
