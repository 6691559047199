import { FC, useState } from 'react';
import L from 'i18n-react';
import { api } from 'services';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { notificationContainer } from 'services/utils/notificationContainer';
import ConfirmDeletePopup from 'ui/ConfirmDeletePopup';
import { ITableItem } from './types';

const TradeTableItem: FC<ITableItem> = ({ item }) => {
	const [openModal, setOpenModal] = useState(false);

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const closeModal = () => {
		setOpenModal(false);
	};

	const handleCloseOrder = async () => {
		try {
			await api.perpetualTrade.cancelOrder({
				order_future_id: item.id,
			});
			notificationContainer(
				String(L.translate('Trade.Perpetual.messages.order_was_successfully_cancelled')),
				'success',
			);
			setOpenModal(false);
		} catch (error) {
			notificationContainer(
				String(L.translate('Trade.Perpetual.messages.failed_to_cancel_order')),
				'error',
			);
			console.error(error);
		}
	};
	return (
		<div className="tr">
			<div className="td">
				{/* Contract */}
				<p>{item.base_asset?.toUpperCase()}/USDT</p>
			</div>
			<div className="td">
				{/* ID */}
				<p>{item.id}</p>
			</div>
			<div className="td">
				{/* Created At */}
				<p>{item.created_at && customDateFormat(item.created_at)}</p>
			</div>
			<div className="td">
				{/* Side */}
				<p>
					{item.transactions_type === 'buy'
						? String(L.translate('Trade.Perpetual.long'))
						: String(L.translate('Trade.Perpetual.short'))}
				</p>
			</div>
			<div className="td">
				{/* Price */}
				<p>{item.price_entry_for_order}</p>
			</div>
			<div className="td">
				{/* Size */}
				<p>
					{item.amount_asset_request
						? `${String(item.amount_asset_request)} ${
								item.base_asset ? String(item.base_asset)?.toUpperCase() : ''
						  }`
						: ''}
				</p>
			</div>
			<div className="td">
				{/* Unfilled */}
				<p>{item.amount_asset_request_left}</p>
			</div>
			<div className="td">
				{/* Fill Price */}
				<p>{item.price_avarage_completed}</p>
			</div>
			<div className="td">
				{/* Stop Order */}
				<p>{item.symbol_tp_sl || '-'}</p>
			</div>
			<div className="td td--right">
				<button className="button button--type-text fw-700" type="button" onClick={handleOpenModal}>
					Cancel
				</button>
			</div>
			<ConfirmDeletePopup
				title={String(L.translate('Trade.Perpetual.messages.confirm_close_order'))}
				bodyMessage={String(
					L.translate('Trade.Perpetual.messages.confirm_close_order_body_message'),
				)}
				openModal={openModal}
				closeModal={closeModal}
				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				handleDelete={handleCloseOrder}
			/>
		</div>
	);
};

export default TradeTableItem;
