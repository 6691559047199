import { FC } from 'react';
import { Page } from 'layouts/page';
// import { MobNav } from 'layouts-elements/MobNav';
import SidePanel from 'layouts-elements/WalletNav';
import { TPageProps } from './types';

// ==========================================:
export const WalletLayout: FC<TPageProps> = ({ children, title }) => {
	return (
		<Page title={title}>
			<main>
				<section className="wallet-section section-gradient">
					<div className="page-wrap">
						<SidePanel />
						{children}
					</div>
				</section>
			</main>
		</Page>
	);
};
