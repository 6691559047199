import React, { FC, useEffect, useRef, useState } from 'react';
import {
	TModalTwoFaOption,
	ETwoFaOption,
} from 'components/AccountSettings/AccountSettingsTwoFa/type';
import L from 'i18n-react';

const ModalTwoFaOption: FC<TModalTwoFaOption> = ({ onClose, onConfirm }) => {
	const modalRef = useRef<HTMLDivElement>(null);
	const [type, setType] = useState<ETwoFaOption | undefined>(undefined);

	const handleOutsideClick = (event: MouseEvent) => {
		if (!modalRef.current?.contains(event.target as Node)) {
			onClose();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleOutsideClick);
		return () => document.removeEventListener('mousedown', handleOutsideClick);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setTypeToApp = () => {
		setType(ETwoFaOption.APP);
	};

	const setTypeToPhone = () => {
		setType(ETwoFaOption.PHONE);
	};

	const confirmHandle = () => {
		if (type) {
			onConfirm(type);
		}
		onClose();
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div ref={modalRef} className="popup popup--width670">
					<button type="button" className="popup__close" onClick={onClose}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
								fill="#1C1C29"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
								fill="#1C1C29"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title">
							{String(L.translate('Account.TwoFactorAuth.two_factor_auth_enable_modal_title'))}
						</p>
					</div>
					<div className="popup-body">
						<div className="popup-text">
							<p>
								{String(L.translate('Account.TwoFactorAuth.two_factor_auth_enable_modal_subtitle'))}
							</p>
						</div>
						<div className="popup-body-item">
							<div className="radio">
								<label className="radio__label radio__label--type2">
									<input type="radio" className="hidden" name="one-group" onChange={setTypeToApp} />
									<span className="radio__text">
										{String(
											L.translate('Account.TwoFactorAuth.two_factor_auth_enable_modal_google'),
										)}
									</span>
									<span className="radio__item" />
								</label>
							</div>
						</div>
						<div className="popup-body-item">
							<div className="radio">
								<label className="radio__label radio__label--type2">
									<input
										type="radio"
										name="one-group"
										className="hidden"
										onChange={setTypeToPhone}
									/>
									<span className="radio__text">
										{String(L.translate('Account.TwoFactorAuth.two_factor_auth_enable_modal_sms'))}
									</span>
									<span className="radio__item" />
								</label>
							</div>
						</div>
					</div>
					<div className="popup-footer">
						<div className="popup-submit popup-submit--sb">
							<button
								type="button"
								disabled={!type}
								onClick={confirmHandle}
								className="button button--size2 button--full-width"
							>
								{String(L.translate('Account.TwoFactorAuth.two_factor_auth_enable_modal_btn'))}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalTwoFaOption;
