import { FC } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import logo from 'assets/img/logo.svg';

// ==========================================:
const AppLogo: FC = () => {
	const location = useLocation();

	if (location.pathname === '/') {
		return (
			<span className="logo">
				{/* <img src={logo} alt="Logo" width="41" height="24" /> */}
				<img src={logo} alt="Logo" />
			</span>
		);
	}

	return (
		<Link to="/" className="logo">
			<img src={logo} alt="Logo" />
		</Link>
	);
};

export default AppLogo;
