/* eslint-disable no-debugger */
import { FC, useState, useEffect, useCallback, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import L from 'i18n-react';
import Loader from 'ui/Loader';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getOrderHistory, getOrderHistoryLoader } from 'redux/reducers/options/selectors';
import { getOrderHistoryRequest } from 'redux/reducers/options/reducer';
import LoginTradeTablePlug from 'components/Trade/TradeTablePlug';
import OrderHistoryTabItem from './OrderHistoryTabItem';

const OrderHistoryTab = () => {
	const dispatch = useDispatch();
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const loader = useSelector(getOrderHistoryLoader);
	const orderHistory = useSelector(getOrderHistory);
	const { perPage, currentPage } = usePagination();

	useEffect(() => {
		dispatch(
			getOrderHistoryRequest({
				per_page: perPage,
				current_page: currentPage,
			}),
		);
	}, [currentPage, dispatch, perPage]);

	const renderContent: () => ReactNode = () => {
		if (!authIsAuthenticated) {
			return <LoginTradeTablePlug />;
		}
		if (loader) {
			return (
				<div className="popup-loader">
					<Loader />
				</div>
			);
		}
		if (orderHistory && orderHistory?.data?.length > 0) {
			return orderHistory?.data?.map((item) => {
				return <OrderHistoryTabItem key={item.id} item={item} />;
			});
		}
		return <TableEmptyPlug />;
	};

	return (
		<div className="table table--wallet table--options table--options-order-history">
			<div className="table-header">
				<div className="tr">
					<div className="td td--left">
						<p>{String(L.translate('Trade.Options.columns.contract'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.direction'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.order_price'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.qty_btc'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.avg_filled_price'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.filled_qty_btc'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.fees'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.order_pl'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.cash_flow'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.order_id'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.order_type'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.time_in_force'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Options.columns.status'))}</p>
					</div>
					<div className="td td--right">
						<p>{String(L.translate('Trade.Options.columns.order_time'))}</p>
					</div>
				</div>
			</div>
			<div className="table-body">{renderContent()}</div>
			{orderHistory?.last_page && Number(orderHistory?.total) > Number(orderHistory?.per_page) && (
				<Pagination lastPage={orderHistory.last_page} getCurrentHref={(s) => String(s)} />
			)}
		</div>
	);
};

export default OrderHistoryTab;
