import { FC } from 'react';
// import L from 'i18n-react';
import { IHeaderDropdownList } from 'layouts-elements/Header/AuthHeader/types';
import { NavLink } from 'react-router-dom';
import { IHeaderDropdown } from './types';

// ==================================:
const HeaderDropdown: FC<IHeaderDropdown> = (props) => {
	const { value, options, placeholder = `Select`, onChange } = props;

	const handleChange = (selectedValue: IHeaderDropdownList): void => {
		onChange(selectedValue);
	};

	return (
		<li>
			<button type="button" className="">
				<svg
					className="account-svg"
					width="24"
					height="25"
					viewBox="0 0 24 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M1.25 12.5C1.25 6.56294 6.06294 1.75 12 1.75C17.9371 1.75 22.75 6.56294 22.75 12.5C22.75 18.4371 17.9371 23.25 12 23.25C6.06294 23.25 1.25 18.4371 1.25 12.5ZM12 3.25C6.89136 3.25 2.75 7.39136 2.75 12.5C2.75 17.6086 6.89136 21.75 12 21.75C17.1086 21.75 21.25 17.6086 21.25 12.5C21.25 7.39136 17.1086 3.25 12 3.25Z"
						fill="#FF57D4"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8.75 9.5C8.75 7.70509 10.2051 6.25 12 6.25C13.7949 6.25 15.25 7.70509 15.25 9.5C15.25 11.2949 13.7949 12.75 12 12.75C10.2051 12.75 8.75 11.2949 8.75 9.5ZM12 7.75C11.0335 7.75 10.25 8.53351 10.25 9.5C10.25 10.4665 11.0335 11.25 12 11.25C12.9665 11.25 13.75 10.4665 13.75 9.5C13.75 8.53351 12.9665 7.75 12 7.75Z"
						fill="#FF57D4"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M9.99977 15.75C7.74825 15.75 5.90517 17.5012 5.75914 19.7154C5.73189 20.1287 5.37473 20.4416 4.96141 20.4144C4.5481 20.3871 4.23514 20.03 4.2624 19.6166C4.46007 16.6194 6.95289 14.25 9.99977 14.25H13.9998C17.0426 14.25 19.5329 16.6131 19.7364 19.6048C19.7645 20.018 19.4523 20.3758 19.039 20.4039C18.6258 20.432 18.268 20.1198 18.2398 19.7065C18.0895 17.4966 16.2483 15.75 13.9998 15.75H9.99977Z"
						fill="#FF57D4"
					/>
				</svg>
				{/* <span
					className={`select__icon ${
						placeholderIcon && placeholderIcon !== undefined ? placeholderIcon : ''
					}`}
				/> */}
				{value || <span>{placeholder}</span>}
				<svg
					style={{ marginLeft: '5px' }}
					width="10"
					height="6"
					viewBox="0 0 10 6"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M10 0.333328H1H0L5 5.33333L10 0.333328Z" fill="#1C1C29" />
				</svg>
			</button>

			<div className="header__nav-drop">
				<ul>
					{options.map((opt: IHeaderDropdownList) => {
						return (
							<li key={opt?.order}>
								<NavLink onClick={() => handleChange(opt)} to={opt.link}>
									{opt?.name}
								</NavLink>
							</li>
						);
					})}
				</ul>
			</div>
		</li>
	);
};

export default HeaderDropdown;
