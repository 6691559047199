import { FC } from 'react';
import { IOrderBookItem } from './types';

const BidItem: FC<IOrderBookItem> = ({ price: _price, size: _size, total: _total }) => {
	let price: number | string = _price;
	if (typeof price === 'number') {
		price = Number(price)?.toFixed(2);
	}
	price = price?.replace(/\.0{2}$/, '');

	let size: number | string = _size;
	if (typeof size === 'number') {
		size = Number(size)?.toFixed(6);
	}
	size = size?.replace(/\.0{2}$/, '');

	let total: number | string = _total;
	if (typeof total === 'number') {
		total = Number(total)?.toFixed(6);
	}
	total = total?.replace(/\.0{2}$/, '');

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Total</p>
				<p>{total || '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Ask Size</p>
				<p>{size || '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Ask Price</p>
				<p className="green">{price || '-'}</p>
			</div>
		</div>
	);
};

export default BidItem;
