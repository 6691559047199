import { useEffect } from 'react';
import Exchange from 'components/Exchange';
import ConvertLayout from 'layouts/Convert';
import { useDispatch } from 'react-redux';
import { getAssetPairsRequest } from 'redux/reducers/assetPairs/reducer';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';

const ExchangePage = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getWalletsRequest());
		dispatch(getAssetsRequest());
		dispatch(getAssetPairsRequest());
	}, [dispatch]);

	return (
		<ConvertLayout>
			<Exchange />
		</ConvertLayout>
	);
};

export default ExchangePage;
