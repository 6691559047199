import {
	checkAntiPhishingStateRequest,
	checkAntiPhishingStateSuccess,
	disableAntiPhishingRequest,
	disableAntiPhishingSuccess,
	getReferralBonusesError,
	getReferralBonusesRequest,
	getReferralBonusesSuccess,
	getUserReferralsInfoRequest,
	getUserReferralsInfoSuccess,
	postInvitationEmailSendRequest,
	postInvitationEmailSendSuccess,
	postKycValidationRequest,
	postKycValidationSuccess,
	setAntiPhishingCodeRequest,
	setNewPasswordRequest,
	setPhoneRequest,
	settingsInitState,
	userSettingsRequest,
	userSettingsSuccess,
} from 'redux/reducers/settings/reducer';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import L from 'i18n-react';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
// import L from 'i18n-react';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { responseErrors } from 'services/http/responseErrors';
import { callErrorFunc } from 'services/http/customResponseError/customResponseErrors';
import { TApiGetReferralBonusesResponse } from 'services/api/settings/types';
import { windowReference } from 'services/utils/windowRefference';

import {
	IAntiphishing,
	IAntiPhishingStatus,
	IKYCResponse,
	IUserSettingsResponse,
	TChangePhoneRequest,
	TInvitationsAll,
	TInvitationsData,
	TRefMemeber,
} from './types';

/* eslint-disable no-debugger */

// =============================================================:
function* userSettingsWorker() {
	try {
		yield put(showLoading());
		const response: IUserSettingsResponse = yield call(api.settings.userSettings);
		yield put(userSettingsSuccess(response.user));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
// function* changeUserPassWorker(action: PayloadAction<IChangePassPayload>) {
// 	const { payload } = action;

// 	try {
// 		yield put(showLoading());
// 		yield call(api.settings.changePassword, payload);
// 		yield put(changeUserPassSuccess());
// 		yield put(authInitState());

// 		// notificationContainer(String(L.translate(`Errors.password_successfully_changed`)), 'success');
// 	} catch (error) {
// 		if (axios.isAxiosError(error)) {
// 			responseErrors(error);
// 		}
// 		yield put(settingsInitState());
// 	} finally {
// 		yield put(hideLoading());
// 	}
// }

// =============================================================:
// function* generate2faKeyWorker() {
// 	try {
// 		yield put(showLoading());
// 		const response: IGenerate2faKeyResponse = yield call(api.settings.generate2faKey);
// 		yield put(generate2faKeySuccess(response));
// 	} catch (error) {
// 		if (axios.isAxiosError(error)) {
// 			responseErrors(error);
// 		}
// 		yield put(settingsInitState());
// 	} finally {
// 		yield put(hideLoading());
// 	}
// }

// =============================================================:
// function* check2faEnablesWorker() {
// 	try {
// 		yield put(showLoading());
// 		const response: ICheck2faEnables = yield call(api.settings.check2faEnables);
// 		yield put(check2faEnablesSuccess(response));
// 	} catch (error) {
// 		if (axios.isAxiosError(error)) {
// 			responseErrors(error);
// 		}
// 		yield put(settingsInitState());
// 	} finally {
// 		yield put(hideLoading());
// 	}
// }

// =============================================================:
// function* enable2faWorker(action: PayloadAction<IEnable2faPayload>) {
// 	const { payload } = action;

// 	try {
// 		yield put(showLoading());
// 		yield call(api.settings.enable2fa, payload);
// 		yield put(enable2faSuccess());
// 		yield put(check2faEnablesRequest());
// 		// notificationContainer(String(L.translate(`Errors.google_2fa_activated`)), 'success');
// 	} catch (error) {
// 		if (axios.isAxiosError(error)) {
// 			responseErrors(error);
// 		}
// 	} finally {
// 		yield put(hideLoading());
// 	}
// }

// =============================================================:
// function* disable2faWorker(action: PayloadAction<IDisable2faPayload>) {
// 	const { payload } = action;

// 	try {
// 		yield put(showLoading());
// 		yield call(api.settings.disable2fa, payload);
// 		yield put(disable2faSuccess());
// 		yield put(check2faEnablesRequest());
// 		// notificationContainer(String(L.translate(`Errors.google_2fa_turned_off`)), 'success');
// 	} catch (error) {
// 		if (axios.isAxiosError(error)) {
// 			responseErrors(error);
// 		}
// 	} finally {
// 		yield put(hideLoading());
// 	}
// }

// =============================================================:
// function* setUserPhoneWorker(action: PayloadAction<ISetUserPhoneRequestPayload>) {
// 	const { payload } = action;

// 	try {
// 		yield put(showLoading());
// 		yield call(api.settings.setPhone, payload);

// 		yield call(userSettingsWorker);

// 		// notificationContainer('Phone was successfully changed', 'success');
// 		payload.closeModal();
// 	} catch (error) {
// 		if (axios.isAxiosError(error)) {
// 			responseErrors(error);
// 		}
// 	} finally {
// 		yield put(hideLoading());
// 	}
// }

// =============================================================:
// function* checkNotificationWorker() {
// 	try {
// 		yield put(showLoading());
// 		const response: INotifications = yield call(api.settings.checkNotification);

// 		yield put(checkNotificationSuccess(response));
// 		// notificationContainer(String(L.translate(`Errors.google_2fa_activated`)), 'success');
// 	} catch (error) {
// 		if (axios.isAxiosError(error)) {
// 			responseErrors(error);
// 		}
// 	} finally {
// 		yield put(hideLoading());
// 	}
// }
// =============================================================:

// function* updateNotificationWorker(action: PayloadAction<INotifications>) {
// 	const { payload } = action;
// 	try {
// 		yield put(showLoading());
// 		const response: INotifications = yield call(api.settings.updateNotification, payload);
// 		yield put(checkNotificationSuccess(response));
// 	} catch (error) {
// 		if (axios.isAxiosError(error)) {
// 			responseErrors(error);
// 		}
// 		yield put(settingsInitState());
// 	} finally {
// 		yield put(hideLoading());
// 	}
// }

// ======================================Gato=======================:

function* postIvitationEmailSendWorker(action: PayloadAction<TRefMemeber>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: TInvitationsAll = yield call(api.settings.emailSend, payload);
		yield put(postInvitationEmailSendSuccess(response));

		yield put(getUserReferralsInfoRequest(true));
		notificationContainer('Email send', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			callErrorFunc(error?.response?.data?.errors);
			return;
		}
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ======================================Gato=======================:

function* getRefarralsWorker() {
	try {
		yield put(showLoading());
		const response: TInvitationsData = yield call(api.settings.getReferrals);
		yield put(getUserReferralsInfoSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(settingsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getReferralBonusesWorker() {
	try {
		const data: TApiGetReferralBonusesResponse = yield call(api.settings.getReferralBonuses);

		yield put(getReferralBonusesSuccess(data));
	} catch (error) {
		yield put(getReferralBonusesError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
			notificationContainer(error?.response?.data?.errors, 'error');
		}
	}
}

function* startKycValidation(action: any) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: { data: string } = yield call(api.settings.postKycVerification, payload);
		yield put(postKycValidationSuccess(response));
		// console.log(response);
		// if (response) {
		// 	const ios = /iPhone|iPad|iPod/i;
		// 	if (ios.exec(navigator?.userAgent) && payload.setIosLink) {
		// 		payload.setIosLink({ url: response.data || '', params: {} });

		// 		notificationContainer(String(L.translate('Notification.link_generated')), 'info');
		// 	} else {
		// 		windowReference(response.data);

		// 		notificationContainer(String(L.translate('Notification.open_ondato')), 'info');
		// 	}
		// } else {
		// 	notificationContainer(String(L.translate('Notification.open_ondato_error')), 'error');
		// }
	} catch (error) {
		if (axios.isAxiosError(error)) {
			const code: string = error?.response?.data?.errors;
			notificationContainer(code, 'error');
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* setPhoneWorker({ payload }: PayloadAction<TChangePhoneRequest>) {
	try {
		yield call(api.settings.setPhone, payload);

		yield put(userSettingsRequest());
		if (payload?.onSuccess) {
			yield put(payload.onSuccess());
		}
	} catch (error) {
		yield put(getReferralBonusesError());

		if (axios.isAxiosError(error)) {
			notificationContainer(`The code sent to your phone is invalid.`, 'error');
		}
	} finally {
		yield put(hideLoading());
	}
}

function* setAntiphishingWorker(action: any) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IAntiphishing = yield call(api.settings.setAntiPhishingCode, payload);

		if (response.status === 200) {
			notificationContainer('Anti-Phishing has been activated', 'info');
		}
		yield put(checkAntiPhishingStateSuccess(true));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
function* setNewPasswordWorker(action: any) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IAntiphishing = yield call(api.settings.setNewPassword, payload);
		console.log(response);
		if (response.status === 200) {
			notificationContainer('Password has been changed', 'info');
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
			const code: string = error?.response?.data?.errors;
			notificationContainer(String(L.translate(`Errors.${code}`)), 'error');
		}
	}
}
function* checkAntiPhishingStateWorker() {
	try {
		const response: IAntiPhishingStatus = yield call(api.settings.getAntiphishingStatus);
		yield put(checkAntiPhishingStateSuccess(response.enabled));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
			const code: string = error?.response?.data?.errors;
			notificationContainer(String(L.translate(`Errors.${code}`)), 'error');
		}
	}
}

function* disableAntiPhishingStateWorker() {
	try {
		const response: IAntiphishing = yield call(api.settings.disableAntiPhishing);
		yield put(disableAntiPhishingSuccess());
		if (response.status === 200) {
			notificationContainer('Anti-Phishing has been turned off', 'info');
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
			const code: string = error?.response?.data?.errors;
			notificationContainer(String(L.translate(`Errors.${code}`)), 'error');
		}
	}
}

// =============================================================:
export function* settingsSaga() {
	yield takeEvery(userSettingsRequest.type, userSettingsWorker);
	// yield takeEvery(changeUserPassRequest.type, changeUserPassWorker);
	// yield takeEvery(check2faEnablesRequest.type, check2faEnablesWorker);
	// yield takeEvery(generate2faKeyRequest.type, generate2faKeyWorker);
	// yield takeEvery(enable2faRequest.type, enable2faWorker);
	// yield takeEvery(disable2faRequest.type, disable2faWorker);
	// yield takeEvery(setPhoneRequest.type, setUserPhoneWorker);
	// yield takeEvery(checkNotificationRequest.type, checkNotificationWorker);
	// yield takeEvery(updateNotificationRequest.type, updateNotificationWorker);
	yield takeEvery(postInvitationEmailSendRequest.type, postIvitationEmailSendWorker);
	yield takeEvery(getUserReferralsInfoRequest.type, getRefarralsWorker);
	yield takeLatest(getReferralBonusesRequest, getReferralBonusesWorker);
	yield takeLatest(postKycValidationRequest.type, startKycValidation);
	yield takeEvery(setPhoneRequest.type, setPhoneWorker);
	yield takeEvery(setAntiPhishingCodeRequest.type, setAntiphishingWorker);
	yield takeEvery(setNewPasswordRequest.type, setNewPasswordWorker);
	// yield takeEvery(checkAntiPhishingStateRequest.type, checkAntiPhishingStateWorker);
	yield takeEvery(disableAntiPhishingRequest.type, disableAntiPhishingStateWorker);
}
