import { FC, useEffect, useState, useCallback } from 'react';
import L from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'ui/Loader';
import { navList } from 'routes/routesList';
import { WalletLayout } from 'layouts/Wallet';
import { convertToFixed } from 'services/utils/convertToFixed';
import { numberExists } from 'services/utils/numberValidation';
import TransferModal from 'components/Wallet/modals/TransferEnhanced';
import {
	getDerivativeAccount,
	getDerivativeAccountLoader,
} from 'redux/reducers/perpetual/selectors';
import { Link } from 'react-router-dom';
import { getDerivativeAccountRequest } from 'redux/reducers/perpetual/reducer';

const Derivatives: FC = () => {
	const dispatch = useDispatch();
	const [transferOpened, setTransferOpened] = useState(false);
	const derivativeAccount = useSelector(getDerivativeAccount);
	const loader = useSelector(getDerivativeAccountLoader);

	const {
		balance_usdt: balanceUsdt,
		balance_usd: balanceUsd,
		available_balance_usdt: availableBalanceUsdt,
		available_balance_usd: availableBalanceUsd,
		unrealized_pnl: unrealizedPnl,
		unrealized_pnl_usd: unrealizedPnlUsd,
		future_margin: futureMargin,
		future_margin_usd: futureMarginUsd,
		option_margin: optionMargin,
		option_margin_usd: optionMarginUsd,
	} = derivativeAccount || {};

	const openTransfer = () => {
		setTransferOpened(true);
	};

	const closeTransfer = () => {
		setTransferOpened(false);
	};

	const fetchDerivatives = useCallback(() => {
		dispatch(getDerivativeAccountRequest());
	}, [dispatch]);

	useEffect(() => {
		fetchDerivatives();
	}, [fetchDerivatives]);

	return (
		<WalletLayout title={String(L.translate('Wallets.Derivatives.helmet_title'))}>
			<div className="wallet-content derivative-account">
				<div className="wallet-content-header flex flex-sb">
					<div className="wallet-content-header__title">
						<p>{String(L.translate('Wallets.Derivatives.title'))}</p>
					</div>
					<div className="wallet-content-header__buttons flex flex-e flex-m">
						<Link to={navList.depositCrypto.path} className="button button--type8">
							{L.translate('Wallets.actions.deposit')}
						</Link>
						<Link to={navList.withdrawCrypto.path} className="button button--type8">
							{L.translate('Wallets.actions.withdraw')}
						</Link>
						<button type="button" onClick={openTransfer} className="button button--type8">
							{L.translate('Wallets.actions.transfer')}
						</button>
					</div>
				</div>
				{loader ? (
					<div className="popup-loader">
						<Loader />
					</div>
				) : (
					<>
						<div className="wallet-content-balance flex">
							<div className="wallet-content-balance-item">
								<div className="wallet-content-balance-item__title">
									<p>{String(L.translate('Wallets.Derivatives.total_balance'))}</p>
								</div>
								<div className="wallet-content-balance-item__value">
									<p>
										{numberExists(balanceUsdt) && convertToFixed(Number(balanceUsdt), 2)}{' '}
										<span>USDT</span>
									</p>
								</div>
								<div className="wallet-content-balance-item__user-currensy">
									<p>≈ ${numberExists(balanceUsd) && convertToFixed(Number(balanceUsd), 2)}</p>
								</div>
							</div>
							<div className="wallet-content-balance-item">
								<div className="wallet-content-balance-item__title">
									<p>{String(L.translate('Wallets.Derivatives.available_balance'))}</p>
								</div>
								<div className="wallet-content-balance-item__value">
									<p>
										{numberExists(availableBalanceUsdt) &&
											convertToFixed(Number(availableBalanceUsdt), 2)}{' '}
										<span>USDT</span>
									</p>
								</div>
								<div className="wallet-content-balance-item__user-currensy">
									<p>
										≈ $
										{numberExists(availableBalanceUsd) &&
											convertToFixed(Number(availableBalanceUsd), 2)}
									</p>
								</div>
							</div>
							<div className="wallet-content-balance-item">
								<div className="wallet-content-balance-item__title">
									<p>{String(L.translate('Wallets.Derivatives.unrealized_pnl'))}</p>
								</div>
								<div className="wallet-content-balance-item__value">
									<p>
										{numberExists(unrealizedPnl) && convertToFixed(Number(unrealizedPnl), 2)}{' '}
										<span>USDT</span>
									</p>
								</div>
								<div className="wallet-content-balance-item__user-currensy">
									<p>
										≈ $
										{numberExists(unrealizedPnlUsd) && convertToFixed(Number(unrealizedPnlUsd), 2)}
									</p>
								</div>
							</div>
							<div className="wallet-content-balance-item">
								<div className="wallet-content-balance-item__title">
									<p>{String(L.translate('Wallets.Derivatives.futures_margin'))}</p>
								</div>
								<div className="wallet-content-balance-item__value">
									<p>
										{numberExists(futureMargin) && convertToFixed(Number(futureMargin), 2)}{' '}
										<span>USDT</span>
									</p>
								</div>
								<div className="wallet-content-balance-item__user-currensy">
									<p>
										≈ ${numberExists(futureMarginUsd) && convertToFixed(Number(futureMarginUsd), 2)}
									</p>
								</div>
							</div>
							<div className="wallet-content-balance-item">
								<div className="wallet-content-balance-item__title">
									<p>{String(L.translate('Wallets.Derivatives.options_margin'))}</p>
								</div>
								<div className="wallet-content-balance-item__value">
									<p>
										{numberExists(optionMargin) && convertToFixed(Number(optionMargin), 2)}{' '}
										<span>USDT</span>
									</p>
								</div>
								<div className="wallet-content-balance-item__user-currensy">
									<p>
										≈ ${numberExists(optionMarginUsd) && convertToFixed(Number(optionMarginUsd), 2)}
									</p>
								</div>
							</div>
						</div>
						<div className="transfer-btn-container">
							<button
								type="button"
								onClick={openTransfer}
								className="button button--big1 button--size2 button--type5"
							>
								{String(L.translate('Wallets.actions.transfer'))}
							</button>
						</div>
					</>
				)}
			</div>
			{transferOpened && <TransferModal onClose={closeTransfer} refetch={fetchDerivatives} />}
		</WalletLayout>
	);
};

export default Derivatives;
