import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	IAssetPairsArray,
	IAssetPairsFeesAndLimitsData,
	IAssetPairsStore,
	IAssetTopPairsItem,
} from './types';

// ==========================================:
const getAssetPairsState = (state: TStoreState): IAssetPairsStore => state.assetPairs;
export const getAssetPairs = createSelector(
	[getAssetPairsState],
	(assetPairs: IAssetPairsStore) => assetPairs,
);
// ====================================================:
export const getAssetPairsIsLoad = createSelector(
	[getAssetPairs],
	(assetPairs: IAssetPairsStore): boolean => assetPairs.assetPairsLoader,
);

// ====================================================:
export const getAssetPairsList = createSelector(
	[getAssetPairs],
	(assetPairs: IAssetPairsStore): IAssetPairsArray => assetPairs.assetPairs,
);

export const getAssetPairsListFavorite = (state: TStoreState) => {
	return getAssetPairsState(state).assetPairsFavorite;
};

export const getAssetPairsFeesAndLimitsIsLoad = createSelector(
	[getAssetPairs],
	(assetPairs: IAssetPairsStore): boolean => assetPairs.assetPairsFeesAndLimitsLoader,
);

// ====================================================:
export const getAssetPairsFeesAndLimitsList = createSelector(
	[getAssetPairs],
	(assetPairs: IAssetPairsStore): IAssetPairsFeesAndLimitsData =>
		assetPairs.assetPairsFeesAndLimits,
);

// ====================================================:
export const getTopAssetPairs = createSelector(
	[getAssetPairs],
	(assetPairs: IAssetPairsStore): IAssetTopPairsItem[] | null => assetPairs.assetTopPairs,
);
export const getTopAssetPairsLoader = createSelector(
	[getAssetPairs],
	(assetPairs: IAssetPairsStore): boolean => assetPairs.assetTopPairsLoader,
);

// ===================================================
export const getVolume24 = createSelector(
	[getAssetPairs],
	(assetPairs: IAssetPairsStore): number | null => assetPairs.volume24,
);
