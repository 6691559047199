import { FC, useState } from 'react';
import PositionCloseModal from 'components/Trade/OptionTrades/Modals/PositionCloseModal';
import { useGetOptionsMap } from 'components/Trade/OptionTrades/TradeTables/hooks';
import { numberExists } from 'services/utils/numberValidation';
import { IOptionOrderPosition } from 'redux/reducers/options/types';
import { IPositionsTabItem } from './types';

const PositionsTabItem: FC<IPositionsTabItem> = ({
	level,
	isFolder,
	opened,
	item,
	title,
	containsFolder,
	onClick,
	summarized,
	avgPrice,
}) => {
	const [closeModalOpened, setCloseModalOpened] = useState(false);
	const optionsMap = useGetOptionsMap();
	const option: IOptionOrderPosition = item?.option_id ? optionsMap?.[item?.option_id] : null;

	const openCloseModal = () => {
		setCloseModalOpened(true);
	};

	const closeCloseModal = () => {
		setCloseModalOpened(false);
	};

	const handleClick = () => {
		onClick && onClick();
	};

	let unrealizedPnl: number | undefined;

	if (
		item &&
		option?.mark_price != null &&
		item?.entry_price != null &&
		item?.amount_asset_underlying != null &&
		option?.type_option
	) {
		if (item?.transactions_type === 'buy') {
			unrealizedPnl =
				(Number(option?.mark_price) - Number(item?.entry_price)) *
				Number(item?.amount_asset_underlying);
		}
		if (item?.transactions_type === 'sell') {
			unrealizedPnl =
				(Number(item?.entry_price) - Number(option?.mark_price)) *
				Number(item?.amount_asset_underlying);
		}
	}

	const finalUpl = isFolder ? summarized?.unrealizedPnl : Number(unrealizedPnl);

	let unrealizedPnlPercent: string | number | null =
		unrealizedPnl != null && item?.entry_price
			? (unrealizedPnl * 100) / Number(item?.entry_price)
			: null;

	unrealizedPnlPercent =
		typeof unrealizedPnlPercent === 'number'
			? Number(unrealizedPnlPercent)
					?.toFixed(2)
					?.replace(/\.0{2}$/, '')
			: null;

	let imCoefficient: string | number | undefined | null = isFolder
		? summarized?.im_coefficient
		: item?.im_coefficient;

	let mmCoefficient: string | number | undefined | null = isFolder
		? summarized?.mm_coefficient
		: item?.mm_coefficient;

	let delta: string | number | null | undefined = isFolder ? summarized?.delta : option?.delta;

	let gamma: string | number | null | undefined = isFolder ? summarized?.gamma : option?.gamma;

	let vega: string | number | null | undefined = isFolder ? summarized?.vega : option?.vega;

	let teta: string | number | null | undefined = isFolder ? summarized?.teta : option?.teta;

	const qty: string | number | null | undefined = isFolder
		? summarized?.qty
		: item?.amount_asset_underlying;

	gamma =
		typeof gamma === 'number'
			? Number(gamma)
					?.toFixed(5)
					?.replace(/\.0{5}$/, '')
			: gamma;

	imCoefficient =
		typeof imCoefficient === 'number'
			? Number(imCoefficient)
					?.toFixed(4)
					?.replace(/\.0{4}$/, '')
			: imCoefficient;

	mmCoefficient =
		typeof mmCoefficient === 'number'
			? Number(mmCoefficient)
					?.toFixed(4)
					?.replace(/\.0{4}$/, '')
			: mmCoefficient;

	delta =
		typeof delta === 'number'
			? Number(delta)
					?.toFixed(4)
					?.replace(/\.0{4}$/, '')
			: delta;

	vega =
		typeof vega === 'number'
			? Number(vega)
					?.toFixed(4)
					?.replace(/\.0{4}$/, '')
			: vega;

	teta =
		typeof teta === 'number'
			? Number(teta)
					?.toFixed(4)
					?.replace(/\.0{4}$/, '')
			: teta;

	const markPrice =
		typeof option?.mark_price === 'number'
			? option?.mark_price?.toFixed(2)?.replace(/\.0{2}$/, '')
			: '-';

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Contract</p>
				{isFolder ? (
					<button
						type="button"
						onClick={handleClick}
						className={`open-tr open-tr--level${String(level)} ${opened ? 'active' : ''}`}
					>
						<span className={`open-tr-line ${opened && containsFolder ? 'active' : ''}`} />
						<svg
							width="15"
							height="16"
							viewBox="0 0 15 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M2.8125 2.84375C2.55362 2.84375 2.34375 3.05362 2.34375 3.3125V12.6875C2.34375 12.9464 2.55362 13.1562 2.8125 13.1562H12.1875C12.4464 13.1562 12.6562 12.9464 12.6562 12.6875V3.3125C12.6562 3.05362 12.4464 2.84375 12.1875 2.84375H2.8125ZM1.40625 3.3125C1.40625 2.53585 2.03585 1.90625 2.8125 1.90625H12.1875C12.9642 1.90625 13.5938 2.53585 13.5938 3.3125V12.6875C13.5938 13.4642 12.9642 14.0938 12.1875 14.0938H2.8125C2.03585 14.0938 1.40625 13.4642 1.40625 12.6875V3.3125Z"
								fill="#7D7D4D"
							/>
							<path
								className="open-tr-path"
								fillRule="evenodd"
								clipRule="evenodd"
								d="M7.5 5.03125C7.75888 5.03125 7.96875 5.24112 7.96875 5.5V10.5C7.96875 10.7589 7.75888 10.9688 7.5 10.9688C7.24112 10.9688 7.03125 10.7589 7.03125 10.5V5.5C7.03125 5.24112 7.24112 5.03125 7.5 5.03125Z"
								fill="#7D7D4D"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M4.53125 8C4.53125 7.74112 4.74112 7.53125 5 7.53125H10C10.2589 7.53125 10.4688 7.74112 10.4688 8C10.4688 8.25888 10.2589 8.46875 10 8.46875H5C4.74112 8.46875 4.53125 8.25888 4.53125 8Z"
								fill="#7D7D4D"
							/>
						</svg>
						<span className="upper-case">{item?.contract_name?.toUpperCase() || title}</span>
					</button>
				) : (
					<div className="td td--left">
						<p className="td-hidden-name">Contract</p>
						<p className={`open-tr--level${level}`}>
							{item?.contract_name?.toUpperCase() || title}
						</p>
					</div>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Qty</p>
				<p className={numberExists(qty) ? 'green' : ''}>{numberExists(qty) ? qty : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Entry Price</p>
				<p>{item?.entry_price != null ? item?.entry_price.toFixed(2) : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">AVg, Session Price</p>
				<p>{summarized?.avgPrice != null ? summarized?.avgPrice.toFixed(2) : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">IM</p>
				<p>{imCoefficient != null ? imCoefficient : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">MM</p>
				<p>{mmCoefficient != null ? mmCoefficient : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Delta</p>
				<p>{delta != null ? delta : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Gamma</p>
				<p>{gamma != null ? gamma : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Vega</p>
				<p>{vega != null ? vega : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Theta</p>
				<p>{teta != null ? teta : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Mark Price</p>
				<p>{markPrice}</p>
				<span>
					{numberExists(option?.iv)
						? `${String(option?.iv?.toFixed(2).replace(/\.00$/, ''))}%`
						: ''}
				</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Unreleased P&L(ROI)</p>
				<p className={(finalUpl && (finalUpl > 0 ? 'green' : 'red')) || ''}>
					{finalUpl?.toFixed(2)}
				</p>
				<span>{unrealizedPnlPercent ? `${unrealizedPnlPercent}%` : ''}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Action</p>
				<span>
					{!isFolder && (
						<button type="button" className="button-table" onClick={openCloseModal}>
							Close
						</button>
					)}
				</span>
			</div>
			{!isFolder && closeModalOpened && item && (
				<PositionCloseModal item={item} avgPrice={avgPrice} onClose={closeCloseModal} />
			)}
		</div>
	);
};

export default PositionsTabItem;
