import { FC, useState, useMemo, useEffect } from 'react';
import L from 'i18n-react';
import { format } from 'date-fns';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getOrdersHistoryRequest, setTradeType } from 'redux/reducers/spotTrade/reducer';
import {
	getSpotUserOrdersHistory,
	getSpotUserOrdersHistoryIsLoad,
	getTradeType,
} from 'redux/reducers/spotTrade/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';
import { deleteSearchParam } from 'services/utils/url';
import FilteredBar from '../FilteredBar';
import OrdersHistoryItem from '../OrdersHistoryItem';
import { IOrdersHistoryProps } from './types';
import EmptyHistoryPlug from '../EmptyHistoryPlug';

export const OrdersHistory: FC<IOrdersHistoryProps> = ({ otherPairsIsHide }) => {
	const ordersHistory = useSelector(getSpotUserOrdersHistory);
	const ordersHistoryIsLoad = useSelector(getSpotUserOrdersHistoryIsLoad);
	const { perPage, currentPage } = usePagination();

	const [dateRange, setDateRange] = useState<null | [Date | null, Date | null]>(null);

	const currentPair = useSelector(getCurrentPair);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const marginTypeSearch = new URLSearchParams(location.search).get('type') || null;
	const tradeType = useSelector(getTradeType);

	const removeCurrentPage = () => {
		const search = deleteSearchParam('currentPage');
		history.replace({
			pathname: location.pathname,
			search,
		});
	};

	useEffect(() => {
		if (marginTypeSearch && tradeType !== marginTypeSearch) {
			dispatch(setTradeType(marginTypeSearch));
		}
		if (location.pathname.includes('spot' || 'Spot') && tradeType !== 'spot') {
			dispatch(setTradeType('spot'));
		}
	}, [location, marginTypeSearch, tradeType, dispatch]);

	useEffect(() => {
		if (authIsAuthenticated) {
			const [startDate, endDate] = dateRange || [];
			dispatch(
				getOrdersHistoryRequest({
					params: {
						order_type_ids: '1,4,7',
						status: 'closed',
						order_type:
							marginTypeSearch === 'cross' || marginTypeSearch === 'isolated'
								? marginTypeSearch
								: 'spot',
						per_page: perPage,
						current_page: currentPage,
						start_date: startDate ? format(startDate, 'yyyy-MM-dd') : undefined,
						end_date: endDate ? format(endDate, 'yyyy-MM-dd') : undefined,
						asset_pair_code: otherPairsIsHide ? currentPair.pair : undefined,
					},
				}),
			);
		}
	}, [
		dispatch,
		authIsAuthenticated,
		marginTypeSearch,
		perPage,
		currentPage,
		otherPairsIsHide,
		currentPair.pair,
		dateRange,
	]);

	const hadleSearchDataPerDate = (date: [Date | null, Date | null]) => {
		removeCurrentPage();
		setDateRange(date);
	};

	return (
		<>
			<FilteredBar
				handleSearch={hadleSearchDataPerDate}
				dateRange={dateRange}
				currentClass="table-date-filter"
			/>

			<div className="table table--trade table--trade-order-history">
				<div className="table-header">
					<div className="tr tr--eleventhColumn">
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.date_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.pair_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.type_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.side_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate(
										'Trade.Spot.SpotHistoryTables.OrdersHistoryTable.average_column_name',
									),
								)}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.price_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.amount_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.filled_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.total_column_name'),
								)}
							</span>
						</div>
						<div className="td">
							<span className="table-header__name">
								{String(
									L.translate(
										'Trade.Spot.SpotHistoryTables.OrdersHistoryTable.trigger_conditions_column_name',
									),
								)}
							</span>
						</div>
						<div className="td td--right">
							<span className="table-header__name">
								{String(
									L.translate('Trade.Spot.SpotHistoryTables.OrdersHistoryTable.status_column_name'),
								)}
							</span>
						</div>
					</div>
				</div>
				<div className="table-body">
					{ordersHistoryIsLoad && (
						<div className="open-orders-history-loader-wrapper">
							<Loader />
						</div>
					)}
					{!ordersHistoryIsLoad && ordersHistory?.data?.length
						? ordersHistory?.data?.map((order: any) => (
								<OrdersHistoryItem key={order.id} data={order} />
						  ))
						: null}

					{!ordersHistoryIsLoad && ordersHistory?.data?.length === 0 && (
						<EmptyHistoryPlug
							text={String(
								L.translate(
									'Trade.Spot.SpotHistoryTables.OrdersHistoryTable.no_orders_history_text',
								),
							)}
						/>
					)}
					{ordersHistory?.last_page &&
						Number(ordersHistory?.total) > Number(ordersHistory?.per_page) && (
							<Pagination lastPage={ordersHistory.last_page} getCurrentHref={(s) => String(s)} />
						)}
				</div>
			</div>
		</>
	);
};
