import { FC } from 'react';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { numberExists } from 'services/utils/numberValidation';
import StatusChip from '../StatusChip';
import { IFiatTableRow } from './types';

const FiatTableRow: FC<IFiatTableRow> = ({ item }) => {
	const {
		created_at: createdAt,
		asset,
		payment_system: paymentSystem,
		indicated_amount: indicatedAmount,
		amount_fee: amountFee,
		amount,
		status,
	} = item;
	return (
		<div className="tr">
			<div className="td">
				<p className="grey">{createdAt && customDateFormat(createdAt)}</p>
			</div>
			<div className="td">
				<p>{asset?.code?.toUpperCase()}</p>
			</div>
			<div className="td">
				<p>{paymentSystem}</p>
			</div>
			<div className="td">
				<p>{indicatedAmount}</p>
			</div>
			<div className="td">
				<p>{amountFee}</p>
			</div>
			<div className="td">
				<p>{numberExists(amount) ? Number(amount)?.toFixed(6) : '-'}</p>
			</div>
			<div className="td td--right">
				<StatusChip status={status} />
			</div>
		</div>
	);
};

export default FiatTableRow;
