import { FC } from 'react';
import { IAssetDropItem } from './types';

const AssetDropItem: FC<IAssetDropItem> = ({ item, handleAssetSelect }) => {
	const { id, code, img_path: imgPath } = item;
	return (
		<li key={id} className="active">
			<button type="button" onClick={() => handleAssetSelect(item)}>
				<span className="select-current-currensy">
					<span className="select-current-currensy__icon">
						<img src={imgPath} alt="coin" />
					</span>
					<span className="select-current-currensy__name">{code?.toUpperCase()}</span>
				</span>
			</button>
			<div className="select__drop-item-check">
				<svg
					width="18"
					height="14"
					viewBox="0 0 18 14"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M17.3593 1.14262C17.6028 1.38728 17.6019 1.783 17.3572 2.0265L6.4718 12.8598C6.22797 13.1025 5.83388 13.1025 5.59004 12.8598L0.642127 7.93558C0.397465 7.69209 0.396516 7.29636 0.640008 7.0517C0.8835 6.80704 1.27923 6.80609 1.52389 7.04958L6.03092 11.5351L16.4755 1.1405C16.7201 0.897004 17.1158 0.897953 17.3593 1.14262Z"
						fill="#FF57D4"
					/>
				</svg>
			</div>
		</li>
	);
};

export default AssetDropItem;
