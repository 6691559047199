import React, { useEffect } from 'react';
import L from 'i18n-react';

const Articles = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<section className="static-section section-gradient">
				<div className="inside">
					<p className="section-title">
						{String(L.translate('StaticPages.ArticlesPage.articles_title_1'))}
					</p>
					<div className="static-content">
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_1'))}</p>
						<h3>{String(L.translate('StaticPages.ArticlesPage.header_2'))}</h3>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_2'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_3'))}</p>
						<h3>{String(L.translate('StaticPages.ArticlesPage.header_3'))}</h3>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_4'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_5'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_6'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_7'))}</p>
						<h3>{String(L.translate('StaticPages.ArticlesPage.header_4'))}</h3>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_8'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_9'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_10'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_11'))}</p>
						<h3>{String(L.translate('StaticPages.ArticlesPage.header_6'))}</h3>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_12'))}</p>
					</div>
				</div>
			</section>
			<section className="static-section section-gradient">
				<div className="inside">
					<p className="section-title">
						{String(L.translate('StaticPages.ArticlesPage.articles_title_2'))}
					</p>
					<div className="static-content">
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_13'))}</p>
						<h3>{String(L.translate('StaticPages.ArticlesPage.header_7'))}</h3>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_14'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_15'))}</p>
						<h3>{String(L.translate('StaticPages.ArticlesPage.header_8'))}</h3>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_16'))}</p>
						<p>
							<a href="https://academy.binance.com/en/articles/a-guide-to-makerdao-and-dai">
								MakerDAO
							</a>{' '}
							{String(L.translate('StaticPages.ArticlesPage.paragraph_17'))}
						</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_18'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_19'))}</p>
						<h3>{String(L.translate('StaticPages.ArticlesPage.header_9'))}</h3>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_20'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_21'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_22'))}</p>
						<h3>{String(L.translate('StaticPages.ArticlesPage.header_10'))}</h3>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_23'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_24'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_25'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_26'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_27'))}</p>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_28'))}</p>
						<h3>{String(L.translate('StaticPages.ArticlesPage.header_11'))}</h3>
						<p>{String(L.translate('StaticPages.ArticlesPage.paragraph_29'))}</p>
					</div>
				</div>
			</section>
		</>
	);
};

export default Articles;
