import { FC } from 'react';
import { ITableItemProps } from './types';

export const TableItem: FC<ITableItemProps> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td">
				<p>{item.email || '-'}</p>
			</div>
			<div className="td">
				<p>{item.phone || '-'}</p>
			</div>
			<div className="td td--right">
				<p>{Number(item.amount_usdt).toFixed(2)}</p>
			</div>
		</div>
	);
};
