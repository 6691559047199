import { FC, useState } from 'react';
import L from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import Portal from 'ui/Portal';
import Loader from 'ui/Loader';
import { getShowCancelOrderConfirm } from 'redux/reducers/options/selectors';
import { setShowCancelOrderConfirm } from 'redux/reducers/options/reducer';
import { ICancelOrderModal } from './types';

const CancelOrderModal: FC<ICancelOrderModal> = ({ loader, onSubmit, onClose }) => {
	const dispatch = useDispatch();
	const [notShowAgain, setNotShowAgain] = useState(false);
	const showCancelOrderConfirm = useSelector(getShowCancelOrderConfirm);
	const handleClose = () => {
		onClose && onClose();
	};

	const handleNotShowAgainClick = () => {
		setNotShowAgain((prev) => !prev);
	};

	const handleSubmit = () => {
		if (notShowAgain) {
			dispatch(setShowCancelOrderConfirm(false));
		}
		onSubmit && onSubmit();
	};

	if (!showCancelOrderConfirm) {
		return null;
	}

	return (
		<Portal>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup">
						<button type="button" className="popup__close" onClick={handleClose}>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
									fill="#1C1C29"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
									fill="#1C1C29"
								/>
							</svg>
						</button>
						<div className="popup-header">
							<p className="popup-header__title">
								{String(L.translate('Trade.Options.cancelOrderModal.title'))}
							</p>
						</div>
						{loader ? (
							<div className="popup-loader">
								<Loader />
							</div>
						) : (
							<div className="popup-body">
								<div className="flex-column">
									<div className="popup-text">
										<p>{String(L.translate('Trade.Options.cancelOrderModal.bodyMessage'))}</p>
									</div>
									<div className="checkbox checkbox--no-mb">
										<label className="checkbox__label flex-t">
											<input
												type="checkbox"
												className="hidden"
												checked={notShowAgain}
												onChange={handleNotShowAgainClick}
											/>
											<span className=" checkbox__item">
												<span className="checkbox__item-icon">
													<svg
														width="17"
														height="17"
														viewBox="0 0 17 17"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M14.5426 4.58269C14.7501 4.79016 14.7501 5.12653 14.5426 5.33399L7.45931 12.4173C7.25184 12.6248 6.91547 12.6248 6.70801 12.4173L3.16634 8.87566C2.95888 8.66819 2.95888 8.33183 3.16634 8.12436C3.37381 7.91689 3.71018 7.91689 3.91764 8.12436L7.08366 11.2904L13.7913 4.58269C13.9988 4.37523 14.3352 4.37523 14.5426 4.58269Z"
															fill="#FF57D4"
														/>
													</svg>
												</span>
											</span>
											<span className="checkbox__text">
												{String(L.translate('Trade.Options.cancelOrderModal.dontShowAgain'))}
											</span>
										</label>
									</div>
								</div>
							</div>
						)}
						<div className="popup-footer popup-footer--item2">
							<button
								type="button"
								className="button button--type3 button--size2 button--full-width"
								onClick={handleClose}
							>
								{String(L.translate('Trade.Options.modals.cancel'))}
							</button>
							<button
								type="button"
								className="button button--size2 button--full-width"
								onClick={handleSubmit}
							>
								{String(L.translate('Trade.Options.modals.confirm'))}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Portal>
	);
};

export default CancelOrderModal;
