import AccountSettingsAnalytics from 'components/AccountSettings/AccountSettingsAnalytics';
import L from 'i18n-react';
import { Page } from 'layouts/page';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';

export const AccountSettingsAnalyticsPage: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAssetsRequest());
	}, [dispatch]);

	return (
		<Page title={String(L.translate('Analytics.helmet_title'))}>
			<AccountSettingsAnalytics />
		</Page>
	);
};
