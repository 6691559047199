import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInterestedHistoryRequest } from 'redux/reducers/marginWallets/reducer';
import {
	getMarginWalletsIsLoader,
	getIsolatedInterestedHistory,
} from 'redux/reducers/marginWallets/selectors';
import usePagination from 'hooks/usePagination';
import InterestPage from 'components/MarginWallets/InterestPage';

const InterestIsolated: FC = () => {
	const dispatch = useDispatch();
	const { perPage, currentPage } = usePagination();
	const loading = useSelector(getMarginWalletsIsLoader);
	const interest = useSelector(getIsolatedInterestedHistory);

	useEffect(() => {
		dispatch(
			getInterestedHistoryRequest({
				isolated: true,
				params: {
					margin_type: 'isolated',
					per_page: perPage,
					current_page: currentPage,
				},
			}),
		);
	}, [currentPage, dispatch, perPage]);

	return <InterestPage isolated interest={interest} loading={loading} />;
};

export default InterestIsolated;
