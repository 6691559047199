import { FC, SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import L from 'i18n-react';
import { useFormikContext, FormikContextType } from 'formik';
import Loader from 'ui/Loader';
import { getActiveBaseAsset } from 'redux/reducers/perpetual/selectors';
import PersentSlider from 'components/Trade/TradesBox/PersentSlider';
import { IPositionCloseModalContent, Values } from './types';

const PositionCloseModalContent: FC<IPositionCloseModalContent> = ({ item, avgPrice, onClose }) => {
	const { values, setValues, isValid, errors, isSubmitting }: FormikContextType<Values> =
		useFormikContext();
	const handleClose = () => {
		onClose && onClose();
	};

	const activeBaseAssets = useSelector(getActiveBaseAsset);

	const btcStatistics = activeBaseAssets?.find((ba) => ba.base_asset === 'btc');

	const fee = btcStatistics?.maker_fee_option;

	let apxLoss: number | undefined;

	if (item.entry_price != null && item.amount_asset_underlying != null && fee != null) {
		if (item.transactions_type === 'buy') {
			apxLoss =
				(Number(values?.price) - item.entry_price) * item.amount_asset_underlying -
				fee * Number(values?.price);
		}
		if (item.transactions_type === 'sell') {
			apxLoss =
				(item.entry_price - Number(values?.price)) * item.amount_asset_underlying -
				fee * Number(values?.price);
		}
	} else {
		apxLoss = undefined;
	}

	const handlePriceChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: price } = target;
		if (Number(price) || Number(price) === 0 || price === '') {
			setValues({
				...values,
				price,
			});
		}
	};

	const handleQuantityChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const { value: quantity } = target;
		if (Number(quantity) || Number(quantity) === 0 || quantity === '') {
			setValues({
				...values,
				quantity,
			});
		}
	};

	const percentButtonCountValue = (percentValue: number): number => {
		return percentValue / 100;
	};

	const handlePercentChange = (percent: number | number[]) => {
		if (!Array.isArray(percent)) {
			const value = percentButtonCountValue(Number(percent));
			const newQty =
				item?.amount_asset_underlying != null && Number(item?.amount_asset_underlying) * value;
			setValues(
				{
					...values,
					quantity: String(newQty),
					percent,
				},
				false,
			);
		}
	};

	const countOrder = (value: number) => {};

	const submitDisabled = !isValid || !values.price || !values.quantity || isSubmitting;

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup">
					<button type="button" className="popup__close" onClick={handleClose}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
								fill="#1C1C29"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
								fill="#1C1C29"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title">
							{String(L.translate('Trade.Options.modals.limit_closing'))}
						</p>
					</div>
					{isSubmitting ? (
						<div className="popup-loader">
							<Loader />
						</div>
					) : (
						<div className="popup-body">
							<div className="flex-column">
								<div className="limit-closing-title upper-case">
									<p>{item.contract_name}</p>
								</div>
								<div className="input">
									<div className="input-wrapper">
										<input
											value={values.price}
											onChange={handlePriceChange}
											className="input-item input-item--pr50"
											type="text"
											placeholder="Price"
										/>
										<div className="input-item-info flex flex--gap8 flex-m">
											<p>USDT</p>
										</div>
									</div>
									<span className="input-notify">{errors?.price}</span>
								</div>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="input-item input-item--pr50"
											value={values.quantity}
											onChange={handleQuantityChange}
											type="text"
											placeholder="Quantity"
										/>
										<div className="input-item-info flex flex--gap8 flex-m">
											<p>BTC</p>
										</div>
									</div>
									<span className="input-notify">{errors?.quantity}</span>
								</div>
								<PersentSlider
									className="persent-slider"
									value={values.percent}
									onChange={handlePercentChange}
									countOrder={countOrder}
									percentButtonCountValue={percentButtonCountValue}
								/>
								<div className="limit-closing-info">
									<p>
										Your position at {values.quantity || '--'} BTC will be closed at{' '}
										{values.price || '--'}.
									</p>
									<p>Your approximate loss is {apxLoss != null ? apxLoss : '--'} USDC.</p>
								</div>
								<div className="pop-line" />
								<div className="info-block">
									<svg
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M3.66583 3.6668C5.2863 2.04635 7.52674 1.04297 10.0003 1.04297C12.4739 1.04297 14.7144 2.04635 16.3348 3.6668L15.8929 4.10874L16.3348 3.6668C17.9553 5.28728 18.9587 7.52772 18.9587 10.0013C18.9587 12.4749 17.9553 14.7153 16.3348 16.3358C14.7144 17.9562 12.4739 18.9596 10.0003 18.9596C7.52674 18.9596 5.2863 17.9562 3.66583 16.3358L4.10777 15.8938L3.66582 16.3358C2.04537 14.7153 1.04199 12.4749 1.04199 10.0013C1.04199 7.52772 2.04537 5.28728 3.66582 3.66681L3.66583 3.6668ZM10.0003 2.29297C7.87158 2.29297 5.94535 3.15507 4.54971 4.55069C3.15409 5.94633 2.29199 7.87255 2.29199 10.0013C2.29199 12.1301 3.15409 14.0563 4.54971 15.4519C5.94535 16.8475 7.87158 17.7096 10.0003 17.7096C12.1291 17.7096 14.0553 16.8475 15.4509 15.4519C16.8466 14.0563 17.7087 12.13 17.7087 10.0013C17.7087 7.87256 16.8466 5.94633 15.4509 4.55069C14.0553 3.15507 12.1291 2.29297 10.0003 2.29297Z"
											fill="#2D9CDB"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M9.99967 4.58203C10.575 4.58203 11.0413 5.04841 11.0413 5.6237C11.0413 6.19899 10.575 6.66536 9.99967 6.66536C9.42438 6.66536 8.95801 6.19899 8.95801 5.6237C8.95801 5.04841 9.42438 4.58203 9.99967 4.58203Z"
											fill="#2D9CDB"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M8.75 8.33203C8.75 7.98685 9.02982 7.70703 9.375 7.70703H10.2083C10.5535 7.70703 10.8333 7.98685 10.8333 8.33203V14.1654C10.8333 14.5105 10.5535 14.7904 10.2083 14.7904C9.86316 14.7904 9.58333 14.5105 9.58333 14.1654V8.95703H9.375C9.02982 8.95703 8.75 8.67721 8.75 8.33203Z"
											fill="#2D9CDB"
										/>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M8.125 14.168C8.125 13.8228 8.40482 13.543 8.75 13.543H11.6667C12.0118 13.543 12.2917 13.8228 12.2917 14.168C12.2917 14.5131 12.0118 14.793 11.6667 14.793H8.75C8.40482 14.793 8.125 14.5131 8.125 14.168Z"
											fill="#2D9CDB"
										/>
									</svg>
									<p>{String(L.translate('Trade.Options.positionCloseModal.notification'))}</p>
								</div>
							</div>
						</div>
					)}
					<div className="popup-footer popup-footer--item2">
						<button
							type="button"
							className="button button--type3 button--size2 button--full-width"
							onClick={handleClose}
						>
							{String(L.translate('Trade.Options.modals.cancel'))}
						</button>
						<button
							type="submit"
							className="button button--size2 button--full-width"
							disabled={submitDisabled}
						>
							{String(L.translate('Trade.Options.modals.confirm'))}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PositionCloseModalContent;
