/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import LimitOrder from '../LimitOrder';
import MarketOrder from '../MarketOrder';
import StopLimitOrder from '../StopLimitOrder';
import { ISwitchOrderTypeProps } from './types';

const SwitchOrderType: FC<ISwitchOrderTypeProps> = ({ orderType, mode, asset, marketType }) => {
	const {
		buy: { balance: buyBalance, code: buyCode },
		sell: { balance: sellBalance, code: sellCode },
	} = asset;

	const getSafeBalance = (balance: number) => {
		return (balance / 100) * 99.999995;
	};

	const balance = mode === 'Buy' ? getSafeBalance(buyBalance) : getSafeBalance(sellBalance);

	const limitOrder = (
		<LimitOrder
			assetToTradeCode={sellCode}
			assetBalanceCode={buyCode}
			balance={balance}
			mode={mode}
			marketType={marketType}
		/>
	);

	const marketOrder = (
		<MarketOrder
			assetToTradeCode={sellCode}
			assetBalanceCode={buyCode}
			balance={balance}
			mode={mode}
			marketType={marketType}
		/>
	);

	const stopLimitOrder = (
		<StopLimitOrder
			assetToTradeCode={sellCode}
			assetBalanceCode={buyCode}
			balance={balance}
			mode={mode}
			marketType={marketType}
		/>
	);

	switch (orderType) {
		case 'limit_order':
			return limitOrder;
		case 'market_order':
			return marketOrder;
		case 'stop_limit_order':
			return stopLimitOrder;

		default:
			return limitOrder;
	}
};

export default SwitchOrderType;
