import { FC, useState, useMemo } from 'react';
import L from 'i18n-react';
import { api } from 'services';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { notificationContainer } from 'services/utils/notificationContainer';
import ConfirmDeletePopup from 'ui/ConfirmDeletePopup';
import { IStopOrderTabItem } from './types';

const StopOrderTabItem: FC<IStopOrderTabItem> = ({ item }) => {
	const [openModal, setOpenModal] = useState(false);

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const closeModal = () => {
		setOpenModal(false);
	};

	const handleCloseOrder = async () => {
		try {
			await api.perpetualTrade.cancelOrder({
				order_future_id: item.id,
			});
			notificationContainer(
				String(L.translate('Trade.Perpetual.messages.order_was_successfully_cancelled')),
				'success',
			);
		} catch (error) {
			notificationContainer(
				String(L.translate('Trade.Perpetual.messages.failed_to_cancel_order')),
				'error',
			);
			console.error(error);
		}
	};

	let side =
		item.transactions_type === 'buy'
			? String(L.translate('Trade.Perpetual.long'))
			: String(L.translate('Trade.Perpetual.short'));

	side = side[0] + side.slice(1);

	if (item.type_order) {
		side = `${side}/${item.type_order === 'limit' ? 'Limit' : 'Market'}`;
	}

	if (item.take_profit || item.stop_loss) {
		side = `${side}${item.take_profit ? 'Take-Profit' : 'Stop-Loss'}`;
	}

	let triggerPrice =
		item.trigger_by_type_price &&
		// eslint-disable-next-line no-unsafe-optional-chaining
		String(item.trigger_by_type_price[0]?.toUpperCase()) +
			String(item.trigger_by_type_price.slice(1));

	triggerPrice = triggerPrice ? triggerPrice.replace('_', ' ') : '';

	return (
		<div className="tr">
			<div className="td">
				{/* Contract */}
				<p>{item.base_asset?.toUpperCase()}/USDT</p>
			</div>
			<div className="td">
				{/* ROLE/ID */}
				<p>{item.id}</p>
			</div>
			<div className="td">
				{/* Creared At */}
				<p>{item.created_at && customDateFormat(item.created_at)}</p>
			</div>
			<div className="td">
				{/* Side */}
				<p>{side}</p>
			</div>
			<div className="td">
				{/* Trigger Price */}
				<p>{triggerPrice}</p>
			</div>
			<div className="td">
				{/* Order Price */}
				<p>{item.price_entry_for_order}</p>
			</div>
			<div className="td">
				{/* Size */}
				<p>{item.amount_asset_request}</p>
			</div>
			<div className="td">
				{/* Fill Price */}
				<p>{item.price_entry_for_order}</p>
			</div>
			<div className="td td--right">
				<button className="button button--type-text fw-700" type="button" onClick={handleOpenModal}>
					Cancel
				</button>
			</div>
			<ConfirmDeletePopup
				title={String(L.translate('Trade.Perpetual.messages.confirm_close_order'))}
				bodyMessage={String(
					L.translate('Trade.Perpetual.messages.confirm_close_order_body_message'),
				)}
				openModal={openModal}
				closeModal={closeModal}
				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				handleDelete={handleCloseOrder}
			/>
		</div>
	);
};
export default StopOrderTabItem;
