/* eslint-disable no-debugger */
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import L from 'i18n-react';
import { Formik, Form } from 'formik';
import Portal from 'ui/Portal';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { api } from 'services';
import yup from 'services/utils/capsLockValidate';
import { notificationContainer } from 'services/utils/notificationContainer';
import PositionCloseModalContent from './PositionCloseModalContent';
import { IPositionCloseModal } from './types';

const PositionCloseModal: FC<IPositionCloseModal> = ({ item, avgPrice, onClose }) => {
	const isAuth = useSelector(getAuthIsAuthenticated);
	const history = useHistory();
	const initialValues = {
		price: item.entry_price || item.entry_price === 0 ? String(item.entry_price) : '',
		quantity:
			item.amount_asset_underlying || item.amount_asset_underlying === 0
				? String(item.amount_asset_underlying)
				: '',
		percent: 100,
	};

	const validationSchema = yup.object().shape({
		price: yup
			.number()
			.typeError(String(L.translate('Trade.Options.messages.price_must_be_a_number')))
			.required(String(L.translate('Trade.Options.messages.price_is_required'))),
		quantity: yup
			.number()
			.min(
				0,
				String(
					L.translate('Wallets.MarginAccount.messages.amount_not_less_than', {
						minAmount: 0,
					}),
				),
			)
			.max(
				Number(item.amount_asset_underlying),
				String(
					L.translate('Wallets.MarginAccount.messages.amount_not_greater_than', {
						maxAmount: String(Number(item.amount_asset_underlying)),
					}),
				),
			)
			.typeError(String(L.translate('Trade.Options.messages.quantity_must_be_a_number')))
			.required(String(L.translate('Trade.Options.messages.quantity_is_required'))),
	});

	return (
		<Portal>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				validateOnBlur
				onSubmit={async (values, { resetForm, setSubmitting }) => {
					if (!isAuth) {
						history.push('/login');
						return;
					}
					try {
						setSubmitting(true);
						await api.optionsTrade.closePosition({
							option_position_id: item.id,
							price_entry_for_order: Number(values.price),
							amount_asset_request: Number(values.quantity),
						});
						notificationContainer(
							`You placed ${values.quantity} ${item?.contract_name || ''} ${
								item.transactions_type
									? `${item.transactions_type?.[0]?.toUpperCase()}${item.transactions_type?.slice(
											1,
									  )}`
									: ''
							} orders at ${values.price}. All orders filled`,
							'success',
							{
								title: 'Order(s) submitted successfully!',
							},
						);
						setSubmitting(false);
						onClose && onClose();
					} catch (err) {
						notificationContainer(
							String(L.translate('Trade.Options.messages.failed_to_close_position')),
							'error',
						);
						console.error(err);
						setSubmitting(false);
					}
				}}
			>
				<Form>
					<PositionCloseModalContent item={item} avgPrice={avgPrice} onClose={onClose} />
				</Form>
			</Formik>
		</Portal>
	);
};

export default PositionCloseModal;
