import { FC } from 'react';
import L from 'i18n-react';
import { Formik, Form } from 'formik';
import { api } from 'services';
import yup from 'services/utils/capsLockValidate';
import { notificationContainer } from 'services/utils/notificationContainer';
import PositionPtslForm, { FormValues } from './PositionPtslForm';
import { PriceType } from './PriceTypeSelect';
import { IPositionPTSL } from './types';

const initialValues = {
	tpPriceType: PriceType.LiquidationPrice,
	slPriceType: PriceType.LiquidationPrice,
	tpPrice: '',
	slPrice: '',
};

const PositionPTSLPopup: FC<IPositionPTSL> = ({ item, markPrice, onClose }) => {
	const validationSchema = yup.object().shape({
		tpPriceType: yup
			.string()
			.required(String(L.translate('Trade.Perpetual.messages.price_type_is_required'))),
		slPriceType: yup
			.string()
			.required(String(L.translate('Trade.Perpetual.messages.price_type_is_required'))),
		tpPrice: yup
			.number()
			.typeError(String(L.translate('Trade.Perpetual.messages.tp_price_must_be_a_number')))
			.required(String(L.translate('Trade.Perpetual.messages.tp_price_is_required'))),
		slPrice: yup
			.number()
			.typeError(String(L.translate('Trade.Perpetual.messages.sl_price_must_be_a_number')))
			.required(String(L.translate('Trade.Perpetual.messages.sl_price_is_required'))),
	});

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup">
					<button className="popup__close" type="button" onClick={onClose}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
								fill="#1C1C29"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
								fill="#1C1C29"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title">
							{String(L.translate('Trade.Perpetual.Positions.sl_tp_title'))}
						</p>
					</div>

					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={async (values: FormValues, { resetForm, setSubmitting }) => {
							setSubmitting(true);
							try {
								await api.perpetualTrade.closePositionByTpSl({
									future_position_id: item.id,
									take_profit: Number(values.tpPrice),
									stop_loss: Number(values.slPrice),
								});
								resetForm();
								onClose && onClose();
								notificationContainer(
									String(L.translate('Trade.Perpetual.messages.successfully_closed')),
									'success',
								);
							} catch (error) {
								notificationContainer(
									String(L.translate('Trade.Perpetual.messages.failed_to_close_position')),
									'error',
								);
								console.error(error);
							} finally {
								setSubmitting(false);
							}
						}}
						// validateOnBlur
						// enableReinitialize
					>
						<Form>
							<PositionPtslForm item={item} markPrice={markPrice} onClose={onClose} />
						</Form>
					</Formik>
				</div>
			</div>
		</div>
	);
};
export default PositionPTSLPopup;
