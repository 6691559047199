import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCreateOrder } from 'redux/reducers/options/selectors';
import Trading from 'layouts/Trading';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';
import OptionsHeader from 'components/Trade/OptionTrades/OptionHeader';
import TradeTables from 'components/Trade/OptionTrades/TradeTables';
import ListTables from 'components/Trade/OptionTrades/ListTables/ListTables';
import { updateCreateOrder, getOrdersListRequest } from 'redux/reducers/options/reducer';
import { getActiveBaseAssetRequest } from 'redux/reducers/perpetual/reducer';
import BuySellModal from 'components/Trade/OptionTrades/Modals/BuySellModal';

const OptionsTradingPage: FC = () => {
	const dispatch = useDispatch();
	const order = useSelector(getCreateOrder);
	const [showModal, setShowModal] = useState<boolean>(false);

	useEffect(() => {
		dispatch(updateCreateOrder(null));
		dispatch(getActiveBaseAssetRequest({}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (order !== null) {
			return setShowModal(true);
		}
		return setShowModal(false);
	}, [showModal, order]);

	useEffect(() => {
		dispatch(getOrdersListRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Trading title="Options trading">
			<section className="options-section section-gradient">
				<div className="options">
					<SocketWithPrivateTopicConnectWrapper topicName="option_account_im_mm_by_user:option">
						<OptionsHeader />
					</SocketWithPrivateTopicConnectWrapper>
					<SocketWithPrivateTopicConnectWrapper topicName="all_options_statistic:option">
						<TradeTables />
						<ListTables />
					</SocketWithPrivateTopicConnectWrapper>
				</div>
			</section>
			{showModal && <BuySellModal />}
		</Trading>
	);
};

export default OptionsTradingPage;
