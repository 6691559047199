import React, { useState, FC, useMemo } from 'react';
import L from 'i18n-react';
import { ETypeOption, IOptionsListItem, IOptionsTableRow } from 'redux/reducers/options/types';
import { transformFirstLetterToCapitalize } from 'services/utils/capitalize';
import {
	convertMonthNumberToAbbreviatedMonthName,
	getTimeToDate,
} from 'services/utils/dateAndTimeHelpers';
import TableRow from './TableRow/TableRow';

const ListTable: FC<IOptionsListItem> = ({ options_consolidate, options_by_date }) => {
	const optionConsolidate = { ...options_consolidate[0] };
	const optionsByDate = useMemo(() => [...options_by_date.options_positions], [options_by_date]);
	const nameExpiresCapitalized = transformFirstLetterToCapitalize(
		optionConsolidate.name_expires || '',
	);
	const expiresDateToView = () => {
		if (optionConsolidate.date_expires) {
			const dateArr = optionConsolidate.date_expires.split('%');
			const day = dateArr[3].slice(0, 2);
			const year = dateArr[1].slice(2, 4);
			const monthName = convertMonthNumberToAbbreviatedMonthName(
				Number(dateArr[2].slice(0, dateArr[2].length - 1)),
			);
			return `${day} ${String(monthName.toUpperCase())} ${year}`;
		}
		return '';
	};

	const timeToExpiresDate = getTimeToDate(
		optionConsolidate?.date_expires ? new Date(optionConsolidate?.date_expires) : new Date(),
	);

	const [active, setActive] = useState(false);
	const toggleDropdown = () => {
		setActive(!active);
	};

	const filterOptionsByDate = useMemo(() => {
		const donorArr = [...optionsByDate];
		const newArr: IOptionsTableRow[] = [];
		if (optionsByDate) {
			// eslint-disable-next-line no-plusplus
			for (let i = 0; donorArr.length > 1; i++) {
				const secondElementIndex = donorArr.findIndex(
					(el) => el.opposite_option_uuid === donorArr[0].opposite_option_uuid,
				);

				const firstElement = donorArr.splice(0, 1)[0];
				const secondElement = donorArr.splice(secondElementIndex, 1)[0];

				if (firstElement.type_option === ETypeOption.CALL) {
					newArr.push({
						options_call: firstElement,
						options_put: secondElement,
					});
				} else {
					newArr.push({
						options_call: secondElement,
						options_put: firstElement,
					});
				}
			}
		}
		return newArr;
	}, [optionsByDate]);

	const optionName = optionConsolidate.contract_name.split('-').slice(0, 2).join('-').toUpperCase();

	return (
		<div className="options-item">
			<div className="options-item-header">
				<div className="options-item-header__item">
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.55806 4.55806C4.80214 4.31398 5.19786 4.31398 5.44194 4.55806L10.4419 9.55806C10.686 9.80214 10.686 10.1979 10.4419 10.4419L5.44194 15.4419C5.19786 15.686 4.80214 15.686 4.55806 15.4419C4.31398 15.1979 4.31398 14.8021 4.55806 14.5581L9.11612 10L4.55806 5.44194C4.31398 5.19786 4.31398 4.80214 4.55806 4.55806Z"
							fill="#7C7CA6"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M9.55806 4.55806C9.80214 4.31398 10.1979 4.31398 10.4419 4.55806L15.4419 9.55806C15.686 9.80214 15.686 10.1979 15.4419 10.4419L10.4419 15.4419C10.1979 15.686 9.80214 15.686 9.55806 15.4419C9.31398 15.1979 9.31398 14.8021 9.55806 14.5581L14.1161 10L9.55806 5.44194C9.31398 5.19786 9.31398 4.80214 9.55806 4.55806Z"
							fill="#7C7CA6"
						/>
					</svg>
					<button type="button" onClick={toggleDropdown}>
						<svg
							className="options-svg-open active"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M7.47505 4.55806C7.71913 4.31398 8.11486 4.31398 8.35893 4.55806L13.3589 9.55806C13.603 9.80214 13.603 10.1979 13.3589 10.4419L8.35893 15.4419C8.11486 15.686 7.71913 15.686 7.47505 15.4419C7.23097 15.1979 7.23097 14.8021 7.47505 14.5581L12.0331 10L7.47505 5.44194C7.23097 5.19786 7.23097 4.80214 7.47505 4.55806Z"
								fill="#7C7CA6"
							/>
						</svg>
					</button>
					<p>
						{expiresDateToView()} ({timeToExpiresDate}) ({nameExpiresCapitalized})
					</p>
				</div>
				<div className="options-item-header__item fw-700">
					<p>{expiresDateToView()}</p>
				</div>
				<div className="options-item-header__item">
					{/* <p>{optionConsolidate.contract_name.toUpperCase()} ($0)</p> */}
					<p>{optionName} ($0)</p>
				</div>
			</div>
			<div className={`options-item-drop ${active ? 'active' : ''}`}>
				<div className="options-item-drop__info">
					<p>{String(L.translate('Trade.Options.Table.calls'))}</p>
					<p>{String(L.translate('Trade.Options.Table.puts'))}</p>
				</div>

				<div className="table table--wallet table--options-drop">
					<div className="table-header">
						<div className="tr">
							<div className="tr-level2">
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.open'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.delta'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.bid_size'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.bid'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.mark_price'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.ask'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.ask_size'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.position'))}</p>
								</div>
							</div>

							<div className="tr-level2 tr-level2--center">
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.strike'))}</p>
								</div>
							</div>

							<div className="tr-level2 tr-level2--right">
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.position'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.bid_size'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.bid'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.mark_price'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.ask'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.ask_size'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.delta'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Trade.Options.Table.columns.open'))}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="table-body">
						{!!filterOptionsByDate.length &&
							filterOptionsByDate.map((el) => (
								<TableRow key={el.options_put.opposite_option_uuid} {...el} />
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ListTable;
