import { FC, useEffect, ReactNode } from 'react';
import L from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import {
	getTransactions,
	getTransactionsLoader,
	getBaseAsset,
} from 'redux/reducers/perpetual/selectors';
import { getTransactionsRequest } from 'redux/reducers/perpetual/reducer';
import { ITransactionItem } from 'redux/reducers/perpetual/types';
import Loader from 'ui/Loader';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import Pagination from 'ui/Pagination';
import usePagination from 'hooks/usePagination';
import LoginTradeTablePlug from '../TradeTablePlug';
import TransactionsTabItem from './TransactionsTabItem';
import { ITransactionsTab } from './types';

const TransactionsTab: FC<ITransactionsTab> = ({ showAll }) => {
	const dispatch = useDispatch();
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const transactions = useSelector(getTransactions);
	const loader = useSelector(getTransactionsLoader);
	// const tansactions = TRANSACTIONS;
	const baseAsset = useSelector(getBaseAsset);

	const { perPage, currentPage } = usePagination();

	useEffect(() => {
		if (baseAsset?.base_asset) {
			dispatch(
				getTransactionsRequest({
					is_show_all_asset: showAll ? 1 : 0,
					base_asset: baseAsset.base_asset,
					per_page: perPage,
					current_page: currentPage,
				}),
			);
		}
	}, [baseAsset?.base_asset, currentPage, dispatch, perPage, showAll]);

	const renderContent: () => ReactNode = () => {
		if (!authIsAuthenticated) {
			return <LoginTradeTablePlug />;
		}
		if (loader) {
			return (
				<div className="popup-loader">
					<Loader />
				</div>
			);
		}
		if (transactions && transactions?.data?.length > 0) {
			return transactions?.data?.map((item: ITransactionItem) => (
				<TransactionsTabItem key={item.id} item={item} />
			));
		}
		return <TableEmptyPlug />;
	};

	return (
		<div className="table table--trade table--trade-perpetual-transactions">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Tables.time'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Tables.asset'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Tables.action_type'))}</p>
					</div>
					<div className="td">
						<p>{String(L.translate('Trade.Perpetual.Tables.amount_my_funds'))}</p>
					</div>
					<div className="td td--right">
						<p>{String(L.translate('Trade.Perpetual.Tables.remarks'))}</p>
					</div>
				</div>
			</div>
			<div className="table-body">{renderContent()}</div>
			{transactions?.last_page && Number(transactions?.total) > Number(transactions?.per_page) && (
				<Pagination lastPage={transactions?.last_page} getCurrentHref={(s) => String(s)} />
			)}
		</div>
	);
};

export default TransactionsTab;
