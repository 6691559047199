import { FC } from 'react';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { ITradeHistoryTableRow } from './types';

const TradeHistoryTableRow: FC<ITradeHistoryTableRow> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td">
				<p className="grey">{item.created_at && customDateFormat(item.created_at)}</p>
			</div>
			<div className="td">
				<p>{item?.pair && item?.pair.replace('_', '/')?.toUpperCase()}</p>
			</div>
			<div className="td order-type-cell">
				<p>{item.type}</p>
			</div>
			<div className="td">
				<p>{item.price}</p>
			</div>
			<div className="td">
				<p>{item.executed}</p>
			</div>
			<div className="td">
				<p>{Number(item.fee)?.toFixed(8)}</p>
			</div>
			<div className="td td--right">
				<p>{Number(item.total)?.toFixed(8)}</p>
			</div>
		</div>
	);
};

export default TradeHistoryTableRow;
