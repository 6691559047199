import { FC, useMemo, useState, useEffect } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getAssetPairsList } from 'redux/reducers/assetPairs/selectors';
import { getSpotRecentTrades } from 'redux/reducers/spotTrade/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { IAssetPairsItem } from 'redux/reducers/assetPairs/types';
import {
	getFirstPartPairCode,
	getLastPartPairCode,
	transformPairCode,
} from 'services/utils/tradingPairHelpers';
import useDecimals from 'hooks/useDecimals';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
// import SpotMarketSettingsModal from '../SpotMarketSettingsModal';

/* eslint-disable no-debugger */

const SpotMarketHeader: FC = () => {
	const [openModal, setOpenModal] = useState(false);
	const [currentAssetPairData, setCurrentAssetPairData] = useState<IAssetPairsItem | null>(null);
	const closeModal = () => setOpenModal(false);

	const currentPair = useSelector(getCurrentPair);
	const assetPairsList = useSelector(getAssetPairsList);
	const recentTrades = useSelector(getSpotRecentTrades);

	const [priceDecimal] = useDecimals();

	const firstRecentTradeItem = recentTrades?.length && recentTrades[0]?.price;
	const secondRecentTradesItem = recentTrades?.length && recentTrades[1]?.price;

	const lastRecentTradeIsLow = Number(firstRecentTradeItem) < Number(secondRecentTradesItem);

	useEffect(() => {
		const currAssetPair = assetPairsList?.find((el) => el.code === currentPair.pair);
		setCurrentAssetPairData(
			currAssetPair
				? {
						...currAssetPair,
						last_price_usd:
							// eslint-disable-next-line eqeqeq
							currAssetPair?.last_price_usd != undefined
								? currAssetPair?.last_price_usd
								: currentAssetPairData?.last_price_usd || 0,
				  }
				: null,
		);
	}, [assetPairsList, currentAssetPairData?.last_price_usd, currentPair.pair]);

	const getMarketDynamicValueColorClass = (value: boolean): string => {
		switch (value) {
			case true:
				return 'trade-header-price__change red';

			case false:
				return 'trade-header-price__change green';

			default:
				return 'trade-header-price__change';
		}
	};

	const getPlusOrmMinus = (isValue: boolean): string => (isValue ? '+' : '');

	return currentAssetPairData ? (
		<div className="trade-header">
			<div className="trade-header-item">
				<div className="select-block">
					<div className="select select--trade-header">
						<button type="button" className="select__current">
							<span className="trade-select-currensy">
								<span className="trade-select-currensy__title">
									{transformPairCode(currentAssetPairData?.code || '')}
								</span>
								<span className="trade-select-currensy__name">
									{currentAssetPairData?.name || ''}
								</span>
							</span>
						</button>
					</div>
				</div>
			</div>
			<div className="trade-header-item flex">
				<div className="trade-header-price">
					<p className={getMarketDynamicValueColorClass(lastRecentTradeIsLow)}>
						<span className="trade-header-price__change-icon">
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12.0039 5.80029C12.4181 5.80029 12.7539 6.13608 12.7539 6.55029V18.5C12.7539 18.9143 12.4181 19.25 12.0039 19.25C11.5897 19.25 11.2539 18.9143 11.2539 18.5V6.55029C11.2539 6.13608 11.5897 5.80029 12.0039 5.80029Z"
									fill="#219653"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M11.4697 5.96967C11.7626 5.67678 12.2374 5.67678 12.5303 5.96967L18.5303 11.9697C18.8232 12.2626 18.8232 12.7374 18.5303 13.0303C18.2374 13.3232 17.7626 13.3232 17.4697 13.0303L12 7.56066L6.53033 13.0303C6.23744 13.3232 5.76256 13.3232 5.46967 13.0303C5.17678 12.7374 5.17678 12.2626 5.46967 11.9697L11.4697 5.96967Z"
									fill="#219653"
								/>
							</svg>
						</span>
						{fixedCropNumber(Number(firstRecentTradeItem), priceDecimal) || firstRecentTradeItem}
					</p>
					<p className="trade-header-price__price">
						$ {Number(currentAssetPairData?.last_price_usd || 0).toFixed(2)}
					</p>
				</div>
				<div
					className={`trade-header-info ${
						currentAssetPairData?.change24 && currentAssetPairData?.change24 > 0 ? 'green' : 'red'
					}`}
				>
					<p>{String(L.translate('Trade.Spot.SpotMarketHeader.change_24_text'))}</p>
					<span
						className={
							currentAssetPairData?.change24 && currentAssetPairData.change24 > 0 ? 'green' : 'red'
						}
					>
						{convertExponentialToDecimal(Number(currentAssetPairData?.change24_value)) ||
							currentAssetPairData?.change24_value}{' '}
						{getPlusOrmMinus(currentAssetPairData?.change24 > 0)}
						{currentAssetPairData?.change24}%
					</span>
				</div>
			</div>
			<div className="trade-header-item">
				<div className="trade-header-info">
					<p>{String(L.translate('Trade.Spot.SpotMarketHeader.high_24_text'))}</p>
					<span>{currentAssetPairData?.high24hr}</span>
				</div>
			</div>
			<div className="trade-header-item">
				<div className="trade-header-info">
					<p>{String(L.translate('Trade.Spot.SpotMarketHeader.low_24_text'))}</p>
					<span>{currentAssetPairData?.low24hr}</span>
				</div>
			</div>
			<div className="trade-header-item">
				<div className="trade-header-info">
					<p>
						{String(L.translate('Trade.Spot.SpotMarketHeader.volume_24_text'))}(
						{getFirstPartPairCode(currentAssetPairData?.code || '')})
					</p>
					<span>{currentAssetPairData?.volume24}</span>
				</div>
			</div>
			<div className="trade-header-item">
				<div className="trade-header-info">
					<p>
						{String(L.translate('Trade.Spot.SpotMarketHeader.volume_24_text'))}(
						{getLastPartPairCode(currentAssetPairData?.code || '')})
					</p>
					<span>{currentAssetPairData?.volumeQuote24}</span>
				</div>
			</div>
		</div>
	) : null;
};

export default SpotMarketHeader;
