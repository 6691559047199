const SwitchButton = ({ onSwitchClick }: any) => {
	return (
		<div className="convert-form-switch">
			<button className="button button--convert-switch" type="button" onClick={onSwitchClick}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M9.5 2.25C9.91421 2.25 10.25 2.58579 10.25 3V21C10.25 21.4142 9.91421 21.75 9.5 21.75C9.08579 21.75 8.75 21.4142 8.75 21V3C8.75 2.58579 9.08579 2.25 9.5 2.25Z"
						fill="white"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M10.0303 2.41938C10.3232 2.71227 10.3232 3.18714 10.0303 3.48004L4.03033 9.48004C3.73744 9.77293 3.26256 9.77294 2.96967 9.48004C2.67678 9.18715 2.67678 8.71228 2.96967 8.41938L8.96967 2.41938C9.26256 2.12648 9.73744 2.12648 10.0303 2.41938Z"
						fill="white"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M14.5 2.30029C14.9142 2.30029 15.25 2.63608 15.25 3.05029V21.0503C15.25 21.4645 14.9142 21.8003 14.5 21.8003C14.0858 21.8003 13.75 21.4645 13.75 21.0503V3.05029C13.75 2.63608 14.0858 2.30029 14.5 2.30029Z"
						fill="white"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M21.0303 14.52C21.3232 14.8129 21.3232 15.2877 21.0303 15.5806L15.0303 21.5806C14.7374 21.8735 14.2626 21.8735 13.9697 21.5806C13.6768 21.2877 13.6768 20.8129 13.9697 20.52L19.9697 14.52C20.2626 14.2271 20.7374 14.2271 21.0303 14.52Z"
						fill="white"
					/>
				</svg>
			</button>
		</div>
	);
};

export default SwitchButton;
