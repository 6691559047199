import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getOrdeBook } from 'redux/reducers/options/selectors';
import AskItem from './OrderBookItems/AskItem';
import BidItem from './OrderBookItems/BidItem';

const OrderBook = () => {
	const orderBook = useSelector(getOrdeBook);

	const bidSide = () => {
		const arr = orderBook?.bid.map((item: any) => item) || [];
		if (arr.length < 8) {
			for (let i = arr.length; i < 8; i += 1) {
				arr.push(null);
			}
		}
		return (
			arr.map((item: any, index: number) => {
				if (item === null) {
					// eslint-disable-next-line react/no-array-index-key
					return <BidItem key={index * Math.random()} size="-" price="-" total="-" />;
				}
				return (
					(
						<BidItem
							key={
								(Math.random() * item.amount_asset_request_left * item.amount_asset_request_left) /
								item.total
							}
							size={item.amount_asset_request_left}
							price={item.price_entry_for_order}
							total={item.total}
						/>
					) || null
				);
			}) || null
		);
	};

	const askSide = () => {
		const arr = orderBook?.ask.map((item: any) => item) || [];
		if (arr.length < 8) {
			for (let i = arr.length; i < 8; i += 1) {
				arr.push(null);
			}
		}
		return (
			arr.map((item: any, index: number) => {
				if (item === null) {
					// eslint-disable-next-line react/no-array-index-key
					return <AskItem key={index * Math.random()} size="-" price="-" total="-" />;
				}
				return (
					(
						<AskItem
							key={
								(Math.random() * item.amount_asset_request_left * item.amount_asset_request_left) /
								item.total
							}
							size={item.amount_asset_request_left}
							price={item.price_entry_for_order}
							total={item.total}
						/>
					) || null
				);
			}) || null
		);
	};
	return (
		<div className="table-options-order-book-wrap">
			<div className="table table--wallet table--options table--options-order-book">
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<p>{String(L.translate('Trade.Options.OrderForm.OrderBook.total'))}</p>
						</div>
						<div className="td">
							<p>{String(L.translate('Trade.Options.OrderForm.OrderBook.bid_size'))}</p>
						</div>
						<div className="td">
							<p>{String(L.translate('Trade.Options.OrderForm.OrderBook.bid_prise'))}</p>
						</div>
					</div>
				</div>
				<div className="table-body">
					<div className="tr-wrap orderbook-body-item__scroll options-orderbook--height">
						{bidSide()}
					</div>
				</div>
			</div>
			<div className="table table--wallet table--options table--options-order-book-type2">
				<div className="table-header">
					<div className="tr">
						<div className="td">
							<p>{String(L.translate('Trade.Options.OrderForm.OrderBook.ask_price'))}</p>
						</div>
						<div className="td">
							<p>{String(L.translate('Trade.Options.OrderForm.OrderBook.ask_size'))}</p>
						</div>
						<div className="td">
							<p>{String(L.translate('Trade.Options.OrderForm.OrderBook.total'))}</p>
						</div>
					</div>
				</div>
				<div className="table-body">
					<div className="tr-wrap orderbook-body-item__scroll options-orderbook--height">
						{askSide()}
					</div>
				</div>
			</div>
		</div>
	);
};
export default OrderBook;
