/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-debugger */
import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, put, race, select, take } from 'redux-saga/effects';
import { eventChannel, EventChannel } from 'redux-saga';
import L from 'i18n-react';
import { updateBarBySockets } from 'components/Chart/datafeed/stream';
import { WEB_SOCKETS_URL } from 'services/constants/env';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	IOpenOrdersData,
	IOpenOrdersUpdateResponseDataPayload,
	IOrderBookData,
	IRecentTradesItem,
	IUserTrades,
} from 'redux/reducers/spotTrade/types';
import {
	getTopAssetPairsSuccess,
	updateAssetPairsSuccess,
} from 'redux/reducers/assetPairs/reducer';
import {
	getOpenOrdersRequest,
	getOpenOrdersSuccess,
	getOrdersHistoryRequest,
	updateCloseOrdersSuccess,
	updateOpenOrdersSuccess,
	updateOrderBookSuccess,
	updateRecentTradesSuccess,
	updateUserTradesCurrentPairSuccess,
	updateUserTradesSuccess,
} from 'redux/reducers/spotTrade/reducer';
import {
	getSpotRecentTrades,
	getSpotUserOpenOrders,
	getSpotUserOrdersHistory,
	getSpotUserTrades,
	getSpotUserTradesCurrentPair,
} from 'redux/reducers/spotTrade/selectors';

import {
	IAssetPairsData,
	IAssetTopPairsItem,
	IDerivativeWallet,
} from 'redux/reducers/assetPairs/types';
import { IWalletItem, IWalletsData, IWalletsResponsePayload } from 'redux/reducers/wallets/types';
import { updateWalletsSuccess } from 'redux/reducers/wallets/reducer';
import { ETradeType } from '../spotTrade/types';
import { getTradeType } from '../spotTrade/selectors';
// import {
// 	updateCrossWalletsSuccess,
// 	updateIsolatedWalletsSuccess,
// 	updateMarginIndexPricesSuccess,
// } from 'bitanica-frontend/src/redux/reducers/marginWallets/reducer';
// import {
// 	IMarginCrossWalletsData,
// 	IMarginIsolatedWalletsData,
// 	IIndexPrices,
// } from 'bitanica-frontend/src/redux/reducers/marginWallets/types';
import {
	IOptionsAll,
	IOptionsStatistics,
	IPrivateNotificationsData,
	IRecentTradesData,
	IRecentTradesResponse,
	IUserImMm,
	SocketsResponseData,
} from './types';
import {
	setSocketsConnect,
	setSocketsDisconnect,
	socketClosedConnection,
	socketOpenConnection,
	socketSendMessage,
} from './reducer';
import {
	getAvailableBalanceSuccess,
	getBaseAssetStatisticsSuccess,
	getMyOrdersFromSocketsSuccess,
	getOrderHistoryFromSocketsSuccess,
	getPerpOrderBookSuccess,
	getRecentPerpetualTradesSuccess,
	setBaseAsset,
} from '../perpetual/reducer';
import { getBaseAsset } from '../perpetual/selectors';
import {
	receiveLastPrice,
	setOptionStatistics,
	setOrderBook,
	setRecentTrades,
	setUserImMm,
	updateActiveOrdersOnCancel,
	updateActiveOrdersOnOpen,
	updateOrderList,
} from '../options/reducer';
import { IActiveOrders } from '../options/types';
import { getWalletsList } from '../wallets/selectors';
import { getIsolatedWalletsList } from '../marginWallets/selectors';
import { IMarginIsolatedWallets, IWalletsIsolatedDataType } from '../marginWallets/types';
import { getMarginIsolatedWalletsSuccess } from '../marginWallets/reducer';
import { convertToFixed } from '../../../services/utils/convertToFixed';

export const socketConnection = (socketToken: string | null) => {
	return new Promise((resolve, reject) => {
		let socket: WebSocket;
		if (socketToken) {
			socket = new WebSocket(`${String(WEB_SOCKETS_URL)}/?${socketToken}`, ['wamp']);
		} else {
			socket = new WebSocket(`${String(WEB_SOCKETS_URL)}`, ['wamp']);
		}

		socket.onopen = () => {
			resolve(socket);
			// console.log('Connection open...');
		};
		socket.onerror = (event) => {
			reject(event);
		};
		socket.onclose = (event) => {
			if (event.wasClean) {
				// console.log('Connection closed...');
			} else {
				// console.log('Lost connection...');
			}
		};
	});
};

export const socketChannel = (socketValue: WebSocket) => {
	const socket = socketValue;
	return eventChannel((emiter) => {
		socket.onmessage = ({ data }) => {
			emiter(JSON.parse(data));
		};
		return () => {
			socket.close();
		};
	});
};

function* socketSend(socket: WebSocket) {
	const isOpenSocket = socket.readyState === socket.OPEN;
	if (isOpenSocket) {
		while (true) {
			const { payload }: { payload: PayloadAction } = yield take(socketSendMessage.type);
			socket.send(JSON.stringify(payload));
		}
	}
}

function* socketClose(socket: WebSocket) {
	while (true) {
		yield take(socketClosedConnection.type);
		yield put(setSocketsDisconnect());

		socket.close();
	}
}

function* socketOnMessage(channel: EventChannel<SocketsResponseData>) {
	while (true) {
		const data: SocketsResponseData = yield take(channel);
		if (+data[0] === 8) {
			const asset: { base_asset: string } = yield select(getBaseAsset);
			const walletsList: IWalletsData = yield select(getWalletsList);
			const isolatedWallets: IMarginIsolatedWallets | null = yield select(getIsolatedWalletsList);

			switch (data[1].split(':')[0]) {
				case 'option_account_im_mm_by_user':
					const accountImMm: IUserImMm = data[2]?.data as IUserImMm | any;
					yield put(setUserImMm(accountImMm.data));
					break;
				case 'header_option_statistic':
					const statistics: IOptionsStatistics = data[2]?.data as IOptionsStatistics | any;
					yield put(setOptionStatistics(statistics.statistics));
					break;
				case 'option_recent_trades':
					const trades: IRecentTradesData = data[2]?.data as IRecentTradesData | any;
					yield put(setRecentTrades(trades.data));
					break;
				case 'derivative_wallet':
					const derivativeWalletData: IDerivativeWallet = data[2]?.data as IDerivativeWallet | any;
					yield put(
						getAvailableBalanceSuccess({
							available_balance_usdt: derivativeWalletData?.wallet?.available_balance_usdt || 0,
						}),
					);
					break;
				case 'opened_user_orders_future':
					const openUserOrders = data[2].data;
					yield put(getMyOrdersFromSocketsSuccess(openUserOrders));
					break;
				case 'closed_user_orders_future':
					const closedUserOrders = data[2].data;
					yield put(getOrderHistoryFromSocketsSuccess(closedUserOrders));
					break;
				case 'future_recent_trades':
					const recentFuturesTradesData: any = data[2].data;
					yield put(
						getRecentPerpetualTradesSuccess(
							recentFuturesTradesData?.data[asset?.base_asset || 'btc'],
						),
					);
					break;
				case 'all_options_statistic':
					if (data[1].split(':')[1] === 'option') {
						const optionsAllData: IOptionsAll = data[2]?.data as IOptionsAll;
						yield put(updateOrderList(optionsAllData.statistics));
						yield put(receiveLastPrice(optionsAllData?.statistics?.index_price_btc_spot));
					}
					break;
				case 'assets_pairs':
					const assetPairsUpdatedData = data[2].data as IAssetPairsData | any;
					if (data[1].split(':')[1] === 'future') {
						yield put(getBaseAssetStatisticsSuccess(assetPairsUpdatedData.assetPairsInfo));

						const newBaseAssetData = assetPairsUpdatedData.assetPairsInfo.find(
							(item: any) => item?.base_asset === (asset?.base_asset || 'btc'),
						);
						yield put(setBaseAsset(newBaseAssetData));
						break;
					}
					yield put(updateAssetPairsSuccess(assetPairsUpdatedData?.assetPairsInfo || []));
					break;
				case 'order_book':
					const orderBookUpdatedData = data[2].data as IOrderBookData;
					if (data[1].split(':')[1] === 'future') {
						yield put(
							getPerpOrderBookSuccess({
								ask: orderBookUpdatedData?.ask || [],
								bid: orderBookUpdatedData?.bid || [],
							}),
						);
						break;
					}
					if (data[1].split(':')[1] === 'option') {
						yield put(setOrderBook(orderBookUpdatedData));
						break;
					}
					yield put(updateOrderBookSuccess(orderBookUpdatedData));
					break;
				case 'trade_history':
					const recentTradesUpdatedData = data[2].data as IRecentTradesResponse;

					const spotRecentTrades: Array<IRecentTradesItem> = yield select(getSpotRecentTrades);
					const currentRecentTrades = spotRecentTrades || [];

					const recentTradesItem = {
						id: recentTradesUpdatedData.trade.id,
						type: recentTradesUpdatedData.trade.side,
						price: recentTradesUpdatedData.trade.price,
						created_at: recentTradesUpdatedData.trade.created_at,
						quantity: recentTradesUpdatedData.trade.amount_sold_asset,
						assets_pairs_id: recentTradesUpdatedData.trade.assets_pairs_id,
					};

					const newRecentTrades: Array<IRecentTradesItem> = yield [
						recentTradesItem,
						...currentRecentTrades,
					].slice(0, 100);

					if (currentRecentTrades[0].id !== recentTradesItem.id) {
						yield put(updateRecentTradesSuccess(newRecentTrades));
					}

					if (recentTradesItem) {
						updateBarBySockets({
							price: +recentTradesItem.price,
							time: +recentTradesItem.created_at * 1000,
							volume: +recentTradesItem.quantity,
						});
					}
					break;
				case 'opened_user_orders':
					const opened: IOpenOrdersData = yield select(getSpotUserOpenOrders);
					const transactionType: ETradeType = yield select(getTradeType);
					const openOrdersUpdatedData = data[2].data as IOpenOrdersUpdateResponseDataPayload;
					if (openOrdersUpdatedData.action === 'UPDATED') {
						const onPage = opened?.data?.find(
							(item) => item.id === openOrdersUpdatedData.orders[0].id,
						);
						const newData = [];
						if (onPage) {
							for (let i = 0; i < opened.data.length; i += 1) {
								if (opened.data[i].id !== openOrdersUpdatedData.orders[0].id) {
									newData.push(opened.data[i]);
								} else newData.push(openOrdersUpdatedData.orders[0]);
							}
						}
						const newOpenOrders = JSON.parse(JSON.stringify(opened));
						if (newOpenOrders?.data) {
							newOpenOrders.data = newData;
						}
						yield put(getOpenOrdersSuccess(newOpenOrders));
					}
					if (openOrdersUpdatedData.action === 'DELETED') {
						const onPage = opened?.data?.find(
							(item) => item.id === openOrdersUpdatedData.orders[0].id,
						);
						if (onPage) {
							yield put(
								getOpenOrdersRequest({
									params: {
										current_page: opened.current_page,
										per_page: 15,
										status: 'opened',
										order_type: transactionType,
									},
								}),
							);
						}
					}
					if (openOrdersUpdatedData.action === 'ADDED' && opened?.current_page === 1) {
						yield put(
							getOpenOrdersRequest({
								params: {
									current_page: opened.current_page,
									per_page: 15,
									status: 'opened',
									order_type: transactionType,
								},
							}),
						);
					}
					break;
				// yield put(updateOpenOrdersSuccess(openOrdersUpdatedData));
				// break;
				// switch (data[1].split(':')[1]) {
				// 	case 'spot':
				// 		const openOrdersUpdatedData = data[2].data as IOpenOrdersUpdateResponseDataPayload;
				// 		yield put(updateOpenOrdersSuccess(openOrdersUpdatedData));
				// 		break;
				// 	case 'margin':
				// 		const openOrdersUpdatedDataCross = data[2]
				// 			.data as IOpenOrdersUpdateResponseDataPayload;
				// 		yield put(updateOpenOrdersSuccess(openOrdersUpdatedDataCross));
				// 		break;
				// 	case 'isolated':
				// 		const openOrdersUpdatedDataIsolated = data[2]
				// 			.data as IOpenOrdersUpdateResponseDataPayload;
				// 		yield put(updateOpenOrdersSuccess(openOrdersUpdatedDataIsolated));
				// 		break;
				// 	default:
				// 		break;
				// }
				case 'closed_user_orders':
					const closed: IOpenOrdersData = yield select(getSpotUserOrdersHistory);
					const tradeType: ETradeType = yield select(getTradeType);
					const closeOrdersUpdatedData = data[2].data as IOpenOrdersUpdateResponseDataPayload;
					const params = {
						order_type_ids: '1,4,7',
						order_type: tradeType,
						per_page: 15,
						current_page: 1,
					};
					if (closed?.current_page === 1) {
						yield put(getOrdersHistoryRequest({ params: { ...params, status: 'closed' } }));
					}
					// yield put(updateCloseOrdersSuccess(closeOrdersUpdatedData));
					break;
				// switch (data[1].split(':')[1]) {
				// case 'spot':
				// 	const closedOrdersUpdatedData = data[2]
				// 		.data as IOrdersHistoryUpdateResponseDataPayload;
				// 	yield put(updateOrdersHistorySuccess(closedOrdersUpdatedData));
				// 	break;
				// 	default:
				// 		break;
				// }
				case 'closed_user_orders_option':
					if (data[1].split(':')[1] === 'option') {
						const activeOrders: IActiveOrders = data[2]?.data as IActiveOrders;
						yield put(updateActiveOrdersOnCancel(activeOrders));
					}
					break;
				case 'opened_user_orders_option':
					if (data[1].split(':')[1] === 'option') {
						const activeOrders: IActiveOrders = data[2]?.data as IActiveOrders;
						yield put(updateActiveOrdersOnOpen(activeOrders));
					}
					break;
				case 'private_trade_history':
					const userTradesItemData = data[2].data as IRecentTradesResponse;

					const userTrades: IUserTrades = yield select(getSpotUserTradesCurrentPair);
					const currentUserTrades = userTrades?.data || [];

					const userTradesItem: any = {
						id: userTradesItemData.trade.id,
						created_at: userTradesItemData.trade.created_at,
						pair: userTradesItemData.trade.pair,
						type: userTradesItemData.trade.side,
						fee:
							userTradesItemData.trade.side === 'taker'
								? userTradesItemData.trade.buyer_fee
								: userTradesItemData.trade.seller_fee,
						total:
							Number(userTradesItemData.trade.price) *
							Number(userTradesItemData.trade.amount_sold_asset),
						price: userTradesItemData.trade.price,
						executed: userTradesItemData.trade.amount_sold_asset,
					};

					const newUserTrades: IUserTrades = {
						...userTrades,
						data: [userTradesItem, ...currentUserTrades],
					};
					const currentUserTradesId = !!currentUserTrades.length && currentUserTrades[0]?.id;
					if (userTradesItem.id !== currentUserTradesId) {
						yield put(updateUserTradesCurrentPairSuccess(newUserTrades));
					}

					break;
				case 'private_common_trade_history':
					const tradeHistoryItemData = data[2].data as IRecentTradesResponse;

					const tradeHistory: IUserTrades = yield select(getSpotUserTrades);
					const currentTradesHistory = tradeHistory?.data || [];

					const tradesHistoryItem: any = {
						id: tradeHistoryItemData.trade.id,
						created_at: tradeHistoryItemData.trade.created_at,
						pair: tradeHistoryItemData.trade.pair,
						type: tradeHistoryItemData.trade.side,
						fee: tradeHistoryItemData.trade.fee,
						total:
							Number(tradeHistoryItemData.trade.price) *
							Number(tradeHistoryItemData.trade.amount_sold_asset),
						price: tradeHistoryItemData.trade.price,
						executed: tradeHistoryItemData.trade.amount_sold_asset,
						incoming_asset: tradeHistoryItemData.trade.incoming_asset,
					};

					const newTradeHistory: IUserTrades = {
						...tradeHistory,
						data: [tradesHistoryItem, ...currentTradesHistory],
					};

					const currentTradesHistoryId = currentTradesHistory?.length
						? currentTradesHistory[0]?.id
						: null;

					if (tradesHistoryItem.id !== currentTradesHistoryId) {
						yield put(updateUserTradesSuccess(newTradeHistory));
					}

					break;
				case 'private-notifications':
					const { message, type } = data[2].data as IPrivateNotificationsData;

					notificationContainer(`${String(message)}`, type);
					break;
				// case 'index_prices':
				// 	const indexPrices = data[2].data as IIndexPrices;
				// 	yield put(updateMarginIndexPricesSuccess(indexPrices));
				case 'balances':
					switch (data[1].split(':')[1]) {
						// case 'cross':
						// 	const CrossWaleltsUpdatedData = data[2].data as IMarginCrossWalletsData;
						// 	yield put(updateCrossWalletsSuccess(CrossWaleltsUpdatedData));

						// 	break;
						// case 'isolated':
						// 	const IsolatedWaleltsUpdatedData = data[2].data as IMarginIsolatedWalletsData;

						// 	yield put(updateIsolatedWalletsSuccess(IsolatedWaleltsUpdatedData));

						// 	break;
						default:
							const waleltsUpdatedData = data[2].data as IWalletsData;
							yield put(updateWalletsSuccess(waleltsUpdatedData));
							break;
					}

					break;
				case 'balance':
					switch (data[1].split(':')[1]) {
						case 'spot':
							const balanceSpot = data[2].data as { balance: number };
							const assetNameSpot = data[1].split(':')[2];
							const currentWallet = walletsList?.filter((el) => el.asset.code === assetNameSpot);
							if (currentWallet?.length) {
								const newCurrentWallet: IWalletItem = {
									...currentWallet[0],
									balance: convertToFixed(balanceSpot.balance),
								};
								const otherWallets = walletsList?.filter((el) => el.asset.code !== assetNameSpot);

								if (otherWallets?.length) {
									const newWallets: IWalletsResponsePayload = [...otherWallets, newCurrentWallet];
									yield put(updateWalletsSuccess(newWallets));
								}
							}
							break;
						case 'isolated':
							const balanceIsolated = data[2].data as { balance: number };
							const pairName = data[1].split(':')[2];
							const assetNameIsolated = data[1].split(':')[3];

							const currentWalletIsolated =
								isolatedWallets?.isolated?.find(
									(el) => el.pair.code === pairName && el.asset.code === assetNameIsolated,
								) || {};
							const otherWallets: IWalletsIsolatedDataType =
								isolatedWallets?.isolated?.filter(
									(wallet) => JSON.stringify(wallet) !== JSON.stringify(currentWalletIsolated),
								) || [];
							if (currentWalletIsolated) {
								const newWallets: any = {
									...isolatedWallets,
									isolated: [
										...otherWallets,
										{ ...currentWalletIsolated, balance: balanceIsolated.balance },
									],
								};
								yield put(getMarginIsolatedWalletsSuccess(newWallets));
							}
							break;
						default:
							break;
					}
					break;
				case 'top_asset_pairs':
					const topAssetPairs = data[2].data as any;
					const topAssetPairsArr = topAssetPairs.assetPairsInfo as IAssetTopPairsItem[];
					if (topAssetPairsArr) {
						yield put(getTopAssetPairsSuccess(topAssetPairsArr));
					}
					break;
				default:
					break;
			}
		}
	}
}

export function* socketsSaga() {
	try {
		while (true) {
			const { payload }: { payload: PayloadAction } = yield take(socketOpenConnection.type);
			const socketToken: any = payload; // TODO: add types

			const socket: WebSocket = yield call(socketConnection, socketToken);
			const channel: EventChannel<any> = yield call(socketChannel, socket);

			if (socket.onopen) {
				yield put(setSocketsConnect());
			}

			yield fork(socketSend, socket);
			yield fork(socketClose, socket);

			const { cancel } = yield race({
				task: call(socketOnMessage, channel),
				cancel: take(socketClosedConnection.type),
			});

			if (cancel) {
				channel.close();
			}
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('websockets_error', error);
		// eslint-disable-next-line no-console
		console.dir('websockets_error', error);
		notificationContainer(String(L.translate(`Errors.websockets_error`)), 'error');
	}
}
