export const changeSearchParams: (
	setParams: { [x: string]: string },
	deleteParams?: string[],
) => string = (setParams, deleteParams) => {
	const urlObj = new URL(window.location.href);
	const search = new URLSearchParams(urlObj.search);
	Object.entries(setParams).forEach((entry) => {
		const [name, value] = entry;
		search.set(name, value);
	});
	deleteParams?.forEach((dp) => {
		search.delete(dp);
	});
	return `${urlObj.href.slice(0, urlObj.href.indexOf('?'))}?${search.toString()}`;
};

export const getSearchParam = (searchParam: string) => {
	const url = new URL(window.location.href);
	const params = new URLSearchParams(url.search);
	return params.get(searchParam);
};

export const deleteSearchParam = (searchParam: string) => {
	const url = new URL(window.location.href);
	const search = new URLSearchParams(url.search);
	search.delete('currentPage');
	return search.toString();
};
