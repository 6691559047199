import L from 'i18n-react';
import { useHistory } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPair, tradingSettingsInitState } from 'redux/reducers/tradingSettings/reducer';
import { getTopAssetPairs, getTopAssetPairsLoader } from 'redux/reducers/assetPairs/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getFirstPartPairCode } from 'services/utils/tradingPairHelpers';
import { changePage } from 'services/utils/changePage';
import { ROUTES } from 'routes/constants';
import SocketWithTopicConnectWrapper from 'components/SocketWithTopicConnectWrapper';
import Loader from 'ui/Loader';

export const TopCryptocurrencies = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const topAssetPairs = useSelector(getTopAssetPairs);
	const topAssetPairsLoader = useSelector(getTopAssetPairsLoader);

	const handleTrade = (pairCode: string, id: number) => {
		if (!pairCode) return;
		dispatch(setCurrentPair({ pair: pairCode, id: Number(id) }));
		changePage(`/spot/${pairCode.toUpperCase()}`, history);
	};

	const handleToMailPairSpot = () => {
		dispatch(tradingSettingsInitState());
		changePage('/spot/btc_usdt', history);
	};

	return (
		<SocketWithTopicConnectWrapper topicName="top_asset_pairs">
			<section className="top-crypto-section">
				<div className="inside">
					{!topAssetPairsLoader ? (
						<div className="top-crypto">
							<div className="page-title page-title--center fw-500">
								<p>{String(L.translate('HomePage.title_3'))}</p>
							</div>
							<div className="top-crypto-nav">
								{authIsAuthenticated ? (
									<button
										type="button"
										onClick={handleToMailPairSpot}
										className="top-crypto-nav__item active"
									>
										{String(L.translate('HomePage.button_1'))}
									</button>
								) : (
									<NavLink to="/registration" className="top-crypto-nav__item active">
										{String(L.translate('HomePage.sign_up'))}
									</NavLink>
								)}
							</div>
							<div className="table table--crypto">
								<div className="table-header">
									<div className="tr">
										<div className="td">
											<p> {String(L.translate('HomePage.td_1'))}</p>
										</div>
										<div className="td">
											<p> {String(L.translate('HomePage.td_2'))}</p>
										</div>
										<div className="td">
											<p> {String(L.translate('HomePage.td_3'))}</p>
										</div>
										<div className="td td--hide">
											<p> {String(L.translate('HomePage.td_4'))}</p>
										</div>
										<div className="td td--right">
											<p> {String(L.translate('HomePage.td_5'))}</p>
										</div>
									</div>
								</div>
								<div className="table-body">
									{!!topAssetPairs?.length &&
										topAssetPairs.map((pair) => (
											<div key={pair.id} className="tr">
												<div className="td">
													<div className="currency-item">
														<div className="currency-item__icon">
															<img src={pair.img_path} alt={pair.base_asset_name} />
														</div>
														<div className="currency-item__name">
															<p>{pair.base_asset_name}</p>
														</div>
														<div className="currency-item__short-name">
															<p>{getFirstPartPairCode(pair.code)}</p>
														</div>
													</div>
												</div>
												<div className="td">
													<p>
														<span className="grey">${Number(pair.last_price).toFixed(2)}</span>
													</p>
												</div>
												<div className="td">
													<span className={pair.change24 < 0 ? 'red' : 'green'}>
														{pair.change24.toFixed(2)}%
													</span>
												</div>
												<div className="td td--hide">
													<div className="currency-chart">
														<img src={`data:image/png;base64,${pair?.chart.base64}`} alt="chart" />
													</div>
												</div>
												<div className="td">
													<div className="flex flex-e">
														<button
															type="button"
															className="button button--type4"
															onClick={() => handleTrade(pair.code, pair.id)}
														>
															Trade
														</button>
													</div>
												</div>
											</div>
										))}
								</div>
							</div>
							<Link
								to={ROUTES.pairs.spot}
								type="button"
								className="button button--big2 button--size2 button--type5"
							>
								{String(L.translate('HomePage.button_3'))}
							</Link>
						</div>
					) : (
						<Loader />
					)}
				</div>
			</section>
		</SocketWithTopicConnectWrapper>
	);
};
