import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsRequest } from 'redux/reducers/analytics/reducer';
import {
	analyticsData,
	analyticsDataLoading,
	getAnalyticsPair,
} from 'redux/reducers/analytics/selectors';
import Graphic from './Graphic/Graphic';
import PriceVolume from './PriceVolume/PriceVolume';
import Semicircle from './Semicircle/Semicircle';
import Loader from '../../../ui/Loader';

const AccountSettingsAnalytics = () => {
	const dispatch = useDispatch();
	const pair = useSelector(getAnalyticsPair);
	const data = useSelector(analyticsData);
	const loading = useSelector(analyticsDataLoading);
	useLayoutEffect(() => {
		dispatch(analyticsRequest(pair));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pair]);

	return (
		<>
			{loading && data ? (
				<div className="analytics-loader-wrapper">
					<Loader />
				</div>
			) : (
				<>
					{data === undefined ? (
						<div className="analytics-loader-wrapper">
							<Loader />
						</div>
					) : (
						<div className="analitics-block">
							<PriceVolume
								data={data?.percents}
								price={data?.['24h_price_change']}
								volume={data?.['24h_volume']}
							/>
							<Semicircle data={data?.volume_paritets} />
							<Graphic data={data?.graphics} />
						</div>
					)}
				</>
			)}
		</>
	);
};

export default AccountSettingsAnalytics;
