export const getDateDiffFormatted = (date1: Date, date2: Date) => {
	const diff = date1.getTime() - date2.getTime();
	let hh: string | number = Math.floor(diff / 3600000);
	let mm: string | number = Math.floor((diff - hh * 3600000) / 60000);
	let ss: string | number = Math.floor((diff - hh * 3600000 - mm * 60000) / 1000);
	hh = `${hh}`.length === 1 ? `0${hh}` : `${hh}`;
	mm = `${mm}`.length === 1 ? `0${mm}` : `${mm}`;
	ss = `${ss}`.length === 1 ? `0${ss}` : `${ss}`;
	return { hh, mm, ss };
};
