import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MarketType, ITransactionHistoryReqPayload } from './types';

const initialState = {
	orderBook: null,
	orderBookLoader: false,
	recentTrades: null,
	recentTradesLoader: false,
	orderHistory: null,
	orderHistoryLoader: false,
	marketType: MarketType.Isolated,
	isolatedLeverage: 1,
	baseAssetStatisticsLoader: false,
	baseAssetStatistics: null,
	baseAsset: null,
	balance: null,
	transactionHistoryLoader: false,
	openPositions: null,
	myOrdersLoader: false,
	myOrders: null,
	tradeHistoryLoader: false,
	tradeHistory: null,
	transactionsLoader: false,
	transactions: null,
	unrealisedPnlLoader: false,
	unrealisedPnl: null,
	stopOrdersLoader: false,
	stopOrders: null,
	stopOrderHistoryLoader: false,
	stopOrderHistory: null,
	closedOrderHistoryLoader: false,
	closedOrderHistory: null,
	userStat: null,
	activeBaseAssetLoader: false,
	activeBaseAsset: null,
	derivativeAccountLoader: false,
	derivativeAccount: null,
};

const perpetualTrade = createSlice({
	name: '@@perpetualTrade',
	initialState,
	reducers: {
		getPerpetualOrderBookRequest: (state, action) => {
			const orderBookRequestState = state;
			orderBookRequestState.orderBookLoader = true;
		},
		getPerpOrderBookSuccess: (state, action) => {
			const { payload } = action;
			const orderBookSuccessState = state;

			orderBookSuccessState.orderBook = payload;
			orderBookSuccessState.orderBookLoader = false;
		},
		getRecentPerpetualTradesRequest: (state, action) => {
			const RecentTradesState = state;
			RecentTradesState.recentTradesLoader = true;
		},
		getRecentPerpetualTradesSuccess: (state, action) => {
			const { payload } = action;
			const recentTradesState = state;

			recentTradesState.recentTrades = payload;
			recentTradesState.recentTradesLoader = false;
		},
		getOrderHistoryRequest: (state, action) => {
			const orderHistoryRequestState = state;
			orderHistoryRequestState.orderHistoryLoader = true;
		},
		getOrderHistorySuccess: (state, action) => {
			const { payload } = action;
			const orderHistoryState = state;

			orderHistoryState.orderHistory = payload;
			orderHistoryState.orderHistoryLoader = false;
		},
		getOrderHistoryFromSocketsSuccess: (state, action) => {
			const { payload } = action;
			const orderHistoryState = state;

			const orderHistory = JSON.parse(JSON.stringify(orderHistoryState.orderHistory));
			orderHistory.data = payload.orders;

			orderHistoryState.orderHistory = orderHistory;
			orderHistoryState.orderHistoryLoader = false;
		},
		getBaseAssetStatisticsRequest: (state) => {
			const baseAssetStatisticsState = state;

			baseAssetStatisticsState.baseAssetStatisticsLoader = true;
		},
		getBaseAssetStatisticsSuccess: (state, action) => {
			const { payload } = action;
			const baseAssetStatisticsState = state;

			if (baseAssetStatisticsState.baseAsset === null) {
				const asset = payload[0];
				baseAssetStatisticsState.baseAsset = asset;
			}
			baseAssetStatisticsState.baseAssetStatisticsLoader = false;
			baseAssetStatisticsState.baseAssetStatistics = payload;
		},
		perpetualTradeInitState: () => initialState,
		setIsolatedLeverage: (state, action) => {
			const { payload } = action;
			const recentTradesState = state;
			recentTradesState.isolatedLeverage = payload;
		},
		setBaseAsset: (state, action) => {
			const { payload } = action;
			const baseAssetState = state;
			if (JSON.stringify(payload) === JSON.stringify(baseAssetState.baseAsset)) {
				return;
			}
			baseAssetState.baseAsset = payload;
		},
		getAvailableBalanceRequest: () => {},
		getAvailableBalanceSuccess: (state, action) => {
			const { payload } = action;
			const balanceState = state;
			balanceState.balance = payload;
		},
		createOrderRequest: (state, action) => {},
		getPerpetualOpenPositionsRequest: (state, action) => {
			const transactionHistoryState = state;

			transactionHistoryState.transactionHistoryLoader = true;
		},
		getPerpetualOpenPositionsSuccess: (state, action) => {
			const openPositionsState = state;
			const { payload } = action;

			openPositionsState.openPositions = payload;
			openPositionsState.transactionHistoryLoader = false;
		},
		getMyOrdersRequest: (state, action) => {
			const myOrdersState = state;

			myOrdersState.myOrdersLoader = true;
		},
		getMyOrdersSuccess: (state, action) => {
			const myOrdersState = state;
			const { payload } = action;

			myOrdersState.myOrders = payload;
			myOrdersState.myOrdersLoader = false;
		},
		getMyOrdersFromSocketsSuccess: (state, action) => {
			const myOrdersState = state;
			const { payload } = action;

			let myOrders = JSON.parse(JSON.stringify(myOrdersState.myOrders));
			if (myOrders?.data) {
				myOrders.data = payload.orders.reverse();
				myOrdersState.myOrders = myOrders;
			} else {
				myOrders = { data: payload.orders.reverse() };
				myOrdersState.myOrders = myOrders;
			}

			myOrdersState.myOrdersLoader = false;
		},
		getTradeHistoryRequest: (state, action) => {
			const tradeHistoryState = state;

			tradeHistoryState.tradeHistoryLoader = true;
		},
		getTradeHistorySuccess: (state, action) => {
			const tradeHistoryState = state;
			const { payload } = action;

			tradeHistoryState.tradeHistory = payload;
			tradeHistoryState.tradeHistoryLoader = false;
		},
		getTransactionsRequest: (state, action) => {
			const transactionsState = state;

			transactionsState.transactionsLoader = true;
		},
		getTransactionsSuccess: (state, action) => {
			const transactionsState = state;
			const { payload } = action;

			transactionsState.transactions = payload;
			transactionsState.transactionsLoader = false;
		},
		getUnrealisedPnlRequest: (state, action) => {
			const unrealisedPnlState = state;

			unrealisedPnlState.unrealisedPnlLoader = true;
		},
		getUnrealisedPnlSuccess: (state, action) => {
			const unrealisedPnlState = state;
			const { payload } = action;

			unrealisedPnlState.unrealisedPnlLoader = false;
			unrealisedPnlState.unrealisedPnl = payload;
		},
		getMyStopOrdersRequest: (state, action) => {
			const transactionsState = state;

			transactionsState.stopOrdersLoader = true;
		},
		getMyStopOrdersSuccess: (state, action) => {
			const stopOrdersState = state;
			const { payload } = action;

			stopOrdersState.stopOrders = payload;
			stopOrdersState.stopOrdersLoader = false;
		},
		getStopOrderHistoryRequest: (state, action) => {
			const stopOrderHistoryState = state;

			stopOrderHistoryState.stopOrderHistoryLoader = true;
		},
		getStopOrderHistorySuccess: (state, action) => {
			const stopOrderHistoryState = state;
			const { payload } = action;

			stopOrderHistoryState.stopOrderHistory = payload;
			stopOrderHistoryState.stopOrderHistoryLoader = false;
		},
		getClosedOrderHistoryRequest: (state, action) => {
			const closedOrderHistoryState = state;

			closedOrderHistoryState.closedOrderHistoryLoader = true;
		},
		getClosedOrderHistorySuccess: (state, action) => {
			const closedOrderHistoryState = state;
			const { payload } = action;

			closedOrderHistoryState.closedOrderHistory = payload;
			closedOrderHistoryState.closedOrderHistoryLoader = false;
		},
		updatePerpOrderBookSuccess: (state, action) => {
			const { payload } = action;
			const orderBookSuccessState = state;

			orderBookSuccessState.orderBook = payload;
			orderBookSuccessState.orderBookLoader = false;
		},
		getUserStatRequest: (state, action) => {
			const userStatState = state;
			userStatState.unrealisedPnlLoader = true;
		},
		getUserStatSuccess: (state, action) => {
			const { payload } = action;
			const userStatState = state;
			userStatState.userStat = payload;
		},
		getActiveBaseAssetRequest: (state, action) => {
			const activeBaseAssetState = state;

			activeBaseAssetState.activeBaseAssetLoader = true;
		},
		getActiveBaseAssetSuccess: (state, action) => {
			const { payload } = action;
			const activeBaseAssetState = state;
			activeBaseAssetState.activeBaseAsset = payload;
		},
		getDerivativeAccountRequest: (state) => {
			const derivativeAccountState = state;
			derivativeAccountState.derivativeAccountLoader = true;
		},
		getDerivativeAccountSuccess: (state, action) => {
			const { payload } = action;
			const derivativeAccountState = state;
			derivativeAccountState.derivativeAccountLoader = false;
			derivativeAccountState.derivativeAccount = payload;
		},
	},
});

export default perpetualTrade.reducer;
export const {
	getPerpetualOrderBookRequest,
	getPerpOrderBookSuccess,
	getRecentPerpetualTradesRequest,
	getRecentPerpetualTradesSuccess,
	getOrderHistoryRequest,
	getOrderHistorySuccess,
	getBaseAssetStatisticsRequest,
	getBaseAssetStatisticsSuccess,
	perpetualTradeInitState,
	setIsolatedLeverage,
	setBaseAsset,
	getAvailableBalanceRequest,
	getAvailableBalanceSuccess,
	createOrderRequest,
	getPerpetualOpenPositionsRequest,
	getPerpetualOpenPositionsSuccess,
	getMyOrdersRequest,
	getMyOrdersSuccess,
	getTradeHistoryRequest,
	getTradeHistorySuccess,
	getTransactionsRequest,
	getTransactionsSuccess,
	getUnrealisedPnlRequest,
	getUnrealisedPnlSuccess,
	getMyStopOrdersRequest,
	getMyStopOrdersSuccess,
	getStopOrderHistoryRequest,
	getStopOrderHistorySuccess,
	getClosedOrderHistoryRequest,
	getClosedOrderHistorySuccess,
	updatePerpOrderBookSuccess,
	getMyOrdersFromSocketsSuccess,
	getOrderHistoryFromSocketsSuccess,
	getUserStatRequest,
	getUserStatSuccess,
	getActiveBaseAssetRequest,
	getActiveBaseAssetSuccess,
	getDerivativeAccountRequest,
	getDerivativeAccountSuccess,
} = perpetualTrade.actions;
