import { IOptionsStatistics, IUserImMmData } from '../sockets/types';

export enum ETypeOption {
	CALL = 'call',
	PUT = 'put',
}

export enum ENameExpires {
	DAILY = 'daily',
	WEEKLY = 'weekly',
	MONTHLY = 'monthly',
	QUARTERLY = 'quarterly',
}

export enum EOptionStatus {
	OPEN = 'open',
	EXPIRED = 'expired',
}

export type IOptionOrderPosition = {
	id: number;
	opposite_option_uuid: string;
	type_option: ETypeOption;
	name_expires: ENameExpires;
	contract_name: string;
	date_expires: string;
	asset_underlying: string;
	asset_future_settings_id: number;
	open_size: number;
	delta: number;
	gamma: number;
	vega: number;
	teta: number;
	bid_size: number;
	bid_price: number;
	ask_size: number;
	ask_price: number;
	mark_price: number;
	strike_price: number;
	status: EOptionStatus;
	is_hidden: boolean;
	iv?: number;
	bid_iv?: number;
	ask_iv?: number;
};

export interface IOptionByDate {
	option_structure_id: number;
	options_positions: IOptionOrderPosition[];
}

export interface IOptionsListItem {
	options_consolidate: IOptionOrderPosition[];
	options_by_date: IOptionByDate;
}

export interface IOptionsTableRow {
	options_call: IOptionOrderPosition;
	options_put: IOptionOrderPosition;
}

export interface IStatisticsResponse {
	option_id: number;
	asset_underlying: string;
	mark_price: number;
	'24h_volume': number;
	'24h_low': number;
	'24h_high': number;
	last_price: number;
}

export interface ICreateOptionResponse {
	amount_asset_request: number;
	option_id: number;
	price_entry_for_order: number;
	transactions_type: 'buy' | 'sell';
	error?: any;
	errors?: any;
}

interface PaginatedData<T> {
	total: number;
	per_page: number;
	last_page: number;
	current_page: number;
	data: Array<T>;
}

export interface IStructureItem {
	id: number;
	date_expires?: string;
	name_structure?: string;
	name_expires?: string;
	asset_underlying?: string;
	asset_future_settings_id: number;
	status?: string;
	created_at?: string;
	updated_at?: string;
}

export type IStructure = IStructureItem[];

export interface IOption {
	id: number;
	contract_name?: string;
	delta?: number;
	gamma?: number;
	vega?: number;
	teta?: number;
	mark_price?: number;
}

export interface IOptionsAllItem {
	options_consolidate?: {
		option?: IOption;
	};
	options_by_date?: {
		option_structure_id: number;
		options_positions?: IOption[];
	}[];
}

export type IOptionsAll = IOptionsAllItem[];

export interface IOrder {
	id: number;
	option_id: number;
	contract_name?: string | null;
	type_option?: string | null;
	price_entry_for_order?: number | null;
	fee?: number | null;
	type_order?: string | null;
	status?: string | null;
	amount_asset_request?: number | null;
	amount_exequted_in_asset?: number | null;
	amount_asset_request_left?: number | null;
	price_avarage_completed?: number | null;
	pnl_realized?: number | null;
	created_at?: number | string | null;
	iv_coefficient?: number | null;
	im_coefficient?: number | null;
	transactions_type?: string | null;
	option?: IOptionOrderPosition;
}

export interface IPositionsItem {
	id: number;
	option_structure_id?: number | null;
	option_id?: number | null;
	transactions_type?: string | null;
	contract_name?: string | null;
	entry_price?: number | null;
	amount_asset_underlying?: number | null;
	amount_asset_request?: number | null;
	amount_exequted_in_usdt?: number | null;
	im_coefficient?: number | null;
	mm_coefficient?: number | null;
	delta?: number | null;
	gamma?: number | null;
	vega?: number | null;
	teta: number | null;
	status?: string | null;
}

export type IPositions = IPositionsItem[];

export type IActiveOrders = IOrder[];

export interface IOrderHistoryItem {
	id: number;
	contract_name?: string | null;
	type_option?: string | null;
	price_entry_for_order?: number | null;
	iv_coefficient?: number | null;
	fee?: number | null;
	type_order?: string | null;
	status?: string | null;
	amount_asset_request?: number | null;
	amount_exequted_in_asset?: number | null;
	amount_asset_request_left?: number | null;
	price_avarage_completed?: number | null;
	pnl_realized?: number | null;
	transactions_type?: string | null;
	created_at?: number | string | null;
}

type IOrderHistory = PaginatedData<IOrderHistoryItem>;

export interface ITradeHistoryItem {
	id: number;
	contract_name?: string | null;
	fill_price?: number | null;
	index_price?: number | null;
	mark_price?: number | null;
	type_option?: string | null;
	amount_asset_filled?: number | null;
	fee_usdt?: number | null;
	status?: string | null;
	transactions_type?: string | null;
	// amount_asset_request?: number | null;
	// amount_exequted_in_asset?: number | null;
	// amount_asset_request_left?: number | null;
	// price_avarage_completed?: number | null;
	// pnl_realized?: number | null;
	iv_coefficient?: number | null;
	buy_order_id?: number | null;
	sell_order_id?: number | null;
	created_at?: number | string | null;
}

type ITradeHistory = PaginatedData<ITradeHistoryItem>;

export interface IOptionsTradeStore {
	createOrder: IOptionOrderPosition | null;
	ordersList: IOptionsListItem[] | null;
	ordersListLoading: boolean;
	statisticLoader: boolean;
	newOrderLoader: boolean;
	showModal: boolean;
	structureLoader?: boolean;
	structure?: IStructure | null;
	optionsAllLoader?: boolean;
	optionsAll?: IOptionsAll | null;
	activeOrdersLoader: boolean;
	activeOrders?: IActiveOrders | null;
	positionsLoader: boolean;
	positions: IPositions | null;
	orderHistoryLoader: boolean;
	orderHistory: IOrderHistory | null;
	tradeHistoryLoader: boolean;
	tradeHistory: ITradeHistory | null;
	showCancelOrderConfirm: boolean;
	orderBook: null | any;
	orderBookLoader: boolean;
	optionStatistics: null | IStatisticsResponse;
	recentTrades: any;
	userImMm: null | IUserImMmData;
	lastPrice: number;
	prevLastPrice: number;
}
