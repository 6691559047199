import {
	IAssetPairsFeesAndLimitsResponsePayload,
	IAssetPairsResponsePayload,
} from 'redux/reducers/assetPairs/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IAssetPairsApi } from './types';

export const assetPairs: IAssetPairsApi = {
	getAssetPairs: () =>
		http
			.get<IAssetPairsResponsePayload>(endpoint.assetPairs.ASSET_PAIRS)
			.then((response) => response.data),

	getTopAssetPairs: () => http.get(endpoint.assetPairs.TOP_ASSET_PAIRS).then(({ data }) => data),

	getAssetPairsFavorite: () => http.get(endpoint.assetPairs.GET_FAVORITE).then(({ data }) => data),

	putAssetPairsFavorite: (params) =>
		http.put(endpoint.assetPairs.TOGGLE_FAVORITE, params).then(({ data }) => data),

	getAssetPairsFeesAndLimits: () =>
		http
			.get<IAssetPairsFeesAndLimitsResponsePayload>(endpoint.assetPairs.ASSET_PAIRS_FEES_AND_LIMITS)
			.then((response) => response.data),

	getMarket24VolumeUsd: () =>
		http.get(endpoint.assetPairs.MARKET_24_VOLUME_USD).then((response) => response.data),
};
