/* eslint-disable @typescript-eslint/no-shadow */
import { FC, ReactNode, SyntheticEvent } from 'react';
import L from 'i18n-react';
import useSelect from 'hooks/useSelect';
import { IPastDaysSelect } from './types';

const PastDaysSelect: FC<IPastDaysSelect> = ({ label, value = null, onChange }) => {
	const { open, setOpen, toggleOpen, currentRef, dropRef } = useSelect();

	const handleTypeChoose = (e: SyntheticEvent<HTMLButtonElement>) => {
		const target = e.target as HTMLButtonElement;
		const { value: targetValue } = target;
		if (onChange) {
			onChange(Number(targetValue));
		}
		setOpen(false);
	};

	const handleAllSelect = () => {
		if (onChange) {
			onChange(null);
		}
		setOpen(false);
	};

	const renderDropItem: (props: { value: number; label: string }) => ReactNode = ({
		value,
		label,
	}) => {
		return (
			<div className="select__drop-item">
				<ul>
					<li>
						<button value={value} onClick={handleTypeChoose} type="button">
							{label}
						</button>
					</li>
				</ul>
			</div>
		);
	};

	return (
		<div className="select-block">
			<p className="select-block__name">{label}</p>
			<div className={`select select--type3 ${open ? ' active' : ''}`}>
				<button type="button" onClick={toggleOpen} className="select__current" ref={currentRef}>
					<p>
						{value != null
							? String(L.translate('TransactionHistory.filters.time_label', { dayCount: value }))
							: String(L.translate('TransactionHistory.filters.asset_all'))}
					</p>
					<span className="select__current-arrow">
						<svg
							width="10"
							height="6"
							viewBox="0 0 10 6"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M10 0.833008H1H0L5 5.83301L10 0.833008Z" fill="#1C1C29" />
						</svg>
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								<li>
									<button onClick={handleAllSelect} type="button">
										{String(L.translate('TransactionHistory.filters.asset_all'))}
									</button>
								</li>
							</ul>
						</div>
						{renderDropItem({
							value: 7,
							label: String(L.translate('TransactionHistory.filters.time_label', { dayCount: 7 })),
						})}
						{renderDropItem({
							value: 30,
							label: String(L.translate('TransactionHistory.filters.time_label', { dayCount: 30 })),
						})}
						{renderDropItem({
							value: 90,
							label: String(L.translate('TransactionHistory.filters.time_label', { dayCount: 90 })),
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PastDaysSelect;
