import { FC, useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { getSpotWalletsByParams, getWalletsList } from 'redux/reducers/wallets/selectors';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import WalletSummary, { IWalletSummaryItem } from 'components/Wallet/Summary';
import WalletsContentHeader from 'components/Wallet/ContentHeader';
import TransferModal from 'components/Wallet/modals/TransferEnhanced';
import { CheckIcon, MagnifierIcon } from 'assets/inline-svg';
import { TStoreState } from 'redux/types';
import { Link } from 'react-router-dom';
import { navList } from 'routes/routesList';
import { getAssetPairsRequest } from 'redux/reducers/assetPairs/reducer';
import { SpotWalletsItem } from './SpotWalletsItem/SpotWalletsItem';
import SortingTableHeader from './SortingTableHeader/SortingTableHeader';
import { ISortParams } from './SortingTableHeader/SortingTh/types';

const SpotWallets: FC = () => {
	const dispatch = useDispatch();

	const [search, setSearch] = useState('');
	const [hideSmallBalances, setHideSmallBalances] = useState(false);
	const [isFiatCompactView, setIsFiatCompactView] = useState(true);
	const [fiatSortParams, setFiatSortParams] = useState<ISortParams>({ field: null });
	const [cryptoSortParams, setCryptoSortParams] = useState<ISortParams>({ field: null });

	const [transferOpened, setTransferOpened] = useState(false);

	const walletsList = useSelector(getWalletsList);
	const fiatWallets = useSelector((state: TStoreState) =>
		getSpotWalletsByParams(state, {
			assetType: 'fiat',
			search,
			excludeSmallBalances: hideSmallBalances,
			sortField: fiatSortParams.field,
			sortAsc: fiatSortParams.isAsc,
		}),
	);
	const cryptoWallets = useSelector((state: TStoreState) =>
		getSpotWalletsByParams(state, {
			assetType: 'crypto',
			search,
			excludeSmallBalances: hideSmallBalances,
			sortField: cryptoSortParams.field,
			sortAsc: cryptoSortParams.isAsc,
		}),
	);

	const fiatWalletsToShow = useMemo(() => {
		if (!fiatWallets) return null;
		return isFiatCompactView ? fiatWallets.slice(0, 2) : fiatWallets;
	}, [fiatWallets, isFiatCompactView]);

	const fetchSpotWallets = useCallback(() => {
		dispatch(getWalletsRequest());
	}, [dispatch]);

	useEffect(() => {
		fetchSpotWallets();
		dispatch(getAssetPairsRequest());
	}, [fetchSpotWallets, dispatch]);

	// TODO: Replace btcValue, usdValue calculate in useMemo
	const summary: IWalletSummaryItem[] = (() => {
		const fiatBalanceList = walletsList?.filter((wl) => wl.asset.is_crypto === 0);
		const cryptoBalanceList = walletsList?.filter((wl) => wl.asset.is_crypto === 1);

		return [
			{
				title: String(L.translate('SpotWallet.summary_fiat_spot')),
				btcValue: walletsList
					? walletsList.reduce((acc, curr) => acc + Number(curr.btc_value), 0)
					: 0,
				usdValue: walletsList
					? walletsList.reduce((acc, curr) => acc + Number(curr.usd_value), 0)
					: 0,
			},
			{
				title: String(L.translate('SpotWallet.summary_spot')),
				btcValue: cryptoBalanceList
					? cryptoBalanceList.reduce((acc, curr) => acc + Number(curr.btc_value), 0)
					: 0,
				usdValue: cryptoBalanceList
					? cryptoBalanceList.reduce((acc, curr) => acc + Number(curr.usd_value), 0)
					: 0,
			},
			{
				title: String(L.translate('SpotWallet.summary_fiat')),
				btcValue: fiatBalanceList
					? fiatBalanceList.reduce((acc, curr) => acc + Number(curr.btc_value), 0)
					: 0,
				usdValue: fiatBalanceList
					? fiatBalanceList.reduce((acc, curr) => acc + Number(curr.usd_value), 0)
					: 0,
			},
		];
	})();

	const openTransfer = () => {
		setTransferOpened(true);
	};

	const closeTransfer = () => {
		setTransferOpened(false);
	};

	return (
		<div className="wallet-content">
			<div className="wallet-content-header flex flex-sb">
				<div className="wallet-content-header__title">
					<p>{L.translate('SpotWallet.title')}</p>
				</div>

				<div className="wallet-content-header__buttons flex flex-e flex-m">
					<Link to={navList.depositCrypto.path} className="button button--type8">
						{L.translate('SpotWallet.button_deposit')}
					</Link>
					<Link to={navList.withdrawCrypto.path} className="button button--type8">
						{L.translate('SpotWallet.button_withdraw')}
					</Link>
					<button type="button" onClick={openTransfer} className="button button--type8">
						{L.translate('SpotWallet.button_transfer')}
					</button>
					<Link to="/wallet/transaction-history/crypto" className="button button--type8">
						{L.translate('SpotWallet.button_history')}
					</Link>
				</div>
			</div>

			<WalletSummary items={summary} />

			<div className="wallet-content-tables">
				<div className="wallet-content-tables-header-search">
					<div className="input input--no-mb">
						<div className="input-wrapper">
							<input
								className="input-item input-item--h40"
								type="text"
								placeholder={String(L.translate('SpotWallet.search_placeholder'))}
								value={search}
								onChange={(e) => setSearch(e.target.value)}
							/>
							<div className="input-icon input-icon--right">
								<MagnifierIcon />
							</div>
						</div>
					</div>

					<div className="checkbox checkbox--type2 checkbox--no-mb">
						<label className="checkbox__label">
							<input
								type="checkbox"
								className="hidden"
								checked={hideSmallBalances}
								onChange={(e) => setHideSmallBalances(e.target.checked)}
							/>
							<span className="checkbox__item">
								<span className="checkbox__item-icon">
									<CheckIcon />
								</span>
							</span>
							<span className="checkbox__text">
								{L.translate('SpotWallet.hide_small_checkbox')}
							</span>
						</label>
					</div>
				</div>

				<div className="table-title">
					<p>{L.translate('SpotWallet.balance_table.fiat_title')}</p>
				</div>

				<div className="table table--wallet table--wallet-fiat-spot">
					<SortingTableHeader sortParams={fiatSortParams} onChangeSort={setFiatSortParams} />

					<div className="table-body">
						{fiatWalletsToShow?.map((item) => (
							<SpotWalletsItem key={item.id} item={item} />
						))}
					</div>

					<div className="table-button-more">
						{Number(fiatWallets?.length) > 2 && isFiatCompactView && (
							<button
								type="button"
								className="button button--regular button--type5"
								onClick={() => setIsFiatCompactView(false)}
							>
								{L.translate('SpotWallet.balance_table.view_more_btn')}
							</button>
						)}
					</div>
				</div>

				<div className="table-title">
					<p>{L.translate('SpotWallet.balance_table.crypto_title')}</p>
				</div>

				<div className="table table--wallet table--wallet-fiat-spot2">
					<SortingTableHeader sortParams={cryptoSortParams} onChangeSort={setCryptoSortParams} />

					<div className="table-body">
						{cryptoWallets?.map((item) => (
							<SpotWalletsItem key={item.id} item={item} />
						))}
					</div>
				</div>
			</div>
			{transferOpened && <TransferModal onClose={closeTransfer} refetch={fetchSpotWallets} />}
		</div>
	);
};

export default SpotWallets;
