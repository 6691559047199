import React from 'react';
import Trading from 'layouts/Trading';
import Articles from 'components/StaticPages/Articles';

const ArticlesPage = () => {
	return (
		<Trading title="Articles">
			<Articles />
		</Trading>
	);
};

export default ArticlesPage;
