import L from 'i18n-react';
import { Item as WalletItem } from '../WalletsSelect';
import { SPOT, MARGIN, PERPETUALS } from './constants';

export const getTransferWallets = (): WalletItem[] => {
	return [
		{
			id: SPOT,
			title: String(L.translate('Wallets.MarginAccount.modals.transferWallets.spot')),
		},
		{
			id: MARGIN,
			title: String(L.translate('Wallets.MarginAccount.modals.transferWallets.margin')),
		},
		{
			id: PERPETUALS,
			title: String(L.translate('Wallets.MarginAccount.modals.transferWallets.perpetuals')),
		},
	];
};
