import { FC } from 'react';
import { trimFileName } from 'services/utils/trimComment';
import { IUploadFile } from './types';

const UploadedFileInput: FC<IUploadFile> = (props) => {
	const { file, handleDeleteFile } = props;
	return (
		<div className="upload-photo-wrap">
			<label className="upload-photo-box upload-photo-box--successfully">
				<span className="upload-photo">
					<span className="upload-photo__info">
						<span className="flex flex-c flex-m">
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M4.3986 4.8986C6.34317 2.95406 9.0317 1.75 12 1.75C14.9683 1.75 17.6568 2.95406 19.6014 4.8986L19.0711 5.42893L19.6014 4.8986C21.5459 6.84317 22.75 9.5317 22.75 12.5C22.75 15.4683 21.5459 18.1568 19.6014 20.1014C17.6568 22.0459 14.9683 23.25 12 23.25C9.0317 23.25 6.34317 22.0459 4.3986 20.1014L4.92893 19.5711L4.3986 20.1014C2.45406 18.1568 1.25 15.4683 1.25 12.5C1.25 9.5317 2.45406 6.84317 4.3986 4.8986L4.3986 4.8986ZM12 3.25C9.4455 3.25 7.13403 4.28452 5.45926 5.95926C3.78451 7.63404 2.75 9.9455 2.75 12.5C2.75 15.0545 3.78452 17.366 5.45926 19.0407C7.13403 20.7155 9.4455 21.75 12 21.75C14.5545 21.75 16.866 20.7155 18.5407 19.0407C20.2155 17.366 21.25 15.0545 21.25 12.5C21.25 9.9455 20.2155 7.63403 18.5407 5.95926C16.866 4.28452 14.5545 3.25 12 3.25Z"
									fill="#219653"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M17.5303 8.96967C17.8232 9.26256 17.8232 9.73744 17.5303 10.0303L11.5303 16.0303C11.2374 16.3232 10.7626 16.3232 10.4697 16.0303L7.46967 13.0303C7.17678 12.7374 7.17678 12.2626 7.46967 11.9697C7.76256 11.6768 8.23744 11.6768 8.53033 11.9697L11 14.4393L16.4697 8.96967C16.7626 8.67678 17.2374 8.67678 17.5303 8.96967Z"
									fill="#219653"
								/>
							</svg>
							<span className="upload-photo__info-title">{trimFileName(file?.name)}</span>
						</span>
					</span>
				</span>
				<button type="button" className="loaded-file__remove" onClick={handleDeleteFile}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						x="0px"
						y="0px"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="#505061"
					>
						<path d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.522222 5.1913289 21.05461 5.5683594 21.431641 C 5.9453899 21.808671 6.4777778 22 7 22 L 17 22 C 17.522222 22 18.05461 21.808671 18.431641 21.431641 C 18.808671 21.05461 19 20.522222 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 L 10 2 z M 7 5 L 17 5 L 17 20 L 7 20 L 7 5 z M 9 7 L 9 18 L 11 18 L 11 7 L 9 7 z M 13 7 L 13 18 L 15 18 L 15 7 L 13 7 z" />
					</svg>
				</button>
			</label>
		</div>
	);
};

export default UploadedFileInput;
