import { useSelector } from 'react-redux';
import L from 'i18n-react';
import { getOptionStatistics, getRecentTrades } from 'redux/reducers/options/selectors';
import { IStatisticsResponse } from 'redux/reducers/options/types';

const AssetStatistics = ({ data }: { data: IStatisticsResponse | null }) => {
	const statistics = useSelector(getOptionStatistics);
	const recentTrades = useSelector(getRecentTrades);

	const renderChange = () => {
		if (
			!recentTrades ||
			!recentTrades[data?.option_id || 0] ||
			!recentTrades[data?.option_id || 0][0] ||
			!recentTrades[data?.option_id || 0][1]
		) {
			return <span className="red">--- %</span>;
		}
		if (recentTrades[data?.option_id || 0].length === 0) {
			return <span className="red">--- %</span>;
		}

		const first = recentTrades[data?.option_id || 0][0].fill_price;
		const second = recentTrades[data?.option_id || 0][1].fill_price;

		let change: number | string = Number(((first - second) / second) * 100)
			?.toFixed(2)
			?.replace(/\.0{2}$/, '');
		if (first > second) {
			return <span className="green"> +{change} %</span>;
		}
		if (first === second) {
			return <span className="green"> + 0.00 %</span>;
		}
		change = Number(((second - first) / second) * 100)
			?.toFixed(2)
			?.replace(/\.0{2}$/, '');
		return <span className="red"> -{change} %</span>;
	};

	return (
		<div className="trade-header trade-header--type3">
			<div className="trade-header-item">
				<div className="trade-header-info">
					<p>{String(L.translate('Trade.Options.OrderForm.AssetStatistics.mark_price'))}</p>
					<span>{statistics?.mark_price?.toFixed(2) || data?.mark_price?.toFixed(2) || 0}</span>
				</div>
			</div>
			<div className="trade-header-item">
				<div className="trade-header-info">
					<p>{String(L.translate('Trade.Options.OrderForm.AssetStatistics.24_low'))}</p>
					<span>{statistics?.['24h_low']?.toFixed(2) || data?.['24h_low']?.toFixed(2) || 0}</span>
				</div>
			</div>
			<div className="trade-header-item">
				<div className="trade-header-info">
					<p>{String(L.translate('Trade.Options.OrderForm.AssetStatistics.24h_high'))}</p>
					<span>{statistics?.['24h_high']?.toFixed(2) || data?.['24h_high']?.toFixed(2) || 0}</span>
				</div>
			</div>
			<div className="trade-header-item">
				<div className="trade-header-info">
					<p>{String(L.translate('Trade.Options.OrderForm.AssetStatistics.24h_volume'))}</p>
					<span>
						{statistics?.['24h_volume']?.toFixed(2) || data?.['24h_volume']?.toFixed(2) || 0}
					</span>
				</div>
			</div>
			<div className="trade-header-item">
				<div className="trade-header-info">
					<p>{String(L.translate('Trade.Options.OrderForm.AssetStatistics.change'))}</p>
					{/* <span className="green">---</span> */}
					{renderChange()}
				</div>
			</div>
		</div>
	);
};
export default AssetStatistics;
