/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAssetPairsFeesAndLimitsList,
	getAssetPairsList,
} from 'redux/reducers/assetPairs/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { createStopLimitOrderRequest } from 'redux/reducers/orders/reducer';
import { getTempOrderPrice } from 'redux/reducers/orders/selectors';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';

import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { numberValidation } from 'services/utils/numberValidation';
import TextError from 'ui/Formik/TextError';
import LoginOrRegister from 'ui/LoginOrRegister';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import PersentSlider from 'components/Trade/TradesBox/PersentSlider';
import reduceToValidNumber from 'services/utils/reduceToValidNumber';
import PercentRadioButtons from '../PercentRadioButtons';
import { IStopLimitOrderProps } from './types';
/* eslint-disable @typescript-eslint/naming-convention */

const StopLimitOrder: FC<IStopLimitOrderProps> = ({
	mode,
	assetToTradeCode,
	assetBalanceCode,
	balance,
	marketType,
}) => {
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const currentPair = useSelector(getCurrentPair);
	const assetPairsList = useSelector(getAssetPairsList);
	const assetPairsFeesAndLimit = useSelector(getAssetPairsFeesAndLimitsList);
	const tempOrderPrice = useSelector(getTempOrderPrice);
	// const marginIndexPrice = useSelector(getMarginIndexPrices);
	// const currentPairUpperCase = currentPair.toUpperCase();
	const dispatch = useDispatch();
	const [percent, setPercent] = useState(0);
	const initState = {
		order_type_id: 7,
		assets_pairs_id: Number(currentPair.id),
		trigger_stop_limit_price: '',
		quantity: '',
		price: '',
		direction: mode.toLowerCase(),
	};

	const { decimals, quantity_decimals } = assetPairsList?.find((item) => item.id === currentPair.id)
		?.setting || { decimals: 10, quantity_decimals: 10 };

	// const [pairPrice, setPairPrice] = useState<number>(
	// 	marginIndexPrice
	// 		? Number(
	// 				Object.entries(marginIndexPrice)?.filter(
	// 					(pair) => pair[0] === currentPair.toLocaleUpperCase(),
	// 				)[0][1],
	// 		  )
	// 		: 43186.931928,
	// );

	const currentAssetPairLimits = useMemo(() => {
		return assetPairsFeesAndLimit?.length
			? assetPairsFeesAndLimit.find((pair) => pair.code === currentPair.pair)
			: null;
	}, [assetPairsFeesAndLimit, currentPair]);

	const currentAssetPairLimitsAmountMin = currentAssetPairLimits?.amount_min || 0;
	const currentAssetPairLimitsAmountMax = currentAssetPairLimits?.amount_max || 0;
	const [stopLimitOrder, setStopLimitOrder] = useState({ ...initState });
	const [total, setTotal] = useState('');
	const isDisabled =
		!Number(reduceToValidNumber(stopLimitOrder.price)) ||
		!Number(reduceToValidNumber(stopLimitOrder.quantity)) ||
		!Number(reduceToValidNumber(stopLimitOrder.trigger_stop_limit_price));
	// ||
	// Number(stopLimitOrder.quantity) > currentAssetPairLimitsAmountMax ||
	// Number(stopLimitOrder.quantity) < currentAssetPairLimitsAmountMin;

	const isMaxAmountError =
		authIsAuthenticated &&
		Number(reduceToValidNumber(stopLimitOrder.quantity)) &&
		Number(reduceToValidNumber(stopLimitOrder.quantity)) > currentAssetPairLimitsAmountMax;

	const isMaxAmountSellError =
		authIsAuthenticated &&
		Number(reduceToValidNumber(stopLimitOrder.quantity)) &&
		mode === 'Sell' &&
		Number(reduceToValidNumber(stopLimitOrder.quantity)) > balance;
	// const isMaxAmountBuyError =
	// 	authIsAuthenticated &&
	// 	Number(stopLimitOrder.quantity) &&
	// 	mode === 'Buy' &&
	// 	Number(stopLimitOrder.quantity) > balance;
	const isMinAmountError =
		authIsAuthenticated &&
		Number(reduceToValidNumber(stopLimitOrder.quantity)) &&
		Number(reduceToValidNumber(stopLimitOrder.quantity)) < currentAssetPairLimitsAmountMin;

	const isBuyTotalError =
		authIsAuthenticated && mode === 'Buy' && Number(total) && Number(total) > balance;

	const pairCrossCourse = () => {
		return assetPairsList?.find((pair) => {
			return pair.code === currentPair.pair;
		})?.last_price;
	};

	const reduceToNumber = (value: string | number) => {
		let result: string | string[] = String(value).split('');
		if (!value) {
			return '';
		}
		const strVal = String(value);
		if (/^\d+[.,]?\d*$/.test(strVal)) {
			const index = result.indexOf(',');
			if (index !== -1) {
				result[index] = '.';
			}
			result = result
				.map((item) => {
					if (/\d+/.test(item)) {
						return item;
					}
					if (/\./.test(item)) {
						return '.';
					}
					return null;
				})
				.filter(Boolean)
				.join('');
		}
		return result;
	};

	useEffect(() => {
		if (stopLimitOrder.quantity === '0') {
			setTotal('');
		}
	}, [stopLimitOrder.quantity]);

	useEffect(() => {
		if (!total) {
			setStopLimitOrder({ ...stopLimitOrder, quantity: '' });
		}
	}, [total]);

	useEffect(() => {
		setStopLimitOrder({ ...initState, price: String(pairCrossCourse()) });
		setTotal('');
	}, [currentPair]);

	const handleChangeTotal = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		console.log(value);
		console.log(String(fixedCropNumber(Number(value), 6)));
		if (value === '') {
			setTotal(value);
			return;
		}

		if (numberValidation(value) && stopLimitOrder.price) {
			setStopLimitOrder({
				...stopLimitOrder,
				quantity: String(
					fixedCropNumber(
						Number(reduceToNumber(value)) / Number(reduceToNumber(stopLimitOrder.price)),
						6,
					),
				),
			});
			setTotal(String(value));
		}
	};

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;

		if (value === '' || numberValidation(value)) {
			setStopLimitOrder({
				...stopLimitOrder,
				[name]: value,
			});
			if (name === 'quantity' && stopLimitOrder.price) {
				const newPercent =
					(Number(stopLimitOrder.price) * Number(reduceToNumber(value)) * 100) / balance;
				setPercent(newPercent);
				setTotal(
					String(Number(reduceToNumber(value)) * Number(reduceToValidNumber(stopLimitOrder.price))),
				);
			}
			if (name === 'price' && stopLimitOrder.quantity) {
				setTotal(
					String(
						Number(reduceToNumber(value)) * Number(reduceToValidNumber(stopLimitOrder.quantity)),
					),
				);
			}
		}
	};

	const cutNumberToDecimals = (num: number | string, dec: number) => {
		let res = num;
		if (typeof res === 'number') {
			res = String(num);
		}
		const afterComa =
			String(reduceToValidNumber(res)).split('.')?.[1]?.slice(0, Math.abs(dec)) || '0';
		const fullNumber = [String(reduceToValidNumber(res)).split('.')[0], afterComa].join('.');
		return Number(fullNumber);
	};

	const handleTradeClick = () => {
		const { quantity, price, trigger_stop_limit_price } = stopLimitOrder;

		if (quantity && price && trigger_stop_limit_price) {
			// if (marketType === 'cross' || marketType === 'isolated') {
			// 	const createOrderData = {
			// 		...stopLimitOrder,
			// 		quantity: fixedCropNumber(Number(quantity), 6),
			// 		price: fixedCropNumber(Number(price), 6),
			// 		trigger_stop_limit_price: fixedCropNumber(Number(trigger_stop_limit_price), 6),
			// 		[`${marketType === 'cross' ? 'is_margin' : 'is_isolated'}`]: true,
			// 	};
			// 	// dispatch(createStopLimitOrderRequest(createOrderData));
			// 	setStopLimitOrder({
			// 		...initState,
			// 	});
			// 	setTotal('');
			// 	return;
			// }
			let marketOrderType = 7;
			if (marketType === 'isolated') {
				marketOrderType = 9;
			}

			const createOrderData = {
				...stopLimitOrder,
				order_type_id: marketOrderType,
				quantity: Number(cutNumberToDecimals(quantity, quantity_decimals)),
				price: Number(cutNumberToDecimals(price, decimals)),
				trigger_stop_limit_price: fixedCropNumber(
					Number(reduceToNumber(trigger_stop_limit_price)),
					6,
				),
			};

			dispatch(createStopLimitOrderRequest(createOrderData));
		}

		setStopLimitOrder({
			...initState,
		});
		setTotal('');
	};

	const countOrder = (value: number) => {
		const lastPrice = pairCrossCourse();
		if (mode === 'Buy') {
			if (!stopLimitOrder.price) {
				setStopLimitOrder({
					...stopLimitOrder,
					quantity: String(value / 100 / lastPrice!),
					price: String(lastPrice),
				});
			} else {
				setStopLimitOrder({
					...stopLimitOrder,
					quantity: String(value / 100 / Number(stopLimitOrder.price)),
				});
			}
			setTotal(String(fixedCropNumber(Number(value / 100), 6)));
		}
		if (mode === 'Sell') {
			if (!stopLimitOrder.quantity) {
				setStopLimitOrder({
					...stopLimitOrder,
					quantity: String(value),
					price: String(lastPrice),
				});
			} else {
				setStopLimitOrder({
					...stopLimitOrder,
					quantity: String(value / 100),
				});
			}
			setTotal(
				String(
					Number(reduceToNumber(stopLimitOrder.quantity)) *
						Number(reduceToNumber(stopLimitOrder.price)),
				),
			);
		}
	};

	const percentButtonCountValue = (percentValue: number): number => {
		if (!balance || !Number(percentValue)) {
			return 0;
		}

		if (
			mode === 'Buy' &&
			Number(reduceToValidNumber(stopLimitOrder?.price)) &&
			Number(reduceToValidNumber(stopLimitOrder?.trigger_stop_limit_price))
		) {
			return (balance / Number(reduceToValidNumber(stopLimitOrder?.price))) * percentValue;
		}

		return balance * percentValue;
	};

	const handlePercentChange = (value: number | number[]) => {
		if (!Array.isArray(value)) {
			setPercent(value);
		}
	};

	const getFieldClass = (fieldName: string): string => {
		switch (fieldName) {
			case 'amount':
				return isMaxAmountError || isMinAmountError || isMaxAmountSellError
					? 'trade-form__input input-form__item--error'
					: 'trade-form__input';

			case 'total':
				return isBuyTotalError && mode === 'Buy'
					? 'trade-form__input input-form__item--error'
					: 'trade-form__input';
			default:
				return 'trade-form__input';
		}
	};

	return (
		<>
			<label className="orderform-input">
				<span className="orderform-input__placeholder">
					{L.translate('Trade.Spot.SpotTradeBox.StopLimitOrder.stop_field_name')}
				</span>
				<input
					type="text"
					className="orderform-input__data"
					placeholder="0.00"
					autoComplete="off"
					value={stopLimitOrder.trigger_stop_limit_price}
					name="trigger_stop_limit_price"
					onChange={handleChangeInput}
				/>
				<span className="orderform-input__placeholder fw-700">
					{assetBalanceCode?.toUpperCase()}
				</span>
			</label>
			<label className="orderform-input">
				<span className="orderform-input__placeholder">
					{L.translate('Trade.Spot.SpotTradeBox.StopLimitOrder.limit_field_name')}
				</span>
				<input
					type="text"
					className="orderform-input__data"
					placeholder="0.00"
					autoComplete="off"
					value={stopLimitOrder.price}
					name="price"
					onChange={handleChangeInput}
				/>
				<span className="orderform-input__placeholder fw-700">
					{assetBalanceCode?.toUpperCase()}
				</span>
			</label>
			<label className="orderform-input">
				<span className="orderform-input__placeholder">
					{L.translate('Trade.Spot.SpotTradeBox.StopLimitOrder.amount_field_name')}
				</span>
				<input
					type="text"
					className="orderform-input__data"
					placeholder="0.00"
					autoComplete="off"
					value={stopLimitOrder.quantity}
					name="quantity"
					onChange={handleChangeInput}
				/>
				<span className="orderform-input__placeholder fw-700">
					{assetToTradeCode?.toUpperCase()}
				</span>
			</label>
			{/* {isMaxAmountError ? (
				<TextError>
					{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.max_amount_error_text')}{' '}
					{convertExponentialToDecimal(currentAssetPairLimitsAmountMax)}{' '}
					{assetToTradeCode?.toUpperCase()}
				</TextError>
			) : null}
			{isMinAmountError ? (
				<TextError>
					{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.min_amount_error_text')}{' '}
					{convertExponentialToDecimal(currentAssetPairLimitsAmountMin)}{' '}
					{assetToTradeCode?.toUpperCase()}
				</TextError>
			) : null}
			{isMaxAmountSellError ? (
				<TextError>
					{L.translate('Trade.Spot.SpotTradeBox.LimitOrder.amount_available_balance_error_text')}
				</TextError>
			) : null} */}

			{/* <PercentRadioButtons
				countOrder={countOrder}
				percentButtonCountValue={percentButtonCountValue}
				mode={mode}
				amount={stopLimitOrder.quantity}
				precents={[0, 0.25, 0.5, 0.75, 1]}
			/> */}
			<div className="orderform-slider">
				<PersentSlider
					value={percent}
					onChange={handlePercentChange}
					countOrder={countOrder}
					percentButtonCountValue={percentButtonCountValue}
				/>
			</div>
			<label className="orderform-input">
				<span className="orderform-input__placeholder">
					{L.translate('Trade.Spot.SpotTradeBox.StopLimitOrder.total_field_name')}
				</span>
				<input
					type="text"
					className="orderform-input__data"
					placeholder="0.00"
					autoComplete="off"
					name="total"
					onChange={handleChangeTotal}
					value={total}
				/>
				<span className="orderform-input__placeholder fw-700">
					{assetBalanceCode?.toUpperCase()}
				</span>
			</label>
			{/* {authIsAuthenticated && isBuyTotalError && mode === 'Buy' ? (
				<TextError>
					{L.translate('Trade.Spot.SpotTradeBox.StopLimitOrder.total_error_text')}
				</TextError>
			) : null} */}

			{authIsAuthenticated ? (
				<button
					className={`button button--full-width ${
						mode === 'Buy' ? 'button--type12' : 'button--type13'
					} trade-form__btn`}
					type="button"
					onClick={handleTradeClick}
					// disabled={isDisabled || !!isBuyTotalError || !!isMaxAmountSellError}
					disabled={isDisabled}
				>
					{mode === 'Buy'
						? L.translate('Trade.Spot.SpotTradeBox.OrderButtons.buy_button')
						: L.translate('Trade.Spot.SpotTradeBox.OrderButtons.sell_button')}{' '}
					{assetToTradeCode?.toUpperCase()}
				</button>
			) : (
				<div className="create-order-btn-login-register-wrapper">
					<LoginOrRegister />
				</div>
			)}
		</>
	);
};
export default StopLimitOrder;
