import React, { useEffect } from 'react';
import L from 'i18n-react';

const Contract = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="static-section section-gradient">
			<div className="inside">
				<p className="section-title">
					{String(L.translate('StaticPages.ContractPage.contract_title'))}
				</p>
				<div className="static-content">
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_1'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_2'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_3'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_4'))}</p>
					<h3>{String(L.translate('StaticPages.ContractPage.header_1'))}</h3>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_5'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_6'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_7'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_8'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_9'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_10'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_11'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_12'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_13'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_14'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_15'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_16'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_17'))}</p>
					<h3>{String(L.translate('StaticPages.ContractPage.header_2'))}</h3>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_18'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_19'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_20'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_21'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_22'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_23'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_24'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_25'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_26'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_27'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_28'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_29'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_30'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_31'))}</p>
					<p>{String(L.translate('StaticPages.ContractPage.paragraph_32'))}</p>
				</div>
			</div>
		</section>
	);
};

export default Contract;
