import L from 'i18n-react';
import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { WalletLayout } from 'layouts/Wallet';
import { useDispatch } from 'react-redux';
import NavButtons from 'components/TransactionHistory/NavButtons';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import TransactionHistoryCrypto from './Crypto';
import TransactionHistoryConvert from './Convert';
import TransactionHistoryTranfer from './Transfer';
import TransactionHistoryFiat from './Fiat';

const TransactionHistory = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAssetsRequest());
	}, [dispatch]);

	const routeParams = useParams<{ transactionType?: string }>();
	const { transactionType } = routeParams;

	const history = useHistory();

	const goBack = () => {
		history.goBack();
	};

	const currentHistoryComponent = () => {
		switch (transactionType) {
			case 'crypto': {
				return <TransactionHistoryCrypto />;
			}
			case 'convert': {
				return <TransactionHistoryConvert />;
			}
			case 'transfer': {
				return <TransactionHistoryTranfer />;
			}
			case 'fiat': {
				return <TransactionHistoryFiat />;
			}
			default: {
				return <TransactionHistoryCrypto />;
			}
		}
	};

	return (
		<WalletLayout>
			<section className="transaction-history-section section-gradient">
				<div className="transaction-history">
					<div className="transaction-history-top">
						<div className="deposit-content-header">
							<button type="button" onClick={goBack} className="deposit-content-header__back">
								<svg
									width="21"
									height="21"
									viewBox="0 0 21 21"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M4.875 10.4961C4.875 10.1509 5.15482 9.87109 5.5 9.87109H15.5C15.8452 9.87109 16.125 10.1509 16.125 10.4961C16.125 10.8413 15.8452 11.1211 15.5 11.1211H5.5C5.15482 11.1211 4.875 10.8413 4.875 10.4961Z"
										fill="#1C1C29"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M10.9419 5.05806C11.186 5.30214 11.186 5.69786 10.9419 5.94194L6.38388 10.5L10.9419 15.0581C11.186 15.3021 11.186 15.6979 10.9419 15.9419C10.6979 16.186 10.3021 16.186 10.0581 15.9419L5.05806 10.9419C4.81398 10.6979 4.81398 10.3021 5.05806 10.0581L10.0581 5.05806C10.3021 4.81398 10.6979 4.81398 10.9419 5.05806Z"
										fill="#1C1C29"
									/>
								</svg>
							</button>
							<p>{String(L.translate('TransactionHistory.title'))}</p>
						</div>
						<NavButtons />
						{currentHistoryComponent()}
					</div>
				</div>
			</section>
		</WalletLayout>
	);
};

export default TransactionHistory;
