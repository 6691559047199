import React, { useEffect } from 'react';
import L from 'i18n-react';

const Perpetual = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="static-section section-gradient">
			<div className="inside">
				<p className="section-title">
					{String(L.translate('StaticPages.PerpetualMarketPage.perpetual_title'))}
				</p>
				<div className="static-content">
					<p>{String(L.translate('StaticPages.PerpetualMarketPage.paragraph_1'))}</p>
					<p>{String(L.translate('StaticPages.PerpetualMarketPage.paragraph_2'))}</p>
					<h3>{String(L.translate('StaticPages.PerpetualMarketPage.header_1'))}</h3>
					<p>{String(L.translate('StaticPages.PerpetualMarketPage.paragraph_3'))}</p>
					<h3>{String(L.translate('StaticPages.PerpetualMarketPage.header_2'))}</h3>
					<p>{String(L.translate('StaticPages.PerpetualMarketPage.paragraph_4'))}</p>
					<p>{String(L.translate('StaticPages.PerpetualMarketPage.paragraph_5'))}</p>
					<p>{String(L.translate('StaticPages.PerpetualMarketPage.paragraph_6'))}</p>
					<h3>{String(L.translate('StaticPages.PerpetualMarketPage.header_3'))}</h3>
					<p>{String(L.translate('StaticPages.PerpetualMarketPage.paragraph_7'))}</p>
					<p>{String(L.translate('StaticPages.PerpetualMarketPage.paragraph_8'))}</p>
					<p>{String(L.translate('StaticPages.PerpetualMarketPage.paragraph_9'))}</p>
					<h3>{String(L.translate('StaticPages.PerpetualMarketPage.header_4'))}</h3>
					<p>{String(L.translate('StaticPages.PerpetualMarketPage.paragraph_10'))}</p>
					<p>{String(L.translate('StaticPages.PerpetualMarketPage.paragraph_11'))}</p>
					<h3>{String(L.translate('StaticPages.PerpetualMarketPage.header_5'))}</h3>
					<p>{String(L.translate('StaticPages.PerpetualMarketPage.paragraph_12'))}</p>
					<p>{String(L.translate('StaticPages.PerpetualMarketPage.paragraph_13'))}</p>
					<p>{String(L.translate('StaticPages.PerpetualMarketPage.paragraph_14'))}</p>
					<p>{String(L.translate('StaticPages.PerpetualMarketPage.paragraph_15'))}</p>
				</div>
			</div>
		</section>
	);
};

export default Perpetual;
