import { FC } from 'react';
import { getLocaleTimeFromTimestamp } from 'services/utils/dateAndTimeHelpers';

type ITradeTableProps = {
	price: number;
	amount: number;
	time: number;
	color: string;
};

const TradeTableItem: FC<ITradeTableProps> = ({ price, amount, time, color }) => {
	return (
		<div className="trades-list__line">
			<span className={color}>{price}</span>
			<span>{amount}</span>
			<span>{getLocaleTimeFromTimestamp(time)}</span>
		</div>
	);
};
export default TradeTableItem;
