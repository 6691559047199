/* eslint-disable @typescript-eslint/naming-convention */
import useDecimals from 'hooks/useDecimals';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { setTempOrderPrice } from 'redux/reducers/orders/reducer';
import { getLocaleTimeFromTimestamp } from 'services/utils/dateAndTimeHelpers';
import { fixedCropNumber } from 'services/utils/fixedCropNumber';
import { IRecentTradesTableItemProps } from './types';

const RecentTradesTableItem: FC<IRecentTradesTableItemProps> = ({ data }) => {
	const { price, quantity, created_at, type } = data;
	const dispatch = useDispatch();

	const [priceDecimal] = useDecimals();

	const getPriceColorClass = (typeValue: string): string => {
		return `table-value ${typeValue === 'sell' ? 'red' : 'green'}`;
	};

	const handleSetTempOrderPrice = () => {
		dispatch(setTempOrderPrice(Number(price)));
	};

	return (
		<div className="trades-list__line" onClick={handleSetTempOrderPrice}>
			{/* <span className={getPriceColorClass(type)}> */}
			<span className="table-value">{fixedCropNumber(Number(price), priceDecimal) || price}</span>

			<span>{Number(quantity).toFixed(6)}</span>

			<span>{getLocaleTimeFromTimestamp(created_at)}</span>
		</div>
	);
};

export default RecentTradesTableItem;
