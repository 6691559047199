import { FC, useMemo } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { getPerpetualOpenPositions, getBaseAsset } from 'redux/reducers/perpetual/selectors';
import AdjustLeverageTableItem from './AdjustLeverageTableItem';
import { IAdjustLeverageMaticPopup } from './types';

const AdjustLeverageMaticPopup: FC<IAdjustLeverageMaticPopup> = ({
	leverage,
	onSubmit,
	onClose,
}) => {
	const openedPositions = useSelector(getPerpetualOpenPositions);
	const baseAsset = useSelector(getBaseAsset);

	const currentPosition = openedPositions?.data?.find(
		(item) => item.base_asset === baseAsset?.base_asset,
	);

	const {
		entry_price: entryPrice,
		amount_exequted_in_usdt: amountExequtedInUsdt,
		amount_leverage_deposit_in_usdt: amountLeverageDepositInUsdt,
		amount_base_asset: amountBaseAsset,
		transactions_type: transactionsType,
	} = currentPosition || {};

	const newMargin = entryPrice && entryPrice / leverage;

	let newLiquidationPrice;
	if (amountExequtedInUsdt && amountBaseAsset && entryPrice) {
		newLiquidationPrice =
			transactionsType === 'buy'
				? (amountExequtedInUsdt - leverage) / amountBaseAsset
				: entryPrice / (amountExequtedInUsdt / leverage) + Number(entryPrice);
	}

	const handleClose = () => {
		onClose && onClose();
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--width800">
					<button className="popup__close" type="button" onClick={handleClose}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
								fill="#1C1C29"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
								fill="#1C1C29"
							/>
						</svg>
					</button>
					<div className="popup-header">
						<p className="popup-header__title">
							{String(L.translate('Trade.Perpetual.Modals.adjust_leverage'))} (
							{baseAsset?.base_asset?.toUpperCase() || ''}/USDT)
						</p>
					</div>

					<div className="popup-body">
						<div className="adjust-leverage-pop">
							<div className="adjust-leverage-title">
								<p>{String(L.translate('Trade.Perpetual.Modals.update_the_leverage_to'))}</p>
								<button
									className="button button--type-text2 button--type-text2-fz14 fw-700"
									type="button"
								>
									{String(L.translate('Trade.Perpetual.Modals.isolated'))} {leverage}X
								</button>
							</div>
							<div className="adjust-leverage-table">
								<AdjustLeverageTableItem
									leverage={leverage}
									margin={newMargin || undefined}
									liquidationPrice={newLiquidationPrice || undefined}
								/>
							</div>
						</div>
					</div>
					<div className="popup-footer popup-footer--item2">
						<button
							className="button button--type3 button--size2 button--full-width "
							type="button"
							onClick={handleClose}
						>
							{String(L.translate('Trade.Perpetual.Modals.cancel'))}
						</button>
						<button
							className="button button--size2 button--full-width"
							type="button"
							onClick={onSubmit}
						>
							{String(L.translate('Trade.Perpetual.Modals.confirm'))}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
export default AdjustLeverageMaticPopup;
