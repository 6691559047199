import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCreateNewPassIsLoad } from 'redux/reducers/auth/selectors';
import { createNewPasswordRequest } from 'redux/reducers/auth/reducer';
import { Auth } from 'layouts/Auth';
import CreateNewPassForm from 'components/Forms/CreateNewPassForm';
import { ICreateNewPasswordFormData } from 'components/Forms/CreateNewPassForm/types';
import GoogleCaptcha from 'components/GoogleCaptcha';
import Loader from 'ui/Loader';

// ==========================================:
export const CreateNewPassword: FC = () => {
	const createNewPassLoad = useSelector(getCreateNewPassIsLoad);
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const [newPassData, setNewPassData] = useState<null | ICreateNewPasswordFormData>(null);

	const handleCreateNewPass = (values: ICreateNewPasswordFormData) => {
		setNewPassData(values);
		setOpen(true);
	};

	const hangleCaptchaClose = (): void => {
		setNewPassData(null);
		setOpen(false);
	};

	const handleCaptcha = (value: string): void => {
		if (newPassData && value) {
			const data = {
				...newPassData,
				// TODO: Now there is no captha on backend
				// captcha: value
			};
			dispatch(createNewPasswordRequest(data));
		}

		setNewPassData(null);
		setOpen(false);
	};

	return (
		<Auth title="Create new password">
			<div className="authorization-section">
				<div className="authorization-section__container">
					<div className="authorization">
						<CreateNewPassForm createNewPassSubmit={handleCreateNewPass} />
						{createNewPassLoad && (
							<div className="auth-loader-wrapper">
								<Loader />
							</div>
						)}
					</div>
				</div>
			</div>
			{open && (
				<div className="popup-window popup-window--captcha">
					<div className="popup-window__inside">
						<GoogleCaptcha setCaptcha={handleCaptcha} captchaClose={hangleCaptchaClose} />
						<div className="popup-window__close" onClick={hangleCaptchaClose} />
					</div>
				</div>
			)}
		</Auth>
	);
};
