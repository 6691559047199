import { FC, useMemo } from 'react';
import Slider from 'rc-slider';
import { IPersentSlider } from './types';

const DEFAULT_SLIDER_VALUES = [0, 25, 50, 75, 100];

const innerMarkStyles = {
	transform: 'translateY(-35px)',
	fontSize: 16,
};

const getMarkStyle = (idx: number, amount: number) => {
	if (idx === amount - 1) {
		return {
			transform: 'translateX(-100%)',
		};
	}
	return {};
};

const PersentSlider: FC<IPersentSlider> = ({
	min = 0,
	max = 100,
	values = DEFAULT_SLIDER_VALUES,
	onChange,
	countOrder,
	percentButtonCountValue,
	unitLabel,
	markStyles = {},
	...rest
}) => {
	const marks = useMemo(() => {
		return values.reduce((acc, curr: number, idx: number, arr: number[]) => {
			return {
				...acc,
				[curr]: {
					style: getMarkStyle(idx, arr.length),
					label: (
						<div style={{ ...innerMarkStyles, ...markStyles }}>
							<span>
								{curr}
								{unitLabel || '%'}
							</span>
						</div>
					),
				},
			};
		}, {});
	}, [markStyles, unitLabel, values]);

	const sliderProps = {
		marks,
		handleStyle: {
			borderRadius: '50%',
		},
	};

	const handleChange = (value: number | number[]): void => {
		countOrder(percentButtonCountValue(Number(value)));
		if (onChange) {
			onChange(value);
		}
	};

	return <Slider min={min} max={max} onChange={handleChange} {...sliderProps} {...rest} />;
};

export default PersentSlider;
