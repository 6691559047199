import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IOptionsListItem } from 'redux/reducers/options/types';
import {
	STRUCTURE,
	POSITIONS,
	OPTIONS_ALL,
	TRADE_HISTORY,
	ORDER_HISTORY,
	ACTIVE_ORDERS,
} from 'redux/reducers/options/mock';
import { IOptionsTradeApi } from './types';

export const optionsTrade: any = {
	getAllOptions: () => {
		return http
			.get<IOptionsListItem[]>(endpoint.optionsTrade.GET_ALL_OPTIONS)
			.then((response) => response.data);
	},
	getOptionStatistic: (params: string | number) =>
		http
			.get<any>(endpoint.optionsTrade.GET_STATISTICS, { params })
			.then((response) => response.data),
	createOrder: (payload: any) =>
		http.post(endpoint.optionsTrade.CREATE_ORDER, payload).then((response) => response.data),
	getStructure: () => http.get(endpoint.optionsTrade.STRUCTURE).then((response) => response.data),
	getStatistics: () => http.get(endpoint.optionsTrade.STATISTICS).then((response) => response.data),
	getPositions: () => http.get(endpoint.optionsTrade.POSITIONS).then((response) => response.data),
	closePosition: (params: any) =>
		http.delete(endpoint.optionsTrade.CLOSE_POSITION, { params }).then((response) => response.data),
	getTradeHistory: (params: any) =>
		http.get(endpoint.optionsTrade.TRADE_HISTORY, { params }).then((response) => response.data),
	editOrder: (payload: any) =>
		http.put(endpoint.optionsTrade.ORDERS_UPDATE, payload).then((response) => response.data),
	cancelOrder: (params: any) =>
		http.delete(endpoint.optionsTrade.ORDERS_CANCEL, { params }).then((response) => response.data),
	getActiveOrders: () => http.get(endpoint.optionsTrade.ORDERS).then((response) => response.data),
	getOrdersHistory: (params: any) =>
		http.get(endpoint.optionsTrade.ORDER_HISTORY, { params }).then((response) => response.data),
	getOrderbookTrades: (params: any) =>
		http.get(endpoint.optionsTrade.ORDERBOOK_TRADES, { params }).then((response) => response.data),
	getAccountImMm: () =>
		http.get(endpoint.optionsTrade.ACCOUNT_IM_MM).then((response) => response.data),
};
