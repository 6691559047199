import { toast } from 'react-toastify';
import ToastifyComponent from 'components/ToastifyComponent';
import { toastifyConfig } from './toastifyConfig';

type NotificationContainer = (message: string, type: string, restProps?: { title: string }) => void;

// ==========================================:
export const notificationContainer: NotificationContainer = (message, type, restProps) => {
	switch (type) {
		case 'success':
			toast.success(
				<ToastifyComponent message={message} title={restProps?.title} />,
				toastifyConfig,
			);
			break;
		case 'error':
			toast.error(<ToastifyComponent message={message} title={restProps?.title} />, toastifyConfig);
			break;
		case 'info':
			toast.info(<ToastifyComponent message={message} title={restProps?.title} />, toastifyConfig);
			break;
		default:
			break;
	}
};
