// import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
	key: 'root',
	storage,
	// whitelist: ['auth', 'wallets'],
	blacklist: [
		'analytics',
		'app',
		'assetPairs',
		'assets',
		'marginWallets',
		'optionsTrade',
		'orders',
		'perpetualTrade',
		'settings',
		'sockets',
		'spotTrade',
		'tradingSettings',
		'twoFa',
		'wallets',
	],
	// transforms: [
	// 	encryptTransform({
	// 		secretKey: 'rT2FZ5y9f9',
	// 		onError: (error) => {
	// 			// eslint-disable-next-line no-console
	// 			console.log(error);
	// 		},
	// 	}),
	// ],
	// whitelist: ['auth'],
};

export { persistConfig };
