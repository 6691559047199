import { FC } from 'react';
import L from 'i18n-react';
import { WalletLayout } from 'layouts/Wallet';
import MarketTypeToggle from 'components/MarginWallets/MarketTypeToggle';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import TableEmptyPlug from 'ui/TableEmptyPlug';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { isIsolated } from './guards';
import { IInnterestPage } from './types';

const InterestPage: FC<IInnterestPage> = ({ interest, isolated, loading }) => {
	return (
		<WalletLayout title={String(L.translate('Wallets.MarginAccount.interest.html_title'))}>
			<div className="wallet-content">
				<div className="wallet-content-header flex flex-sb">
					<div className="wallet-content-header__title">
						<p>{String(L.translate('Wallets.MarginAccount.interest.title'))}</p>
					</div>
					{/* <MarketTypeToggle /> */}
				</div>
				<div className="wallet-content-tables">
					<div className={`table table--wallet table--interest-${isolated ? 'isolated' : 'cross'}`}>
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.coin'))}</p>
								</div>
								{isolated && (
									<div className="td">
										<p>{String(L.translate('Wallets.MarginAccount.columns.pair'))}</p>
									</div>
								)}
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.date'))}</p>
								</div>
								<div className="td">
									<p>{String(L.translate('Wallets.MarginAccount.columns.amount'))}</p>
								</div>
								<div className="td td--right">
									<p>{String(L.translate('Wallets.MarginAccount.columns.daily_interest_rate'))}</p>
								</div>
							</div>
						</div>
						<div className="table-body">
							{loading ? (
								<div className="popup-loader">
									<Loader />
								</div>
							) : (
								<>
									{!interest?.data || interest?.data?.length === 0 ? (
										<TableEmptyPlug />
									) : (
										interest?.data?.map((item) => (
											<div key={item.id} className="tr">
												<div className="td">
													<p>{item.asset.code.toUpperCase()}</p>
												</div>
												{isIsolated(item) && (
													<div className="td">
														<p>{item.pair.code.toUpperCase()}</p>
													</div>
												)}
												<div className="td">
													<p className="grey">{customDateFormat(item.date)}</p>
												</div>
												<div className="td">
													<p>{item.amount}</p>
												</div>
												<div className="td td--right">
													<p>{item.daily_interest_rate}</p>
												</div>
											</div>
										))
									)}
								</>
							)}
						</div>
					</div>
				</div>
				{interest?.last_page && Number(interest?.total) > Number(interest?.per_page) && (
					<Pagination lastPage={interest.last_page} getCurrentHref={(s) => String(s)} />
				)}
			</div>
		</WalletLayout>
	);
};

export default InterestPage;
