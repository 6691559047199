import { FC } from 'react';
import L from 'i18n-react';
import { IMarginBarProps } from './types';

const MarginBar: FC<IMarginBarProps> = ({ marginPairsTab, changeMarginPairsTabs }) => {
	return (
		<div className="margin-type">
			<button
				className={marginPairsTab === 'all' ? 'margin-type__btn active' : 'margin-type__btn'}
				name="all"
				onClick={changeMarginPairsTabs}
				type="button"
			>
				{String(L.translate('Trade.Spot.SpotPairs.all_pairs_title')).toUpperCase()}
			</button>
			<button
				className={marginPairsTab === 'cross' ? 'margin-type__btn active' : 'margin-type__btn'}
				name="cross"
				onClick={changeMarginPairsTabs}
				type="button"
			>
				{String(L.translate('Trade.Margin.TradeBox.cross_trading')).toUpperCase()}
			</button>
			<button
				className={marginPairsTab === 'isolated' ? 'margin-type__btn active' : 'margin-type__btn'}
				name="isolated"
				onClick={changeMarginPairsTabs}
				type="button"
			>
				{String(L.translate('Trade.Margin.TradeBox.isolated_trading')).toUpperCase()}
			</button>
		</div>
	);
};

export default MarginBar;
