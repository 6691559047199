/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	ETwoFaEnabledStatus,
	ITwoFaResponse,
	ITwoFaSmsEnableRequest,
	ITwoFaStatusResponse,
	ITwoFoStore,
} from './types';

// ==========================================:
export const initialState: ITwoFoStore = {
	twoFaDate: null,
	twoFaLoading: false,
	twoFaEnabledStatus: null,
};

// ==========================================:
const twoFa = createSlice({
	name: '@@twoFa',
	initialState,
	reducers: {
		twoFaCheckEnabledRequest: (state, { payload }: PayloadAction<string | undefined>) => {
			const stateTwoFa = state;
			stateTwoFa.twoFaLoading = true;
		},
		twoFaCheckEnabledSuccess: (state, action: PayloadAction<ITwoFaStatusResponse>) => {
			const { payload } = action;
			const stateTwoFa = state;
			stateTwoFa.twoFaEnabledStatus = payload.status;
		},
		twoFaCheckEnabledError: (state) => {
			const stateTwoFa = state;
			stateTwoFa.twoFaEnabledStatus = ETwoFaEnabledStatus.TWO_FA_DISABLED;
		},
		twoFaGenerateKeyRequest: (state) => {
			const stateTwoFa = state;
			stateTwoFa.twoFaDate = {
				secret: '',
				QR_Image: '',
				reauthenticating: '',
				twoFaDateLoading: true,
			};
		},
		twoFaGenerateKeySuccess: (state, action: PayloadAction<ITwoFaResponse>) => {
			const { payload } = action;
			const stateTwoFa = state;
			stateTwoFa.twoFaDate = {
				...payload,
				twoFaDateLoading: false,
			};
		},
		twoFaEnableRequest: (state, action: PayloadAction<string>) => {
			const stateTwoFa = state;
			stateTwoFa.twoFaLoading = true;
		},
		twoFaDisableRequest: (state, action: PayloadAction<string>) => {
			const stateTwoFa = state;
			stateTwoFa.twoFaLoading = true;
		},
		twoFaSmsEnableRequest: (state, action: PayloadAction<ITwoFaSmsEnableRequest>) => {
			const stateTwoFa = state;
			stateTwoFa.twoFaLoading = true;
		},
		twoFaSmsDisableRequest: (state, action: PayloadAction<string>) => {
			const stateTwoFa = state;
			stateTwoFa.twoFaLoading = true;
		},
		twoFaLoadingDisable: (state) => {
			const stateTwoFa = state;
			stateTwoFa.twoFaLoading = false;
		},
		twoFaInitState: () => initialState,
	},
});
export default twoFa.reducer;
export const {
	twoFaCheckEnabledRequest,
	twoFaCheckEnabledSuccess,
	twoFaCheckEnabledError,
	twoFaGenerateKeyRequest,
	twoFaGenerateKeySuccess,
	twoFaEnableRequest,
	twoFaDisableRequest,
	twoFaLoadingDisable,
	twoFaSmsEnableRequest,
	twoFaSmsDisableRequest,
	twoFaInitState,
} = twoFa.actions;
