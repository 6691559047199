/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import usePagination from 'hooks/usePagination';
import { getAllPagesList } from './utils';
import { IPagination } from './types';

const Pagination: FC<IPagination> = ({ amountAroundCurrent = 2, lastPage }) => {
	const { currentPage, onPageClick } = usePagination();
	const pagesList = getAllPagesList(lastPage);
	let start = pagesList.indexOf(currentPage) - amountAroundCurrent;
	let end = pagesList.indexOf(currentPage) + Number(amountAroundCurrent);
	if (start < 0) {
		end -= start;
		start = 0;
	}
	if (end > pagesList.length - 1) {
		end = pagesList.length - 1;
	}

	const handlePrevClick = () => {
		if (currentPage === 1) return;
		onPageClick(currentPage - 1);
	};

	const handleNextClick = () => {
		if (currentPage === pagesList[pagesList.length - 1]) return;
		onPageClick(currentPage + 1);
	};

	return (
		<div className="pagination-block">
			<ul className="pagination">
				<li className={`arrow ${currentPage === pagesList[0] ? 'disabled' : ''}`}>
					<button type="button" onClick={handlePrevClick}>
						<svg
							className="fill"
							width="8"
							height="14"
							viewBox="0 0 8 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M7.0131 0.864853C7.27 0.971265 7.4375 1.22195 7.4375 1.50002V12.5C7.4375 12.7781 7.27 13.0288 7.0131 13.1352C6.75619 13.2416 6.46049 13.1828 6.26386 12.9862L0.763864 7.48616C0.495379 7.21767 0.495379 6.78237 0.763864 6.51388L6.26386 1.01388C6.46049 0.817261 6.75619 0.758441 7.0131 0.864853ZM2.22227 7.00002L6.0625 10.8402V3.15979L2.22227 7.00002Z"
								fill="#9D9DBC"
							/>
						</svg>
					</button>
				</li>
				{start !== 0 && (
					<li>
						<button type="button" onClick={() => onPageClick(pagesList[0])}>
							{pagesList[0]}
						</button>
					</li>
				)}
				{start > 1 && (
					<li>
						<a>...</a>
					</li>
				)}
				{pagesList.map((page, idx) => {
					if (idx >= start && idx <= end) {
						return (
							<li key={page} className={`${page === currentPage ? 'active' : ''}`}>
								<button type="button" onClick={() => onPageClick(page)}>
									{page}
								</button>
							</li>
						);
					}
					return null;
				})}
				{end < pagesList.length - 1 && (
					<li>
						<a>...</a>
					</li>
				)}
				{end !== pagesList.length - 1 && (
					<li>
						<button type="button" onClick={() => onPageClick(pagesList[pagesList.length - 1])}>
							{pagesList[pagesList.length - 1]}
						</button>
					</li>
				)}
				<li
					className={`arrow ${currentPage === pagesList[pagesList.length - 1] ? 'disabled' : ''}`}
				>
					<button type="button" onClick={handleNextClick}>
						<svg
							className="fill"
							width="8"
							height="14"
							viewBox="0 0 8 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M0.903897 0.864853C1.1608 0.758441 1.4565 0.817261 1.65313 1.01388L7.15313 6.51388C7.42161 6.78237 7.42161 7.21767 7.15313 7.48616L1.65313 12.9862C1.4565 13.1828 1.1608 13.2416 0.903897 13.1352C0.646996 13.0288 0.479492 12.7781 0.479492 12.5V1.50002C0.479492 1.22195 0.646996 0.971265 0.903897 0.864853ZM1.85449 3.15979V10.8402L5.69472 7.00002L1.85449 3.15979Z"
								fill="#1C1C29"
							/>
						</svg>
					</button>
				</li>
			</ul>
		</div>
	);
};

export default Pagination;
