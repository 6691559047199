/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from 'react';
import {
	getLocaleDateFromTimestamp,
	getLocaleTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import { transformPairCode } from 'services/utils/tradingPairHelpers';
import { ITradesHistoryItemProps } from './types';

const TradesHistoryItem: FC<ITradesHistoryItemProps> = ({ data }) => {
	const {
		created_at,
		pair,
		type,
		price_filled,
		buyer_fee,
		seller_fee,
		fee,
		total,
		amount_buy_asset,
		amount_sold_asset,
		side,
		price,
		executed,
		incoming_asset,
	} = data;

	const getMarketType = (typeValue: string): string => {
		if (typeValue?.includes('taker')) return 'Taker';

		if (typeValue?.includes('maker')) return 'Maker';

		return '-';
	};

	const getClassByType = (statusValue: string): string => {
		if (statusValue?.includes('taker')) return 'td-name td-name--red';

		if (statusValue?.includes('maker')) return 'td-name td-name--green';

		return 'td-name';
	};

	return (
		<div className="tr">
			<div className="td">
				<span className="td-name td-name--grey">
					{getLocaleDateFromTimestamp(created_at)} {getLocaleTimeFromTimestamp(created_at)}
				</span>
			</div>
			<div className="td">
				<span className="td-name">{transformPairCode(pair)}</span>
			</div>
			<div className="td">
				<span className={getClassByType(type)}>{getMarketType(type)}</span>
			</div>
			<div className="td">
				<span className="td-name">{Number(price).toFixed(8) || '-'}</span>
			</div>
			<div className="td">
				<span className="td-name">{Number(executed).toFixed(8) || '-'}</span>
			</div>
			{/* <div className="td">
				<span className="td-name">{Number(price_filled).toFixed(8)}</span>
			</div> */}
			<div className="td">
				<span className="td-name">
					{/* TODO: Разобраться с fee */}
					{/* {Number(side === 'buy' ? buyer_fee : seller_fee).toFixed(8) || '-'} */}
					{Number(fee).toFixed(8) || '-'} {incoming_asset}
				</span>
			</div>
			<div className="td td--right">
				<span className="td-name">
					{/* {type?.includes('sell') && Number(asset_sold_change).toFixed(8)} */}
					{/* {type?.includes('buy') && Number(asset_get_change).toFixed(8)} */}
					{/* TODO: Разобраться с Total */}
					{/* {Number(side === 'buy' ? amount_buy_asset * price : amount_sold_asset).toFixed(8)} */}
					{Number(total).toFixed(8)} {incoming_asset}
				</span>
			</div>
		</div>
	);
};

export default TradesHistoryItem;
