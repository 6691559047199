/* eslint-disable */
export class BlackScholesService
{
    // https://github.com/gauss314/back-scholes-model

    public fi(x: number)
    {
        const Pi = 3.141592653589793238;
        const a1 = 0.319381530;
        const a2 = -0.356563782;
        const a3 = 1.781477937;
        const a4 = -1.821255978;
        const a5 = 1.330274429;
        const L = Math.abs(x);
        const k = 1 / (1 + 0.2316419 * L);
        let p = 1 - 1 / Math.pow(2 * Pi, 0.5) * Math.exp(-Math.pow(L, 2) / 2) * (a1 * k + a2 * Math.pow(k, 2)
                + a3 * Math.pow(k, 3) + a4 * Math.pow(k, 4) + a5 * Math.pow(k, 5));
        if (x >= 0) {
            return p;
        } else {
            return 1 - p;
        }
    }

    public normalInv(x: number)
    {
        return ((1 / Math.sqrt(2 * Math.PI)) * Math.exp(-x * x * 0.5));
    }


    // Funciones de Calculo de primas y griegas
    public bsCall(S0: number, K: number, r: number, T: number, sigma: number, q = 0)
    {
        const ret={mark_price: 0, delta: 0, gamma: 0, vega: 0, theta: 0, rho: 0, errors: ''};
        if (S0 > 0 && K > 0 && r >= 0 && T > 0 && sigma > 0) {
            let d1 = (Math.log(S0 / K) + (r - q + sigma * sigma * 0.5) * T) / (sigma * Math.sqrt(T));
            let d2 = d1 - sigma * Math.sqrt(T);
            ret.mark_price = Math.exp(-q * T) * S0 * this.fi(d1) - K * Math.exp(-r * T) * this.fi(d2);
            ret.delta = Math.exp(-q * T) * this.fi(d1);
            ret.gamma = (this.normalInv(d1) * Math.exp(-q * T)) / (S0 * sigma * Math.sqrt(T));
            ret.vega = 0.01 * S0 * Math.exp(-q * T) * this.normalInv(d1) * Math.sqrt(T);
            ret.theta = (1 / 365) * (-((S0 * sigma * Math.exp(-q * T)) / (2 * Math.sqrt(T))) * this.normalInv(
                        d1
                    ) - r * K * (Math.exp(-r * T)) * this.fi(d2) + q * S0 * (Math.exp(-q * T)) * this.fi(d1));
            ret.rho = 0.01 * K * T * Math.exp(-r * T) * this.fi(d2);
        } else {
            ret.errors = "Invalid values entered";
        }
        return ret;
    }


    public bsPut(S0: number, K: number, r: number, T: number, sigma: number, q = 0)
    {
        const ret={mark_price: 0, delta: 0, gamma: 0, vega: 0, theta: 0, rho: 0, errors: ''};
        if (S0 > 0 && K > 0 && r >= 0 && T > 0 && sigma > 0) {
            let d1 = (Math.log(S0 / K) + (r - q + sigma * sigma * 0.5) * T) / (sigma * Math.sqrt(T));
            let d2 = d1 - sigma * Math.sqrt(T);
            ret.mark_price = K * Math.exp(-r * T) * this.fi(-d2) - Math.exp(-q * T) * S0 * this.fi(-d1);
            ret.delta = -Math.exp(-q * T) * this.fi(-d1);
            ret.gamma = Math.exp(-q * T) * this.normalInv(d1) / (S0 * sigma * Math.sqrt(T));
            ret.vega = 0.01 * S0 * Math.exp(-q * T) * this.normalInv(d1) * Math.sqrt(T);
            ret.theta = (1 / 365) * (-((S0 * sigma * Math.exp(-q * T)) / (2 * Math.sqrt(T))) * this.normalInv(
                        d1
                    ) + r * K * (Math.exp(-r * T)) * this.fi(-d2) - q * S0 * (Math.exp(-q * T)) * this.fi(-d1));
            ret.rho = -0.01 * K * T * Math.exp(-r * T) * this.fi(-d2);
        } else {
            ret.errors = "Invalid values entered";
        }
        return ret;
    }


    public ivCall(S0: number, K: number, r: number, T: number, prima: number, q = 0)
    {
        let vi;
        if (S0 > 0 && K > 0 && r >= 0 && T > 0) {
            let maximasIteraciones = 300;
            let pr_techo = prima;
            let pr_piso = prima;
            let vi_piso = maximasIteraciones;
            for (let number = 1; number <= maximasIteraciones; number+=1) {
                let sigma = (number) / 100;
                let primaCalc = this.bsCall(S0, K, r, T, sigma, q)['mark_price'];
                if (primaCalc > prima) {
                    vi_piso = number - 1;
                    pr_techo = primaCalc;
                    break;
                } else {
                    pr_piso = primaCalc;
                }
            }

            let res = (pr_techo - pr_piso);
            res = res == 0 ? 1 : res;
            let rango = (prima - pr_piso) / res;

            vi = vi_piso + rango;
        } else {
            vi = "Unable to calculate IV because entered values are incorrect";
        }
        return vi;
    }


    public ivPut(S0: number, K: number, r: number, T: number, prima: number, q = 0)
    {
        let vi;
        if (S0 > 0 && K > 0 && r >= 0 && T > 0) {
            let maximasIteraciones = 300;
            let pr_techo = prima;
            let pr_piso = prima;
            let vi_piso = maximasIteraciones;
            for (let number = 1; number <= maximasIteraciones; number+=1) {
                let sigma = (number) / 100;
                let primaCalc = this.bsPut(S0, K, r, T, sigma, q)['mark_price'];
                if (primaCalc > prima) {
                    vi_piso = number - 1;
                    pr_techo = primaCalc;
                    break;
                } else {
                    pr_piso = primaCalc;
                }
            }

            let res = (pr_techo - pr_piso);
            res = res == 0 ? 1 : res;

            let rango = (prima - pr_piso) / res;
            vi = vi_piso + rango;
        } else {
            vi = "Unable to calculate IV because entered values are incorrect";
        }
        return vi;
    }

//    EXAMPLE:
//    S0 = spot price
//    K = strike price
//    r = free risk = 0.001;
//    T = time to Math.expire = 30 / 365
//    sigma = index volatility (IV) 1=100%
//    q = 0;
//    prima = last price

}
