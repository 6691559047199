import React from 'react';
import About from 'components/StaticPages/about';
import Trading from 'layouts/Trading';

const AboutPage = () => {
	return (
		<Trading title="About">
			<About />
		</Trading>
	);
};

export default AboutPage;
