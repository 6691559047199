import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLiqudationHistoryRequest } from 'redux/reducers/marginWallets/reducer';
import {
	getMarginWalletsIsLoader,
	getLiquidationIsolatedHistory,
} from 'redux/reducers/marginWallets/selectors';
import usePagination from 'hooks/usePagination';
import LiquidationPage from 'components/MarginWallets/LiquidationPage';

const LiquidationIsolated: FC = () => {
	const dispatch = useDispatch();
	const { perPage, currentPage } = usePagination();
	const loading = useSelector(getMarginWalletsIsLoader);
	const liquidation = useSelector(getLiquidationIsolatedHistory);

	useEffect(() => {
		dispatch(
			getLiqudationHistoryRequest({
				isolated: true,
				params: {
					per_page: perPage,
					current_page: currentPage,
				},
			}),
		);
	}, [currentPage, dispatch, perPage]);

	return <LiquidationPage liquidation={liquidation} isolated loading={loading} />;
};

export default LiquidationIsolated;
