export const endpoint = {
	auth: {
		LOGIN: '/v1/login',
		REFRESH_TOKEN: '/refresh',
		REGISTRATION: '/v1/register',
		LOGOUT: '/v1/logout',
		RESET_PASSWORD: '/v1/password_reset',
		NEW_PASSWORD: '/v1/user/password',
		RESET_EMAIL_CONFIRM: '/v1/reset_token',
		EMAIL_CONFIRM: '/v1/email_confirmation',
		IP_CONFIRM: '/whitelist_ip',
		GENERATE_SMS: '/v1/generate_sms',
		GENERATE_EMAIL_TOTP: '/v1/generate_email_totp',
		CONFIRM_PHONE: '/confirmation/phone',
		RESET_TWOFA: '/v1/2fa/reset',
		CHECK_TWOFA: '/v1/settings/2fa/check_enabled',
	},
	assetPairs: {
		ASSET_PAIRS: '/v1/spot/asset_pairs',
		TOP_ASSET_PAIRS: '/v1/spot/top_asset_pairs',
		GET_FAVORITE: '/v1/account/favorite_pairs',
		TOGGLE_FAVORITE: '/v1/account/toggle_favorite_pair',
		ASSET_PAIRS_FEES_AND_LIMITS: '/pairs/fees',
		MARKET_24_VOLUME_USD: '/v1/spot/market_24h_volume_usd',
	},
	// assets: {
	// 	GET_ASSETS: '/assets',
	// 	GET_ASSETS_BY_ID: (id: string) => `/assets/${id}`,
	// 	ASSET_PAIRS: (id: string) => `/asset_pairs/${id}`,
	// 	GET_GRAPH: (idPair: string, period: string) => `/candles/${idPair}/${period}`,
	// },
	assets: {
		ASSETS: '/v1/asset/active',
	},
	settings: {
		USER_SETTINGS: '/v1/account/user_data',
		SEND_EMAIL: '/v1/user/invitation/send',
		// CHANGE_PASSWORD: '/user_settings/change_password',
		ENABLE_2FA: '/v1/settings/2fa/enable',
		DISABLE_2FA: '/v1/settings/2fa/disable',
		ENABLE_SMS_2FA: '/v1/settings/sms/enable',
		DISABLE_SMS_2FA: '/v1/settings/sms/disable',
		GENERATE_2FA_KEY: '/v1/settings/2fa/generate_secret_key',
		CHECK_ENABLED_2FA: (email?: string) =>
			`/v1/settings/2fa/check_enabled${email ? `?email=${email}` : ''}`,
		SET_PHONE: '/v1/settings/set_phone',
		CHANGE_PHONE: 'v1/settings/change_phone',
		// CHECK_NOTIFICATION: '/user_settings/notification_settings',
		// UPDATE_NOTIFICATION: '/user_settings/notification_settings',
		REFERRALS: '/v1/user/invitations',
		GET_REFERRAL_BONUSES: '/v1/user/bonuses',
		KYC_VERIFICATION: '/v1/account/kyc/start',
		GET_SMS_CODE: `/v1/generate_sms`,
		SET_ANTIPHISHING_CODE: '/v1/account/anti_phishing',
		SET_NEW_PASSWORD: '/v1/settings/change_password',
		CHECK_ANTIPHISHING_STATUS: '/v1/account/check_anti_phishing',
		DISABLE_ANTIPHISHING: '/v1/account/disable_anti_phishing',
	},
	spotTrade: {
		ORDER_BOOK: (id: number) => `/v1/spot/order_book/${id}`,
		RECENT_TRADES: (pair: string) => `/v1/spot/trades/${pair}`,
		USER_TRADES: '/v1/spot/my-order-transactions',
		// USER_OPEN_ORDERS: '/my_orders/opened',
		USER_OPEN_ORDERS: '/v1/spot/my-orders',
		REMOVE_OPEN_ORDER: `v1/spot/order-cancel`,
		REMOVE_ALL_OPEN_ORDERS: 'v1/spot/order-cancel-all',
		USER_CLOSED_ORDERS: '/v1/spot/my-orders',
	},
	tradingChart: {
		CHART_HISTORY: '/v1/candle/history',
		FUTURES_HISTORY: '/v1/futures_candle/history',
	},
	orders: {
		CALCULATE_LIMIT_ORDER: '/calculate_limit_order',
		CALCULATE_MARKET_ORDER: '/calculate_market_order',
		CREATE_ORDER: '/v1/spot/order-create',
		CREATE_STOP_LIMIT_ORDER: '/v1/spot/order-create',
		MAKE_EXCHANGE: '/v1/spot/make-exchange',
		GET_EXCHANGE_RATE: 'v1/spot/get-exchange-rate',
		GET_ALL_ORDER_TYPES: '/v1/spot/order_types',
		GET_EXCHANGE_INFO: '/v1/spot/exchange_info',
	},
	wallets: {
		// marginAccount: {
		// 	CROSS: '/v1/wallet/cross',
		// 	ISOLATED: '/v1/wallet/isolated',
		// },
		WALLETS: (search_coin?: string) => `/v1/wallet/spot?search_coin=${search_coin || ''}`,
		GENERATE_ADDRESS: (wallet_id: number, network: string, asset_id: number) =>
			`/v1/crypto/get-deposit-address?wallet_id=${wallet_id}&network=${network}&asset_id=${asset_id}`,
		WITHDRAWALS_HISTORY: '/wallets/withdrawals',
		DEPOSITS_HISTORY: '/wallets/deposits',
		// CRYPTO_HISTORY: '/wallets/transactions',
		CRYPTO_HISTORY: '/v1/crypto/transactions',
		CONVERT_HISTORY: (
			from_asset_id: number | undefined,
			to_asset_id: number | undefined,
			past_days: number | undefined,
			current_page: number,
			per_page: number,
		) =>
			`/v1/spot/my-exchanges?
			${from_asset_id ? `asset_id=${from_asset_id}&` : ''}
			${to_asset_id ? `to_asset_id=${to_asset_id}&` : ''}
			${past_days ? `past_days=${past_days}&` : ''}
			current_page=${current_page}&per_page=${per_page}`,
		TRANSFER_HISTORY: '/v1/wallet/transfers',
		FIAT_HISTORY: '/v1/fiat/history',
		GET_WALLET_NETWORKS: (wallet_id: number, asset_id: number) =>
			`/v1/crypto/wallet-networks?wallet_id=${wallet_id}&asset_id=${asset_id}`,
		WITHDRAW_CRYPTO: '/v1/crypto/withdrawal',
		CHECK_CRYPTO_ADDRESS: '/v1/crypto/check-address',
	},
	marginWallets: {
		MARGIN_CROSS_WALLETS: '/v1/wallet/cross',
		MARGIN_ISOLATED_WALLETS: '/v1/wallet/isolated',
		// MARGIN_ISOLATED_WALLETS: '/margin/isolated/wallets',
		INDEX_PRICES: '/v1/spot/index_prices',
		TRANSFER_TO_MARGIN: `margin/cross/transfer/to_margin`,
		TRANSFER_TO_SPOT: 'margin/cross/transfer/to_spot',
		TRANSFER_ISOLATED_TO_MARGIN: 'margin/isolated/transfer/to_margin',
		TRANSFER_ISOLATED_TO_SPOT: 'margin/isolated/transfer/to_spot',
		BORROW_MAXIMUM_AMOUT: (assetId: number) => `margin/cross/borrow/available?asset_id=${assetId}`,
		BORROW_TAKE: (assetId: number, amount: string) =>
			`/margin/cross/borrow/take?asset_id=${assetId}&amount=${amount}`,
		BORROW_PAY_TAKE: (assetId: number, amount: string) =>
			`/margin/cross/borrow/pay?asset_id=${assetId}&amount=${amount}`,
		BORROW_AVAILABLE: `margin/isolated/borrow/available`,
		BORROW_ISOLATED_TAKE: '/margin/isolated/borrow/take',
		BORROW_ISOLATED_PAY_TAKE: '/margin/isolated/borrow/pay',
		GET_BORROW_CROSS: '/v1/wallet/margin/borrow/cross',
		GET_BORROW_ISOLATED: '/v1/wallet/margin/borrow/isolated',
		GET_HISTORY_TRANSFER: 'wallets/transfers',
		TAKE_BORROW_CROSS: '/v1/wallet/margin/borrow/cross',
		PAY_BORROW_CROSS: '/v1/wallet/margin/repayment/cross',
		MAKE_ISOLATED_BORROW: '/v1/wallet/margin/borrow/isolated',
		MAKE_ISOLATED_REPAY: '/v1/wallet/margin/repayment/isolated',
		GET_REPAY_CROSS: '/v1/wallet/margin/repayment/cross',
		GET_REPAY_ISOLATED: '/v1/wallet/margin/repayment/isolated',
		BORROW_CROSS_HISTORY: `/v1/wallet/margin/history/borrowing/cross`,
		BORROW_ISOLATED_HISTORY: '/v1/wallet/margin/history/borrowing/isolated',
		REPAY_HISTORY: '/v1/wallet/margin/history/repayment/cross',
		REPAY_HISTORY_ISOLATED: '/v1/wallet/margin/history/repayment/isolated',
		INTEREST_HISTORY: '/v1/wallet/margin/history/interest/cross',
		INTERSET_HISTORY_ISOLATED: '/v1/wallet/margin/history/interest/isolated',
		CALL_HISTORY: '/v1/wallet/margin/history/margin-calls/cross',
		CALL_HISTORY_ISOLATED: '/v1/wallet/margin/history/margin-calls/isolated',
		LIQUIDATION_HISTORY: '/v1/wallet/margin/history/liquidation-calls/cross',
		LIQUIDATION_HISTORY_ISOLATED: '/v1/wallet/margin/history/liquidation-calls/isolated',
		TRANSFER_FROM_SPOT_TO_CROSS: '/v1/wallet/margin/transfer/spot-cross',
		TRANSFER_FROM_CROSS_TO_SPOT: '/v1/wallet/margin/transfer/cross-spot',
		TRANSFER_FROM_SPOT_TO_ISOLATED: '/v1/wallet/margin/transfer/spot-isolated',
		TRANSFER_FROM_ISOLATED_TO_SPOT: '/v1/wallet/margin/transfer/isolated-spot',
	},
	payments: {
		PAYMENTS_PAYPAL: (amount: number, assetId: number) =>
			`/payment/paypal/deposit/new?amount=${amount}&asset_id=${assetId}`,
		PAYMENTS_HISTORY: `/payment/history`,
	},
	perpetualTrade: {
		ORDER_BOOK: (id: number) => `/v1/futures/orderbook?id=${id}`,
		RECENT_TRADES: (limit: number, base_asset: string) =>
			`/v1/futures/trades?limit=${limit}&base_asset=${base_asset}`,
		BASE_ASSET_STATISTICS: '/v1/futures/base_asset_statistics',
		ACTIVE_BASE_ASSET_STATISTICS: '/v1/futures/active_base_asset',
		ORDER_HISTORY: `/v1/futures/user/order_history`,
		BALANCE: '/v1/futures/available_balance',
		CREATE_ORDER: '/v1/futures/orders/create',
		GET_OPEN_POSITIONS: '/v1/futures/positions/open_positions',
		// Closed History - undefined
		GET_MY_ORDERS: '/v1/futures/user/orders',
		GET_MY_STOP_ORDERS: `/v1/futures/user/stop_orders`,
		GET_STOP_ORDER_HISTORY: `/v1/futures/user/stop_order_history`,
		GET_CLOSED_ORDER_HISTORY: `/v1/futures/user/closed_order_history`,
		// Stop orders - undefined
		// Stopped history - undefined
		GET_TRADE_HISTORY: '/v1/futures/user/trade_history',
		GET_TRANSACTIONS: '/v1/futures/user/transactions',
		CLOSE_POSITION_BY_MARKET: '/v1/futures/positions/close_by_market',
		CLOSE_POSITION_BY_LIMIT: '/v1/futures/positions/close_by_limit',
		CLOSE_POSITION_BY_TP_SL: '/v1/futures/positions/close_by_tp_sl',
		CLOSE_ALL_POSITIONS_BY_MARKET: '/v1/futures/positions/close_all_by_market',
		CANCEL_ORDER: '/v1/futures/orders/cancel',
		UNREALISED_PNL: `/v1/futures/positions/unpnl_by_user_asset`,
		ADD_MARGIN: '/v1/futures/positions/add_margin',
		TRANSFER_FROM_SPOT: '/v1/futures/transfer_from_spot',
		TRANSFER_TO_SPOT: '/v1/futures/transfer_to_spot',
		GET_USER_STAT: '/v1/futures/user/asset_stats',
		ACTIVE_BASE_ASSET: '/v1/futures/active_base_asset',
		DERIVATIVE_ACCOUNT: '/v1/futures/get-derivative-account',
	},
	optionsTrade: {
		GET_ALL_OPTIONS: '/v1/options/all',
		GET_STATISTICS: '/v1/options/statistic',
		CREATE_ORDER: '/v1/options/Orders/create',
		STRUCTURE: '/v1/options/structure',
		STATISTICS: '/v1/options/statistic',
		ALL: '/v1/options/all',
		POSITIONS: '/v1/options/positions',
		CLOSE_POSITION: '/v1/options/positions/close',
		ORDERS: '/v1/options/Orders',
		ORDERS_UPDATE: '/v1/options/Orders/update',
		ORDERS_CANCEL: '/v1/options/Orders/cancel',
		ORDER_HISTORY: '/v1/options/OrderHistory',
		TRADE_HISTORY: '/v1/options/TradeHistory',
		ACCOUNT_IM_MM: '/v1/options/account-im-mm',
		ORDERBOOK_TRADES: '/v1/options/order-book-recent-trade',
	},
	analytics: {
		analytics: '/v1/spot/market_data',
	},
};
