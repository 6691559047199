import moment from 'moment';

export const getFormattedTxID: (value: string) => string = (value) => {
	if (value.length > 19) {
		return `${value.slice(0, 9)}...${value.slice(value.length - 7, value.length)}`;
	}
	return value;
};

export const getFormattedDate: (timestamp: number) => string = (timestamp) => {
	return moment(timestamp).format('YYYY-MM-DD hh:mm:ss');
};
