/* eslint-disable no-debugger */
import { useEffect, useState, FC } from 'react';
import { CrossIcon } from 'assets/inline-svg';
import Popup from 'reactjs-popup';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import yup from 'services/utils/capsLockValidate';
import { getUsersSettings } from 'redux/reducers/settings/selectors';
import useTimer from 'hooks/useTimer';
import { generateSmsRequest } from 'redux/reducers/auth/reducer';
import ConfirmWithdrawForm from './ConfirmWithdrawForm';
import { IConfirmWithdrawModalProps } from './types';

const initialValues = {
	code: '',
};

const ConfirmWithdrawModal: FC<IConfirmWithdrawModalProps> = (props) => {
	const { actionType, onClose, onConfirm, ...rest } = props;
	const dispatch = useDispatch();
	const userSettings = useSelector(getUsersSettings);

	const [code, setCode] = useState('');

	const [timerValue, startTimer] = useTimer();

	const handleGetCode = () => {
		dispatch(generateSmsRequest({}));
		startTimer();
	};

	useEffect(() => {
		setCode('');
	}, [actionType]);

	const validationSchema = yup.object().shape({
		code: yup
			.number()
			.typeError(String(L.translate('WithdrawCrypto.Messages.code_must_be_a_number')))
			.required(String(L.translate('WithdrawCrypto.Messages.code_is_required'))),
	});

	return (
		<Popup open={!!actionType} onClose={onClose} closeOnEscape>
			<div className="popup-window confirm-withfrow-modal">
				<div className="popup-window__inside">
					<div className="popup">
						<button type="button" className="popup__close" onClick={onClose}>
							<CrossIcon />
						</button>

						<div className="popup-header">
							<p className="popup-header__title">
								{String(L.translate('WithdrawCrypto.withdrawal'))}
							</p>
						</div>

						<Formik
							initialValues={initialValues}
							validationSchema={
								actionType === '2fa-sms' || actionType === '2fa-google' ? validationSchema : null
							}
							validateOnChange
							onSubmit={(values) => {
								onConfirm(values.code);
							}}
						>
							<ConfirmWithdrawForm actionType={actionType} {...rest} />
						</Formik>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default ConfirmWithdrawModal;
