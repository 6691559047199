import { FC } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { appLanguage } from 'redux/reducers/app/selectors';
import { languages } from 'languages/index';
import { routesList } from 'routes/routesList';
import SocketConnectWrapper from 'components/SocketConnectWrapper';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';

const App: FC = () => {
	const language = useSelector(appLanguage);
	L.setTexts(languages[language]);

	return (
		<BrowserRouter>
			<SocketConnectWrapper>
				{/* <SocketWithPrivateTopicConnectWrapper topicName="private-notifications"> */}
				<Switch>
					{routesList?.map(({ path, component, exact = true }) => (
						<Route key={path} exact={exact} path={path} component={component} />
					))}
					<Redirect to="/" />
				</Switch>
				{/* </SocketWithPrivateTopicConnectWrapper> */}
			</SocketConnectWrapper>
		</BrowserRouter>
	);
};

export default App;
