/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useState } from 'react';
import L from 'i18n-react';
import { useDispatch } from 'react-redux';
import { removeOpenOrdersRequest } from 'redux/reducers/spotTrade/reducer';
import {
	getLocaleDateFromTimestamp,
	getLocaleTimeFromTimestamp,
} from 'services/utils/dateAndTimeHelpers';
import { transformPairCode } from 'services/utils/tradingPairHelpers';
import ConfirmDeletePopup from 'ui/ConfirmDeletePopup';
import { IOpenOrdersItemProps } from './types';

const OpenOrdersItem: FC<IOpenOrdersItemProps> = ({ data }) => {
	const {
		created_at,
		direction,
		price,
		quantity,
		quantity_left,
		asset_pair,
		trigger_stop_limit_price,
		id,
		total,
		price_average,
	} = data;

	const dispatch = useDispatch();
	const [openModal, setOpenModal] = useState(false);
	const closeModal = () => setOpenModal(false);

	const getType = (triggerValue: string | number | null, typeValue: string): string => {
		if (triggerValue) return 'Stop-Limit';

		return typeValue?.includes('market') ? 'Market' : 'Limit';
	};

	const getMarketType = (typeValue: string): string => {
		if (typeValue?.includes('sell')) return 'Sell';

		if (typeValue?.includes('buy')) return 'Buy';

		return '-';
	};

	const getClassByType = (typeValue: string): string => {
		if (typeValue?.includes('sell')) return 'td-name td-name--red';

		if (typeValue?.includes('buy')) return 'td-name td-name--green';

		return 'td-name';
	};

	const getTriggerConditions = () => {
		if (!trigger_stop_limit_price) return '-';

		const sign = direction?.includes('sell') ? '>=' : '<=';
		const value = Number(trigger_stop_limit_price).toFixed(6);

		return `${sign} ${value}`;
	};

	const filled = 100 - (Number(quantity_left) / Number(quantity)) * 100;

	const totalCalc = Number(quantity) * Number(price);

	const handleRemoveUserOpenOrder = () => {
		dispatch(removeOpenOrdersRequest({ order_id: id }));
	};

	return (
		<div className="tr tr--eleventhColumn">
			<div className="td">
				{getLocaleDateFromTimestamp(created_at)} {getLocaleTimeFromTimestamp(created_at)}
			</div>
			<div className="td">{transformPairCode(asset_pair?.code)}</div>
			<div className="td">{getType(trigger_stop_limit_price, direction)}</div>
			<div className="td">
				<p className={getClassByType(direction)}>{getMarketType(direction)}</p>
			</div>
			<div className="td">{Number(price_average).toFixed(6) || '-'}</div>
			<div className="td">{Number(price).toFixed(6) || '-'}</div>
			<div className="td">{Number(quantity).toFixed(6) || '-'}</div>
			<div className="td">{filled ? `${Number(filled).toFixed(2)} %` : '-'}</div>
			<div className="td">{Number(total || totalCalc).toFixed(6)}</div>
			<div className="td">{getTriggerConditions()}</div>
			<div className="td td--right">
				<button
					type="button"
					className="link status-table-text"
					onClick={() => setOpenModal((prevOpenModal) => !prevOpenModal)}
				>
					{String(L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.cancel_button'))}
				</button>
				<ConfirmDeletePopup
					title={String(
						L.translate('Trade.Spot.SpotHistoryTables.OpenOrdersTable.confirm_delete_order_title'),
					)}
					bodyMessage={String(
						L.translate(
							'Trade.Spot.SpotHistoryTables.OpenOrdersTable.confirm_delete_order_body_message',
						),
					)}
					openModal={openModal}
					closeModal={closeModal}
					handleDelete={handleRemoveUserOpenOrder}
				/>
			</div>
		</div>
	);
};

export default OpenOrdersItem;
