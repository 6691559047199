import React from 'react';
import Trading from 'layouts/Trading';
import Cookies from 'components/StaticPages/cookies';

const CookiesPage = () => {
	return (
		<Trading title="Cookies">
			<Cookies />
		</Trading>
	);
};

export default CookiesPage;
