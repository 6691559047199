import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import { IOptionsTradeStore } from './types';

// ==================================================================
const getOptionsTradeState = (state: TStoreState): IOptionsTradeStore => state.optionsTrade;
export const getCreateOrder = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.createOrder,
);

export const selectOrdersList = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.ordersList,
);
export const getStatisticLoader = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.statisticLoader,
);
export const showModal = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.showModal,
);
export const getNewOrderLoader = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.newOrderLoader,
);

// ==================================================================
export const getStructureLoader = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.structureLoader,
);

export const getStructure = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.structure,
);

// ==================================================================
export const getPositionsLoader = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.positionsLoader,
);

export const getPositions = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.positions,
);

// ==================================================================
export const getOptionsAllLoader = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.optionsAllLoader,
);

export const getOptionsAll = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.optionsAll,
);

// ==================================================================
export const getActiveOrdersLoader = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.activeOrdersLoader,
);

export const getActiveOrders = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.activeOrders,
);

// ==================================================================
export const getOrderHistoryLoader = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.orderHistoryLoader,
);

export const getOrderHistory = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.orderHistory,
);

// ==================================================================
export const getTradeHistoryLoader = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.tradeHistoryLoader,
);

export const getTradeHistory = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.tradeHistory,
);

// ==================================================================
export const getShowCancelOrderConfirm = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.showCancelOrderConfirm,
);

export const getOrdeBook = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.orderBook,
);

export const getOptionStatistics = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.optionStatistics,
);

export const getRecentTrades = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.recentTrades,
);

export const getUserImMm = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.userImMm,
);

export const getPrevLastPrice = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.prevLastPrice,
);

export const getLastPrice = createSelector(
	[getOptionsTradeState],
	(optionsTrade: IOptionsTradeStore) => optionsTrade.lastPrice,
);
