/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import {
	// IChangePassPayload,
	// ICheck2faEnables,
	// IDisable2faPayload,
	// IEnable2faPayload,
	// IGenerate2faKeyResponse,
	IUserSettingsData,
	TInvitationsDataResponse,
	// INotifications,
} from 'redux/reducers/settings/types';
import { ISettingsApi } from './types';

// ====================
// ======================:
export const settings: ISettingsApi = {
	userSettings: () =>
		http.get<IUserSettingsData>(endpoint.settings.USER_SETTINGS).then((response) => response.data),

	// changePassword: (payload) =>
	// 	http.put<IChangePassPayload>(endpoint.settings.CHANGE_PASSWORD, payload),

	// generate2faKey: () =>
	// 	http
	// 		.get<IGenerate2faKeyResponse>(endpoint.settings.GENERATE_2FA_KEY)
	// 		.then((response) => response.data),

	// check2faEnables: () =>
	// 	http
	// 		.get<ICheck2faEnables>(endpoint.settings.CHECK_ENABLED_2FA)
	// 		.then((response) => response.data),

	// enable2fa: (payload) => http.post<IEnable2faPayload>(endpoint.settings.ENABLE_2FA, payload),

	// disable2fa: (payload) => http.post<IDisable2faPayload>(endpoint.settings.DISABLE_2FA, payload),

	// setPhone: (payload) => http.post(endpoint.settings.SET_PHONE, payload.data),
	// checkNotification: () =>
	// 	http
	// 		.get<INotifications>(endpoint.settings.CHECK_NOTIFICATION)
	// 		.then((response) => response.data),
	// updateNotification: (payload) =>
	// 	http
	// 		.put<INotifications>(endpoint.settings.UPDATE_NOTIFICATION, payload)
	// 		.then((response) => response.data),
	emailSend: (payload) => http.post<any>(endpoint.settings.SEND_EMAIL, payload),
	getReferrals: () =>
		http
			.get<TInvitationsDataResponse>(endpoint.settings.REFERRALS)
			.then((response) => response.data),

	getReferralBonuses: () =>
		http.get(endpoint.settings.GET_REFERRAL_BONUSES).then(({ data }) => data),
	postKycVerification: (payload: any) =>
		http.post<any>(endpoint.settings.KYC_VERIFICATION, payload),
	setAntiPhishingCode: (payload: any) =>
		http.put<any>(endpoint.settings.SET_ANTIPHISHING_CODE, payload),
	setNewPassword: (payload: any) => http.put(endpoint.settings.SET_NEW_PASSWORD, payload),
	setPhone: (payload) => http.post(endpoint.settings.SET_PHONE, payload).then(({ data }) => data),
	getAntiphishingStatus: () =>
		http.get(endpoint.settings.CHECK_ANTIPHISHING_STATUS).then(({ data }) => data),
	disableAntiPhishing: (payload: any) => http.put(endpoint.settings.DISABLE_ANTIPHISHING, payload),
};
