export const ROUTES = {
	wallet: {
		spot: '/wallet/spot',
		marginAccount: {
			root: '/wallet/margin-account',
			openOrders: '/wallet/margin-account/open-orders',
			orderHistory: '/wallet/margin-account/order-history',
			tradeHistory: '/wallet/margin-account/trade-history',
			borrowing: '/wallet/margin-account/borrowing',
			repayment: '/wallet/margin-account/repayment',
			interest: '/wallet/margin-account/interest',
			calls: '/wallet/margin-account/calls',
			liquidationOrders: '/wallet/margin-account/liquidation-orders',
		},
		transactionHistory: '/wallet/transaction-history/:transactionType',
		derivatives: '/wallet/derivatives',
		depositCrypto: '/wallet/deposit-crypto',
		withdrawCrypto: '/wallet/withdraw-crypto',
	},
	accountSettings: {
		payments: '/account-settings/payments',
		general: '/account-settings/general',
		analytics: '/account-settings/analytics',
		twoFa: '/account-settings/twoFa',
		index: '/account-settings',
	},
	pairs: {
		spot: '/pairs/spot',
	},
	static: {
		about: '/about',
		faq: '/faq',
		cookies: '/cookies',
		terms: '/terms',
		glossary: '/glossary',
		spotMarginEtf: '/spotMarginEtf',
		perpetualPage: '/perpetualPage',
		contractPage: '/contractPage',
		articles: '/articles',
		privacy: '/privacy',
		security: '/security',
	},
};
