import { IPositionsItem } from 'redux/reducers/options/types';
import { IPosTabItemSummarized } from './types';

const initialSum = {
	im_coefficient: 0,
	mm_coefficient: 0,
	delta: 0,
	gamma: 0,
	vega: 0,
	teta: 0,
	unrealizedPnl: 0,
	qty: 0,
};

export const summarizeItems: (items: IPositionsItem[], optionsMap: any) => IPosTabItemSummarized = (
	items,
	optionsMap,
) => {
	const qtSum = items?.reduce((acc, curr) => {
		return curr?.amount_asset_underlying != null
			? acc + Number(curr?.amount_asset_underlying)
			: acc;
	}, 0);
	const priceSum = items?.reduce((acc, curr) => {
		return curr?.amount_exequted_in_usdt != null
			? acc + Number(curr?.amount_exequted_in_usdt)
			: acc;
	}, 0);
	return items?.reduce((acc, curr, _, arr) => {
		const option = curr?.option_id ? optionsMap?.[curr?.option_id] : null;
		let unrealizedPnl: number | undefined;

		if (
			curr &&
			option?.mark_price != null &&
			curr?.entry_price != null &&
			curr?.amount_asset_underlying != null &&
			option?.type_option
		) {
			if (curr?.transactions_type === 'buy') {
				unrealizedPnl =
					(Number(option?.mark_price) - Number(curr?.entry_price)) *
					Number(curr?.amount_asset_underlying);
			}
			if (curr?.transactions_type === 'sell') {
				unrealizedPnl =
					(Number(curr?.entry_price) - Number(option?.mark_price)) *
					Number(curr?.amount_asset_underlying);
			}
		}

		const result = {
			im_coefficient: (acc?.im_coefficient || 0) + (curr?.im_coefficient || 0),
			mm_coefficient: (acc?.mm_coefficient || 0) + (curr?.mm_coefficient || 0),
			avgPrice: arr?.length === 1 ? curr?.entry_price : priceSum / qtSum,
			delta: (acc?.delta || 0) + (Number(option?.delta) || 0),
			gamma: (acc?.gamma || 0) + (Number(option?.gamma) || 0),
			vega: (acc?.vega || 0) + (Number(option?.vega) || 0),
			teta: (acc?.teta || 0) + (Number(option?.teta) || 0),
			unrealizedPnl: (acc?.unrealizedPnl || 0) + Number(unrealizedPnl || 0),
			qty: (acc?.qty || 0) + Number(curr.amount_asset_underlying || 0),
		};
		result.delta = result.delta > 1 ? 1 : result.delta;
		return result;
	}, initialSum);
};

export const summarizeFolders: (items: IPosTabItemSummarized[]) => IPosTabItemSummarized = (
	items,
) => {
	return items?.reduce((acc, curr) => {
		const result = {
			im_coefficient: (acc?.im_coefficient || 0) + (curr?.im_coefficient || 0),
			mm_coefficient: (acc?.mm_coefficient || 0) + (curr?.mm_coefficient || 0),
			delta: (acc?.delta || 0) + (curr?.delta || 0),
			gamma: (acc?.gamma || 0) + (curr?.gamma || 0),
			vega: (acc?.vega || 0) + (curr?.vega || 0),
			teta: (acc?.teta || 0) + (curr?.teta || 0),
			unrealizedPnl: (acc?.unrealizedPnl || 0) + (curr?.unrealizedPnl || 0),
			qty: (acc?.qty || 0) + Number(curr?.qty || 0),
		};
		result.delta = result.delta > 1 ? 1 : result.delta;
		return result;
	}, initialSum);
};
