import { Field, Form, Formik } from 'formik';
import useTimer from 'hooks/useTimer';
import yup from 'services/utils/capsLockValidate';
import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateSmsRequest } from 'redux/reducers/auth/reducer';
import { getUsersSettings } from 'redux/reducers/settings/selectors';
import { twoFaCheckEnabledRequest } from 'redux/reducers/twoFa/reducer';
import { getTwoFaEnableStatus } from 'redux/reducers/twoFa/selectors';
import Input from 'ui/Formik/Input';
import TwoFaCode from 'ui/Formik/Input/twoFaCode';
import L from 'i18n-react';
import { IChangePassword, IChangePasswordValue } from './types';

const AccountSettingsGeneralPassword: FC<IChangePassword> = ({ changePasswordSubmit }) => {
	const dispatch = useDispatch();
	const [isShowOldPass, setIsShowOldPass] = useState(false);
	const [isShowPass, setIsShowPass] = useState(false);
	const [isShowPassConfirm, setIsShowPassConfirm] = useState(false);
	const twoFaStatus = useSelector(getTwoFaEnableStatus);
	const user = useSelector(getUsersSettings);
	const [phoneCodeTimerValue, phoneCodeTimerReset] = useTimer();

	useEffect(() => {
		dispatch(twoFaCheckEnabledRequest());
	}, [dispatch]);

	const initialValues = {
		old_password: '',
		password: '',
		confirm_password: '',
		totp: '',
		twoFaStatus,
	};

	const handleGetCode = () => {
		dispatch(generateSmsRequest({ phone: user?.data.phone }));
		phoneCodeTimerReset();
	};

	const validationSchema = yup.object().shape({
		old_password: yup
			.string()
			.required(String(L.translate('Forms.ChangePassForm.password_required')))
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				String(String(L.translate('Forms.ChangePassForm.new_password'))),
			)
			.max(25, String(L.translate('Forms.ChangePassForm.password_max_length'))),
		password: yup
			.string()
			.required(String(L.translate('Forms.ChangePassForm.password_required')))
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				String(L.translate('Forms.ChangePassForm.new_password')),
			)
			.max(25, String(L.translate('Forms.ChangePassForm.password_max_length'))),
		confirm_password: yup
			.string()
			.required(String(L.translate('Forms.ChangePassForm.enter_password')))
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				String(L.translate('Forms.ChangePassForm.new_password')),
			)
			.oneOf(
				[yup.ref('password'), null],
				String(L.translate('Forms.ChangePassForm.password_match')),
			)
			.max(25, String(L.translate('Forms.ChangePassForm.password_max_length'))),
		totp: yup
			.string()
			.matches(/^[0-9]{6,6}$/, String(L.translate('Forms.ChangePassForm.password_enter_6_code')))
			// .required('required!')
			.when('twoFaStatus', {
				is: 'sms2fa_enabled',
				then: yup
					.string()
					.required(String(L.translate('Forms.ChangePassForm.auth_code_is_required'))),
			})
			.when('twoFaStatus', {
				is: 'google2fa_enabled',
				then: yup
					.string()
					.required(String(L.translate('Forms.ChangePassForm.auth_code_is_required'))),
			}),
	});
	const handleOldPassDisplay = (): void => {
		setIsShowOldPass(!isShowOldPass);
	};
	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handlePassConfirmDisplay = (): void => {
		setIsShowPassConfirm(!isShowPassConfirm);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IChangePasswordValue, { resetForm, setSubmitting }) => {
				let data;
				if (twoFaStatus === 'google2fa_enabled' || twoFaStatus === 'sms2fa_enabled') {
					data = values;
				} else {
					data = {
						old_password: values.old_password,
						password: values.password,
						confirm_password: values.confirm_password,
					};
				}
				changePasswordSubmit(values);
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty }) => (
				<Form>
					<div className="setting-change-password">
						<div className="input">
							<div className="input-wrapper">
								<Field
									type={isShowOldPass ? 'text' : 'password'}
									placeholder={String(
										L.translate('Forms.ChangePassForm.change_pass_form_field_old_password'),
									)}
									name="old_password"
									required
									component={Input}
									ariaLabel="show-old-password"
									showPass={setIsShowOldPass}
									setIsShowPass={handleOldPassDisplay}
								/>
							</div>
						</div>
						<div className="input">
							<div className="input-wrapper">
								<Field
									type={isShowPass ? 'text' : 'password'}
									placeholder={String(
										L.translate('Forms.ChangePassForm.change_pass_form_field_password'),
									)}
									name="password"
									required
									component={Input}
									ariaLabel="show-new-password"
									showPass={setIsShowPass}
									setIsShowPass={handlePassDisplay}
								/>
							</div>
						</div>
						<div className="input">
							<div className="input-wrapper">
								<Field
									type={isShowPassConfirm ? 'text' : 'password'}
									placeholder={String(
										L.translate('Forms.ChangePassForm.change_pass_form_field_confirm_password'),
									)}
									name="confirm_password"
									required
									component={Input}
									ariaLabel="show-confirm-password"
									showPass={setIsShowPassConfirm}
									setIsShowPass={handlePassConfirmDisplay}
								/>
							</div>
						</div>
						{twoFaStatus === 'google2fa_enabled' && (
							<div className="input">
								<div className="input-wrapper">
									<Field
										type="text"
										placeholder={String(
											L.translate(
												'Forms.ChangePassForm.change_pass_form_enter_authentication_code',
											),
										)}
										name="totp"
										required={twoFaStatus === 'google2fa_enabled'}
										component={TwoFaCode}
										ariaLabel="Authentication Code"
									/>
								</div>
							</div>
						)}
						{twoFaStatus === 'sms2fa_enabled' && (
							<div className="input">
								<button
									type="button"
									disabled={!!phoneCodeTimerValue}
									onClick={handleGetCode}
									className="button button--size2 button--full-width button--full-width--margin"
								>
									{String(L.translate('UI.GetCodeButton.get_code'))}{' '}
									{!!phoneCodeTimerValue && phoneCodeTimerValue}
								</button>
								<div className="input-wrapper">
									<Field
										type="text"
										placeholder={String(
											L.translate('Forms.ChangePassForm.change_pass_form_field_old_password_SMS'),
										)}
										name="totp"
										required={twoFaStatus === 'sms2fa_enabled'}
										component={TwoFaCode}
										ariaLabel="Authentication Code"
									/>
								</div>
							</div>
						)}
						<button
							type="submit"
							disabled={!(isValid && dirty) || isSubmitting}
							aria-label="form-submit"
							className="button button--size2 button--full-width"
						>
							{String(L.translate('Forms.ChangePassForm.change_pass_form_btn'))}
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default AccountSettingsGeneralPassword;
