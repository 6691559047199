import { FC } from 'react';
import useSelect from 'hooks/useSelect';
import { IWalletsSelect, Item } from './types';

const WalletsSelect: FC<IWalletsSelect> = ({ items, value, placeholder, onClear, onSelect }) => {
	const { open, setOpen, toggleOpen, currentRef, dropRef } = useSelect();

	const handleSelect = (item: Item) => {
		onSelect && onSelect(item);
		setOpen(false);
	};

	const handleClear = () => {
		onClear && onClear();
	};

	return (
		<div className="select-block">
			<div className={`select select--currensy ${open ? ' active' : ''} select--currensy-type2`}>
				<button type="button" className="select__current" onClick={toggleOpen} ref={currentRef}>
					<span className="select-current-currensy select-current-currensy--type2">
						<span className="select-current-currensy__icon">
							<svg
								width="24"
								height="25"
								viewBox="0 0 24 25"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M4.25 4C4.25 3.58579 4.58579 3.25 5 3.25H19C19.4142 3.25 19.75 3.58579 19.75 4V8C19.75 8.41421 19.4142 8.75 19 8.75C18.5858 8.75 18.25 8.41421 18.25 8V4.75H5.75V8C5.75 8.41421 5.41421 8.75 5 8.75C4.58579 8.75 4.25 8.41421 4.25 8V4Z"
									fill="#7D7D4D"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M1.75 8C1.75 7.58579 2.08579 7.25 2.5 7.25H21.5C21.9142 7.25 22.25 7.58579 22.25 8V14C22.25 14.4142 21.9142 14.75 21.5 14.75C21.0858 14.75 20.75 14.4142 20.75 14V8.75H3.25V20.25H21.5C21.9142 20.25 22.25 20.5858 22.25 21C22.25 21.4142 21.9142 21.75 21.5 21.75H2.5C2.08579 21.75 1.75 21.4142 1.75 21V8Z"
									fill="#7D7D4D"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M14.9359 13.3898C15.273 13.6305 15.3511 14.0989 15.1103 14.436L13.4574 16.7501H21.5C21.9142 16.7501 22.25 17.0858 22.25 17.5001C22.25 17.9143 21.9142 18.2501 21.5 18.2501H12C11.7191 18.2501 11.4617 18.093 11.3331 17.8432C11.2046 17.5934 11.2264 17.2927 11.3897 17.0641L13.8897 13.5641C14.1305 13.2271 14.5989 13.149 14.9359 13.3898Z"
									fill="#7D7D4D"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M10.25 11C10.25 10.5858 10.5858 10.25 11 10.25H13C13.4142 10.25 13.75 10.5858 13.75 11C13.75 11.4142 13.4142 11.75 13 11.75H11C10.5858 11.75 10.25 11.4142 10.25 11Z"
									fill="#7D7D4D"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M5.25 11C5.25 10.5858 5.58579 10.25 6 10.25H8C8.41421 10.25 8.75 10.5858 8.75 11C8.75 11.4142 8.41421 11.75 8 11.75H6C5.58579 11.75 5.25 11.4142 5.25 11Z"
									fill="#7D7D4D"
								/>
							</svg>
						</span>
						<span className="select-current-currensy__name">
							<span className={`select-current-currensy__name-text ${value ? '' : 'empty'}`}>
								{value || placeholder || ''}
							</span>
						</span>
					</span>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{value ? (
							<button type="button" onClick={handleClear}>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M3.75 5C3.75 4.58579 4.08579 4.25 4.5 4.25H19.5C19.9142 4.25 20.25 4.58579 20.25 5V22C20.25 22.4142 19.9142 22.75 19.5 22.75H4.5C4.08579 22.75 3.75 22.4142 3.75 22V5ZM5.25 5.75V21.25H18.75V5.75H5.25Z"
										fill="#FF57D4"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M10 9.25C10.4142 9.25 10.75 9.58579 10.75 10V16.5C10.75 16.9142 10.4142 17.25 10 17.25C9.58579 17.25 9.25 16.9142 9.25 16.5V10C9.25 9.58579 9.58579 9.25 10 9.25Z"
										fill="#FF57D4"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M14 9.25C14.4142 9.25 14.75 9.58579 14.75 10V16.5C14.75 16.9142 14.4142 17.25 14 17.25C13.5858 17.25 13.25 16.9142 13.25 16.5V10C13.25 9.58579 13.5858 9.25 14 9.25Z"
										fill="#FF57D4"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M1.25 5C1.25 4.58579 1.58579 4.25 2 4.25H22C22.4142 4.25 22.75 4.58579 22.75 5C22.75 5.41421 22.4142 5.75 22 5.75H2C1.58579 5.75 1.25 5.41421 1.25 5Z"
										fill="#FF57D4"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M8.98683 1.63949C9.11849 1.39931 9.3706 1.25 9.6445 1.25H14.3885C14.6647 1.25 14.9186 1.40179 15.0493 1.6451L16.6607 4.6451C16.7856 4.87753 16.7791 5.15847 16.6437 5.38492C16.5083 5.61136 16.2638 5.75 16 5.75H8C7.73502 5.75 7.4897 5.61017 7.35468 5.38217C7.21965 5.15418 7.21496 4.87185 7.34233 4.63949L8.98683 1.63949ZM10.0887 2.75L9.26642 4.25H14.7458L13.9401 2.75H10.0887Z"
										fill="#FF57D4"
									/>
								</svg>
							</button>
						) : null}
						<span className="select__current-arrow">
							<svg
								width="10"
								height="6"
								viewBox="0 0 10 6"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M10 0.833496H1H0L5 5.8335L10 0.833496Z" fill="#9D9DBC" />
							</svg>
						</span>
					</div>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								{items?.map((item) => (
									<li key={item.id} className="active">
										<button type="button" onClick={() => handleSelect(item)}>
											{item.title}
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export type { Item };

export default WalletsSelect;
