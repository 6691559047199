/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { Trade } from 'components/Trade';
import Trading from 'layouts/Trading';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAssetPairsFeesAndLimitsRequest,
	getAssetPairsRequest,
} from 'redux/reducers/assetPairs/reducer';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import { getAssetPairsList } from 'redux/reducers/assetPairs/selectors';
import {
	getOrderBookRequest,
	getRecentTradesRequest,
	getUserTradesRequest,
} from 'redux/reducers/spotTrade/reducer';
import { getCurrentPair } from 'redux/reducers/tradingSettings/selectors';
import { useHistory, useLocation } from 'react-router';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getWalletsRequest } from 'redux/reducers/wallets/reducer';
import {
	getMarginCrossWalletsRequest,
	getMarginIndexPricesRequest,
	getMarginIsolatedWalletsRequest,
} from 'redux/reducers/marginWallets/reducer';
/* eslint-disable no-debugger */
// ==========================================:
const SpotTradingPage: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const currentPair = useSelector(getCurrentPair);
	const authIsAuthenticated = useSelector(getAuthIsAuthenticated);
	const titleMargin = location.pathname.includes('MarginTrading');
	const marginTypeSearch = new URLSearchParams(location.search).get('type') || null;

	useEffect(() => {
		dispatch(getAssetsRequest());
		dispatch(getAssetPairsRequest());
		// dispatch(getAssetPairsFeesAndLimitsRequest());
	}, [dispatch]);
	useEffect(() => {
		const transformCurrrentPair = currentPair?.pair.toUpperCase();

		if (location.pathname.includes('MarginTrading')) {
			history.push(`/MarginTrading/${transformCurrrentPair}${location.search}`);
		} else {
			history.push(`/spot/${transformCurrrentPair}${location.search}`);
		}

		dispatch(
			getOrderBookRequest({
				id: Number(currentPair.id),
				// params: {
				// 	limit: 100,
				// },
			}),
		);

		dispatch(
			getRecentTradesRequest({
				pair: currentPair.pair,
				params: {
					limit: 100,
				},
			}),
		);

		if (authIsAuthenticated) {
			dispatch(getWalletsRequest());
			// TODO: Temporary remove cross
			// dispatch(getMarginCrossWalletsRequest({}));
			// dispatch(getMarginIndexPricesRequest());
			dispatch(getMarginIsolatedWalletsRequest({}));

			if (marginTypeSearch === 'isolated') {
				dispatch(
					getUserTradesRequest({
						params: {
							current_page: 1,
							per_page: 100,
							transaction_type: 'isolated',
							asset_pair_code: currentPair.pair,
							hide_other_pairs: 0,
						},
					}),
				);
			} else {
				dispatch(
					getUserTradesRequest({
						params: {
							per_page: 15,
							current_page: 1,
							transaction_type: 'spot',
							hide_other_pairs: 0,
						},
					}),
				);
			}
		}
		// else if (!authIsAuthenticated) {
		// 	dispatch(resetMarginWalletsRequest());
		// }
	}, [dispatch, currentPair, authIsAuthenticated]);

	return (
		<Trading title={titleMargin ? 'Margin Trading' : 'Spot Trading'}>
			<Trade />
		</Trading>
	);
};

export default SpotTradingPage;
