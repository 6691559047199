import React, { useEffect } from 'react';
import L from 'i18n-react';

const Terms = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="static-section section-gradient">
			<div className="inside">
				<p className="section-title">{String(L.translate('StaticPages.TermsPage.terms_title'))}</p>
				<div className="static-content">
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_1'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_2'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_3'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_4'))}</p>
					<h3>Safety</h3>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_5'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_6'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_7'))}</p>
					<ul className="list-style-dash">
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_1'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_2'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_3'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_4'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_5'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_6'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_7'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_8'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_9'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_10'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_11'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_12'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_13'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_14'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_15'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_16'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_17'))}</i>
						</li>
					</ul>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_8'))}</p>
					<ul className="list-style-dash">
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_18'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_19'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_20'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_21'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_22'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_23'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_24'))}</i>
						</li>
					</ul>
					<h3>{String(L.translate('StaticPages.TermsPage.header_1'))}</h3>
					<ul className="list-style-dash">
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_25'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_26'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_27'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_28'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_29'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_30'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_31'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_32'))}</i>
						</li>
						<li>
							<i>{String(L.translate('StaticPages.TermsPage.li_33'))}</i>
						</li>
					</ul>

					<p>{String(L.translate('StaticPages.TermsPage.paragraph_9'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_10'))}</p>

					<p>{String(L.translate('StaticPages.TermsPage.paragraph_11'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_12'))}</p>

					<p>{String(L.translate('StaticPages.TermsPage.paragraph_13'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_14'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_15'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_16'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_17'))}</p>

					<p>{String(L.translate('StaticPages.TermsPage.paragraph_18'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_19'))} </p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_20'))}</p>

					<p>{String(L.translate('StaticPages.TermsPage.paragraph_21'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_22'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_23'))}</p>

					<p>{String(L.translate('StaticPages.TermsPage.paragraph_24'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_25'))}</p>

					<p>{String(L.translate('StaticPages.TermsPage.paragraph_26'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_27'))}</p>

					<p>{String(L.translate('StaticPages.TermsPage.paragraph_28'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_29'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_30'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_31'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_32'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_33'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_34'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_35'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_36'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_37'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_38'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_39'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_40'))}</p>

					<p>{String(L.translate('StaticPages.TermsPage.paragraph_41'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_42'))}</p>

					<p>{String(L.translate('StaticPages.TermsPage.paragraph_43'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_44'))}</p>

					<p>{String(L.translate('StaticPages.TermsPage.paragraph_45'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_46'))}</p>
					<p>{String(L.translate('StaticPages.TermsPage.paragraph_47'))}</p>
				</div>
			</div>
		</section>
	);
};

export default Terms;
