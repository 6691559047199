import { Suspense, FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { navList } from 'routes/routesList';
import { LoginForm } from '../LoginForm';
import { TwoFa } from '../TwoFa';
import { ILoginForm } from './types';

export const LoginSection: FC<ILoginForm> = ({ data, loginSubmit }) => {
	return (
		<Suspense fallback="Loading...">
			<Switch>
				<Route
					path={navList.login.path}
					render={() => <LoginForm loginSubmit={loginSubmit} data={data} />}
				/>
				<Route
					path={navList.twoFa.path}
					render={() => <TwoFa loginSubmit={loginSubmit} data={data} />}
				/>
				<Redirect to={navList.login.path} />
			</Switch>
		</Suspense>
	);
};
