import L from 'i18n-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getAssetsRequest } from 'redux/reducers/assets/reducer';
import { IAssetsItem } from 'redux/reducers/assets/types';
import { IWalletsNetwork } from 'redux/reducers/wallets/types';
import { ChooseNetworkPopup } from 'ui/ChooseNetworkPopup/ChooseNetworkPopup';
import { SelectAssetInput } from 'ui/SelectAssetInput/SelectAssetInput';
import { WalletLayout } from 'layouts/Wallet';
import {
	getGenerateAddressRequest,
	getWalletToWalletNetworksRequest,
} from 'redux/reducers/wallets/reducer';
import {
	getCurrentWallet,
	getWalletsAddress,
	getGenerateAddressLoader,
} from 'redux/reducers/wallets/selectors';
import Loader from 'ui/Loader';
import { notificationContainer } from 'services/utils/notificationContainer';

export const DepositCrypto = () => {
	const dispatch = useDispatch();
	const location = useLocation<{ initAsset?: IAssetsItem }>();

	const currentWallet = useSelector(getCurrentWallet);
	const walletsAddress = useSelector(getWalletsAddress);
	const generateAddressLoader = useSelector(getGenerateAddressLoader);
	const history = useHistory();

	useEffect(() => {
		dispatch(getAssetsRequest());
	}, [dispatch]);

	const initAsset = location.state?.initAsset || null;

	const [activeCurrency, setActiveCurrency] = useState<IAssetsItem | null>(initAsset);
	const [activeNetwork, setActiveNetwork] = useState<IWalletsNetwork | null>(null);
	const [networkPopup, setNetworkPopup] = useState<boolean>(false);

	const openNetworkPopup = () => {
		setNetworkPopup(true);
	};

	const closeNetworkPopup = () => {
		setNetworkPopup(false);
	};

	const onNetworkChange = (network: IWalletsNetwork) => {
		setActiveNetwork(network);
		if (currentWallet) {
			const generateAddressPayload = {
				wallet_id: currentWallet.id,
				network: network.network,
				asset_id: currentWallet.asset.id,
			};
			dispatch(getGenerateAddressRequest(generateAddressPayload));
		}
	};

	const handleActiveCurrencyChange = (currency: any) => {
		setActiveCurrency(currency);
		setActiveNetwork(null);
	};

	const handleCopyAddress = () => {
		// eslint-disable-next-line no-void
		void navigator.clipboard.writeText(walletsAddress?.address || '');
		notificationContainer(String(L.translate('DepositCrypto.copied_to_clipboard')), 'info');
	};

	const goBack = () => {
		history.goBack();
	};

	useEffect(() => {
		if (activeCurrency) {
			dispatch(getWalletToWalletNetworksRequest(activeCurrency.code));
		}
	}, [activeCurrency, dispatch]);

	return (
		<WalletLayout>
			<div className="deposit-content">
				<div className="deposit-content-inside">
					<div className="deposit-content-header">
						<button type="button" onClick={goBack} className="deposit-content-header__back">
							<svg
								width="21"
								height="21"
								viewBox="0 0 21 21"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M4.875 10.4961C4.875 10.1509 5.15482 9.87109 5.5 9.87109H15.5C15.8452 9.87109 16.125 10.1509 16.125 10.4961C16.125 10.8413 15.8452 11.1211 15.5 11.1211H5.5C5.15482 11.1211 4.875 10.8413 4.875 10.4961Z"
									fill="#1C1C29"
								/>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M10.9419 5.05806C11.186 5.30214 11.186 5.69786 10.9419 5.94194L6.38388 10.5L10.9419 15.0581C11.186 15.3021 11.186 15.6979 10.9419 15.9419C10.6979 16.186 10.3021 16.186 10.0581 15.9419L5.05806 10.9419C4.81398 10.6979 4.81398 10.3021 5.05806 10.0581L10.0581 5.05806C10.3021 4.81398 10.6979 4.81398 10.9419 5.05806Z"
									fill="#1C1C29"
								/>
							</svg>
						</button>
						<p>{String(L.translate('DepositCrypto.deposit_crypto_page_title'))}</p>
					</div>
					<div className="deposit-content-item">
						<div className="deposit-content-item__title">
							<p>{String(L.translate('DepositCrypto.deposit_crypto_select_coin'))}</p>
						</div>
						<div className="select-block">
							<div className="select-block__name">
								<p>{String(L.translate('DepositCrypto.deposit_crypto_coin'))}</p>
							</div>
						</div>
						<SelectAssetInput onChange={handleActiveCurrencyChange} initValue={initAsset} />
					</div>
					{!!activeCurrency && (
						<div className="deposit-content-item">
							<div className="deposit-content-item__title">
								<p>{String(L.translate('DepositCrypto.deposit_crypto_to'))}</p>
							</div>
							<div className="select-block">
								<div className="select-block__name">
									<p>{String(L.translate('DepositCrypto.deposit_crypto_network'))}</p>
								</div>
								<div className="select">
									<button
										type="button"
										onClick={openNetworkPopup}
										className="select__current select-current-currensy--type2 "
									>
										{activeNetwork ? (
											<span className="select-current-currensy__name">
												{activeNetwork.network_full}
											</span>
										) : (
											<p>{String(L.translate('DepositCrypto.deposit_crypto_select_network'))}</p>
										)}
										<span className="select__current-arrow">
											<svg
												width="10"
												height="6"
												viewBox="0 0 10 6"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M10 0.833008H1H0L5 5.83301L10 0.833008Z" fill="#1C1C29" />
											</svg>
										</span>
									</button>
								</div>
							</div>
						</div>
					)}
					{!!activeNetwork && (
						<>
							{generateAddressLoader ? (
								<div
									className="deposit-content-item"
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										height: '300px',
									}}
								>
									<Loader />
								</div>
							) : (
								<div className="deposit-content-item">
									<div className="deposit-address">
										<div className="deposit-address__title">
											<p>{String(L.translate('DepositCrypto.deposit_crypto_address'))}</p>
										</div>
										<div className="deposit-address__text">
											<p>{walletsAddress?.address}</p>
										</div>
										<button
											type="button"
											className="button button--type3 button--size2 button--full-width"
											onClick={handleCopyAddress}
										>
											<svg
												width="21"
												height="20"
												viewBox="0 0 21 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M9.25033 2.70801C9.13526 2.70801 9.04199 2.80128 9.04199 2.91634V4.16634C9.04199 4.51152 8.76217 4.79134 8.41699 4.79134C8.07181 4.79134 7.79199 4.51152 7.79199 4.16634V2.91634C7.79199 2.11093 8.44489 1.45801 9.25033 1.45801H17.5837C18.3891 1.45801 19.042 2.11093 19.042 2.91634V12.083C19.042 12.8884 18.3891 13.5413 17.5837 13.5413H15.917C15.5718 13.5413 15.292 13.2615 15.292 12.9163C15.292 12.5712 15.5718 12.2913 15.917 12.2913H17.5837C17.6987 12.2913 17.792 12.1981 17.792 12.083V2.91634C17.792 2.80128 17.6987 2.70801 17.5837 2.70801H9.25033Z"
													fill="#1C1C29"
												/>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M3.41634 8.125C3.30128 8.125 3.20801 8.21827 3.20801 8.33333V16.6667C3.20801 16.7817 3.30128 16.875 3.41634 16.875H11.7497C11.8647 16.875 11.958 16.7817 11.958 16.6667V8.33333C11.958 8.21827 11.8647 8.125 11.7497 8.125H3.41634ZM1.95801 8.33333C1.95801 7.52792 2.61093 6.875 3.41634 6.875H11.7497C12.5551 6.875 13.208 7.52792 13.208 8.33333V16.6667C13.208 17.4721 12.5551 18.125 11.7497 18.125H3.41634C2.61093 18.125 1.95801 17.4721 1.95801 16.6667V8.33333Z"
													fill="#1C1C29"
												/>
											</svg>
											{String(L.translate('DepositCrypto.deposit_crypto_copy_button'))}
										</button>

										<div className="deposit-address-qr">
											<div className="deposit-address-qr__photo">
												<img src={walletsAddress?.address_qr} alt="address_qr" />
											</div>
											<div className="deposit-address-qr__info">
												{/* <div className="deposit-address-qr__info-title"> */}
												{/*	<p>MEMO</p> */}
												{/* </div> */}
												{/* <div className="deposit-address-qr__code"> */}
												{/*	<p>101573098</p> */}
												{/*	<button type="button"> */}
												{/*		<svg */}
												{/*			width="20" */}
												{/*			height="20" */}
												{/*			viewBox="0 0 20 20" */}
												{/*			fill="none" */}
												{/*			xmlns="http://www.w3.org/2000/svg" */}
												{/*		> */}
												{/*			<path */}
												{/*				fillRule="evenodd" */}
												{/*				clipRule="evenodd" */}
												{/*				d="M6.58887 2.70801C6.28684 2.70801 6.04199 2.95285 6.04199 3.25488V5.17951C6.04199 5.52469 5.76217 5.80451 5.41699 5.80451C5.07181 5.80451 4.79199 5.52469 4.79199 5.17951V3.25488C4.79199 2.2625 5.59648 1.45801 6.58887 1.45801H16.7451C17.7375 1.45801 18.542 2.2625 18.542 3.25488V13.4111C18.542 14.4035 17.7375 15.208 16.7451 15.208H14.7988C14.4536 15.208 14.1738 14.9282 14.1738 14.583C14.1738 14.2378 14.4536 13.958 14.7988 13.958H16.7451C17.0471 13.958 17.292 13.7132 17.292 13.4111V3.25488C17.292 2.95285 17.0471 2.70801 16.7451 2.70801H6.58887Z" */}
												{/*				fill="#7C7CA6" */}
												{/*			/> */}
												{/*			<path */}
												{/*				fillRule="evenodd" */}
												{/*				clipRule="evenodd" */}
												{/*				d="M1.45801 6.58887C1.45801 5.59648 2.2625 4.79199 3.25488 4.79199H13.4111C14.4035 4.79199 15.208 5.59648 15.208 6.58887V16.7451C15.208 17.7375 14.4035 18.542 13.4111 18.542H3.25488C2.2625 18.542 1.45801 17.7375 1.45801 16.7451V6.58887ZM3.25488 6.04199C2.95285 6.04199 2.70801 6.28684 2.70801 6.58887V16.7451C2.70801 17.0471 2.95285 17.292 3.25488 17.292H13.4111C13.7132 17.292 13.958 17.0471 13.958 16.7451V6.58887C13.958 6.28684 13.7132 6.04199 13.4111 6.04199H3.25488Z" */}
												{/*				fill="#7C7CA6" */}
												{/*			/> */}
												{/*		</svg> */}
												{/*	</button> */}
												{/* </div> */}
												{/* <div className="deposit-address-qr__info-block"> */}
												{/*	<div className="deposit-address-qr__info-block-icon"> */}
												{/*		<svg */}
												{/*			width="20" */}
												{/*			height="20" */}
												{/*			viewBox="0 0 20 20" */}
												{/*			fill="none" */}
												{/*			xmlns="http://www.w3.org/2000/svg" */}
												{/*		> */}
												{/*			<path */}
												{/*				fillRule="evenodd" */}
												{/*				clipRule="evenodd" */}
												{/*				d="M3.66583 3.66582C5.2863 2.04537 7.52674 1.04199 10.0003 1.04199C12.4739 1.04199 14.7144 2.04537 16.3348 3.66582L15.8929 4.10777L16.3348 3.66583C17.9553 5.2863 18.9587 7.52674 18.9587 10.0003C18.9587 12.4739 17.9553 14.7144 16.3348 16.3348C14.7144 17.9553 12.4739 18.9587 10.0003 18.9587C7.52674 18.9587 5.2863 17.9553 3.66583 16.3348L4.10777 15.8929L3.66582 16.3348C2.04537 14.7144 1.04199 12.4739 1.04199 10.0003C1.04199 7.52674 2.04537 5.2863 3.66582 3.66583L3.66583 3.66582ZM10.0003 2.29199C7.87158 2.29199 5.94535 3.15409 4.54971 4.54971C3.15409 5.94536 2.29199 7.87158 2.29199 10.0003C2.29199 12.1291 3.15409 14.0553 4.54971 15.4509C5.94535 16.8466 7.87158 17.7087 10.0003 17.7087C12.1291 17.7087 14.0553 16.8466 15.4509 15.4509C16.8466 14.0553 17.7087 12.1291 17.7087 10.0003C17.7087 7.87158 16.8466 5.94535 15.4509 4.54971C14.0553 3.15409 12.1291 2.29199 10.0003 2.29199Z" */}
												{/*				fill="#EB5757" */}
												{/*			/> */}
												{/*			<path */}
												{/*				fillRule="evenodd" */}
												{/*				clipRule="evenodd" */}
												{/*				d="M9.99967 15.4163C10.575 15.4163 11.0413 14.95 11.0413 14.3747C11.0413 13.7994 10.575 13.333 9.99967 13.333C9.42438 13.333 8.95801 13.7994 8.95801 14.3747C8.95801 14.95 9.42438 15.4163 9.99967 15.4163Z" */}
												{/*				fill="#EB5757" */}
												{/*			/> */}
												{/*			<path */}
												{/*				fillRule="evenodd" */}
												{/*				clipRule="evenodd" */}
												{/*				d="M10 4.375C10.3452 4.375 10.625 4.65482 10.625 5V11.6667C10.625 12.0118 10.3452 12.2917 10 12.2917C9.65482 12.2917 9.375 12.0118 9.375 11.6667V5C9.375 4.65482 9.65482 4.375 10 4.375Z" */}
												{/*				fill="#EB5757" */}
												{/*			/> */}
												{/*		</svg> */}
												{/*	</div> */}
												{/*	<p>MEMO is required, or you will lose your coins</p> */}
												{/* </div> */}
												<div className="deposit-address-qr__info-title">
													<p>{String(L.translate('DepositCrypto.deposit_crypto_minimum'))}</p>
												</div>
												<div className="deposit-address-qr__info-text">
													<p>
														{activeNetwork.withdraw_min} {activeCurrency?.code}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</div>

			<ChooseNetworkPopup
				opened={networkPopup}
				closePopup={closeNetworkPopup}
				onNetworkChange={onNetworkChange}
			/>
		</WalletLayout>
	);
};
