import { FC } from 'react';
import { customDateFormat } from 'services/utils/dateAndTimeHelpers';
import { IOpenOrdersTableRow } from './types';

const OrderHistoryTableRow: FC<IOpenOrdersTableRow> = ({ item }) => {
	return (
		<>
			<div className="tr">
				<div className="td">
					<p className="grey">{item.created_at && customDateFormat(item.created_at)}</p>
				</div>
				<div className="td">
					<p>{item?.asset_pair?.code && item?.asset_pair?.code?.replace('_', '/').toUpperCase()}</p>
				</div>
				<div className="td order-type-cell">
					<p>{item.type}</p>
				</div>
				<div className={`td ${item.direction === 'buy' ? 'green' : 'red'} capitalize`}>
					<p>{item.direction}</p>
				</div>
				<div className="td">
					<p>{item.price_average}</p>
				</div>
				<div className="td">
					<p>{item.price}</p>
				</div>
				<div className="td">
					<p>{item.quantity_left}</p>
				</div>
				<div className="td">
					<p>{item.quantity}</p>
				</div>
				<div className="td">
					<p>{Number(item.total)?.toFixed(2)}</p>
				</div>
				<div className="td">
					<p>{item.trigger_stop_limit_price}</p>
				</div>
				<div className="td td--right">
					<p className="green capitalize">{item.status}</p>
				</div>
			</div>
		</>
	);
};

export default OrderHistoryTableRow;
