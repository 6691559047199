import useSelect from 'hooks/useSelect';

const BirthSelect = ({
	title,
	range,
	select,
	selected,
}: {
	title: string;
	range: [number, number] | any;
	select: any;
	selected: string;
}) => {
	const { open, setOpen, toggleOpen, currentRef, dropRef } = useSelect();

	const renderItems = () => {
		let items = [];
		if (range.length === 2) {
			for (let i = range[0]; i <= range[1]; i += 1) {
				items.push(i);
			}
		}
		if (range.length > 2) {
			items = [...range];
		}
		return items.map((item) => (
			<li key={String(item) + String(item)}>
				<button type="button" value={item}>
					{item}
				</button>
			</li>
		));
	};

	const handleClick = (e: any) => {
		const { value } = e.target;
		if (value) {
			select(value);
			setOpen(false);
		}
	};

	return (
		<div className={`select ${open ? ' active' : ''}`} ref={dropRef}>
			<button className="select__current" type="button" onClick={toggleOpen} ref={currentRef}>
				<p>{selected || title}</p>
				<span className="select__current-arrow">
					<svg
						width="10"
						height="6"
						viewBox="0 0 10 6"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M10 0.833008H1H0L5 5.83301L10 0.833008Z" fill="#1C1C29" />
					</svg>
				</span>
			</button>
			<div className="select__drop">
				<div className="select__drop-scroll">
					<div className="select__drop-item" onClick={handleClick}>
						<ul>{renderItems()}</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
export default BirthSelect;
