import { TriangleArrowDownIcon } from 'assets/inline-svg';
import { FC, useState } from 'react';
import L from 'i18n-react';
import { ChooseNetworkPopup } from 'ui/ChooseNetworkPopup/ChooseNetworkPopup';
import { INetworkSelectProps } from './types';

const NetworkSelect: FC<INetworkSelectProps> = ({ current, onChange }) => {
	const [isOpenPopup, setIsOpenPopup] = useState(false);

	return (
		<div className="select-block">
			<div className="select-block__name">
				<p>{String(L.translate('WithdrawCrypto.NetworkPopup.title'))}</p>
			</div>

			<div className="select">
				<button
					type="button"
					onClick={() => setIsOpenPopup(true)}
					className="select__current select-current-currensy--type2 "
				>
					{current ? (
						<span className="select-current-currensy__name">
							{current.network_name.toUpperCase()}
						</span>
					) : (
						<p>{String(L.translate('WithdrawCrypto.NetworkPopup.select_network'))}</p>
					)}
					<span className="select__current-arrow">
						<TriangleArrowDownIcon />
					</span>
				</button>
			</div>

			<ChooseNetworkPopup
				opened={isOpenPopup}
				closePopup={() => setIsOpenPopup(false)}
				onNetworkChange={onChange}
			/>
		</div>
	);
};

export default NetworkSelect;
