import { FC } from 'react';
import L from 'i18n-react';

const ConvertFrom: FC = () => {
	return (
		<div className="convert-form-item">
			<div className="convert-form-item__header">
				<p>{String(L.translate('Wallets.MarginAccount.modals.from'))}</p>
			</div>
			<div className="input">
				<div className="input-wrapper">
					<div className="transfer-destination input-item input-item--left-icon">
						<span>{String(L.translate('Wallets.MarginAccount.modals.spot_wallet'))}</span>
					</div>
					<div className="input-icon input-icon--auto">
						<svg
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M15.5176 1.85061C15.8763 1.6435 16.335 1.76641 16.5421 2.12512L18.8483 6.11958C18.9823 6.35175 18.9823 6.63782 18.8481 6.86993C18.7139 7.10203 18.4661 7.24487 18.198 7.24458L8.99046 7.23457C8.65116 7.23421 8.35437 7.00608 8.26673 6.6783C8.17909 6.35051 8.32243 6.00471 8.61628 5.83506L15.5176 1.85061ZM11.7851 5.73761L16.8989 5.74316L15.6181 3.52464L11.7851 5.73761Z"
								fill="#7D7D4D"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M3 7.25C2.86192 7.25 2.75 7.36192 2.75 7.5V21.5C2.75 21.6381 2.86192 21.75 3 21.75H21C21.1381 21.75 21.25 21.6381 21.25 21.5V7.5C21.25 7.36191 21.1381 7.25 21 7.25H3ZM1.25 7.5C1.25 6.53348 2.03351 5.75 3 5.75H21C21.9665 5.75 22.75 6.53349 22.75 7.5V21.5C22.75 22.4665 21.9665 23.25 21 23.25H3C2.03351 23.25 1.25 22.4665 1.25 21.5V7.5Z"
								fill="#7D7D4D"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M14.25 14.5C14.25 12.671 15.7959 11.25 17.625 11.25H22C22.4142 11.25 22.75 11.5858 22.75 12V17C22.75 17.4142 22.4142 17.75 22 17.75H17.625C15.7959 17.75 14.25 16.329 14.25 14.5ZM17.625 12.75C16.5546 12.75 15.75 13.5676 15.75 14.5C15.75 15.4324 16.5546 16.25 17.625 16.25H21.25V12.75H17.625Z"
								fill="#7D7D4D"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M22 8C22.4142 8 22.75 8.33579 22.75 8.75V20.75C22.75 21.1642 22.4142 21.5 22 21.5C21.5858 21.5 21.25 21.1642 21.25 20.75V8.75C21.25 8.33579 21.5858 8 22 8Z"
								fill="#7D7D4D"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConvertFrom;
